import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from './CadastroHelper.js';

class CadastroTipoCarro extends Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <CadastroHelper
        dados={{
          acessoID: '78aa66a5-c371-4b57-bf34-332d2ae8cd22',
          parentProps: this.props,
          id: "t03_cd_tp_carro",
          tipo_cadastro: "Tipo de carro",
          tipo_plural: "carros",
          href: "/tipo_carro",
          titulo: "Cadastro de Tipos de Carros",
          requests: {
            type: 'tipo_carro',
            list: this.ws.getTipo.bind(this.ws),
            save: this.ws.criarTipo.bind(this.ws),
            edit: this.ws.alterarTipo.bind(this.ws),
            delete: this.ws.deleteTipo.bind(this.ws),
          },
          campos: [
            {
              ref: "t03_prioridade",
              label: "Prioridade",
              type: "number",
              placeHolder: "Escreva a prioridade do tipo de carro",
            },
            {
              ref: "t03_sg_tp_carro",
              label: "Sigla",
              type: "text",
              placeHolder: "Escreva a sigla do tipo de carro",
            },
            {
              ref: "t03_desc_tp_carro",
              label: "Descrição",
              type: "text",
              placeHolder: "Escreva a descrição do tipo de carro",
            },
            {
              ref: "t03_ativo",
              label: "Ativo",
              type: "checkbox",
            },
          ],
          botoes: [
            {
              label: "Criar Tipo",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Prioridade",
                accessor: "t03_prioridade",
                width: 80,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                    }}
                  >
                  {row.original.t03_prioridade}
                  </div>
                ),
              },
              {
                Header: "Ativo",
                accessor: "t03_ativo",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.t03_ativo ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
              {
                Header: "Sigla",
                accessor: "t03_sg_tp_carro",
                width: 100,
              },
              {
                Header: "Descrição",
                accessor: "t03_desc_tp_carro",
              },
            ],
          }
        }}
      />
    );
  }
}

export default CadastroTipoCarro;
