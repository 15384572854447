import React, { Component } from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap'
import Autosuggest from 'react-autosuggest';
import Loader from 'react-loader';

class AutoComplete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: true,
    };

  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() { }

  onChange = (event, { newValue }) => {
    this.props.changeEndereco(newValue, 'Curitiba', null)
  };

  onSuggestionsFetchRequested = ({ value }) => {
      if (this.props.autocomplete) {
        this.props.autocomplete(value, this.props.center).then(result => {
            this.props.changeAddress(result);
          })
      }

  };

  onSuggestionsClearRequested = () => {
    this.props.changeAddress([]);
  };

  render() {
    return (
      <FormGroup className="autoComp">
        <Loader loaded={this.state.loaded}>
        </Loader>
        <ControlLabel>{this.props.title}</ControlLabel>
        <Autosuggest
          ref={"auto_complete_helper"}
          highlightFirstSuggestion={true}
          focusInputOnSuggestionClick={false}
          suggestions={this.props.address}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={(e, { suggestion, method }) => {
            this.props.changeEndereco(suggestion.endereco, suggestion.city, suggestion.state);
            if (this.props.endSelected) {
              this.props.endSelected(suggestion.endereco, suggestion.city, suggestion.state);
            }
          }}
          getSuggestionValue={item => item.endereco}
          renderSuggestion={item =>
            <div>
              {item.label}
            </div>
          }
          inputProps={{
            value: this.props.endereco,
            onChange: this.onChange,
          }}
        />
      </FormGroup>
    );
  }
}
export default AutoComplete;
