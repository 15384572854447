import React, { Component } from 'react';
import { GoogleMap, withGoogleMap, withScriptjs, Polygon } from 'react-google-maps';
import WS from '../services/webservice.js';
import CustomMarker from './CustomMarker.js'
import './Solicitacoes.css';
import { Col, Breadcrumb, FormGroup, ControlLabel, Button, FormControl } from 'react-bootstrap';

import livreDot from '../images/livre-dot.png';
import ocupadoDot from '../images/ocupado-dot.png';

const GoogleMapsWrapper = withScriptjs(withGoogleMap(props => {
  const { onMapMounted, ...otherProps } = props;
  return <GoogleMap {...otherProps} ref={c => { onMapMounted && onMapMounted(c) }}>
    {props.children}
  </GoogleMap>
}));

class MapaMotoRefs extends Component {

  static defaultProps = {
    center: { lat: -25.475015, lng: -49.2911143 },
    zoom: 12
  };

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      motoristas: [],
      center: { lat: -25.475015, lng: -49.2911143 },
      zoom: 12,
      numeroAT: '',
      numeroPonto: '',
    }

    this.webservice = new WS();

    this.getRefs = this.getRefs.bind(this);
    this.getMotoristas = this.getMotoristas.bind(this);
  }

  componentDidMount() {
    this.getRefs();
    this.timerId = setInterval(() => { this.getMotoristas(); }, 15000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  async getMotoristas() {
    let result = await this.webservice.fetch2('motoristas/?centrais=' + this.props.childProps.userAttrib.centralString.ids);
    result = await result.json();
    this.setState({ motoristas: result });
  }

  async getRefs() {
    let result = await this.webservice.getRefsMapa();

    this.setState({ list: result }, () => {
      let a = [{ path: [] }];

      this.state.list.map((r, i) => {
        a = [{ path: [] }];
        for (let i = 0; i < r.t61_path.length; i++) {
          a[0].path.push(
            {
              lat: r.t61_path[i][0],
              lng: r.t61_path[i][1]
            },
          );
        }
        r.t61_path = a;
        return null;
      });
    });
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active href='referencias'>Mapa das Referências</Breadcrumb.Item>
        </Breadcrumb>
        <FormGroup>
          <Col md={2} style={{ textAlign: 'end', paddingRight: 0 }}>
            <ControlLabel>Número do Ponto:</ControlLabel>
          </Col>
          <Col md={1}>
            <FormControl
              type="number"
              value={this.state.numeroPonto}
              onChange={e => { this.setState({ numeroPonto: e.target.value }) }}
            />
          </Col>
          <Col md={1}>
            <Button
              onClick={() => {
                if (this.state.numeroPonto === '') {
                  alert('Por favor preencha o número do ponto!');
                  return;
                }
                let obj = this.state.list.find((obj) => { return obj.t61_ref.toString() === this.state.numeroPonto; });
                this.setState({ center: { lat: obj.t61_lat, lng: obj.t61_lon }, zoom: 16 })
              }}
            >Pesquisar Ponto</Button>
          </Col>
          <Col md={2} style={{ textAlign: 'end', paddingRight: 0 }}>
            <ControlLabel>AT:</ControlLabel>
          </Col>
          <Col md={1}>
            <FormControl
              type="number"
              value={this.state.numeroAT}
              onChange={e => { this.setState({ numeroAT: e.target.value }) }}
            />
          </Col>
          <Col md={1}>
            <Button
              onClick={() => {
                if (this.state.numeroAT === '') {
                  alert('Por favor preencha o AT do motorista!');
                  return;
                }
                let obj = this.state.motoristas.find((obj) => { return obj.dados.at.toString() === this.state.numeroAT; });
                if (obj) {
                  this.setState({ center: { lat: obj.latitude, lng: obj.longitude }, zoom: 16 })
                } else {
                  alert('Não foi encontrado nenhum motorista nesse momento!');
                }
              }}
            >Pesquisar Motorista</Button>
          </Col>
        </FormGroup>
        <Col md={12}>
          <div id="googleMapDiv" style={{ height: '90vh', width: '100%' }}>
            <GoogleMapsWrapper
              options={{ streetViewControl: false, mapTypeControl: false }}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCJvlPMRSy6vltr9575qooDS8bNKRtXxbs&language=pt&region=br&v=3&libraries=drawing"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              onMapMounted={this._handleMapMounted}
              center={this.state.center}
              zoom={this.state.zoom}
            >
              {this.state.list.map((r, i) => {
                if (r.t61_path && r.t61_path.length > 0 && r.t61_path[0].path) {
                  return (
                    <Polygon
                      key={i}
                      ref={"poly" + i}
                      defaultPath={r.t61_path[0].path}
                      path={r.t61_path[0].path}
                    />                    
                  )
                }
                return null;
              })
              }
              {this.state.list.map((r, i) => {
                return (
                  <CustomMarker
                    key={i}
                    onClick={() => {}}
                    lat={r.t61_lat}
                    lng={r.t61_lon}
                    info={'Ponto ' + r.t61_ref}
                  />
                );
              })
              }
              {this.state.motoristas.map((m, i) => {
                return (
                  <CustomMarker
                    key={i}
                    onClick={() => {}}
                    iconUrl={m.livre ? livreDot : ocupadoDot}
                    iconSize={12}
                    lat={m.latitude}
                    lng={m.longitude}
                    info={'AT: ' + m.dados.at}
                  />
                );
              })
              }
            </GoogleMapsWrapper>
          </div>
        </Col>
      </div>
    );
  }
}

export default MapaMotoRefs;
