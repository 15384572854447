import React, { Component } from 'react'
import WS from '../services/webservice'
import CadastroHelper from '../helper/CadastroHelper.js'
import { Button } from 'react-bootstrap'
import Fontes from '../fonts/Fontes.js';

class LegendaTipo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sublistas: [],
      record: {}
    }
    this.ws = new WS()
  }

  componentWillUnmount() {
    clearInterval(this.timerId)
  }

  componentDidMount() {}
  mirrorState(key) {
    console.log('mirrorState', key)
    this.setState(key)
  }
  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: 'id',
          tipo_cadastro: 'Legenda de tipo',
          tipo_plural: 'Legenda de tipo',
          href: 'legendatipo',
          novo: false,
          titulo: 'Legenda de tipo',
          mirrorState: ((key) => {
            this.setState(key)
          }).bind(this),
          requests: {
            type: 'legendatipo',
            new: true,
            list: true,
            save: true,
            edit: true,
            delete: true,
            listSub: true
          },
          subtipos: [
            {
              tipo: 'siglas',
              p: 'oarray',
              array: [
                { txt: 'CNL', id: 'cnl' },
                { txt: 'PRM', id: 'prm' },
                { txt: 'C15', id: 'c15' },
                { txt: 'MOT', id: 'mot' },
                { txt: 'CDO', id: 'cdo' },
                { txt: 'POP', id: 'pop' },
                { txt: 'LDY', id: 'ldy' },
                { txt: 'VET', id: 'vet' }
              ]
            },
            {
              tipo: 'icones',
              p: 'array',
              array: Fontes.ListIconFont()
            }
          ],
          organizado: [
            [{ref: 'linha', tipo: 'hr'},],
            [{ref: 'tipo', tipo: 'campo'}],
            [{ref: 'descricao', tipo: 'campo'}],
            [{ref: 'icone', tipo: 'campo'},{ref: 'ident', tipo: 'texto', texto:<><Fontes.IconFont
            icone={this.state.record.icone ? this.state.record.icone : "FontAwesome:car"}
            size={60}
            color={'Red'}
          />
            <a
              style={{ display: 'table-cell' }}
              href={'https://oblador.github.io/react-native-vector-icons/'}
              target='_blank'
            >
              Legendas
            </a>
          </>},],
            [{ref: 'linha', tipo: 'hr'},],
            [{ref: 'iconeb64', tipo: 'campo'}],
          ],
          campos: [
            // TODO: confirmar a necessidade dos campos comentados
            // {
            //   ref: "id",
            //   label: "Código",
            //   type: "justlabel",
            //   placeHolder: "Código",
            // },
            // {
            //   ref: "central",
            //   label: "Central",
            //   type: "justlabel",
            //   placeHolder: "Central",
            // },
            {
              ref: 'tipo',
              label: 'Tipo',
              type: 'select',
              listtype: 'siglas',
              default: 'cnl',
              placeHolder: 'Tipo'
            },
            {
              ref: 'descricao',
              label: 'Descrição',
              type: 'text',
              placeHolder: 'Descrição'
            },
            {
              ref: 'icone',
              label: 'Icone',
              type: 'select',
              listtype: 'icones',
              default: 'FaCar',
              placeHolder: 'Icone'
            },
            {
              ref: 'iconeb64',
              label: 'Icone Imagen',
              type: 'foto64'
            }
          ],
          table: {
            colunas: [
              {
                Header: 'Tipo',
                accessor: 'tipo'
              },
              {
                Header: 'Descrição',
                accessor: 'descricao'
              }
            ]
          }
        }}
      />
    )
  }
}

export default LegendaTipo
