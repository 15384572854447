import React, { Component } from 'react';
import './Corridas.css';
import {  Form, ControlLabel, FormControl, Button, Modal, Col, Row } from 'react-bootstrap';
import WS from '../services/webservice';

class Despacho extends Component {

  constructor(props) {
    super(props);
    this.state = {
      MAXD: 0,
      MAXDL: 0,
      MMS1: 0,
      MMS2: 0,
      MINDL: 0,
      TC: 0,
      TECP: 0,
      TECP2: 0,
      TECP3: 0,
      TTSB: 0,
      variable: {},
      iparam: {},
      showParam: false,
    };

    this.salvaform = this.salvaform.bind(this);

    this.webservice = new WS();
  }

  componentDidMount() {
    this.attParam();
  }

  async salvaform() {
    this.webservice.saveDespacho(
      this.state.MAXD,
      this.state.MAXDL,
      this.state.MMS1,
      this.state.MMS2,
      this.state.MINDL,
      this.state.TC,
      this.state.TECP,
      this.state.TECP2,
      this.state.TECP3,
      this.state.TTSB,
    ).then(()=>this.attParam())
    alert('Configurações salvas')
    this.props.modalHide();
  }

  async attParam() {
    let result = await this.webservice.getDespacho()
    console.log("resultado dos parametros", result)
    this.setState({
      MAXD: result.MAXIMO_DISTANCIA,
      MAXDL: result.MAXIMO_DISTANCIA_LISTA,
      MMS1: result.MAX_MOTORISTA_STEP1,
      MMS2: result.MAX_MOTORISTA_STEP2,
      MINDL: result.MINIMO_DISTANCIA_LISTA,
      TC: result.TEMPO_CASTIGO,
      TECP: result.TEMPO_EXPIRA_CORRIDA_PENDENTE,
      TECP2: result.TEMPO_EXPIRA_CORRIDA_PENDENTE2,
      TECP3: result.TEMPO_EXPIRA_CORRIDA_PENDENTE3,
      TTSB: result.TIME_TO_SEND_BACK,
    })
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.modalHide}>
        <Modal.Header>
          <Modal.Title>Despacho</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>Tempo Pendencia em {this.state.FPC} minutos</p>
            <p>Tempo Atraso Atendimento em {this.state.FPQRL} minutos</p> */}
          <Form horizontal>
              <Row>
                <Col md={7} style={{ textAlign: 'left'}}>
                  <ControlLabel>MAXIMO_DISTANCIA:</ControlLabel>
                </Col>
                <Col md={5} >
                  <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '28px' }} placeholder={this.state.MAXD} onChange={e => { this.setState({ MAXD: e.target.value }) }} />
                </Col>
              </Row>
              <Row>
                <Col md={7} style={{ textAlign: 'left' }}>
                  <ControlLabel>MAXIMO_DISTANCIA_LISTA:</ControlLabel>
                </Col>
                <Col md={5}  >
                  <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '28px' }} placeholder={this.state.MAXDL} onChange={e => { this.setState({ MAXDL: e.target.value }) }} />
                </Col>
              </Row>
              <Row>
                <Col md={7} style={{ textAlign: 'left' }}>
                  <ControlLabel> MAX_MOTORISTA_STEP1:</ControlLabel>
                </Col>
                <Col md={5}  >
                  <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '28px' }} placeholder={this.state.MMS1} onChange={e => { this.setState({ MMS1: e.target.value }) }} />
                </Col>
              </Row>
              <Row>
                <Col md={7} style={{ textAlign: 'left' }}>
                  <ControlLabel> MAX_MOTORISTA_STEP2:</ControlLabel>
                </Col>
                <Col md={5}  >
                  <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '28px' }} placeholder={this.state.MMS2} onChange={e => { this.setState({ MMS2: e.target.value }) }} />
                </Col>
              </Row>
              <Row>
                <Col md={7} style={{ textAlign: 'left' }}>
                  <ControlLabel> MINIMO_DISTANCIA_LISTA:</ControlLabel>
                </Col>
                <Col md={5}  >
                  <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '28px' }} placeholder={this.state.MINDL} onChange={e => { this.setState({ MINDL: e.target.value }) }} />
                </Col>
              </Row>
              <Row>
                <Col md={7} style={{ textAlign: 'left' }}>
                  <ControlLabel> TEMPO_CASTIGO:</ControlLabel>
                </Col>
                <Col md={5}  >
                  <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '28px' }} placeholder={this.state.TC} onChange={e => { this.setState({ TC: e.target.value }) }} />
                </Col>
              </Row>
              <Row>
                <Col md={7} style={{ textAlign: 'left' }}>
                  <ControlLabel> TEMPO_EXPIRA_CORRIDA_PENDENTE:</ControlLabel>
                </Col>
                <Col md={5}  >
                  <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '28px' }} placeholder={this.state.TECP} onChange={e => { this.setState({ TECP: e.target.value }) }} />
                </Col>
              </Row>
              <Row>
                <Col md={7} style={{ textAlign: 'left' }}>
                  <ControlLabel> TEMPO_EXPIRA_CORRIDA_PENDENTE2:</ControlLabel>
                </Col>
                <Col md={5}  >
                  <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '28px' }} placeholder={this.state.TECP2} onChange={e => { this.setState({ TECP2: e.target.value }) }} />
                </Col>
              </Row>
              <Row>
                <Col md={7} style={{ textAlign: 'left' }}>
                  <ControlLabel> TEMPO_EXPIRA_CORRIDA_PENDENTE3:</ControlLabel>
                </Col>
                <Col md={5}  >
                  <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '28px' }} placeholder={this.state.TECP3} onChange={e => { this.setState({ TECP3: e.target.value }) }} />
                </Col>
              </Row>
              <Row>
                <Col md={7} style={{ textAlign: 'left' }}>
                  <ControlLabel> TIME_TO_SEND_BACK:</ControlLabel>
                </Col>
                <Col md={5}  >
                  <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '28px' }} placeholder={this.state.TTSB} onChange={e => { this.setState({ TTSB: e.target.value }) }} />
                </Col>
              </Row>

            <Button onClick={this.salvaform}>Salvar</Button>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
}
export default Despacho;
