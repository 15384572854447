import React, { Component } from 'react';
import './Bubbles.css';
import ReactTable from "react-table";
import "react-table/react-table.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import WS from '../services/webservice';

class HistoricoPA extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataHora: null,
      items: [],
      center: { lat: -25.47, lng: -49.29 },
      zoom: 15,
      dataHoraFim: moment(),
      dataHoraInicio: moment().subtract(1, 'hours'),
      pathPolyline: [],
    }

    this.fetchData = this.fetchData.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.dataHoraHandleChange = this.dataHoraHandleChange.bind(this);

    this.webservice = new WS();
  }

  async fetchData(dados, instance) {
    let pge = dados.page + 1;
    let order = dados.sorted;
    if (order.length <= 0) {
      order = [{ id: "datahora", desc: true }]
    }
    this.setState({ loading: true, dados: dados, instance: instance });

    let result;
    if (this.state.dataHora) {
      result = await this.webservice.getTipo2('historicodepa?t07_carro.t07_conceccao={"eq": " ' + this.props.moto.porta + ' " }&datahora={"gte": " ' + moment(this.state.dataHora).format("YYYY-MM-DD 00:00") + ' " ,"lte": "' + moment(this.state.dataHora).format("YYYY-MM-DD 23:59") + ' " }', '', order, pge, dados.pageSize, dados.filtered, null);
    } else {
      result = await this.webservice.getTipo2('historicodepa?t07_carro.t07_conceccao={"eq": " ' + this.props.moto.porta + ' " }', '', order, pge, dados.pageSize, dados.filtered, null);
    }

    let count = result.headers.get('X-Total-Count');
    result = await result.json();

    this.setState({
      refs: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false
    })
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => { this.fetchData(dados, instance) }, 300)
    });
  }

  dataHoraHandleChange(date) {
    this.setState({ dataHora: date }, () => {
      this.fetchData(this.state.dados, this.state.instance);
    });
  }

  render() {
    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={this.props.modalHide}
        onEntered={() => { }}
        container={this}
        aria-labelledby="contained-modal-title"
        dialogClassName="pa-dialog-modal"
      >
        <Modal.Body>
          <ReactTable
            manual
            pages={this.state.pages}
            loading={this.state.loading}
            onFetchData={this.fetchDataTimeOut}
            data={this.state.refs}
            pageSizeOptions={[5, 10, 15]}
            previousText='Anterior'
            nextText='Próxima'
            loadingText='Carregando...'
            noDataText='Nenhum dado encontado'
            pageText='Página'
            ofText='de'
            rowsText='linhas'
            columns={[
              {
                Header: 'Motorista',
                accessor: 't08_motoristas_t08_nomemotorista',
              },
              {
                Header: 'Data/Hora',
                id: 'datahora',
                accessor: d => moment(d.datahora).format('DD/MM/YYYY HH:mm'),
                width: 140,
                filterable: true,
                filterMethod: (filter, row) => {
                  return this.state.dataHora;
                },
                Filter: ({ filter, onChange }) =>
                  <DatePicker
                    className="react-table-date-picker"
                    maxDate={moment()}
                    selected={this.state.dataHora}
                    onChange={this.dataHoraHandleChange}
                    isClearable={true}
                    withPortal
                  />
              },
              {
                Header: 'Posição',
                id: 'posicao',
                accessor: d => d.posicao + '°',
                width: 70,
                filterable: true,
              },
              {
                Header: 'Referência',
                accessor: 'referencia',
                width: 80,
                filterable: true,
              },
              {
                Header: 'Status',
                accessor: 'status',
                width: 85,
                filterable: true,
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  } else {
                    return row[filter.id];
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todas</option>
                    <option value="Livre">Livre</option>
                    <option value="Ocupado">Ocupado</option>
                  </select>
              },
            ]}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </Modal.Body>
      </Modal>
    );
  }
}
export default HistoricoPA;