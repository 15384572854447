import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class PrecadastrobkpNew extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: []
    }
    this.ws = new WS();
    this.restaurar = this.restaurar.bind(this);
  }

  mirrorState(key) {
    console.log('mirrorState', key)
    this.setState(key);
  }
  async restaurar(p, after) {
    
    await this.ws.restauraprecadastro(p.id);
    if (after) {
      after();
    }
  }
  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Precadastro",
          tipo_plural: "Precadastro",
          href: "pre_cadastro_backup",
          titulo: "Backup de précadastro (ADMIN ONLY)",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'pre_cadastro_backup',
            list: true,
            save: true,
            edit: true,
            delete: true,
            listSub: true,
            defaultorder: [{ id: "-id" }],
            outros: [
              {icone: 'FaUsers', tamanho: 30, cor: 'blue', texto: 'Restaura Cadastro', funcao:this.restaurar.bind(this)}],
          },
          campos: [
            {
              ref: "id",
              label: "id",
              type: "justlabel",
            },
            {
              ref: "datadora",
              label: "data",
              type: "justlabel",
            },
            {
              ref: "reqbody",
              label: "body",
              type: "jsonlist",
            },
            {
              ref: "reparam",
              label: "param",
              type: "jsonlist",
            },
          ],
          table: {
            colunas: [
              {
                
                Header: "id",
                accessor: "id",
              },
              {
                Header: "data",
                accessor: "datadora",
              },
              {
                Header: "cpf",
                accessor: "reqbody",
                Cell: row => {
                  let info = JSON.parse(row.original.reqbody);
                  return (
                  <div>{info.cpf}</div>
                )},
              },
              {
                Header: "nome",
                accessor: "reqbody",
                Cell: row => {
                  let info = JSON.parse(row.original.reqbody);
                  return (
                  <div>{info.nome_completo}</div>
                )},
              },
            ],
          }
        }}
      />
    );
  }
}
export default PrecadastrobkpNew;
