import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import { Row, OverlayTrigger, Popover, Image, Checkbox, Modal, Breadcrumb, Button, Form, FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import moment from 'moment';
import "react-table/react-table.css";

class Payments extends Component {

  constructor(props) {
    super(props);
    this.state = {
      corridas: false,
      corridalista: [],
      motoristas: false,
      motoristaslista: [],
    }
    this.webservice = new WS();
  }

  mirrorState(key) {
    this.setState(key);
  }
  
  outras(p) {
    console.log('outras', p)
  }

  async efetuaPagamento(p, after) {
    console.log('corridas 1', p)
    await this.webservice.abl_efetuaPagamento(p.id)
    if (after) {
      after()
    }
  }

  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Pagamento",
          tipo_plural: "Pagamentos",
          href: "payments",
          titulo: "Histórico de Pagamentos",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'payments',
            list: true,
            save: false,
            edit: false,
            delete: false,
            listSub: true,
            outras: [{icone: 'FaUsers', tamanho: 30, cor: 'blue', funcao:this.efetuaPagamento.bind(this)}],
            defaultorder: [{ id: "status" }, { id: "id" }],
            defaultfilter: [{id: "status", value: "0"}],
          },
          subtipos: [
            {tipo: 'status',p:'oarray', array:[{ txt: 'Nova', id: 0 },
            { txt: 'Paga', id: 1 },
            { txt: 'Paga', id: 3 },
            { txt: 'Cancelada', id: 9 }]},
          ],
          campos: [
            {
              ref: "id",
            label: "id",
            type: "justlabel",

          },
          {
            ref: "tipo",
            label: "tipo",
            type: "justlabel",

          },
          {
            ref: "subtipo",
            label: "subtipo",
            type: "justlabel",

          },
          {
            ref: "idcontrole",
            label: "idcontrole",
            type: "justlabel",

          },
          {
            ref: "idfatura",
            label: "idfatura",
            type: "justlabel",

          },
          {
            ref: "central",
            label: "central",
            type: "justlabel",

          },
          {
            ref: "referencia",
            label: "referencia",
            type: "justlabel",

          },
          {
            ref: "uuid",
            label: "uuid",
            type: "justlabel",

          },
          {
            ref: "parametros",
            label: "parametros",
            type: "justlabel",

          },
          {
            ref: "retorno",
            label: "retorno",
            type: "justlabel",

          },
          {
            ref: "response",
            label: "response",
            type: "justlabel",

          },
          {
            ref: "status",
            label: "status",
            type: "justlabel",

          },
          {
            ref: "creationdate",
            label: "creationdate",
            type: "justlabel",

          },
          {
            ref: "responsedate",
            label: "responsedate",
            type: "justlabel",

          },
          {
            ref: "valor",
            label: "valor",
            type: "justlabel",

          },
          {
            ref: "validade",
            label: "validade",
            type: "justlabel",

          },
          ],
          botoes: [

          ],
          table: {
            colunas: [
              {
                Header: "id",
                accessor: "id",
              },
              { 
                Header: "central",
                accessor: "central",
              },
              {
                Header: "creationdate",
                accessor: "creationdate",
                Cell: row => {
                  return (
                    <div>
                      {moment(row.original.creationdate).format("DD/MM/YYYY HH:mm:SS")}
                    </div>
                  )
                }
              },                            
              {
                Header: "Valor",
                id: "valor",
                accessor: d => <NumberFormat value={Number(d.valor)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />,
              },
              {
                Header: "Situação",
                accessor: "status",
                width: 150,
                Cell: row => {
                  return (<div>{row.original.status === 0 ? 'Nova' : row.original.status === 1 ? 'Pago' : row.original.status === 9 ? 'Cancelada' : '-'}</div>)
                },
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : 0}
                  >
                    <option value={0}>Nova</option>
                    <option value={1}>Pago</option>
                    <option value={9}>Cancelada</option>
                  </select>
              },
            ],
          }
        }}
      />
    );
  }
}
export default Payments;
