import React, { Component } from 'react';
import WS from '../services/webservice';
import ReactTable from "react-table";
import "react-table/react-table.css";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Modal, Row, Col, FormGroup, ControlLabel } from 'react-bootstrap'
import DadosReclamacao from './DadosReclamacao.js';


class PesquisaReclamacoes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, "months"),
      endDate: moment().add(1, "hours"),
      showDados: false,
    }

    this.dadosHide = this.dadosHide.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.callInside = this.callInside.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() { }

  async fetchData(dados, instance) {
    // show the loading overlay
    this.setState({ loading: true, dados: dados, instance: instance })
    let dateStart = moment(this.state.startDate).utc().format("YYYY-MM-DD HH:mm");
    let dateEnd = moment(this.state.endDate).utc().format("YYYY-MM-DD HH:mm");
    let paginas = dados.page + 1;
    // fetch your data
    let result = await this.ws.getReclamacoes(dateStart, dateEnd, dados.sorted, paginas, dados.pageSize, dados.filtered);
    let count = result.headers.get('X-Total-Count');
    result = await result.json();

    this.setState({
      reclamacoes: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false
    })
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => { this.fetchData(dados, instance) }, 300)
    });
  }

  handleStartDateChange(date) {
    this.setState({
      startDate: date,
    })
    this.fetchData(this.state.dados, this.state.instance);
  }

  callInside(){
    this.fetchData(this.state.dados, this.state.instance);
  }
  handleEndDateChange(date) {
    this.setState({
      endDate: date,
    })
    this.fetchData(this.state.dados, this.state.instance);
  }

  dadosHide() {
    this.setState({ showDados: false, reclamacao: null });
  }

  render() {
    //Change the current language to portuguese BR
    require('moment/locale/pt-br.js')
    moment.locale('pt-br')

    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={this.props.modalHide}
        container={this}
        aria-labelledby="contained-modal-title"
        dialogClassName='pesquisa-corridas-dialog-modal'
      >
        <Modal.Body>
          <Row>
            <Col xs={12} md={3}>
              <FormGroup>
                <ControlLabel>Data hora início</ControlLabel>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={60}
                  dateFormat="DD/MM/YYYY HH:mm"
                  timeCaption="time"
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={3}>
              <FormGroup>
                <ControlLabel>Data hora fim</ControlLabel>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  showTimeSelect
                  minDate={moment(this.state.startDate)}
                  timeFormat="HH:mm"
                  timeIntervals={60}
                  dateFormat="DD/MM/YYYY HH:mm"
                  timeCaption="time"
                />
              </FormGroup>
            </Col>
          </Row>
          <br /><br />
          <ReactTable
            manual
            data={this.state.reclamacoes}
            pages={this.state.pages}
            loading={this.state.loading}
            onFetchData={this.fetchDataTimeOut}
            previousText='Anterior'
            nextText='Próxima'
            loadingText='Carregando...'
            noDataText='Nenhum dado encontado'
            pageText='Página'
            ofText='de'
            rowsText='linhas'
            getTdProps={(state, props, column) => {
              return {
                style: {
                  cursor: "pointer",
                },
                onClick: (e) => {
                  if(props && props.original){
                    this.setState({showDados: true, reclamacao: props.original});
                  }
                },
              }
            }}
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
            columns={[
              {
                Header: "QRU",
                accessor: "corrida_id",
                width: 80,
              },
              {
                id: "datahora",
                Header: "Data/Hora",
                accessor: d => d.datahora ? moment(d.datahora).format("DD/MM/YYYY HH:mm") : "Sem Data Hora",
                width: 130,
              },
              {
                id: "nome_motorista",
                Header: "Motorista",
                accessor: d => d.t08_motoristas_t08_nomemotorista === "nada" ? "Sem motorista" : d.t08_motoristas_t08_nomemotorista,
                width: 140,
              },
              {
                id: "sobre",
                Header: "Sobre",
                accessor: d => d.corrida_id ? "Corrida" : "Motorista",
                width: 100,
              },
              {
                Header: "Tipo de Reclamação",
                accessor: "tiporeclamacao_descricao",
                width: 200,
              },
              {
                Header: "Descrição",
                accessor: "descricao"
              },

            ]}
            defaultPageSize={10}
            className="-striped -highlight"
          />
          
          <DadosReclamacao
            show={this.state.showDados}
            hide={this.dadosHide}
            depProp={this.props.depProp}
            nivProp={this.props.nivProp}
            staProp={this.props.staProp}
            reclamacao={this.state.reclamacao}
            callInside={this.callInside}
          />
        </Modal.Body>
      </Modal>
    );
  }
}
export default PesquisaReclamacoes;