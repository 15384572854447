import React, { Component } from 'react';
import WS from '../services/webservice';
import { CSSTransitionGroup } from 'react-transition-group'
import Loader from 'react-loader';
import Push from 'push.js';
import './Corridas.css';
import { fitBounds } from 'google-map-react/utils';
//import GoogleMapReact from 'google-map-react';
import Notification from './MsgNotification.js';
import SacNotigication from './SacNotigication.js';
import SacNotigicationPiloto from './SacNotigicationPiloto.js';
import SacNotigicationCenter from './SacNotigicationCenter.js';
import { BrowserRouter as Router } from "react-router-dom";
import moment from 'moment';
import Mensagens2 from './Mensagens2.js';
import Mensagens from './Mensagens.js'
import MenuMotorista from './MenuMoto.js'
import { Card, CardBody, CardTitle, CardText, CardFooter } from 'reactstrap';
import { Modal, Table, Breadcrumb, Row, Col, DropdownButton, MenuItem, Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import Buscamoto from './Buscamoto.js';
import ReactDOM from 'react-dom';
import Parametro from './Parametro.js';
import Despacho from './Despacho.js';
import PesquisaCorridas from './PesquisaCorridas.js';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, DirectionsRenderer } from "react-google-maps"
import { FloatingMenu, MainButton, } from 'react-floating-button-menu';
import { MdClose, MdSearch } from 'react-icons/md';
import Sidebar from "react-sidebar";

import GoMap from './GoMap.js';


//import Dialog from 'react-bootstrap-dialog-v2';

const { compose, withProps, lifecycle } = require("recompose");

var _carro;
var _cliente;
var _corrida;
var _pagto;

var interval;

var estiloRodrigo;

var central;
var solicitante;
var empresa;

var FotoMoto = require('../images/taxi-laranja.png')


class Corridas extends Component {

  static defaultProps = {
    center: { lat: -25.47, lng: -49.29 },
    zoom: 16
  };

  constructor(props) {
    super(props);
    this.state = {
      trocar: true,
      showqrvavaliacao: false,
      notificacaomsg: true,
      time: '',
      distance2: '',
      showParametros: false,
      showDespacho: false,
      tipoenvio: 5,
      pesquisaCorridasShow: false,
      menuMotoShow: false,
      abertomsg: false,
      menuMotoAT: '',
      isOpenm: false,
      corridas: [],
      sac: {sac: [], mot: []},
      mensagens: [],
      enviando: "",
      enviando2: null,
      tipo_msg: [],
      respostas: [],
      mensagemcarregada: [],
      showRtDetails: false,
      showMsgDetails: false,
      mensagematual: [],
      distance: '',
      distanceValue: '',
      valorTabelada: '',
      band1: '',
      band1Desc: '',
      band2: '',
      band2Desc: '',
      lpreco: false,
      executivoB1: '',
      executivoB1Desc: '',
      executivoB2: '',
      executivoB2Desc: '',
      PC: 0,
      op1: "1",
      op2: "1",
      op3: "1",
      opt1: false,
      opt2: false,
      telmask: '(99) 9999-9999',
      celmask: '(99) 99999-9999',
      FPC: 0,
      FPQRL: 0,
      FPE: 0,
      FPQRV: 0,
      PQRL: 0,
      cpf: '',
      param: 1,
      param2: false,
      foco: {},
      loaded: false,
      numero: '1',
      height: 100,
      error: null,
      selection: '',
      value2: '',
      selected: [],
      selectedz: [],
      center: {
        lat: -25.47,
        lng: -49.29,
      },
      orig: {
        latitude: null,
        longitude: null,
      },
      dest: {
        latitude: null,
        longitude: null,
      },
      motorista: {
        latitude: null,
        longitude: null,
      },
      zoom: 16,
      msgOpen: false,
      estadobotao: false,

    };
    this.child = React.createRef();
    this.updateData = this.updateData.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClick2 = this.handleClick2.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseqrv = this.handleCloseqrv.bind(this);
    this.cancelTaxi = this.cancelTaxi.bind(this);
    this.handleCancelALL = this.handleCancelALL.bind(this);
    this.salvaValor = this.salvaValor.bind(this);
    this.chanceRT = this.chanceRT.bind(this);
    this.BuscarRT = this.BuscarRT.bind(this);
    this.handleBusca = this.handleBusca.bind(this);
    this.despachocorrida = this.despachocorrida.bind(this);
    this.manageShortcut = this.manageShortcut.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.QrlOp = this.QrlOp.bind(this);
    this.QrvOp = this.QrvOp.bind(this);
    this.Reenviar = this.Reenviar.bind(this);
    this.tmsg = this.tmsg.bind(this);
    this.webservice = new WS();
    this.showParam = this.showParam.bind(this);
    this.showDespacho = this.showDespacho.bind(this);
    this.attParam = this.attParam.bind(this);
    this.atualizarPosMot = this.atualizarPosMot.bind(this);
    this.chamafunc = this.chamafunc.bind(this);
    this.handleShowCancel = this.handleShowCancel.bind(this)
    this.handleCloseCancel = this.handleCloseCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.notificacaomsg = this.notificacaomsg.bind(this);
    this.showQrv = this.showQrv.bind(this);
    this.openShowMsg = this.openShowMsg.bind(this);
  }


  setDistance(directions) {
    this.setState({
      distance2: directions.routes[0].legs[0].distance.text,
      time: directions.routes[0].legs[0].duration.text
    })
  }

  hidePesquisasCorridas() {
    this.setState({ pesquisaCorridasShow: false })
  }

  menuMotoHide() {
    this.setState({ menuMotoShow: false });
  }

  changeMenuMotoAT(at) {
    this.setState({ menuMotoAT: at });
  }

  getRef(referencia) {
    this.setState({
      foco: referencia,
    })
  }

  async attParam() {
    let iparame = await this.webservice.getParametro();
    this.setState({
      FPC: iparame.tempopendencia,
      FPQRL: iparame.tempoqrl,
      FPQRV: iparame.tempoqrv,
      FPE: iparame.tempoemfrente,
    })
  }

  onchangecpf(cpf) {
    this.setState({ cpf: cpf })
  }
  despachocorrida() {
    this.webservice.despacho(this.state.selected.id, this.state.cpf);
    // //console.log(mostra);
  }
  notificacaomsg() {
    this.setState({notificacaomsg: !this.state.notificacaomsg});
  }
  async componentDidMount() {
    interval = setInterval(() => {
      this.setState({ trocar: !this.state.trocar }, () => {
        estiloRodrigo = { cursor: 'pointer', backgroundColor: this.state.trocar ? 'rgb(255,208,39)' : 'rgb(255,81,38)' };
      })
    }, 1000);
    let permission = Push.Permission.has();
    if (!permission) { Push.Permission.request(); }
    //let testando = window
    console.log("window", window)
    //Push.create('Hello World!')

    ReactDOM.findDOMNode(this.refs.content_div).focus();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push("/");
    }
    this.timerId = setInterval(this.updateData, 25000);
    await this.updateData();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    let central = this.webservice.getCookie('central-id');
    //console.log("centrais", this.props.childProps.userAttrib)
    // //console.log('CENTRAL', central);
    if (central) {
      // //console.log('TESTE CORRIDAS');
      this.webservice.getOptions('carro').then(result => _carro = result)
      this.webservice.getOptions('cliente').then(result => _cliente = result)
      this.webservice.getOptions('corrida').then(result => _corrida = result)
      this.webservice.getOptions('pagto').then(result => _pagto = result)
      let cancelamentos = await this.webservice.getCancel()
      //console.log("UEFIAOO", cancelamentos)
      let res = await this.webservice.tipo_msg()
      // //console.log("res", res)

      this.setState({
        tipo_msg: res,
        tipo_cancel: cancelamentos
      })
    }
  }
  componentWillUnmount() {
    console.log('UNMONTED')
    clearInterval(this.timerId);
    clearInterval(interval);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    var h = window.innerHeight / 1.2;
    this.setState({ height: h });

  }

  handleKeyDown = function (e) {
    if (e.key === 13) {
      e.preventDefault();
      this.handleSend();
    }
  };

  async handleSend() {

    try {
      //mexer aqui
      let altf = '';

      this.state.respostas.map((resp, i) => {
        if (i === 0) {
          altf += resp
        } else {
          altf += "," + resp
        }
        return null;
      })
      let re = /([1-9]+:)([1-9]+)/
      let ncpf = this.state.mensagematual.cpf
      let ref = ncpf.replace(re, '$2')

      // //console.log("teste1", altf)
      let tev = /<>/
      let nmsg = null
      //console.log("SOU O TIPO", this.state.tipoenvio)
      if (this.state.tipoenvio === 3) {
        nmsg = this.state.enviando2.replace(tev, this.state.selected.endereco)
      }
      if (this.state.tipoenvio === 2) {
        nmsg = this.state.enviando2.replace(tev, this.state.enviando)
      }
      if (this.state.tipoenvio === 1) {
        nmsg = this.state.enviando2
      }
      //console.log("MENSAGEM POHA", nmsg)
      await this.webservice.newmsg(this.state.selected.motorista.central_id, 'mensagens/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cpf: ref,
          uuid: this.state.mensagematual.uuid,
          at: this.state.mensagematual.at,
          rt: this.state.mensagematual.rt,
          textolivre: nmsg ? nmsg + "" : this.state.enviando,
          remetente: "monitor",
          alternativas: ((altf !== '') ? altf : " OK "),
        })
      })
      //console.log("mensagem linda", this.state.mensagematual);
      this.setState({
        enviando: '',
        enviando2: null,
        tipoenvio: 5
      });
      this.handleClick2(this.state.selected.motorista.central_id, ref)
      //this.handleClick2(this.state.mensagematual.cpf);
    } catch (err) {
      this.setState({ error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }

  salvaValor(e) {
    var value = e.target.value;
    this.setState({
      enviando: value
    })
  }

  async updateData() {
    try {
      //console.log("YO SOY PROPS", this.props.childProps.userAttrib)
      let thetoken = this.props.childProps.userAttrib.admin
      //console.log("soy o cookie", thetoken)
      await this.attParam();
      let result
      if (thetoken === true) {
        result = await this.webservice.fetch2('corrida?corridaPendente=0&qrl=0&qrv=0&centrais=' + this.props.childProps.userAttrib.centralString.ids);

      } else {
        result = await this.webservice.fetch2('corrida?corridaPendente=' + this.state.FPC + '&qrl=' + this.state.FPQRL + '&qrv=' + this.state.FPQRV + '&centrais=' + this.props.childProps.userAttrib.centralString.ids);
      }
      var corrid = await result.json();
      this.setState({ error: null, loaded: true });
      //let result2 = await this.webservice.fetch2('mensagens?centrais=' + this.props.childProps.userAttrib.centralString.ids);
      // let sac = await this.webservice.checksac(this.props.childProps.userAttrib.centralString.ids);
      // console.log('checksac',sac)
      //var msg = await result2.json();
      //let result3 = await this.webservice.fetch2('corrida?corridaPendente=' + this.state.FPC +'&qrv=120000&centrais=' + this.props.childProps.userAttrib.centralString.ids);
      //var corrid2 = await result3.json();
      ////console.log("dando update novo vamo ver o que da", corrid2)
      //console.log("UPDATE DA MSG", corrid)
      this.setState({
        corridas: corrid,
        mensagens: [],
      })

      if (this.state.showRtDetails === true && this.state.selected.motorista) {
        this.handleClick2(this.state.selected.motorista.central_id, this.state.selected.motorista.cpf);
      }


    } catch (err) {
      this.setState({ loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })

    }
  }
  async handleBusca() {
    if (!this.state.buscaRt && !this.state.buscaAT) {
      return;
    }
    try {
      var busca = "";

      if (this.state.buscaRt.length === 4) {
        busca = "?at=" + this.state.buscaRt;
      } else if (this.state.buscaRt.length === 3) {
        busca = "?rt=" + this.state.buscaRt;
      } else if (this.state.buscaRt.forceRt) {
        busca = "?at=" + this.state.buscaRt;
      } else {
        busca = "?rt=" + this.state.buscaRt;
      }
      let result = await this.webservice.fetch2('motoristas/search' + busca);
      var msgc = await result.json(result);

      this.setState({
        motoristas: msgc
      })
      this.setState({ error: null, loadedbusca: true });
    } catch (err) {
      this.setState({ loadedbusca: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }


  async handleClick2(central, key) {
    try {
      let result = await this.webservice.newmsg(central, 'mensagens?cpf=' + key);
      // //console.log("opa olha a key", key)

      var msgc = await result.json(result.result);
      //console.log("faco parte do erro", msgc)
      // //console.log("msgc", msgc)
      var msgAT = msgc[0];
      this.setState({
        mensagemcarregada: msgc,
        mensagematual: msgAT,
        showMsgDetails: true,
      });
    } catch (err) {
      this.setState({ error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }

  async handleCancelALL() {
    var r = window.confirm("Você tem certeza? VAI DEMORAR!!!");
    if (r === true) {
      try {
        clearInterval(this.timerId);
        let result = await this.webservice.fetch2('corrida?corridaPendente=10&qrl=10&qrv=10');
        //this.setState({error: null, loaded: true})
        let corrid = await result.json();
        let centralID = this.webservice.getCookie('central-id');
        for (var c in corrid) {
          if (corrid[c].id) {
            let response = await this.webservice.cancelTaxi(corrid[c].id, this.props.childProps.userAttrib.nome, centralID).result;
            console.log(response);
          }
        }
        alert("AGUARDE MAIS TEMPO");
        this.timerId = setInterval(this.updateData, 25000);
      } catch (err) {
        this.setState({ loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })

      }
    }
  }

  handleChange() {
    this.setState({ selection: this.inputEl.value });
  }

  chanceRT(e) {
    var value = e.target.value;
    this.setState({
      buscaRt: value
    })
  }
  async handleClick(key) {
    let chave = await this.webservice.fetch2('corridas/' + key)
    let chavez = await this.webservice.fetch2('corridaz/' + key)
    chave = await chave.json();
    chavez = await chavez.json();
    //console.log("chaveee", chave)
    let posmot = await this.webservice.getMotRandom(chave.randomcode)
    console.log("posicao do motorista", posmot)
    if (chave.motorista) {
      //aqui colocar funcao pra ficar chamando quando tiver motorista

      var msg = [];
      msg.cpf = chave.motorista.cpf;
      msg.rt = chave.motorista.rt;
      msg.at = chave.motorista.at;
      msg.uuid = chave.motorista.uuid;
      // //console.log("enviando o cpf", msg)

      this.handleClick2(chave.motorista.central_id, chave.motorista.cpf);
    }
    //console.log('chave', chave)
    this.setState({
      selected: chave,
      selectedz: chavez,
      showRtDetails: true,
      center: {
        lat: chave.latitude,
        lng: chave.longitude,
      },
      orig: {
        latitude: chave.latitude,
        longitude: chave.longitude,
      },
      dest: {
        latitude: chave.latitudedestino,
        longitude: chave.longitudedestino,
      },
      motorista: {
        latitude: chave.motorista && chave.motorista.latitude,
        longitude: chave.motorista && chave.motorista.longitude,
      },
      zoom: 16,
      mensagematual: msg
    }, () => {
      central = null;
      if (this.state.selected.central_id) {
        this.webservice.getCentral(this.state.selected.central_id).then(result => {
          central = result;
        })
      }
      solicitante = null;
      if (this.state.selected.voucher_func_solicitante) {
        this.webservice.getUser(this.state.selected.voucher_func_solicitante).then(result => {
          solicitante = result;
        })
      }
      empresa = null;
      if (this.state.selected.empresa) {
        this.webservice.getEmpresas(this.state.selected.empresa).then(result => {
          empresa = result;
        })
      }
      if (this.state.selected.latitudedestino) {
        this.getCenter(this.state.selected.latitude, this.state.selected.longitude, this.state.selected.latitudedestino, this.state.selected.longitudedestino)
      } else {
        if (this.state.selected.motorista) {
          this.getCenter2(this.state.selected.latitude, this.state.selected.longitude, this.state.selected.motorista.latitude, this.state.selected.motorista.longitude)
        }
        this.setState({
          center: {
            lat: this.state.selected.latitude,
            lng: this.state.selected.longitude,
          },
        })
      }
    });

  }
  handleCloseqrv() {
    this.setState({
      showqrvavaliacao: false
    })
  }
  showQrv() {
    this.setState({
      showqrvavaliacao: true,
      avalia_clie: 5,
      avalia_comentario: ''
    })
  }

  handleClose() {
    this.setState({
      showRtDetails: false,
      showMsgDetails: false,
      param: false,
      estadobotao: false,

    })
    clearInterval(this.intervalo)
  }

  BuscarRT(e) {
    //var value = e.target.value;
    this.handleBusca();
    return true
  }

  renderMarkers(map, maps) {
    let marker = new maps.Marker({
      position: { lat: this.state.selected.latitude, lng: this.state.selected.longitude },
      map: map,
      icon: require('../images/red-marker.png'),
      title: 'Cliente!'
    });
    console.log(marker);
    if (this.state.selected.motorista) {
      let marker2 = new maps.Marker({
        position: { lat: this.state.selected.motorista.latitude, lng: this.state.selected.motorista.longitude },
        map: map,
        icon: require('../images/motorista.png'),
        title: 'Motorista!'
      });
      console.log(marker2);
    }
    if (this.state.selected.latitudedestino) {
      let marker3 = new maps.Marker({
        position: { lat: this.state.selected.latitudedestino, lng: this.state.selected.longitudedestino },
        map: map,
        icon: require('../images/cliente.png'),
        title: 'Destino!'
      });
      console.log(marker3)
    }
    if (this.state.selected.latitudedestino) {
      this.getCenter(this.state.selected.latitude, this.state.selected.longitude, this.state.selected.latitudedestino, this.state.selected.longitudedestino)
    } else {
      if (this.state.selected.motorista) {
        this.getCenter2(this.state.selected.latitude, this.state.selected.longitude, this.state.selected.motorista.latitude, this.state.selected.motorista.longitude)
      }
      this.setState({
        center: {
          lat: this.state.selected.latitude,
          lng: this.state.selected.longitude,
        },
      })
    }
  }

  handleShowCancel() {
    this.setState({
      showCancel: true,
      value2: '',
    })
  }

  handleCloseCancel() {
    this.setState({
      showCancel: false,
    })
  }


  async cancelTaxi() {
    var r = window.confirm("Você tem certeza?");
    //console.log("cancelamento da corrida", this.state.selected)
    if (r === true && this.state.selection !== '') {
      try {
        await this.webservice.cancelTaxi(this.state.selected.id, this.props.childProps.userAttrib.nome, this.state.selected.central_id, this.state.value2, this.state.selection).result;
        this.setState({
          showRtDetails: false,
          showCancel: false,
        })
      } catch (err) {
      }
      alert("Corrida Cancelada");
      this.updateData();
    } else {
      alert("Preencha os campos faltantes")
    }
  }

  getCenter2(latA, lngA, latB, lngB) {
    const bounds = {
      ne: {
        lat: latA,
        lng: lngA,
      },
      sw: {
        lat: latB,
        lng: lngB,
      }
    }

    if (latA > bounds.ne.lat) {
      bounds.ne.lat = latA;
    }
    if (lngA > bounds.ne.lng) {
      bounds.ne.lng = lngA;
    }
    if (latA < bounds.sw.lat) {
      bounds.sw.lat = latA;
    }
    if (lngA < bounds.sw.lng) {
      bounds.sw.lng = lngA;
    }

    if (latB > bounds.ne.lat) {
      bounds.ne.lat = latB;
    }
    if (lngB > bounds.ne.lng) {
      bounds.ne.lng = lngB;
    }
    if (latB < bounds.sw.lat) {
      bounds.sw.lat = latB;
    }
    if (lngB < bounds.sw.lng) {
      bounds.sw.lng = lngB;
    }


    const size = {
      width: 550, // Map width in pixels
      height: 253, // Map height in pixels
    };

    const { center, zoom } = fitBounds(bounds, size)
    this.setState({
      center: center,
      zoom: zoom,
    })

    //Set the distance from local position to destination
  }

  getCenter(latA, lngA, latB, lngB) {
    const bounds = {
      ne: {
        lat: latA,
        lng: lngA,
      },
      sw: {
        lat: latB,
        lng: lngB,
      }
    }

    if (latA > bounds.ne.lat) {
      bounds.ne.lat = latA;
    }
    if (lngA > bounds.ne.lng) {
      bounds.ne.lng = lngA;
    }
    if (latA < bounds.sw.lat) {
      bounds.sw.lat = latA;
    }
    if (lngA < bounds.sw.lng) {
      bounds.sw.lng = lngA;
    }

    if (latB > bounds.ne.lat) {
      bounds.ne.lat = latB;
    }
    if (lngB > bounds.ne.lng) {
      bounds.ne.lng = lngB;
    }
    if (latB < bounds.sw.lat) {
      bounds.sw.lat = latB;
    }
    if (lngB < bounds.sw.lng) {
      bounds.sw.lng = lngB;
    }


    const size = {
      width: 550, // Map width in pixels
      height: 253, // Map height in pixels
    };

    const { center, zoom } = fitBounds(bounds, size)
    this.setState({
      center: center,
      zoom: zoom,
    })

    //Set the distance from local position to destination

    this.webservice.directions(bounds.ne.lat, bounds.ne.lng, bounds.sw.lat, bounds.sw.lng).then(result => {
      this.setState({ distance: result.text })
      this.setState({ distanceValue: result.value }, () => this.calculatePrices())
    })
  }


  calculatePrices() {
    if (this.state.distanceValue === '') {
      this.setState({
        situation: 'Por favor preencha com endereço local e destino!',
        situationStyle: {
          color: 'red',
        },
      })
      return;
    }

    const bandeirada = 5.4
    const bandeiradaExecutivo = 7.2
    const valorB1 = 2.7
    const valorB2 = 3.3
    const valorExecutivoB1 = 3.6
    const valorExecutivoB2 = 4.4

    const dist = this.state.distanceValue / 1000
    let band1 = bandeirada + (dist * valorB1)
    let band2 = bandeirada + (dist * valorB2)
    let band1Desc = band1 * (this.state.selected && (1 - (this.state.selected.desconto / 100)))
    let band2Desc = band2 * (this.state.selected && (1 - (this.state.selected.desconto / 100)))
    let executivoB1 = bandeiradaExecutivo + (dist * valorExecutivoB1)
    let executivoB1Desc = executivoB1 * (this.state.selected && (1 - (this.state.selected.desconto / 100)))
    let executivoB2 = bandeiradaExecutivo + (dist * valorExecutivoB2)
    let executivoB2Desc = executivoB2 * (this.state.selected && (1 - (this.state.selected.desconto / 100)))

    band1 = band1.toFixed(2)
    band2 = band2.toFixed(2)
    band1Desc = band1Desc.toFixed(2)
    band2Desc = band2Desc.toFixed(2)
    executivoB1 = executivoB1.toFixed(2)
    executivoB2 = executivoB2.toFixed(2)
    executivoB1Desc = executivoB1Desc.toFixed(2)
    executivoB2Desc = executivoB2Desc.toFixed(2)

    band1 = band1.toString();
    band2 = band2.toString();
    band1Desc = band1Desc.toString();
    band2Desc = band2Desc.toString();
    executivoB1 = executivoB1.toString();
    executivoB2 = executivoB2.toString();
    executivoB1Desc = executivoB1Desc.toString();
    executivoB2Desc = executivoB2Desc.toString();

    band1 = band1.replace('.', ',')
    band2 = band2.replace('.', ',')
    band1Desc = band1Desc.replace('.', ',')
    band2Desc = band2Desc.replace('.', ',')
    executivoB1 = executivoB1.replace('.', ',')
    executivoB2 = executivoB2.replace('.', ',')
    executivoB1Desc = executivoB1Desc.replace('.', ',')
    executivoB2Desc = executivoB2Desc.replace('.', ',')

    this.setState({
      lpreco: true,
      valorTabelada: band1,
      band1: band1,
      band1Desc: band1Desc,
      band2: band2,
      band2Desc: band2Desc,
      executivoB1: executivoB1,
      executivoB1Desc: executivoB1Desc,
      executivoB2: executivoB2,
      executivoB2Desc: executivoB2Desc,
    })
  }
  openShowMsg() {
    this.setState({ isOpenm: !this.state.isOpenm});
  }
  manageShortcut(e) {
    let code = (e.keyCode ? e.keyCode : e.which);
    console.log("ABERTOOO", this.state.abertomsg)
    console.log(this.state.abertomsg, this.state.numero === '1', this.state.menuMotoShow, this.state.showRtDetails, this.state.showMsgDetails, this.state.msgOpen, this.state.param2, this.state.pesquisaCorridasShow, this.state.isOpenm);
    //SHORTCUT: P
    if (code === 80 && !this.state.abertomsg && this.state.numero === '1' && !this.state.menuMotoShow && !this.state.showRtDetails && !this.state.showMsgDetails && !this.state.msgOpen && !this.state.param2 && !this.state.pesquisaCorridasShow && !this.state.isOpenm) {
      e.preventDefault(); e.stopPropagation();
      this.setState({ pesquisaCorridasShow: true });
    }

    //SHORTCUT: M
    if (code === 77 && !this.state.abertomsg && this.state.numero === '1' && !this.state.menuMotoShow && !this.state.showRtDetails && !this.state.showMsgDetails && !this.state.msgOpen && !this.state.param2 && !this.state.pesquisaCorridasShow && !this.state.isOpenm) {
      this.setState({
        isOpenm: true
      })
      console.log("get low", this.state.foco.focar)
      //ReactDOM.findDOMNode(this.state.foco.focar).focus();

    }

    if (code === 27) {
      this.setState({
        isOpenm: false,
        msgOpen: false,
        showMsgDetails: false
      })
    }

    //SHORTCUT: 0-9
    if (document.activeElement !== ReactDOM.findDOMNode(this.state.foco.focar) && this.state.numero === '1' && !this.state.showRtDetails && !this.state.abertomsg && !this.state.showMsgDetails && !this.state.msgOpen && !this.state.param2 && !this.state.pesquisaCorridasShow && !this.state.isOpenm) {
      if ((code >= 48 && code <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        if (!this.state.menuMotoShow) this.setState({ menuMotoAT: '' })
        this.setState({ menuMotoShow: true })
      }
    }
  }

  async Reenviar() {
    let retorno = await this.webservice.reenviarTaxi(this.state.selected.id, this.props.childProps.userAttrib.nome);
    //console.log("corrida reenviada", retorno)
    if (retorno.ok === false) {
      alert("Ocorreu um erro ao reenviar a corrida")
    } else {
      alert("Corrida reenviada");
    }
    this.handleClose();
  }


  QrlOp() {
    //console.log("usuario atual")
    this.webservice.qrlOp(this.state.selected.id, this.props.childProps.userAttrib.id);
    alert("Qrl feito pelo operador")
    this.handleClose();
  }

  QrvOp() {
    //console.log("usuario atual")

    this.showQrv();

    // this.webservice.qrvOp(this.state.selected.id, this.props.childProps.userAttrib.nome);
    // alert("Qrv feito pelo operador")
    // this.handleClose();
  }
  tmsg() {
    this.child.current.showMensagens()
    this.setState({
      numero: '0',
    })
  }

  handleClose2() {
    this.setState({
      numero: '1',
    })
  }

  showParam() {
    this.setState({ showParametros: true, param2: true, })
  }
  paramHide() {
    this.setState({ showParametros: false, param2: false });
  }

  showDespacho() {
    this.setState({ showDespacho: true, param2: true, })
  }
  despachoHide() {
    this.setState({ showDespacho: false, param2: false });
  }

  changeState(state) {
    console.log('ENTROU AQUI', state)
    this.setState({ msgOpen: state, showMsgDetails: state });
  }

  atualizarPosMot(random) {
    if (this.state.estadobotao === false) {
      this.chamafunc(random)
      this.intervalo = setInterval(async () => this.chamafunc(random), 10000)
    } else {
      //console.log("nao entro aqui")
      clearInterval(this.intervalo)
    }
    this.setState({
      estadobotao: !this.state.estadobotao,
    })
  }

  async chamafunc(random) {
    let testeresp = await this.webservice.getMotRandom(random)
    this.setState({
      motorista: {
        latitude: testeresp.latitude,
        longitude: testeresp.longitude,
      },
    })
    //console.log("novo motorista postion", testeresp)
  }

  msgaberto(resultado) {
    this.setState({
      abertomsg: resultado
    })
  }
  render() {
    return (
      <div ref='content_div' onKeyDown={this.manageShortcut} tabIndex="0" style={{ height: this.state.height }}>
        <div style={{ zIndex: 1 }}>
          <Breadcrumb>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active href="/corridas">Corridas</Breadcrumb.Item>
            {/* {console.log('URL', window.location.href)} */}
            {window.location.href.indexOf("localhost") > -1 &&
              <Breadcrumb.Item>
                <button className="btn btn-warning btn-sm" id="btn-cancel" onClick={this.handleCancelALL}>Cancelar Todas as Corridas</button>
              </Breadcrumb.Item>}
            {(this.props.childProps.userAttrib.admin ||
              (this.props.childProps.userAttrib.acesso['17f8bf1c-a48e-43f5-b652-b0a55faeacc3'] &&
                this.props.childProps.userAttrib.acesso['17f8bf1c-a48e-43f5-b652-b0a55faeacc3'].indexOf('enviar_para_todos'))) > -1 &&
              < Breadcrumb.Item >
                <button className="btn btn-warning btn-sm" id="todos" onClick={this.tmsg}>Enviar para todos</button>
              </Breadcrumb.Item>
            }
            {(this.props.childProps.userAttrib.admin ||
              (this.props.childProps.userAttrib.acesso['17f8bf1c-a48e-43f5-b652-b0a55faeacc3'] &&
                this.props.childProps.userAttrib.acesso['17f8bf1c-a48e-43f5-b652-b0a55faeacc3'].indexOf('parametros'))) > -1 &&
              <Breadcrumb.Item>
                <button className="btn btn-warning btn-sm" id="parametro" onClick={this.showParam}>Parametros</button>
                <Parametro show={this.state.showParametros} modalHide={this.paramHide.bind(this)} />
              </Breadcrumb.Item>
            }
            {this.props.childProps.userAttrib.admin &&
              <Breadcrumb.Item>
                <button className="btn btn-warning btn-sm" onClick={this.showDespacho}>Despacho</button>
                <Despacho show={this.state.showDespacho} modalHide={this.despachoHide.bind(this)} />
              </Breadcrumb.Item>

            }
              <Breadcrumb.Item>
          <button className="btn btn-warning btn-sm" onClick={this.notificacaomsg}>Nofificacão MSG {this.state.notificacaomsg ? '(Ligada)' : '(Desligada)'}</button>
              </Breadcrumb.Item>
          </Breadcrumb>
        </div>



        <div style={{ position: 'fixed', bottom: '25px', marginLeft: 5, left: '5px', zIndex: 100 }}>
          <FloatingMenu
            slideSpeed={500}
            direction="up"
            spacing={8}
            isOpen={this.state.isOpenm}
          >
            <MainButton
              iconResting={<MdSearch style={{ fontSize: 20 }} nativeColor="white" />}
              iconActive={<MdClose style={{ fontSize: 20 }} nativeColor="white" />}
              backgroundColor="black"
              onClick={() => this.setState({ isOpenm: !this.state.isOpenm })}
              size={56}
            />

          </FloatingMenu>
        </div>

        <Mensagens ref={this.child} childProps={this.props.childProps} num={this.state.numero} handleClose={this.handleClose2.bind(this)} />
        {
          this.state.error && (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          )
        }
        <Sidebar
          sidebar={
            <div style={{ marginTop: 5 }}>
              {this.state.isOpenm && <Mensagens2 mymsg={this.state.isOpenm} returnShow={this.openShowMsg} msgaberto={this.msgaberto.bind(this)} getRef={this.getRef.bind(this)} cor={this.state.corridas} changeState={this.changeState.bind(this)} central={this.props.childProps.userAttrib && this.props.childProps.userAttrib} />}
            </div>
          }
          open={this.state.isOpenm}
          styles={{
            sidebar: {
              background: "white",
              width: 400
            },
            root: {
              zIndex: 0,
              position: "inherit",
              display: this.state.isOpenm ? 'inherit' : 'none',
            },
            content: {
              zIndex: 0,
              position: "inherit",
            },
            overlay: {
              zIndex: 0,
            },
            dragHandle: {
              zIndex: 0,
            }
          }}
        >

        </Sidebar>
        <div style={{ zIndex: 0 }}>
          <Row>
            {/* 1 */}
            <Col xs={5} md={3} className="coluna" >

              <Loader loaded={this.state.loaded}>
                <Router>
                  <Table condensed className="espaco">
                    <thead>
                      <tr>
                        <th className="vai"><center>Corridas pendente</center></th>

                      </tr>

                    </thead>
                    <CSSTransitionGroup
                      transitionName="example"
                      component="tbody"
                      transitionEnterTimeout={500}
                      transitionLeaveTimeout={300}>
                      {

                        this.state.corridas.map(corrida => {
                          let diferenca2
                          let estilo
                          let estilo2
                          let centralnome
                          let agora = moment();
                          let datahora = moment(corrida.datahora);
                          let diferenca = moment.duration(moment(agora).diff(moment(datahora)));
                          if (corrida.datahoraagendamento) {
                            let datahoraagenda = moment(corrida.datahoraagendamentoefetivo);
                            diferenca2 = moment.duration(moment(agora).diff(moment(datahoraagenda)));
                          }
                          ////console.log(diferenca.asMinutes());
                          //<p>Status: <Label bsStyle={badge}>{status}</Label></p>
                          //Motorista: {corrida.motorista && corrida.motorista.rt}
                          // MEXER NAS COR
                          if (corrida.datahoraagendamento) {
                            if (moment.utc(diferenca2.asMilliseconds()).format("m") < 4) {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 240, 107)' }
                            } else {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 184, 91)' }
                            }
                          } else {
                            if (moment.utc(diferenca.asMilliseconds()).format("m") > 5) {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 0, 4)' };
                            }
                          }

                          if (!corrida.motorista) {
                            //console.log("TRUE", corrida.endereco)
                          } else {
                            //console.log("false", corrida.endereco)
                          }

                          // //console.log(this.state.corridas);
                          if (!corrida.motorista && (moment.utc(diferenca.asMilliseconds()).format("m") >= (Number(this.state.FPC) / 60000))) {
                            return (
                              <tr key={corrida.id} onClick={() => this.handleClick(corrida.id)}>
                                <td className="vai">
                                  <Card className="cartao" style={estilo} >

                                    <CardBody>
                                      <CardTitle></CardTitle>
                                      <CardText>{!corrida.datahoraagendamento && <font> <font size="3">{moment.utc(diferenca.asMilliseconds()).format("m")}m</font> - <b> {corrida.endereco}</b></font>}
                                        {corrida.datahoraagendamento && <font> <font size="3">{moment.utc(diferenca2.asMilliseconds()).format("m")}m</font> - <b> {corrida.endereco}</b></font>}<br />
                                        {corrida.cidade && <font> {corrida.cidade} </font>}<br />
                                        {corrida.numero_reenvios && <font>Reenviado: {corrida.numero_reenvios}  </font>}
                                        <br />
                                        {(corrida.recusados > 0) && <font>Recusado: {corrida.recusados}</font>}
                                      </CardText>
                                    </CardBody>
                                    {this.props.childProps.userAttrib.centrais.map((central, i) => {
                                      if (central.id === Number(corrida.central_id)) {
                                        estilo2 = { backgroundColor: central.aplicativocor.background, color: central.aplicativocor.foreground }
                                        centralnome = central.nomefantasia
                                        return (
                                          <CardFooter key={i} style={estilo2}>
                                            <center> {centralnome} </center>
                                          </CardFooter>
                                        )
                                      }
                                      else {
                                        return null
                                      }
                                    })}

                                  </Card>

                                </td>
                              </tr>

                            )
                          } else {
                            return null
                          }


                        })
                      }
                    </CSSTransitionGroup>

                  </Table>
                </Router>
              </Loader>
            </Col>
            {/* 2 */}
            <Col xs={5} md={3} className="coluna">
              <Loader loaded={this.state.loaded}>
                <Router>
                  <Table condensed className="espaco" >
                    <thead>
                      <tr>
                        <th className="vai"><center>Pendente em frente</center></th>

                      </tr>

                    </thead>
                    <CSSTransitionGroup
                      transitionName="example"
                      component="tbody"
                      transitionEnterTimeout={500}
                      transitionLeaveTimeout={300}>
                      {
                        this.state.corridas.map(corrida => {
                          let agora = moment();
                          let diferenca2
                          let estilo
                          let centralnome
                          let estilo2
                          let datahora = moment(corrida.dataHoraRecebido);
                          if (corrida.datahoraagendamento) {
                            let datahoraagenda = moment(corrida.datahoraagendamentoefetivo);
                            diferenca2 = moment.duration(moment(agora).diff(moment(datahoraagenda)));
                          }
                          let diferenca = moment.duration(moment(agora).diff(moment(datahora)));
                          //let diferenca3 = moment.duration(moment(agora).diff(moment(corrida.dataHoraEmFrente)));



                          ////console.log(diferenca.asMinutes());
                          //<p>Status: <Label bsStyle={badge}>{status}</Label></p>
                          //Motorista: {corrida.motorista && corrida.motorista.rt}
                          if (corrida.datahoraagendamento) {
                            if (moment.utc(diferenca2.asMilliseconds()).format("m") < 4) {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 240, 107)' }
                            } else {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 184, 91)' }
                            }
                          } else {
                            if (moment.utc(diferenca.asMilliseconds()).format("m") > 5) {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 0, 4)' };
                              ////console.log("entrei aqui e n era")
                            }
                          }




                          if (corrida.motorista && corrida.motorista.rt && !corrida.dataHoraEmFrente && (moment.utc(diferenca.asMilliseconds()).format("m") >= (Number(this.state.FPE) / 60000))) {

                            return (
                              <tr key={corrida.id} onClick={() => this.handleClick(corrida.id)}>
                                <td className="vai">
                                  <Card className="cartao" style={estilo}>
                                    <CardBody>
                                      <CardTitle >Placa: {corrida.motorista && corrida.motorista.placa}</CardTitle>
                                      <CardText>{corrida.datahoraagendamento && <font> <font size="3">{moment.utc(diferenca2.asMilliseconds()).format("m")}m</font> - <b>{corrida.endereco}</b></font>}
                                        {!corrida.datahoraagendamento && <font> <font size="3">{moment.utc(diferenca.asMilliseconds()).format("m")}m</font> - <b>{corrida.endereco}</b></font>}<br />
                                        {corrida.cidade && <font> {corrida.cidade} </font>}
                                        {corrida.numero_reenvios && <font>Reenviado: {corrida.numero_reenvios}  </font>}
                                      </CardText>
                                    </CardBody>
                                    {this.props.childProps.userAttrib.centrais.map(central => {
                                      if (central.id === Number(corrida.central_id)) {
                                        estilo2 = { backgroundColor: central.aplicativocor.background, color: central.aplicativocor.foreground }
                                        centralnome = central.nomefantasia
                                        return (
                                          <CardFooter style={estilo2}>
                                            <center> {centralnome} </center>
                                          </CardFooter>
                                        )
                                      }
                                      else {
                                        return null
                                      }
                                    })}


                                  </Card></td>
                              </tr>
                            )
                          }
                          else {
                            return null
                          }



                        })

                      }
                    </CSSTransitionGroup>
                  </Table>
                </Router>
              </Loader>
            </Col>
            {/* 3 */}
            {/*<Col xs={5} md={4} className="coluna">
              <Loader loaded={this.state.loaded}>
                <Router>
                  <Table condensed className="espaco" >
                    <thead>
                      <tr>
                        <th className="vai"><center>Pendente QRL</center></th>

                      </tr>

                    </thead>
                    <CSSTransitionGroup
                      transitionName="example"
                      component="tbody"
                      transitionEnterTimeout={500}
                      transitionLeaveTimeout={300}>
                      {
                        this.state.corridas.map(corrida => {
                          let agora = moment();
                          let diferenca2
                          let estilo
                          let centralnome
                          let estilo2
                          let datahora = moment(corrida.dataHoraEmFrente);
                          if (corrida.datahoraagendamento) {
                            let datahoraagenda = moment(corrida.datahoraagendamentoefetivo);
                            diferenca2 = moment.duration(moment(agora).diff(moment(datahoraagenda)));
                          }
                          let diferenca = moment.duration(moment(agora).diff(moment(datahora)));
                          //let diferenca3 = moment.duration(moment(agora).diff(moment(corrida.dataHoraEmFrente)));



                          ////console.log(diferenca.asMinutes());
                          //<p>Status: <Label bsStyle={badge}>{status}</Label></p>
                          //Motorista: {corrida.motorista && corrida.motorista.rt}
                          if (corrida.datahoraagendamento) {
                            if (moment.utc(diferenca2.asMilliseconds()).format("m") < 4) {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 240, 107)' }
                            } else {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 184, 91)' }
                            }
                          } else {
                            if (moment.utc(diferenca.asMilliseconds()).format("m") > 5) {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 0, 4)' };
                              ////console.log("entrei aqui e n era")
                            }
                          }




                          if (corrida.motorista && corrida.motorista.rt && !corrida.dataHoraQrl && corrida.dataHoraEmFrente && (moment.utc(diferenca.asMilliseconds()).format("m") >= (Number(this.state.FPQRL) / 60000))) {

                            return (
                              <tr key={corrida.id} onClick={() => this.handleClick(corrida.id)}>
                                <td className="vai">
                                  <Card className="cartao" style={estilo}>
                                    <CardBody>
                                      <CardTitle >AT: {corrida.motorista && corrida.motorista.porta}</CardTitle>
                                      <CardText>{corrida.datahoraagendamento && <font> <font size="3">{moment.utc(diferenca2.asMilliseconds()).format("m")}m</font> - <b>{corrida.endereco}</b></font>}
                                        {!corrida.datahoraagendamento && <font> <font size="3">{moment.utc(diferenca.asMilliseconds()).format("m")}m</font> - <b>{corrida.endereco}</b></font>}<br />
                                        {corrida.cidade && <font> {corrida.cidade} </font>}<br />
                                        {corrida.numero_reenvios && <font>Reenviado: {corrida.numero_reenvios}  </font>}
                                      </CardText>
                                    </CardBody>
                                    {this.props.childProps.userAttrib.centrais.map(central => {
                                      if (central.id === Number(corrida.central_id)) {
                                        estilo2 = { backgroundColor: central.aplicativocor.background, color: central.aplicativocor.foreground }
                                        centralnome = central.nomefantasia
                                        return (
                                          <CardFooter style={estilo2}>
                                            <center> {centralnome} </center>
                                          </CardFooter>
                                        )
                                      }
                                      else {
                                        return null
                                      }
                                    })}


                                  </Card></td>
                              </tr>
                            )
                          }
                          else {
                            return null
                          }



                        })

                      }
                    </CSSTransitionGroup>
                  </Table>
                </Router>
              </Loader>
                    </Col>*/}
            {/* 4 */}
            {<Col xs={5} md={3} className="coluna">
              <Loader loaded={this.state.loaded}>
                <Router>
                  <Table condensed className="espaco" >
                    <thead>
                      <tr>
                        <th className="vai"><center>Corrida não finalizada</center></th>

                      </tr>

                    </thead>
                    <CSSTransitionGroup
                      transitionName="example"
                      component="tbody"
                      transitionEnterTimeout={500}
                      transitionLeaveTimeout={300}>
                      {
                        this.state.corridas.map(corrida => {
                          let agora = moment();
                          let diferenca2
                          let estilo
                          let centralnome
                          let estilo2
                          let datahora = moment(corrida.dataHoraQrl);
                          if (corrida.datahoraagendamento) {
                            let datahoraagenda = moment(corrida.datahoraagendamentoefetivo);
                            diferenca2 = moment.duration(moment(agora).diff(moment(datahoraagenda)));
                          }
                          let diferenca = moment.duration(moment(agora).diff(moment(datahora)));
                          //let diferenca3 = moment.duration(moment(agora).diff(moment(corrida.dataHoraEmFrente)));



                          ////console.log(diferenca.asMinutes());
                          //<p>Status: <Label bsStyle={badge}>{status}</Label></p>
                          //Motorista: {corrida.motorista && corrida.motorista.rt}
                          if (corrida.datahoraagendamento) {
                            if (moment.utc(diferenca2.asMilliseconds()).format("m") < 4) {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 240, 107)' }
                            } else {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 184, 91)' }
                            }
                          } else {
                            if (moment.utc(diferenca.asMilliseconds()).format("m") > 5) {
                              estilo = { cursor: 'pointer', backgroundColor: 'rgb(255, 0, 4)' };
                              ////console.log("entrei aqui e n era")
                            }
                          }




                          if (corrida.motorista && corrida.motorista.rt && !corrida.dataHoraQrv && corrida.dataHoraQrl && corrida.dataHoraEmFrente && (moment.utc(diferenca.asMilliseconds()).format("m") >= (Number(this.state.FPQRV) / 60000))) {

                            return (
                              <tr key={corrida.id} onClick={() => this.handleClick(corrida.id)}>
                                <td className="vai">
                                  <Card className="cartao" style={estilo}>
                                    <CardBody>
                                      <CardTitle >Placa: {corrida.motorista && corrida.motorista.placa}</CardTitle>
                                      <CardText>{corrida.datahoraagendamento && <font> <font size="3">{moment.utc(diferenca2.asMilliseconds()).format("m")}m</font> - <b>{corrida.endereco}</b></font>}
                                        {!corrida.datahoraagendamento && <font> <font size="3">{moment.utc(diferenca.asMilliseconds()).format("m")}m</font> - <b>{corrida.endereco}</b></font>}<br />
                                        {corrida.cidade && <font> {corrida.cidade} </font>}<br />
                                        {corrida.numero_reenvios && <font>Reenviado: {corrida.numero_reenvios}  </font>}
                                      </CardText>
                                    </CardBody>
                                    {this.props.childProps.userAttrib.centrais.map(central => {
                                      if (central.id === Number(corrida.central_id)) {
                                        estilo2 = { backgroundColor: central.aplicativocor.background, color: central.aplicativocor.foreground }
                                        centralnome = central.nomefantasia
                                        return (
                                          <CardFooter style={estilo2}>
                                            <center> {centralnome} </center>
                                          </CardFooter>
                                        )
                                      }
                                      else {
                                        return null
                                      }
                                    })}


                                  </Card></td>
                              </tr>
                            )
                          }
                          else {
                            return null
                          }



                        })

                      }
                    </CSSTransitionGroup>
                  </Table>
                </Router>
              </Loader>
            </Col>}
            {/*5
          <Col xs={4} md={3}  MENSAGENS>


          </Col>
            */}
            {<Col xs={5} md={3} className="coluna">
              <Loader loaded={this.state.loaded}>
                <Router>
                  <Table condensed className="espaco" >
                    <thead>
                      <tr>
                        <th className="vai"><center>ALERTA! Corrida com mais de 1h</center></th>

                      </tr>

                    </thead>
                    <CSSTransitionGroup
                      transitionName="example"
                      component="tbody"
                      transitionEnterTimeout={500}
                      transitionLeaveTimeout={300}>
                      {
                        this.state.corridas.map(corrida => {
                          let agora = moment();
                          let diferenca2
                          //let estilo
                          let centralnome
                          let estilo2
                          let datahora = moment(corrida.datahora);
                          if (corrida.datahoraagendamento) {
                            let datahoraagenda = moment(corrida.datahoraagendamentoefetivo);
                            diferenca2 = moment.duration(moment(agora).diff(moment(datahoraagenda)));
                          }
                          let diferenca = moment.duration(moment(agora).diff(moment(datahora)));
                          //let diferenca3 = moment.duration(moment(agora).diff(moment(corrida.dataHoraEmFrente)));



                          ////console.log(diferenca.asMinutes());
                          //<p>Status: <Label bsStyle={badge}>{status}</Label></p>
                          //Motorista: {corrida.motorista && corrida.motorista.rt}
                          if (corrida.datahoraagendamento) {
                            if (moment.utc(diferenca2.asMilliseconds()).format("H") <= 1) {
                              //estilo = { cursor: 'pointer', backgroundColor: 'rgb(127, 76, 255)' }
                            } else {
                              //estilo = { cursor: 'pointer', backgroundColor: 'rgb(127, 76, 255)' }
                            }
                          } else {
                            if (moment.utc(diferenca.asMilliseconds()).format("H") >= 1) {
                              //estilo = { cursor: 'pointer', backgroundColor: 'rgb(127, 76, 255)' };
                              ////console.log("entrei aqui e n era")
                            }
                          }
                          if ((moment.utc(diferenca.asMilliseconds()).format("H") >= (1))) {
                            return (
                              <tr key={corrida.id} onClick={() => this.handleClick(corrida.id)}>
                                <td className="vai">
                                  <Card className="cartao" style={estiloRodrigo}>
                                    <CardBody>
                                      {corrida.motorista && corrida.motorista.porta && <CardTitle >Placa: {corrida.motorista && corrida.motorista.placa}</CardTitle>}
                                      {!corrida.motorista && <CardTitle ></CardTitle>}

                                      <CardText>{corrida.datahoraagendamento && <font> <font size="3">{moment.utc(diferenca2.asMilliseconds()).format("HH:mm")}</font> - <b>{corrida.endereco}</b></font>}
                                        {!corrida.datahoraagendamento && <font> <font size="3">{moment.utc(diferenca.asMilliseconds()).format("HH:mm")}</font> - <b>{corrida.endereco}</b></font>}<br />
                                        {corrida.cidade && <font> {corrida.cidade} </font>}<br />
                                        {corrida.numero_reenvios && <font>Reenviado: {corrida.numero_reenvios}  </font>}
                                      </CardText>
                                    </CardBody>
                                    {this.props.childProps.userAttrib.centrais.map(central => {
                                      if (central.id === Number(corrida.central_id)) {
                                        estilo2 = { backgroundColor: central.aplicativocor.background, color: central.aplicativocor.foreground }
                                        centralnome = central.nomefantasia
                                        return (
                                          <CardFooter style={estilo2}>
                                            <center> {centralnome} </center>
                                          </CardFooter>
                                        )
                                      }
                                      else {
                                        return null
                                      }
                                    })}


                                  </Card></td>
                              </tr>
                            )
                          }
                          else {
                            return null
                          }



                        })

                      }
                    </CSSTransitionGroup>
                  </Table>
                </Router>
              </Loader>
            </Col>}

          </Row>
        </div>

        <div style={{ zIndex: 1000, position: 'fixed', bottom: '5px', left:'65px' }}>
        
          {true && <SacNotigicationCenter notificacaomsg={this.state.notificacaomsg}  msgaberto={this.msgaberto.bind(this)} central={this.props.childProps.userAttrib && this.props.childProps.userAttrib} />}
          {false && <SacNotigicationPiloto  notificacaomsg={this.state.notificacaomsg}  msgaberto={this.msgaberto.bind(this)} central={this.props.childProps.userAttrib && this.props.childProps.userAttrib} />}
          {false && <SacNotigication notificacaomsg={this.state.notificacaomsg}  msgaberto={this.msgaberto.bind(this)} central={this.props.childProps.userAttrib && this.props.childProps.userAttrib} />}
        </div>
        <Modal
          show={this.state.showRtDetails}
          onHide={this.handleClose}
          dialogClassName="dados-corrida"
        >
          <Modal.Header closeButton>
            <Modal.Title>Corrida {this.state.selected.id} da {central && central.nomefantasia}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ marginTop: -10 }}>
              {this.state.selected.motorista &&
                <button className="btn btn-warning btn-sm espacamento" id="btn-cancel" onClick={this.Reenviar}>Reenviar Corrida</button>}
              {this.state.selected.motorista && !this.state.selected.dataHoraQrl &&
                <button className="btn btn-warning btn-sm espacamento" id="btn-cancel" onClick={this.QrlOp}>Qrl</button>}
              {this.state.selected.motorista && this.state.selected.dataHoraQrl &&
                <button className="btn btn-warning btn-sm espacamento" id="btn-cancel" onClick={this.QrvOp}>Qrv</button>}
              <button className="btn btn-warning btn-sm espacamento" id="btn-cancel" onClick={() => this.handleShowCancel()}>Cancelar Corrida</button>
              <button className="btn btn-warning btn-sm espacamento" id="btn-cancel" onClick={() => this.atualizarPosMot(this.state.selected.randomcode)}>Atualizar motorista: {this.state.estadobotao ? "ligado" : "desligado"}</button>

            </div>
            <Row style={{ paddingTop: '5px' }}>
              <Col xs={6} md={2} style={{ padding: 0, paddingLeft: 15, height: 470, overflowY: 'scroll' }}>
                {this.state.selectedz.ztatus === 'ACAMINHO' &&
                <div className='data-box'>
                  <h4><strong>Atraso</strong></h4>
                  <h4><strong>{moment.utc(moment.duration(moment().diff(moment(this.state.selectedz.t12_dthr_corrida_che_cel))).asMilliseconds()).format("m")} m</strong></h4>
                </div>}
                <div className="data-box">
                  {/* {//console.log("i'm back")} */}
                  {/* {//console.log(this.state.selected)} */}
                  <h4><strong>Corrida</strong></h4>
                  {this.state.selected.datahoraagendamento &&
                    <div><p><strong>Data Agendada:</strong> {moment(this.state.selected.datahoraagendamento).format("DD/MM/YY HH:mm")}</p></div>
                  }
                  {(this.state.selected.datahora && !this.state.selected.datahoraagendamento) &&
                    <div><p><strong>Data:</strong> {moment(this.state.selected.datahora).format("DD/MM/YY HH:mm")}</p></div>
                  }
                  <div><p><strong>Endereço:</strong> {this.state.selected.endereco}</p></div>

                  <div><p><strong>Cliente: </strong>{this.state.selected.cliente}</p></div>

                  <div><p><strong>Telefone: </strong>{this.state.selected.telefone && (this.state.selected.telefone.toString().length === 10 ? this.state.selected.telefone.toString().replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") : this.state.selected.telefone.toString().replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"))}</p></div>

                  <div><p><strong>Atendente:</strong> {this.state.selected.atendente}</p></div>

                  <div><p><strong>Destino: </strong> {this.state.selected.enderecodestino}</p></div>

                  {empresa &&
                    <div><p><strong>Empresa: </strong>{empresa.razaosocial}</p></div>
                  }

                  {solicitante &&
                    <div><p><strong>Solicitante: </strong>{solicitante.nome}</p></div>
                  }
                </div>
                <div className="data-box">
                  <h4><strong>Códigos</strong></h4>
                  <p><strong>Carro: </strong>
                    {/* {//console.log("SELECTED")}
                        {//console.log(this.state.selected)}
                        {//console.log(_carro)} */}

                    {this.state.selected.t12_cd_tp_carro_a && _carro && _carro.map((m, i) => {
                      let string = '';
                      for (let index = 0; index < this.state.selected.t12_cd_tp_carro_a.length; index++) {
                        // //console.log(m.id)
                        // //console.log(this.state.selected.t12_cd_tp_carro_a[index])
                        if (m.id === this.state.selected.t12_cd_tp_carro_a[index]) {
                          if (index === 0) string += ' ' + m.text;
                          else string += ', ' + m.text;

                        }
                      }
                      return string;
                    })}
                  </p>
                  <p><strong>Cliente: </strong>
                    {this.state.selected.t12_cd_tp_cliente_a && _cliente && _cliente.map((m, i) => {
                      let string = '';
                      for (let index = 0; index < this.state.selected.t12_cd_tp_cliente_a.length; index++) {
                        if (m.id === this.state.selected.t12_cd_tp_cliente_a[index]) {
                          if (index === 0) string += ' ' + m.text;
                          else string += ', ' + m.text;
                        }
                      }
                      return string;
                    })}
                  </p>
                  <p><strong>Corrida: </strong>
                    {this.state.selected.t12_cd_tp_corrida_a && _corrida && _corrida.map((m, i) => {
                      let string = '';
                      for (let index = 0; index < this.state.selected.t12_cd_tp_corrida_a.length; index++) {
                        if (m.id === this.state.selected.t12_cd_tp_corrida_a[index]) {
                          if (index === 0) string += ' ' + m.text;
                          else string += ', ' + m.text;
                        }
                      }
                      return string;
                    })}
                  </p>
                  <p><strong>Pagamento: </strong>
                    {this.state.selected.t12_cd_tp_pgto_a && _pagto && _pagto.map((m, i) => {
                      let string = '';
                      for (let index = 0; index < this.state.selected.t12_cd_tp_pgto_a.length; index++) {
                        if (m.id === this.state.selected.t12_cd_tp_pgto_a[index]) {
                          if (index === 0) string += ' ' + m.text;
                          else string += ', ' + m.text;
                        }
                      }
                      return string;
                    })}
                  </p>
                </div>
                {this.state.selected.latitudedestino && false &&
                  <Loader loaded={this.state.lpreco}>
                    <div className='data-box'>
                      <h4><strong>Preço estimado</strong></h4>
                      {(this.state.selected.t12_cd_tp_carro_a && this.state.selected.t12_cd_tp_carro_a.indexOf(10) !== -1) &&
                        (this.state.selected.t12_cd_tp_corrida_a && this.state.selected.t12_cd_tp_corrida_a.indexOf(95) !== -1) &&
                        <p><strong>Bandeira 1 desconto:</strong> R${(this.state.distanceValue) && this.state.band1Desc}</p>}
                      {(this.state.selected.t12_cd_tp_carro_a && this.state.selected.t12_cd_tp_carro_a.indexOf(10) !== -1) &&
                        (this.state.selected.t12_cd_tp_corrida_a && this.state.selected.t12_cd_tp_corrida_a.indexOf(95) === -1) &&
                        <p><strong>Bandeira 1:</strong> R${(this.state.distanceValue) && this.state.band1}</p>}

                      {(this.state.selected.t12_cd_tp_carro_a && this.state.selected.t12_cd_tp_carro_a.indexOf(10) !== -1) &&
                        (this.state.selected.t12_cd_tp_corrida_a && this.state.selected.t12_cd_tp_corrida_a.indexOf(95) !== -1) &&
                        <p><strong>Bandeira 2 desconto:</strong> R${(this.state.distanceValue) && this.state.band2Desc}</p>}
                      {(this.state.selected.t12_cd_tp_carro_a && this.state.selected.t12_cd_tp_carro_a.indexOf(10) !== -1) &&
                        (this.state.selected.t12_cd_tp_corrida_a && this.state.selected.t12_cd_tp_corrida_a.indexOf(95) === -1) &&
                        <p><strong>Bandeira 2:</strong> R${(this.state.distanceValue) && this.state.band2}</p>}

                      {(this.state.selected.t12_cd_tp_carro_a && this.state.selected.t12_cd_tp_carro_a.indexOf(94) !== -1) &&
                        (this.state.selected.t12_cd_tp_corrida_a && this.state.selected.t12_cd_tp_corrida_a.indexOf(95) !== -1) &&
                        <p><strong>Executivo Bnd 1 desconto:</strong> R${(this.state.distanceValue) && this.state.executivoB1Desc}</p>}
                      {(this.state.selected.t12_cd_tp_carro_a && this.state.selected.t12_cd_tp_carro_a.indexOf(94) !== -1) &&
                        (this.state.selected.t12_cd_tp_corrida_a && this.state.selected.t12_cd_tp_corrida_a.indexOf(95) === -1) &&
                        <p><strong>Executivo Bnd 1:</strong> R${(this.state.distanceValue) && this.state.executivoB1}</p>}

                      {(this.state.selected.t12_cd_tp_carro_a && this.state.selected.t12_cd_tp_carro_a.indexOf(94) !== -1) &&
                        (this.state.selected.t12_cd_tp_corrida_a && this.state.selected.t12_cd_tp_corrida_a.indexOf(95) !== -1) &&
                        <p><strong>Executivo Bnd 2 desconto:</strong> R${(this.state.distanceValue) && this.state.executivoB2Desc}</p>}
                      {(this.state.selected.t12_cd_tp_carro_a && this.state.selected.t12_cd_tp_carro_a.indexOf(94) !== -1) &&
                        (this.state.selected.t12_cd_tp_corrida_a && this.state.selected.t12_cd_tp_corrida_a.indexOf(95) === -1) &&
                        <p><strong>Executivo Bnd 2:</strong> R${(this.state.distanceValue) && this.state.executivoB2}</p>}

                    </div>
                  </Loader>
                }
                <div className='data-box' >
                  <h4><strong>Motorista</strong></h4>
                  {/* {console.log("sou o motorista,", this.state.selected.motorista)} */}
                  <p><strong>RT:</strong> {this.state.selected.motorista && this.state.selected.motorista.rt}</p>
                  <p><strong>Nome:</strong> {this.state.selected.motorista && this.state.selected.motorista.nome} </p>
                  <p><strong>Placa:</strong> {this.state.selected.motorista && this.state.selected.motorista.placa.replace(/(\w+[A-Z])(\d{4})/, "$1-$2")}</p>
                  <p><strong>Carro:</strong> {this.state.selected.motorista && this.state.selected.motorista.carro}</p>
                  <p><strong>Celular:</strong> {this.state.selected.motorista && this.state.selected.motorista.celular}</p>
                </div>

                <div className='data-box' >
                  <h4><strong>Observações</strong></h4>
                  <p><strong>Motorista: </strong>{this.state.selected.observacoes}</p>
                  <p><strong>Interna: </strong>{this.state.selected.coordenadas}</p>
                </div>

                <div className='data-box' >
                  <h4><strong>Histórico</strong></h4>
                  {this.state.selected.lista && <div><p><strong>Corrida atribuida pela lista</strong></p></div>}
                  <div><p><strong>QRL:</strong> {this.state.selected.dataHoraQrl && moment(this.state.selected.dataHoraQrl || this.state.selected.t12_dthr_embarque).format("DD/MM/YY HH:mm")}</p></div>
                  <div><p><strong>QRV:</strong> {this.state.selected.dataHoraQrv && moment(this.state.selected.dataHoraQrv || this.state.selected.t12_dthr_dembarque).format("DD/MM/YY HH:mm")}</p></div>
                  {/* {//console.log("historico")} */}
                  <div><strong>Motorista recusou: </strong> {this.state.selected.recusado && this.state.selected.recusado.map(r => { return (<p key={r.uuid}>{r.motorista.at} - {r.motorista.nome}</p>) })}</div>
                </div>

              </Col>



              <Col xs={6} md={5}>
                {/*<button className="btn btn-warning btn-sm espacamento" id="btn-cancel" onClick={this.cancelTaxi}>Cancelar Corrida</button>*/}
                <br />
                {!this.state.selected.motorista &&
                  <div>
                    <p className="alinhamento"><b>Atribuir corrida:</b></p>
                    <Buscamoto id={this.state.selected.id} handleClose={this.handleClose.bind(this)} central={this.props.childProps.userAttrib && this.props.childProps.userAttrib} />
                  </div>
                }
                {this.state.selected.corridaPendente &&

                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel">
                        <div className="panel-body">
                          <ul className="chat">
                            {
                              this.state.mensagemcarregada.map(m => {
                                let classe = "right clearfix";
                                let classe2 = "pull-right text-muted";
                                let remetente = "Monitor";
                                let interna = true;
                                let url = "http://placehold.it/50/FA6F57/fff&text=OP";
                                if (m.remetente === 'Motorista') {
                                  interna = false;
                                  url = "http://placehold.it/50/55C1E7/fff&text=" + m.at;
                                  classe = "left clearfix";
                                  classe2 = "pull-left text-muted";
                                  remetente = "Motorista  " + m.placa + " / RT " + m.rt;
                                }
                                return (
                                  <li key={m.datahora} className={classe}>
                                    {interna &&
                                      <span className="chat-img pull-right">
                                        <img src={url} alt="User Avatar" className="img-circle" />
                                      </span>}
                                    {!interna &&
                                      <span className="chat-img pull-left">
                                        <img src={url} alt="User Avatar" className="img-circle" />
                                      </span>}
                                    <div className="chat-body clearfix">
                                      {interna &&
                                        <div className="header clearfix">
                                          <small className={classe2}>{moment(m.datahora).format("DD/MM/YY HH:mm")}</small>
                                          <strong className="pull-left primary-font">{remetente}</strong>
                                        </div>
                                      }
                                      {!interna &&
                                        <div className="header clearfix">
                                          <strong className="pull-right primary-font">{remetente}</strong>
                                          <small className={classe2}>{moment(m.datahora).format("DD/MM/YY HH:mm")}</small>
                                        </div>
                                      }
                                      <div className={classe}><p>{m.textolivre}</p></div>
                                    </div>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                        <div className="panel-footer">
                          {this.state.enviando2 && <div>Mensagem selecionada {this.state.categoria}</div>}
                          <div className="input-group">
                            {<input id="btn-input" style={{ height: 40 }} type="text" className="form-control input-sm" placeholder="Escreva sua mensagem..."
                              value={this.state.enviando} disabled={this.state.tipoenvio === 1 || this.state.tipoenvio === 3 ? true : false}
                              onChange={(v) => this.salvaValor(v)} onKeyDown={e => e.keyCode === 13 && this.state.enviando !== '' && this.handleSend(e) && e.preventDefault()} autoFocus
                            />}
                            <span className="input-group-btn">
                              <button className="btn btn-warning btn-sm" disabled={this.state.enviando === '' ? true : false} style={{ height: 40 }} id="btn-chat" onClick={this.handleSend} >Enviar</button>
                            </span>
                          </div>
                          <br />
                          <p>Opções de resposta</p>
                          <DropdownButton title="mensagens prontas">
                            {this.state.tipo_msg.map((tipo) => {
                              return (<MenuItem
                                onClick={() => this.setState({
                                  enviado: ' ',
                                  enviando: ' ',
                                  enviando2: tipo.m04_msg,
                                  respostas: tipo.m04_resposta,
                                  tipoenvio: tipo.m04_cat_order,
                                  categoria: tipo.m04_categoria
                                })
                                }>{tipo.m04_categoria}</MenuItem>)
                            })}
                          </DropdownButton>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <br />
              </Col>
              <Col xs={6} md={5} style={{ padding: 0, paddingLeft: 15 }}>
                <div style={{ width: '620px', height: '78vh' }} >
                  <div style={{ position: 'absolute', top: -25, fontSize: 16 }}>
                    {this.state.distance2 !== '' &&
                      <b><span>Distância aprox: {this.state.distance2}&nbsp;&nbsp;</span></b>}
                    {this.state.time !== '' &&
                      <b><span>Duração aprox: {this.state.time}&nbsp;</span></b>}
                  </div>
                  <GoMap
                    width={400}
                    height={450}
                    markO={[this.state.orig,this.state.dest]}
                  ></GoMap>

                </div>
              </Col>
            </Row>
            {/*this.state.selected.motorista &&
                <Tab eventKey={2} title="Mensagens" onClick={() => this.state.selected.motorista && this.handleClick2(this.state.selected.motorista.cpf)}>
                  <Modal.Body>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="panel">
                          <div className="panel-body">
                            <ul className="chat">
                              {
                                this.state.mensagemcarregada.map(m => {
                                  let classe = "right clearfix";
                                  let classe2 = "pull-right text-muted";
                                  let remetente = "Monitor";
                                  let interna = true;
                                  let url = "http://placehold.it/50/FA6F57/fff&text=OP";
                                  if (m.remetente === 'Motorista') {
                                    interna = false;
                                    url = "http://placehold.it/50/55C1E7/fff&text=" + m.at;
                                    classe = "left clearfix";
                                    classe2 = "pull-left text-muted";
                                    remetente = "Motorista AT " + m.at + " / RT " + m.rt;
                                  }
                                  return (
                                    <li key={m.datahora} className={classe}>
                                      {interna &&
                                        <span className="chat-img pull-right">
                                          <img src={url} alt="User Avatar" className="img-circle" />
                                        </span>}
                                      {!interna &&
                                        <span className="chat-img pull-left">
                                          <img src={url} alt="User Avatar" className="img-circle" />
                                        </span>}

                                      <div className="chat-body clearfix">
                                        {interna &&
                                          <div className="header clearfix">
                                            <small className={classe2}>{moment(m.datahora).format("DD/MM/YY HH:mm")}</small>
                                            <strong className="pull-left primary-font">{remetente}</strong>
                                          </div>
                                        }

                                        {!interna &&

                                          <div className="header clearfix">
                                            <strong className="pull-right primary-font">{remetente}</strong>
                                            <small className={classe2}>{moment(m.datahora).format("DD/MM/YY HH:mm")}</small>
                                          </div>
                                        }

                                        <div className={classe}><p>{m.textolivre}</p></div>

                                      </div>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                          <div className="panel-footer">
                            <div className="input-group">
                              <input id="btn-input" style={{ height: 40 }} type="text" className="form-control input-sm" placeholder="Escreva sua mensagem..."
                                value={this.state.enviando}
                                onChange={(v) => this.salvaValor(v)} onKeyDown={e => e.keyCode === 13 && this.handleSend(e) && e.preventDefault()} autofocus
                              />
                              <span className="input-group-btn">
                                <button className="btn btn-warning btn-sm" style={{ height: 40 }} id="btn-chat" onClick={this.handleSend} >Enviar</button>
                              </span>

                            </div>
                            <br />


                            <p>Opções de resposta</p>
                            <Form inline>
                              <FormControl componentClass="select" style={{ height: 40, width: 100, marginLeft: '12px' }} inputRef={el => this.inputEl1 = el} onClick={e => { this.setState({ op1: this.inputEl1.value, opt1: true }) }}>
                                <option value="1"> </option>
                                <option value="Sim">Sim</option>
                                <option value="Nao">Nao</option>
                                <option value="OK">OK</option>
                              </FormControl>
                              {this.state.opt1 &&
                                <FormControl componentClass="select" style={{ height: 40, width: 100, marginLeft: '12px' }} inputRef={el => this.inputEl2 = el} onClick={e => { this.setState({ op2: this.inputEl2.value, opt2: true }) }}>
                                  <option value="1"> </option>
                                  <option value="Sim">Sim</option>
                                  <option value="Nao">Nao</option>
                                  <option value="OK">OK</option>
                                </FormControl>
                              }
                              {this.state.opt2 &&
                                <FormControl componentClass="select" style={{ height: 40, width: 100, marginLeft: '12px' }} inputRef={el => this.inputEl3 = el} onClick={e => { this.setState({ op3: this.inputEl3.value }) }}>
                                  <option value="1"> </option>
                                  <option value="Sim">Sim</option>
                                  <option value="Nao">Nao</option>
                                  <option value="OK">OK</option>
                                </FormControl>
                              }
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Modal.Body>
                </Tab>
                            */}
          </Modal.Body>
        </Modal>
        <Row className="footer"> M - Mensagem RT  </Row>
        <MenuMotorista
          show={this.state.menuMotoShow}
          modalHide={this.menuMotoHide.bind(this)}
          menuMotoAT={this.state.menuMotoAT}
          changeMenuMotoAT={this.changeMenuMotoAT.bind(this)}
          history={this.props.history}
          childProps={this.props.childProps}
        />
        <PesquisaCorridas
          show={this.state.pesquisaCorridasShow}
          modalHide={this.hidePesquisasCorridas.bind(this)}
          childProps={this.props.childProps}
          motoAT={null}
          solicitacoes={false}
        />
        <Modal
          show={this.state.showCancel}
          onHide={this.handleCloseCancel}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
              Cancelar corrida
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <FormGroup controlId="formControlsSelectMultiple">
                <ControlLabel>Motivo cancelamento</ControlLabel>
                <FormControl componentClass="select" onSelect={this.handleChange} onChange={this.handleChange} inputRef={el => this.inputEl = el} style={{ height: 40 }} >
                  <option value=""></option>
                  {this.state.tipo_cancel && this.state.tipo_cancel.map((c, i) => {
                    return (
                      <option key={i} ref={"selecionado"} value={c.id}>{c.descricao}</option>
                    )
                  })}

                </FormControl>
              </FormGroup>
              <FormGroup
                controlId="formBasicText"
              >
                <ControlLabel>Observações: </ControlLabel>
                <FormControl
                  type="text"
                  componentClass="textarea"
                  value={this.state.value2}
                  placeholder="Escreva o texto"
                  onChange={e => this.setState({ value2: e.target.value })}
                />

              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.cancelTaxi()}>Sim </Button>
            <Button onClick={this.handleCloseCancel}>Não</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showqrvavaliacao}
          onHide={this.handleCloseqrv}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
              QRV
            </Modal.Title>
          </Modal.Header>
<Modal.Body>
          <ControlLabel>Avaliação: </ControlLabel>
          <FormControl
            type="number"
            value={this.state.avalia_clie}
            onChange={e => {
              let v = e.target.value;
              this.setState({ avalia_clie: v < 0 ? 0 : v > 5 ? 5 : v })
            }}
          />

        <ControlLabel>Comentário:</ControlLabel>
          <FormControl
            type="text"
            componentClass="textarea"
            value={this.state.avalia_comentario}
            placeholder="Escreva o texto"
            onChange={e => this.setState({ avalia_comentario: e.target.value })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={async () => {
              let vai = true;
              if (this.state.avalia_clie < 4) {
                if (this.state.avalia_comentario.length < 3) {
                  vai = false;
                  alert("Favor prencha o comentário!")
                }
              }
              if (vai) {
                await this.webservice.qrvOp(this.state.selected.id, this.props.childProps.userAttrib.nome);
                await this.webservice.avaOP(this.state.selected.id, this.state.avalia_clie, this.state.avalia_comentario);
                alert("Qrv feito pelo operador")
                this.handleCloseqrv();
                this.handleClose();
              }
              }}>Sim </Button>
          <Button onClick={this.handleCloseqrv}>Não</Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }
}

export default Corridas;
