import React, { Component } from 'react';
import WS from '../services/webservice';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Modal, Button } from 'react-bootstrap'
import moment from 'moment';
//import MostraHistorico from './MostraHistorico.js'
import DadosCorrida from './DadosCorrida.js'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import Workbook from 'react-excel-workbook';

class MonitorAgendamento extends Component {

  constructor(props) {
    super(props);
    this.state = {
      corridas: [],
      selected: null,
      dataHoraCorrida: null,
      dataHoraAgendada: null,
      dataHoraServico: null,
      dataHoraDesembarque: null,
      columnsSol: [
        {
          Header: "CÓD.",
          accessor: "t12_num_corrida",
          width: 75,
        },
        // {
        //   Header: "",
        //   accessor: "ztatus",
        //   width: 80,
        //   Cell: props => (
        //     <div
        //       style={props.original.t12_st_corrida === 'C' ||
        //         props.original.t12_st_corrida === 'M' ||
        //         props.original.t12_st_corrida === 'T' ||
        //         props.original.t12_st_corrida === 'H' ? { backgroundColor: 'red', margin: '0 auto' } :
        //         props.original.datahoraemfrente === null && props.original.t12_dthr_dembarque === null ? { backgroundColor: 'orange', margin: '0 auto' } :
        //           props.original.t12_dthr_dembarque === null ? { backgroundColor: 'deepskyblue', margin: '0 auto' } :
        //             { backgroundColor: 'darkgreen', margin: '0 auto' }}
        //       className='box-status'></div>
        //   ),
        //   filterMethod: (filter, row) => {
        //     if (filter.value === "") {
        //       return true;
        //     } else {
        //       return row[filter.id];
        //     }
        //   },
        //   Filter: ({ filter, onChange }) =>
        //     <select
        //       onChange={event => onChange(event.target.value)}
        //       style={{ width: "100%" }}
        //       value={filter ? filter.value : ""}
        //     >
        //       <option value="">Todas</option>
        //       <option value="NOVA">Novas</option>
        //       <option value="ACAMINHO">A caminho</option>
        //       <option value="EMFRENTE">Em frente</option>
        //       <option value="COMPASSAGEIRO">QRL</option>
        //       <option value="FINALIZANDO">QRV</option>
        //       <option value="AGENDADA">Agendada</option>
        //       {/* <option value="6-NOVA">Novas</option> */}
        //       <option value="REENVIADA">Reenviadas</option>
        //       <option value="CANCELADA">Canceladas</option>
        //       <option value="FINAL">Finalizadas</option>
        //     </select>
        // },
        // {
        //   Header: "TELEFONE",
        //   id: "t12_fone",
        //   accessor: d => d.t12_fone.toString().length === 10 ? d.t12_fone.toString().replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") : d.t12_fone.toString().replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
        //   width: 120,
        // },
        {
          Header: "AGENDAMENTO",
          id: 't12_dthr_agendamento',
          accessor: d => d.t12_dthr_agendamento && moment(d.t12_dthr_agendamento).format("DD/MM/YY HH:mm"),
          width: 110,
          filterMethod: (filter, row) => {
            return this.state.dataHoraAgendada;
          },
          Filter: ({ filter, onChange }) =>
            <DatePicker
              className="react-table-date-picker"
              maxDate={moment()}
              selected={this.state.dataHoraAgendada}
              onChange={this.dateHandleChangeAgendada}
              isClearable={true}
              withPortal
            />
        },
        {
          Header: "CIDADE",
          accessor: "cidade",
          width: 100,
        },
        // {
        //   Header: "PASSAGEIRO",
        //   accessor: "t12_nome",
        //   width: 100,
        // },
        // {
        //   Header: "SOLICITAÇÃO",
        //   id: 't12_dthr_tele',
        //   accessor: d => d.t12_dthr_tele && moment(d.t12_dthr_tele).format("DD/MM/YY HH:mm"),
        //   width: 110,
        //   filterMethod: (filter, row) => {
        //     return this.state.dataHoraCorrida;
        //   },
        //   Filter: ({ filter, onChange }) =>
        //     <DatePicker
        //       className="react-table-date-picker"
        //       maxDate={moment()}
        //       selected={this.state.dataHoraCorrida}
        //       onChange={this.dateHandleChangeCorrida}
        //       isClearable={true}
        //       withPortal
        //     />
        // },

        // {
        //   Header: "QRL",
        //   id: 't12_dthr_embarques',
        //   accessor: d => d.t12_dthr_embarques && moment(d.t12_dthr_embarques).format("DD/MM/YYYY HH:mm"),
        //   width: 123,
        //   filterMethod: (filter, row) => {
        //     return this.state.dataHoraServico;
        //   },
        //   Filter: ({ filter, onChange }) =>
        //     <DatePicker
        //       className="react-table-date-picker"
        //       maxDate={moment()}
        //       selected={this.state.dataHoraServico}
        //       onChange={this.dateHandleChangeServico}
        //       isClearable={true}
        //       withPortal
        //     />
        // },
        // {
        //   Header: "QRV",
        //   id: 't12_dthr_dembarque',
        //   accessor: d => d.t12_dthr_dembarque && moment(d.t12_dthr_dembarque).format("DD/MM/YYYY HH:mm"),
        //   width: 123,
        //   filterMethod: (filter, row) => {
        //     return this.state.dataHoraDesembarque;
        //   },
        //   Filter: ({ filter, onChange }) =>
        //     <DatePicker
        //       className="react-table-date-picker"
        //       maxDate={moment()}
        //       selected={this.state.dataHoraDesembarque}
        //       onChange={this.dateHandleChangeDesembarque}
        //       isClearable={true}
        //       withPortal
        //     />
        // },
        // {
        //   Header: "",
        //   accessor: "ztatus",
        //   width: 80,
        //   Cell: props => (
        //     <div>{props.original.ztatus === 'NOVA' ? 'Nova'
        //       : props.original.ztatus === 'ACAMINHO' ? 'A caminho'
        //         : props.original.ztatus === 'EMFRENTE' ? 'Em frente'
        //           : props.original.ztatus === 'COMPASSAGEIRO' ? 'QRL'
        //             : props.original.ztatus === 'FINALIZANDO' ? 'QRV'
        //               : props.original.ztatus === 'AGENDADA' ? 'Agendada'
        //                 : props.original.ztatus === 'REENVIADA' ? 'Reenviada'
        //                   : props.original.ztatus === 'CANCELADA' ? 'Cancelada'
        //                     : props.original.ztatus === 'FINALIZADA' ? 'Finalizada'
        //                       : ''}</div>
        //   ),
        //   filterMethod: (filter, row) => {
        //     if (filter.value === "") {
        //       return true;
        //     } else {
        //       return row[filter.id];
        //     }
        //   },
        //   Filter: ({ filter, onChange }) =>
        //     <select
        //       onChange={event => onChange(event.target.value)}
        //       style={{ width: "100%" }}
        //       value={filter ? filter.value : ""}
        //     >
        //       <option value="">Todas</option>
        //       <option value="NOVA">Novas</option>
        //       <option value="ACAMINHO">A caminho</option>
        //       <option value="EMFRENTE">Em frente</option>
        //       <option value="COMPASSAGEIRO">QRL</option>
        //       <option value="FINALIZANDO">QRV</option>
        //       <option value="AGENDADA">Agendada</option>
        //       {/* <option value="5-NOVA">Novas</option> */}
        //       {/* <option value="6-NOVA">Novas</option> */}
        //       <option value="REENVIADA">Reenviadas</option>
        //       <option value="CANCELADA">Canceladas</option>
        //       <option value="FINAL">Finalizadas</option>
        //     </select>
        // },
        {
          Header: "CT",
          width: 40,
          filterable: false,
          Cell: props => {
            let central = null;
            this.props.childProps.userAttrib.centrais.forEach(c => {
              if ((c.id) === props.original.central_id) {
                central = c;
              }
            });
            return <div style={{ color: central && central.aplicativocor.background }}>{central && central.nomefantasia}</div>
          },
        },
        // {
        //   Header: "T",
        //   width: 35,
        //   id: 'dados_corrida',
        //   filterable: false,
        //   accessor: d => d.dados_corrida ? d.dados_corrida.niveltransbordo > 0 ? 'T' : '' : '',
        // },
        // {
        //   Header: "Atendente",
        //   width: 100,
        //   id: 'taxi333$atendente',
        //   accessor: d => d.taxi333$atendente,
        //   filterable: true,
        // },
      ],
      columnsCor: [
        {
          Header: "CÓD.",
          accessor: "t12_num_corrida",
          width: 75,
        },
        {
          Header: "SOLICITAÇÃO",
          id: 't12_dthr_tele',
          accessor: d => d.t12_dthr_tele && moment(d.t12_dthr_tele).format("DD/MM/YY HH:mm"),
          width: 110,
          filterMethod: (filter, row) => {
            return this.state.dataHoraCorrida;
          },
          Filter: ({ filter, onChange }) =>
            <DatePicker
              className="react-table-date-picker"
              maxDate={moment()}
              selected={this.state.dataHoraCorrida}
              onChange={this.dateHandleChangeCorrida}
              isClearable={true}
              withPortal
            />
        },
        {
          Header: "AGENDAMENTO",
          id: 't12_dthr_agendamento',
          accessor: d => d.t12_dthr_agendamento && moment(d.t12_dthr_agendamento).format("DD/MM/YY HH:mm"),
          width: 110,
          filterMethod: (filter, row) => {
            return this.state.dataHoraAgendada;
          },
          Filter: ({ filter, onChange }) =>
            <DatePicker
              className="react-table-date-picker"
              maxDate={moment()}
              selected={this.state.dataHoraAgendada}
              onChange={this.dateHandleChangeAgendada}
              isClearable={true}
              withPortal
            />
        },
        {
          Header: "Cidade",
          accessor: "cidade",
          width: 120,
        },
        {
          Header: "ORIGEM",
          accessor: "origem",
          width: 220,
        },
        {
          Header: "CT",
          width: 40,
          filterable: false,
          Cell: props => {
            let central = null;
            this.props.childProps.userAttrib.centrais.forEach(c => {
              if ((c.id) === props.original.central_id) {
                central = c;
              }
            });
            return <div style={{ color: central && central.aplicativocor.background }}>{central && central.nomefantasia}</div>
          },
        },
        {
          Header: "Atendente",
          width: 100,
          id: 'taxi333$atendente',
          accessor: d => d.taxi333$atendente,
          filterable: true,
        },
        {
          Header: "",
          accessor: "ztatus",
          filterable: false,
          width: 40,
          Cell: props => (
            <div
              style={props.original.t12_st_corrida === 'C' ||
                props.original.t12_st_corrida === 'M' ||
                props.original.t12_st_corrida === 'T' ||
                props.original.t12_st_corrida === 'H' ? { backgroundColor: 'red', margin: '0 auto' } :
                props.original.datahoraemfrente === null && props.original.t12_dthr_dembarque === null ? { backgroundColor: 'orange', margin: '0 auto' } :
                  props.original.t12_dthr_dembarque === null ? { backgroundColor: 'deepskyblue', margin: '0 auto' } :
                    { backgroundColor: 'darkgreen', margin: '0 auto' }}
              className='box-status'></div>
          )
        },
        {
          Header: "Situação",
          accessor: "ztatus",
          width: 80,
          Cell: props => (
            <div>{props.original.ztatus === 'NOVA' ? 'Nova'
              : props.original.ztatus === 'ACAMINHO' ? 'A caminho'
                : props.original.ztatus === 'EMFRENTE' ? 'Em frente'
                  : props.original.ztatus === 'COMPASSAGEIRO' ? 'QRL'
                    : props.original.ztatus === 'FINALIZANDO' ? 'QRV'
                      : props.original.ztatus === 'AGENDADA' ? 'Agendada'
                        : props.original.ztatus === 'REENVIADA' ? 'Reenviada'
                          : props.original.ztatus === 'CANCELADA' ? 'Cancelada'
                            : props.original.ztatus === 'FINALIZADA' ? 'Finalizada'
                              : props.original.ztatus}</div>
          ),
          filterMethod: (filter, row) => {
            if (filter.value === "") {
              return true;
            } else {
              return row[filter.id];
            }
          },
          Filter: ({ filter, onChange }) =>
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : ""}
            >
              <option value="">Todas</option>
              <option value="NOVA">Novas</option>
              <option value="ACAMINHO">A caminho</option>
              <option value="EMFRENTE">Em frente</option>
              <option value="COMPASSAGEIRO">QRL</option>
              <option value="FINALIZANDO">QRV</option>
              <option value="AGENDADA">Agendada</option>
              {/* <option value="5-NOVA">Novas</option> */}
              {/* <option value="6-NOVA">Novas</option> */}
              <option value="REENVIADA">Reenviadas</option>
              <option value="CANCELADA">Canceladas</option>
              <option value="FINAL">Finalizadas</option>
            </select>
        },
        {
          Header: "PASSAGEIRO",
          accessor: "t12_nome",
          width: 100,
        },
        {
          Header: "Motorista",
          accessor: "t08_motoristas_t08_nomemotorista",
          width: 100,
        },
        {
          Header: "CPF",
          accessor: "t12_cpf_sec",
          width: 100,
        },
        {
          Header: "Placa",
          accessor: "t07_carro_t07_placa_detran",
          width: 100,
        },
        {
          Header: "Embarque",
          id: 't12_dthr_embarques',
          accessor: d => d.t12_dthr_embarques && moment(d.t12_dthr_embarques).format("DD/MM/YYYY HH:mm"),
          width: 123,
          filterMethod: (filter, row) => {
            return this.state.dataHoraServico;
          },
          Filter: ({ filter, onChange }) =>
            <DatePicker
              className="react-table-date-picker"
              maxDate={moment()}
              selected={this.state.dataHoraServico}
              onChange={this.dateHandleChangeServico}
              isClearable={true}
              withPortal
            />
        },
        {
          Header: "Desembarque",
          id: 't12_dthr_dembarque',
          accessor: d => d.t12_dthr_dembarque && moment(d.t12_dthr_dembarque).format("DD/MM/YYYY HH:mm"),
          width: 123,
          filterMethod: (filter, row) => {
            return this.state.dataHoraDesembarque;
          },
          Filter: ({ filter, onChange }) =>
            <DatePicker
              className="react-table-date-picker"
              maxDate={moment()}
              selected={this.state.dataHoraDesembarque}
              onChange={this.dateHandleChangeDesembarque}
              isClearable={true}
              withPortal
            />
        },
        
        {
          Header: "T",
          width: 35,
          id: 'dados_corrida',
          filterable: false,
          accessor: d => d.dados_corrida ? d.dados_corrida.niveltransbordo > 0 ? 'T' : '' : '',
        },
        {
          Header: "ATRASO",
          width: 70,
          id: 'atraso',
          filterable: false,
          accessor: d => d.ztatus2 === 'EXECUCAO' ? d.ztatus === 'ACAMINHO' ? moment.utc(moment.duration(moment().diff(moment(d.t12_dthr_corrida_che_cel))).asMilliseconds()).format("m") + ' m' : '' : '',
        },



        // {
        //   Header: "REF.",
        //   width: 100,
        //   id: 'referencia_original_string',
        //   accessor: d => d.dados_corrida && d.dados_corrida.referencia_original_string ? d.dados_corrida.referencia_original_string.split(' - ')[1] : '',
        //   filterable: false,
        // },
        {
          Header: "DESPACHO",
          accessor: "despacho",
          width: 110,
          filterMethod: (filter, row) => {
            if (filter.value === "") {
              return true;
            } else {
              return row[filter.id];
            }
          },
          Filter: ({ filter, onChange }) =>
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : ""}
            >
              <option value="">Todas</option>
              <option value="Lista">Listas</option>
              <option value="Normal">Normais</option>
              <option value="Operador">Operadores</option>
            </select>
        },
        
        
        


      ]
    }

    this.ws = new WS();

    this.fetchData = this.fetchData.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.showHistorico = this.showHistorico.bind(this);
    this.hideHistorico = this.hideHistorico.bind(this);
    this.manageShortcut = this.manageShortcut.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.fetchDataTimeOut2 = this.fetchDataTimeOut2.bind(this);
    this.dateHandleChangeCorrida = this.dateHandleChangeCorrida.bind(this);
    this.dateHandleChangeAgendada = this.dateHandleChangeAgendada.bind(this);
    this.dateHandleChangeServico = this.dateHandleChangeServico.bind(this);
    this.dateHandleChangeDesembarque = this.dateHandleChangeDesembarque.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentWillMount() { }

  componentDidMount() { }

  async fetchData(dados, instance) {

    var filtered = dados.filtered.filter(function(value, index, arr){return value.id !== 't12_dthr_corridas';});
  

    dados.filtered = filtered;
    let pge = dados.page + 1;

    let order = dados.sorted;
    if (order.length <= 0) {
      order = [{ id: "ztatus1" }, { id: "t12_dthr_tele", desc: true }, { id: "t12_num_corrida", desc: true }]
    }


    this.setState({ loading: true, dados: dados, instance: instance });

    if (this.state.dataHoraAgendada) {
      dados.filtered.push({ id: "t12_dthr_agendamento", value: '{"gte": " ' + moment(this.state.dataHoraAgendada).format("YYYY-MM-DD 00:00") + ' " ,"lte": "' + moment(this.state.dataHoraAgendada).format("YYYY-MM-DD 23:59") + ' " }' })
    }
    if (this.state.dataHoraDesembarque) {
      dados.filtered.push({ id: "t12_dthr_dembarque", value: '{"gte": " ' + moment(this.state.dataHoraDesembarque).format("YYYY-MM-DD 00:00") + ' " ,"lte": "' + moment(this.state.dataHoraDesembarque).format("YYYY-MM-DD 23:59") + ' " }' })
    }
    if (this.state.dataHoraServico) {
      dados.filtered.push({ id: "t12_dthr_embarques", value: '{"gte": " ' + moment(this.state.dataHoraServico).format("YYYY-MM-DD 00:00") + ' " ,"lte": "' + moment(this.state.dataHoraServico).format("YYYY-MM-DD 23:59") + ' " }' })
    }
    if (this.state.dataHoraCorrida) {
      dados.filtered.push({ id: "t12_dthr_tele", value: '{"gte": " ' + moment(this.state.dataHoraCorrida).format("YYYY-MM-DD 00:00") + ' " ,"lte": "' + moment(this.state.dataHoraCorrida).format("YYYY-MM-DD 23:59") + ' " }' })
    }
    console.log('FILTERED antes', dados.filtered);

    if (dados.filtered.length < 1) {
      dados.filtered.push({ id: "ztatus", value: 'AGENDADA' });
    }

    console.log('FILTERED depois', dados.filtered);

    let result = await this.ws.getTipo('corridaz', '', order, pge, dados.pageSize, dados.filtered);

    let count = result.headers.get('X-Total-Count');
    result = await result.json();
    if (result) {
      this.setState({
        corridas: result,
        pages: Math.ceil(count / dados.pageSize),
        loading: false
      })
    } else {
      this.setState({
        loading: false
      })
    }
    this.fetchDataTimeOut2(dados, instance);
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => { this.fetchData(dados, instance) }, 300)
    });
  }
  fetchDataTimeOut2(dados, instance) {
    clearTimeout(this.state.fetchTimeout2);
    this.setState({
      fetchTimeout2: setTimeout(() => { this.fetchData(dados, instance) }, 1000 * 120)
    });
  }
  manageShortcut(e) {
    let code = (e.keyCode ? e.keyCode : e.which);

    if (e.altKey && code === 80 && this.props.childProps.userAttrib.admin) {
      e.preventDefault(); e.stopPropagation();
      this.fetchData(this.state.dados, this.state.instance);
    }
  }

  setSelected(original) { this.setState({ selected: original }); }

  showHistorico() { this.setState({ showHistorico: true }); }

  hideHistorico(id) {
    console.log('id', id)
    this.setState({ showHistorico: false }, () => {
      if (!id) {
        this.fetchData(this.state.dados, this.state.instance);
      } else {
        let dados = this.state.dados;
        dados.filtered = [{ id: "z_corrida_original", value: id }];
        this.fetchData(dados, this.state.instance);
      }
    });
  }
  dateHandleChangeAgendada(date) {
    this.setState({ dataHoraAgendada: date }, () => {
      this.fetchData(this.state.dados, this.state.instance);
    });
  }
  dateHandleChangeCorrida(date) {
    this.setState({ dataHoraCorrida: date }, () => {
      this.fetchData(this.state.dados, this.state.instance);
    });
  }

  dateHandleChangeServico(date) {
    this.setState({ dataHoraServico: date }, () => {
      this.fetchData(this.state.dados, this.state.instance);
    });
  }

  dateHandleChangeDesembarque(date) {
    this.setState({ dataHoraDesembarque: date }, () => {
      this.fetchData(this.state.dados, this.state.instance);
    });
  }

  render() {
    require('moment/locale/pt-br.js')
    moment.locale('pt-br')

    return (
      <div onKeyDown={this.manageShortcut} tabIndex="0">
          <DadosCorrida
            reclamacao={true}
            show={this.state.showHistorico}
            onHide={this.hideHistorico}
            corrida={this.state.selected}
            childProps={this.props.childProps}
          />
            <ReactTable
              manual
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.fetchDataTimeOut}
              data={this.state.corridas}
              pageSizeOptions={this.props.childProps.userAttrib.monitorcentral || this.props.childProps.userAttrib.admin ? [5, 10, 15, 100, 200] : [5, 10, 15]}
              previousText='Anterior'
              nextText='Próxima'
              loadingText='Carregando...'
              noDataText='Nenhum dado encontado'
              pageText='Página'
              ofText='de'
              rowsText='linhas'
              filterable
              sortable={false}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
              getTdProps={(state, props, column) => {
                return {
                  style: {
                    cursor: "pointer",
                  },
                  onClick: (e) => {
                    if(props && props.original){
                      this.setSelected(props.original);
                    }
                    this.showHistorico();
                  },
                }
              }}
              columns={this.state.columnsSol}
              defaultPageSize={15}
              className="-striped -highlight"
            />
      </div>
    );
  }
}
export default MonitorAgendamento;
