import {
  Badge,
  Calendar,
  Checkbox,
  DatePicker,
  Modal,
  Select
} from 'antd'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import WS from '../../services/webservice'

const { Option } = Select
const CheckboxGroup = Checkbox.Group
const { MonthPicker, RangePicker } = DatePicker

const plainOptions = ['Manhã', 'Tarde', 'Noite']

// --------- Styled Components ---------------

// -----------------------------------------
const getBadge = (periodo) => {
  if (periodo.length === 0) {
    return
  }

  return (
    <Badge
      count={periodo.join(', ')}
      style={{
        backgroundColor: '#52c41a'
      }}
    />
  )
}

class CalendarioDisponivel extends Component {
  constructor(props) {
    super(props)
    this.webservice = new WS()
    this.agenda = this.agenda.bind(this)
    this.state = {
      visible: false,
      selectedValue: [],
      selectedDate: null,
      calendarData: [],
      city: 'Curitiba/PR',
      events: []
    }
  }

  dateCellRender = (value) => {
    const { calendarData } = this.state
    const match = calendarData.find(
      (item) => item.data === dayjs(value).format('DD-MM-YYYY')
    )
    if (match) {
      return (
        <div style={{ backgroundColor: '#e6f7ff' }}>
          <Badge
            count={
              <div>
                {match.periodo.join(', ')}
                <a
                  onClick={() => this.removeBadge(match.data)}
                  style={{ marginLeft: '5px', cursor: 'pointer', color: 'red' }}
                >
                  <button style={{ margin: '0.5em' }}>X</button>
                </a>
              </div>
            }
            style={{
              backgroundColor: '#52c41a'
            }}
          />
        </div>
      )
    }
    return null
  }


  onSelect = (value) => {
    this.setState({
      selectedDate: dayjs(value),
      visible: true
    })
  }

  handleCancel = () => {
    this.setState({
      visible: false
    })
  }

  handleOk = () => {
    this.setState((prevState) => ({
      visible: false,
      calendarData: [
        ...prevState.calendarData,
        {
          data: prevState.selectedDate.format('DD-MM-YYYY'),
          periodo: prevState.selectedValue
        }
      ]
    }))
  }

  removeBadge = (data) => {
    this.setState((prevState) => ({
      calendarData: prevState.calendarData.filter((item) => item.data !== data)
    }))
  }

  onChange = (selectedValue) => {
    this.setState({ selectedValue })
  }

  handleCityChange = (city) => {
    this.setState({ city })
  }

  async agenda() {
    try {
      let result = await this.webservice.getAgenda()
      let events = []
      this.setState({ events: [] })
      result.forEach(({ solicitacoes, id_instalador }) => {
        solicitacoes.forEach(({ id, data, periodo }) => {
          const [day, month, year] = data.split('-')
          periodo.forEach((time) => {
            events.push({
              id,
              title: `Instalador: ${id_instalador}`,
              start: new Date(year, month - 1, day),
              end: new Date(year, month - 1, day)
            })
          })
        })
      })

      this.setState({ events })
    } catch (error) {
      return error
    }
  }

  componentDidMount() {
    this.agenda()
    console.log('*** CALENDÁRIO ***', this.state.events)
  }

  render() {
    return (
      <div>
        <div style={{ margin: 16 }}>
          Selecione a Região:{' '}
          <Select
            value={this.state.city}
            style={{ width: 120 }}
            onChange={this.handleCityChange}
          >
            <Option value='Curitiba/PR'>Curitiba/PR</Option>
            <Option value='São José dos Pinhais/PR'>
              São José dos Pinhais/PR
            </Option>
            <Option value='Fazenda Rio Grande/PR'>Fazenda Rio Grande/PR</Option>
            <Option value='Colombo/PR'>Colombo/PR</Option>
            <Option value='Pinhais/PR'>Pinhais/PR</Option>
          </Select>
        </div>

        <Calendar
          mode='month'
          dateCellRender={this.dateCellRender}
          onSelect={this.onSelect}
        />
        <Modal
          title='Selecione os períodos'
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <CheckboxGroup options={plainOptions} onChange={this.onChange} />
        </Modal>
      </div>
    )
  }
}

export default CalendarioDisponivel
