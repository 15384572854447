import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from './CadastroHelper.js';

class CadastroUsuario extends Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {
    //console.log(this.props)
  }

  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Usuário",
          tipo_plural: "Usuários",
          href: "/usuario",
          titulo: "Cadastro de Usuários",
          requests: {
            type: 'usuario',
            list: this.ws.getTipo.bind(this.ws),
            save: this.ws.criarTipo.bind(this.ws),
            edit: this.ws.alterarTipo.bind(this.ws),
            delete: this.ws.deleteTipo.bind(this.ws),
            niveis: this.ws.getTipo.bind(this.ws),
            addCentral: this.ws.addCentral.bind(this.ws),
            delCentral: this.ws.deleteCentral.bind(this.ws),
            attCentral: this.ws.atualizarCentral.bind(this.ws),
            attCentral2: this.ws.atualizarCentral2.bind(this.ws),
          },
          campos: [
            {
              ref: "nome",
              label: "Nome",
              type: "text",
              placeHolder: "Escreva o nome do usuário",
            },
            {
              ref: "email",
              label: "Email",
              type: "email",
              placeHolder: "Escreva email do usuário",
            },
            {
              ref: "senha",
              label: "Senha",
              type: "text",
              placeHolder: "Escreva a senha do usuário",
            },
            {
              ref: "central_padrao",
              label: "Central Padrão",
              type: "select2Padrao",
              placeholder: "Selecione a Central Padrão",
            },
            {
              ref: "centrais",
              label: "Centrais",
              type: "select2Centrais",
              placeholder: "Selecione as Centrais",
            },
            {
              ref: "admin_central",
              label: "Admin Central",
              type: "checkbox",
            },
            {
              ref: "admin_cidade",
              label: "Admin Cidade",
              type: "checkbox",
            },
            {
              ref: "cidadeatuacao",
              label: "Cidades",
              type: "select2cidades",
              placeholder: "Selecione as cidades",
              defaultvalue: []
            },
            {
              ref: "admin",
              label: "Admin",
              type: "checkbox",
            },
          ],
          botoes: [
            {
              label: "Criar Usuário",
            },
          ],
          table: {
            titulo: "Usuários",
            colunas: [
              {
                Header: "Nome",
                accessor: "nome",
              },
              {
                Header: "Email",
                accessor: "email",
              },
              // {
              //   Header: "Ramal",
              //   accessor: "ramal",
              // },
              {
                Header: "Admin central",
                accessor: "admin_central",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.admin_central ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
              {
                Header: "Admin Sistema",
                accessor: "admin",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.admin ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
            ],
          }
        }}
      />
    );
  }
}

export default CadastroUsuario;
