import React, { Component } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
class CustomMarker extends Component {
  state = {
    showInfoWindow: false
  };
  handleMouseOver = e => {
    this.setState({
      showInfoWindow: true
    });
  };
  handleMouseExit = e => {
    this.setState({
      showInfoWindow: false
    });
  };
  render() {
    const { showInfoWindow } = this.state;
    const { info, lat, lng, iconUrl, iconSize, onClick } = this.props;
    if (iconSize && iconUrl) {
      const icon = { url: iconUrl, scaledSize: { width: iconSize, height: iconSize } }
      return (
        <Marker
          icon={icon}
          position={{ lat, lng }}
          onClick={onClick}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseExit}
        >
          {showInfoWindow && (
            <InfoWindow>
              <h4>{info}</h4>
            </InfoWindow>
          )}
        </Marker>
      );
    } else {
      return (
        <Marker
          position={{ lat, lng }}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseExit}>
          {showInfoWindow && (
            <InfoWindow>
              <h4>{info}</h4>
            </InfoWindow>
          )}
        </Marker>
      );
    }
  }
}
export default CustomMarker;