import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export default class PrecadastroEmpresa extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: []
    }
    this.ws = new WS();
  }

  mirrorState(key) {
    console.log('mirrorState', key)
    this.setState(key);
  }
  async motoristas(p, after) {
    alert("Aguarde Processamento!")
    console.log('precadastro', p)
    await this.ws.efetivaprecadastrosempresa(p.id);
    alert("Processamento concluido!")
    if (after) {
      after();
    }
    //console.log('motoristas 2', p.motorista)
  }
  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Pré-Cadastro Convênio",
          tipo_plural: "Pré-Cadastros Convênio",
          href: "precadastroempresas",
          titulo: "Cadastro de Convênio",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'precadastroempresas',
            list: true,
            save: true,
            edit: true,
            new: true,
            delete: false,
            listSub: true,
            outros: [
              {icone: 'FaUsers', tamanho: 30, cor: 'blue', texto: 'Efetivar Cadastro', funcao:this.motoristas.bind(this)}],
          },
          subtipos: [
            {tipo: 'status',p:'oarray', array:[{ txt: 'Ativo', id: true }, { txt: 'Inativo', id: false }]},
            {tipo: 'simnao',p:'oarray', array:[{ txt: 'Sim', id: true }, { txt: 'Não', id: false }]},
            {tipo: 'central', id: 'id', txt: 'nomefantasia', p:'get'},
          ],
          campos: [
            { ref: "central", label: "Central", type: "select", listtype: 'central', default: this.ws.getCookie('central-id')},
            { ref: "cnpj", label: "cnpj", type: "maskedtext", mask:"99.999.999/9999-99" },
            { ref: "razao_social", label: "Razão Social", type: "text", },
            { ref: "cep", label: "cep", type: "maskedtext", mask:"99999-999" },
            { ref: "rua", label: "rua", type: "text", },
            { ref: "numero", label: "numero", type: "text", },
            { ref: "complemento", label: "complemento", type: "text", },
            { ref: "bairro", label: "bairro", type: "text", },
            { ref: "cidade", label: "cidade", type: "text", },
            { ref: "uf", label: "uf", type: "text", },
            { ref: "telefone", label: "telefone", type: "telefone", maskCel:'(99) 99999-9999' ,maskFixo:'(99) 9999-9999'},
            { ref: "email", label: "email", type: "text", },
            { ref: "nomeresponsavel", label: "nomeresponsavel", type: "text", },
            { ref: "cpfresponsavel", label: "cpfresponsavel", type: "maskedtext", mask:"999.999.999-99" },
            { ref: "codigoindicacao", label: "codigoindicacao", type: "text", },
          ],
          table: {
            colunas: [
              {
                Header: "Central",
                accessor: "central",
                filterable:false,
                Cell: row => {
                  return (
                    <div>
                      {this.state.sublistas && this.state.sublistas.central &&this.state.sublistas.central.find(element => element.id === row.original.central ) &&
                      this.state.sublistas.central.find(element => element.id === row.original.central ).text
                      }
                    </div>
                  )
                }
              },
              { Header: "Razão Social", accessor: "razao_social", },
              { Header: "CNPJ", accessor: "cnpj", },
              { Header: "CPF", accessor: "cpfresponsavel", },
              { Header: "E-mail", accessor: "email", },
            ],
          }
        }}
      />
    );
  }
};
