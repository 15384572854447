import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class SaldoMotorista1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: []
    }

  }

  mirrorState(key) {
    console.log('mirrorState', key)
    this.setState(key);
  }
  registrapagamento(p, after) {
    if (after) {
      after();
    }
  }
  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "t08_cpf_sec",
          tipo_cadastro: "Saldo / Faturamento Motorista",
          tipo_plural: "Saldo / Faturamento Motorista",
          href: "motfinanceiro1",
          titulo: "Saldo / Faturamento Motorista",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'motfinanceiro1',
            list: true,
            save: false,
            edit: false,
            semdetalhes:true,
            delete: false,
            listSub: true,
            defaultorder: [{ id: "t08_cpf_sec" }],
            outras: [
              {icone: 'FaReceipt', tamanho: 22, cor: 'blue', funcao:this.registrapagamento.bind(this)}],
          },
          subtipos: [
            {tipo: 'central', id: 'id', txt: 'nomefantasia', p:'get'},
          ],
          campos: [
            {
              ref: "t08_cpf_sec",
              label: "Cpf",
              type: "text",
            },
            {
              ref: "t08_nomemotorista",
              label: "Nome",
              type: "text",
            },
            {
              ref: "central",
              label: "Central",
              type: "select",
              listtype:'central',
            },
            {
              ref: "statusmot",
              label: "Status",
              type: "text",
            },
            {
              ref: "centralmotorista_cpf",
              label: "centralmotorista_cpf",
              type: "text",
            },
            {
              ref: "centralmotorista_central",
              label: "centralmotorista_central",
              type: "select",
              listtype:'central',
            },
            {
              ref: "corrida_central",
              label: "corrida_central",
              type: "select",
              listtype:'central',
            },
            {
              ref: "saldo",
              label: "saldo",
              listtype:'text'
            },
          ],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "t08_cpf_sec",
                accessor: "t08_cpf_sec",
              },
              {
                Header: "t08_nomemotorista",
                accessor: "t08_nomemotorista",
              },
              {
                Header: "central",
                accessor: "central",
                filterable:false,
                Cell: row => {
                  return (
                    <div>
                      {this.state.sublistas.central.find(element => element.id === row.original.central ) &&
                      this.state.sublistas.central.find(element => element.id === row.original.central ).text
                      }
                    </div>
                  )
                }
              },
              {
                Header: "corrida_central",
                accessor: "corrida_central",
                filterable:false,
                Cell: row => {
                  return (
                    
                    <div>
                      {this.state.sublistas.central.find(element => element.id === row.original.corrida_central ) &&
                      this.state.sublistas.central.find(element => element.id === row.original.corrida_central ).text
                      }
                    </div>
                  )
                }
              },
              {
                Header: "saldo",
                accessor: "saldo",
                filterable:false,
                Cell: row => {
                  return (
                    <div>
                      {Number(row.original.saldo).toFixed(2).toString().replace('.',',')}
                    </div>
                  )
                }
              },
            ],
          }
        }}
      />
    );
  }
}
export default SaldoMotorista1;
