import React, { Component } from 'react'
//import { render } from 'react-dom'
import { Launcher } from './CompMsg/index'
import moment from 'moment';
import {
  DropdownButton, MenuItem
} from 'react-bootstrap'
import WS from '../services/webservice';
import './Bubbles.css'

var messageList = [];

class NewMsg extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tipo_msg: [],
      aberto: false,
      enviando2: null,
      respostas: [],
      tipoenvio: 5,
      categoria: '',
      aberto2: true,
    };

    this.loadMsg = this.loadMsg.bind(this)
    this.envio = this.envio.bind(this)
    this.msgpai = this.msgpai.bind(this)
    this.msgpai2 = this.msgpai2.bind(this)
    this.tipos = this.tipos.bind(this)
    this.mostraCentral = this.mostraCentral.bind(this)

    this.webservice = new WS();
  }

  

  async loadMsg() {
    let novovet = []
    if(!this.props.aberto){
      messageList = []

    }
    let result = await this.webservice.fetch2('mensagens?cpf=' + this.props.cpf + '&dataHoraInicio=' + moment().subtract(2, 'days').valueOf() + '&dataHoraFim=' + moment().valueOf(), {}, this.props.central);
    let msgc = await result.json(result.result);
    for (let i of msgc) {
      if (i.remetente === "Motorista") {
        novovet = [...novovet, { author: 'them', type: 'text', data: { text: i.textolivre } }]
      } else {
        novovet = [...novovet, { author: 'me', type: 'text', data: { text: i.textolivre } }]
      }
    }
    console.log("PRE MANDAR")
    let reverso = novovet.reverse()
    messageList = reverso
    this.setState({ state: this.state });
    console.log("Mandei", messageList)
    this.timeoutid = setTimeout(this.loadMsg,6500);
    /*this.setState({
      messageList: reverso
    }, ()=> console.log("EU MANDEI", this.state.messageList))*/
  }

  async tipos() {
    let res = await this.webservice.tipo_msg()
    let res2 = await this.webservice.newmsg(String(this.props.central), 'motoristas/search?at=' + this.props.at + '&centrais=' + this.props.central)
    let jres2 = await res2.json(res2);
    console.log("DADOS DO MOTORISTA", jres2)
    var ultcorrida = '';
    jres2.forEach(m => {
      if (m.uuid333 === this.props.uuid) {
        //FAZER AMANAH ESSA BAGAÇA
        ultcorrida = m.ultima_corrida;
      }
    })
    console.log("sabendo a ultima corrida", ultcorrida)
    if(ultcorrida){
      let mresult = await this.webservice.fetch2('corrida/' + ultcorrida);
      let resultin = await mresult.json(mresult);
      console.log("SOU O RESULTIN", resultin)
    this.setState({
      tipo_msg: res,
      his: resultin[0],
    })
    }else{
      this.setState({
        tipo_msg: res,
      })
    }
   
    
  }
  componentDidMount() {
    this.tipos()
    
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  mostraCentral(motorista) {
    let motcentral = motorista;
    let obj = this.props.centrais.centrais.find((obj) => { return obj.id === Number(motcentral); });
    let nomecentral = obj.nomefantasia;
    // let nomecentral = this.props.central.centrais.map(c=>{
    //   if(Number(motcentral) === c.id){
    //     return c.nomefantasia
    //   }
    // })
    //console.log("nome da central", nomecentral);
    return nomecentral;
  }

  _onMessageWasSent(message) {
    console.log("MIM msg", message)
    this.envio(message.data.text)
    this.setState({
      messageList: [...messageList, message]
    })
  }

  async envio(msg) {

    let altf = '';
      console.log("STATE ", this.state.enviando2)
      this.state.respostas.map((resp, i) => {
        if (i === 0) {
          altf += resp
        } else {
          altf += "," + resp
        }
        return null;
      })

    let tev = /<>/
    let nmsg = null
    console.log("SOU O TIPO", this.state.tipoenvio)
    if (this.state.tipoenvio === 3) {
      nmsg = this.state.enviando2.replace(tev, this.state.his.endereco)
    }
    if (this.state.tipoenvio === 2) {
      nmsg = this.state.enviando2.replace(tev, msg)
    }
    if (this.state.tipoenvio === 1) {
      nmsg = this.state.enviando2
    }
    await this.webservice.newmsg(String(this.props.central), 'mensagens/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cpf: this.props.cpf,
        uuid: this.props.uuid,
        at: this.props.at,
        rt: this.props.rt,
        textolivre: nmsg !== null ? nmsg : msg,
        remetente: "monitor",
        alternativas: ((altf !== '') ? altf : " OK "),
      })
    })
    this.loadMsg()
  }

  _sendMessage(text) {
    console.log('opa', messageList)
    if (text.length > 0) {
      this.setState({
        messageList: [...messageList, {
          author: 'them',
          type: 'text',
          data: { text }
        }]
      })
    }
  }

  msgpai() {
    if (this.props.sendmsg) {
      console.log("OLHA O M AQUI CARAio", this.props.eme)
      this.props.sendmsg(this.props.eme);
    }
  }

  msgpai2() {
    console.log("result boys2", this.state.aberto)
    if (this.props.msgaberto) {
      this.props.msgaberto(!this.state.aberto)
    }
  }

  render() {
    return (<div style={{ marginLeft: 65 }}>
      <div style={{ position: 'fixed', bottom: '90px', width: '60x', backgroundColor: 'white' }}>
        <center> AT: {this.props.at} <br /> {this.mostraCentral(this.props.central)} </center>
      </div>
      {(this.props.aberto ? this.props.aberto : this.state.aberto) && <div style={{ position: 'fixed', zIndex: 1000, bottom: '155px', height: '40px', width: '358px'}}>
        <center><DropdownButton id="msgs_prontas" title="mensagens prontas">
          {/* {console.log("MENSAGEEEEM", this.state.tipo_msg)} */}
          {this.state.tipo_msg.map((tipo, i) => {
            return (<MenuItem key={i}
              onClick={() => {this.setState({
                enviando: ' ',
                enviando2: tipo.m04_msg,
                respostas: tipo.m04_resposta,
                tipoenvio: tipo.m04_cat_order,
                categoria: tipo.m04_categoria
              }, ()=> this.envio('a'));
                
              }}>{tipo.m04_categoria}</MenuItem>)
          })}
        </DropdownButton></center>
      </div>}
      <div className="chat-wrapper" onClick={(e) => { this.loadMsg(); console.log("ohla o e");  }}>
      {console.log("MASOQMEUDEUS", this.props.aberto)}
        <Launcher
          agentProfile={{
            teamName: 'AT:' + this.props.at,
            imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
          }}
          mute={true}
          onMessageWasSent={this._onMessageWasSent.bind(this)}
          newMessagesCount={1}
          isOpen={this.props.aberto ? this.props.aberto : this.state.aberto}
          handleClick={() => {
            if (this.props.aberto) {
              this.timeoutid = null;
              this.props.modalHide();
              this.msgpai();
            } else {
              this.setState({ aberto: !this.state.aberto });
              console.log("CLICADO NA PARADA", this.state.aberto);
              this.msgpai();
              this.msgpai2();
            }
          }}
          showEmoji={false}
          messageList={messageList}
        />
      </div>

    </div>)
  }
}

export default NewMsg;