import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Card, Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import WS from '../../services/webservice'
import { fitBounds } from 'google-map-react/utils'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CarOutlined,
  DollarCircleOutlined,
  PhoneOutlined
} from '@ant-design/icons'
// -------- GRAFICOS ----------------
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Line,
  Label
} from 'recharts'

import Motoristas from '../Motoristas'
import GoMap from '../GoMap.js'
import { FormControl, InputGroup, Button } from 'react-bootstrap'

const { Content } = Layout

// -------------- Estilos do componente

const StyledLayout = styled(Layout)`
  position: relative;
  width: 100%;
  height: 100%;
`

const StyledContentTop = styled(Content)`
  top: 0;
  left: 0;
  right: 0;
  height: 20vh;
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr;
  

  .ant-card-head {
    height: 40px;
    background-color: ${(props) => props.background};
    color: ${(props) => props.color};
  }
`

const StyledContentBottom = styled(Content)`
  bottom: 0;
  left: 0;
  right: 0;
  height: 80vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .ant-card-head {
    height: 40px;
    background-color: ${(props) => props.background};
    color: ${(props) => props.color};
  }
  .ant-card-header-title {
    padding: 8;
  }
`

const StyledCardTop = styled(Card)`
  position: relative;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  display: grid;
  background-color: #c1f7f7;
  margin: 10px;
  grid-template-rows: auto;
  grid-template-columns: auto;
  overflow: hidden;

  .span-top-name {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .span-top-value {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-weight: bold;
    font-size: 1.7em;
  }

  .ant-card-head {
    display: none;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const StyledCardTopTipoSolicitacao = styled(Card)`
  display: none;
`

const StyledCardBottom = styled(Card)`
  position: relative;
  text-align: initial;
  font-size: 1rem;
  font-weight: bold;
  display: grid;
  background-color: #f8ede3;
  margin: 10px;
  grid-template-rows: auto;
  grid-template-columns: auto;
  overflow: hidden;

  .ant-card-head {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .ant-card-head-title {
      font-size: 1.2rem;
      padding: 2px;
      text-align: center;
    }
  }

  .ant-card-body {
    height: 100vh;
    padding: 15px;

    /* .go-map {
      position: relative;
      width: 100%;
      height: 100%;
    } */
  }
`

// Estilos para o card de Solicitações
const Solicitacoes = styled.div`
  display: table;
  width: 100%;
  .solicitacoes-item {
    display: table-row;
  }
`
const SolicitacoesDescricao = styled.span`
  display: table-cell;
  font-size: 1rem;
  font-weight: bold;
  padding: 5px;
`
const SolicitacoesQuantidade = styled.span`
  display: table-cell;
  font-size: 1rem;
  font-weight: bold;
  color: #1890ff;
  text-align: right;
  padding: 5px;
`

// Estilos para o card de motoristas online
const MotoristasTable = styled.div`
  display: table;
  width: 100%;
`
const MotoristasTableRow = styled.div`
  display: table-row;
`
const MotoristasTableCell = styled.div`
  display: table-cell;
  font-weight: bold;
  padding: 5px;
`
const MotoristasTableCellValue = styled(MotoristasTableCell)`
  text-align: right;
  padding: 5px;
  color: ${(props) => props.color};
`

// Estilos para o card de formas de pagamento

const FormaPagamento = styled.div`
  display: table;
  width: 100%;
  .forma-pagamento-item {
    display: table-row;
  }
`
const FormaPagamentoDescricao = styled.span`
  display: table-cell;
  font-size: 1rem;
  font-weight: bold;
  padding: 5px;
`
const FormaPagamentoQuantidade = styled.span`
  display: table-cell;
  font-size: 1rem;
  font-weight: bold;
  color: #1890ff;
  text-align: right;
  padding: 5px;
`

// Estilos para o card de formas de Tipos de Solicitações

const TipoSolicitacao = styled.div`
  display: table;
  width: 100%;
  .tipo-solicitacao-item {
    display: table-row;
  }
`
const TipoSolicitacaoDescricao = styled.span`
  display: table-cell;
  font-size: 1rem;
  font-weight: bold;
  padding: 5px;
`
const TipoSolicitacaoQuantidade = styled.span`
  display: table-cell;
  font-size: 1rem;
  font-weight: bold;
  color: #1890ff;
  text-align: right;
  padding: 5px;
`

const GoMapContainer = styled.div`
  //make a div to place the content of the className go-map and the go-map-legend
  position: relative;
  width: 100%;
  height: 100%;

  // make both the map and the legend to be evenly spaced inside the StyledCardBottom

  .go-map {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .go-map-legend {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100%;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    border-left: 1px solid #ccc;
    overflow-y: auto;
  }
`

// -------------- Componente ----------------

export default class Dashboard2 extends Component {
  constructor(props) {
    super(props)
    this.webservice = new WS()
    this.getQtdeFormaPagamento = this.getQtdeFormaPagamento.bind(this)
    this.getTotalSolicitacoes = this.getTotalSolicitacoes.bind(this)
    this.updateDataMotoristas = this.updateDataMotoristas.bind(this)
    this.getTiposCorridas = this.getTiposCorridas.bind(this)
    this.markerCLick = this.markerCLick.bind(this)

    // ---------- Estados iniciais do componente
    this.state = {
      totalizadores: {},
      motoristasOnline: [],
      dadosOnline: 0,
      qtdeFormaPagamento: [],
      totalSolicitacoes: 0,
      motoristas: [],
      qtdeTipoSolicitacao: [],
      loadedbusca: true,
      error: null,
      motoristas: [],
      center: { lat: -25.47, lng: -49.29 },
      zoom: 11,
      dataPieGraph: [],
      arrPagamentos: [],
      graficoSolicitacoes: [],
      arrSolicitacoes: [],
      dataTeste: [],
      arrTipoSolicitacao: [],
      filteredRt: null
    }
  }

  COLORS = ['#8884d8', '#82ca9d', '#FFBB28', '#FF8042', '#AF19FF', '#FF0000']

  // ------------ Funções do componente

  async getTotalSolicitacoes() {
    //if (this.cancel || !this.mounted) return
    try {
      let solicitacoes = await this.webservice.getTotalSolicitacoes()
      let total = solicitacoes.reduce((acc, item) => {
        return acc + item.quantidade
      }, 0)

      let arrSolicitacoes = solicitacoes.map((item) => {
        return {
          status: item.status,
          quantidade: item.quantidade
        }
      })

      this.setState({ totalSolicitacoes: total })
      this.setState({ arrSolicitacoes })
    } catch (error) {
      return error
    }
  }

  async getQtdeFormaPagamento() {
    if (this.cancel) return
    try {
      let qtdePagamento = await this.webservice.getQtdeFormaPagamento()
      this.setState({
        qtdeFormaPagamento: qtdePagamento
      })

      let arrPagamentos = qtdePagamento.map((item) => {
        return {
          formaPagamentoDescricao: item.formaPagamentoDescricao,
          quantidade: parseInt(item.quantidade)
        }
      })

      this.setState({ arrPagamentos })
    } catch (error) {
      return error
    }
  }

  async updateDataMotoristas() {
    try {
      let result = await this.webservice.fetch2(
        'motoristas/?centrais=' +
          this.props.childProps.userAttrib.centralString.ids
      )
      result = await result.json()
      console.log('updateData', result)
      //let result2 = await this.webservice.fetch2(
      //  'mensagens/?centrais=' +
      //    this.props.childProps.userAttrib.centralString.ids
      //)
      //var msg2 = await result2.json()

      this.setState({
        motoristas: result.map((r) => {
          return { ...r, _uuid: r.uuid, color: r.livre ? 'green' : 'red' }
        }),
        mensagens: null
      })

      const bounds = {
        ne: {
          lat: result[0]?.latitude + 0.0001,
          lng: result[0]?.longitude + 0.0001
        },
        sw: {
          lat: result[0]?.latitude - 0.0001,
          lng: result[0]?.longitude - 0.0001
        }
      }
      result.forEach((m) => {
        if (m.latitude > bounds.ne.lat) {
          bounds.ne.lat = m.latitude
        }
        if (m.longitude > bounds.ne.lng) {
          bounds.ne.lng = m.longitude
        }
        if (m.latitude < bounds.sw.lat) {
          bounds.sw.lat = m.latitude
        }
        if (m.longitude < bounds.sw.lng) {
          bounds.sw.lng = m.longitude
        }
      })
      const size = {
        width: 440,
        height: 380
      }
      console.log(result)
      if (this.state.att === true) {
        const { center, zoom } = fitBounds(bounds, size)
        await this.setState({
          center: center,
          zoom: zoom,
          motoristas: result
        })
      }
    } catch (err) {
      console.log('err', err)
      return err
    }
  }

  async updateDataMotoristas2() {
    this.timerId = setInterval(this.updateDataMotoristas, 12000)
    let result3 = await this.webservice.fetch2('motoristas/search')
    var moto2 = await result3.json()
    console.log('**moto2', moto2)
    this.setState({
      motoristas2: moto2
    })

    await this.updateDataMotoristas()
  }

  markerCLick(m) {
    console.log('markerCLick', m)
    this.setState({
      selectedRt: m,
      showRtDetails: true
    })
  }

  get motoristasLivres() {
    return this.state.motoristas.filter((m) => m.livre).length
  }

  get motoristasOcupados() {
    return this.state.motoristas.filter((m) => !m.livre).length
  }

  async getTiposCorridas() {
    //if (this.cancel || !this.mounted) return
    let tiposCorridas = await this.webservice.getTiposCorridas()
    console.log('!tiposCorridas', tiposCorridas)
    this.setState({
      qtdeTipoSolicitacao: tiposCorridas
    })

    let arrTipoSolicitacao = tiposCorridas.map((item) => {
      return {
        descricao: item.descricao,
        past_24_hours: item.past_24_hours
      }
    })

    this.setState({
      arrTipoSolicitacao
    })
  }

  filtrarPorRt() {
    let teste = ReactDOM.findDOMNode(this.txtRt).value
    console.log('valor buscado:', teste)
    this.setState({
      filteredRt: teste
    })
    return true
  }

  // ------------- Hooks ciclo de vida do component --------------------

  async componentDidMount() {
    this.mounted = true
    this.cancel = false
    this.getQtdeFormaPagamento()
    this.getTotalSolicitacoes()
    this.updateDataMotoristas2()
    this.getTiposCorridas()
  }

  componentWillUnmount() {
    this.mounted = false
    this.cancel = true
    clearInterval(this.timerId)
  }

  // --------------- Renderização do componente
  render() {
    let marcadores = []
    const {
      arrSolicitacoes,
      COLORS,
      arrPagamentos,
      arrTipoSolicitacao,
      qtdeFormaPagamento
    } = this.state

    console.log('*AQUI*', this.state.qtdeTipoSolicitacao)

    if (this.state.filteredRt) {
      for (let index = 0; index < this.state.motoristas.length; index++) {
        const element = this.state.motoristas[index]
        if (element.dados) {
          if (element.dados.placa !== 'QWH9946') {
            if (element.dados.cpf) {
              if (
                element.dados.cpf.toLowerCase() ===
                this.state.filteredRt.toLowerCase()
              ) {
                marcadores.push(element)
              }
            }
            if (element.dados.cidadeatuacao) {
              if (
                element.dados.cidadeatuacao
                  .toLowerCase()
                  .indexOf(this.state.filteredRt.toLowerCase()) >= 0
              ) {
                marcadores.push(element)
              }
            }
            if (element.dados.nome) {
              if (
                element.dados.nome
                  .toLowerCase()
                  .indexOf(this.state.filteredRt.toLowerCase()) >= 0
              ) {
                marcadores.push(element)
              }
            }
            if (element.dados.placa) {
              if (
                element.dados.placa
                  .toLowerCase()
                  .indexOf(this.state.filteredRt.toLowerCase()) >= 0
              ) {
                marcadores.push(element)
              }
            }
          }
        }
      }
    } else {
      marcadores = this.state.motoristas
    }

    return (
      <StyledLayout>
        <StyledContentTop
          background={
            this.props.central && this.props.central.aplicativocor
              ? this.props.central.aplicativocor.background
              : 'lightgrey'
          }
          color={
            this.props.central && this.props.central.aplicativocor
              ? this.props.central.aplicativocor.foreground
              : 'black'
          }
        >
          <StyledCardTop title='Total de Solicitações' bordered={false}>
            <span className='span-top-name'>
              <PhoneOutlined /> Total de Solicitações:
            </span>
            <span
              className='span-top-value'
              style={{
                color: this.state.totalSolicitacoes !== 0 ? 'blue' : 'red'
              }}
            >
              {this.state.totalSolicitacoes}
            </span>
          </StyledCardTop>

          <StyledCardTop title='Motoristas online' bordered={false}>
            <span className='span-top-name'>
              <CarOutlined /> Motoristas Online:
            </span>
            <span
              className='span-top-value'
              style={{
                color: this.state.motoristas.length !== 0 ? 'blue' : 'red'
              }}
            >
              {this.state.motoristas.length !== 0
                ? this.state.motoristas.length
                : 0}
            </span>
          </StyledCardTop>

          <StyledCardTop title='Pagamento' bordered={false}>
            <span className='span-top-name'>
              <DollarCircleOutlined /> {'  '}
              Pagamentos:
            </span>
            <span
              className='span-top-value'
              style={{
                color:
                  this.state.qtdeFormaPagamento.length !== 0 ? 'blue' : 'red'
              }}
            >
              {this.state.qtdeFormaPagamento.length !== 0
                ? this.state.qtdeFormaPagamento
                    .map((item) => parseInt(item.quantidade, 10))
                    .reduce((a, b) => a + b, 0)
                : 0}
            </span>
          </StyledCardTop>
        </StyledContentTop>

        {/* ---------- Cards de baixo ------------ */}

        <StyledContentBottom
          background={
            this.props.central && this.props.central.aplicativocor
              ? this.props.central.aplicativocor.background
              : 'lightgrey'
          }
          color={
            this.props.central && this.props.central.aplicativocor
              ? this.props.central.aplicativocor.foreground
              : 'black'
          }
        >
          <StyledCardBottom title='Solicitações' bordered={false}>
            {this.state.arrSolicitacoes.length !== 0 ? (
              <div>
                <div>
                  {this.state.arrSolicitacoes &&
                    this.state.arrSolicitacoes.map((item, index) => {
                      return (
                        <Solicitacoes key={index}>
                          <SolicitacoesDescricao>
                            {item.status}
                          </SolicitacoesDescricao>
                          <SolicitacoesQuantidade>
                            {item.quantidade}
                          </SolicitacoesQuantidade>
                        </Solicitacoes>
                      )
                    })}
                </div>
                <div>
                  <ResponsiveContainer width='95%' height={400}>
                    <PieChart>
                      <Pie
                        data={arrSolicitacoes}
                        dataKey='quantidade'
                        nameKey='status'
                        cx='50%'
                        cy='50%'
                        fill='#8884d8'
                      >
                        {arrSolicitacoes.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={this.COLORS[index % this.COLORS.length]}
                          />
                        ))}
                      </Pie>

                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            ) : (
              <div
                style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}
              >
                <h3>Nenhuma solicitação encontrada</h3>
              </div>
            )}
          </StyledCardBottom>

          <StyledCardBottom title='Motoristas' defaultBordered={false}>
            <MotoristasTable>
              <MotoristasTableRow>
                <MotoristasTableCell>Livres:</MotoristasTableCell>
                <MotoristasTableCellValue color='green'>
                  <CheckCircleOutlined style={{ marginRight: '10px' }} />
                  {this.motoristasLivres}
                </MotoristasTableCellValue>
              </MotoristasTableRow>
              <MotoristasTableRow>
                <MotoristasTableCell>Ocupados:</MotoristasTableCell>
                <MotoristasTableCellValue color='red'>
                  <CloseCircleOutlined style={{ marginRight: '10px' }} />
                  {this.motoristasOcupados}
                </MotoristasTableCellValue>
              </MotoristasTableRow>
            </MotoristasTable>

            <div className='go-map'>
              <GoMap
                //EscondeAtalhos={true}
                MarcadorClick={this.markerCLick}
                width={'17%'}
                height={300}
                minZoom={7}
                maxZoom={18}
                markO={marcadores}
                //zoommanual={false}
              ></GoMap>
            </div>
            <div className='go-map-legend'>
              <InputGroup>
                <FormControl
                  style={{ height: 30 }}
                  onKeyDown={(e) =>
                    e.keyCode === 13 &&
                    this.filtrarPorRt() &&
                    e.preventDefault()
                  }
                  ref={(ref) => (this.txtRt = ref)}
                  placeholder='Buscar Motorista'
                />
                <InputGroup.Button>
                  <Button onClick={this.filtrarPorRt} style={{ height: 30 }}>
                    Buscar
                  </Button>
                </InputGroup.Button>
              </InputGroup>
            </div>
          </StyledCardBottom>

          <StyledCardBottom title='Formas de Pagamento' bordered={false}>
            {this.state.arrPagamentos.length !== 0 ? (
              <div>
                <div>
                  <FormaPagamento>
                    {this.state.qtdeFormaPagamento.map((item) => (
                      <div
                        key={item.formaPagamentoDescricao}
                        className='forma-pagamento-item'
                      >
                        <FormaPagamentoDescricao>
                          {item.formaPagamentoDescricao}
                        </FormaPagamentoDescricao>
                        <FormaPagamentoQuantidade>
                          {item.quantidade}
                        </FormaPagamentoQuantidade>
                      </div>
                    ))}
                  </FormaPagamento>
                </div>
                <div>
                  <ResponsiveContainer width='95%' height={300}>
                    <PieChart>
                      <Pie
                        data={arrPagamentos}
                        dataKey='quantidade'
                        nameKey='formaPagamentoDescricao'
                        cx='50%'
                        cy='50%'
                        fill='#8884d8'
                      >
                        {arrPagamentos.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={this.COLORS[index % this.COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            ) : (
              <div
                style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}
              >
                <h3>Nenhuma Forma de Pagamento encontrada</h3>
              </div>
            )}
          </StyledCardBottom>

          <StyledCardBottom title='Tipos de Solicitações' bordered={false}>
            {this.state.arrTipoSolicitacao.length !== 0 ? (
              <div>
                <div>
                  <TipoSolicitacao>
                    {this.state.qtdeTipoSolicitacao.map((item, index) => (
                      <div key={index} className='tipo-solicitacao-item'>
                        <TipoSolicitacaoDescricao>
                          {item.descricao}
                        </TipoSolicitacaoDescricao>
                        <TipoSolicitacaoQuantidade>
                          {item.past_24_hours}
                        </TipoSolicitacaoQuantidade>
                      </div>
                    ))}
                  </TipoSolicitacao>
                </div>
                <div>
                  <ResponsiveContainer width='95%' height={400}>
                    <PieChart>
                      <Pie
                        data={arrTipoSolicitacao}
                        dataKey='past_24_hours'
                        nameKey='descricao'
                        cx='50%'
                        cy='50%'
                        fill='#8884d8'
                      >
                        {arrTipoSolicitacao.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={this.COLORS[index % this.COLORS.length]}
                          />
                        ))}
                      </Pie>

                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            ) : (
              <div
                style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}
              >
                <h3>Nenhum tipo de Solicitação encontrado</h3>
              </div>
            )}
          </StyledCardBottom>
        </StyledContentBottom>
      </StyledLayout>
    )
  }
}
