import WS from '../services/webservice';
import React, { Component } from 'react';
import ReactDom from 'react-dom'
import { Modal, Row, Col } from 'react-bootstrap'

class PopUp extends Component {

  constructor(props) {
    super(props);

    this.webservice = new WS();
  }


  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={this.props.modalHide}
        onEntered={function () { ReactDom.findDOMNode(this.refs.corrida0).focus(); this.props.changePopUpRefs(this.refs) }.bind(this)}
        container={this}
        aria-labelledby="contained-modal-title"
      >
        <Modal.Body>
          <Row className="titulo-antigos">
            <Col xs={6} md={10}>
              <div>Endereço</div>
            </Col>
            <Col xs={6} md={2}>
              <div>Número</div>
            </Col>
          </Row>
          {this.props.corridas.map((m, i) => {
            return (
              <Row key={i} ref={'corrida' + i} tabIndex="0" className="enderecos-antigos" onClick={e => {
                this.props.changeModalShow(false);
                  this.props.changeEndereco(m.endereco, m.cidade, m.estado, m.bairro, this.props.addressType, null, null);
                this.props.changeNumber(m.numero, this.props.addressType);
                this.webservice.geocodeByPlaceId(m.endereco, m.numero, m.cidade).then(result => {
                  if (m.cidade === '' || m.estado === '' || m.bairro === '') {
                    let endereco = '';
                    let cidade = '';
                    let estado = '';
                    let bairro = '';
                    result.moreinfo.address_components.map((el) => {
                      if (el.types[0] === "route") endereco = el.long_name;
                      else if (el.types[0] === "administrative_area_level_2") cidade = el.long_name;
                      else if (el.types[0] === "administrative_area_level_1") estado = el.short_name;
                      else if (el.types[0] === "sublocality_level_1" || el.types[1] === "sublocality_level_1" || el.types[2] === "sublocality_level_1") bairro = el.long_name;
                      return null;
                    })
                    this.props.changeEndereco(endereco, cidade, estado, bairro, this.props.addressType, null, null)
                    this.props.changeNumber(m.numero, this.props.addressType)
                  }
                  this.props.changeCenter(result.latitude, result.longitude)
                  this.props.changeMarker(result.latitude, result.longitude, this.props.markerImg)
                })
              }}
              >
                <Col xs={6} md={10}>
                  <div>{m.endereco}</div>
                </Col>
                <Col xs={6} md={2}>
                  <div>{m.numero}</div>
                </Col>
              </Row>
            )
          })
          }
        </Modal.Body>
      </Modal>
    );
  }
}
export default PopUp;