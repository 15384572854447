import React, { Component, useRef } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Select from 'react-select';
import InputMask from 'react-input-mask';

import { Row, OverlayTrigger, Popover, Image, Checkbox, Modal, Breadcrumb, Button, Form, FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap'
const md5 = require('md5');

class MotoristaCentral extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      salvos: null,
      showBanco: false,
      showPadronizador: false,
      detalhemotorista: {},
      showdetalhemotorista: false,
      detalhecarro: {},
      showdetalhecarro: false,
      dadosbanco: {},
      showFotos: false
    }
    this.ws = new WS();
    this.showPadronizador = this.showPadronizador.bind(this);
    this.modalHide = this.modalHide.bind(this);
    this.modalHideBanco = this.modalHideBanco.bind(this);
    this.salvar = this.salvar.bind(this);
    this.salvarbanco = this.salvarbanco.bind(this);
    this.hidedetalhemotorista = this.hidedetalhemotorista.bind(this);
    this.showdetalhemotorista = this.showdetalhemotorista.bind(this);
    this.showcreditosmotoristacentral = this.showcreditosmotoristacentral.bind(this);

    this.hidedetalhecarro = this.hidedetalhecarro.bind(this);
    this.showdetalhecarro = this.showdetalhecarro.bind(this);

    this.salvadetalhecarro = this.salvadetalhecarro.bind(this);
    this.salvadetalhemotorista = this.salvadetalhemotorista.bind(this);

    //this.cadastrodriver = useRef();
    this.cadastrodriver_ref = this.updateRef.bind(this, 'cadastrodriver');
    this.creditosmotoristacentral_ref = this.updateRef.bind(this, 'creditosmotoristacentral');
    this.renderTonggle = this.renderTonggle.bind(this);
  }

  renderTonggle() {
    return (
      <div
        style={{
          border: "1.5px solid #000",
          borderRadius: "10px",
          padding: "5px",
          fontWeight: "bold",
        }}
        onClick={() => this.setState({ showFotos: !this.state.showFotos })}
      >
        {this.state.showFotos ? "Mostrar Cadastro" : "Mostrar Fotos"}
</div>
    );
  }
  updateRef(name, ref) {
    this[name] = ref;
  }
  salvar() {
    this.ws.postItens(this.state.salvos);
    this.modalHide();
  }
  salvarbanco() {
    this.ws.postbanco(this.state.dadosbanco);
    this.modalHideBanco();
  }
  mirrorState(key) {
    this.setState(key);
  }
  modalHideBanco() {
    this.setState({showBanco: false})
    if (this.state.after) {
      this.state.after();
    }
  }

  async salvadetalhemotorista() {
    let id = this.state.detalhemotorista.t08_cpf_sec;
    let dadosbase = JSON.parse(JSON.stringify(this.state.detalhemotorista));

    let fotobase64 = {fotobase64: dadosbase.fotobase64}
    let fotobase64_1 = {fotobase64_1: dadosbase.fotobase64_1}
    let fotobase64_2 = {fotobase64_2: dadosbase.fotobase64_2}
    let fotobase64_3 = {fotobase64_3: dadosbase.fotobase64_3}
    let foto_carro_1 = {foto_carro_1: dadosbase.foto_carro_1}
    let foto_carro_2 = {foto_carro_2: dadosbase.foto_carro_2}
    let foto_carro_3 = {foto_carro_3: dadosbase.foto_carro_3}
    let foto_carro_4 = {foto_carro_4: dadosbase.foto_carro_4}
    let foto_carro_5 = {foto_carro_5: dadosbase.foto_carro_5}
    let foto_carro_6 = {foto_carro_6: dadosbase.foto_carro_6}
    let foto_carro_7 = {foto_carro_7: dadosbase.foto_carro_7}
    delete dadosbase.fotobase64;
    delete dadosbase.fotobase64_1;
    delete dadosbase.fotobase64_2;
    delete dadosbase.fotobase64_3;
    delete dadosbase.foto_carro_1;
    delete dadosbase.foto_carro_2;
    delete dadosbase.foto_carro_3;
    delete dadosbase.foto_carro_4;
    delete dadosbase.foto_carro_5;
    delete dadosbase.foto_carro_6;
    delete dadosbase.foto_carro_7;

    await this.ws.postExtras('cadastrodriver',id, dadosbase);
    await this.ws.postExtras('cadastrodriver',id, fotobase64);
    await this.ws.postExtras('cadastrodriver',id, fotobase64_1);
    await this.ws.postExtras('cadastrodriver',id, fotobase64_2);
    await this.ws.postExtras('cadastrodriver',id, fotobase64_3);
    await this.ws.postExtras('cadastrodriver',id, foto_carro_1);
    await this.ws.postExtras('cadastrodriver',id, foto_carro_2);
    await this.ws.postExtras('cadastrodriver',id, foto_carro_3);
    await this.ws.postExtras('cadastrodriver',id, foto_carro_4);
    await this.ws.postExtras('cadastrodriver',id, foto_carro_5);
    await this.ws.postExtras('cadastrodriver',id, foto_carro_6);
    await this.ws.postExtras('cadastrodriver',id, foto_carro_7);

    this.hidedetalhemotorista();
    if (this.state.after) {
      this.state.after();
    }
  }
  hidedetalhemotorista() {
    this.setState({showdetalhemotorista: false, detalhemotorista:{}})
  }
  async showdetalhemotorista(m, after) {
    let cpf = m.cpf;
    //getDados Motorista
    let detalhemotorista = await this.ws.getTipo2('cadastrodriver',cpf);
    this.cadastrodriver.ClickExtertno(detalhemotorista, after);
    //this.setState({detalhemotorista: detalhemotorista, showdetalhemotorista: true, after: after})
  }
async showcreditosmotoristacentral(m, after) {
  let cpf = m.cpf;
  let valor = Math.random();
  let id =  md5(valor);
  let centralID = this.ws.getCookie('central-id');
  let itens = {id: id.toString().substring(0,32),
    centralmotorista_cpf: cpf,
    centralmotorista_central: centralID,
    descritivo: 'Inclusão de créditos',
    fotorecibo: '',
    tipotransacao: 0,
    saldo: 0,
    creditos: 0,
    status: 0,
  };
  this.creditosmotoristacentral.ClickExtertno(itens,after, true);
}
  async salvadetalhecarro() {
    let carroid = this.state.detalhecarro.t07_num_seq_carro;
    await this.ws.postExtras('cadastrocar',carroid, this.state.detalhecarro);
    this.hidedetalhecarro();
    if (this.state.after) {
      this.state.after();
    }
  }
  hidedetalhecarro() {
    this.setState({detalhecarro: {}, showdetalhecarro: false})
  }
  async showdetalhecarro(m, after) {
    let carroid = m.t08_motoristas_t08_num_seq_carro;
    //getDados Motorista
    let detalhecarro = await this.ws.getTipo2('cadastrocar',carroid);
    this.setState({detalhecarro: detalhecarro, showdetalhecarro: true, after: after})

    console.log('****showdetalhecarro', detalhecarro)
  }

  modalHide() {
    this.setState({showPadronizador: false})
  }
  atualizabanco(p, after) {
    this.setState({dadosbanco:p, showBanco: true, after: after})

  }
  showPadronizador() {
    console.log('showPadronizador')
    if (this.state.sublistas !== undefined) {
      if (this.state.sublistas.motorista_central_tipos !== undefined) {
        let salvos = this.state.sublistas.motorista_central_tipos.map( (a,b) => a.id)
        this.setState({showPadronizador: true, salvos: salvos})
        console.log('showPadronizador', salvos)
      }
    }
  }
  render() {
    return (
      <>
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "cpf",
          tipo_cadastro: "Motoristas",
          tipo_plural: "Motoristas",
          href: "motorista_central",
          titulo: "Controle de Motoristas",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          extrabutton: (() => {this.showPadronizador()}).bind(this),
          extrabuttontexto: 'Atualizar Todos',
          organizado: [
            [{ref: 'cpf', tipo: 'campo'}],
            [{ref: 't08_motoristas_t08_nomemotorista', tipo: 'campo'}],
            [{ref: 't08_motoristas_email', tipo: 'campo'}],
            [{ref: 't08_motoristas_cidadeatuacao', tipo: 'campo'}],
            [{ref: 't08_motoristas_t08_telefone1', tipo: 'campo'}],
            [{ref: 't07_carro_t07_placa_detran', tipo: 'campo'}],
            [{ref: 't07_carro_t07_cd_marca', tipo: 'campo'}],
            [{ref: 't07_carro_t07_cd_modelo', tipo: 'campo'}],
            [{ref: 'categoriacarro', tipo: 'campo'}],
            [{ref: 'tiposcarro', tipo: 'campo'}],
            [{ref: 'status', tipo: 'campo'}],
            [{ref: 'saldo', tipo: 'campo'}],
            [{ref: 'pinaplicativo', tipo: 'campo'}],
            [{ref: 'codigos', tipo: 'campo'}],
            [{ref: 'dadosmotoristaindicador', tipo: 'campo'}],
            [{ref: 'dadosmotoristaindicados', tipo: 'campo'}],
          ],
          requests: {
            webservice: this.ws,
            type: 'motorista_central',
            list: true,
            save: true,
            edit: true,
            delete: false,
            listSub: true,
            defaultorder: [{ id: "t08_motoristas.t08_nomemotorista" }, { id: "t08_motoristas.email" }],
            defaultfilter: [{id: "status", value: "0"}],
            outras: [
              {icone: 'FaUser', tamanho: 22, cor: 'red', texto:'Atualizar Dados do Motorista' ,funcao:this.showdetalhemotorista.bind(this)},
              {icone: 'FaCar', tamanho: 22, cor: 'black', texto:'Atualizar Dados do Carro' ,funcao:this.showdetalhecarro.bind(this)},
            ],
          },
          subtipos: [
            {tipo: 'motorista_central_tipos', id: 'tipo', txt: 'lista', p:'get'},
            {tipo: 'marcaveiculo', id: 't17_cd_marca', txt: 't17_desc_carro', p:'get'},
            {tipo: 'modeloveiculo', id: 't18_cd_modelo', txt: 't18_desc_modelo', p:'get'},
            {tipo: 'status',p:'oarray', array:[{ txt: 'Ativo', id: 0 }, { txt: 'Inativo', id: 1 }]},
            {tipo: 'categoria',p:'oarray', array:[{ txt: 'Carro Convencional', id: 'CTP' }, { txt: 'Táxi Convencional', id: 'CTX' }]},
          ],
          campos: [
            {
              ref: "cpf",
              label: "CPF",
              type: "justlabel",
            },
            {
              ref: "t08_motoristas_t08_nomemotorista",
              label: "Nome",
              type: "justlabel",
              filter: 't08_motoristas.t08_nomemotorista',
            },
            {
              ref: "t08_motoristas_cidadeatuacao",
              label: "Cidade de Atuação",
              type: "justlabelupper",
              filter: 't08_motoristas.cidadeatuacao',
            },
            {
              ref: "t08_motoristas_t08_telefone1",
              label: "Telefone",
              type: "justlabel",
              filter: 't08_motoristas.t08_telefone1',
            },
            {
              ref: "t07_carro_t07_placa_detran",
              label: "Placa",
              type: "justlabelupper",
              filter: 't07_carro.t07_placa_detran',
            },
            {
              ref: "t07_carro_t07_cd_marca",
              label: "Marca",
              type: "select",
              listtype:'marcaveiculo',
              disabled: true,
              filter: 't07_carro.t07_cd_marca',
            },
            {
              ref: "t07_carro_t07_cd_modelo",
              label: "Modelo",
              type: "select",
              listtype:'modeloveiculo',
              disabled: true,
              filter: 't07_carro.t07_cd_modelo',
            },
            {
              ref: "categoriacarro",
              label: "Categoria",
              type: "select",
              listtype:'categoria',
            },
            {
              ref: "tiposcarro",
              label: "Tipo do carro",
              type: "justlabelupper",
            },
            {
              ref: "status",
              label: "Situação",
              type: "select",
              listtype:'status',
            },
            {
              ref: "saldo",
              label: "Saldo",
              type: "currencydisabled",
            },
            {
              ref: "pinaplicativo",
              label: "Senha Aplicativo",
            },
            {
              ref: "codigos",
              label: "Código", type: "checkboxnexted", placeHolder: "", listtype:'motorista_central_tipos'
            },
          ],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "CPF",
                accessor: "cpf",
              },
              {
                accessor: "t08_motoristas_t08_nomemotorista",
                Header: "Nome",
              },
              {
                accessor: "t08_motoristas_email",
                Header: "Email",
                Cell: row => {
                  return (<div>{row.original.t08_motoristas_email ? row.original.t08_motoristas_email.toUpperCase() : ''}</div>)
                },
              },
              {
                accessor: "t08_motoristas_cidadeatuacao",
                Header: "Cidade de Atuação",
              },
              {
                accessor: "t07_carro_t07_placa_detran",
                Header: "Placa",
                Cell: row => {
                  return (<div>{row.original.t07_carro_t07_placa_detran ? row.original.t07_carro_t07_placa_detran.toUpperCase() : ''}</div>)
                },
              },
              {
                accessor: "t08_motoristas_t08_telefone1",
                Header: "Telefone",
              },
              {
                Header: "Situação",
                accessor: "status",
                width: 150,
                Cell: row => {
                  return (<div>{row.original.status === 0 ? 'Ativo' : row.original.status === 1 ? 'Inativo' : '-'}</div>)

                },
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : 0}
                  >
                    <option value={0}>Ativo</option>
                    <option value={1}>Inativo</option>
                  </select>
              },
              {
                Header: "Senha",
                accessor: "pinaplicativo",
              },
            ],
          }
        }}
      />








      <CadastroHelper
      ref = {this.cadastrodriver_ref}
        dados={{
          parentProps: this.props,
          id: "t08_cpf_sec",
          tipo_cadastro: "Motoristas",
          tipo_plural: "Motoristas",
          href: "motorista_central",
          titulo: "Controle de Motoristas",
          onlymodal: true,
          mirrorState: ((key) => {}).bind(this),
          requests: {
            webservice: this.ws,
            type: 'cadastrodriver',
            list: true,
            save: true,
            edit: true,
            delete: false,
            listSub: true,
          },
          organizado: this.state.showFotos ? [[{ ref: "tonggleFotos", tipo: "fct", function: this.renderTonggle }],
          [{ref: 'ident', tipo: 'texto', texto:'FOTO'}, {ref: 'ident', tipo: 'texto', texto:'CNH'},],
          [{ref: 'fotobase64', tipo: 'campo', hidelabel: true}, {ref: 'fotobase64_1', tipo: 'campo', hidelabel: true}],
          [{ref: 'ident', tipo: 'texto', texto:'CRLV'}, {ref: 'ident', tipo: 'texto', texto:'COMPROVANTE'}],
          [{ref: 'fotobase64_2', tipo: 'campo', hidelabel: true}, {ref: 'fotobase64_3', tipo: 'campo', hidelabel: true}],

          [{ref: 'ident', tipo: 'texto', texto:'foto_carro_1'}, {ref: 'ident', tipo: 'texto', texto:'foto_carro_2'}],
          [{ref: 'foto_carro_1', tipo: 'campo', hidelabel: true}, {ref: 'foto_carro_2', tipo: 'campo', hidelabel: true}],
          [{ref: 'ident', tipo: 'texto', texto:'foto_carro_3'}, {ref: 'ident', tipo: 'texto', texto:'foto_carro_4'}],
          [{ref: 'foto_carro_3', tipo: 'campo', hidelabel: true}, {ref: 'foto_carro_4', tipo: 'campo', hidelabel: true}],
          [{ref: 'ident', tipo: 'texto', texto:'foto_carro_5'}, {ref: 'ident', tipo: 'texto', texto:'foto_carro_6'}],
          [{ref: 'foto_carro_5', tipo: 'campo', hidelabel: true}, {ref: 'foto_carro_6', tipo: 'campo', hidelabel: true}],
          [{ref: 'ident', tipo: 'texto', texto:'foto_carro_7'}],
          [{ref: 'foto_carro_7', tipo: 'campo', hidelabel: true}],
        
        ] : [
          [{ ref: "tonggleFotos", tipo: "fct", function: this.renderTonggle }],
            [{ref: 't08_cpf_sec', tipo: 'campo'}],
            [{ref: 't08_nomemotorista', tipo: 'campo'}],
            [{ref: 'email', tipo: 'campo'}],
            [{ref: 'chave_pix', tipo: 'campo'}],
            [{ref: 't08_telefone1', tipo: 'campo'}],
            [{ref: 't08_cnh', tipo: 'campo'},{ref: 't08_rg', tipo: 'campo'}],
            [{ref: 't08_endereco', tipo: 'campo'},{ref: 'numero', tipo: 'campo'}],
            [{ref: 'complemento', tipo: 'campo'},{ref: 't08_bairro', tipo: 'campo'}],
            [{ref: 't08_cidade', tipo: 'campo'},{ref: 'uf', tipo: 'campo'},{ref: 't08_cep', tipo: 'campo'}],
          ],
          campos: [
            {
              ref: "fotobase64",
              label: "Foto",
              type: "foto64",
            },
            {
              ref: "fotobase64_1",
              label: "CNH",
              type: "foto64",
            },
            {
              ref: "fotobase64_2",
              label: "CRLV",
              type: "foto64",
            },
            {
              ref: "fotobase64_3",
              label: "Comprovante",
              type: "foto64",
            },
            { ref: "foto_carro_1", label: "foto_carro_1", type: "foto", },
            { ref: "foto_carro_2", label: "foto_carro_2", type: "foto", },
            { ref: "foto_carro_3", label: "foto_carro_3", type: "foto", },
            { ref: "foto_carro_4", label: "foto_carro_4", type: "foto", },
            { ref: "foto_carro_5", label: "foto_carro_5", type: "foto", },
            { ref: "foto_carro_6", label: "foto_carro_6", type: "foto", },
            { ref: "foto_carro_7", label: "foto_carro_7", type: "foto", },
            {
              ref: "t08_cpf_sec",
              label: "CPF",
              type: "justlabel",
            },
            {
              ref: "chave_pix",
              label: "Chave Pix",
              type: "text",
            },
            {
              ref: "email",
              label: "E-mail",
              type: "text",
            },
            {
              ref: "t08_nomemotorista",
              label: "Nome",
              type: "text",
            },
            {
              ref: "t08_telefone1",
              label: "Telefone",
              type: "text",
            },
            {
              ref: "t08_cnh",
              label: "CNH",
              type: "text",
            },
            {
              ref: "t08_rg",
              label: "RG",
              type: "text",
            },
            {
              ref: "t08_endereco",
              label: "Rua",
              type: "text",
            },
            {
              ref: "numero",
              label: "Número",
              type: "text",
            },
            {
              ref: "complemento",
              label: "Complemento",
              type: "text",
            },
            {
              ref: "t08_bairro",
              label: "Bairro",
              type: "text",
            },
            {
              ref: "t08_cidade",
              label: "Cidade",
              type: "text",
            },
            {
              ref: "uf",
              label: "UF",
              type: "text",
            },
            {
              ref: "t08_cep",
              label: "CEP",
              type: "text",
            },
          ],
          botoes: [
            
          ],
          table: {
            colunas: [
              {
                Header: "CPF",
                accessor: "t08_cpf_sec",
              },
            ],
          }
        }}
      />


<CadastroHelper
      ref = {this.creditosmotoristacentral_ref}
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Créditos",
          tipo_plural: "Créditos",
          href: "motorista_central",
          titulo: "Créditos de Motoristas",
          onlymodal: true,
          mirrorState: ((key) => {}).bind(this),
          requests: {
            webservice: this.ws,
            type: 'creditosmotoristacentral',
            list: true,
            save: true,
            edit: true,
            delete: false,
            listSub: true,
          },
          campos: [
            { ref: "id", label: "ID", type: "justlabel", },
            { ref: "centralmotorista_cpf", label: "CPF", type: "justlabel", },
            { ref: "centralmotorista_central", label: "CENTRAL", type: "justlabel", },
            { ref: "descritivo", label: "Descrição", type: "text", },
            { ref: "creditos", label: "creditos", type: "hidden", },
            { ref: "saldo", label: "Valor", type: "currency", },
            { ref: "status", label: "status", type: "hidden", },
            { ref: "tipotransacao", label: "tipotransacao", type: "hidden", },
            { ref: "fotorecibo", label: "Recibo", type: "foto64", },
          ],
          botoes: [
            
          ],
          table: {
            colunas: [
              {
                Header: "id",
                accessor: "id",
              },
            ],
          }
        }}
      />




      
        <Modal
          keyboard
          show={this.state.showdetalhecarro}
          onHide={this.hidedetalhecarro}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header>
            <Modal.Title>Dados Do Veículo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.state.detalhecarro.t07_placa_detran && this.state.sublistas && this.state.sublistas.marcaveiculo &&
            <div>
                <Row  key={'t07_placa_detran'}>
                  <Col sm={6}><ControlLabel>Placa do Detran</ControlLabel></Col>
                  <Col sm={6}>
                  <InputMask
                    value={this.state.detalhecarro.t07_placa_detran ? this.state.detalhecarro.t07_placa_detran : ''}
                    ref={'t07_placa_detran'}
                    className="form-control"
                    mask={'aaa9**9'}
                    maskChar="_"
                    placeholder={'Placa do Detran'}
                    onChange={e => { 
                      let tmp = this.state.detalhecarro;
                      tmp.t07_placa_detran = e.target.value;
                      this.setState({
                        detalhecarro: tmp
                      })
                     }}
                  />
                  </Col>
                </Row>
                <Row  key={'t07_renavan'}>
                  <Col sm={6}><ControlLabel>Renavam</ControlLabel></Col>
                  <Col sm={6}>
                  <FormControl
                    value={this.state.detalhecarro.t07_renavan ? this.state.detalhecarro.t07_renavan : ''}
                    ref={'t07_placa_detran'}
                    className="form-control"
                    placeholder={'Renavam'}
                    onChange={e => { 
                      let tmp = this.state.detalhecarro;
                      tmp.t07_renavan = e.target.value;
                      this.setState({
                        detalhecarro: tmp
                      })
                     }}
                  />
                  </Col>
                </Row>
                <Row  key={'t07_ano_fabricacao'}>
                  <Col sm={6}><ControlLabel>Ano de Fabricação</ControlLabel></Col>
                  <Col sm={6}>
                  <InputMask
                    value={this.state.detalhecarro.t07_ano_fabricacao ? this.state.detalhecarro.t07_ano_fabricacao : ''}
                    ref={'t07_placa_detran'}
                    className="form-control"
                    mask={'9999'}
                    maskChar="_"
                    placeholder={'Ano de Fabricação'}
                    onChange={e => { 
                      let tmp = this.state.detalhecarro;
                      tmp.t07_ano_fabricacao = e.target.value;
                      this.setState({
                        detalhecarro: tmp
                      })
                     }}
                  />
                  </Col>
                </Row>
                <Row  key={'anomodelo'}>
                  <Col sm={6}><ControlLabel>Ano do Modelo</ControlLabel></Col>
                  <Col sm={6}>
                  <InputMask
                    value={this.state.detalhecarro.anomodelo ? this.state.detalhecarro.anomodelo : ''}
                    ref={'t07_placa_detran'}
                    className="form-control"
                    mask={'9999'}
                    maskChar="_"
                    placeholder={'Ano do Modelo'}
                    onChange={e => { 
                      let tmp = this.state.detalhecarro;
                      tmp.anomodelo = e.target.value;
                      this.setState({
                        detalhecarro: tmp
                      })
                     }}
                  />
                  </Col>
                </Row>
                <Row  key={'t07_cd_marca'}>
                  <Col sm={6}><ControlLabel>Marca</ControlLabel></Col>
                  <Col sm={6}>
                  <Select
                    ref={'banco'}
                    value={this.state.detalhecarro.t07_cd_marca !== undefined ? 
                      this.state.sublistas.marcaveiculo.find(x => x.id === Number(this.state.detalhecarro.t07_cd_marca)) : null}
                    options={this.state.sublistas.marcaveiculo}
                    onChange={e => {
                      let tmp = this.state.detalhecarro;
                      tmp.t07_cd_marca = e.id;
                      this.setState({
                        detalhecarro: tmp
                      })
                    }}
                    isSearchable={false}
                    placeholder={'Marca do Veículo'}
                  />
                  </Col>
                </Row>
                <Row  key={'t07_cd_modelo'}>
                  <Col sm={6}><ControlLabel>Modelo</ControlLabel></Col>
                  <Col sm={6}>
                  <Select
                    ref={'t07_cd_modelo'}
                    value={this.state.detalhecarro.t07_cd_modelo !== undefined ? 
                      this.state.sublistas.modeloveiculo.find(x => x.id === Number(this.state.detalhecarro.t07_cd_modelo)) : null}
                    options={this.state.sublistas.modeloveiculo}
                    onChange={e => {
                      let tmp = this.state.detalhecarro;
                      tmp.t07_cd_modelo = e.id;
                      this.setState({
                        detalhecarro: tmp
                      })
                    }}
                    isSearchable={false}
                    placeholder={'Marca do Veículo'}
                  />
                  </Col>
                </Row>

            </div>}
            <div>
                    <Button onClick={this.salvadetalhecarro}>Salvar</Button>
                    <Button onClick={this.hidedetalhecarro}>Cancelar</Button>
                  </div>
          </Modal.Body>
        </Modal>
      <Modal
          keyboard
          show={this.state.showBanco}
          onHide={this.modalHideBanco}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header>
            <Modal.Title>Dados Bancários</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {console.log('teste', [this.state.dadosbanco, this.state.sublistas])}
            {this.state.dadosbanco.cpf && this.state.sublistas && this.state.sublistas.tabelabancos &&
            <div>
                <Row  key={'Nome'}>
                  <Col sm={6}><ControlLabel>Nome</ControlLabel></Col>
                  <Col sm={6}>{this.state.dadosbanco.t08_motoristas_t08_nomemotorista}</Col>
                </Row>
                <Row  key={'CPF'}>
                  <Col sm={6}><ControlLabel>CPF</ControlLabel></Col>
                  <Col sm={6}>{this.state.dadosbanco.cpf}</Col>
                </Row>
                <Row  key={'banco'}>
                  <Col sm={6}><ControlLabel>Banco</ControlLabel></Col>
                  <Col sm={6}>
                  {console.log('t08_motoristas_banco', [this.state.dadosbanco.t08_motoristas_banco, Number(this.state.dadosbanco.t08_motoristas_banco)])}
                  {console.log('t08_motoristas_banco', [this.state.sublistas.tabelabancos, Number(this.state.dadosbanco.t08_motoristas_banco)])}
                  <Select
                    ref={'banco'}
                    value={this.state.dadosbanco.t08_motoristas_banco !== undefined ? this.state.sublistas.tabelabancos.find(x => x.id === Number(this.state.dadosbanco.t08_motoristas_banco)) : null}
                    options={this.state.sublistas.tabelabancos}
                    onChange={e => {
                      let bco = this.state.dadosbanco;
                      bco.t08_motoristas_banco = e.id;
                      this.setState({
                        dadosbanco: bco
                      })
                    }}
                    isSearchable={false}
                    placeholder={'Banco'}
                  />
                  </Col>
                </Row>
                <Row  key={'tipo'}>
                  <Col sm={6}><ControlLabel>Tipo da conta</ControlLabel></Col>
                  <Col sm={6}>
                  <Select
                    ref={'tipo'}
                    value={this.state.dadosbanco.t08_motoristas_tipoconta !== undefined ? this.state.sublistas.tipoconta.find(x => x.id === Number(this.state.dadosbanco.t08_motoristas_tipoconta)) : null}
                    options={this.state.sublistas.tipoconta}
                    onChange={e => {
                      let bco = this.state.dadosbanco;
                      bco.t08_motoristas_tipoconta = e.id;
                      this.setState({
                        dadosbanco: bco
                      })
                    }}
                    isSearchable={false}
                    placeholder={'tipo'}
                  />
                  </Col>
                </Row>
                <Row  key={'Agencia'}>
                  <Col sm={6}><ControlLabel>Agencia ({this.state.dadosbanco.t08_motoristas_banco !== undefined ? this.state.sublistas.tabelabancos.find(x => x.id === Number(this.state.dadosbanco.t08_motoristas_banco)).agencia : '9999'})</ControlLabel></Col>
                  <Col sm={6}>
                  <InputMask
                    value={this.state.dadosbanco.t08_motoristas_numeroagencia}
                    ref={'agencia'}
                    className="form-control"
                    mask={this.state.dadosbanco.t08_motoristas_banco !== undefined ? this.state.sublistas.tabelabancos.find(x => x.id === Number(this.state.dadosbanco.t08_motoristas_banco)).agencia : '9999'}
                    maskChar="_"
                    placeholder={'Agencia'}
                    onChange={e => { 
                      let bco = this.state.dadosbanco;
                      bco.t08_motoristas_numeroagencia = e.target.value;
                      this.setState({
                        dadosbanco: bco
                      })
                     }}
                  />
                  </Col>
                </Row>
                <Row  key={'conta'}>
                  <Col sm={6}><ControlLabel>Conta ({this.state.dadosbanco.t08_motoristas_banco !== undefined ? this.state.sublistas.tabelabancos.find(x => x.id === Number(this.state.dadosbanco.t08_motoristas_banco)).conta : '9999'})</ControlLabel></Col>
                  <Col sm={6}>
                  <InputMask
                    value={this.state.dadosbanco.t08_motoristas_numeroconta}
                    ref={'conta'}
                    className="form-control"
                    mask={this.state.dadosbanco.t08_motoristas_banco !== undefined ? this.state.sublistas.tabelabancos.find(x => x.id === Number(this.state.dadosbanco.t08_motoristas_banco)).conta : '9999'}
                    maskChar="_"
                    placeholder={'Agencia'}
                    onChange={e => { 
                      let bco = this.state.dadosbanco;
                      bco.t08_motoristas_numeroconta = e.target.value;
                      this.setState({
                        dadosbanco: bco
                      })
                     }}
                  />
                  </Col>
                </Row>
            </div>}
            <div>
                    <Button onClick={this.salvarbanco}>Salvar</Button>
                    <Button onClick={this.modalHideBanco}>Cancelar</Button>
                  </div>
            </Modal.Body>
      </Modal>
      <Modal
          keyboard
          show={this.state.showPadronizador}
          onHide={this.modalHide}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header>
            <Modal.Title>Atualizar Todos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form
                onSubmit={e => this.submit(e)}
                onKeyPress={e => {
                  if (e.which === 13) {
                    e.preventDefault()
                    return false
                  }
                }}>
                  <div>
                  {this.state.showPadronizador && <>
                  {this.state.sublistas !== undefined && <>
                  {this.state.sublistas.motorista_central_tipos !== undefined && <>
                  {this.state.sublistas.motorista_central_tipos.map( (a,b) => {
                    let ok = false;
                    let valorsalv = this.state.salvos;
                    if (valorsalv.indexOf(a.id) >= 0){
                      ok = true;
                    }
                      return (<Checkbox
                            checked={ok}
                            onChange={e => {
                              let valorsalv = this.state.salvos;
                              if (e.target.checked) {
                                var index = valorsalv.indexOf(a.id);
                                if (index < 0) {
                                  valorsalv.push(a.id);
                                }
                              } else {
                                var index = valorsalv.indexOf(a.id);
                                if (index > -1) {
                                  valorsalv.splice(index, 1);
                                }
                              }
                              valorsalv.sort();
                              valorsalv = valorsalv.map( (a, b) => {
                                if (this.state.sublistas.motorista_central_tipos.findIndex(h=> h.id === a) >= 0) {return a}
                              })
                              valorsalv = valorsalv.filter(function (el) {
                                return el != null;
                              });
                              this.setState({
                                salvos: valorsalv,
                              })
                            }}
                            key={a.id}>
                          <b><strong>{a.cat.toUpperCase()}</strong> - {a.label}</b>
                      </Checkbox>)
                    })}</>}</>}</>}
                  </div>
                  <div>
                    <Button onClick={this.salvar}>Salvar</Button>
                    <Button onClick={this.modalHide}>Cancelar</Button>
                  </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default MotoristaCentral;
