import styled from 'styled-components'
import { Select, Card, Layout, Row } from 'antd'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #faf9f6;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #e0dfdc;
  margin: 10px;
`

export const TableHeaderMotoristas = styled.header`
  display: block;
  font-size: 25px;
  color: #111;
  cursor: pointer;
`

export const TableHeaderSolicitacoes = styled.header`
  display: block;
  font-size: 25px;
  color: #111;
  cursor: pointer;
`

export const TableHr = styled.hr`
  display: block;
  margin-left: 0;
  margin-right: 0;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0.1)
  );
`

export const ContainerSolicitacoes = styled.div`
  display: block;
`

export const ContainerMotoristas = styled.div`
  /* display: grid;
  grid-template-columns: repeat(auto-fit, 2fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 300px);
  grid-auto-flow: row; */
  /* tbody:nth-child(even){
    background-color: #fff;
  } */

  th {
    text-align: justify;
    padding: 2px;
    font-size: 18px;
  }
  td {
    padding: 2px;
  }

  td.motoristasCorridas {
    text-align: center;
  }

  th.motoristasNomes {
    text-align: center;
  }
`

// -- Estilos para a lib Ant Design --

export const DivPrincipal = styled.div`
  background-color: #fff;
  height: 100%;

  .ant-card-body {
    text-align: center;
  }

  .gutter-row {
    display: flex;
    flex-wrap: wrap;
  }
`

export const CardBodyStyle = styled.span`
  padding: 0px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  
`

export const InfosCards = styled.div`
  display: grid;
  width: 100vw;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`

export const CustomLayout = styled(Layout)`
  margin-Inline: 2% 5%, writing-Mode: horizontal-t;
  
`
export const CustomCard = styled(Card)`
  margin: 10px;
  border-radius: 18px;
  border: none
  background: #f2f0e8;
  box-shadow: -14px 14px 28px #b6b4ae, 14px -14px 28px #ffffff;
  .ant-card-head-title {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .ant-card-body {
    padding: 10px;
  }
  
`

export const CustomRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
` 
export const CustomTotCard = styled(Card)`
  width: 180px;
  height: 100px;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  .ant-card-body {
    padding: 2px;
  }

  .div-interna-customcard {
    font-weight: bold;
    font-size: 16px;
  }
`