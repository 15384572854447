import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom'
import BlockUi from '@ethanresnick/react-block-ui'
import '@ethanresnick/react-block-ui/style.css'
import WS from './services/webservice'

import 'react-select2-wrapper/css/select2.css'
import Transbordo from './components/Transbordo.js'
import Politica from './components/Politica.js'
import Corridas from './components/Corridas.js'

import Monitor from './components/Monitor.js'
import Motoristas from './components/Motoristas.js'
//import Mensagens from './components/Mensagens.js';
import HistoricoGps from './components/HistoricoGps.js'
import Login from './components/login.js'
import Solicitacoes from './components/Solicitacoes.js'
import Relatorio from './components/Relatorio.js'
import Rfaturamento from './components/Rfaturamento.js'
import CadastroNiveisAcesso from './components/CadastroNiveisDeAcesso.js'
import CadastroTipoCancelamento from './components/CadastroTipoCancelamento.js'
import PortalFatura from './components/PortalFatura.js'
import Caridade from './components/Caridade.js'
import CadastroEmpresa from './components/CadastroEmpresa.js'
import Centralafaturar from './components/Centralafaturar.js'
import MotoristaFinanceiro from './components/MotoristaFinanceiro.js'
import CadastroTipoReclamacao from './components/CadastroTipoReclamacao.js'
import CadastroTipoCliente from './components/CadastroTipoCliente.js'
import CadastroTipoMensagem from './components/CadastroTIpoMensagem.js'
import CadastroTipoCorrida from './components/CadastroTipoCorrida.js'
import CadastroTipoCarro from './components/CadastroTipoCarro.js'
import CadastroTipoPagto from './components/CadastroTipoPagto.js'
import CadastroUsuario from './components/CadastroUsuario.js'
import Referencias from './components/Referencias.js'
import Tabelamento from './components/Tabelamento.js'
import CriaDepartamento from './components/CriarDepartamento.js'
import CriaNivel from './components/CriarNivel.js'
import CriaStatus from './components/CriarStatus.js'
import MapaRefs from './components/MapaRefs.js'
import MapaMotoRefs from './components/MapaMotoRefs.js'
import AttUUID from './components/AttUUID.js'
import Central from './components/Central.js'
import CadastroMsgChat from './components/CadastroMsgChat.js'
import MotoristaCentral from './components/MotoristaCentral.js'
import IuguWeb from './components/IuguWeb.js'
//admin
import MarcaVeiculo from './components/MarcaVeiculo.js'
import ModeloVeiculo from './components/ModeloVeiculo.js'
import PrecadastrobkpNew from './components/precadastrobkpNew.js'
import PrecadastroNew from './components/PrecadastroNew.js'
import PrecadastroEmpresa from './components/PrecadastroEmpresa.js'
import ValoresCentral from './components/ValoresCentral.js'
import ValoresEmpresa from './components/ValoresEmpresa.js'
import LegendaTipo from './components/legendatipo.js'
//voucher
import Empresa from './components/Empresa.js'
import Afaturar from './components/Afaturar.js'
import Faturas from './components/Faturas.js'
import SaldoMotorista1 from './components/SaldoMotorista1.js'
import SaldoMotorista2 from './components/SaldoMotorista2.js'
import FaturasCentral from './components/FaturasCentral.js'
import Wallet from './components/Wallet.js'
import ControlarTipo from './components/ControlarTipo.js'
import './App.css'
import { Row, Col } from 'react-bootstrap'
import fetchIntercept from 'fetch-intercept'

import WpgApp from './components/WpgApp.js'

import Payments from './components/Payments.js'
import Dashboard from './components/Dashboard'
import Dashboard2 from './components/Dashboard2/index'
import {
  CarOutlined,
  DollarCircleOutlined,
  FileOutlined,
  GlobalOutlined,
  ProfileOutlined,
  SettingOutlined,
  UserOutlined,
  LogoutOutlined,
  CalendarOutlined
} from '@ant-design/icons'
import { Calendar, Layout, Menu } from 'antd'
import styled from 'styled-components'
import SolicitacoesDashboard from './components/Dashboard2/SolicitacoesDashboard'
import CalendarioAgendar from './components/Calendario/CalendarioAgendar'
import CalendarioDisponivel from './components/Calendario/CalendarioDisponivel'

//Fontes
import './fonts/Fontes.css'
import './fonts/FontAwesome.ttf'
import './fonts/AntDesign.ttf'
import './fonts/Entypo.ttf'
import './fonts/EvilIcons.ttf'
import './fonts/Feather.ttf'
import './fonts/Fontisto.ttf'
import './fonts/Foundation.ttf'
import './fonts/Ionicons.ttf'
import './fonts/MaterialCommunityIcons.ttf'
import './fonts/MaterialIcons.ttf'
import './fonts/Octicons.ttf'
import './fonts/SimpleLineIcons.ttf'
import './fonts/Zocial.ttf'
//Fontes



const { Header, Content, Footer, Sider } = Layout

var jwtDecode = require('jwt-decode')


//-------------------- Estilos do Styled Component ---------------------

const CustomLatyout = styled(Layout)`
  height: 100%;
`
const StyledLayout = styled(Layout)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const CustomContent = styled(Content)`
  height: 100vh;
  overflow: auto;
  margin-left: 10px;
  margin-right: 10px;
`

const CustomMenuSubmenu = styled(Menu.SubMenu)`
  .ant-menu-submenu-title {
    background-color: ${(props) => props.background};
    color: ${(props) => props.color};
  }
`

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      blocking: false,
      buttonName: 'Centrais',
      showCentrais: false,
      isAuthenticated: false,
      isAuthenticating: true,
      userData: [],
      userAttrib: [],
      centralSelected: '',
      centrais: [],
      central: '',
      collapsed: false,
      newItems: [],
      showContent: false,
      siderWidth: '200',
      userAcessos: []
    }

    this.ws = new WS()

    this.logInUser = this.logInUser.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.getUserAcessos = this.getUserAcessos.bind(this)
  }

  async getUserAcessos() {
    let data = await this.ws.getUserAcessos()
    this.setState({ userAcessos: data })
  }

  componentDidMount() {
    this.setState({
      isAuthenticating: false
    })
    this.ws.getUserAcessos()
  }

  getSelectedOptionsValue(selectedOptions) {
    var values = []

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value)
    }

    return values
  }

  logInUser(user) {
    let data
    if (user.token) {
      data = jwtDecode(user.token)
      this.ws.setCookie('token', user.token, 1)
    } else {
      data = jwtDecode(user)
      this.ws.setCookie('token', user, 1)
    }

    let centralsalva = this.ws.getCookie('central-id')

    if (!centralsalva) {
      centralsalva = data.central_padrao
      this.ws.setCookie('central-id', centralsalva, 1)
    }
    let checkcentralsalva = data.centrais.filter(
      (x) => Number(x.id) === Number(centralsalva)
    )
    if (checkcentralsalva.length < 1) {
      centralsalva = data.centrais[0].id
      this.ws.setCookie('central-id', centralsalva, 1)
    }

    this.ws.getCentral(centralsalva).then((result) => {
      this.setState({
        centralSelected: [result.id],
        buttonName: result.nomefantasia,
        central: result
      })
    })

    this.ws.getCentrais().then((result) => {
      this.setState({ centrais: result })

      data.centrais = result.map((m, i) => {
        return { ...m, text: m.nomefantasia || m.nome }
      })

      this.setState({ userAttrib: data })

      let ids = ''
      result.map((central, i) => {
        if (i === 0) {
          ids = central.id + ''
        } else {
          ids = ids + ',' + central.id
        }
        return null
      })
      data.centralString = { ids }
      data.centralRodrigo = []
    })

    this.ws.getDepartamento().then((result) => {
      data.departamento = result.map((m, i) => {
        return { ...m, text: m.descricao }
      })
    })
    this.ws.GetCidade().then((result) => {
      data.cidade = result.map((m, i) => {
        return { id: m, text: m }
      })
    })
    this.ws.getStatus().then((result) => {
      data.status = result.map((m, i) => {
        return { ...m, text: m.descricao }
      })
    })

    this.ws.getNivel().then((result) => {
      data.nivel = result.map((m, i) => {
        return { ...m, text: m.descricao }
      })
    })

    console.log('data', data)
    let n = Object.keys(data.acesso).length

    //data.monitorcentral = n >= 17 ? true : false;

    this.setState({
      isAuthenticated: true,
      isAuthenticating: false,
      userData: user,
      userAttrib: data
    })

    this.ws.getUserAcessos().then((result) => {
      this.setState({ userAcessos: result })
      //console.log('!!**acesso', this.state.userAcessos)
    })
  }

  handleLogout() {
    //console.log('DESLOGAR');
    this.ws.eraseCookie('token')
    this.ws.eraseCookie('central-id')
    this.ws.eraseCookie('ramal')
    localStorage.clear()
    this.setState({
      isAuthenticated: false,
      isAuthenticating: false,
      userData: [],
      userAttrib: []
    })
  }

  setCentral(id) {
    let central = this.ws.getCookie('central-id')
    this.ws.getCentral(central).then((result) => {
      this.setState({
        centralSelected: result.id,
        showCentrais: false,
        buttonName: result.nomefantasia,
        central: result
      })
    })
  }

  // -------------------------- JSX ---------------------------------

  render() {
    let self = this
    fetchIntercept.register({
      request: function (url, config) {
        // Modify the url or config here
        return [url, config]
      },

      requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error)
      },

      response: function (response) {
        // Modify the reponse object
        if (response.status === 401) {
          console.log('LOGAR DE NOVO')
          self.handleLogout()
        }
        if (response.status === 403) {
          console.log('LOGAR DE NOVO')
          self.handleLogout()
        }
        return response
      },

      responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error)
      }
    })

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      isAuthenticating: this.state.isAuthenticating,
      userData: this.state.userData,
      userAttrib: this.state.userAttrib,
      logInUser: this.logInUser.bind(this),
      centralData: this.state.central,
      setCentral: this.setCentral.bind(this)
    }

    console.log('****USER-ATTRIB', this.state.userAttrib)
    //console.log('****USER-DATA', this.state.data)

    return (
      !this.state.isAuthenticating && (
        <Router>
          <BlockUi tag='div' blocking={this.state.blocking}>
            <div className='remove'>
              <CustomLatyout>
                {/* Sider */}

                {/* -------------------------------------------------- */}

                {/* ----- ADMIN GERAL ----- */}
                {!!this.state.userAttrib.admin && !!this.state.isAuthenticated && (
                  <Sider
                    theme='light'
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={(value) => {
                      this.setState({ collapsed: value })
                    }}
                    collapsedWidth={'50px'}
                  >
                    <Menu
                      theme='light'
                      defaultSelectedKeys={['calendario']}
                      mode='inline'
                      style={{ height: '100%', borderRight: 0 }}
                    >

                      {/* MENU DO CALENDÁRIO E AGENDAMENTO COMENTADO PARA SER UTILIZADO ALGUM DIA */}
                      {/* <Menu.SubMenu
                        key='calendario'
                        icon={<CalendarOutlined />}
                        title='Calendário'
                      >
                        <Link
                          to='/CalendarioAgendar'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='agendar'>Agendar</Menu.Item>
                        </Link>
                        <Link
                          to='/CalendarioDisponivel'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='disponivel'>
                            Disponibilidade
                          </Menu.Item>
                        </Link>
                      </Menu.SubMenu> */}


                      {/* Selecionar Centrais */}
                      <CustomMenuSubmenu
                        key='centrais'
                        icon={<GlobalOutlined />}
                        title={this.state.buttonName}
                        background={
                          this.state.central && this.state.central.aplicativocor
                            ? this.state.central.aplicativocor.background
                            : 'lightgrey'
                        }
                        color={
                          this.state.central && this.state.central.aplicativocor
                            ? this.state.central.aplicativocor.foreground
                            : 'black'
                        }
                      >
                        {this.state.isAuthenticated &&
                          this.state.centrais.map((m, i) => {
                            return (
                              <Menu.Item
                                key={i}
                                show={this.state.showCentrais}
                                style={
                                  i % 2 !== 0
                                    ? {
                                        backgroundColor: '#f6f6f6',
                                        margin: '10px',
                                        padding: '2px'
                                      }
                                    : {
                                        margin: '10px',
                                        padding: '2px'
                                      }
                                }
                                onClick={() => {
                                  this.setState(
                                    {
                                      centralSelected: m.id,
                                      showCentrais: false,
                                      buttonName: m.nomefantasia,
                                      central: m
                                    },
                                    () => {
                                      this.ws.setCookie(
                                        'central-id',
                                        this.state.centralSelected,
                                        1
                                      )
                                      window.location.reload()
                                    }
                                  )
                                }}
                              >
                                <Row
                                  style={{
                                    color: 'black',
                                    fontSize: '15px'
                                  }}
                                >
                                  <Col md={6}>
                                    <div className='justify-content-start'>
                                      <Link to='/Dashboard2'>
                                        {m.nomefantasia}
                                      </Link>
                                    </div>
                                  </Col>
                                  {/* <Col md={6}>
                                        <div>{m.cnpj}</div>
                                      </Col> */}
                                </Row>
                              </Menu.Item>
                            )
                          })}
                      </CustomMenuSubmenu>

                      {/* Menu ADM */}
                      <Menu.SubMenu
                        key='adm'
                        icon={<UserOutlined />}
                        title='Administrativo'
                      >
                        <Link
                          to='/Dashboard2'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='Dashboard2'>Dashboard</Menu.Item>
                        </Link>

                        <Link
                          to='/Dashboard'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='Dashboard'>
                            Detalhes Dashboard
                          </Menu.Item>
                        </Link>

                        <Link to='/corridas' style={{ textDecoration: 'none' }}>
                          <Menu.Item key='corridas'>
                            Corridas Pendentes
                          </Menu.Item>
                        </Link>

                        <Link to='/monitor' style={{ textDecoration: 'none' }}>
                          <Menu.Item key='monitor'>Monitor</Menu.Item>
                        </Link>
                      </Menu.SubMenu>

                      {/* Menu ADM */}
                      <Menu.SubMenu
                        key='dev'
                        icon={<UserOutlined />}
                        title='DevControl'
                      >
                        <Link to='/pg_apps' style={{ textDecoration: 'none' }}>
                          <Menu.Item key='pg_apps'>pg_apps</Menu.Item>
                        </Link>

                        <Link to='/wallet' style={{ textDecoration: 'none' }}>
                          <Menu.Item key='wallet'>wallet</Menu.Item>
                        </Link>
                      </Menu.SubMenu>

                      {/* Menu Financeiro */}
                      <Menu.SubMenu
                        key='fin'
                        icon={<DollarCircleOutlined />}
                        title='Financeiro'
                      >
                        <Link
                          to='/valorescentral'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='valorescentral'>
                            Valores da Central
                          </Menu.Item>
                        </Link>

                        <Link
                          to='valoresempresa'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='valoresempresa'>
                            Valores dos Convênios
                          </Menu.Item>
                        </Link>

                        <Link
                          to='tabelamento'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='tabelamento'>Tabelamento</Menu.Item>
                        </Link>

                         <Link
                          to='/saldomotorista2'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='saldomotorista2'>
                            Pagamento Motoristas
                          </Menu.Item>
                        </Link>

                        <Link to='/payments' style={{ textDecoration: 'none' }}>
                          <Menu.Item key='payments'>Pagamentos</Menu.Item>
                        </Link>


<Link
  to='/motorista_financeiro'
  style={{ textDecoration: 'none' }}
>
  <Menu.Item key='payments'>Creditos do motorista</Menu.Item>
</Link>
                        
                      </Menu.SubMenu>

                      {/* Menu Convênios*/}
                      <Menu.SubMenu
                        key='con'
                        icon={<ProfileOutlined />}
                        title='Convênios'
                      >
                        <Link
                          to='/cadastroempresa'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='cadastroempresa'>
                            Empresas Conveniadas
                          </Menu.Item>
                        </Link>

                        <Link
                          to='/voucher_empresa'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='voucher_empresa'>
                            Usuários Empresas
                          </Menu.Item>
                        </Link>

                        <Link
                          to='/precadastroempresa'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='precadastroempresa'>
                            Pré Cadastro Convênios
                          </Menu.Item>
                        </Link>

                        <Link
                          to='/cria_departamento'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='cria_departamento'>
                            Departamentos
                          </Menu.Item>
                        </Link>

                        <Link
                          to='/voucher_fatura'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='voucher_fatura'>Fatura</Menu.Item>
                        </Link>

                        <Link
                          to='/voucher_afaturar'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='voucher_afaturar'>
                            A Faturar
                          </Menu.Item>
                        </Link>

                        <Link
                          to='/faturaportal'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='faturaportal'>
                            Faturas Portal
                          </Menu.Item>
                        </Link>
                      </Menu.SubMenu>

                      {/* Menu Motoristas */}
                      <Menu.SubMenu
                        key='mot'
                        icon={<CarOutlined />}
                        title='Motoristas'
                      >
                          <Link
                            to='/motorista_central'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='motorista_central'>
                              Motoristas da Central
                            </Menu.Item>
                          </Link>
                          <Link
                            to='/precadastro'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='precadastro'>
                              Pré-Cadastro
                            </Menu.Item>
                          </Link>

                        <Link
                          to='/precadastrobkp'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='precadastrobkp'>
                            Pré-Cadastro bkp Admin Only
                          </Menu.Item>
                        </Link>

                        <div>
                          <Link
                            to='/motoristas'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='motoristas'>
                              Localização Atual
                            </Menu.Item>
                          </Link>
                          <Link
                            to='/historicogps'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='historicogps'>
                              Históricos do Motorista
                            </Menu.Item>
                          </Link>
                        </div>
                      </Menu.SubMenu>

                      {/* Menu Relatórios */}
                      <Menu.SubMenu
                        key='rel'
                        icon={<FileOutlined />}
                        title='Relatórios'
                      >
                        <Link
                          to='/relatorio'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='relatorio'>
                            Relatórios Gerais
                          </Menu.Item>
                        </Link>

                        <Link
                          to='/faturamento'
                          style={{ textDecoration: 'none' }}
                        >
                          <Menu.Item key='faturamento'>Faturamento</Menu.Item>
                        </Link>
                      </Menu.SubMenu>

                      {/* Menu Configurações */}
                      <Menu.SubMenu
                        key='cfg'
                        icon={<SettingOutlined />}
                        title='Configurações'
                      >
                        <Menu.SubMenu
                          key='cad'
                          icon={<UserOutlined />}
                          title='Cadastros'
                        >
                          <Link
                            to='/caridade'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='caridade'>
                              Instituição de Caridade
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/usuario'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='usuario'>
                              Usuários Callcenter
                            </Menu.Item>
                          </Link>
                        </Menu.SubMenu>

                        <Menu.SubMenu
                          key='cen'
                          icon={<CarOutlined />}
                          title='Centrais'
                        >
                          <Link
                            to={'/transbordo'}
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='transbordo'>Transbordo</Menu.Item>
                          </Link>

                          <Link
                            to='/centrais'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='centrais'>Config Central</Menu.Item>
                          </Link>

                          <Link
                            to='/centralafaturar'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='centralafaturar'>
                              Valores a Faturar
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/faturascentral'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='faturascentral'>Faturas</Menu.Item>
                          </Link>
                        </Menu.SubMenu>

                        <Menu.SubMenu
                          key='adm2'
                          icon={<UserOutlined />}
                          title='Administração'
                        >
                          <Link
                            to='/controlar_tipo'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='controlar_tipo'>
                              Ativar/Desativar Tipos
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/legendatipo'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='legendatipo'>
                              Legendas para Tipos
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/msg_chat'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='msg_chat'>Mensagens Chat</Menu.Item>
                          </Link>

                          <Link
                            to='/tipo_mensagem'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='tipo_mensagem'>
                              Tipos de Mensagem
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/tipo_cancelamento'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='tipo_cancelamento'>
                              Tipos de Cancelamento
                            </Menu.Item>
                          </Link>
                        </Menu.SubMenu>

                        <Menu.SubMenu
                          key='sist'
                          icon={<SettingOutlined />}
                          title='Sistema'
                        >
                          <Link
                            to='/marcaveiculo'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='marcaveiculo'>
                              Marcas de Veículos
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/modeloveiculo'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='modeloveiculo'>
                              Modelos de Veículos
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/tipo_cliente'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='tipo_cliente'>
                              Tipos de Clientes
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/tipo_corrida'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='tipo_corrida'>
                              Tipos de Corrida
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/tipo_carro'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='tipo_carro'>
                              Tipos de Carro
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/tipo_pagamento'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='tipo_pagamento'>
                              Tipos de Pagamento
                            </Menu.Item>
                          </Link>
                        </Menu.SubMenu>
                      </Menu.SubMenu>

                      {/* LOGOUT */}
                      <Menu.Item
                        key='logout'
                        icon={<LogoutOutlined />}
                        onClick={this.handleLogout}
                      >
                        Sair
                      </Menu.Item>
                      {/** Fim dos menus e Submenus */}
                    </Menu>
                  </Sider>
                )}

                {/* -------------------------------------------------- */}

                {/* ----- ADMIN CIDADE ----- */}
                {!this.state.userAttrib.admin &&
                !this.state.userAttrib.monitorcentral &&
                !!this.state.userAttrib.gestorcidade &&
                  !!this.state.isAuthenticated && (
                    <Sider
                      theme='light'
                      collapsible
                      collapsed={this.state.collapsed}
                      onCollapse={(value) => {
                        this.setState({ collapsed: value })
                      }}
                      collapsedWidth={'50px'}
                    >
                      <Menu
                        theme='light'
                        defaultSelectedKeys={['Dashboard2']}
                        mode='inline'
                        style={{ height: '100%', borderRight: 0 }}
                      >
                        {/* Menu ADM */}
                        <Menu.SubMenu
                          key='adm'
                          icon={<UserOutlined />}
                          title='Administrativo'
                        >
                          <Link
                            to='/Dashboard2'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='Dashboard2'>Dashboard</Menu.Item>
                          </Link>

                          <Link
                            to='/Dashboard'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='Dashboard'>
                              Detalhes Dashboard
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/corridas'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='corridas'>
                              Corridas Pendentes
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/monitor'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='monitor'>Monitor</Menu.Item>
                          </Link>
                        </Menu.SubMenu>


                        {/* Menu Motoristas */}
                        <Menu.SubMenu
                          key='mot'
                          icon={<CarOutlined />}
                          title='Motoristas'
                        >
                          <div>
                            <Link
                              to='/motorista_central'
                              style={{ textDecoration: 'none' }}
                            >
                              <Menu.Item key='motorista_central'>
                                Motoristas da Central
                              </Menu.Item>
                            </Link>
                            <Link
                              to='/precadastro'
                              style={{ textDecoration: 'none' }}
                            >
                              <Menu.Item key='precadastro'>
                                Pré-Cadastro
                              </Menu.Item>
                            </Link>
                          </div>

                          

                          <div>
                            <Link
                              to='/motoristas'
                              style={{ textDecoration: 'none' }}
                            >
                              <Menu.Item key='motoristas'>
                                Localização Atual
                              </Menu.Item>
                            </Link>
                            <Link
                              to='/historicogps'
                              style={{ textDecoration: 'none' }}
                            >
                              <Menu.Item key='historicogps'>
                                Históricos do Motorista
                              </Menu.Item>
                            </Link>
                            

                          
                          </div>
                        </Menu.SubMenu>

                        {/* LOGOUT */}
                        <Menu.Item
                          key='logout'
                          icon={<LogoutOutlined />}
                          onClick={this.handleLogout}
                        >
                          Sair
                        </Menu.Item>
                        {/** Fim dos menus e Submenus */}
                      </Menu>
                    </Sider>
                  )}

                {/* -------------------------------------------------- */}      

                {/* ADMIN CENTRAL */}
                {!!this.state.userAttrib.monitorcentral &&
                 !this.state.userAttrib.admin &&
                  !!this.state.isAuthenticated && (
                    <Sider
                      theme='light'
                      collapsible
                      collapsed={this.state.collapsed}
                      onCollapse={(value) => {
                        this.setState({ collapsed: value })
                      }}
                      collapsedWidth={'50px'}
                    >
                      <Menu
                        theme='light'
                        defaultSelectedKeys={['Dashboard2']}
                        mode='inline'
                        style={{ height: '100%', borderRight: 0 }}
                      >
                        {/* ADMINISTRATIVO */}
                        <Menu.SubMenu
                          key='adm'
                          icon={<UserOutlined />}
                          title='Administrativo'
                        >
                          <Link
                            to='/Dashboard2'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='Dashboard2'>Dashboard</Menu.Item>
                          </Link>

                          <Link
                            to='/Dashboard'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='Dashboard'>
                              Detalhes Dashboard
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/corridas'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='corridas'>
                              Corridas Pendentes
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/monitor'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='monitor'>Monitor</Menu.Item>
                          </Link>
                        </Menu.SubMenu>

                        {/* FINANCEIRO*/}
                        <Menu.SubMenu
                          key='fin'
                          icon={<DollarCircleOutlined />}
                          title='Financeiro'
                        >
                          <Link
                            to='/valorescentral'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='valorescentral'>
                              Valores da Central
                            </Menu.Item>
                          </Link>

                          <Link
                            to='valoresempresa'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='valoresempresa'>
                              Valores dos Convênios
                            </Menu.Item>
                          </Link>

                          <Link
                            to='tabelamento'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='tabelamento'>Tabelamento</Menu.Item>
                          </Link>

                          <Link
                            to='/saldomotorista2'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='saldomotorista2'>
                              Pagamento Motoristas
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/payments'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='payments'>Pagamentos</Menu.Item>
                          </Link>


                          <Link
                            to='/motorista_financeiro'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='payments'>Creditos do motorista</Menu.Item>
                          </Link>
                          

                        </Menu.SubMenu>

                        {/* CONVÊNIOS*/}
                        <Menu.SubMenu
                          key='con'
                          icon={<ProfileOutlined />}
                          title='Convênios'
                        >
                          <Link
                            to='/cadastroempresa'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='cadastroempresa'>
                              Empresas Conveniadas
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/voucher_empresa'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='voucher_empresa'>
                              Usuários Empresas
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/precadastroempresa'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='precadastroempresa'>
                              Pré Cadastro Convênios
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/cria_departamento'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='cria_departamento'>
                              Departamentos
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/voucher_fatura'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='voucher_fatura'>Fatura</Menu.Item>
                          </Link>

                          <Link
                            to='/voucher_afaturar'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='voucher_afaturar'>
                              A Faturar
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/faturaportal'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='faturaportal'>
                              Faturas Portal
                            </Menu.Item>
                          </Link>
                        </Menu.SubMenu>

                        {/* Menu Motoristas */}
                        <Menu.SubMenu
                        key='mot'
                        icon={<CarOutlined />}
                        title='Motoristas'
                      >
                        <div>
                          <Link
                            to='/motorista_central'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='motorista_central'>
                              Motoristas da Central
                            </Menu.Item>
                          </Link>
                          <Link
                            to='/precadastro'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='precadastro'>
                              Pré-Cadastro
                            </Menu.Item>
                          </Link>
                        </div>




                        <div>
                          <Link
                            to='/motoristas'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='motoristas'>
                              Localização Atual
                            </Menu.Item>
                          </Link>
                          <Link
                            to='/historicogps'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='historicogps'>
                              Históricos do Motorista
                            </Menu.Item>
                          </Link>
                        </div>
                        </Menu.SubMenu>

                        {/* Menu Relatórios */}
                        <Menu.SubMenu
                          key='rel'
                          icon={<FileOutlined />}
                          title='Relatórios'
                        >
                          <Link
                            to='/relatorio'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='relatorio'>
                              Relatórios Gerais
                            </Menu.Item>
                          </Link>

                          <Link
                            to='/faturamento'
                            style={{ textDecoration: 'none' }}
                          >
                            <Menu.Item key='faturamento'>Faturamento</Menu.Item>
                          </Link>
                        </Menu.SubMenu>

                        {/* Menu Configurações */}
                        <Menu.SubMenu 
                          key='cfg'
                          icon={<SettingOutlined />}
                          title='Configurações'
                        >
                          <Menu.SubMenu
                            key='cad'
                            icon={<UserOutlined />}
                            title='Cadastros'
                          >
                            <Link
                              to='/usuario'
                              style={{ textDecoration: 'none' }}
                            >
                              <Menu.Item key='usuario'>
                                Usuários Callcenter
                              </Menu.Item>
                            </Link>
                          </Menu.SubMenu>

                        </Menu.SubMenu>

                        {/* LOGOUT */}
                        <Menu.Item
                          key='logout'
                          icon={<LogoutOutlined />}
                          onClick={this.handleLogout}
                        >
                          Sair
                        </Menu.Item>
                        {/** Fim dos menus e Submenus */}
                      </Menu>
                    </Sider>
                  )}

                {/* -------------------------------------------------- */}

                {/* Conteúdo */}
                <StyledLayout>
                  <CustomContent>
                    <Route
                      exact
                      path='/login'
                      render={(props) => (
                        <Login
                          {...props}
                          childProps={childProps}
                          loginUser={this.logInUser}
                        />
                      )}
                    />
                    {this.state.isAuthenticated ? (
                      <Switch>
                        <Route
                          exact
                          path='/Dashboard'
                          render={(props) => (
                            <Dashboard
                              {...props}
                              childProps={childProps}
                              centralName={this.state.central.nomefantasia}
                              centralId={this.state.central.id}
                              central={this.state.central}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/CalendarioAgendar'
                          render={(props) => (
                            <CalendarioAgendar
                              {...props}
                              childProps={childProps}
                              centralName={this.state.central.nomefantasia}
                              centralId={this.state.central.id}
                              central={this.state.central}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/CalendarioDisponivel'
                          render={(props) => (
                            <CalendarioDisponivel
                              {...props}
                              childProps={childProps}
                              centralName={this.state.central.nomefantasia}
                              centralId={this.state.central.id}
                              central={this.state.central}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/Dashboard2'
                          render={(props) => (
                            <Dashboard2
                              {...props}
                              childProps={childProps}
                              central={this.state.central}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/corridas'
                          render={(props) => (
                            <Corridas
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/wallet'
                          render={(props) => (
                            <Wallet
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/monitor'
                          render={(props) => (
                            <Monitor
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/solicitacoesDashboard'
                          render={(props) => (
                            <SolicitacoesDashboard
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        )}
                        <Route
                          exact
                          path='/motoristas'
                          render={(props) => (
                            <Motoristas
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/centralafaturar'
                          render={(props) => (
                            <Centralafaturar
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/faturascentral'
                          render={(props) => (
                            <FaturasCentral
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/iugu_loadmarketplace'
                          render={(props) => (
                            <IuguWeb
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/motorista_financeiro'
                          render={(props) => (
                            <MotoristaFinanceiro
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/payments'
                          render={(props) => (
                            <Payments
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/historicogps'
                          render={(props) => (
                            <HistoricoGps
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/solicitacoes'
                          render={(props) => (
                            <Solicitacoes
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/relatorio'
                          render={(props) => (
                            <Relatorio
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/faturamento'
                          render={(props) => (
                            <Rfaturamento
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/tipo_reclamacao'
                          render={(props) => (
                            <CadastroTipoReclamacao
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/tipo_reclamacao/novo'
                          render={(props) => (
                            <CadastroTipoReclamacao
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                              new={true}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/tipo_cancelamento'
                          render={(props) => (
                            <CadastroTipoCancelamento
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/tipo_cancelamento/novo'
                          render={(props) => (
                            <CadastroTipoCancelamento
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                              new={true}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/tipo_corrida'
                          render={(props) => (
                            <CadastroTipoCorrida
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/tipo_corrida/novo'
                          render={(props) => (
                            <CadastroTipoCorrida
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                              new={true}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/tipo_carro'
                          render={(props) => (
                            <CadastroTipoCarro
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/tipo_carro/novo'
                          render={(props) => (
                            <CadastroTipoCarro
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                              new={true}
                            />
                          )}
                        />
                        <Route
                          path='/cadastroempresa'
                          render={(props) => (
                            <CadastroEmpresa
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/tipo_cliente'
                          render={(props) => (
                            <CadastroTipoCliente
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/tipo_mensagem'
                          render={(props) => (
                            <CadastroTipoMensagem
                              {...props}
                              childProps={childProps}
                              loginUser={this.loginUser}
                            />
                          )}
                        />
                        <Route
                          path='/tipo_cliente/novo'
                          render={(props) => (
                            <CadastroTipoCliente
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                              new={true}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/controlar_tipo'
                          render={(props) => (
                            <ControlarTipo
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/tipo_pagamento'
                          render={(props) => (
                            <CadastroTipoPagto
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/tipo_pagamento/novo'
                          render={(props) => (
                            <CadastroTipoPagto
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                              new={true}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/usuario'
                          render={(props) => (
                            <CadastroUsuario
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/usuario/novo'
                          render={(props) => (
                            <CadastroUsuario
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                              new={true}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/nivel_acesso'
                          render={(props) => (
                            <CadastroNiveisAcesso
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/nivel_acesso/novo'
                          render={(props) => (
                            <CadastroNiveisAcesso
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                              new={true}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/faturaportal'
                          render={(props) => (
                            <PortalFatura
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/faturaportal/novo'
                          render={(props) => (
                            <PortalFatura
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                              new={true}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/centrais'
                          render={(props) => (
                            <Central
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/msg_chat'
                          render={(props) => (
                            <CadastroMsgChat
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/msg_chat/novo'
                          render={(props) => (
                            <CadastroMsgChat
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                              new={true}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/uuid'
                          render={(props) => (
                            <AttUUID
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/tabelamento'
                          render={(props) => (
                            <Tabelamento
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/cria_departamento'
                          render={(props) => (
                            <CriaDepartamento
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/cria_status'
                          render={(props) => (
                            <CriaStatus
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/cria_nivel'
                          render={(props) => (
                            <CriaNivel
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/referencias'
                          render={(props) => (
                            <Referencias
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/mapa_referencias'
                          render={(props) => (
                            <MapaRefs
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/mapa_moto_referencias'
                          render={(props) => (
                            <MapaMotoRefs
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/motorista_central'
                          render={(props) => (
                            <MotoristaCentral
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/transbordo'
                          render={(props) => (
                            <Transbordo
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/legendatipo'
                          render={(props) => (
                            <LegendaTipo
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/transbordo'
                          render={(props) => (
                            <Transbordo
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/voucher_empresa'
                          render={(props) => (
                            <Empresa
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/marcaveiculo'
                          render={(props) => (
                            <MarcaVeiculo
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/precadastronew'
                          render={(props) => (
                            <PrecadastroNew
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/precadastro'
                          render={(props) => (
                            <PrecadastroNew
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/precadastroempresa'
                          render={(props) => (
                            <PrecadastroEmpresa
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/valorescentral'
                          render={(props) => (
                            <ValoresCentral
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/valoresempresa'
                          render={(props) => (
                            <ValoresEmpresa
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/modeloveiculo'
                          render={(props) => (
                            <ModeloVeiculo
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/precadastrobkp'
                          render={(props) => (
                            <PrecadastrobkpNew
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/precadastrobkpnew'
                          render={(props) => (
                            <PrecadastrobkpNew
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/saldomotorista1'
                          render={(props) => (
                            <SaldoMotorista1
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/saldomotorista2'
                          render={(props) => (
                            <SaldoMotorista2
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/caridade'
                          render={(props) => (
                            <Caridade
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/voucher_fatura'
                          render={(props) => (
                            <Faturas
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/voucher_afaturar'
                          render={(props) => (
                            <Afaturar
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          path='/pg_apps'
                          render={(props) => (
                            <WpgApp
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                      </Switch>
                    ) : (
                      <Switch>
                        <Route
                          exact
                          path='/termosdeuso/:id'
                          render={(props) => (
                            <Politica
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/privacy/:id'
                          render={(props) => (
                            <Politica
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='*'
                          render={(props) => (
                            <Login
                              {...props}
                              childProps={childProps}
                              loginUser={this.logInUser}
                            />
                          )}
                        />
                      </Switch>
                    )}
                  </CustomContent>
                </StyledLayout>
              </CustomLatyout>
            </div>
          </BlockUi>
        </Router>
      )
    )
  }
}

const Home = (props) => (
  <Link to='/Dashboard2' className='btn btn-primary'>
    Ir para o Dashboard
  </Link>
)

export default App
