import React, { Component } from 'react';
import './Corridas.css';
import { InputGroup, Form, ControlLabel, FormControl, Button, Modal, Col } from 'react-bootstrap';
import WS from '../services/webservice';

class Parametro extends Component {

  constructor(props) {
    super(props);
    this.state = {
      PC: 0,
      PQRL: 0,
      PQRV: 0,
      FPC: 0,
      FPQRL: 0,
      FPQRV: 0,
      variable: {},
      iparam: {},
      showParam: false,
    };

    this.salvaform = this.salvaform.bind(this);

    this.webservice = new WS();
  }

  componentDidMount() {
    this.attParam();
  }

  async salvaform() {
    await this.setState({
      FPC: this.state.PC * 60000,
      FPQRL: this.state.PQRL * 60000,
      FPQRV: this.state.PQRV * 60000,
      FPE: this.state.PE * 60000,
    }, () => this.webservice.saveParametro(this.state.FPC, this.state.FPQRL, this.state.FPQRV, this.state.FPE))
    alert('Configurações salvas')
    this.props.modalHide();
  }

  attParam() {
    this.webservice.getParametro().then(iparame => {
      this.setState({
        FPC: Number(iparame.tempopendencia) / 60000,
        FPQRL: Number(iparame.tempoqrl) / 60000,
        PC: Number(iparame.tempopendencia) / 60000,
        PQRL: Number(iparame.tempoqrl) / 60000,
        FPQRV: Number(iparame.tempoqrv) /60000,
        PQRV: Number(iparame.tempoqrv) / 60000,
        FPE: Number(iparame.tempoemfrente) / 60000,
        PE: Number(iparame.tempoemfrente) /60000,
      })
    })
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.modalHide}>
        <Modal.Header>
          <Modal.Title>Parametros</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>Tempo Pendencia em {this.state.FPC} minutos</p>
            <p>Tempo Atraso Atendimento em {this.state.FPQRL} minutos</p> */}
          <Form horizontal>
            <InputGroup style={{ paddingBottom: '10px' }}>
              <Col md={7} style={{ textAlign: 'left' }}>
                <ControlLabel>Tempo da tela de pendencia:</ControlLabel>
              </Col>
              <Col md={5}  >
                <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '12px' }} placeholder={this.state.PC + " minutos"} onChange={e => { this.setState({ PC: e.target.value }) }} />
              </Col>
            </InputGroup>
            <InputGroup style={{ paddingBottom: '10px' }}>
              <Col md={7} style={{ textAlign: 'left' }}>
                <ControlLabel>Tempo da pendente em frente:</ControlLabel>
              </Col>
              <Col md={5}  >
                <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '8px' }} placeholder={this.state.PE + " minutos"} onChange={e => { this.setState({ PE: e.target.value }) }} />
              </Col>
            </InputGroup>
            <InputGroup style={{ paddingBottom: '10px' }}>
              <Col md={7} style={{ textAlign: 'left' }}>
                <ControlLabel> Tempo do atraso no atendimento:</ControlLabel>
              </Col>
              <Col md={5}  >
                <FormControl type="number" style={{ height: 40, width: 200 }} placeholder={this.state.PQRL + " minutos"} onChange={e => { this.setState({ PQRL: e.target.value }) }} />
              </Col>
            </InputGroup>
            <InputGroup>
              <Col md={7} style={{ textAlign: 'left' }}>
                <ControlLabel> Tempo do atraso QRV:</ControlLabel>
              </Col>
              <Col md={5}  >
                <FormControl type="number" style={{ height: 40, width: 200, marginLeft: '28px'}} placeholder={this.state.PQRV + " minutos"} onChange={e => { this.setState({ PQRV: e.target.value }) }} />
              </Col>
            </InputGroup>
            <Button onClick={this.salvaform}>Salvar</Button>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
}
export default Parametro;
