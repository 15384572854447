import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class Empresa extends Component {

  constructor(props) {
    super(props);
    this.state = {}

  }

  mirrorState(key) {
    this.setState(key);
  }

  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "t17_cd_marca",
          tipo_cadastro: "Marca",
          tipo_plural: "Marcas",
          href: "marcaveiculo",
          titulo: "Cadastro de Marcas de Veículos",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'marcaveiculo',
            new: true,
            list: true,
            save: true,
            edit: true,
            delete: false,
            listSub: false,
            defaultorder: [{ id: "t17_desc_carro" }, { id: "t17_cd_marca" }],
          },
          campos: [
            {
              ref: "t17_desc_carro",
              label: "t17_desc_carro",
              type: "text",
            },
          ],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "t17_desc_carro",
                accessor: "t17_desc_carro",
              },
            ],
          }
        }}
      />
    );
  }
}
export default Empresa;
