import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class Wallet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: []
    }
    this.webservice = new WS();

    this.efetuaPagamento = this.efetuaPagamento.bind(this)
  }

  mirrorState(key) {
    console.log('mirrorState', key)
    this.setState(key);
  }

  async efetuaPagamento(p, after) {
    console.log('ps_efetuaPagamento 1', p)
    await this.webservice.ps_efetuaPagamento(p.pix_id)
    if (after) {
      after()
    }
  }

  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "wallet",
          tipo_plural: "wallet",
          href: "wallet",
          titulo: "wallet",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'wallet',
            list: true,
            new: true,
            save: true,
            edit: true,
            delete: false,
            listSub: true,
            outras: [{icone: 'FaUsers', tamanho: 30, cor: 'blue', funcao:this.efetuaPagamento.bind(this)}],

            defaultorder: [{ id: "-id" }],
          },
          campos: [
            {
              ref: "id",
              type: "text",
            },
            {
              ref: "id_client",
              type: "text",
            },
            {
              ref: "pix_id",
              type: "text",
            },
            {
              ref: "valor",
              type: "text",
            },
          ],
          table: {
            colunas: [
              {
                Header: "id",
                accessor: "id",
              },
              {
                Header: "id_client",
                accessor: "id_client",
              },
              {
                Header: "pix_id",
                accessor: "pix_id",
              },
              {
                Header: "valor",
                accessor: "valor",
              },
            ],
          }
        }}
      />
    );
  }
}
export default Wallet;