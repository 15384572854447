import React, { Component } from 'react'
import { Form, Input, Button, Modal, Layout } from 'antd'
import styled from 'styled-components'

// ---- FullCalendar Plugins ----
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import WS from '../../services/webservice.js'

const { Content, Header, Footer } = Layout

const StyledLayout = styled(Layout)`
  display: grid;
  position: relative;
  grid-template-areas:
    'header'
    'content';
  grid-template-rows: 1fr 10fr;
`

const ModalContent = styled.div`
  p {
    color: blue;
    font-weight: bold;
    margin: 10px 0;
  }

  strong {
    font-weight: bold;
  }
`

class CalendarioDisponivel extends Component {
  constructor(props) {
    super(props)
    this.webservice = new WS()
    this.agenda = this.agenda.bind(this)
    this.insertAgenda = this.insertAgenda.bind(this)
    this.showModal = this.showModal.bind(this)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)

    this.state = {
      events: [],
      eventInfo: null,
      visible: false,
      agendar: []
    }
  }

  async componentDidMount() {
    this.agenda()
    //console.log('AQUI!!!!', this.state.events)
    //this.insertAgenda()
  }

  handleEventClick = (eventClickInfo) => {
    this.setState({
      eventInfo: eventClickInfo.event,
      visible: true
    })
  }

  showModal(eventInfo) {
    this.setState({
      visible: true,
      eventInfo: eventInfo
    })
  }

  handleOk() {
    this.setState({
      visible: false,
      eventInfo: null
    })
  }

  handleCancel() {
    this.setState({
      visible: false,
      eventInfo: null
    })
  }

  async agenda() {
    try {
      let result = await this.webservice.getAgenda()
      let events = []

      result.forEach(({ solicitacoes, id_instalador }) => {
        solicitacoes.forEach(({ id, data, periodo }) => {
          const [day, month, year] = data.split('-')
          periodo.forEach((time) => {
            events.push({
              id,
              title: `Instalador: ${id_instalador}`,
              start: new Date(year, month - 1, day),
              end: new Date(year, month - 1, day)
            })
          })
        })
      })

      this.setState({ events })
    } catch (error) {
      return error
    }
  }

  async insertAgenda() {
    try {
      let result = await this.webservice.insertAgenda(this.state.agendar)
      return result
    } catch (error) {
      return error
    }
  }

  // handleFormSubmit = (event) => {
  //   event.preventDefault()
  //   const agendar = {
  //     id_instalador: this.state.agendar.id_instalador,
  //     data: this.state.agendar.data,
  //     periodo: this.state.agendar.periodo
  //   }
  //   this.insertAgenda(agendar)
  // }

  // handleInputChange = (event) => {
  //   const { name, value } = event.target
  //   this.setState({ agendar: { ...this.state.agendar, [name]: value } })
  // }

  render() {
    return (
      <StyledLayout>
        <h1>Datas/Horários Disponíveis</h1>
        <FullCalendar
          contentHeight='auto'
          allDaySlot={false}
          slotEventOverlap={false}
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView='dayGridMonth'
          events={this.state.events}
          //eventClick={this.handleEventClick}
        />
        <Modal
          title={`Informações do Evento`}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <ModalContent>
            {this.state.eventInfo ? (
              <div>
                <p>
                  <strong>ID:</strong> {this.state.eventInfo.id}
                </p>
                <p>{this.state.eventInfo.title}</p>
              </div>
            ) : (
              <p>Nenhum evento selecionado</p>
            )}
          </ModalContent>
        </Modal>
      </StyledLayout>
    )
  }
}

export default CalendarioDisponivel
