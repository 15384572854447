import React from 'react';
import WS from '../services/webservice';
import GOpivot from './gopivot';
import PivotTableUI from 'react-pivottable/PivotTable';
import 'react-pivottable/pivottable.css';
import Loader from 'react-loader';
import moment from 'moment';
import ReactTable from "react-table";
import _ from 'lodash'
import { Row, Col, Well, Collapse, DropdownButton, MenuItem, ButtonToolbar, Button, Form, FormGroup, ControlLabel, FormControl, Radio } from 'react-bootstrap'
import "react-table/react-table.css";

const mesNome = ['', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

class Relatorio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      var: [],
      vari: [],
      att: false,
      cols: [],
      rows: [],
      prel: [],
      pCentral: [],
      temano: false,
      temmes: false,
      attcod: 0,
      ano: moment().year(),
      coluna: '',
      linha: '',
      rtipo: 1,
      mes: moment().format('MM'),
      ativo: null,
      cod: '',
      mudou: false,
      open2: false,
      open3: false,
      vetform: [],
      sql: '',
      loaded: false,
      dado: {
        mes: 6,
        ano: 2018
      },
    };

    this.webservice = new WS();
    this.pegarel = this.pegarel.bind(this);
    this.escolhe = this.escolhe.bind(this);
    this.escolhes = this.escolhes.bind(this);
    this.mudar = this.mudar.bind(this);
    this.formularios = this.formularios.bind(this);
    this.criarRel = this.criarRel.bind(this);
    this.abrirform2 = this.abrirform2.bind(this);
    this.abrirform3 = this.abrirform3.bind(this)
    this.enviaRel = this.enviaRel.bind(this);
    this.enviaRel2 = this.enviaRel2.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  async pegarel() {
    let rel = await this.webservice.listaRelatorio();
    this.setState({
      prel: rel,
    })
    //console.log("VOCCE TEM QUE APARECER SEU SACI")
    //console.log(rel)
  }

  async componentDidMount() {
    this.pegarel();
    this.setState({
      mudou: false,
    })
  }

  abrirform2() {
    console.log('admin',this.props.childProps.userAttrib)
    this.setState({
      open3: false,
      open2: !this.state.open2,
      att: false,
      attcod: '',
      cod: '',
      coluna: '',
      linha: '',
      rtipo: 1,
      sql: ''
    })
  }

  abrirform3() {
    console.log(this.state.ativo)
    if (!this.state.ativo.codigo) { return }
    this.setState({
      open3: !this.state.open3,
      open2: false,
      att: !this.state.att,
      attcod: this.state.ativo.codigo,
      cod: this.state.ativo.relatorio,
      coluna: this.state.ativo.colunas.join(" "),
      linha: this.state.ativo.linhas.join(" "),
      sql: this.state.ativo.sqltext,
      rtipo: this.state.ativo.tipo
    })
  }

  async mudar() {
    await this.setState({
      mudou: true,
      loaded: false,
    });
    //console.log("SABER QUAL ESTA ATIVO")
    //console.log(this.state.ativo)
    this.escolhe(this.state.ativo)

    //funcao vai ser chamada apos pressionar o botao depois de selecionado
    // sem problema de dar conflito com o estado falso



    // passa o mes e ano do input
  }
  handleOptionChange(changeEvent) {
    console.log('changeEvent',changeEvent.target.value)
    this.setState({
      rtipo: Number(changeEvent.target.value)
    });
  }
  criarRel() {
    return (
      <div>
        <Form>
          {!this.state.att && <ControlLabel>SQL</ControlLabel>}
          {this.state.att && <ControlLabel> Atualizar SQL </ControlLabel>}
          <FormControl style={{ height: 200, marginLeft: '12px', marginBottom: '3px' }} componentClass="textarea" placeholder="insira o sql" inputRef={i => this.rel = i} onChange={(e) => this.setState({ sql: e.target.value })} value={this.state.sql} />
          <FormControl style={{ height: 40, width: 500, marginLeft: '12px', marginBottom: '3px' }} type="text" label="Text" placeholder="escreva colunas separada por espaço" inputRef={a => this.env = a} onChange={(e) => this.setState({ coluna: e.target.value })} value={this.state.coluna} />
          <FormControl style={{ height: 40, width: 500, marginLeft: '12px', marginBottom: '3px' }} type="text" label="Text" placeholder="escreva linhas separada por espaço" inputRef={a => this.env2 = a} onChange={(e) => this.setState({ linha: e.target.value })} value={this.state.linha} />
          <FormControl type="text" placeholder="escreva o nome do relatorio" style={{ height: 40, width: 300, marginLeft: '12px', marginBottom: '3px' }} onChange={e => { this.setState({ cod: e.target.value }) }} value={this.state.cod} />
          <FormGroup>
            <Radio name="radioGroup" value="0" checked={this.state.rtipo === 0} onChange={this.handleOptionChange} inline>
              Tipo 0 (INATIVO)
            </Radio>{' '}
            <Radio name="radioGroup" value="1" checked={this.state.rtipo === 1} onChange={this.handleOptionChange} inline>
              Tipo 1 (Simples)
            </Radio>{' '}
            <Radio name="radioGroup" value="2" checked={this.state.rtipo === 2} onChange={this.handleOptionChange} inline>
              Tipo 2 (Gigante)
            </Radio>{' '}
            <Radio name="radioGroup" value="3" checked={this.state.rtipo === 3} onChange={this.handleOptionChange} inline>
              Tipo 3 (Separado por Central)
            </Radio>{' '}
            <Radio name="radioGroup" value="4" checked={this.state.rtipo === 4} onChange={this.handleOptionChange} inline>
              Tipo 4 (Gigante sem pivot)
            </Radio>{' '}
            <Radio name="radioGroup" value="4" checked={this.state.rtipo === 5} onChange={this.handleOptionChange} inline>
              Tipo 5 Outro Ainda
            </Radio>
          </FormGroup>

        {this.state.att && <FormControl type="hidden" placeholder="escreva o numero do relatorio" style={{ height: 40, width: 300, marginLeft: '12px', marginBottom: '3px' }} onChange={e => { this.setState({ attcod: e.target.value }) }} value={this.state.attcod} />}
        </Form>
      </div>
    )
  }

  async enviaRel() {
    let vsql = this.state.sql.split("\n")
    let rsql = ''
    for (let i = 0; i < vsql.length; i++) {
      rsql += vsql[i] + " ";
    }
    let vcol = this.state.coluna.split(" ")
    let vlin = this.state.linha.split(" ")
    console.log(this.state.sql);
    await this.webservice.salvaRelatorio(rsql, this.state.cod, this.state.rtipo, vlin, vcol)
    this.setState({
      open2: !this.state.open2
    })
    this.pegarel();
  }

  async enviaRel2() {
    let vsql = this.state.sql.split("\n")
    let rsql = ''
    for (let i = 0; i < vsql.length; i++) {
      rsql += vsql[i] + " ";
    }
    let vcol = this.state.coluna.split(" ")
    let vlin = this.state.linha.split(" ")
    await this.webservice.updateRelatorio(rsql, this.state.cod, this.state.rtipo, this.state.attcod, vlin, vcol)
    this.setState({
      open3: !this.state.open3
    })
    this.pegarel();
  }
  async escolhes(n) {
    this.setState({
      ativo: n,
      open2: false,
      open3: false,
      temano: false,
      emmes: false,
      att: false,
      attcod: n.codigo,
      cod: n.relatorio,
      coluna: n.colunas.join(" "),
      linha: n.linhas.join(" "),
      sql: n.sqltext,
      rtipo: n.tipo
    })
  }
  async escolhe(n) {
    this.setState({
      ativo: n,
      open2: false,
      open3: false,
      temano: false,
      emmes: false,
      att: false,
      attcod: n.codigo,
      cod: n.relatorio,
      coluna: n.colunas.join(" "),
      linha: n.linhas.join(" "),
      sql: n.sqltext,
      rtipo: n.tipo
    })
    let dado
    let n2 = parseInt(n.codigo, 10);
    dado = {
      ano: Number(this.state.ano),
      mes: Number(this.state.mes),
      central_id: this.props.childProps.userAttrib.centralString.ids
    }
    console.log('dado',dado)
    let lista = await this.webservice.executeRelatorio(n2, dado)
    let row = n.linhas
    let col = n.colunas

    let vari = [];
    vari.push(Object.keys(lista[0]));
    let itensvari = Object.keys(lista[0]);
    //console.log('vari 1',itensvari)
    let colti = [];

    if (n.tipo === 2 || n.tipo === 4) {
      for (let i = 0; i < itensvari.length; i++) {
        let header = itensvari[i].toUpperCase();
        if (itensvari[i].charAt(0) === 'd') {
          header = header.charAt(1) + header.charAt(2);
        }
        let item = {Header: header, accessor: itensvari[i]}
        if (itensvari[i].charAt(0) === 'd') {
           item.aggregate= vals => _.sum(vals)
           item.width = 50
        } else if (itensvari[i] === 'total') {
           item.aggregate = vals => _.sum(vals)
           item.width = 100
        } else {
          item.Aggregated = header
        }
        //console.log('colti item',itensvari.length)
        colti.push(item)
      }
    }
    let pCentral = [];
    if (n.tipo === 3) {
      let itens = [];
      let titulo = lista[0].central;
      for (let i = 0; i < lista.length; i++) {
        if (titulo !== lista[i].central) {
          pCentral.push({central: titulo, itens: itens});
          titulo = lista[i].central;
          itens = [];
        }
        itens.push(lista[i]);
      }
      pCentral.push({central: titulo, itens: itens});
    }
    console.log('pCentral',pCentral)
    let datanumber = lista.length;


    this.setState({
      var: lista,
      vari: vari,
      loaded: true,
      rows: row,
      cols: col,
      colti: colti,
      datanumber: datanumber,
      pCentral: pCentral
    })
  }

  formularios(n) {
    if (!n.codigo) { return }
    let n2 = parseInt(n.codigo, 10);
    console.log("FORM")
    console.log(n2)
    let sql = n.sqltext;
    let vsql = sql.split("\n")
    let rsql
    for (let i = 0; i < vsql.length; i++) {
      rsql += vsql[i] + " ";
    }
    let exp = rsql.split(" ");
    let vetor = []
    let x = /(:)([A-Z]|[a-z])\w+(:)/;
    console.log(rsql)
    for (let i = 0; i < exp.length; i++) {
      if (x.exec(exp[i])) {
        console.log(exp[i])
        vetor.push(exp[i]);
      }
    }
    let fvetor = [];
    let y = /([A-Z]|[a-z])\w+/;
    for (let i = 0; i < vetor.length; i++) {
      fvetor[i] = y.exec(vetor[i])
      vetor[i] = fvetor[i][0];
    }
    // fazer a funcao de achar o estado equivalente
    for (let j = 0; j < vetor.length; j++) {
      if (vetor[j].localeCompare("ano") === 0 && this.state.temano === false) {
        this.setState({
          temano: true,
        })
      }
      if (vetor[j].localeCompare("mes") === 0 && this.state.temmes === false) {
        this.setState({
          temmes: true,
        })
      }
    }

    return (
      <Row>
        {this.state.temano &&
          <Col md={2}>
            <FormGroup>
              <ControlLabel>Ano</ControlLabel>
              <FormControl type="number" value={this.state.ano} style={{ height: 30 }} onChange={e => { this.setState({ ano: e.target.value }) }} />
            </FormGroup>
          </Col>
        }
        {this.state.temmes &&

          <Col md={2}>
            <FormGroup>
              <ControlLabel>Mês</ControlLabel>
              <FormControl value={this.state.mes} style={{ height: 30 }} componentClass="select" inputRef={el => this.inputEl = el} onChange={e => { this.setState({ mes: this.inputEl.value }) }}>
                <option key='1' value="01">Janeiro</option>
                <option key='2' value="02">Fevereiro</option>
                <option key='3' value="03">Março</option>
                <option key='4' value="04">Abril</option>
                <option key='5' value="05">Maio</option>
                <option key='6' value="06">Junho</option>
                <option key='7' value="07">Julho</option>
                <option key='8' value="08">Agosto</option>
                <option key='9' value="09">Setembro</option>
                <option key='10' value="10">Outubro</option>
                <option key='11' value="11">Novembro</option>
                <option key='12' value="12">Dezembro</option>
              </FormControl>
            </FormGroup>
          </Col>
        }
        {(this.state.temmes || this.state.temano) &&
          < Col md={2} style={{marginTop: 20}}>
            <Button onClick={this.mudar}> Salvar </Button>
          </Col>
        }
      </Row>
    )
  }

  render() {
    return (
      <div style={{ minHeight: '400px' }}>
        <ButtonToolbar style={{ marginBottom: '20px' }}>
          <DropdownButton title="Relatorios" id='relatorios'>
            {this.state.prel.map((r, i) => {
              if (r.sqltext) {
                if (r.tipo > 0) {
                return (<MenuItem key={r.codigo} eventKey={i.toString()}  onClick={() => this.escolhe(r)}  >Relatorio {r.relatorio}</MenuItem>)
                } else return null;
              }
              else return null;
            })}
          </DropdownButton>
          {this.props.childProps.userAttrib.admin &&
            <DropdownButton title="RelatoriosEdit" id='RelatoriosEdit'>
              {this.state.prel.map((r, i) => {
                if (r.sqltext) {
                  return (<MenuItem key={r.codigo} eventKey={i.toString()}  onClick={() => this.escolhes(r)}  >Relatorio {r.relatorio}</MenuItem>)
                }
                else return null;
              })}
            </DropdownButton>
          }
          {this.props.childProps.userAttrib.admin &&
          <Button onClick={this.abrirform2} >Criar relatorio</Button> }
            {this.props.childProps.userAttrib.admin &&
          <Button onClick={this.abrirform3}> Atualizar relatorio </Button> }
        </ButtonToolbar>
        <Collapse in={this.state.ativo ? true : false}>
          <div >
            <Well>
              {this.state.ativo && this.formularios(this.state.ativo)}
            </Well>
          </div>
        </Collapse>
        <Collapse in={this.state.open2}>
          <div >
            <Well>
              {this.criarRel()}
              <Button onClick={this.enviaRel}> Salvar </Button>
            </Well>
          </div>
        </Collapse>
        <Collapse in={this.state.open3}>
          <div>
            <Well>
              {this.criarRel()}
              <Button onClick={this.enviaRel2}> Salvar </Button>
            </Well>
          </div>
        </Collapse>
        {this.state.ativo &&
          <Loader loaded={this.state.loaded}>
            <h1>{this.state.ativo.relatorio} - {mesNome[Number(this.state.mes)]} / {this.state.ano}</h1>
            {this.state.ativo.tipo === 1 &&
            <PivotTableUI
                data={this.state.var}
                rows={this.state.rows}
                cols={this.state.cols}
                onChange={s => this.setState(s)}
                {...this.state}
              />
            }
            {this.state.ativo.tipo === 2 &&
            <ReactTable
                  data={this.state.var}
                  previousText='Anterior'
                  nextText='Próxima'
                  loadingText='Carregando...'
                  noDataText='Nenhum dado encontado'
                  pageText='Página'
                  ofText='de'
                  rowsText='linhas'
                  pivotBy={this.state.rows}
                  columns={this.state.colti}
                  defaultPageSize={this.state.datanumber}
                  className="-striped -highlight"
                />
            }
            {this.state.ativo.tipo === 3 &&
              <view>
                {this.state.pCentral.map((r, i) => {
                    return (<PivotTableUI key={r.central} data={r.itens} rows={this.state.rows} cols={this.state.cols}  onChange={s => this.setState(s)}
                    {...this.state}/>)
                })}

          </view>
            }
            {this.state.ativo.tipo === 4 &&
            <ReactTable
                  data={this.state.var}
                  previousText='Anterior'
                  nextText='Próxima'
                  loadingText='Carregando...'
                  noDataText='Nenhum dado encontado'
                  pageText='Página'
                  ofText='de'
                  rowsText='linhas'
                  columns={this.state.colti}
                  defaultPageSize={this.state.datanumber}
                  className="-striped -highlight"
                />
            }
            {this.state.ativo.tipo === 5 &&
              <GOpivot data={this.state.var} row={this.state.rows} col={this.state.cols} tipo={this.state.ativo.tipo}/>
            }
          </Loader>
        }
      </div>
    );
  }
}

export default Relatorio;
