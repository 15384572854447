import WS from '../services/webservice';
import React, { Component } from 'react';
import { FormControl, FormGroup, ControlLabel } from 'react-bootstrap'
import AutoSuggest from 'react-autosuggest'
import moment from 'moment';

var pagto;

const renderSuggestion = item => (
  <div>
    <span>
      <strong>ID: </strong> {item.id}
    </span>
    <br />
    {item.t12_dthr_agendamento &&
      <span>
        <strong>Data/Hora Agendada:</strong> {moment(item.datahoracorrida).format("DD/MM/YYYY HH:mm")}
      </span>
    }
    {!item.t12_dthr_agendamento &&
      <span>
        <strong>Data/Hora:</strong> {moment(item.datahoracorrida).format("DD/MM/YYYY HH:mm")}
      </span>
    }
    <br />
    <span>
      <strong>Telefone:</strong> {item.telefone.toString().length === 10 ? item.telefone.toString().replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") : item.telefone.toString().replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}
    </span>
    <br />
    <span>
      <strong>Cliente:</strong> {item.cliente}
    </span>
    <br />
    <span>
      <strong>Endereço: </strong>{item.endereco}, {item.numero}
    </span>
    <br />
    <span>
      <strong>Tipo Pagamento:</strong>
      {pagto.map((m, i) => {
        let string = '';
        for (let index = 0; index < item.t12_cd_tp_pgto_a.length; index++) {
          if (m.id === item.t12_cd_tp_pgto_a[index]) {
            if (index === 0) string += ' ' + m.text;
            else string += ', ' + m.text;
          }
        }
        return string;
      })
      }
    </span>
    <br />
    <span>
      <strong>Motorista:</strong> {item.t08_nomemotorista === 'nada' ? 'Sem motorista' : item.t08_nomemotorista + ' (AT ' + item.t07_conceccao + ')'}
    </span>
    <br />
    <span>
      <strong>Status:</strong> {
        item.t12_st_corrida === 'C' ||
          item.t12_st_corrida === 'M' ||
          item.t12_st_corrida === 'H' ||
          item.t12_st_corrida === 'T' ? 'Corrida cancelada' :
          item.t12_st_corrida === 'N' ? 'Corrida não enviada' :
            item.t12_dthr_embarque === null ? 'Pendente QRL' :
              item.t12_dthr_dembarque === null ? 'Pendente QRV' :
                'Corrida finalizada'}
    </span>
    <br />
    <span>
      <strong>Atendente:</strong> {item.atendente}
    </span>
  </div>
);

const renderSuggestionMoto = item => (
  <div>
    <span>
      <strong>AT: </strong> {item.porta}
    </span>
    <br />
    <span>
      <strong>RT: </strong> {item.rt}
    </span>
    <br />
    <span>
      <strong>CPF: </strong> {item.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
    </span>
    <br />
    <span>
      <strong>Nome: </strong> {item.nome}
    </span>
    <br />
  </div>
);

class Search extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
    }

    this.webservice = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
    this.props.defaultClasses.clearProps();
  }

  componentWillMount() {
  }

  componentDidMount() {
    pagto = this.props.pagtos;
    if (this.props.searchType === 'fone') this.props.defaultClasses.getRefs(this.refs);
  }

  getSuggestionValue(item) {
    if (!this.props.motorista) {
      if (this.props.searchType === 'fone') return item.telefone.toString()
      if (this.props.searchType === 'endereco') return item.endereco.toString()
      if (this.props.searchType === 'cliente') return item.cliente.toString()
      if (this.props.searchType === 'moto') return item.t08_nomemotorista.toString()
      if (this.props.searchType === 'atendente') return item.atendente.toString()
    } else {
      if (this.props.searchType === 'AT') return item.porta.toString()
      if (this.props.searchType === 'RT') return item.rt.toString()
      if (this.props.searchType === 'CPF') return item.cpf.toString()
      if (this.props.searchType === 'nome') return item.nome.toString()
    }
  }

  onChange = (event, { newValue }) => {
    newValue = newValue.toString();
    if (!this.props.motorista) {
      if (this.props.searchType === 'fone') {
        if (newValue.length <= 11)
          this.props.defaultClasses.changeValue(newValue.replace(/\D/g, ''), this.props.searchType);
      } else if (this.props.searchType === 'moto') {
        if (newValue.length <= 4)
          this.props.defaultClasses.changeValue(newValue.replace(/\D/g, ''), this.props.searchType);
      } else {
        this.props.defaultClasses.changeValue(newValue, this.props.searchType);
      }
    } else {
      if (this.props.searchType === 'AT') {
        if (newValue.length <= 4)
          this.props.defaultClasses.changeValue(newValue.replace(/\D/g, ''), this.props.searchType);
      }
      else if (this.props.searchType === 'RT') {
        if (newValue.length <= 3)
          this.props.defaultClasses.changeValue(newValue.replace(/\D/g, ''), this.props.searchType);
      }
      else if (this.props.searchType === 'CPF') {
        if (newValue.length <= 11)
          this.props.defaultClasses.changeValue(newValue.replace(/\D/g, ''), this.props.searchType);
      }
      else {
        this.props.defaultClasses.changeValue(newValue, this.props.searchType);
      }
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    value = value.toString();
    if (!this.props.motorista) {
      if (this.props.searchType === 'moto') {
        if (value.length >= 3 && value.length <= 4) {
          this.webservice.runSearch(this.props.defaultProps.startDate, this.props.defaultProps.endDate, this.props.searchType, value).then(result => {
            this.setState({ suggestions: result })
          })
        }
      } else if (this.props.searchType === 'fone') {
        if (value.length >= 10 && value.length <= 11) {
          this.webservice.runSearch(this.props.defaultProps.startDate, this.props.defaultProps.endDate, this.props.searchType, value.replace(/\D/g, '')).then(result => {
            this.setState({ suggestions: result })
          })
        }
      } else {
        this.webservice.runSearch(this.props.defaultProps.startDate, this.props.defaultProps.endDate, this.props.searchType, value).then(result => {
          this.setState({ suggestions: result })
        })
      }
    } else {
      if (this.props.searchType === 'AT') {
        if (value.length <= 4) {
          this.webservice.getMotoristas('?at=' + value).then(result => {
            this.setState({ suggestions: result })
          });
        }
      }
      else if (this.props.searchType === 'RT') {
        if (value.length <= 3) {
          this.webservice.getMotoristas('?rt=' + value).then(result => {
            this.setState({ suggestions: result })
          });
        }
      }
      else if (this.props.searchType === 'CPF') {
        if (value.length <= 11) {
          this.webservice.getMotoristas('?cpf=' + value).then(result => {
            this.setState({ suggestions: result })
          });
        }
      }
      else {
        this.webservice.getMotoristas('?nome=' + value).then(result => {
          this.setState({ suggestions: result })
        });
      }
    }
    console.log(this.state.suggestions)
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] })
  };

  render() {
    return (
      <FormGroup className="autoComp search">
        <ControlLabel>{this.props.title}</ControlLabel>
        {(!this.props.historico && !this.props.motorista) &&
          <AutoSuggest
            ref={"auto_complete_" + this.props.searchType}
            highlightFirstSuggestion={true}
            focusInputOnSuggestionClick={false}
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={(e, { suggestion }) => {
              this.props.defaultClasses.changeCorrida(suggestion);
            }}
            getSuggestionValue={this.getSuggestionValue.bind(this)}
            renderSuggestion={renderSuggestion}
            inputProps={{
              value: this.props.value.toString(),
              onChange: this.onChange,
            }}
          />
        }
        {this.props.historico &&
          <FormControl
            ref={"auto_complete_" + this.props.searchType}
            type="text"
            value={this.props.value}
            onChange={e => {
              if (this.props.searchType === 'fone') {
                if (e.target.value.length <= 11)
                  this.props.defaultClasses.changeValue(e.target.value.replace(/\D/g, ''), this.props.searchType);
              } else if (this.props.searchType === 'moto') {
                if (e.target.value.length <= 4)
                  this.props.defaultClasses.changeValue(e.target.value.replace(/\D/g, ''), this.props.searchType);
              } else
                this.props.defaultClasses.changeValue(e.target.value, this.props.searchType);
            }}
          />
        }
        {this.props.motorista &&
          <AutoSuggest
            ref={"auto_complete_" + this.props.searchType}
            highlightFirstSuggestion={true}
            focusInputOnSuggestionClick={false}
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={(e, { suggestion }) => {
              this.props.defaultClasses.changeMotorista(suggestion);
            }}
            getSuggestionValue={this.getSuggestionValue.bind(this)}
            renderSuggestion={renderSuggestionMoto}
            inputProps={{
              value: this.props.value.toString(),
              onChange: this.onChange,
            }}
          />
        }
      </FormGroup>
    );
  }
}
export default Search;