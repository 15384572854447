import React, { Component } from 'react';
import ReactDom from 'react-dom'
import BlockUi from '@ethanresnick/react-block-ui';
import '@ethanresnick/react-block-ui/style.css';
//import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
//import Autosuggest from 'react-autosuggest';
import AutoComplete from './AutoComplete.js'
import CorridasAntigas from './PopUp.js'
import Dialog from 'react-bootstrap-dialog-v2'
import SearchModal from './SearchModal.js'
import CurrencyInput from 'react-currency-input';
import CorridasAgendadas from './CorridasAgendadas.js'
import DadosCorrida from './DadosCorrida.js'
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PesquisaCorridas from './PesquisaCorridas.js';
import moment from 'moment';
import InputMask from 'react-input-mask';
import PesquisaReclamacoes from './PesquisaReclamacoes.js';
import DadosIUGU from './DadosIUGU.js';
import WS from '../services/webservice';
import './Solicitacoes.css';
import taxi_nor from '../images/taxi-laranja.png';
import taxi_prm from '../images/taxi-preto.png';
import taxi_cdo from '../images/taxi-azul.png';
import red_marker from '../images/red-marker.png';
import blue_marker from '../images/blue-marker.png';
import GoMap from './GoMap.js';
import { Image, Modal, Checkbox, Button, ButtonToolbar, FormGroup, ControlLabel, FormControl, Grid, Row, Col } from 'react-bootstrap'
import GoPagamento from './GoPagamento.js';

var modalIndex = 0;
var nomeSalvo;
var enderecoSalvo;
var numeroSalvo;
var complementoSalvo;

var popUpRefs;
var localRefs;
var destinoRefs;

var enviando = false;

//Markers data
//const MarkerComponent = ({ img_src, rotation }) => <div><img alt="marker" style={{ transform: `rotate(${rotation}deg)` }} src={img_src} className="markers" /></div>;

class Solicitacoes extends Component {

  static defaultProps = {
    center: { lat: -25.475015, lng: -49.2911143 },
    zoom: 15
  };

  constructor(props) {
    super(props);
    this.state = {
      pricescalculado: {},
      c_points: null,
      c_prices: null,
      c_prices_escolhido: null,
      c_prices_tabela: null,
      c_pagamento: null,

      pricescalculado_escolhido: null,
      pricescalculado_desc : '',
      pricescalculado_valor: 0,
      markerDestTemp: null,
      destinos: [],
      multiplosDestinos: false,
      moip: null,
      showIugu: false,
      cartaoSalvo: false,
      descontoEmpresa: null,
      gotTelefone: false,
      departamento: null,
      centralAtual: { endereco: { cidade: '' } },
      pesquisaCorridasShow: false,
      blocking: false,
      vouchers: false,
      voucherOption: [],
      reclamacoes: [],
      todosFuncs: [],
      todosUsuarios: [],
      passageiro: '',
      todasEmpresas: [],
      clickedValor: false,
      valorAproxEmpresa: '',
      gotEmpresa: false,
      gotFuncionario: false,
      nomeEmpresa: '',
      empresaSelected: [],
      empresasOptions: [],
      cpf: '',
      voucherAvulso: null,
      codigoEmpresa: null,
      matricula: null,
      valorCorridaEmpresa: '',
      valorMinimoEmpresa: '',
      valorTaxaMunicipio: '',
      motorista: null,
      corrida: null,
      corridaID: '',
      central: null,
      corridaStatus: '',
      dadosCorrida: null,
      corridasAntigas: [],
      showModal: false,
      showRamal: false,
      ramal: '',
      center: { lat: -25.475015, lng: -49.2911143 },
      zoom: 15,
      address: [],
      addressDest: [],
      local_fullAddress: '',
      destiny_fullAddress: '',
      local: {
        estado: '',
        bairro: '',
        cidade: '',
        numero: '',
        shortAddress: '',
        fullAddress: ''
      },
      localSelected: false,
      destiny: {
        estado: '',
        bairro: '',
        cidade: '',
        shortAddress: '',
      },
      destinySelected: false,
      cliente: '',
      user: null,
      agendCount: [0],
      cpfMask: '',
      cpfChar: '',
      numeroVoucher: '',
      horarioFormat: [{
        '9': '[0-9]',
        '8': '[0-2]',
        '7': '[0-5]',
        '6': '[0-9]',
      }],
      complemento: '',
      complementoDest: '',
      telefone: '',
      telefoneContato: '',
      telefoneMask: '(99) 9999-9999',
      contatoMask: '(99) 99999-9999',
      numeroMask: '99999',
      numeroDestMask: '99999',
      dataAgend: [null],
      horaAgend: [''],
      antecipar: '15',
      observacoes: '',
      coordenadas: '',
      numCarros: '1',
      numberLocal: '',
      numberDest: '',
      markerLocal: [{
        lat: '',
        lng: '',
        img_src: '',
        label: ''
      }],
      markerDest: [{
        lat: '',
        lng: '',
        img_src: '',
      }],
      motoMarker: [{
        lat: '',
        lng: '',
        img_src: '',
      }],
      motoristas: [],
      distance: '',
      distanceValue: '',
      band1: '',
      band1Desc: '',
      band2: '',
      band2Desc: '',
      executivoB1: '',
      executivoB1Desc: '',
      executivoB2: '',
      executivoB2Desc: '',
      pagtoOptions: [],
      carroOptions: [],
      corridaOptions: [],
      clienteOptions: [],
      carroSelected: [],
      clienteSelected: [],
      corridaSelected: [],
      pagtoSelected: [],
      telefoneValidation: null,
      clienteValidation: null,
      enderecoValidation: null,
      localNumberValidation: null,
      destinoValidation: null,
      destNumberValidation: null,
      tipoPagtoValidation: null,
      tipoCorridaValidation: null,
      tipoCarroValidation: null,
      tipoClienteValidation: null,
      situation: '',
      situationStyle: {
        color: '',
      },
      descClicked: false,
      pricesClicked: false,
      descPedidoDin: false,
      descPedidoCar: false,
      changed: false,
      gotClient: false,
      showSearch: false,
      showHistorico: false,
      showMotorista: false,
      showAgend: false,
      showReclamacao: false,
      showReclamacaoLista: false,
      ehReclamacao: false,
      canESC: false,
      disableAll: false,
      tabelada: false,
      valorTabelada: 0,
      agendadaSelected: false,
      loaded: true,
    }

    this.reclamar = this.reclamar.bind(this);
    this.pedirTab = this.pedirTab.bind(this);
    this.setRamal = this.setRamal.bind(this);
    this.modalHide = this.modalHide.bind(this);
    this.hidaRamal = this.hidaRamal.bind(this);
    this.orderTaxi = this.orderTaxi.bind(this);
    this.descClick = this.descClick.bind(this);
    this.pedirDescDin = this.pedirDescDin.bind(this);
    this.pedirDescCartao = this.pedirDescCartao.bind(this);
    this.searchHide = this.searchHide.bind(this);
    this.editCorrida = this.editCorrida.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.motoristaHide = this.motoristaHide.bind(this);
    this.motoristaShow = this.motoristaShow.bind(this);
    this.historicoHide = this.historicoHide.bind(this);
    this.historicoShow = this.historicoShow.bind(this);
    this.cancelCorrida = this.cancelCorrida.bind(this);
    this.getMotoristas = this.getMotoristas.bind(this);
    this.manageShortcut = this.manageShortcut.bind(this);
    this.clearSituation = this.clearSituation.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.atualizarSalvos = this.atualizarSalvos.bind(this);
    this.calculatePrices = this.calculatePrices.bind(this);
    this.checkValidation = this.checkValidation.bind(this);
    this.dateHandleChange = this.dateHandleChange.bind(this);
    this.getClientByNumber = this.getClientByNumber.bind(this);
    this.hidePesquisasCorridas = this.hidePesquisasCorridas.bind(this);
    this.calculatePricesEmpresa = this.calculatePricesEmpresa.bind(this);
    this.validateAutoCompleteLocal = this.validateAutoCompleteLocal.bind(this);
    this.validateAutoCompleteDestiny = this.validateAutoCompleteDestiny.bind(this);
    this.handleShowCancel = this.handleShowCancel.bind(this)
    this.handleCloseCancel = this.handleCloseCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.newCalculaPrice = this.newCalculaPrice.bind(this);
    this.gosetTipoPagamento = this.gosetTipoPagamento.bind(this);
    this.goupdatePagamento = this.goupdatePagamento.bind(this);
    this.webservice = new WS();

    Dialog.setOptions({
      defaultOkLabel: 'Sim',
      defaultCancelLabel: 'Não',
      primaryClassName: 'btn-danger '
    })
  }

  handleShowCancel() {
    if (this.state.corrida) {
      this.setState({ showCancel: true });
    } else {
      this.setState({
        situation: 'Nenhuma corrida selecionada!',
        situationStyle: {
          color: 'red',
        },
      })
      this.clearSituation();
    }
  }

  handleCloseCancel() {
    this.setState({
      showCancel: false,
    })
  }

  handleChange() {
    this.setState({ selection: this.inputEl.value });
  }


  getClientByNumber(n) {
    if (!this.state.gotTelefone) {
      this.setState({ blocking: true });

      const number = n.replace(/\D/g, '');
      if (number.length < 10) {
        this.setState({ telefoneValidation: "error" });
        this.setState({ blocking: false });
        return;
      }
      if (!this.state.agendadaSelected) {
        if (n === this.state.telefonesalvo) {
          this.setState({ blocking: false });
          return;
        }
        this.setState({ telefoneValidation: null });
        this.setState({ telefone: n })
        this.webservice.getClient(n).then(result => {
          if (Object.keys(result).length === 0) {
            this.setState({
              situation: "Telefone não cadastrado!",
              situationStyle: {
                color: 'red',
              },
              blocking: false,
            })
            this.clearSituation();
            return;
          }

          if (moment(result.datahora_ultima_corrida).isBetween(moment().subtract(20, 'minutes'), moment().add(1, 'minutes'))) {
            this.setState({ blocking: false });
            this.webservice.corridaByID(result.ultima_corrida).then(r => {
              if (r[0].t12_dthr_dembarque === null) {
                this.setState({ canESC: false })
                this.dialog.show({
                  body: 'Reclamação??',
                  actions: [
                    Dialog.CancelAction(() => { this.setState({ canESC: true }) }),
                    Dialog.OKAction(() => {
                      this.webservice.corridaByID(result.ultima_corrida).then(r => {
                        this.changeCorridaDados(r[0]);
                      })
                      setTimeout(function () { this.reclamacaoShow(); }.bind(this), 2000);
                      this.setState({ canESC: true })
                    }),
                  ],
                  bsSize: 'small',
                  onHide: (dialog) => {
                    this.setState({ canESC: true })
                    dialog.hide()
                  }
                })
              }
            })
          }

          this.setState({ gotClient: true, localSelected: true, gotTelefone: true });

          var mask = '';
          for (let i = 0; i < result.numero.length; i++) {
            mask += '9';
          }
          this.setState({ numeroMask: mask })

          nomeSalvo = result.cliente;
          enderecoSalvo = result.endereco;
          numeroSalvo = result.numero + '';
          complementoSalvo = result.complemento;

          let c, b, es;

          if (result.bairro === '' || result.bairro === null) {
            this.webservice.geocodeByPlaceId(result.endereco, result.numero, result.cidade).then(res2 => {
              console.log('VALOR NUMERO 1');
              res2.moreinfo.address_components.map((el) => {
                if (el.types[0] === "sublocality_level_1" || el.types[1] === "sublocality_level_1" || el.types[2] === "sublocality_level_1") b = el.long_name;
                else if (el.types[0] === "administrative_area_level_1") es = el.long_name;
                else if (el.types[0] === "administrative_area_level_2") c = el.short_name;
                return null;
              })

              this.setState({
                telefonesalvo: this.state.telefone,
                cliente: result.cliente,
                local: {
                  shortAddress: result.endereco,
                  numero: result.numero,
                  fullAddress: result.endereco,
                  cidade: c,
                  estado: es,
                  bairro: b,
                },
                local_fullAddress: result.endereco,
                complemento: result.complemento,
                numberLocal: result.numero,
                telefoneContato: result.telefone_contato ? result.telefone_contato : this.state.telefoneContato,
                contatoMask: result.telefone_contato && result.telefone_contato.toString().length === 10 ? '(99) 9999-9999' : '(99) 99999-9999',
                coordenadas: result.coordenadas || '',
                //observacoes: result.observacoes || '',
              })
              this.webservice.geocodeByPlaceId(result.endereco, result.numero, result.cidade).then(res => {
                console.log('VALOR NUMERO 2');
                this.setState({
                  markerLocal: [{ lat: res.latitude, lng: res.longitude, img_src: red_marker, label: result.endereco }],
                  center: { lat: res.latitude, lng: res.longitude },
                  blocking: false,
                })
                this.getMotoristas();
              })
            })
          } else {
            this.setState({
              telefonesalvo: this.state.telefone,
              cliente: result.cliente,
              local: {
                shortAddress: result.endereco ? result.endereco : '',
                cidade: result.cidade,
                estado: result.estado,
                bairro: result.bairro,
                numero: result.numero,
              },
              local_fullAddress: result.endereco,
              complemento: result.complemento,
              numberLocal: result.numero,
              telefoneContato: result.telefone_contato ? result.telefone_contato : this.state.telefoneContato,
              contatoMask: result.telefone_contato && result.telefone_contato.toString().length === 10 ? '(99) 9999-9999' : '(99) 99999-9999',
              coordenadas: result.coordenadas || '',
              //observacoes: result.observacoes || '',
            })
            this.webservice.geocodeByPlaceId(result.endereco, result.numero, result.cidade).then(res => {
              console.log('VALOR NUMERO 3');
              this.setState({
                markerLocal: [{ lat: res.latitude, lng: res.longitude, img_src: red_marker, label: result.endereco }],
                center: { lat: res.latitude, lng: res.longitude },
                blocking: false,
              })
              this.getMotoristas();
            })
          }
        })
      }
    }
  }

  showPesquisasCorridas() {
    this.setState({ pesquisaCorridasShow: true, canESC: false, ehReclamacao: false });
  }

  hidePesquisasCorridas() {
    this.setState({ pesquisaCorridasShow: false, canESC: true, ehReclamacao: false });
  }

  hideDadosIUGU(cancelado) {
    if (cancelado && !this.state.cartaoSalvo) {
      this.setState({ pagtoSelected: [] });
    }
    this.setState({ showIugu: false, canESC: true, ehReclamacao: false });
  }

  setDadosIugu(dados, token, cpf, email) {
    let tel = this.state.telefone.replace(/\D/g, '');
    this.setState({
      cartaoSalvo: true,
      moip: {
        fone:
        {
          cpf: cpf,
          fim: tel.substring(0, 2),
          ini: tel.substring(2, this.state.telefone.length)
        },
        token: token,
        cartao: {
          nomecartao: dados.first_name + ' ' + dados.last_name,
          emailcliente: email
        },
        executado: 0,
        producao: window.location.href.indexOf("localhost") > -1 || window.location.href.indexOf("staging") > -1 ? false : true,
      }
    }, () => {
      console.log(this.state.moip);
    });
  }


  reclamar() {
    if (this.state.corrida) {
      this.reclamacaoShow();
    } else {
      this.motoristaShow();
    }
  }

  changeAgendada(corrida) {
    this.setState({ telefoneValidation: null })
    this.setState({ dataAgend: [null], horaAgend: [''], agendCount: [0], horarioFormat: [{ '9': '[0-9]', '8': '[0-2]', '7': '[0-5]', '6': '[0-9]', }] });

    let data = [moment(corrida.datahoraagendamento)];
    let hora = [moment(corrida.datahoraagendamento).format("HH:mm")];

    let destinoEndereco;
    let destinoCidade;
    let destinoNumero;

    let array = corrida.enderecodestino.split(',');

    destinoEndereco = array[0];
    destinoNumero = array[1];
    destinoCidade = corrida.cidadedestino;

    let valorTab = '0';
    if (corrida.t12_cd_tp_corrida_a.includes(88)) {
      valorTab = corrida.valor_tabelada;
      this.setState({ tabelada: true })
    }

    if (corrida.t12_cd_tp_corrida_a.includes(95)) {
      this.setState({ descPedidoDin: true })
    } else if (corrida.t12_cd_tp_corrida_a.includes(100)) {
      this.setState({ descPedidoCar: true })
    }

    this.setState({
      telefoneMask: corrida.telefone.toString().length === 11 ? '(99) 99999-9999' : '(99) 9999-9999',
    });

    this.setState({
      gotTelefone: true,
      destinos: corrida.destinos ? corrida.destinos : [],
      multiplosDestinos: corrida.destinos.length > 0 ? true : false,
      destinySelected: corrida.latitudedestino ? true : false,
      agendadaSelected: true,
      localSelected: true,
      corrida: corrida,
      corridaID: corrida.id,
      central: corrida.central_id,
      telefone: corrida.telefone.toString(),
      cliente: corrida.cliente,
      local: { fullAddress: corrida.endereco, numero: corrida.numero,shortAddress: corrida.endereco, cidade: corrida.cidade, bairro: corrida.t12_bairro, estado: corrida.estado },
      local_fullAddress: corrida.endereco,
      destiny_fullAddress: destinoEndereco,
      numberLocal: corrida.numero,
      complemento: corrida.complemento || '',
      center: { lat: corrida.latitude, lng: corrida.longitude },
      destiny: { fullAddress: destinoEndereco || '', shortAddress: destinoEndereco || '', cidade: destinoCidade || '', bairro: corrida.bairrodestino, estado: corrida.estadodestino },
      numberDest: destinoNumero || '',
      pagtoSelected: corrida.t12_cd_tp_pgto_a,
      corridaSelected: corrida.t12_cd_tp_corrida_a,
      carroSelected: corrida.t12_cd_tp_carro_a,
      clienteSelected: corrida.t12_cd_tp_cliente_a,
      valorTabelada: valorTab,
      telefoneContato: corrida.telefonecontato ? corrida.telefonecontato : corrida.telefone,
      dataAgend: data,
      horaAgend: hora,
      observacoes: corrida.observacoes,
      coordenadas: corrida.coordenadas,
      antecipar: corrida.antecipar,
    }, function () {
      this.agendamentoHide();
      if (corrida.latitudedestino) {
        this.getCenter()
      }
    }.bind(this))
  }

  async sendAtraso() {
    if (this.state.corrida.t12_cpf_sec !== '0') {
      await this.webservice.fetch2('mensagens/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cpf: this.state.corrida.t12_cpf_sec,
          uuid: this.state.corrida.uuid333,
          at: this.state.corrida.t07_conceccao,
          rt: this.state.corrida.t08_rt_sec,
          textolivre: 'O cliente reclamou da demora!',
          remetente: "monitor",
          alternativas: "OK"
        })
      })
    }
  }

  agendamentoHide() {
    this.setState({ showAgend: false, canESC: true });
  }

  agendamentoShow() {
    this.setState({ showAgend: true, canESC: false });
  }

  reclamacaoHide() {
    this.setState({ showReclamacao: false, canESC: true, ehReclamacao: false });
  }

  reclamacaoShow() {
    this.setState({ showReclamacao: true, canESC: false, ehReclamacao: true });
  }

  showReclamacaoLista() {
    this.setState({ showReclamacaoLista: true, canESC: false, ehReclamacao: false });
  }

  hideReclamacaoLista() {
    this.setState({ showReclamacaoLista: false, canESC: true, ehReclamacao: false });
  }

  openCorridaDados() {
    this.setState({ showReclamacao: true, canESC: false, ehReclamacao: true });
  }

  cancelCorrida() {
    if (this.state.corrida) {
      if (this.state.agendadaSelected) {
        this.setState({ canESC: false })
        this.dialog.show({
          body: 'Você tem certeza que deseja cancelar essa corrida??',
          actions: [
            Dialog.CancelAction(() => { this.setState({ canESC: true }) }),
            Dialog.OKAction(() => {
              this.webservice.cancelTaxi(this.state.corridaID, this.props.childProps.userAttrib.nome, this.state.central).then(result => { console.log(result) })
              this.setState({
                situation: 'Corrida cancelada!',
                situationStyle: {
                  color: 'green',
                },
              })
              setTimeout(function () { this.clearFields(); }.bind(this), 2000);
              this.setState({ canESC: true })
            }),
          ],
          bsSize: 'small',
          onHide: (dialog) => {
            this.setState({ canESC: true })
            dialog.hide()
          },
        })
        return;
      }
      else if (this.state.corrida.t12_dthr_dembarque !== null) {
        this.setState({
          situation: 'Essa corrida já foi finalizada! Você não pode cancelar!', situationStyle: { color: 'red', },
        })
        this.clearSituation();
        return;
      }

      this.setState({ canESC: false })
      this.dialog.show({
        body: 'Você tem certeza que deseja cancelar essa corrida??',
        actions: [
          Dialog.CancelAction(() => { this.setState({ canESC: true }) }),
          Dialog.OKAction(() => {
            if (this.state.corrida.t12_st_corrida !== 'C' ||
              this.state.corrida.t12_st_corrida !== 'M' ||
              this.state.corrida.t12_st_corrida !== 'H' ||
              this.state.corrida.t12_st_corrida !== 'T') {
              this.webservice.cancelTaxi(this.state.corridaID, this.props.childProps.userAttrib.nome, this.state.central).then(result => { console.log(result) })
              this.setState({
                situation: 'Corrida cancelada!',
                situationStyle: {
                  color: 'green',
                },
              })
              setTimeout(function () { this.clearFields(); }.bind(this), 2000);
              this.setState({ canESC: true })
            } else {
              this.setState({
                situation: 'Essa corrida já foi cancelada!',
                situationStyle: {
                  color: 'red',
                },
              })
            }
            this.clearSituation();
          }
          ),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          this.setState({ canESC: true })
          dialog.hide()
        }
      })
    } else {
      this.setState({
        situation: 'Nenhuma corrida selecionada!',
        situationStyle: {
          color: 'red',
        },
      })
      this.clearSituation();
    }
  }

  changeCanESC(condition) {
    this.setState({ canESC: condition })
  }

  async changeMotorista(motorista) {
    await this.setState({ motorista: motorista, corrida: null });

    this.motoristaHide();
    this.reclamacaoShow();
    this.setState({ loaded: true });
  }

  async changeCorridaDados(corrida) {

    var motoristaMarker;

    if (corrida.t12_cpf_sec !== '0') {
      this.setState({ loaded: false })
      let result = await this.webservice.getMotoristas('?cpf=' + corrida.t12_cpf_sec);
      result.map((m, i) => {
        if (m.online) {
          this.setState({ motoMarker: [{ lat: m.latitude || '', lng: m.longitude || '', img_src: taxi_prm, }], })
          motoristaMarker = m;
        }
        return null;
      })
      this.setState({ loaded: true })
    }

    this.setState({ showSearch: false, canESC: true, disableAll: true })

    if (/^..9/.test(corrida.telefone)) {
      this.setState({ telefoneMask: '(99) 99999-9999' })
      this.setState({ telefoneContato: corrida.telefone })
    }
    else {
      this.setState({ telefoneMask: '(99) 9999-9999' })
    }

    let destinoEndereco;
    let destinoCidade;
    let destinoNumero;

    if (corrida.latitudedestino) {
      let array = corrida.enderecodestino.split(',');

      destinoEndereco = array[0];
      destinoNumero = array[1];
      destinoCidade = corrida.cidadedestino;
    }

    let valorTab = '0';
    if (corrida.t12_cd_tp_corrida_a.includes(88)) {
      valorTab = corrida.valor_tabelada;
      this.setState({ tabelada: true })
    }

    await this.setState({
      motorista: null,
      corrida: corrida,
      dadosCorrida: corrida.t12_dthr_dembarque,
      corridaID: corrida.id,
      central: corrida.central_id,
      telefone: corrida.t12_fone,
      cliente: corrida.t12_nome,
      local: { fullAddress: corrida.t12_endereco,  numero: corrida.t12_numero,shortAddress: corrida.t12_endereco, cidade: 'Curitiba', estado: corrida.estado, bairro: corrida.t12_bairro },
      numberLocal: corrida.t12_numero,
      local_fullAddress: corrida.t12_endereco,
      destiny_fullAddress: destinoEndereco,
      complemento: corrida.t12_complemento || '',
      center: { lat: corrida.t12_lat, lng: corrida.t12_lon },
      destiny: { fullAddress: destinoEndereco || '', shortAddress: destinoEndereco || '', cidade: destinoCidade || '', estado: corrida.estadodestino, bairro: corrida.bairrodestino },
      numberDest: destinoNumero || '',
      complementoDest: corrida.complementodestino,
      pagtoSelected: corrida.t12_cd_tp_pgto_a,
      corridaSelected: corrida.t12_cd_tp_corrida_a,
      carroSelected: corrida.t12_cd_tp_carro_a,
      clienteSelected: corrida.t12_cd_tp_cliente_a,
      valorTabelada: valorTab,
      telefoneContato: corrida.telefonecontato ? corrida.telefonecontato : corrida.t12_fone,
      dataAgend: corrida.t12_dthr_agendamento ? moment(corrida.t12_dthr_agendamento) : corrida.datahoraagendamento ? moment(corrida.datahoraagendamento) : null,
      horaAgend: corrida.t12_dthr_agendamento ? moment(corrida.t12_dthr_agendamento).format("HH:mm") : corrida.datahoraagendamento ? moment(corrida.datahoraagendamento).format("HH:mm") : '',
      antecipar: corrida.antecipar || '15',
      observacoes: corrida.t12_obs || '',
      coordenadas: corrida.t12_coordenadas || '',
    }, function () {
      if (destinoEndereco || motoristaMarker) {
        this.getCenter()
      }
    }.bind(this))
  }

  onSuggestionSelected(addressType) {
    //if (addressType === 'local') ReactDom.findDOMNode(this.refs.numero_local).focus()
   // if (addressType === 'destino') ReactDom.findDOMNode(this.refs.numero_destino).focus()
  }

  changeAutoCompleteRefs(refs, addressType) {
    if (addressType === 'local') localRefs = refs;
    if (addressType === 'destino') destinoRefs = refs;
  }

  changePopUpRefs(refs) {
    popUpRefs = refs;
  }

  clearSituation() {
    setTimeout(function () {
      this.setState({ situation: '' })
    }.bind(this), 5000);
  }

  atualizarSalvos() {
    nomeSalvo = this.state.cliente;
    enderecoSalvo = this.state.local.shortAddress;
    numeroSalvo = this.state.numberLocal;
    complementoSalvo = this.state.complemento;
  }

  changeCenter(latitude, longitude) {
    this.setState({ center: { lat: latitude, lng: longitude } })
  }

  changeMarker(latitude, longitude, markerImg) {
    this.setState({
      markerLocal: [{
        lat: latitude,
        lng: longitude,
        img_src: markerImg,
        label: ''
      }]
    })
  }

  changeNumber(number, addressType) {
    if (addressType === 'local') this.setState({ numberLocal: number })
    if (addressType === 'destino') this.setState({ numberDest: number })
  }

  changeModalShow(condition) {
    this.setState({ showModal: condition })
  }

  changeAddress(newAddress, addressType) {
    if (addressType === 'local') { this.setState({ address: newAddress }); }
    if (addressType === 'destino') { this.setState({ addressDest: newAddress }); }
  }
  changeEnderecoExtra(newAddress,addressType) {
    if (addressType === 'local') {
      this.setState({ local_fullAddress: newAddress})
    }
    if (addressType === 'destino') {
      this.setState({ destiny_fullAddress: newAddress})
    }
  }
  async organizaMarquerDestinos() {
    let marques = []
    let destinos = this.state.destinos;
    //console.log('destinos',destinos)
    //console.log('destinos',this.state.destiny)
    if (this.state.destiny) {
      if (this.state.destiny.latlon) {
        marques.push({ lat: this.state.destiny.latlon.lat, lng: this.state.destiny.latlon.lng, img_src: blue_marker, label: this.state.destiny.fullAddress })
      }
    }
    //console.log('destinos',marques)
    for (let x = 0; x < destinos.length; x++) {
      const e = destinos[x];
      marques.push({ lat: e.latlon.lat, lng: e.latlon.lng, img_src: blue_marker, label:e.fullAddress})
      //console.log('destinos',marques)
    }

    await this.setState({ markerDest: marques})
    this.getCenter();
  }

  changeEndereco(newAddress, newCity, estado, bairro, addressType, number, obs, latlon, result) {
    if (addressType === 'local') {
      this.setState({ local_fullAddress: newAddress, markerLocal: [{ lat: latlon.lat, lng: latlon.lng, img_src: red_marker, label: newAddress}], local: { fullAddress: newAddress, numero: number, shortAddress: result.rua, cidade: newCity, estado: estado, bairro: bairro, latlon:latlon }, numeroMask: '99999', numberLocal: number ? number : this.state.numberLocal, observacoes: obs ? obs : this.state.observacoes })
    }
    if (addressType === 'destino') {
      this.setState({ destiny_fullAddress: newAddress, destiny: { fullAddress: newAddress, shortAddress: result.rua, cidade: newCity, estado: estado, bairro: bairro,latlon:latlon }, numeroDestMask: '99999', numberDest: number ? number : this.state.numberDest, observacoes: obs ? obs : this.state.observacoes })
    }
    this.getPrices();
    this.organizaMarquerDestinos();
  }

  changeValidation(newValidation, addressType, state) {
    if (addressType === 'local') {
      this.setState({ enderecoValidation: newValidation });
      this.setState({ localSelected: state });
    }
    if (addressType === 'destino') {
      this.setState({ destinoValidation: newValidation, destinySelected: state });
      this.setState({ destinySelected: state });
    }
  }

  setRamal() {
    if (this.state.telefone.length === 0 || this.state.telefone.length === 1) {
      let ramal = this.webservice.getCookie('ramal');
      if (!ramal) {
        ramal = this.state.ramal;
        this.webservice.setCookie('ramal', ramal, 1);
      }
      this.webservice.getRamal(ramal).then(result => {
        if (result.resultado !== 0 && result.destino && result.origem) {
          let uniqueid = this.webservice.getCookie('uniqueid');
          if (uniqueid) {
            console.log(uniqueid, result.uniqueid, result.destino, result.origem);
            if (result.uniqueid === uniqueid) { return; }
          }
          if (result.destino.length > 5 || result.origem.length > 5) {
            /*FAIXA VERMELHA*/
            if (result.extension === " 100") {
              this.webservice.setCookie('central-id', 1, 1);
            }
            /*LIG TAXI 333*/
            if (result.extension === " 200") {
              this.webservice.setCookie('central-id', 2, 1);
            }
            /*BLUMENAU*/
            if (result.extension === " 600") {
              this.webservice.setCookie('central-id', 7, 1);
            }
            /*JOINVILLE*/
            if (result.extension === " 700") {
              this.webservice.setCookie('central-id', 6, 1);
            }
            /*EXECUTIVO*/     //if (result.queuename === "400") { this.webservice.setCookie('central-id', 3, 1); console.log('') }
            /*SAVE AGR*/      //if (result.queuename === "500") { this.webservice.setCookie('central-id', 4, 1); console.log('') }

            this.props.childProps.setCentral();

            this.webservice.getOptions('pagto').then(result => this.setState({ pagtoOptions: result }));
            this.webservice.getOptions('carro').then(result => this.setState({ carroOptions: result }));
            this.webservice.getOptions('corrida').then(result => this.setState({ corridaOptions: result }));
            this.webservice.getOptions('cliente').then(result => this.setState({ clienteOptions: result }));

            if (/^...9/.test(result.destino)) {
              this.setState({ telefoneMask: '(99) 99999-9999' })
            } else {
              this.setState({ telefoneMask: '(99) 9999-9999' })
            }

            this.webservice.setCookie('uniqueid', result.uniqueid, 1);

            if (result.destino.length > 5) {
              enviando = false;
              this.setState({ telefone: result.destino.replace(/\D/g, '') }, () => {
                this.getClientByNumber(this.state.telefone);
                ReactDom.findDOMNode(this.refs.cliente).focus();
              });
            }
            if (result.origem.length > 5) {
              enviando = false;
              this.setState({ telefone: result.origem.replace(/\D/g, '') }, () => {
                this.getClientByNumber(this.state.telefone);
                ReactDom.findDOMNode(this.refs.cliente).focus();
              });
            }
          }
        }
      })
    }
  }

  hidaRamal() {
    this.setState({ showRamal: false, canESC: true });
  }

  modalHide() {
    this.setState({ showModal: false, canESC: true });
  }

  searchHide() {
    this.setState({ showSearch: false, canESC: true });
  }

  historicoHide() {
    this.setState({ showHistorico: false, canESC: true });
  }

  motoristaHide() {
    this.setState({ showMotorista: false, canESC: true });
  }

  searchShow() {
    this.setState({ showSearch: true, canESC: false });
  }

  historicoShow() {
    this.setState({ showHistorico: true, canESC: false });
  }

  motoristaShow() {
    this.setState({ showMotorista: true, canESC: false });
  }

  validateAutoCompleteLocal() {
    if ((/^.....3/.test(this.state.telefone) || /^.....4/.test(this.state.telefone)) && this.state.gotClient && !this.state.gotEmpresa && !this.state.changed) {
      if (this.state.local.shortAddress !== enderecoSalvo) {
        this.setState({
          changed: true,
          situation: 'O endereço foi alterado! Atualizar cadastro do cliente?',
          situationStyle: {
            color: 'red',
          },
        })
      }
    }
  }

  validateAutoCompleteDestiny() {
    // if (this.state.destiny.shortAddress.length > 0 && !this.state.destinySelected) {
    //   this.setState({ destinoValidation: "error" })
    // }
  }

  //Validate the Fields
  checkValidation() {
    let tel = this.state.telefone.replace(/\D/g, '');

    if (this.state.corridaSelected.indexOf("95") > -1 && !this.state.descPedidoDin) {
      this.setState({ descPedidoDin: true });
    } else if (this.state.corridaSelected.indexOf("100") > -1 && !this.state.descPedidoCar) {
      this.setState({ descPedidoCar: true });
    }

    //if (this.state.gotEmpresa && this.state.matricula === '') { ReactDom.findDOMNode(this.refs.matricula).focus(); return false; }
    if (this.state.voucherOption.length > 0 && !this.state.gotFuncionario) {
      ReactDom.findDOMNode(this.refs.autocomplete_cpf.input).focus();
      this.setState({ situation: 'Por favor preencha o CPF!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (this.state.voucherOption.length > 0 && this.state.gotFuncionario && !this.state.gotEmpresa) {
      ReactDom.findDOMNode(this.refs.empresas).focus();
      this.setState({ situation: 'Por favor selecione uma empresa!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (this.state.gotEmpresa && !this.state.destinySelected) {
      ReactDom.findDOMNode(destinoRefs.auto_complete_destino.input).focus();
      this.setState({ situation: 'Por favor preencha o destino!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (tel === '') {
      ReactDom.findDOMNode(this.refs.telefone).focus();
      this.setState({ situation: 'Por favor preencha o Telefone!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (/^..9/.test(tel) && tel.length < 11) {
      ReactDom.findDOMNode(this.refs.telefone).focus();
      this.setState({ situation: 'Por favor preencha o Telefone corretamente!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (!/^..9/.test(tel) && tel.length < 10) {
      ReactDom.findDOMNode(this.refs.telefone).focus();
      this.setState({ situation: 'Por favor preencha o Telefone corretamente!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (this.state.multiplosDestinos && this.state.destinos.length > 0) {
      for (let d in this.state.destinos) {
        if (this.state.destinos[d].Address.shortAddress === '') {
          this.setState({ situation: 'Por favor preencha todos os destinos corretamente!', situationStyle: { color: 'red' } })
          this.clearSituation();
          return false;
        }
        if (!this.state.destinySelected) {
          ReactDom.findDOMNode(destinoRefs.auto_complete_destino.input).focus();
          this.setState({ situation: 'Por favor preencha todos os destinos corretamente!', situationStyle: { color: 'red' } })
          this.clearSituation();
          return false;
        }
      }
    }

    // if (!/^.....9/.test(tel) && tel.length < 14 && !this.state.agendadaSelected) {
    //   ReactDom.findDOMNode(this.refs.telefone).focus();
    //   this.setState({ situation: 'Por favor preencha o Telefone corretamente! teste1', situationStyle: { color: 'red' } })
    //   this.clearSituation();
    //   return false;
    // }

    // if (/^.....9/.test(tel) && tel.length < 15 && !this.state.agendadaSelected) {
    //   ReactDom.findDOMNode(this.refs.telefone).focus();
    //   this.setState({ situation: 'Por favor preencha o Telefone corretamente! teste4', situationStyle: { color: 'red' } })
    //   this.clearSituation();
    //   return false;
    // }

    if (this.state.cliente === '') {
      ReactDom.findDOMNode(this.refs.cliente).focus();
      this.setState({ situation: 'Por favor preencha o Cliente!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (!this.state.localSelected) {
      ReactDom.findDOMNode(localRefs.auto_complete_local.input).focus();
      this.setState({ situation: 'Por favor selecione a Origem!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    // if (this.state.numberLocal === '') {
    //   ReactDom.findDOMNode(this.refs.numero_local).focus();
    //   this.setState({ situation: 'Por favor preencha o número de origem!', situationStyle: { color: 'red' } })
    //   this.clearSituation();
    //   return false;
    // }

    // if (this.state.pagtoSelected.length === 0) {
    //   ReactDom.findDOMNode(this.refs.pagto_type).focus();
    //   this.setState({ situation: 'Por favor selecione um pagamento!', situationStyle: { color: 'red' } })
    //   this.clearSituation();
    //   return false;
    // }

    // if (this.state.telefoneContato.length === 0) {
    //   ReactDom.findDOMNode(this.refs.telefone_contato).focus();
    //   this.setState({ situation: 'Por favor preencha o contato!', situationStyle: { color: 'red' } })
    //   this.clearSituation();
    //   return false;
    // }

    if (this.state.destiny.shortAddress.length > 0 && !this.state.destinySelected) {
      ReactDom.findDOMNode(destinoRefs.auto_complete_destino.input).focus();
      this.setState({ situation: 'Por favor selcione o endereço de destino corretamente!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    // if (this.state.destinySelected && this.state.numberDest === '') {
    //   ReactDom.findDOMNode(this.refs.numero_destino).focus();
    //   this.setState({ situation: 'Por favor preencha o número de destino!', situationStyle: { color: 'red' } })
    //   this.clearSituation();
    //   return false;
    // }

    if (this.state.tabelada && !this.state.destinySelected) {
      ReactDom.findDOMNode(destinoRefs.auto_complete_destino.input).focus();
      this.setState({ situation: 'Por favor selecione o destino!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (this.state.moip && !this.state.destinySelected) {
      ReactDom.findDOMNode(destinoRefs.auto_complete_destino.input).focus();
      this.setState({ situation: 'Por favor selecione o destino!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (this.state.moip && this.state.destinySelected && !this.state.tabelada) {
      this.setState({ situation: 'Por favor preencha o valor tabelado ou selecione do calcular preços!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (this.state.moip && this.state.destinySelected && this.state.tabelada && this.state.valorTabelada <= 0) {
      this.setState({ situation: 'Por favor preencha o valor tabelado ou selecione do calcular preços!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (this.state.dataAgend[0] !== null && this.state.horaAgend[0] === '') {
      ReactDom.findDOMNode(this.refs.agendar_horario.input).focus();
      this.setState({ situation: 'Por favor preencha a hora do agendamento!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (this.state.horaAgend[0] !== '' && this.state.dataAgend[0] === null) {
      ReactDom.findDOMNode(this.refs.date_picker.input).focus();
      this.setState({ situation: 'Por favor preencha a data do agendamento!', situationStyle: { color: 'red' } })
      this.clearSituation();
      return false;
    }

    if (this.state.corridaSelected.length === 0) { this.state.corridaSelected.push("10"); }
    if (this.state.carroSelected.length === 0) { this.state.carroSelected.push("10"); }
    if (this.state.clienteSelected.length === 0) { this.state.clienteSelected.push("10"); }

    return true;
  }

  //Clear the fields to start a new run
  clearFields() {
    ReactDom.findDOMNode(this.refs.telefone).focus();
    ReactDom.findDOMNode(this.refs.telefone).setSelectionRange(0, 0);

    this.setState({
      c_points: null,
      c_prices: null,
      c_prices_escolhido: null,
      c_prices_tabela: null,
      c_pagamento: null,
      pricescalculado: {},
      pricescalculado_escolhido: null,
      pricescalculado_desc : '',
      pricescalculado_valor: 0,
      markerDestTemp: null,
      destinos: [],
      multiplosDestinos: false,
      moip: null,
      showIugu: false,
      cartaoSalvo: false,
      descontoEmpresa: null,
      gotTelefone: false,
      departamento: null,
      pesquisaCorridasShow: false,
      blocking: false,
      vouchers: false,
      voucherOption: [],
      reclamacoes: [],
      todasEmpresas: [],
      todosFuncs: [],
      todosUsuarios: [],
      passageiro: '',
      nomeEmpresa: '',
      empresaSelected: [],
      empresasOptions: [],
      gotFuncionario: false,
      cpf: '',
      voucherAvulso: null,
      clickedValor: false,
      valorAproxEmpresa: '',
      gotEmpresa: false,
      codigoEmpresa: null,
      matricula: null,
      valorCorridaEmpresa: '',
      valorMinimoEmpresa: '',
      valorTaxaMunicipio: '',
      motorista: null,
      corrida: null,
      corridaID: '',
      central: null,
      corridaStatus: '',
      dadosCorrida: null,
      corridasAntigas: [],
      showModal: false,
      showRamal: false,
      center: { lat: -25.475015, lng: -49.2911143 },
      zoom: 15,
      address: [],
      addressDest: [],
      local: {
        cidade: '',
        shortAddress: '',
        estado: '',
        bairro: '',
        fullAddress: '',
        numero: ''
      },
      local_fullAddress: '',
      destiny_fullAddress: '',    
      localSelected: false,
      destiny: {
        cidade: '',
        shortAddress: '',
        estado: '',
        bairro: '',
        fullAddress: '',
      },
      destinySelected: false,
      cliente: '',
      user: null,
      agendCount: [0],
      cpfMask: '',
      cpfChar: '',
      numeroVoucher: '',
      horarioFormat: [{
        '9': '[0-9]',
        '8': '[0-2]',
        '7': '[0-5]',
        '6': '[0-9]',
      }],
      complemento: '',
      complementoDest: '',
      telefone: '',
      telefoneContato: '',
      telefoneMask: '(99) 9999-9999',
      contatoMask: '(99) 99999-9999',
      numeroMask: '99999',
      numeroDestMask: '99999',
      dataAgend: [null],
      dataHoraFim: moment().add(30, "days").utc().format("YYYY-MM-DD HH:mm"),
      horaAgend: [''],
      antecipar: '15',
      observacoes: '',
      coordenadas: '',
      numCarros: '1',
      numberLocal: '',
      numberDest: '',
      markerLocal: [{
        lat: '',
        lng: '',
        img_src: '',
        label: ''
      }],
      markerDest: [{
        lat: '',
        lng: '',
        img_src: '',
      }],
      motoristas: [],
      distance: '',
      distanceValue: '',
      band1: '',
      band1Desc: '',
      band2: '',
      band2Desc: '',
      executivoB1: '',
      executivoB1Desc: '',
      executivoB2: '',
      executivoB2Desc: '',
      carroSelected: [],
      clienteSelected: [],
      corridaSelected: [],
      pagtoSelected: [],
      telefoneValidation: null,
      clienteValidation: null,
      enderecoValidation: null,
      localNumberValidation: null,
      destinoValidation: null,
      destNumberValidation: null,
      tipoPagtoValidation: null,
      tipoCorridaValidation: null,
      tipoCarroValidation: null,
      tipoClienteValidation: null,
      situation: '',
      situationStyle: {
        color: '',
      },
      descClicked: false,
      pricesClicked: false,
      descPedidoDin: false,
      descPedidoCar: false,
      changed: false,
      gotClient: false,
      showSearch: false,
      ehReclamacao: false,
      showReclamacao: false,
      showReclamacaoLista: false,
      showHistorico: false,
      showMotorista: false,
      showAgend: false,
      canESC: true,
      disableAll: false,
      tabelada: false,
      valorTabelada: 0,
      agendadaSelected: false,
      loaded: true,
    })

    modalIndex = 0;
    nomeSalvo = '';
    numeroSalvo = '';
    enderecoSalvo = '';
    complementoSalvo = '';
  }

  //Save the date selected from DatePicker
  dateHandleChange(index, date) {
    let d = this.state.dataAgend;
    d[index] = date;
    this.setState({ dataAgend: d });
  }

  //Gets current position and sets the center on map
  updateLocation(position) {
    this.setState({
      center: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    })
  }

  //Sets the center and the zoom between local position and destination
  getCenter() {
    console.log('ENTROU GET CENTER');
    if (!this.state.local) {return;}
    if (!this.state.local.latlon) {return;}
    if (!this.state.destiny) {return;}
    if (!this.state.destiny.latlon) {return;}
    const bounds = {
      ne: {
        lat: this.state.markerLocal[0].lat,
        lng: this.state.markerLocal[0].lng,
      },
      sw: {
        lat: this.state.markerDest[0].lat,
        lng: this.state.markerDest[0].lng,
      }
    }

    if (this.state.markerLocal[0].lat > bounds.ne.lat) {
      bounds.ne.lat = this.state.markerLocal[0].lat;
    }
    if (this.state.markerLocal[0].lng > bounds.ne.lng) {
      bounds.ne.lng = this.state.markerLocal[0].lng;
    }
    if (this.state.markerLocal[0].lat < bounds.sw.lat) {
      bounds.sw.lat = this.state.markerLocal[0].lat;
    }
    if (this.state.markerLocal[0].lng < bounds.sw.lng) {
      bounds.sw.lng = this.state.markerLocal[0].lng;
    }

    for (let i = 0; i < this.state.markerDest.length; i++) {
      if (this.state.markerDest[i].lat !== '') {
        console.log('POSSUI MARKER DEST');
        if (this.state.markerDest[i].lat > bounds.ne.lat) {
          bounds.ne.lat = this.state.markerDest[i].lat;
        }
        if (this.state.markerDest[i].lng > bounds.ne.lng) {
          bounds.ne.lng = this.state.markerDest[i].lng;
        }
        if (this.state.markerDest[i].lat < bounds.sw.lat) {
          bounds.sw.lat = this.state.markerDest[i].lat;
        }
        if (this.state.markerDest[i].lng < bounds.sw.lng) {
          bounds.sw.lng = this.state.markerDest[i].lng;
        }
      }
    }

    if (this.state.motoMarker[0].lat !== '') {
      if (this.state.motoMarker[0].lat > bounds.ne.lat) {
        bounds.ne.lat = this.state.motoMarker[0].lat;
      }
      if (this.state.motoMarker[0].lng > bounds.ne.lng) {
        bounds.ne.lng = this.state.motoMarker[0].lng;
      }
      if (this.state.motoMarker[0].lat < bounds.sw.lat) {
        bounds.sw.lat = this.state.motoMarker[0].lat;
      }
      if (this.state.motoMarker[0].lng < bounds.sw.lng) {
        bounds.sw.lng = this.state.motoMarker[0].lng;
      }
    }

    const size = {
      width: 550, // Map width in pixels
      height: 253, // Map height in pixels
    };

    const { center, zoom } = fitBounds(bounds, size)
    this.setState({
      center: center,
      zoom: zoom,
    })

// aqui calcula os prices
    //this.newCalculaPrice();
    // if (this.state.motoMarker[0].lat === '') {
    //   if (this.state.destinos.length <= 0 && !this.state.multiplosDestinos) {
    //     //Set the distance from local position to destination
    //     this.webservice.directions(this.state.markerLocal[0].lat, this.state.markerLocal[0].lng, this.state.markerDest[0].lat, this.state.markerDest[0].lng).then(result => {
    //       console.log('DISTANCE', result);
    //       this.setState({ distance: result.text })
    //       this.setState({ distanceValue: result.value },
    //         () => {
    //           if (this.state.gotEmpresa) {
    //             this.calculatePricesEmpresa(this.state.valorCorridaEmpresa);
    //           }
    //         })
    //     })
    //   } else {
    //
    //     this.webservice.directionsWaypoints(this.state.markerLocal[0].lat, this.state.markerLocal[0].lng, this.state.markerDest[this.state.markerDest.length - 1].lat, this.state.markerDest[this.state.markerDest.length - 1].lng, this.state.destinos).then(result => {
    //       let legs = result.routes[0].legs;
    //       let distance = 0;
    //       legs.map(leg => {
    //         distance += leg.distance.value;
    //         return null;
    //       })
    //       let distanceText = (distance / 1000).toFixed(1);
    //       this.setState({ distance: distanceText.toString() + ' km', distanceValue: distance },
    //         () => {
    //           if (this.state.gotEmpresa) {
    //             this.calculatePricesEmpresa(this.state.valorCorridaEmpresa);
    //           }
    //         })
    //     })
    //   }
    // }
  }
  async newCalculaPrice() {
    let central = this.webservice.getCookie('central-id');
    let empresa = this.state.codigoEmpresa
    let origem = {lat: this.state.markerLocal[0].lat, lng: this.state.markerLocal[0].lng};
    let destino = {lat: this.state.markerDest[this.state.markerDest.length - 1].lat, lng: this.state.markerDest[this.state.markerDest.length - 1].lng};
    let destinos = this.state.markerDest;
    if (destinos.length > 0) {
      await this.webservice.calcula(origem,destino,destinos,null,central,empresa).then(result => {
        //console.log('newCalculaPrice',result)
        if (result.prices) {
          let distanceValue = result.prices.cnl.distancia_final;
          let distance = (distanceValue / 1000).toFixed(1).toString() + ' km';
          let variaveis = ['cnl','prm','cdo','ldy','c15','pop','vet'];
          let meuprice = result.prices.cnl;
          let escolhas = ['cnl','cnl'];

          for (let g in this.state.carroOptions) {
            let e = this.state.carroOptions[g];
            if (this.state.carroSelected.indexOf(e.id.toString()) >= 0) {
              if (variaveis.indexOf(e.sigla.toLowerCase()) >= 0) {
                escolhas.push(e.sigla.toLowerCase())
              }
            }
          }
          for (let g in this.state.corridaOptions) {
            let e = this.state.corridaOptions[g];
            if (this.state.corridaSelected.indexOf(e.id.toString()) >= 0) {
              if (variaveis.indexOf(e.sigla.toLowerCase()) >= 0) {
                escolhas.push(e.sigla.toLowerCase())
              }
            }
          }
          // let tipoDeCarro2 = tipoDeCarro.reduce((e, i) => {
          //   if (e)
          //     return e
          //   if (i)
          //     return i
          //   return null
          // })
          // //console.log('tipoDeCarro2',tipoDeCarro2)
          // if (tipoDeCarro2 === null) {
          //   tipoDeCarro2 = 'CNL'
          // }
          //console.log('newCalculaPrice',escolhas)
          if (escolhas.indexOf('c15')>=0) { meuprice = result.prices.c15; }
          if (escolhas.indexOf('pop')>=0) { meuprice = result.prices.pop; }
          if (escolhas.indexOf('ldy')>=0) { meuprice = result.prices.ldy; }
          if (escolhas.indexOf('cdo')>=0) { meuprice = result.prices.cdo; }
          if (escolhas.indexOf('prm')>=0) { meuprice = result.prices.prm; }
          if (this.state.gotEmpresa) { meuprice = result.prices.vet; }

          this.setState({
            pricescalculado: result.prices,
            pricescalculado_desc : meuprice.tipo,
            pricescalculado_valor: meuprice.valor_final,
            valorTabelada: meuprice.valor_final,
            valorAproxEmpresa:  meuprice.valor_final,
            pricescalculado_escolhido: meuprice,
            distanceValue: distanceValue,
            distance: distance,
          })
        }
      })
    }

  }
  calculatePricesEmpresa(preco) {
    if (this.state.distanceValue === '') {
      this.setState({
        situation: 'Por favor preencha com endereço local e destino!',
        situationStyle: {
          color: 'red',
        },
      })
      this.clearSituation();
      return;
    }
    let price = 0;
    let dist = 0;

    dist = this.state.distanceValue / 1000
    price = dist * preco;

    if (price < this.state.valorMinimoEmpresa) { price = this.state.valorMinimoEmpresa }
    if (this.state.local.cidade !== this.state.destiny.cidade) { price += this.state.valorTaxaMunicipio }
    if (this.state.descontoEmpresa) { price = price * (1 - (this.state.descontoEmpresa / 100)); }

    price = price + Number(this.state.comissao);

    this.setState({ valorAproxEmpresa: price });
  }

  //Calculates the price based on the distance from local position to destination
  calculatePrices() {
    let central;
    let central_ID = this.webservice.getCookie('central-id');
    console.log('this.props.childProps.userAttrib.centrais',this.props.childProps.userAttrib.centrais)
    this.props.childProps.userAttrib.centrais.map(c => {
      if (central_ID === c.id.toString()) central = c;
      return null;
    })
    let tipoDeCarro = this.state.carroOptions.map((e) => {
      if (Number(this.state.carroSelected[0]) === e.id) {
        return e.sigla
      }
      return null
    })
    let tipoDeCarro2 = tipoDeCarro.reduce((e, i) => {
      if (e)
        return e
      if (i)
        return i
      return null
    })
    //console.log('tipoDeCarro2',tipoDeCarro2)
    if (tipoDeCarro2 === null) {
      tipoDeCarro2 = 'CNL'
    }
    let valoresNovos = central.valores.filter((e) => {
      if (e && tipoDeCarro2) {
        if (e.sigla.toLowerCase() === tipoDeCarro2.toLowerCase()) {
          return e
        }
      }
    })
    if (valoresNovos.length < 1) {
      tipoDeCarro2 = 'CNL';
      valoresNovos = central.valores.filter((e) => {
        if (e && tipoDeCarro2) {
          if (e.sigla.toLowerCase() === tipoDeCarro2.toLowerCase()) {
            return e
          }
        }
      })
    }
    //console.log("tipo", valoresNovos)
    if (this.state.distanceValue === '') {
      this.setState({
        situation: 'Por favor preencha com endereço local e destino!',
        situationStyle: {
          color: 'red',
        },
      })
      this.clearSituation();
      return;
    }

    this.setState({ pricesClicked: true })

    const bandeirada = valoresNovos[0].band_b1;
    const bandeiradaExecutivo = valoresNovos[0].band_b2;
    const valorB1 = valoresNovos[0].km_b1;
    const valorB2 = valoresNovos[0].km_b2;
    const valorExecutivoB1 = 3.6;
    const valorExecutivoB2 = 4.4;

    let dist = this.state.distanceValue / 1000;
    let band1 = bandeirada + (dist * valorB1);
    let band2 = bandeirada + (dist * valorB2);
    let band1Desc = band1 * 0.8;
    let band2Desc = band2 * 0.8;
    let executivoB1 = bandeiradaExecutivo + (dist * valorExecutivoB1);
    let executivoB1Desc = executivoB1 * 0.8;
    let executivoB2 = bandeiradaExecutivo + (dist * valorExecutivoB2);
    let executivoB2Desc = executivoB2 * 0.8;

    if (this.state.local.cidade !== this.state.destiny.cidade) {
      band1 = band1 + central.taxaretornofixo + (band1 * (central.taxaretornoperc / 100));
      band1Desc = band1 * 0.8;
      band2 = band2 + central.taxaretornofixo + (band2 * (central.taxaretornoperc / 100));
      band2Desc = band2 * 0.8;
      executivoB1 = executivoB1 + central.taxaretornofixo + (executivoB1 * (central.taxaretornoperc / 100));
      executivoB1Desc = executivoB1 * 0.8;
      executivoB2 = executivoB2 + central.taxaretornofixo + (executivoB2 * (central.taxaretornoperc / 100));
      executivoB2Desc = executivoB2 * 0.8;
    }

    this.setState({
      valorTabelada: band1,
      band1: band1,
      band1Desc: band1Desc,
      band2: band2,
      band2Desc: band2Desc,
      executivoB1: executivoB1,
      executivoB1Desc: executivoB1Desc,
      executivoB2: executivoB2,
      executivoB2Desc: executivoB2Desc,
    }, function () {
      if (this.state.tabelada) {
        ReactDom.findDOMNode(this.refs.tabelada).focus();
      }
    }.bind(this))
  }

  //Manages the shotcuts and calls the function for each shortcut
  manageShortcut(e) {
    var code = (e.keyCode ? e.keyCode : e.which);

    //SHORTCUT: ALT + Q
    if (code === 81 && e.altKey) {
      e.preventDefault(); e.stopPropagation();
      console.log("TESTANDO ULTIMA CHANCE", this.props)
      this.showReclamacaoLista();
    }

    //SHORTCUT: F2
    if (code === 113) {
      e.preventDefault(); e.stopPropagation();
      this.showPesquisasCorridas();
    }

    //SHORTCUT: ALT + R
    if (code === 82 && e.altKey) {
      e.preventDefault(); e.stopPropagation();
      Dialog.setOptions({
        defaultOkLabel: 'Sim',
        defaultCancelLabel: 'Não',
        primaryClassName: 'btn-danger '
      })
      if (this.state.corrida) {
        this.reclamacaoShow();
      }
      else {
        this.motoristaShow();
      }
    }

    //SHORTCUT: END
    if (code === 35) {
      this.setState({ changed: false });
      this.refs.pagto_type.el.select2('close');
      this.refs.carro_type.el.select2('close');
      this.refs.corrida_type.el.select2('close');
      if (!this.state.agendadaSelected) {
        this.orderTaxi();
      } else {
        this.editCorrida();
      }
      // else {
      //   this.setState({
      //     situation: 'Por favor preencha os campos obrigatórios!',
      //     situationStyle: { color: 'red' }
      //   })
      //   this.clearSituation();
      // }
    }
    //SHORTCUT: ESC
    if (code === 27) {
      e.preventDefault(); e.stopPropagation();
      if (this.state.canESC) {
        this.setState({ canESC: false })
        this.dialog.show({
          body: 'Limpar a Tela??',
          actions: [
            Dialog.CancelAction(() => { this.setState({ canESC: true }) }),
            Dialog.OKAction(() => {
              setTimeout(function () { this.clearFields(); }.bind(this), 400);
              setTimeout(function () { ReactDom.findDOMNode(this.refs.telefone).focus(); }.bind(this), 635);
            }),
          ],
          bsSize: 'small',
          onHide: (dialog) => {
            this.setState({ canESC: true })
            dialog.hide()
          }
        })
      }
    }

    // //SHORTCUT: ALT + P
    // if (code === 80 && e.altKey) {
    //   e.preventDefault(); e.stopPropagation();
    //   this.searchShow();
    // }

    //SHORTCUT: F3
    if (code === 114) {
      e.preventDefault(); e.stopPropagation();
      this.agendamentoShow();
    }

    //SHORTCUT: F4
    if (code === 115) {
      e.preventDefault(); e.stopPropagation();
      if (this.state.corrida) {
        this.cancelCorrida();
      } else {
        this.setState({
          situation: 'Nenhuma corrida selecionada!',
          situationStyle: {
            color: 'red',
          },
        })
        this.clearSituation();
      }
    }

    //SHORTCUT:F6
    if (code === 117) {
      e.preventDefault(); e.stopPropagation();
      if (this.state.gotClient) {
        if (this.state.corridasAntigas.length <= 0) {
          this.webservice.getCorridasAntigas(this.state.telefone.replace(/\D/g, '')).then(result => {
            this.setState({ corridasAntigas: result }, () => {
              if (this.state.corridasAntigas.length > 0) {
                this.setState({ showModal: true, canESC: false, numeroDestMask: "99999", numeroMask: "99999" });
                modalIndex = 0;
              }
            })
          })
        } else {
          this.setState({ showModal: true, canESC: false, numeroDestMask: "99999", numeroMask: "99999" });
          modalIndex = 0;
        }
      }
    }

    //SHORTCUT: F8
    if (code === 119) {
      e.preventDefault(); e.stopPropagation();
      this.historicoShow();
    }

    if (this.state.showModal === true) {
      if (code === 38) {
        modalIndex--;
        if (modalIndex < 0) modalIndex = 9;
        ReactDom.findDOMNode(popUpRefs['corrida' + modalIndex]).focus();
      }
      if (code === 40) {
        modalIndex++;
        if (modalIndex > 9) modalIndex = 0;
        ReactDom.findDOMNode(popUpRefs['corrida' + modalIndex]).focus();
      }

    
      if (code === 13) {
        this.setState({
          local: {
            fullAddress: this.state.corridasAntigas[modalIndex].endereco,
            shortAddress: this.state.corridasAntigas[modalIndex].endereco,
            cidade: 'Curitiba',
            numero: this.state.corridasAntigas[modalIndex].numero,
          },
          numberLocal: this.state.corridasAntigas[modalIndex].numero,
          showModal: false,
          local_fullAddress:this.state.corridasAntigas[modalIndex].endereco
        })
        this.webservice.geocodeByPlaceId(this.state.corridasAntigas[modalIndex].endereco, this.state.corridasAntigas[modalIndex].numero, this.state.local.cidade).then(result => {
          console.log('VALOR NUMERO 4');
          this.setState({
            markerLocal: [{
              lat: result.latitude,
              lng: result.longitude,
              img_src: red_marker,
              label: this.state.corridasAntigas[modalIndex].endereco,
            }]
            , center: { lat: result.latitude, lng: result.longitude },
          })
        })
      }
    }
  }

  //Order a taxi and save the data
  editCorrida() {
    if (!enviando) {
      enviando = true;
      if (this.checkValidation()) {

        let date = moment(this.state.dataAgend[0]).format("YYYY-MM-DD");
        let hour = this.state.horaAgend;

        let dataCompleta = moment(date + 'T' + hour + ':00').utc().format("YYYY-MM-DD HH:mm");

        let monDataComp = moment(date + 'T' + hour + ':00').format("YYYY-MM-DD HH:mm");
        if (moment(monDataComp).format("MM") === moment().format("MM")) {
          if (moment(monDataComp).format("DD") === moment().format("DD")) {
            if (moment(monDataComp).format("HH") === moment().format("HH")) {
              if (Number(moment(monDataComp).format("mm")) < Number(moment().format("mm"))) {
                this.setState({
                  situation: 'Horário agendado inválido!',
                  situationStyle: {
                    color: 'red',
                  }
                })
                this.clearSituation();
                enviando = false;
                return;
              }
            } else if (Number(moment(monDataComp).format("HH")) < Number(moment().format("HH"))) {
              this.setState({
                situation: 'Horário agendado inválido!',
                situationStyle: {
                  color: 'red',
                }
              })
              this.clearSituation();
              enviando = false
              return;
            }
          }
        }
        let codigos = [];

        codigos.push(this.state.c_prices_escolhido)



        this.webservice.orderTaxi(
          {
            destinos: this.state.destinos,
            codigos: codigos.join(' ').toUpperCase().trim(),
            voucherAvulso: this.state.voucherAvulso,
            codigo_empresa: this.state.codigoEmpresa,
            matricula: this.state.matricula,
            departamento: this.state.departamento,
            id: this.state.corridaID,
            telefone: this.state.telefone,
            nome: this.state.cliente,
            endereco: this.state.local.fullAddress,
            numero: this.state.numberLocal,
            complemento: this.state.complemento,
            cidade: this.state.local.cidade,
            estado: this.state.local.estado,
            bairro: this.state.local.bairro,
            latitude: this.state.markerLocal[0].lat,
            longitude: this.state.markerLocal[0].lng,
            enderecoDestino: this.state.destiny.fullAddress,
            numeroDestino: this.state.numberDest,
            complementoDest: this.state.complementoDest,
            cidadeDestino: this.state.destiny.cidade,
            estadoDestino: this.state.destiny.estado,
            bairroDestino: this.state.destiny.bairro,
            latitudeDestino: this.state.markerDest[0].lat === '' ? null : this.state.markerDest[0].lat,
            longitudeDestino: this.state.markerDest[0].lng === '' ? null : this.state.markerDest[0].lng,
            tipo_pagto: this.state.pgto,
            telefone_contato: this.state.telefoneContato === '' ? this.state.telefone : this.state.telefoneContato,
            data_hora_agendamento: dataCompleta,
            observacoes: this.state.observacoes,
            coordenadas: this.state.coordenadas,
            numero_carros: Number(this.state.numCarros),
            atendente: this.props.childProps.userAttrib.nome,
            antecipar: Number(this.state.antecipar),
            tabelavalores: this.state.c_prices_tabela,
            valorTabelada: this.state.c_prices_tabela ? this.state.c_prices_tabela.valor_final : 0,
          }).then(async result => {
            if (result.result) {
              this.clearFields();
              this.setState({
                situation: 'Atendimento editado com sucesso!',
                situationStyle: {
                  color: 'green',
                }
              })
              this.clearSituation();
            } else {
              if (result.err) {
                console.log(result.err);
                enviando = false;
                this.clearSituation();
              } else {
                result = await result.json();
                this.setState({
                  situation: result.err,
                  situationStyle: {
                    color: 'red',
                  }
                })
                enviando = false;
                this.clearSituation();
              }
            }
          }).catch(err => {
            if (err) {
              this.setState({
                situation: 'Ocorreu um erro ao enviar o atendimento! Verifique se está conectado na internet e tente novamente!',
                situationStyle: { color: 'red' }
              })
              enviando = false;
              return;
            }
          })
      }
      else {
        enviando = false;
      }
    }
  }

  //Order a taxi and save the data
  orderTaxi() {
    if (!enviando) {
      enviando = true;
      if (this.checkValidation()) {
        if (this.state.local.cidade.toUpperCase() !== this.state.centralAtual.endereco.cidade.toUpperCase()) {

          Dialog.resetOptions();
          this.dialog.showAlert('Cidade não atendida pela Central!');
          enviando = false;

          // this.dialog.show({
          //   body: 'Você confimar a corrida com a origem na cidade de ' + this.state.local.cidade + '??',
          //   actions: [
          //     Dialog.CancelAction(() => { this.setState({ canESC: true }) }),
          //     Dialog.OKAction(() => {
          //       var dataCompleta = null;
          //       let Observacao = this.state.observacoes;

          //       if (this.state.tabelada) {
          //         let vlt = this.state.valorTabelada;
          //         Observacao += ' / Tabelada em R$ ' + vlt.toFixed(2);
          //       }
          //       let descontoAsked = null;
          //       if (this.state.corridaSelected.indexOf("95") > -1 || this.state.corridaSelected.indexOf("100") > -1) { descontoAsked = 20; }
          //       else if (this.state.corridaSelected.indexOf("96") > -1) { descontoAsked = 30; }
          //       else if (this.state.corridaSelected.indexOf("97") > -1) { descontoAsked = 40; }
          //       else if (this.state.corridaSelected.indexOf("98") > -1) { descontoAsked = 50; }
          //       else if (this.state.corridaSelected.indexOf("99") > -1) { descontoAsked = 60; }
          //       else if (this.state.corridaSelected.indexOf("100") > -1) { descontoAsked = 70; }
          //       else if (this.state.corridaSelected.indexOf("101") > -1) { descontoAsked = 80; }
          //       else if (this.state.corridaSelected.indexOf("102") > -1) { descontoAsked = 90; }

          //       if (this.state.dataAgend[0] !== null) {
          //         for (let i = 0; i < this.state.dataAgend.length; i++) {
          //           Observacao = '';
          //           if (this.state.tabelada) {
          //             let vlt = this.state.valorTabelada;
          //             Observacao += ' / Tabelada em R$ ' + vlt.toFixed(2);
          //           }
          //           let date = moment(this.state.dataAgend[i]).format("YYYY-MM-DD");
          //           let hour = this.state.horaAgend[i];

          //           dataCompleta = moment(date + 'T' + hour + ':00').utc().format("YYYY-MM-DD HH:mm");
          //           let dataCompletaFormatada = moment(date + 'T' + hour + ':00').format("DD/MM/YYYY HH:mm");
          //           Observacao += ' / Agendado para ' + dataCompletaFormatada;

          //           this.webserv ice.orderTaxi(
          //             {
          //               desconto: descontoAsked,
          //               voucherAvulso: this.state.voucherAvulso,
          //               codigo_empresa: this.state.codigoEmpresa,
          //               matricula: this.state.matricula,
          //               departamento: this.state.departamento,
          //               telefone: this.state.telefone,
          //               nome: this.state.cliente,
          //               endereco: this.state.local.shortAddress,
          //               numero: this.state.numberLocal,
          //               complemento: this.state.complemento,
          //               cidade: this.state.local.cidade,
          //               estado: this.state.local.estado,
          //               bairro: this.state.local.bairro,
          //               latitude: this.state.markerLocal[0].lat,
          //               longitude: this.state.markerLocal[0].lng,
          //               enderecoDestino: this.state.destiny.shortAddress,
          //               numeroDestino: this.state.numberDest,
          //               complementoDest: this.state.complementoDest,
          //               cidadeDestino: this.state.destiny.cidade,
          //               estadoDestino: this.state.destiny.estado,
          //               bairroDestino: this.state.destiny.bairro,
          //               latitudeDestino: this.state.markerDest[0].lat === '' ? null : this.state.markerDest[0].lat,
          //               longitudeDestino: this.state.markerDest[0].lng === '' ? null : this.state.markerDest[0].lng,
          //               tipo_pagto: this.state.pagtoSelected,
          //               tipo_carro: this.state.carroSelected,
          //               tipo_cliente: this.state.clienteSelected,
          //               tipo_corrida: this.state.corridaSelected,
          //               telefone_contato: this.state.telefoneContato === '' ? this.state.telefone : this.state.telefoneContato,
          //               data_hora_agendamento: dataCompleta,
          //               observacoes: Observacao,
          //               coordenadas: this.state.coordenadas,
          //               numero_carros: Number(this.state.numCarros),
          //               atendente: this.props.childProps.userAttrib.nome,
          //               antecipar: Number(this.state.antecipar),
          //               cadastrarCliente: !this.state.gotClient,
          //               valorTabelada: this.state.gotEmpresa || this.state.clickedValor ? this.state.valorAproxEmpresa : this.state.tabelada ? this.state.valorTabelada : null,
          //             }).then(async result => {
          //               if (result.result) {
          //                 this.clearFields();
          //                 this.setState({
          //                   situation: 'Atendimento enviado com sucesso!',
          //                   situationStyle: { color: 'green' }
          //                 })
          //                 this.clearSituation();
          //               } else {
          //                 if (result.err) {
          //                   if (typeof (result.err) === "string") {
          //                     this.setState({
          //                       situation: result.err,
          //                       situationStyle: { color: 'red' }
          //                     })
          //                   }
          //                   console.log(result.err);
          //                   this.clearSituation();
          //                 } else {
          //                   result = await result.json();
          //                   if (typeof (result.err) === "string") {
          //                     this.setState({
          //                       situation: result.err,
          //                       situationStyle: { color: 'red' }
          //                     })
          //                   }
          //                   console.log(result.err);
          //                   this.clearSituation();
          //                 }
          //               }
          //             }).catch(err => {
          //               if (err) {
          //                 this.setState({
          //                   situation: 'Ocorreu um erro ao enviar o atendimento! Verifique se está conectado na internet e tente novamente!',
          //                   situationStyle: { color: 'red' }
          //                 })
          //               }
          //             })
          //         }
          //       } else {
          //         this.webserv ice.orderTaxi(
          //           {
          //             desconto: descontoAsked,
          //             voucherAvulso: this.state.voucherAvulso,
          //             codigo_empresa: this.state.codigoEmpresa,
          //             matricula: this.state.matricula,
          //             departamento: this.state.departamento,
          //             telefone: this.state.telefone,
          //             nome: this.state.cliente,
          //             endereco: this.state.local.shortAddress,
          //             numero: this.state.numberLocal,
          //             complemento: this.state.complemento,
          //             cidade: this.state.local.cidade,
          //             estado: this.state.local.estado,
          //             bairro: this.state.local.bairro,
          //             latitude: this.state.markerLocal[0].lat,
          //             longitude: this.state.markerLocal[0].lng,
          //             enderecoDestino: this.state.destiny.shortAddress,
          //             numeroDestino: this.state.numberDest,
          //             complementoDest: this.state.complementoDest,
          //             cidadeDestino: this.state.destiny.cidade,
          //             estadoDestino: this.state.destiny.estado,
          //             bairroDestino: this.state.destiny.bairro,
          //             latitudeDestino: this.state.markerDest[0].lat === '' ? null : this.state.markerDest[0].lat,
          //             longitudeDestino: this.state.markerDest[0].lng === '' ? null : this.state.markerDest[0].lng,
          //             tipo_pagto: this.state.pagtoSelected,
          //             tipo_carro: this.state.carroSelected,
          //             tipo_cliente: this.state.clienteSelected,
          //             tipo_corrida: this.state.corridaSelected,
          //             telefone_contato: this.state.telefoneContato === '' ? this.state.telefone : this.state.telefoneContato,
          //             data_hora_agendamento: dataCompleta,
          //             observacoes: Observacao,
          //             coordenadas: this.state.coordenadas,
          //             numero_carros: Number(this.state.numCarros),
          //             atendente: this.props.childProps.userAttrib.nome,
          //             antecipar: Number(this.state.antecipar),
          //             cadastrarCliente: !this.state.gotClient,
          //             valorTabelada: this.state.gotEmpresa || this.state.clickedValor ? this.state.valorAproxEmpresa : this.state.tabelada ? this.state.valorTabelada : null,
          //           }).then(async result => {
          //             if (result.result) {
          //               this.clearFields();
          //               this.setState({
          //                 situation: 'Atendimento enviado com sucesso!',
          //                 situationStyle: {
          //                   color: 'green',
          //                 }
          //               })
          //               this.clearSituation();
          //             } else {
          //               if (result.err) {
          //                 if (typeof (result.err) === "string") {
          //                   this.setState({
          //                     situation: result.err,
          //                     situationStyle: { color: 'red' }
          //                   })
          //                 }
          //                 console.log(result.err);
          //                 enviando = false;
          //                 this.clearSituation();
          //               } else {
          //                 result = await result.json();
          //                 if (typeof (result.err) === "string") {
          //                   this.setState({
          //                     situation: result.err,
          //                     situationStyle: { color: 'red' }
          //                   })
          //                 }
          //                 console.log(result.err);
          //                 enviando = false;
          //                 this.clearSituation();
          //               }
          //             }
          //           }).catch(err => {
          //             if (err) {
          //               this.setState({
          //                 situation: 'Ocorreu um erro ao enviar o atendimento! Verifique se está conectado na internet e tente novamente!',
          //                 situationStyle: { color: 'red' }
          //               })
          //               enviando = false;
          //               return;
          //             }
          //           })
          //       }

          //     }),
          //   ],
          //   bsSize: 'small',
          //   onHide: (dialog) => {
          //     this.setState({ canESC: true })
          //     dialog.hide()
          //   }
          // })
        } else {

          var dataCompleta = null;
          let Observacao = this.state.observacoes;

          if (this.state.tabelada) {
            let vlt = this.state.valorTabelada;
            Observacao += ' / Tabelada em R$ ' + vlt.toFixed(2);
          }
          let descontoAsked = null;
          if (this.state.corridaSelected.indexOf("95") > -1 || this.state.corridaSelected.indexOf("100") > -1) { descontoAsked = 20; }
          else if (this.state.corridaSelected.indexOf("96") > -1) { descontoAsked = 30; }
          else if (this.state.corridaSelected.indexOf("97") > -1) { descontoAsked = 40; }
          else if (this.state.corridaSelected.indexOf("98") > -1) { descontoAsked = 50; }
          else if (this.state.corridaSelected.indexOf("99") > -1) { descontoAsked = 60; }
          else if (this.state.corridaSelected.indexOf("100") > -1) { descontoAsked = 70; }
          else if (this.state.corridaSelected.indexOf("101") > -1) { descontoAsked = 80; }
          else if (this.state.corridaSelected.indexOf("102") > -1) { descontoAsked = 90; }

          if (this.state.dataAgend[0] !== null) {
            for (let i = 0; i < this.state.dataAgend.length; i++) {
              Observacao = '';
              if (this.state.tabelada) {
                let vlt = this.state.valorTabelada;
                Observacao += ' / Tabelada em R$ ' + vlt.toFixed(2);
              }
              let date = moment(this.state.dataAgend[i]).format("YYYY-MM-DD");
              let hour = this.state.horaAgend[i];

              dataCompleta = moment(date + 'T' + hour + ':00').utc().format("YYYY-MM-DD HH:mm");
              let dataCompletaFormatada = moment(date + 'T' + hour + ':00').format("DD/MM/YYYY HH:mm");
              Observacao += ' / Agendado para ' + dataCompletaFormatada;
              let codigos = [];

              codigos.push(this.state.c_prices_escolhido)

              this.webservice.orderTaxi(
                {
                  destinos: this.state.destinos,
                  codigos: codigos.join(' ').toUpperCase().trim(),
                  moip: this.state.moip,
                  desconto: descontoAsked,
                  voucherAvulso: this.state.voucherAvulso,
                  codigo_empresa: this.state.codigoEmpresa,
                  matricula: this.state.matricula,
                  departamento: this.state.departamento,
                  telefone: this.state.telefone,
                  nome: this.state.cliente,
                  endereco: this.state.local.fullAddress,
                  numero: this.state.numberLocal,
                  complemento: this.state.complemento,
                  cidade: this.state.local.cidade,
                  estado: this.state.local.estado,
                  bairro: this.state.local.bairro,
                  latitude: this.state.markerLocal[0].lat,
                  longitude: this.state.markerLocal[0].lng,
                  enderecoDestino: this.state.destiny.fullAddress,
                  numeroDestino: this.state.numberDest,
                  complementoDest: this.state.complementoDest,
                  cidadeDestino: this.state.destiny.cidade,
                  estadoDestino: this.state.destiny.estado,
                  bairroDestino: this.state.destiny.bairro,
                  latitudeDestino: this.state.markerDest[0].lat === '' ? null : this.state.markerDest[0].lat,
                  longitudeDestino: this.state.markerDest[0].lng === '' ? null : this.state.markerDest[0].lng,
                  tipo_pagto: this.state.pgto,
                  telefone_contato: this.state.telefoneContato === '' ? this.state.telefone : this.state.telefoneContato,
                  data_hora_agendamento: dataCompleta,
                  observacoes: Observacao,
                  coordenadas: this.state.coordenadas,
                  numero_carros: Number(this.state.numCarros),
                  atendente: this.props.childProps.userAttrib.nome,
                  antecipar: Number(this.state.antecipar),
                  cadastrarCliente: !this.state.gotClient,
                  tabelavalores: this.state.c_prices_tabela,
                  valorTabelada: this.state.c_prices_tabela ? this.state.c_prices_tabela.valor_final : 0,
                }).then(async result => {
                  if (result.result) {
                    this.clearFields();
                    this.setState({
                      situation: 'Atendimento enviado com sucesso!',
                      situationStyle: { color: 'green' }
                    })
                    this.clearSituation();
                  } else {
                    if (result.err) {
                      if (typeof (result.err) === "string") {
                        this.setState({
                          situation: result.err,
                          situationStyle: { color: 'red' }
                        })
                      }
                      console.log(result.err);
                      this.clearSituation();
                    } else {
                      result = await result.json();
                      if (typeof (result.err) === "string") {
                        this.setState({
                          situation: result.err,
                          situationStyle: { color: 'red' }
                        })
                      }
                      console.log(result.err);
                      this.clearSituation();
                    }
                  }
                }).catch(err => {
                  if (err) {
                    this.setState({
                      situation: 'Ocorreu um erro ao enviar o atendimento! Verifique se está conectado na internet e tente novamente!',
                      situationStyle: { color: 'red' }
                    })
                  }
                })
            }
          } else {
            let codigos = [];

            codigos.push(this.state.c_prices_escolhido)
            this.webservice.orderTaxi(
              {
                destinos: this.state.destinos,
                codigos: codigos.join(' ').toUpperCase().trim(),
                moip: this.state.moip,
                desconto: descontoAsked,
                voucherAvulso: this.state.voucherAvulso,
                codigo_empresa: this.state.codigoEmpresa,
                matricula: this.state.matricula,
                departamento: this.state.departamento,
                telefone: this.state.telefone,
                nome: this.state.cliente,
                endereco: this.state.local.fullAddress,
                numero: this.state.numberLocal,
                complemento: this.state.complemento,
                cidade: this.state.local.cidade,
                estado: this.state.local.estado,
                bairro: this.state.local.bairro,
                latitude: this.state.markerLocal[0].lat,
                longitude: this.state.markerLocal[0].lng,
                enderecoDestino: this.state.destiny.fullAddress,
                numeroDestino: this.state.numberDest,
                complementoDest: this.state.complementoDest,
                cidadeDestino: this.state.destiny.cidade,
                estadoDestino: this.state.destiny.estado,
                bairroDestino: this.state.destiny.bairro,
                latitudeDestino: this.state.markerDest[0].lat === '' ? null : this.state.markerDest[0].lat,
                longitudeDestino: this.state.markerDest[0].lng === '' ? null : this.state.markerDest[0].lng,
                tipo_pagto: this.state.pgto,
                telefone_contato: this.state.telefoneContato === '' ? this.state.telefone : this.state.telefoneContato,
                data_hora_agendamento: dataCompleta,
                observacoes: Observacao,
                coordenadas: this.state.coordenadas,
                numero_carros: Number(this.state.numCarros),
                atendente: this.props.childProps.userAttrib.nome,
                antecipar: Number(this.state.antecipar),
                cadastrarCliente: !this.state.gotClient,
                tabelavalores: this.state.c_prices_tabela,
                valorTabelada: this.state.c_prices_tabela ? this.state.c_prices_tabela.valor_final : 0,
              }).then(async result => {
                if (result.result) {
                  this.clearFields();
                  this.setState({
                    situation: 'Atendimento enviado com sucesso!',
                    situationStyle: {
                      color: 'green',
                    }
                  })
                  this.clearSituation();
                } else {
                  if (result.err) {
                    if (typeof (result.err) === "string") {
                      this.setState({
                        situation: result.err,
                        situationStyle: { color: 'red' }
                      })
                    }
                    console.log(result.err);
                    enviando = false;
                    this.clearSituation();
                  } else {
                    result = await result.json();
                    if (typeof (result.err) === "string") {
                      this.setState({
                        situation: result.err,
                        situationStyle: { color: 'red' }
                      })
                    }
                    console.log(result.err);
                    enviando = false;
                    this.clearSituation();
                  }
                }
              }).catch(err => {
                if (err) {
                  this.setState({
                    situation: 'Ocorreu um erro ao enviar o atendimento! Verifique se está conectado na internet e tente novamente!',
                    situationStyle: { color: 'red' }
                  })
                  enviando = false;
                  return;
                }
              })
          }
        }
      }
      else {
        enviando = false;
      }
    }
  }
  gosetTipoPagamento(tipo,prices,pgto) {
    this.setState({
      c_prices_escolhido: tipo,
      c_prices_tabela: prices,
      c_pagamento: pgto
    })
    console.log('c_prices_tabela',prices)
  }
  goupdatePagamento(pgto) {
    this.setState({
      c_pagamento: pgto
    })
  }
  async getPrices() {
    if (!this.state.local) {return;}
    if (!this.state.local.latlon) {return;}
    if (!this.state.destiny) {return;}
    if (!this.state.destiny.latlon) {return;}
    let valores = await this.webservice.calcula(this.state.local,this.state.destiny,this.state.destinos)

    let points = valores.waypoints.waypoints.routes[0].geometry.coordinates;
    let prices = valores.prices;
    //console.log('c_points',points)
    this.setState({
      c_prices: prices,
      c_points: points
    })

    console.log('valores',valores)
  }
  getMotoristas() {
    if (this.state.markerLocal[0].lat !== "" && this.state.motoMarker[0].lat === '') {
      this.webservice.driversAround(this.state.markerLocal[0].lat, this.state.markerLocal[0].lng).then(result => {
        this.setState({ motoristas: result.result })
      })
    }
  }

  descClick() {
    this.setState({ descClicked: !this.state.descClicked })
  }

  pedirDescDin() {
    if (!this.state.descPedidoDin) {
      this.setState({ descPedidoDin: true, descPedidoCar: false });
      let index = this.state.corridaSelected.indexOf("100");
      if (index > -1) {
        this.state.corridaSelected.splice(index, 1);
      }
      this.state.corridaSelected.push("95");
    } else {
      this.setState({ descPedidoDin: false });
      let index = this.state.corridaSelected.indexOf("95");
      if (index > -1) {
        this.state.corridaSelected.splice(index, 1);
      }
    }
  }

  pedirDescCartao() {
    if (!this.state.descPedidoCar) {
      this.setState({ descPedidoCar: true, descPedidoDin: false });
      let index = this.state.corridaSelected.indexOf("95");
      if (index > -1) {
        this.state.corridaSelected.splice(index, 1);
      }
      this.state.corridaSelected.push("100");
      this.state.corridaSelected.push("95");
    } else {
      this.setState({ descPedidoCar: false });
      let index = this.state.corridaSelected.indexOf("100");
      if (index > -1) {
        this.state.corridaSelected.splice(index, 1);
      }
    }
  }

  pedirTab() {
    this.setState({ tabelada: !this.state.tabelada })
    if (!this.state.tabelada) {
      this.state.corridaSelected.push("88");
    }
    else {
      var index = this.state.corridaSelected.indexOf("88");
      if (index > -1) {
        this.state.corridaSelected.splice(index, 1);
      }
    }
  }
  changeAddressDestinosextra(newAddress,addressType,j) {
    //let o = obs ? obs + ', ' : '';
    //parei aqui

    let mydest = this.state.destinos;
    
    for (let a in mydest) {
      if (Number(a) === Number(j)) {
        mydest[a].Address_FullAddress = newAddress;
      }
    }


    this.setState({
      destinos: mydest
    })
  }
  changeAddressDestinos(newAddress, newCity, estado, bairro, addressType, number, obs, latlon, result, j) {
    //let o = obs ? obs + ', ' : '';
    //parei aqui
    console.log([newAddress, newCity, estado, bairro, addressType, number, obs, latlon, result, j])
    let mydest = this.state.destinos;
    
    for (let a in mydest) {
      if (Number(a) === Number(j)) {
        mydest[a].Address_FullAddress = newAddress;
        mydest[a].Address.shortAddress = result.rua;
        mydest[a].Address.fullAddress = newAddress;
        mydest[a].cidade = newCity;
        mydest[a].estado = estado;
        mydest[a].bairro = bairro;
        mydest[a].latlon = latlon;
        mydest[a].numeroDestMask = '99999';
        mydest[a].numero = number;
        mydest[a].complemento = '';
      }
    }


    this.setState({
      destinos: mydest
    })
    this.getPrices();
    this.organizaMarquerDestinos();
  }

  //Get the options from multiselect fields, put to an array and later save on DB(type of cars, payment, clients and runs)
  getSelectedOptionsValue(selectedOptions) {
    var values = [];

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value)
    }

    return values;
  }

  changeShowSearchModal(state) {
    this.setState({ showHistorico: state, canESC: false });
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentWillMount() {
    if (this.props.location.state && this.props.location.state.corrida) {
      this.setState({ corrida: this.props.location.state.corrida }, () => this.changeCorridaDados(this.state.corrida));
      this.props.history.replace({
        pathname: '/solicitacoes',
        state: {}
      });
    }
  }

  componentDidMount() {
    this.timerId = setInterval(this.getMotoristas, 20000);
    //this.getMotoristas();
    this.webservice.getCancel().then(result => {
      this.setState({ tipo_cancel: result });
    })

    let ramal = this.webservice.getCookie('ramal');
    if (!ramal) {
      this.setState({ showRamal: true, canESC: false });
    } else {
      this.setState({ showRamal: false, canESC: true });
      this.timerId = setInterval(this.setRamal, 2000);
      this.setRamal();
    }
    let central = this.webservice.getCookie('central-id');
    if (central) {
      //Get options from DB to multiselect fields(type of payment, cars, runs and clients)
      this.webservice.getOptions('pagto').then(result => this.setState({ pagtoOptions: result }));
      this.webservice.getOptions('carro').then(result => this.setState({ carroOptions: result }));
      this.webservice.getOptions('corrida').then(result => this.setState({ corridaOptions: result }));
      this.webservice.getOptions('cliente').then(result => this.setState({ clienteOptions: result }));
    }

    this.webservice.getCentral(central).then(result => {
      this.setState({ centralAtual: result });
    })
  }

  onBlurNomeEmpresa = (event, { highlightedSuggestion }) => {
    if (highlightedSuggestion) {
      this.setState({ nomeEmpresa: highlightedSuggestion.razaosocial })
    }
  };

  onChangeCPF = (event, { newValue }) => {
    this.setState({ pagtoSelected: [], cpf: newValue.replace(/\D/g, '') })
  };

  onBlurCPF = (event, { highlightedSuggestion }) => {
    if (highlightedSuggestion) {
      this.setState({ cpf: highlightedSuggestion.cpf })
    }
  };

  onChangeNomeEmpresa = (event, { newValue }) => {
    this.setState({ nomeEmpresa: newValue })
  };

  onBlurPassageiro = (event, { highlightedSuggestion }) => {
    if (highlightedSuggestion) {
      this.setState({ passageiro: highlightedSuggestion.voucher_usuarios_nome })
    }
  };

  onChangePassageiro = (event, { newValue }) => {
    this.setState({ passageiro: newValue })
  };

  render() {
    //Change the current language to portuguese BR
    require('moment/locale/pt-br.js')
    moment.locale('pt-br')
    console.log(this.state.destinos)
    const hidden = this.state.descClicked ? '' : 'hidden';
    const hiddenPrices = this.state.pricesClicked ? '' : 'hidden';
    const hiddenAtt = this.state.changed ? '' : 'hidden';
    const hiddenTabelada = this.state.tabelada ? '' : 'hidden';

    return (

      <div ref='content_div' onKeyDown={this.manageShortcut} tabIndex="0">
        <BlockUi tag="div" blocking={this.state.blocking}>
          < form >
            <Grid>
              <div>
                <p className="situation" style={this.state.situationStyle}>
                  {this.state.situation}
                  <Button className={hiddenAtt} onClick={e => {
                    this.atualizarSalvos();
                    this.webservice.atualizarCadastro(nomeSalvo, enderecoSalvo, numeroSalvo, complementoSalvo, this.state.local.estado, this.state.local.bairro, this.state.local.cidade, this.state.markerLocal[0].lat, this.state.markerLocal[0].lng, this.state.telefone).then(result => {
                    });
                    this.setState({
                      changed: false,
                      situation: 'Cadastro atualizado com sucesso!',
                      situationStyle: { color: 'green' }
                    })
                    this.clearSituation();
                  }}>Atualizar</Button>
                </p>
              </div>
              <Col id="dados" xs={12} md={7}>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup validationState={this.state.telefoneValidation}>
                      <ControlLabel>Telefone*</ControlLabel>
                      <InputMask
                        ref="telefone"
                        value={this.state.telefone}
                        autoFocus
                        className="form-control"
                        mask={this.state.telefoneMask}
                        maskChar=""
                        disabled={this.state.disableAll}
                        onBlur={e => this.getClientByNumber(e.target.value)}
                        onChange={e => {
                          enviando = false;
                          this.setState({ telefone: e.target.value, telefonesalvo: null, corridasAntigas: [] })
                          if (/^.....9/.test(e.target.value)) {
                            this.setState({ telefoneMask: '(99) 99999-9999', telefoneContato: e.target.value })
                          } else {
                            this.setState({ telefoneMask: '(99) 9999-9999' })
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={8}>
                    <FormGroup validationState={this.state.clienteValidation}>
                      <ControlLabel>Cliente*</ControlLabel>
                      <FormControl
                        ref="cliente"
                        type="text"
                        placeholder=""
                        value={this.state.cliente}
                        disabled={this.state.disableAll}
                        onChange={e => { this.setState({ cliente: e.target.value }) }}
                        onBlur={e => {
                          if (e.target.value.length < 3) {
                            this.setState({ clienteValidation: "error" });
                            return;
                          }
                          if ((/^.....3/.test(this.state.telefone) || /^.....4/.test(this.state.telefone)) && this.state.gotClient && !this.state.gotEmpresa && !this.state.changed) {
                            if (e.target.value !== nomeSalvo) {
                              this.setState({
                                changed: true,
                                situation: 'O nome do cliente foi alterado! Atualizar cadastro do cliente?',
                                situationStyle: {
                                  color: 'red',
                                },
                              })
                            }
                          }
                          this.setState({ clienteValidation: null });
                          this.setState({ cliente: e.target.value })
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={this.state.gotFuncionario ? 6 : 12}>
                    <FormGroup className="autoComp">
                      <ControlLabel>CPF ou Voucher Avulso</ControlLabel>
                      <InputMask
                        ref='autocomplete_cpf'
                        className="form-control"
                        mask={this.state.cpfMask}
                        maskChar={this.state.cpfChar}
                        value={this.state.cpf.length > 0 ? this.state.cpf : this.state.numeroVoucher}
                        disabled={this.state.disableAll}
                        onChange={e => {
                          enviando = false;
                          if (this.state.gotFuncionario) {
                            this.setState({ canESC: false });
                            Dialog.setOptions({
                              defaultOkLabel: 'Sim',
                              defaultCancelLabel: 'Não',
                              primaryClassName: 'btn-danger '
                            })
                            this.dialog.show({
                              body: 'Deseja fazer outra busca??',
                              actions: [
                                Dialog.CancelAction(() => { this.setState({ canESC: true }) }),
                                Dialog.OKAction(() => {
                                  setTimeout(function () { this.clearFields(); }.bind(this), 400);
                                  setTimeout(function () { ReactDom.findDOMNode(this.refs.autocomplete_cpf).focus(); }.bind(this), 635);
                                }),
                              ],
                              bsSize: 'small',
                              onHide: (dialog) => { this.setState({ canESC: true }); dialog.hide() }
                            })
                          }

                          if (e.target.value === this.state.numeroVoucher || e.target.value === this.state.cpf) {
                            this.setState({ cpfMask: "", cpfChar: "", numeroVoucher: "", cpf: "" });
                            return;
                          }

                          if (e.target.value[0] && e.target.value[0].match("[0-9]") && this.state.numeroVoucher.length <= 0) {
                            this.setState({ cpfMask: "999.999.999-99", cpfChar: "", cpf: e.target.value.replace(/\D/g, '') });
                            return;
                          } else if (e.target.value[0] && this.state.numeroVoucher.length === 0) {
                            this.setState({ cpfMask: "VA - 999999999999999", cpfChar: "", numeroVoucher: "VA - " });
                            return;
                          }

                          if (this.state.numeroVoucher.length > 0) { this.setState({ numeroVoucher: e.target.value }); }
                        }}
                        onBlur={e => {
                          if (!this.state.gotFuncionario && this.state.numeroVoucher.length > 0) {
                            let voucherNumber = this.state.numeroVoucher.replace(/\D/g, '');
                            if (voucherNumber > 0) {
                              this.setState({ blocking: true });
                              this.webservice.getVoucherByID(voucherNumber).then(voucher => {
                                if (voucher.length <= 0) {
                                  this.setState({
                                    blocking: false,
                                    situation: 'Não foi encontrado esse Voucher Avulso!',
                                    situationStyle: { color: 'red', },
                                  });
                                  this.clearSituation();
                                }
                                if (voucher.length > 0) {
                                  this.setState({ blocking: true });
                                  this.webservice.getEmpresas(voucher[0].empresa).then(result => {
                                    if (result.length <= 0) {
                                      this.setState({
                                        blocking: false,
                                        situation: 'Não foi encontrado empresas nesse voucher avulso!',
                                        situationStyle: { color: 'red' },
                                      });
                                      this.clearSituation();
                                      return;
                                    }
                                    result = {
                                      ...result,
                                      text: result.razaosocial + " - Voucher Avulso",
                                      idvoucher: voucher[0].id,
                                      idemp: result.id,
                                      id: parseInt(result.id + '999', 10),
                                      nomecliente: voucher[0].nome,
                                    }
                                    this.setState({
                                      empresasOptions: [...this.state.empresasOptions, result],
                                      gotFuncionario: true
                                    }, () => {
                                      if (this.state.empresasOptions.length === 1) {
                                        this.setState({ blocking: true });
                                        this.setState({ empresaSelected: [this.state.empresasOptions[0].id] });
                                        let empresa = this.state.empresasOptions[0];
                                        console.log(empresa)
                                        let dayOfTheWeek = moment().format("d");
                                        let hourOfTheDay = moment().hour();
                                        let bandeira = 0;
                                        if ((dayOfTheWeek >= 1 && dayOfTheWeek <= 5 && hourOfTheDay >= 6 && hourOfTheDay < 20)
                                          || (dayOfTheWeek === 6 && hourOfTheDay >= 6 && hourOfTheDay < 13)) {
                                          bandeira = parseInt(empresa.km_b1, 10);
                                        } else {
                                          bandeira = parseInt(empresa.km_b2, 10);
                                        }

                                        this.setState({
                                          telefone: empresa.telefone,
                                          valorCorridaEmpresa: bandeira,
                                          valorMinimoEmpresa: parseInt(empresa.valor_minimo, 10),
                                          valorTaxaMunicipio: parseInt(empresa.taxa_retorno, 10),
                                          local_fullAddress: empresa.endereco,
                                          local: {
                                            fullAddress: empresa.endereco,
                                            cidade: empresa.cidade || "Curitiba",
                                            shortAddress: empresa.endereco.split(', ')[0],
                                            numero: empresa.endereco.split(', ')[1]
                                          },
                                          numberLocal: empresa.endereco.split(', ')[1],
                                        })

                                        this.setState({
                                          blocking: false,
                                          cliente: empresa.nomecliente,
                                          gotEmpresa: true,
                                          gotClient: true,
                                          matricula: null,
                                          voucherAvulso: parseInt(empresa.idvoucher, 10),
                                          codigoEmpresa: parseInt(empresa.idemp, 10),
                                        })
                                        //this.newCalculaPrice();
                                      }
                                    })
                                  })
                                } else {
                                  this.setState({ blocking: false });
                                }
                              })
                              return;
                            }
                          }
                          if (!this.state.gotFuncionario && this.state.cpf.length === 11) {
                            this.setState({ blocking: true });
                            this.webservice.getSaldo(this.state.cpf).then(result => {
                              console.log(result);
                              if (result.length <= 0) {
                                this.setState({
                                  blocking: false,
                                  situation: 'Não foi encontrado esse CPF!',
                                  situationStyle: { color: 'red', },
                                });
                                this.clearSituation();
                              } else {
                                let empresas = [];
                                result.map(r => {
                                  let empresa = {
                                    desconto: r.desconto,
                                    text: r.depto ? r.razaosocial + ' - ' + r.depto : r.razaosocial,
                                    id: r.empresa + '_' + r.d_id,
                                    km_b1: r.km_b1,
                                    km_b2: r.km_b2,
                                    telefone: r.telefone,
                                    valor_minimo: r.valor_minimo,
                                    taxa_retorno: r.taxa_retorno,
                                    cidade: r.cidade,
                                    bairro: r.bairro,
                                    endereco: r.endereco,
                                    latitude: r.latitude,
                                    longitude: r.longitude,
                                    cliente: r.nome,
                                    func: r.f_id,
                                    dep_id: r.d_id,
                                    comissao: r.comissao,
                                  }
                                  empresas.push(empresa);
                                  return null;
                                })

                                console.log(empresas);

                                this.setState({
                                  empresasOptions: empresas,
                                  gotFuncionario: true,
                                }, () => {
                                  if (this.state.empresasOptions.length === 1) {
                                    this.setState({ blocking: true });
                                    this.setState({ empresaSelected: [this.state.empresasOptions[0].id] });
                                    let empresa = this.state.empresasOptions[0];
                                    let dayOfTheWeek = moment().format("d");
                                    let hourOfTheDay = moment().hour();
                                    let bandeira = 0;
                                    if ((dayOfTheWeek >= 1 && dayOfTheWeek <= 5 && hourOfTheDay >= 6 && hourOfTheDay < 20)
                                      || (dayOfTheWeek === 6 && hourOfTheDay >= 6 && hourOfTheDay < 13)) {
                                      bandeira = parseInt(empresa.km_b1, 10);
                                    } else {
                                      bandeira = parseInt(empresa.km_b2, 10);
                                    }

                                    this.setState({
                                      cliente: empresa.cliente,
                                      telefone: empresa.telefone,
                                      valorCorridaEmpresa: bandeira,
                                      valorMinimoEmpresa: parseInt(empresa.valor_minimo, 10),
                                      valorTaxaMunicipio: parseInt(empresa.taxa_retorno.taxaRetornoFixo, 10),
                                      local: {
                                        fullAddress: empresa.endereco,
                                        cidade: empresa.cidade,
                                        bairro: empresa.bairro,
                                        shortAddress: empresa.endereco.split(', ')[0],
                                        numero:empresa.endereco.split(', ')[1],
                                      },
                                      localSelected: true,
                                      markerLocal: [{ lat: empresa.latitude, lng: empresa.longitude, img_src: red_marker, label: empresa.endereco }],
                                      numberLocal: empresa.endereco.split(', ')[1],
                                      blocking: false,
                                      gotEmpresa: true,
                                      gotClient: true,
                                      matricula: empresa.func,
                                      voucherAvulso: null,
                                      codigoEmpresa: parseInt(empresa.id, 10),
                                      descontoEmpresa: empresa.desconto,
                                      departamento: empresa.dep_id,
                                      comissao: empresa.comisao,
                                    });
                                    //this.newCalculaPrice();
                                  } else {
                                    this.setState({ blocking: false });
                                  }
                                });
                              }
                            })
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {this.state.gotFuncionario &&
                    <Col xs={12} md={6}>
                      <FormGroup className="autoComp">
                        <ControlLabel>Empresa</ControlLabel>
                        <Select2
                          id='empresas-id'
                          ref='empresas'
                          disabled={this.state.disableAll}
                          value={this.state.empresaSelected}
                          data={this.state.empresasOptions}
                          options={{
                            placeholder: '',
                          }}
                          onSelect={e => {
                            this.setState({ empresaSelected: this.getSelectedOptionsValue(e.target.selectedOptions) });
                            let empresa = e.params.data.element.attributes;
                            let dayOfTheWeek = moment().format("d");
                            let hourOfTheDay = moment().hour();
                            let bandeira = 0;
                            if ((dayOfTheWeek >= 1 && dayOfTheWeek <= 5 && hourOfTheDay >= 6 && hourOfTheDay < 20)
                              || (dayOfTheWeek === 6 && hourOfTheDay >= 6 && hourOfTheDay < 13)) {
                              bandeira = parseInt(empresa.km_b1.value, 10);
                            } else {
                              bandeira = parseInt(empresa.km_b2.value, 10);
                            }

                            this.setState({
                              telefone: empresa.telefone.value,
                              comissao: empresa.comissao.value,
                              valorCorridaEmpresa: bandeira,
                              valorMinimoEmpresa: parseInt(empresa.valor_minimo.value, 10),
                              valorTaxaMunicipio: parseInt(empresa.taxa_retorno.value, 10),
                              local: {
                                cidade: empresa.cidade ? empresa.cidade.value : "Curitiba",
                                shortAddress: empresa.endereco.value.split(', ')[0],
                                numero: empresa.endereco.value.split(', ')[1],
                              },
                              numberLocal: empresa.endereco.value.split(', ')[1],
                            })

                            if (!empresa.nomecliente) {
                              this.setState({
                                cliente: empresa.cliente.value,
                                gotEmpresa: true,
                                gotClient: true,
                                matricula: empresa.func.value,
                                voucherAvulso: null,
                                codigoEmpresa: parseInt(empresa.value.value.split('_')[0], 10),
                              })
                            } else {
                              this.setState({
                                cliente: empresa.nomecliente.value,
                                gotEmpresa: true,
                                gotClient: true,
                                matricula: null,
                                voucherAvulso: parseInt(empresa.idvoucher.value, 10),
                                codigoEmpresa: parseInt(empresa.idemp.value, 10),
                              })
                            }
                            //this.newCalculaPrice();
                          }}
                        />
                      </FormGroup>
                    </Col>
                  }
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <AutoComplete
                      disabled={this.state.disableAll}
                      address={this.state.address} changeAddress={this.changeAddress.bind(this)}
                      endereco={this.state.local_fullAddress} changeEndereco={this.changeEndereco.bind(this)}
                      changeEnderecoExtra={this.changeEnderecoExtra.bind(this)}
                      validation={this.state.enderecoValidation} changeValidation={this.changeValidation.bind(this)}
                      changeAutoCompleteRefs={this.changeAutoCompleteRefs.bind(this)}
                      onSuggestionSelected={this.onSuggestionSelected.bind(this)}
                      changeNumber={this.changeNumber.bind(this)}
                      title="Endereço*"
                      center={this.state.center}
                      addressType='local'
                      blur={this.validateAutoCompleteLocal.bind(this)}
                    />
                  </Col>

                  <Col xs={12} md={4}>
                    <FormGroup>
                      <ControlLabel>Complemento</ControlLabel>
                      <FormControl
                        ref="complemento"
                        type="text"
                        placeholder=""
                        value={this.state.complemento}
                        disabled={this.state.disableAll}
                        onChange={e => { this.setState({ complemento: e.target.value }) }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                  <Row>
                  <Col xs={12} md={2}></Col>
                  <Col xs={12} md={7}>
                    <FormGroup validationState={this.state.localNumberValidation}>
                      <ControlLabel>Rua</ControlLabel>
                      <InputMask
                        ref='rua_local'
                        className="form-control"
                        value={this.state.local.shortAddress ? this.state.local.shortAddress : '' }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>  
                  <Col xs={12} md={3}>
                    <FormGroup validationState={this.state.localNumberValidation}>
                      <ControlLabel>Número</ControlLabel>
                      <InputMask
                        ref='numero_local'
                        className="form-control"
                        value={this.state.local.numero ? this.state.local.numero : '' }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  </Row>


                <Row>
                  <Col xs={12} md={2}></Col>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <ControlLabel>Bairro</ControlLabel>
                      <FormControl
                        ref="bairro"
                        type="text"
                        placeholder=""
                        value={this.state.local.bairro ? this.state.local.bairro : ''}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <ControlLabel>Cidade</ControlLabel>
                      <FormControl
                        ref="cidade"
                        type="text"
                        placeholder=""
                        value={this.state.local.cidade ? this.state.local.cidade : ''}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={2}>
                    <FormGroup>
                      <ControlLabel>Estado</ControlLabel>
                      <FormControl
                        ref="estado"
                        type="text"
                        placeholder=""
                        value={this.state.local.estado ? this.state.local.estado : ''}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {this.state.destinos.map((d, i) => {
                  return (
                    <div key={i}>
                      <Row>
                        <Col xs={12} md={8}>
                          <AutoComplete
                            disabled={this.state.disableAll}
                            address={this.state.addressDest}
                            i={i}
                            changeAddress={this.changeAddress.bind(this)}
                            endereco={d.Address_FullAddress ? d.Address_FullAddress : ''}
                            onSuggestionSelected={this.onSuggestionSelected.bind(this)}
                            changeEnderecoExtra={this.changeAddressDestinosextra.bind(this)}
                            changeEndereco={this.changeAddressDestinos.bind(this)}
                            validation={null}
                            changeValidation={
                              (newValidation, addressType, state) => {
                                d.destinoValidation = newValidation;
                                d.destinySelected = state;
                              }
                            }
                            changeAutoCompleteRefs={() => { }}
                            title={"Endereço Destino " + (i + 1) + "*"}
                            addressType='destino'
                            center={this.state.center}
                            blur={this.validateAutoCompleteDestiny}
                          />
                        </Col>
                        
                        <Col xs={12} md={4} style={{ paddingRight: 50 }}>
                          <FormGroup>
                            <ControlLabel>Complemento</ControlLabel>
                            <FormControl
                              ref="complemento_dest"
                              type="text"
                              placeholder=""
                              value={d.complementoDest ? d.complementoDest : ''}
                              onChange={e => { d.complementoDest = e.target.value }}
                            />
                          </FormGroup>
                          <Image
                            src={require('../images/remove.png')}
                            height='25'
                            width='25'
                            style={{ cursor: 'pointer', zIndex: 100, position: 'absolute', bottom: 5, right: 15 }}
                            onClick={() => {
                              let destinos = this.state.destinos;
                              if (destinos.length === 1 && !this.state.destinySelected) {
                                let dest = destinos.splice(i, 1);
                                console.log(dest);
                                let markers = [];
                                this.state.destinos.map(d2 => {
                                  markers.push({
                                    lat: d2.latitude,
                                    lng: d2.longitude,
                                    img_src: blue_marker
                                  });
                                  return null;
                                });
                                markers.push({
                                  lat: this.state.markerDestTemp.lat,
                                  lng: this.state.markerDestTemp.lng,
                                  img_src: blue_marker
                                })
                                this.setState({
                                  destinos: destinos,
                                  destiny: {
                                    estado: dest[0].estado,
                                    bairro: dest[0].bairro,
                                    cidade: dest[0].cidade,
                                    shortAddress: dest[0].Address.shortAddress,
                                  },
                                  numberDest: dest[0].numberDest,
                                  numeroDestMask: dest[0].numeroDestMask,
                                  destinySelected: true,
                                  destinoValidation: true,
                                }, () => this.getCenter());
                              } else {
                                destinos.splice(i, 1);
                                let markers = [];
                                this.state.destinos.map(d2 => {
                                  markers.push({
                                    lat: d2.latitude,
                                    lng: d2.longitude,
                                    img_src: blue_marker
                                  });
                                  return null;
                                });
                                markers.push({
                                  lat: this.state.markerDestTemp.lat,
                                  lng: this.state.markerDestTemp.lng,
                                  img_src: blue_marker
                                })
                                this.setState({ destinos: destinos }, () => this.getCenter());
                              }
                              if (destinos.length <= 0) {
                                this.setState({ multiplosDestinos: false }, () => this.getCenter());
                              }
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                      <Col xs={12} md={2}></Col>

                      <Col xs={12} md={7}>
                          <FormGroup>
                            <ControlLabel>Rua</ControlLabel>
                            <InputMask
                              ref={'rua_destino_' + i}
                              value={d.Address.shortAddress ? d.Address.shortAddress : ''}
                              className="form-control"
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                      <Col xs={12} md={3}>
                          <FormGroup>
                            <ControlLabel>Número</ControlLabel>
                            <InputMask
                              ref={'numero_destino_' + i}
                              value={d.numero ? d.numero : ''}
                              className="form-control"
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>


                      </Row>
                      <Row>
                      <Col xs={12} md={2}></Col>
                        <Col xs={12} md={4}>
                          <FormGroup>
                            <ControlLabel>Bairro Destino</ControlLabel>
                            <FormControl
                              ref="bairro_dest"
                              type="text"
                              placeholder=""
                              value={d.bairro ? d.bairro : ''}
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup>
                            <ControlLabel>Cidade Destino</ControlLabel>
                            <FormControl
                              ref="cidade_dest"
                              type="text"
                              placeholder=""
                              value={d.cidade ? d.cidade : ''}
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup>
                            <ControlLabel>UF</ControlLabel>
                            <FormControl
                              ref="estado_dest"
                              type="text"
                              placeholder=""
                              value={d.estado ? d.estado : ''}
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  );
                })
                }
                <Row>
                  <Col xs={12} md={8}>
                    <AutoComplete
                      disabled={this.state.disableAll}
                      address={this.state.addressDest} changeAddress={this.changeAddress.bind(this)}
                      endereco={this.state.destiny_fullAddress} changeEndereco={this.changeEndereco.bind(this)}
                      changeEnderecoExtra={this.changeEnderecoExtra.bind(this)}
                      validation={null} changeValidation={this.changeValidation.bind(this)}
                      changeAutoCompleteRefs={this.changeAutoCompleteRefs.bind(this)}
                      onSuggestionSelected={this.onSuggestionSelected.bind(this)}
                      title={this.state.multiplosDestinos ? "Endereço Destino Final*" : "Endereço Destino*"}
                      addressType='destino'
                      center={this.state.center}
                      blur={this.validateAutoCompleteDestiny}
                    />
                  </Col>
                  <Col xs={12} md={4} style={{ paddingRight: 50 }}>
                    <FormGroup>
                      <ControlLabel>Complemento</ControlLabel>
                      <FormControl
                        ref="complemento_dest"
                        type="text"
                        placeholder=""
                        value={this.state.complementoDest ? this.state.complementoDest : ''}
                        onChange={e => { this.setState({ complementoDest: e.target.value }) }}
                      />
                    </FormGroup>
                    {this.state.destinos.length < 3 &&
                      <Image
                        src={require('../images/plus.png')}
                        height='25'
                        width='25'
                        style={{ cursor: 'pointer', zIndex: 100, position: 'absolute', bottom: 5, right: 15 }}
                        onClick={() => {
                          let destinos = this.state.destinos;
                          if (this.state.destinySelected) {
                            let dest2 = this.state.destiny;
                            destinos.push({
                              Address: {
                                shortAddress: dest2.shortAddress,
                                fullAddress: dest2.fullAddress,
                              },
                              Address_FullAddress: this.state.destiny_fullAddress,
                              cidade: dest2.cidade,
                              estado: dest2.estado,
                              bairro: dest2.bairro,
                              complemento: '',
                              numeroDestMask: this.state.numeroDestMask,
                              numero: this.state.numberDest,
                              latlon: dest2.latlon,
                              latitude: this.state.markerDest[0].lat,
                              longitude: this.state.markerDest[0].lng,
                              destinoValidation: true,
                              destinySelected: true,
                              order: this.state.destinos.length,
                            });
                            this.setState({
                              multiplosDestinos: true,
                              destinos: destinos,
                              destiny: {
                                estado: '',
                                bairro: '',
                                cidade: '',
                                shortAddress: '',
                              },
                              Address_FullAddress: '',
                              numberDest: '',
                              latlon: null,
                              numeroDestMask: '99999',
                              destinySelected: false,
                              destinoValidation: null,
                            }, () => ReactDom.findDOMNode(destinoRefs.auto_complete_destino.input).focus());
                          } else {
                            destinos.push({
                              Address: {
                                shortAddress: '',
                                fullAddress: '',
                              },
                              cidade: '',
                              estado: '',
                              bairro: '',
                              complemento: '',
                              numeroDestMask: '99999',
                              numberDest: '',
                              latitude: 0,
                              longitude: 0,
                              destinoValidation: false,
                              destinySelected: false,
                              order: this.state.destinos.length
                            });
                            this.setState({
                              multiplosDestinos: true,
                              destinos: destinos,
                            });
                          }
                        }}
                      />
                    }
                  </Col>
                </Row>
                <Row>
                <Col xs={12} md={2}></Col>
                <Col xs={12} md={7}>
                    <FormGroup>
                      <ControlLabel>Rua</ControlLabel>
                      <InputMask
                        ref='rua_destino'
                        value={this.state.destiny.shortAddress ? this.state.destiny.shortAddress : ''}
                        className="form-control"
                        disabled="false"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <ControlLabel>Número</ControlLabel>
                      <InputMask
                        ref='numero_destino'
                        value={this.state.numberDest ? this.state.numberDest : ''}
                        className="form-control"
                        disabled="false"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                <Col xs={12} md={2}></Col>

                  <Col xs={12} md={4}>
                    <FormGroup>
                      <ControlLabel>Bairro Destino</ControlLabel>
                      <FormControl
                        ref="bairro_dest"
                        type="text"
                        placeholder=""
                        value={this.state.destiny.bairro ? this.state.destiny.bairro : ''}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <ControlLabel>Cidade Destino</ControlLabel>
                      <FormControl
                        ref="cidade_dest"
                        type="text"
                        placeholder=""
                        value={this.state.destiny.cidade ? this.state.destiny.cidade : ''}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={2}>
                    <FormGroup>
                      <ControlLabel>UF</ControlLabel>
                      <FormControl
                        ref="estado_dest"
                        type="text"
                        placeholder=""
                        value={this.state.destiny.estado ? this.state.destiny.estado : ''}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {(this.state.gotEmpresa || this.state.clickedValor) &&
                  <Row>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <ControlLabel>Valor Aproximado</ControlLabel>
                        <CurrencyInput
                          ref="valor_corrida_empresa"
                          className="form-control"
                          value={this.state.valorAproxEmpresa}
                          disabled={true}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix="R$ "
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                }
                {false &&
                <Row onKeyUp={this.manageShortcut}>
                  <Col xs={12} md={3}>
                    <FormGroup validationState={this.state.tipoPagtoValidation}>
                      <ControlLabel>Tipo de Pagto*</ControlLabel>
                      <Select2
                        id='pagamento-id'
                        ref='pagto_type'
                        multiple
                        disabled={this.state.disableAll}
                        value={this.state.pagtoSelected}
                        data={this.state.vouchers ? this.state.voucherOption : this.state.pagtoOptions}
                        options={{
                          placeholder: '',
                        }}
                        onSelect={e => {
                          let tel = this.state.telefone.replace(/\D/g, '');
                          if (tel === '' || (/^..9/.test(tel) && tel.length < 11) || (!/^..9/.test(tel) && tel.length < 10)) {
                            Dialog.resetOptions();
                            this.dialog.showAlert('Por favor preencha o telefone antes de escolher o pagamento!');
                            this.setState({ pagtoSelected: [] })
                          } else {
                            this.setState({ pagtoSelected: this.getSelectedOptionsValue(e.target.selectedOptions) }, () => {
                              this.state.pagtoOptions.map((o, i) => {
                                this.state.pagtoSelected.map((s, i2) => {
                                  if (o.id + '' === s) {
                                    if (o.iugu) {
                                      this.setState({ showIugu: true, canESC: false });
                                    }
                                  }
                                  if (o.id + '' === s) {
                                    if (o.tipo_voucher !== null) {
                                      this.setState({ vouchers: true, voucherOption: [o] })
                                    } else {
                                      this.setState({ vouchers: false, voucherOption: [] })
                                    }
                                  }
                                  return null;
                                })
                                return null;
                              })
                            })
                            this.setState({ tipoPagtoValidation: null })
                          }
                        }}
                        onUnselect={e => {
                          this.setState({ pagtoSelected: this.getSelectedOptionsValue(e.target.selectedOptions) })
                          if (this.state.pagtoSelected.length < 1) {
                            this.setState({ tipoPagtoValidation: "error", vouchers: false, voucherOption: [] })
                          }
                        }}
                      />
                    </FormGroup>
                    {this.state.cartaoSalvo &&
                      <Image
                        src={require('../images/card.png')}
                        height='25'
                        width='30'
                        style={{ cursor: 'pointer', zIndex: 100, position: 'absolute', bottom: 23, right: -20 }}
                        onClick={() => { this.setState({ showIugu: true, canESC: false }) }}
                      />
                    }
                  </Col>
                  <Col xs={6} md={3} style={{ paddingRight: 0, paddingLeft: 30 }}>
                    <FormGroup>
                      <Checkbox style={{ marginTop: 15, marginBottom: 10 }} disabled={this.state.disableAll} checked={this.state.descPedidoDin} onChange={this.pedirDescDin}>
                        <span style={{ color: 'green' }}>Desc Dinheiro?</span>
                      </Checkbox>
                      <Checkbox disabled={this.state.disableAll} checked={this.state.descPedidoCar} onChange={this.pedirDescCartao}>
                        <span style={{ color: 'red' }}>Desc Cartão?</span>
                      </Checkbox>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup validationState={this.state.tipoCorridaValidation}>
                      <ControlLabel>Tipo de Corrida*</ControlLabel>
                      <Select2
                        ref='corrida_type'
                        multiple
                        disabled={this.state.disableAll}
                        value={this.state.corridaSelected}
                        data={this.state.corridaOptions}
                        options={{
                          placeholder: '',
                        }}
                        onSelect={e => {
                          this.setState({ corridaSelected: this.getSelectedOptionsValue(e.target.selectedOptions) })
                          this.setState({ tipoCorridaValidation: null })
                        }}
                        onUnselect={e => {
                          this.setState({ corridaSelected: this.getSelectedOptionsValue(e.target.selectedOptions) })
                          if (this.state.corridaSelected.length < 1) {
                            this.setState({ tipoCorridaValidation: "error" })
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup validationState={this.state.tipoCarroValidation}>
                      <ControlLabel>Tipo de Carro*</ControlLabel>
                      <Select2
                        ref='carro_type'
                        multiple
                        disabled={this.state.disableAll}
                        value={this.state.carroSelected}
                        data={this.state.carroOptions}
                        options={{
                          placeholder: '',
                        }}
                        onSelect={e => {
                          this.setState({ carroSelected: this.getSelectedOptionsValue(e.target.selectedOptions) })
                          this.setState({ tipoCarroValidation: null })
                        }}
                        onUnselect={e => {
                          this.setState({ carroSelected: this.getSelectedOptionsValue(e.target.selectedOptions) })
                          if (this.state.carroSelected.length < 1) {
                            this.setState({ tipoCarroValidation: "error" })
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>}
                
                <Row>
                <Col xs={12} md={12}>
                  <GoPagamento
                      c_prices_escolhido={this.state.c_prices_escolhido}
                      c_prices_tabela={this.state.c_prices_tabela}
                      c_pagamento={this.state.c_pagamento}
                      updatePagamento={this.goupdatePagamento}
                      mostrardetalhesuser={this.props.childProps.userAttrib.admin}
                    setTipoPagamento={this.gosetTipoPagamento}
                    pagtoOptions={this.state.pagtoOptions}
                    c_prices={this.state.c_prices}></GoPagamento>
                  </Col>
                </Row>
                
                <Row>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <ControlLabel>N° de Carros</ControlLabel>
                      <FormControl
                        value={this.state.numCarros ? this.state.numCarros : 1}
                        id="numero-carros"
                        disabled={this.state.disableAll}
                        max="10"
                        min="1"
                        type="number"
                        placeholder=""
                        onChange={e => {
                          const number = e.target.value.replace(/\D/g, '');
                          if (number.length > 0) {
                            let n = Number(number);
                            if (n > 10) n = 10;
                            this.setState({ numCarros: n });
                          } else {
                            this.setState({ numCarros: '' });
                          }
                        }}
                        onBlur={e => {
                          const number = e.target.value.replace(/\D/g, '');
                          if (number.length > 0) {
                            let n = Number(number);
                            if (n > 10) n = 10;
                            else if (n < 1) n = 1;
                            this.setState({ numCarros: n });
                          } else {
                            this.setState({ numCarros: 1 });
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={3}>
                    {!this.state.gotEmpresa &&
                      <FormGroup>
                        <Checkbox disabled={this.state.disableAll} checked={this.state.tabelada} onChange={this.pedirTab}>
                          <span>Tabelada?</span>
                        </Checkbox>
                      </FormGroup>
                    }
                  </Col>
                  <Col xs={6} md={3} className={hiddenTabelada}>
                    <FormGroup>
                      <ControlLabel>Valor</ControlLabel>
                      <CurrencyInput
                        ref='tabelada'
                        className="form-control"
                        value={this.state.valorTabelada ? this.state.valorTabelada : ''}
                        disabled={this.state.disableAll}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix="R$ "
                        onFocus={e => {
                          let str = e.target.value.split(' ')[1];
                          let number = Number(str.replace(',', '.'))
                          this.setState({ valorTabelada: number })
                        }}
                        onChangeEvent={(event, maskedvalue, floatvalue) => {
                          this.setState({ valorTabelada: floatvalue })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col xs={12} md={3}>
                    <FormGroup validationState={this.state.tipoClienteValidation}>
                      <ControlLabel>Tipo de Cliente*</ControlLabel>
                      <Select2
                        multiple
                        ref='cliente_type'
                        disabled={this.state.disableAll}
                        value={this.state.clienteSelected}
                        data={this.state.clienteOptions}
                        options={{
                          placeholder: '',
                        }}
                        onSelect={e => {
                          this.setState({ clienteSelected: this.getSelectedOptionsValue(e.target.selectedOptions) })
                          this.setState({ tipoClienteValidation: null })
                        }}
                        onUnselect={e => {
                          this.setState({ clienteSelected: this.getSelectedOptionsValue(e.target.selectedOptions) })
                          if (this.state.clienteSelected.length < 1) {
                            this.setState({ tipoClienteValidation: "error" })
                          }
                        }}
                      />
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <ControlLabel>Telefone Contato</ControlLabel>
                      <InputMask
                        ref='telefone_contato'
                        className="form-control"
                        mask={this.state.contatoMask}
                        maskChar="_"
                        disabled={this.state.disableAll}
                        value={this.state.telefoneContato ? this.state.telefoneContato : ''}
                        onChange={e => {
                          this.setState({ telefoneContato: e.target.value })
                          if (/^.....9/.test(e.target.value)) {
                            this.setState({ contatoMask: '(99) 99999-9999' })
                          } else {
                            this.setState({ contatoMask: '(99) 9999-9999' })
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <ControlLabel>Agendar Data</ControlLabel>
                      {this.state.agendCount.map((m, i) => {
                        if (i === 0) {
                          return (
                            <DatePicker
                              key={i}
                              ref='date_picker'
                              className="form-control"
                              disabled={this.state.disableAll}
                              popperPlacement="top-start"
                              popperModifiers={{
                                flip: {
                                  enabled: false
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false
                                }
                              }}
                              minDate={moment()}
                              maxDate={moment().add(30, "days")}
                              selected={this.state.dataAgend ? this.state.dataAgend[i] : null}
                              onChange={date => this.dateHandleChange(i, date)}
                            />
                          );
                        } else {
                          return (
                            <DatePicker
                              key={i}
                              ref='date_picker'
                              className="form-control form-control-margin2"
                              popperPlacement="top-start"
                              popperModifiers={{
                                flip: {
                                  enabled: false
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false
                                }
                              }}
                              disabled={this.state.disableAll}
                              minDate={moment()}
                              maxDate={moment().add(30, "days")}
                              selected={this.state.dataAgend[i]}
                              onChange={date => this.dateHandleChange(i, date)}
                            />
                          );
                        }
                      })}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <ControlLabel>Agendar Horário</ControlLabel>
                      {this.state.agendCount.map((m, i) => {
                        if (i === 0) {
                          return (
                            <div key={i}>
                              <InputMask
                                ref='agendar_horario'
                                className="form-control"
                                mask="86:79"
                                maskChar="_"
                                formatChars={this.state.horarioFormat[i]}
                                value={this.state.horaAgend[i] ? this.state.horaAgend[i] : ''}
                                disabled={this.state.disableAll}
                                onChange={e => {
                                  let ha = this.state.horaAgend;
                                  ha[i] = e.target.value;
                                  this.setState({ horaAgend: ha });
                                  if (e.target.value[0] === "2") {
                                    let hf = this.state.horarioFormat;
                                    hf[i] = { '9': '[0-9]', '8': '[0-2]', '7': '[0-5]', '6': '[0-3]', };
                                    this.setState({ horarioFormat: hf });
                                  } else {
                                    let hf = this.state.horarioFormat;
                                    hf[i] = { '9': '[0-9]', '8': '[0-2]', '7': '[0-5]', '6': '[0-9]', };
                                    this.setState({ horarioFormat: hf });
                                  }
                                }}
                              />
                              {!this.state.agendadaSelected &&
                                <Image
                                  style={{ cursor: 'pointer', width: 20, position: 'absolute', top: 22, right: -7 }}
                                  src={require('../images/plus.png')}
                                  onClick={() => { this.setState({ agendCount: [...this.state.agendCount, 0] }) }}
                                />
                              }
                            </div>
                          );
                        } else {
                          return (
                            <div key={i}>
                              <InputMask
                                style={{ marginTop: 2 }}
                                ref='agendar_horario'
                                className="form-control"
                                mask="86:79"
                                maskChar="_"
                                formatChars={this.state.horarioFormat[i]}
                                value={this.state.horaAgend[i] ? this.state.horaAgend[i] : ''}
                                disabled={this.state.disableAll}
                                onChange={e => {
                                  let ha = this.state.horaAgend;
                                  ha[i] = e.target.value;
                                  this.setState({ horaAgend: ha });
                                  if (e.target.value[0] === "2") {
                                    let hf = this.state.horarioFormat;
                                    hf[i] = { '9': '[0-9]', '8': '[0-2]', '7': '[0-5]', '6': '[0-3]', };
                                    this.setState({ horarioFormat: hf });
                                  } else {
                                    let hf = this.state.horarioFormat;
                                    hf[i] = { '9': '[0-9]', '8': '[0-2]', '7': '[0-5]', '6': '[0-9]', };
                                    this.setState({ horarioFormat: hf });
                                  }
                                }}
                              />
                              <Image
                                style={{ cursor: 'pointer', width: 20, position: 'absolute', top: 27 * (i + 1), right: -7 }}
                                src={require('../images/remove.png')}
                                onClick={() => {
                                  let ar = this.state.agendCount; ar.splice(i, 1);
                                  let ha = this.state.horaAgend; ha.splice(i, 1);
                                  let hf = this.state.horarioFormat; hf.splice(i, 1);
                                  let da = this.state.dataAgend; da.splice(i, 1);
                                  this.setState({ agendCount: ar, horaAgend: ha, horarioFormat: hf, dataAgend: da });
                                }}
                              />
                            </div>
                          );
                        }
                      })
                      }
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={2}>
                    <FormGroup>
                      <ControlLabel>Antecipar</ControlLabel>
                      <FormControl
                        ref="antecipar"
                        value={this.state.antecipar ? this.state.antecipar : 10}
                        maxLength="2"
                        type="text"
                        placeholder=""
                        disabled={this.state.disableAll}
                        onChange={e => { this.setState({ antecipar: e.target.value }) }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <ControlLabel>Observação Interna</ControlLabel>
                      <FormControl
                        disabled={this.state.disableAll}
                        id="coords"
                        componentClass="textarea"
                        placeholder=""
                        maxLength="255"
                        value={this.state.coordenadas ? this.state.coordenadas : ''}
                        onChange={e => { this.setState({ coordenadas: e.target.value }) }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <ControlLabel>Observações</ControlLabel>
                      <FormControl
                        disabled={this.state.disableAll}
                        ref="obs"
                        componentClass="textarea"
                        placeholder=""
                        maxLength="355"
                        value={this.state.observacoes ? this.state.observacoes : ''}
                        onChange={e => { this.setState({ observacoes: e.target.value }) }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col id="mapa" xs={12} md={5}>
                <Row>
                    <GoMap 
                    position={this.state.center}
                    width={400}
                    height={500}
                    markO={this.state.markerLocal}
                    mark1={this.state.markerDest}
                    mark2={this.state.motoristas}
                    mark3={this.state.motoMarker}
                    c_points={this.state.c_points}
                    >
                    </GoMap>
                </Row>
              </Col>
              <CorridasAntigas
                index={modalIndex}
                refs={popUpRefs}
                addressType='local'
                markerImg={red_marker}
                modalHide={this.modalHide}
                show={this.state.showModal}
                corridas={this.state.corridasAntigas}
                changeNumber={this.changeNumber.bind(this)}
                changeCanESC={this.changeCanESC.bind(this)}
                changeCenter={this.changeCenter.bind(this)}
                changeMarker={this.changeMarker.bind(this)}
                changeEndereco={this.changeEndereco.bind(this)}
                changeModalShow={this.changeModalShow.bind(this)}
                changePopUpRefs={this.changePopUpRefs.bind(this)}
              />
              <SearchModal
                historico={false}
                motorista={false}
                modalHide={this.searchHide}
                show={this.state.showSearch}
                loaded={this.state.loaded}
                pagtos={this.state.pagtoOptions}
                changeCanESC={this.changeCanESC.bind(this)}
                changeCorridaDados={this.changeCorridaDados.bind(this)}
                childProps={this.props.childProps}
              />
              <SearchModal
                historico={true}
                motorista={false}
                modalHide={this.historicoHide}
                show={this.state.showHistorico}
                loaded={this.state.loaded}
                pagtos={this.state.pagtoOptions}
                changeShowSearchModal={this.changeShowSearchModal.bind(this)}
                changeCanESC={this.changeCanESC.bind(this)}
                changeCorridaDados={this.changeCorridaDados.bind(this)}
                openCorridaDados={this.openCorridaDados.bind(this)}
                childProps={this.props.childProps}
              />
              <SearchModal
                historico={false}
                motorista={true}
                modalHide={this.motoristaHide}
                show={this.state.showMotorista}
                loaded={this.state.loaded}
                pagtos={this.state.pagtoOptions}
                changeCanESC={this.changeCanESC.bind(this)}
                changeMotorista={this.changeMotorista.bind(this)}
              />
              <CorridasAgendadas
                modalHide={this.agendamentoHide.bind(this)}
                changeAgendada={this.changeAgendada.bind(this)}
                show={this.state.showAgend}
              />
              <DadosCorrida
                reclamacao={this.state.ehReclamacao}
                show={this.state.showReclamacao}
                onHide={this.reclamacaoHide.bind(this)}
                corrida={this.state.corrida}
                motorista={this.state.motorista}
                childProps={this.props.childProps}
              />
              <PesquisaReclamacoes
                show={this.state.showReclamacaoLista}
                depProp={this.props.childProps.userAttrib.departamento}
                nivProp={this.props.childProps.userAttrib.nivel}
                staProp={this.props.childProps.userAttrib.status}
                modalHide={this.hideReclamacaoLista.bind(this)}
              />
              <PesquisaCorridas
                show={this.state.pesquisaCorridasShow}
                modalHide={this.hidePesquisasCorridas.bind(this)}
                childProps={this.props.childProps}
                solicitacoes={true}
              />
              <DadosIUGU
                show={this.state.showIugu}
                modalHide={this.hideDadosIUGU.bind(this)}
                childProps={this.props.childProps}
                setDadosIugu={this.setDadosIugu.bind(this)}
                dialog={this.dialog}
                dialogPai={Dialog}
              />
              <Modal
                keyboard
                show={this.state.showRamal}
                onHide={this.hidaRamal}
                bsSize="small"
                container={this}
                aria-labelledby="contained-modal-title"
              >
                <Modal.Body>
                  <FormGroup>
                    <ControlLabel>No. Ramal</ControlLabel>
                    <FormControl
                      id="ramal"
                      maxLength="4"
                      value={this.state.ramal}
                      onChange={e => { this.setState({ ramal: e.target.value }) }}
                    />
                  </FormGroup>
                  <Button
                    onClick={e => {
                      this.timerId = setInterval(this.setRamal, 2000);
                      this.setRamal();
                      this.hidaRamal();
                    }}>
                    OK!
                </Button>
                </Modal.Body>
              </Modal >
              <Dialog ref={(el) => { this.dialog = el }} />
            </Grid>
          </form>
          <div>
            <ButtonToolbar style={{/*position:'absolute', bottom: 0, width:'99%', padding:'3px 0'*/ }}>
              <Button onClick={() => {
                this.setState({ canESC: false })
                this.dialog.show({
                  body: 'Limpar a Tela??',
                  actions: [
                    Dialog.CancelAction(() => { this.setState({ canESC: true }) }),
                    Dialog.OKAction(() => {
                      setTimeout(function () { this.clearFields(); }.bind(this), 400);
                      setTimeout(function () { ReactDom.findDOMNode(this.refs.telefone).focus(); }.bind(this), 635);
                    }),
                  ],
                  bsSize: 'small',
                  onHide: (dialog) => {
                    this.setState({ canESC: true })
                    dialog.hide()
                  }
                })
              }} bsSize="small">ESC - Novo</Button>
              <Button onClick={!enviando ? this.state.agendadaSelected ? this.editCorrida : this.orderTaxi : null} bsSize="small">END - Salvar</Button>
              {/* <Button onClick={this.searchShow.bind(this)} bsSize="small">ALT+P - Pesquisar</Button> */}
              <Button onClick={this.showPesquisasCorridas.bind(this)} bsSize="small">F2 - Corridas</Button>
              <Button onClick={this.agendamentoShow.bind(this)} bsSize="small">F3 - Agenda</Button>
              <Button onClick={this.handleShowCancel} bsSize="small">F4 - Cancelar</Button>
              <Button onClick={this.reclamar} bsSize="small">ALT+R - Reclamação</Button>
              <Button onClick={this.historicoShow} bsSize="small">F8 - Histórico</Button>
              <Button onClick={this.showReclamacaoLista.bind(this)} bsSize="small">ALT+Q - Reclamações</Button>
            </ButtonToolbar>
          </div>
        </BlockUi>
        <Modal
          show={this.state.showCancel}
          onHide={this.handleCloseCancel}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
              Cancelar corrida
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <FormGroup controlId="formControlsSelectMultiple">
                <ControlLabel>Motivo cancelamento</ControlLabel>
                <FormControl componentClass="select" onSelect={this.handleChange} onChange={this.handleChange} inputRef={el => this.inputEl = el} style={{ height: 40 }} >
                  <option value=""></option>
                  {this.state.tipo_cancel && this.state.tipo_cancel.map((c, i) => {
                    return (
                      <option key={i} ref={"selecionado"} value={c.id}>{c.descricao}</option>
                    )
                  })}

                </FormControl>
              </FormGroup>
              <FormGroup
                controlId="formBasicText"
              >
                <ControlLabel>Observações: </ControlLabel>
                <FormControl
                  type="text"
                  componentClass="textarea"
                  value={this.state.value2}
                  placeholder="Escreva o texto"
                  onChange={e => this.setState({ value2: e.target.value })}
                />

              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.cancelCorrida()}>Sim </Button>
            <Button onClick={this.handleCloseCancel}>cancelar</Button>
          </Modal.Footer>
        </Modal>d
      </div >
    );
  }
}

export default Solicitacoes;
