import WS from '../services/webservice';
import React, { Component } from 'react';
import Loader from 'react-loader';
import Dialog from 'react-bootstrap-dialog-v2';
import ReactTable from "react-table";
import "react-table/react-table.css";
import PesquisaCorridas from './PesquisaCorridas.js';
import {  Button, Modal, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import moment from 'moment';
import './Solicitacoes.css';
import HistoricoRastreamento from './HistoricoRastreamento';
import HistoricoMensagens from './HistoricoMensagens';
import HistoricoPA from './HistoricoPA';
import SuspenderFF from './SuspenderFF';
import GoMap from './GoMap.js';




class MenuMoto extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataHoraInicio: null,
      dataHoraFim: null,
      dados: [],
      items: [],
      ffTipos: [],
      loaded: true,
      showFF: false,
      showPA: false,
      showMsgs: false,
      showModal: false,
      showHistRast: false,
      center: { lat: -25.47, lng: -49.29 },
      zoom: 15,
      mensagemcarregada: [],
      mensagematual: [],
      moto: null,
    }

    this.handleSend = this.handleSend.bind(this);
    this.salvaValor = this.salvaValor.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.manageShortcut = this.manageShortcut.bind(this);

    this.webservice = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {
    this.timerId = setInterval(this.getCurrentTime, 5000);
  }

  async handleClick(key) {
    try {
      let ncpf = key
      let ccpf = ncpf.replace("1:", "")
      let result = await this.webservice.fetch2('mensagens?cpf=' + ccpf);
      var msgc = await result.json(result.result);
      var msgAT = msgc[0];
      this.setState({
        mensagemcarregada: msgc,
        mensagematual: msgAT,
        showMsgDetails: true
      });
    } catch (err) {
      this.setState({ error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }

  async handleSend() {
    try {
      let re = /([1-9]+:)([1-9]+)/
      let ncpf = this.state.mensagematual.cpf
      let ref = ncpf.replace(re, '$2')
      await this.webservice.fetch2('mensagens/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cpf: ref,
          uuid: this.state.mensagematual.uuid,
          at: this.state.mensagematual.at,
          rt: this.state.mensagematual.rt,
          textolivre: this.state.enviando,
          remetente: "monitor",
          alternativas: "OK"
        })
      })

      this.setState({ enviando: '' });

      this.handleClick(this.state.mensagematual.cpf);
    } catch (err) {
      this.setState({ error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }

  salvaValor(e) {
    var value = e.target.value;
    this.setState({ enviando: value });
  }

  manageShortcut(e) {
    var code = (e.keyCode ? e.keyCode : e.which);

    //SHORTCUT: ESC
    if (code === 27) {
      this.setState({ loaded: true });
    }

    //SHORTCUT: C - CORRIDAS
    if (code === 67) {
      this.getMotoristaCorridas();
    }

    //SHORTCUT: H - HIST PA
    if (code === 72) {
      this.getHistPA();
    }

     //SHORTCUT: L - LIBERAR FF
     if (code === 76) {
      this.liberarFF();
    }

    //SHORTCUT: M - MAPA
    if (code === 77) {
      this.mostraMapa();
    }

    //SHORTCUT: P - PAGAMENTO
    if (code === 80) {
      this.cadastraPagamento();
    }

    //SHORTCUT: Q - QRT FF
    if (code === 81) {
      this.suspenderQRT();
    }

    //SHORTCUT: R - HIST RASTR
    if (code === 82) {
      this.getHistRast();
    }

    //SHORTCUT: S - STATUS
    if (code === 83) {
      this.getMotoristaStatus();
    }

    //SHORTCUT: U - HIST MSGS
    if (code === 85) {
      this.getHistMsgs();
    }
  }

  getMotoristaCorridas() {
    this.setState({ loaded: false });
    this.webservice.runSearch(moment().subtract(30, 'days').format("YYYY/MM/DD HH:mm"), moment().format("YYYY/MM/DD HH:mm"), 'motorista', this.props.menuMotoAT).then(result => {
      console.log(result.length);
      this.setState({ dados: result, showModal: true, loaded: true })

      /* if (result.length > 0) {
         this.setState({ dados: result, showModal: true, loaded: true })
       } else {
         this.setState({ loaded: true })
         Dialog.resetOptions();
         this.dialog.showAlert("Não foi encontrado corridas nesse AT!");
       }*/
    })
  }

  getHistPA() {
    this.webservice.getMotoristas('?at=' + this.props.menuMotoAT + '&centrais=' + this.props.childProps.userAttrib.centralString.ids).then(result => {
      let obj = result.find((obj) => { return obj.online === true });
      if (obj) {
        this.setState({ moto: obj, showPA: true });
      } else {
        Dialog.resetOptions();
        this.dialog.showAlert("Não foi encontrado corridas nesse AT!");
      }
    })
  }

  mostraMapa() {
    this.webservice.getMotoristas('?at=' + this.props.menuMotoAT + '&centrais=' + this.props.childProps.userAttrib.centralString.ids).then(result => {
      let obj = result.find((obj) => { return obj.online === true });
      Dialog.resetOptions();
      this.dialog.show({
        body:
          obj ?
            <div id="googleMapDiv" style={{ height: '500', width: '500' }}>
                <GoMap
              EscondeAtalhos={true}
              MarcadorClick={this.markerCLick}
              width={500}
              height={500}
              minZoom={7}
              maxZoom={18}
              markO={[obj]}
              ></GoMap>
              
            </div>
            :
            'Não foi encontrado esse AT!'
        ,
        actions: [
          Dialog.OKAction(() => { }),
        ],
        bsSize: 'lg',
        onHide: (dialog) => {
          dialog.hide()
        },
      })
    })
  }

  cadastraPagamento() {
    this.webservice.getMotoristas('?at=' + this.props.menuMotoAT + '&centrais=' + this.props.childProps.userAttrib.centralString.ids).then(result => {
      Dialog.resetOptions();
      this.dialog.show({
        body:
          result.length > 0 ?
            <ReactTable
              data={result}
              pageSize={result.length}
              previousText='Anterior'
              nextText='Próxima'
              loadingText='Carregando...'
              noDataText='Nenhum dado encontado'
              pageText='Página'
              ofText='de'
              rowsText='linhas'
              filterable={false}
              sortable={false}
              columns={[
                {
                  Header: "NOME",
                  accessor: "nome",
                },
                {
                  Header: "STATUS",
                  id: 'online',
                  accessor: d => d.online ? <div style={{ color: 'green' }}>Online</div> : <div style={{ color: 'red' }}>Offline</div>,
                  width: 70,
                },
                {
                  Header: "PAGAMENTO",
                  id: 'click-me-button',
                  width: 150,
                  Cell: props =>
                    <Button
                      onClick={(e) => {
                        this.webservice.pagamento(props.original)
                          .then(result => {
                            console.log('duashuidhsahdiashiu', result);
                            if (!result.ok) {
                              alert('Ocorreu um erro ao cadastrar o pagamento!');
                            } else {
                              alert('Cadastro do pagamento efetuado com sucesso!');
                              this.dialog.hide();
                            }
                          })
                      }}>Pagamento</Button>
                }
              ]}
              showPagination={false}
              className="-striped -highlight"
            />
            :
            'Não foi encontrado esse AT!'
        ,
        actions: [
          Dialog.OKAction(() => { }),
        ],
        bsSize: 'md',
        onHide: (dialog) => {
          dialog.hide()
        },
      })
    })
  }

  suspenderQRT() {
    this.webservice.getMotoristas('?at=' + this.props.menuMotoAT + '&centrais=' + this.props.childProps.userAttrib.centralString.ids).then(result => {
      Dialog.resetOptions();
      this.dialog.show({
        body:
          result.length > 0 ?
            <ReactTable
              data={result}
              pageSize={result.length}
              getTdProps={(state, props, column) => {
                return {
                  style: {
                    cursor: "pointer",
                  },
                  onClick: (e) => {
                    let ffTipos;
                    this.webservice.getFFTipos()
                      .then(ff => {
                        ffTipos = ff.map((f, i) => { return { ...f, id: f.t28_cdtp_ff, text: f.t28_desc_ff } });
                      });
                    Dialog.setOptions({
                      defaultOkLabel: 'Sim',
                      defaultCancelLabel: 'Não',
                      primaryClassName: 'btn-danger '
                    });
                    this.dialog.show({
                      body: 'Deseja colocar esse motorista em FF?',
                      actions: [
                        Dialog.OKAction(() => {
                          this.setState({ moto: props.original, showFF: true, ffTipos: ffTipos });
                        }),
                        Dialog.CancelAction(() => { }),
                      ],
                      bsSize: 'md',
                      onHide: (dialog) => {
                        dialog.hide()
                      },
                    })
                  },
                }
              }}
              previousText='Anterior'
              nextText='Próxima'
              loadingText='Carregando...'
              noDataText='Nenhum dado encontado'
              pageText='Página'
              ofText='de'
              rowsText='linhas'
              filterable={false}
              sortable={false}
              columns={[
                {
                  Header: "NOME",
                  accessor: "nome",
                },
                {
                  Header: "STATUS",
                  id: 'online',
                  accessor: d => d.online ? <div style={{ color: 'green' }}>Online</div> : <div style={{ color: 'red' }}>Offline</div>,
                  width: 70,
                },
              ]}
              showPagination={false}
              className="-striped -highlight"
            />
            :
            'Não foi encontrado esse AT!'
        ,
        actions: [
          Dialog.OKAction(() => { }),
        ],
        bsSize: 'sm',
        onHide: (dialog) => {
          dialog.hide()
        },
      })
    });
  }

  liberarFF() {
    this.webservice.getFFbyAT(this.props.menuMotoAT).then(result => {
      console.log('FF', result);
      Dialog.resetOptions();
      this.dialog.show({
        body:
          result.length > 0 ?
            <ReactTable
              data={result}
              pageSize={result.length}
              getTdProps={(state, props, column) => {
                return {
                  style: {
                    cursor: "pointer",
                  },
                  onClick: (e) => {
                    Dialog.setOptions({
                      defaultOkLabel: 'Sim',
                      defaultCancelLabel: 'Não',
                      primaryClassName: 'btn-danger '
                    });
                    this.dialog.show({
                      body: 'Deseja remover o FF desse motorista?',
                      actions: [
                        Dialog.OKAction(() => {
                          this.webservice.removerFFMoto(props.original.t44_num_ocorrencia, this.props.childProps.userAttrib.nome)
                          .then(result => {
                            console.log('RESULTADO FF ',result);
                            Dialog.resetOptions();
                            this.dialog.showAlert("FF removido com sucesso!");
                          })
                        }),
                        Dialog.CancelAction(() => { }),
                      ],
                      bsSize: 'md',
                      onHide: (dialog) => {
                        dialog.hide()
                      },
                    })
                  },
                }
              }}
              previousText='Anterior'
              nextText='Próxima'
              loadingText='Carregando...'
              noDataText='Nenhum dado encontado'
              pageText='Página'
              ofText='de'
              rowsText='linhas'
              filterable={false}
              sortable={false}
              columns={[
                {
                  Header: "NOME",
                  accessor: "t08_motoristas_t08_nomemotorista",
                },
                {
                  Header: 'MOTIVO',
                  accessor: 't28_tp_ff_t28_desc_ff',
                },
                {
                  Header: "Data/Hora Inicio",
                  id: 'hora_inicio',
                  accessor: d => moment(d.t44_dthr_ini).format('DD/MM/YYYY HH:mm'),
                  width: 120,
                },
              ]}
              showPagination={false}
              className="-striped -highlight"
            />
            :
            'Não foi encontrado nenhum FF nesse AT!'
        ,
        actions: [
          Dialog.CancelAction(() => { }),
        ],
        bsSize: 'md',
        onHide: (dialog) => {
          dialog.hide()
        },
      })
    });
  }

  getHistRast() {
    this.webservice.getMotoristas('?at=' + this.props.menuMotoAT + '&centrais=' + this.props.childProps.userAttrib.centralString.ids).then(result => {
      let obj = result.find((obj) => { return obj.online === true });
      if (obj) {
        this.setState({ moto: obj, showHistRast: true });
      } else {
        Dialog.resetOptions();
        this.dialog.showAlert("Não foi encontrado corridas nesse AT!");
      }
    })
  }

  getMotoristaStatus() {
    this.webservice.getMotoristas('?at=' + this.props.menuMotoAT + '&centrais=' + this.props.childProps.userAttrib.centralString.ids).then(result => {
      Dialog.resetOptions();
      this.dialog.show({
        body:
          result.length > 0 ?
            <ReactTable
              data={result}
              pageSize={result.length}
              previousText='Anterior'
              nextText='Próxima'
              loadingText='Carregando...'
              noDataText='Nenhum dado encontado'
              pageText='Página'
              ofText='de'
              rowsText='linhas'
              filterable={false}
              sortable={false}
              columns={[
                {
                  Header: "NOME",
                  accessor: "nome",
                },
                {
                  Header: "STATUS",
                  id: 'online',
                  accessor: d => d.online ? <div style={{ color: 'green' }}>Online</div> : <div style={{ color: 'red' }}>Offline</div>,
                  width: 70,
                },
              ]}
              showPagination={false}
              className="-striped -highlight"
            />
            :
            'Não foi encontrado esse AT!'
        ,
        actions: [
          Dialog.OKAction(() => { }),
        ],
        bsSize: 'sm',
        onHide: (dialog) => {
          dialog.hide()
        },
      })
    })
  }

  getHistMsgs() {
    this.webservice.getMotoristas('?at=' + this.props.menuMotoAT + '&centrais=' + this.props.childProps.userAttrib.centralString.ids).then(result => {
      let obj = result.find((obj) => { return obj.online === true });
      if (obj) {
        this.setState({ moto: obj, showMsgs: true });
      } else {
        Dialog.resetOptions();
        this.dialog.showAlert("Não foi encontrado esse AT!");
      }
    })
  }

  modalHide() {
    this.setState({ showModal: false })
  }

  hideHistRast() {
    this.setState({ showHistRast: false })
  }

  hideFF() {
    this.setState({ showFF: false })
  }

  hidePA() {
    this.setState({ showPA: false })
  }

  hideMsgs() {
    this.setState({ showMsgs: false })
  }

  render() {
    return (
      <div>
        <Modal
          keyboard
          onKeyDown={this.manageShortcut}
          tabIndex="0"
          container={this}
          show={this.props.show}
          onHide={this.props.modalHide}
          aria-labelledby="contained-modal-title"
          onEntered={function () { }}
          style={{ zIndex: 1040 }}
        >
          <Modal.Body>
            <Row>
              <Col xs={12} md={12}>
                <FormGroup>
                  <ControlLabel>AT</ControlLabel>
                  <FormControl
                    autoFocus
                    type="text"
                    placeholder=""
                    maxLength="4"
                    value={this.props.menuMotoAT}
                    onChange={e => { this.props.changeMenuMotoAT(e.target.value.replace(/\D/g, '')) }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Loader loaded={this.state.loaded}>
              <div>
                <Row className='button-grid'>
                  <Col xs={6} md={4}>
                    <Button onClick={this.getMotoristaStatus.bind(this)}><strong style={{ fontSize: '20px' }}>S</strong><br></br>Status</Button>
                  </Col>
                  <Col xs={6} md={4}>
                    <Button onClick={this.getMotoristaCorridas.bind(this)}><strong style={{ fontSize: '20px' }}>C</strong><br></br>Corridas</Button>
                  </Col>
                  <Col xs={6} md={4}>
                    <Button onClick={this.mostraMapa.bind(this)}><strong style={{ fontSize: '20px' }}>M</strong><br></br>Mostrar no Mapa</Button>
                  </Col>
                </Row>
                <Row className='button-grid'>
                  <Col xs={6} md={4}>
                    <Button onClick={this.getHistPA.bind(this)}><strong style={{ fontSize: '20px' }}>H</strong><br></br>Histórico de PA</Button>
                  </Col>
                  <Col xs={6} md={4}>
                    <Button onClick={this.getHistRast.bind(this)}><strong style={{ fontSize: '20px' }}>R</strong><br></br>Hist. Rastreamento</Button>
                  </Col>
                  <Col xs={6} md={4}>
                    <Button onClick={this.getHistMsgs.bind(this)}><strong style={{ fontSize: '20px' }}>U</strong><br></br>Hist. Últimas Msgs</Button>
                  </Col>
                </Row>
                <Row className='button-grid'>
                  <Col xs={6} md={4}>
                    <Button onClick={this.cadastraPagamento.bind(this)}><strong style={{ fontSize: '20px' }}>P</strong><br></br>Pagamento</Button>
                  </Col>
                  <Col xs={6} md={4}>
                    <Button onClick={this.suspenderQRT.bind(this)} ><strong style={{ fontSize: '20px' }}>Q</strong><br></br>Suspender F.F.</Button>
                  </Col>
                  <Col xs={6} md={4}>
                    <Button onClick={this.liberarFF.bind(this)} ><strong style={{ fontSize: '20px' }}>L</strong><br></br>Liberar F.F.</Button>
                  </Col>
                </Row>
              </div>
            </Loader>
          </Modal.Body>
        </Modal>
        <Dialog ref={(el) => { this.dialog = el }} />
        <Dialog ref={(el) => { this.dialog2 = el }} />
        <PesquisaCorridas
          show={this.state.showModal}
          modalHide={this.modalHide.bind(this)}
          childProps={this.props.childProps}
          motoAT={this.props.menuMotoAT}
        />
        <HistoricoRastreamento
          show={this.state.showHistRast}
          modalHide={this.hideHistRast.bind(this)}
          childProps={this.props.childProps}
          moto={this.state.moto}
        />
        <SuspenderFF
          show={this.state.showFF}
          modalHide={this.hideFF.bind(this)}
          childProps={this.props.childProps}
          moto={this.state.moto}
          ffTipos={this.state.ffTipos}
          dialog={this.dialog2}
        />
        <HistoricoPA
          show={this.state.showPA}
          modalHide={this.hidePA.bind(this)}
          childProps={this.props.childProps}
          moto={this.state.moto}
        />
        <HistoricoMensagens
          show={this.state.showMsgs}
          modalHide={this.hideMsgs.bind(this)}
          childProps={this.props.childProps}
          moto={this.state.moto}
        />
      </div>
    );
  }
}
export default MenuMoto;
