import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from './CadastroHelper.js';

class CadastroTipoReclamacao extends Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <CadastroHelper
        dados={{
          acessoID: '3ef9d707-80bc-48d5-86a9-a4ae32b3a213',
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Tipo de reclamação",
          tipo_plural: "reclamações",
          href: "/tipo_reclamacao",
          titulo: "Cadastro de Tipos de Reclamações",
          requests: {
            type: 'tipo_reclamacao',
            list: this.ws.getTipo.bind(this.ws),
            save: this.ws.criarTipo.bind(this.ws),
            edit: this.ws.alterarTipo.bind(this.ws),
            delete: this.ws.deleteTipo.bind(this.ws),
          },
          campos: [
            {
              ref: "descricao",
              label: "Descrição",
              type: "text",
              placeHolder: "Escreva a descrição do tipo de reclamação",
            },
          ],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Descrição",
                accessor: "descricao",
              },
            ],
          }
        }}
      />
    );
  }
}

export default CadastroTipoReclamacao;
