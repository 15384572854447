import React, {Component} from 'react'
import { Col, Row, Modal, Button, Form, Image, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import ReactTable from "react-table";
import WS from '../services/webservice';

class CriaStatus extends Component{

   constructor(props) {
    super(props);
    this.state = {
      id: '',
      descricao: '',
      administrativo: false,
      next: 0,
    }

    this.webservice = new WS();
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.edit = this.edit.bind(this)
    this.remove = this.remove.bind(this)

  }
  async fetchData(dados, instance) {
    // show the loading overlay
    this.setState({ loading: true, dados: dados, instance: instance })
    // fetch your data
    let order = dados.sorted;
    let pages = dados.page + 1
    let result = await this.webservice.getTipo('status_reclamacao', '', order, pages, dados.pageSize, dados.filtered, null);
    let count = result.headers.get('X-Total-Count');
    result = await result.json();

    this.setState({
      list: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false
    })
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => { this.fetchData(dados, instance) }, 300)
    });
  }

  edit(a) {
    console.log("MEUS DADOS AQUI", a)
    this.setState({
      modal: true,
      edit: true,
      id: a.original.id,
      descricao: a.original.descricao,
      next: a.original.next,
    }, ()=>this.fetchData(this.state.dados, this.state.instance))
  }

  modalHide() {
    this.setState({ modal: false, edit: false });
  }

  remove(a) {
    this.webservice.deleteStatus(a.original.id)
    this.fetchData(this.state.dados, this.state.instance)
  }
  render() {

    return (
      <div>
        <div style={{ marginBottom: 10, marginLeft: 10 }}>

          <Button onClick={() => this.setState({
            modal: true,
            edit: false,
            id: '',
            descricao: '',
            next: '',
          })}>
            novo
        </Button>
        </div>

        <ReactTable
          manual
          data={this.state.list}
          pages={this.state.pages}
          loading={this.state.loading}
          onFetchData={this.fetchDataTimeOut}
          previousText='Anterior'
          nextText='Próxima'
          loadingText='Carregando...'
          noDataText='Nenhum dado encontado'
          pageText='Página'
          ofText='de'
          rowsText='linhas'
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
          columns={[
            {
              columns: [
                {
                  Header: 'Nome',
                  accessor: 'descricao',
                },

                {
                  Header: 'Ações',
                  width: 180,
                  filterable: false,
                  sortable: false,
                  style: {
                    cursor: 'pointer',
                  },
                  Cell: props =>
                    <div>
                      {(this.props.childProps.userAttrib.admin ||
                        this.props.childProps.userAttrib.monitorcentral ||
                        this.state.userAttrib.acesso['9fb9c59f-7bca-4649-a910-62100976239c']) &&
                        <Image src={require('../images/edit.png')} style={{ marginLeft: '10px' }}
                          onClick={() => { this.edit(props) }}></Image>
                      }
                      {(this.props.childProps.userAttrib.admin ||
                        this.props.childProps.userAttrib.monitorcentral ||
                        this.state.userAttrib.acesso['9fb9c59f-7bca-4649-a910-62100976239c']) &&
                        <Image src={require('../images/remove.png')} style={{ marginLeft: '10px' }}
                          onClick={() => { this.remove(props) }}></Image>
                      }
                    </div>
                }
              ]
            },
          ]}
          defaultPageSize={5}
          className="-striped -highlight"
        />
        <Modal
          keyboard
          show={this.state.modal}
          onHide={this.modalHide.bind(this)}
          container={this}
          aria-labelledby="contained-modal-title"
          dialogClassName="referencias-dialog-modal">
          <Modal.Body>
            <div style={{ margin: 20 }}>
              <Form>
                <Row style={{ marginBottom: 5 }}>
                  <FormGroup>
                    <Col sm={2}>
                      <ControlLabel>Id</ControlLabel>
                    </Col>
                    <Col sm={5}>
                      <FormControl onChange={(e) => this.setState({
                        id: e.target.value
                      })} type="email" name="email" value={this.state.id} />
                    </Col>
                  </FormGroup>
                </Row>
                <Row style={{ marginBottom: 5 }}>
                  <FormGroup>
                    <Col sm={2}>
                      <ControlLabel >Descricao</ControlLabel>
                    </Col>
                    <Col sm={5}>
                      <FormControl onChange={(e) => {
                        this.setState({
                          descricao: e.target.value
                        })
                      }} type="email" name="email" value={this.state.descricao} />
                    </Col>
                  </FormGroup>
                </Row>
                <Row style={{ marginBottom: 5 }}>
                <FormGroup>
                    <Col sm={2}>
                      <ControlLabel >Next</ControlLabel>
                    </Col>
                    <Col sm={5}>
                      <FormControl onChange={(e) => {
                        this.setState({
                          next: e.target.value
                        })
                      }}  value={this.state.next} />
                    </Col>
                  </FormGroup>
                </Row>
                {!this.state.edit &&
                  <Button onClick={() => { this.webservice.criaStatus(this.state.id, this.state.descricao, this.state.next).then(()=>this.fetchData(this.state.dados, this.state.instance)); this.setState({modal: false}) }}>
                    Criar
                </Button>
                }
                {this.state.edit &&
                  <Button onClick={() => { this.webservice.editStatus(this.state.id, this.state.descricao, this.state.next).then(()=>this.fetchData(this.state.dados, this.state.instance)); this.setState({modal:false}) }}>
                    Salvar
                </Button>
                }

              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default CriaStatus