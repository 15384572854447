import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class WpgApp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: []
    }
    this.webservice = new WS();
  }

  mirrorState(key) {
    this.setState(key);
  }


  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "pg_apps",
          tipo_plural: "pg_apps",
          href: "pg_apps",
          titulo: "pg_apps",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'pg_apps',
            list: true,
            new: true,
            save: true,
            edit: true,
            delete: false,
            listSub: true,
          },
          campos: [
            { ref: "central", type: "text", },
			      { ref: "ps_name", type: "text", },
			      { ref: "ps_client_id", type: "text", },
			      { ref: "ps_client_secret", type: "text", },
			      { ref: "ps_site", type: "text", },
			      { ref: "ps_redirect_uri", type: "text", },
			      { ref: "ps_account_id", type: "text", },
			      { ref: "ps_client_type", type: "text", }
          ],
          table: {
            colunas: [
              { Header: "central", accessor: "v", },
              { Header: "ps_name", accessor: "ps_name", },
              { Header: "ps_account_id", accessor: "ps_account_id", }

            ],
          }
        }}
      />
    );
  }
}
export default WpgApp;