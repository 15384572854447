import React, { Component } from 'react';
import './Corridas.css';
import { CSSTransitionGroup } from 'react-transition-group'
import Push from 'push.js';
import NewMsg from './NewMsg.js';
import WS from '../services/webservice';

class Notification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      PC: 0,
      mensagensTotal: [],
      mensagensAtivas: [],
      mensagemcarregada: [],
      mensagematual: [],
      m:{},
      at: '',
      aberto: false,
      central: '',
      cpf: '',
      rt: '',
      uuid: '',
      PQRL: 0,
      PQRV: 0,
      FPC: 0,
      FPQRL: 0,
      FPQRV: 0,
      variable: {},
      iparam: {},
      showParam: false,
    };

    this.updateData = this.updateData.bind(this);
    this.addMensagem = this.addMensagem.bind(this);
    this.msgaberto = this.msgaberto.bind(this);

    this.webservice = new WS();
  }


  componentDidMount() {
    this.timerId = setInterval(this.updateData, 15000);
    //console.log("FUI CHAMADO FIAO")
    this.updateData();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  addMensagem(m) {
    let AV = this.state.mensagensAtivas
    /*console.log("OLHA O AV", AV)
    console.log("olha o m", m)
    console.log("TOTAL MSG", this.state.mensagensTotal)*/
    console.log("olha o m", m)

    var index = AV.map(function(e) { console.log("vtnc", e);return e.uuid; }).indexOf(m.uuid);
    console.log("VAI TOMAR NO CU", index)
    //var index = AV.indexOf(av => av.uuid === m.uuid);
    if (index > -1) {
      AV.splice(index, 1);
      this.setState({
        mensagensAtivas: AV,
        m:{},
        at: '',
        aberto: false,
        central: '',
        cpf: '',
        rt: '',
        uuid: '',
      })
    } else {
      this.setState({
        mensagensAtivas: [...this.state.mensagensAtivas, m]
      })
    }

  }

  msgaberto(result) {
    console.log("result boys1", result)

    this.props.msgaberto(result)
  }

  modalHide() {
    this.setState({
      aberto: false
    })
  }

  handleClick(m) {
    this.addMensagem(m)
    console.log("MEU M MOTORISTA", m)
    this.setState({
      eme: m,
      at: m.at,
      central: m.central,
      cpf: m.cpf,
      rt: m.rt,
      uuid: m.uuid,
      aberto: true,
    })
  }

  async updateData() {
    try {
      //console.log("PROZAOLINDO", this.props)
      if (this.props.central.centralString) {
        //let result = await this.webservice.fetch2('mensagens?centrais=' + this.props.central.centralString.ids);
        //var msg = await result.json();
        var msg = []
        let self = this;
        msg.result.map((m, i) => {
          let obj = this.props.central.centrais.find((obj) => { return obj.id === Number(m.central); });
          let nomecentral = obj.nomefantasia;
          Push.create("Nova Mensagem", {
            body: "AT: " + m.at + " - Central: " + nomecentral,
            icon: require('../images/motorista.png'),
            timeout: 4000,
            onClick: function () {
              window.focus();
              this.close();
              self.handleClick(m);
            }
          });
          return null;
        })
        console.log("Mensagens total", this.state.mensagensTotal)
        console.log("mensagens ativas", this.state.mensagensAtivas)
        this.setState({
          mensagens: msg.result,
          mensagensTotal: [...new Set([...msg.result, ...this.state.mensagensAtivas])]
        });

      }
      //console.log("SOU O CPF", this.state.mensagens)
      this.setState({ error: null, loaded: true });

    } catch (err) {
      //console.log("ERROR", err)
      this.setState({ loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }


  render() {
    return (
      <div>
        <CSSTransitionGroup
          transitionName="example"
          component="tbody"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}>
          <div style={{ position: 'fixed', bottom: '50px' }}>
            {
              this.state.mensagensTotal.map(m => {
                console.log("NOVA MESSAGI", m)
                return (
                  //<tr key={m.cpf} onClick={() => this.handleAtual(m, m.central ? m.central : m.central_id)}>
                  //<td>Nova Mensagem - {m.at} - {m.rt} - {this.mostraCentral(m)}</td>
                  
                  <div style={{ display: 'inline-block', padding: 5 }} onClick={()=>this.handleClick(m)} >
                    {!this.state.aberto && m && <NewMsg
                      modalHide={this.modalHide.bind(this)}
                      centrais={this.props.central}
                      sendmsg={this.addMensagem}
                      msgaberto={this.msgaberto.bind(this)}
                      eme={m}
                      at={ Number(m.at)}
                      rt={ Number(m.rt)}
                      uuid={ m.uuid}
                      central={ Number(m.central ? m.central : m.central_id)}
                      cpf={ Number(m.cpf)} />}
                  </div>

                  //</tr>
                )
              })
            }
            {this.state.aberto && <div style={{ display: 'inline-block', padding: 5 }} >
              <NewMsg
                modalHide={this.modalHide.bind(this)}
                aberto={this.state.aberto}
                centrais={this.props.central}
                sendmsg={this.addMensagem.bind(this)}
                msgaberto={this.msgaberto.bind(this)}
                eme={this.state.eme}
                at={Number(this.state.at)}
                rt={Number(this.state.rt)}
                uuid={this.state.uuid}
                central={Number(this.state.central)}
                cpf={Number(this.state.cpf)} />
            </div>}
          </div>

        </CSSTransitionGroup>
      </div>
    )
  }
}
export default Notification;
