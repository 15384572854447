import {
  Button,
  Card,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Divider,
  Dropdown,
  Modal,
  Row,
  Select,
  Table,
  Layout
} from 'antd'
import { Excel } from 'antd-table-saveas-excel'
import { Option } from 'antd/es/mentions'

import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

import React, { Component } from 'react'
import WS from '../../services/webservice'
import * as Styled from './styles'

const { Panel } = Collapse
const { Content } = Layout
dayjs.locale('pt-br')

// -- Estilos inline específicos para alguns componentes --

const cardStyleOff = {
  textAlign: 'center',
  color: '#FF0000',
  width: '100%',
  margin: '5px'
}

const cardStyle = {
  width: '100%',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
}

const panelStyle = {
  fontSize: '15px',
  fontWeight: 'bold',
  border: 'solid 0.05px #999',
  overflow: 'hidden',
  margin: '0',
  borderRadius: '5px'
}

// -- colunas para a tabela de motoristas --

const colunasMotoristas = [
  {
    title: 'Documento do Motorista',
    dataIndex: 'id',
    colSpan: '1'
  },
  {
    title: 'Nome do Motorista',
    dataIndex: 'nome',
    colSpan: '1'
  },
  {
    title: 'Solicitações no mês selecionado',
    dataIndex: 'corridas',
    colSpan: '1'
  }
]

const colunasClienteCanceladas = [
  {
    title: 'ID da Corrida',
    dataIndex: 'id_corrida'
  },
  {
    title: 'Nome do Motorista',
    dataIndex: 'nome_motorista'
  },
  {
    title: 'Nome do Cliente',
    dataIndex: 'nome_cliente'
  },
  {
    title: 'Valor da Corrida',
    dataIndex: 'valor_corrida'
  },
  {
    title: 'Motivo do Cancelamento',
    dataIndex: 'motivo_cancelamento'
  },
  {
    title: 'Endereço de Origem',
    dataIndex: 'endereco_origem'
  }
]

const colunasClienteAgendadas = [
  {
    title: 'ID da Corrida',
    dataIndex: 'id_corrida'
  },
  {
    title: 'Nome do Motorista',
    dataIndex: 'nome_motorista'
  },
  {
    title: 'Nome do Cliente',
    dataIndex: 'nome_cliente'
  },
  {
    title: 'Valor da Corrida',
    dataIndex: 'valor_corrida'
  },
  {
    title: 'Data do Agendamento',
    dataIndex: 'data_agendamento'
  },
  {
    title: 'Endereço de Origem',
    dataIndex: 'endereco_origem'
  }
]

const colunasCliente = [
  {
    title: 'ID da Corrida',
    dataIndex: 'id_corrida'
  },
  {
    title: 'Nome do Motorista',
    dataIndex: 'nome_motorista'
  },
  {
    title: 'Nome do Cliente',
    dataIndex: 'nome_cliente'
  },
  {
    title: 'Valor da Corrida',
    dataIndex: 'valor_corrida'
  },
  {
    title: 'Endereço de Origem',
    dataIndex: 'endereco_origem'
  }
]

// -----------------------------------------------

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.webservice = new WS()
    this.getDriversDrives = this.getDriversDrives.bind(this)
    this.getStatusCorridas = this.getStatusCorridas.bind(this)
    this.getDashboardInfos = this.getDashboardInfos.bind(this)
    this.getStatusCorridasMonth = this.getStatusCorridasMonth.bind(this)

    this.state = {
      motoristas: [],
      statusCorrida: [],
      qtdeFormaPagamento: [],
      dashBoardInfos: [],
      infosMotoristas: {},
      central_id: '',
      statusCorridaMonth: [],
      selectStatus: '',
      selectStatus30: '',
      dataSelecionada: '',
      dataSelecionadaMotoristas: '',
      totalizadores: '',
      motoristasOnline: [],
      dadosOnline: [],
      tiposCorridas: [],
      openModal: false
    }
  }

  handleSelectChange = (value) => {
    this.setState({ selectStatus: value })
    this.getStatusCorridas(value)
  }

  handleSelectChange30 = (value) => {
    this.setState({ selectStatus30: value })
    this.getStatusCorridasMonth(value)
  }

  handleSelectChangeDashboardInfos = (value) => {
    this.setState({ selectStatusDashboardInfos: value })
    this.getDashboardInfos(value)
  }

  handleOpenModal = () => {
    this.setState({ openModal: true })
  }

  handleCloseModal = () => {
    this.setState({ openModal: false })
  }

  async getStatusCorridas() {
    try {
      const result = await this.webservice.getStatusCorridas()
      this.setState({ statusCorrida: result })
    } catch (error) {
      console.log('error', error)
      return error
    }
  }

  async getStatusCorridasMonth(
    selectMes = JSON.stringify(new Date().toISOString().slice(0, 10))
  ) {
    try {
      const result = await this.webservice.getStatusCorridasMonth(selectMes)
      this.setState({ statusCorridaMonth: result })
    } catch (error) {
      return error
    }
  }

  async getDriversDrives(
    data = JSON.stringify(new Date().toISOString().slice(0, 10))
  ) {
    try {
      console.log('!!!****data', data)
      let result = await this.webservice.getDriversDrives(data)
      if (!result) {
        this.setState({
          motoristas: null
        })
      }
      this.setState({
        motoristas: result
      })
    } catch (error) {
      return error
    }
  }

  async getQtdeFormaPagamento() {
    try {
      let qtdePagamento = await this.webservice.getQtdeFormaPagamento()
      this.setState({
        qtdeFormaPagamento: qtdePagamento
      })
    } catch (error) {
      return error
    }
  }

  async getDashboardInfos(selectStatus) {
    try {
      const infos = await this.webservice.getDashboardInfos(selectStatus)
      this.setState({
        dashBoardInfos: infos
      })
    } catch (error) {
      console.log('*Error', error)
      return error
    }
  }

  async getDriversInfos() {
    try {
      let infosMotoristas = await this.webservice.getDriversInfos()
      this.setState({
        infosMotoristas: infosMotoristas
      })
    } catch (error) {
      return error
    }
  }

  async getTotalizadores() {
    try {
      let totalizadores = await this.webservice.getTotalizadores()
      this.setState({
        totalizadores: totalizadores.qtdeCorridas[0]
      })
    } catch (error) {
      return error
    }
  }

  async getTiposCorridas() {
    try {
      let tiposCorridas = await this.webservice.getTiposCorridas()
      this.setState({
        tiposCorridas: tiposCorridas
      })
    } catch (error) {
      return error
    }
  }

  async componentDidMount() {
    this.getDriversDrives()
    this.getStatusCorridas()
    this.getQtdeFormaPagamento()
    this.getDashboardInfos()
    this.getDriversInfos()
    this.getStatusCorridasMonth()
    this.getTotalizadores()
    this.getTiposCorridas()

    let result = await this.webservice.fetch2('motoristas/search')
    result = await result.json()
    console.log('*updateData', result)
    this.setState({
      motoristasOnline: result.map((r) => {
        if (r.online) {
          this.setState({ dadosOnline: this.state.dadosOnline + 1 })
        }
        return { ...r, _uuid: r.uuid, color: r.livre ? 'green' : 'red' }
      })
    })

    console.log('*motoristasOnline', this.state.motoristasOnline)
    console.log('*dadosOnline', this.state.dadosOnline)
  }

  render() {
    const {
      selectStatus,
      selectStatus30,
      statusCorrida,
      statusCorridaMonth,
      motoristas,
      dashBoardInfos,
      dataSelecionada,
      dataSelecionadaMotoristas,
      selectStatusDashboardInfos,
      qtdeFormaPagamento,
      totalizadores,
      motoristasOnline,
      dadosOnline,
      tiposCorridas,
      openModal
    } = this.state

    let marcadores = []
    let dados = {}

    for (let index = 0; index < this.state.motoristasOnline.length; index++) {
      const element = this.state.motoristasOnline[index]
      let cidade = element.dados ? element.dados.cidadeatuacao : ''
      if (!cidade) {
        cidade = ''
      }
      if (cidade.trim() == -'') {
        cidade = '-'
      }
      cidade = cidade.toUpperCase().trim()
      if (!dados[cidade]) {
        dados[cidade] = { cidade: cidade, livre: 0, ocupado: 0, total: 0 }
      }
      let d = dados[cidade]
      d.total = d.total + 1
      if (element.livre) {
        d.livre = d.livre + 1
      } else {
        d.ocupado = d.ocupado + 1
      }
      dados[cidade] = d
    }

    let dadosArray = Object.keys(dados).map((i) => dados[i])
    dadosArray.sort((a, b) => {
      return +(a.cidade > b.cidade) || +(a.cidade === b.cidade) - 1
    })

    console.log('*******Motortistas Ativos', this.state.infosMotoristas)

    return (
      <Styled.CustomLayout>
        <Content style={{ height: '100vh' }}>
          {/* ------ Painel principal nao Colapsavel com Totalizadores -------- 
          <Styled.CustomCard title={'Totais'}>
            <Styled.CustomRow>
              <Col>
                <Styled.CustomTotCard hoverable bordered>
                  <h5>Solicitações nos últimos 30 dias: </h5>
                  <div className='div-interna-customcard'>
                    {totalizadores.quantidade_30_dias}
                  </div>
                </Styled.CustomTotCard>
              </Col>
              <Col>
                <Styled.CustomTotCard hoverable bordered>
                  <h5>Solicitações nas últimas 24 horas: </h5>
                  <div className='div-interna-customcard'>
                    {totalizadores.quantidade_24_horas}
                  </div>
                </Styled.CustomTotCard>
              </Col>
              <Col>
                <Styled.CustomTotCard hoverable bordered>
                  <h5>Motoristas Online</h5>
                  <div className='div-interna-customcard'>
                    {dadosOnline.length === 0 ? 0 : dadosOnline}
                  </div>
                  {dadosOnline.length !== 0 && (
                    <div>
                      <Button
                        type='primary'
                        style={{
                          width: '100%',
                          position: 'relative',
                          bottom: 0
                        }}
                        onClick={this.handleOpenModal}
                      >
                        Info
                      </Button>
                      <Modal
                        open={this.state.openModal}
                        footer={null}
                        onCancel={this.handleCloseModal}
                        getContainer={() => document.body}
                      >
                        <Row style={{ borderBottom: '1px solid black' }}>
                          <Col sm={7}> </Col>
                          <Col sm={1}>L</Col>
                          <Col sm={1}>O</Col>
                          <Col sm={1}>T</Col>
                        </Row>
                        {dadosArray.map((a) => {
                          return (
                            <Row style={{ borderBottom: '1px solid black' }}>
                              <Col sm={7}>{a.cidade.toUpperCase()}</Col>
                              <Col sm={1}>{a.livre}</Col>
                              <Col sm={1}>{a.ocupado}</Col>
                              <Col sm={1}>{a.total}</Col>
                            </Row>
                          )
                        })}
                      </Modal>
                    </div>
                  )}
                </Styled.CustomTotCard>
              </Col>
            </Styled.CustomRow>
          </Styled.CustomCard>

          {(tiposCorridas.length !== 0 || tiposCorridas !== 0) && (
            <Styled.CustomCard title={'Últimas 24 horas'}>
              <Styled.CustomRow>
                {this.state.tiposCorridas.map((item, index) => {
                  return (
                    <Col key={index}>
                      <Styled.CustomTotCard
                        hoverable
                        bordered
                        background={
                          this.props.central && this.props.central.aplicativocor
                            ? this.props.central.aplicativocor.background
                            : 'lightgrey'
                        }
                        color={
                          this.props.central && this.props.central.aplicativocor
                            ? this.props.central.aplicativocor.foreground
                            : 'black'
                        }
                      >
                        <h5>{item.descricao}</h5>
                        <div className='div-interna-customcard'>
                          {item.past_24_hours}
                        </div>
                      </Styled.CustomTotCard>
                    </Col>
                  )
                })}
              </Styled.CustomRow>
            </Styled.CustomCard>
          )}
          {(tiposCorridas.length !== 0 || tiposCorridas !== 0) && (
            <Styled.CustomCard title={'Últimos 30 dias'}>
              <Styled.CustomRow>
                {this.state.tiposCorridas.map((item, index) => {
                  return (
                    <Col key={index}>
                      <Styled.CustomTotCard
                        hoverable
                        bordered
                        background={
                          this.props.central && this.props.central.aplicativocor
                            ? this.props.central.aplicativocor.background
                            : 'lightgrey'
                        }
                        color={
                          this.props.central && this.props.central.aplicativocor
                            ? this.props.central.aplicativocor.foreground
                            : 'black'
                        }
                      >
                        <h5>{item.descricao}</h5>
                        <div className='div-interna-customcard'>
                          {item.past_30_days}
                        </div>
                      </Styled.CustomTotCard>
                    </Col>
                  )
                })}
              </Styled.CustomRow>
            </Styled.CustomCard>
          )} */}

          <Collapse
            style={{
              background: '#fff',
              overflow: 'hidden',
              objectFit: 'cover',
              marginTop: '5px'
            }}
            onClick={() => {
              this.getDriversDrives()
              this.getStatusCorridas()
              this.getQtdeFormaPagamento()
              this.getDriversInfos()
              this.getStatusCorridasMonth()
            }}
            defaultActiveKey={['1', '2', '3', '4', '5']}
          >
            {/* ----- PAINEL 01 -----  */}

            {/*<Panel
              header='Status das Solicitações nas últimas 24 horas'
              key={1}
              style={panelStyle}
            >
              <Styled.DivPrincipal>
                <Row justify='space-around' style={{ backgroundColor: '#fff' }}>
                  {statusCorrida.length !== 0 ? (
                    statusCorrida.map((item, index) => (
                      <Col
                        key={index}
                        style={{
                          marginBottom: '10px',
                          textAlign: 'center',
                          width: '250px'
                        }}
                      >
                        <Card
                          hoverable
                          headStyle={{ backgroundColor: '#a5f79c' }}
                          title={item.status.toUpperCase()}
                          bordered={false}
                          style={cardStyle}
                          bodyStyle={{ backgroundColor: '#7daffa' }}
                        >
                          <Styled.CardBodyStyle>
                            {item.status === 'Canceladas' ? (
                              <div>{`Quantidade: ${item.quantidade}`}</div>
                            ) : (
                              <div>
                                <div>{`Quantidade: ${item.quantidade}`}</div>
                                
                              </div>
                            )}
                          </Styled.CardBodyStyle>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Card hoverable style={cardStyleOff} bordered>
                      Sem informações
                    </Card>
                  )}
                </Row>
              </Styled.DivPrincipal>
            </Panel>*/}

            {/* ----- PAINEL 02 -----  */}

            <Panel
              header='Status das Solicitações por mês'
              key={2}
              style={panelStyle}
            >
              {!dataSelecionada && (
                <Divider
                  orientationMargin='2'
                  orientation='left'
                  style={{ fontWeight: 'bold' }}
                >
                  Selecione um mês
                </Divider>
              )}

              <ConfigProvider locale={'pt_BR'}>
                <DatePicker
                  picker='month'
                  placeholder={dayjs().format('MMMM/YYYY')}
                  format={'YYYY-MM-DD'}
                  inputReadOnly
                  onChange={(date, dateString) => {
                    this.getStatusCorridasMonth(dateString)
                    this.setState({
                      dataSelecionada: dateString
                    })
                  }}
                />
              </ConfigProvider>

              <Divider
                orientationMargin='2'
                orientation='left'
                style={{ fontWeight: 'bold' }}
              >
                {dataSelecionada && dataSelecionada.length !== 0
                  ? `Solicitações realizadas no mês de ${dayjs(
                      dataSelecionada
                    ).format('MMMM')}`
                  : ''}
              </Divider>

              <Row justify='space-around' style={{ backgroundColor: '#fff' }}>
                {statusCorridaMonth !== null ? (
                  statusCorridaMonth.map((item, index) => (
                    <Col
                      key={index}
                      style={{
                        marginBottom: '10px',
                        textAlign: 'center',
                        width: '250px'
                      }}
                    >
                      <Card
                        hoverable
                        headStyle={{ backgroundColor: '#a5f79c' }}
                        title={item.status.toUpperCase()}
                        bordered={false}
                        bodyStyle={{ backgroundColor: '#7daffa' }}
                      >
                        {item.status === 'Canceladas' ? (
                          <div>{`Quantidade: ${item.quantidade}`}</div>
                        ) : (
                          <div>
                            <div>{`Quantidade: ${item.quantidade}`}</div>
                            {/* <div>{`Total: ${item.total}`}</div> */}
                          </div>
                        )}
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Card hoverable style={cardStyleOff} bordered>
                    Sem informações para o mês selecionado
                  </Card>
                )}
              </Row>
            </Panel>

            {/* ----- PAINEL 03 -----  */}

            <Panel header='Motoristas' key={3} style={panelStyle}>
              {Object.entries(this.state.infosMotoristas).length !== 0 && (
                <Row
                  style={{
                    justifyContent:
                      this.state.infosMotoristas.motoristas_ativos !== 0 &&
                      this.state.infosMotoristas.motoristas_inativos !== 0
                        ? 'space-evenly'
                        : 'center'
                  }}
                >
                  {this.state.infosMotoristas.motoristas_ativos !== 0 && (
                    <Col span={8}>
                      <Card
                        hoverable
                        title='Motoristas Ativos'
                        bordered={false}
                        style={{
                          margin: '15px',
                          textAlign: 'center',
                          width: '230px'
                        }}
                        bodyStyle={{ backgroundColor: '#affa7d' }}
                      >
                        <Styled.CardBodyStyle>
                          {this.state.infosMotoristas.motoristas_ativos}
                        </Styled.CardBodyStyle>
                      </Card>
                    </Col>
                  )}
                  {this.state.infosMotoristas.motoristas_inativos !== 0 && (
                    <Col span={8}>
                      <Card
                        hoverable
                        title='Motoristas Inativos'
                        bordered={false}
                        style={{
                          margin: '15px',
                          textAlign: 'center',
                          width: '230px'
                        }}
                        bodyStyle={{ backgroundColor: '#f5594e' }}
                      >
                        <Styled.CardBodyStyle>
                          {this.state.infosMotoristas.motoristas_inativos}
                        </Styled.CardBodyStyle>
                      </Card>
                    </Col>
                  )}
                </Row>
              )}
              {motoristas !== null ? (
                <div>
                  {!dataSelecionadaMotoristas && (
                    <Divider
                      orientationMargin='2'
                      orientation='left'
                      style={{ fontWeight: 'bold' }}
                    >
                      Selecione um mês
                    </Divider>
                  )}
                  <DatePicker
                    picker='month'
                    placeholder={dayjs().format('MMMM/YYYY')}
                    format={'YYYY-MM-DD'}
                    inputReadOnly
                    onChange={(date, dateString) => {
                      this.getDriversDrives(dateString)
                      this.setState({ dataSelecionadaMotoristas: dateString })
                    }}
                  />
                  <Divider
                    orientationMargin='2'
                    orientation='left'
                    style={{ fontWeight: 'bold' }}
                  >
                    {dataSelecionadaMotoristas &&
                    dataSelecionadaMotoristas.length !== 0
                      ? `Corridas/Motoristas no mês: ${dayjs(
                          dataSelecionadaMotoristas
                        ).format('MMMM')}`
                      : ''}
                  </Divider>

                  <Table dataSource={motoristas} columns={colunasMotoristas} />
                  <Button
                    type='primary'
                    onClick={() => {
                      const excel = new Excel()
                      excel
                        .addSheet('Solicitações_Motoristas')
                        .addColumns(colunasMotoristas)
                        .addDataSource(motoristas)
                        .saveAs('Solicitações_por_Motoristas_no_Mes.xlsx')
                    }}
                  >
                    Exportar para Excel
                  </Button>
                </div>
              ) : (
                <div>
                  {!dataSelecionadaMotoristas && (
                    <Divider
                      orientationMargin='2'
                      orientation='left'
                      style={{ fontWeight: 'bold' }}
                    >
                      Selecione um mês
                    </Divider>
                  )}
                  <DatePicker
                    picker='month'
                    placeholder={dayjs().format('MMMM/YYYY')}
                    format={'YYYY-MM-DD'}
                    inputReadOnly
                    onChange={(date, dateString) => {
                      this.getDriversDrives(dateString)
                      this.setState({ dataSelecionadaMotoristas: dateString })
                    }}
                  />
                  <Divider
                    orientationMargin='2'
                    orientation='left'
                    style={{ fontWeight: 'bold' }}
                  >
                    {dataSelecionadaMotoristas &&
                    dataSelecionadaMotoristas.length !== 0
                      ? `Corridas/Motoristas no mês: ${dayjs(
                          dataSelecionadaMotoristas
                        ).format('MMMM')}`
                      : ''}
                  </Divider>

                  <Card hoverable style={cardStyleOff} bordered>
                    Sem informações para o mês selecionado
                  </Card>
                </div>
              )}
            </Panel>

            {/* ----- PAINEL 04 -----  */}

            <Panel
              header={`Cliente: ${this.props.centralName}`}
              key={4}
              style={panelStyle}
            >
              <Select
                onChange={this.handleSelectChangeDashboardInfos}
                autoClearSearchValue={true}
                placeholder={'Selecione o status'}
                style={{ margin: '5px', width: '150px' }}
              >
                <Option value='FINALIZADA'>FINALIZADAS</Option>
                <Option value='CANCELADA'>CANCELADAS</Option>
                <Option value='AGENDADA'>AGENDADAS</Option>
              </Select>

              {selectStatusDashboardInfos === 'CANCELADA' ? (
                <div>
                  <Table
                    columns={colunasClienteCanceladas}
                    dataSource={dashBoardInfos}
                    scroll
                  />
                  {dashBoardInfos.length !== 0 && (
                    <Button
                      type='primary'
                      onClick={() => {
                        const excel = new Excel()
                        excel
                          .addSheet('Solicitações_Canceladas')
                          .addColumns(colunasClienteCanceladas)
                          .addDataSource(dashBoardInfos)
                          .saveAs('Solicitações_Canceladas.xlsx')
                      }}
                    >
                      Exportar para Excel
                    </Button>
                  )}
                </div>
              ) : selectStatusDashboardInfos === 'AGENDADA' ? (
                <div>
                  <Table
                    columns={colunasClienteAgendadas}
                    dataSource={dashBoardInfos}
                    scroll
                  />
                  {dashBoardInfos.length !== 0 && (
                    <Button
                      type='primary'
                      onClick={() => {
                        const excel = new Excel()
                        excel
                          .addSheet('Corridas_Agendadas')
                          .addColumns(colunasClienteAgendadas)
                          .addDataSource(dashBoardInfos)
                          .saveAs('Corridas_Agendadas.xlsx')
                      }}
                    >
                      Exportar para Excel
                    </Button>
                  )}
                </div>
              ) : (
                selectStatusDashboardInfos === 'FINALIZADA' && (
                  <div>
                    <Table
                      columns={colunasCliente}
                      dataSource={dashBoardInfos}
                      scroll
                    />
                    {dashBoardInfos.length !== 0 && (
                      <Button
                        type='primary'
                        onClick={() => {
                          const excel = new Excel()
                          excel
                            .addSheet('Solicitações_Finalizadas')
                            .addColumns(colunasCliente)
                            .addDataSource(dashBoardInfos)
                            .saveAs('Solicitações_Finalizadas.xlsx')
                        }}
                      >
                        Exportar para Excel
                      </Button>
                    )}
                  </div>
                )
              )}
            </Panel>

            {/* ----- PAINEL 05 -----  */}

            {/* <Panel
              header='Formas de Pagamento nas últimas 24 horas'
              key={5}
              style={panelStyle}
              forceRender={true}
            >
              <Row gutter={[16, 16]} justify='space-between'>
                {this.state.qtdeFormaPagamento.length !== 0 ? (
                  this.state.qtdeFormaPagamento.map((item, index) => {
                    return (
                      <Col key={index} span={6} style={{ textAlign: 'center' }}>
                        <Card
                          hoverable
                          headStyle={{ backgroundColor: '#a5f79c' }}
                          title={item.formaPagamentoDescricao}
                          bordered={false}
                          style={cardStyle}
                          bodyStyle={{ backgroundColor: '#7daffa' }}
                        >
                          <Styled.CardBodyStyle>
                            <div>
                              Quantidade: {item.quantidade}
                              <Divider
                              plain
                              style={{
                                fontWeight: 'bold'
                              }}
                            >
                              Total Faturado:
                            </Divider>
                            {item.total}
                            </div>
                          </Styled.CardBodyStyle>
                        </Card>
                      </Col>
                    )
                  })
                ) : (
                  <Card hoverable style={cardStyleOff} bordered>
                    Sem informações
                  </Card>
                )}
              </Row>
            </Panel> */}
          </Collapse>
        </Content>
      </Styled.CustomLayout>
    )
  }
}
