import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import { Row, OverlayTrigger, Popover, Image, Checkbox, Modal, Breadcrumb, Button, Form, FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import moment from 'moment';
import ReactTable from "react-table";
import "react-table/react-table.css";

class FaturasCentral extends Component {

  constructor(props) {
    super(props);
    this.state = {
      corridas: false,
      corridalista: [],
      motoristas: false,
      motoristaslista: [],
    }
    this.webservice = new WS();
  }

  mirrorState(key) {
    this.setState(key);
  }
  outras(p) {
    console.log('outras', p)
  }
  async corridas(p, after) {
    console.log('corridas 1', p)
    console.log('corridas 2', p.corridas)
    let corridas = await this.webservice.getListaFaturas(p.id,'corridas')
    this.setState({
      corridas: true,
      motoristas: false,
      corridalista: corridas,
    })
  }
  render() {
    return (<div>
    <Row>
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Fatura",
          tipo_plural: "Vaturas",
          href: "faturamentocentral",
          titulo: "Cadastro de Faturas",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'faturamentocentral',
            list: true,
            save: true,
            edit: true,
            delete: false,
            listSub: true,
            outras: [
            {icone: 'FaList', tamanho: 30, cor: 'blue', funcao:this.corridas.bind(this)}],
            defaultorder: [{ id: "status" }, { id: "datavencimento" }],
            defaultfilter: [{id: "status", value: "0"}],
          },
          subtipos: [
            {tipo: 'status',p:'oarray', array:[{ txt: 'Nova', id: 0 },
            
            { txt: 'Pendente', id: 1 },
            { txt: 'Paga', id: 3 },
            { txt: 'Cancelada', id: 9 }]},
          ],
          campos: [
            {
              ref: "central_id",
              label: "Central",
              type: "justlabel",
            },
            {
              ref: "valor",
              label: "Valor",
              type: "currencydisabled",
            },
            {
              ref: "datavencimento",
              label: "Vencimento",
              type: "datedisabled",
            },
            {
              ref: "numeronf",
              label: "Nota Fiscal",
              type: "text"
            },
            {
              ref: "status",
              label: "Situação",
              type: "select",
              listtype:'status',
              search: false,
            },
            {
              ref: "dadosabertura",
              label: "body",
              type: "jsonlist",
            },
          ],
          botoes: [

          ],
          table: {
            colunas: [
              {
                Header: "Razão Social",
                accessor: "voucher_empresa_razaosocial",
              },
              { 
                Header: "No.Fatura",
                accessor: "numerofaturamento",
              },
              {
                Header: "Vencimento",
                accessor: "datavencimento",
                Cell: row => {
                  return (
                    <div>
                      {moment(row.original.datavencimento).format("DD/MM/YYYY")}
                    </div>
                  )
                }
              },                            
              {
                Header: "Valor",
                id: "valor",
                accessor: d => <NumberFormat value={Number(d.valor)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />,
              },
              {
                Header: "Nota Fiscal",
                accessor: "numeronf",
              },
              {
                Header: "Situação",
                accessor: "status",
                width: 150,
                Cell: row => {
                  return (<div>{row.original.status === 0 ? 'Nova' : row.original.status === 1 ? 'Pendente' : row.original.status === 3 ? 'Pago' : row.original.status === 9 ? 'Cancelada' : '-'}</div>)
                },
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : 0}
                  >
                    <option value={0}>Nova</option>
                    <option value={1}>Pendente</option>
                    <option value={3}>Pago</option>
                    <option value={9}>Cancelada</option>
                  </select>
              },
              // {
              //   Header: "Corridas",
              //   accessor: "corridas",
              //   width: 150,
              //   Cell: row => {
              //     return (<div>{row.original.corridas.length}</div>)
              //   },
              // },
            ],
          }
        }}
      /></Row>
      {this.state.corridas &&
      <Row><Col sm={1}></Col><Col sm={10}>
        <ReactTable
            loading= {false}
            showPagination= {false}
            data={this.state.corridalista}
            defaultPageSize={10}
            pageSize={this.state.corridalista.length}
            columns={[
              {
                columns:
                  [
                    { Header: "Data", accessor: d => moment(d.t12_dthr_corrida).format('DD/MM/YYYY HH:mm'), id: "t12_dthr_corrida"},
                    { Header: "Origem", accessor: "origem"},
                    { Header: "Motorista", accessor: "motoristanome"},
                    { Header: "Placa", accessor: "placa"},
                    { Header: 'Central', accessor: 'centralpegoustr'},
                    { Header: "Valor", id: "valorcalculado", accessor: d => <NumberFormat value={Number(d.valorcalculado)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />, },
                  ]
              }
            ]}
          /></Col><Col sm={1}></Col>
      </Row>}
      </div>
    );
  }
}
export default FaturasCentral;
//              {row.original.statusnf === 0 ? 'Nova' : row.original.statusnf === 1 ? 'Pendente' : row.original.statusnf === 3 ? 'Pago' : row.original.statusnf === 9 ? 'Cancelada' : ''}
