import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";

class GOpivot extends Component {
  constructor(props){
    super(props);

    this.state = {
      val: this.props.val,
      loaded: false
    }
  }
  componentDidMount() {

  }
  renderTitulo() {
    var tmp = [];
    for (var i = 0; i < this.props.dias; i++) {
      tmp.push(i);
    }
    return (
      <tr>
        <th>
          Motorista
        </th>
        <th>
          CPF
        </th>
        <th>
          Tipo
        </th>
        {tmp.map((a, b) => { return (<th>{a+1}</th>)})}
        <th>
          Total
        </th>
      </tr>
    )
  }
  renderLinha(o) {
    let tmp = [];
    for (var i = 0; i < this.props.dias; i++) {
      tmp.push(i);
    }
    return (
      <React.Fragment>
        <tr>
          <td rowSpan={2}>{o.nome}</td>
          <td rowSpan={2}>{o.cpf}</td>
          <td>Valor</td>
          {tmp.map((a, b) => { return (<td>{o[a+1].v.toFixed(2)}</td>)})}
          <td>{o.tv.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Qtd</td>
          {tmp.map((a, b) => { return (<td>{o[a+1].c}</td>)})}
          <td>{o.tc}</td>
        </tr>
      </React.Fragment>
    )
  }
  colunas() {
    let col = [];
    col.push({Header: "Nome", accessor: 'nome'});
    col.push({Header: "CPF", accessor: 'cpf'});
    for (let i = 1; i <= this.props.dias; i++) {
      col.push({Header: (i).toString(), 
      Cell: row => {
        
        let k = i.toString();
        //console.log(k, Object.keys(row.original));
        return (
          <div>
          {row.original[k] &&
          <div>{row.original[k].v.toFixed(2).toString().replace('.',',')}</div>
          }
          {row.original[k] &&
          <div>{row.original[k].c}</div>
          }
        </div>
        )
      }
    })
  }
    col.push({Header: "Total", 
      Cell: row => (
        <div>
          <div>{row.original.tv.toFixed(2).toString().replace('.',',')}</div>
          <div>{row.original.tc}</div>
        </div>
      )})
    
    return col;
  }
  render() {
    console.log('this.props.val',this.props.val)
    return (<div>
      {this.props.val &&
      <div>
        {this.props.val.map((a, i) => {
            return (<div key={a.centralid}>
              <h3>{a.central}</h3>
              <ReactTable
                  previousText='Anterior'
                  nextText='Próxima'
                  loadingText='Carregando...'
                  noDataText='Nenhum dado encontado'
                  pageText='Página'
                  ofText='de'
                  rowsText='linhas'
                  defaultPageSize={5}
                  data={a.org}
                  columns={this.colunas()}
                  className="-striped -highlight"
                />
              </div>)
          })
        }</div>
      }
      </div>)
  }
}
export default GOpivot;
