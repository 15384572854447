import WS from '../services/webservice';
import React, { Component } from 'react';
import { Modal, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';

class DadosIUGU extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cartao: '',
      nome: '',
      cvv: '',
      mes: '',
      ano: '',
      mesAno: '',
      cpf: '',
      email: '',
    }

    this.webservice = new WS();
  }


  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={() => this.props.modalHide(true)}
        container={this}
        aria-labelledby="contained-modal-title"
      >
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Número do Cartão</ControlLabel>
            <InputMask
              className="form-control"
              mask={"9999 9999 9999 9999"}
              maskChar="_"
              value={this.state.cartao}
              onChange={e => { this.setState({ cartao: e.target.value }) }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Mês/Ano do vencimento</ControlLabel>
            <InputMask
              className="form-control"
              mask={"12/99"}
              maskChar="_"
              formatChars={this.state.mesAno[0] === "1" ? { '1': '[0-1]', '2': '[0-2]', '9' : '[0-9]' } : { '1': '[0-1]', '2': '[0-9]', '9' : '[0-9]' }}
              value={this.state.mesAno}
              onChange={e => { this.setState({ mesAno: e.target.value }) }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Nome do Titular do Cartão</ControlLabel>
            <FormControl
              type="text"
              placeholder=""
              value={this.state.nome}
              onChange={e => { this.setState({ nome: e.target.value }) }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>CPF do Titular do Cartão</ControlLabel>
            <InputMask
              className="form-control"
              mask={"999.999.999-99"}
              maskChar="_"
              value={this.state.cpf}
              onChange={e => {
                this.setState({ cpf: e.target.value })
              }}
            />
          </FormGroup>
          {/* <FormGroup>
            <ControlLabel>Ano</ControlLabel>
            <InputMask
              className="form-control"
              mask={"2099"}
              maskChar="_"
              value={this.state.ano}
              onChange={e => {
                this.setState({ ano: e.target.value })
              }}
            />
          </FormGroup> */}
          <FormGroup>
            <ControlLabel>CVV - código atrás do cartão</ControlLabel>
            <InputMask
              className="form-control"
              mask={"999"}
              maskChar="_"
              value={this.state.cvv}
              onChange={e => {
                this.setState({ cvv: e.target.value })
              }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>E-mail</ControlLabel>
            <FormControl
              type="text"
              placeholder=""
              value={this.state.email}
              onChange={e => { this.setState({ email: e.target.value }) }}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              let split = this.state.mesAno.split('/');
              let mes = split[0];
              let ano = '20' + split[1];

              this.props.dialogPai.resetOptions();

              if (this.state.cpf === '' || this.state.cpf.replace(/\D/g, '').length < 11) {
                this.props.dialog.showAlert('Por favor preencha o CPF corretamente!');
                return;
              }
              if (this.state.cartao === '' || this.state.cartao.replace(/\D/g, '').length < 16) {
                this.props.dialog.showAlert('Por favor preencha o número do cartão corretamente!');
                return;
              }
              if (this.state.nome === '' || this.state.nome.indexOf(' ') <= -1 || this.state.nome.split(' ')[1].length <= 0) {
                this.props.dialog.showAlert('Por favor preencha o nome do cartão corretamente!');
                return;
              }
              if (mes === '' || mes.replace(/\D/g, '').length < 2) {
                this.props.dialog.showAlert('Por favor preencha o mês corretamente!');
                return;
              }
              if (ano === '' || ano.replace(/\D/g, '').length < 4) {
                this.props.dialog.showAlert('Por favor preencha o ano corretamente!');
                return;
              }
              if (this.state.cvv === '' || this.state.cvv.replace(/\D/g, '').length < 3) {
                this.props.dialog.showAlert('Por favor preencha o CVV corretamente!');
                return;
              }
              if (this.state.email === '' || this.state.email.indexOf('@') <= -1 || this.state.email.indexOf('.') <= -1) {
                this.props.dialog.showAlert('Por favor preencha o email corretamente!');
                return;
              }
              let nomeSplit = this.state.nome.split(' ');
              let dados = {
                number: this.state.cartao.replace(/\D/g, ''),
                verification_value: this.state.cvv,
                first_name: nomeSplit[0],
                last_name: nomeSplit[nomeSplit.length - 1],
                month: mes,
                year: ano,
              }

              // this.webservice.criarTokenIugu(dados, false).then(result => {
              //   console.log(result);
              // })

              this.webservice.enviarDadosIugu(dados, window.location.href.indexOf("localhost") > -1 || window.location.href.indexOf("staging") > -1 ? false : true).then(result => {
                console.log(result);
                if (result.errors) {
                  if (result.errors.number) {
                    this.props.dialog.showAlert('Número ' + result.errors.number[0] + '!');
                  }
                } else {
                  this.setState({ tokenIugu: result }, () => {
                    this.props.setDadosIugu(dados, this.state.tokenIugu, this.state.cpf, this.state.email);
                    setTimeout(() => {
                      this.props.modalHide(false);
                      this.props.dialog.showAlert('Cartão salvo com sucesso na corrida!');
                    }, 2000)
                  });
                }
              })
            }}
          >Salvar</Button>
          <Button onClick={() => this.props.modalHide(true)}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default DadosIUGU;