import WS from '../services/webservice';
import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import { FormGroup, ControlLabel, FormControl, Button, Modal, Row, Col, DropdownButton, MenuItem } from 'react-bootstrap'
import moment from 'moment';
import './Corridas.css';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import Loader from 'react-loader';
import Dialog from 'react-bootstrap-dialog-v2';
import NumberFormat from 'react-number-format';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline, DirectionsRenderer } from "react-google-maps";
import polyline from '@mapbox/polyline';
import HistoricoDespacho from './HistoricoDespacho.js';
import GoMap from './GoMap.js';


var carro;
var cliente;
var corrida;
var pagto;
var motorista;
var central;
var solicitante;
var empresa;

var clear = false;


class DadosCorrida extends Component {

  static defaultProps = {
    center: { lat: -25.47, lng: -49.29 },
    zoom: 16
  };

  constructor(props) {
    super(props);

    this.state = {
      enviando: '',
      time: '',
      distance: '',
      centralizar: true,
      selected: null,
      center: { lat: -25.47, lng: -49.29 },
      zoom: 16,
      reclamacaoOptions: [],
      reclamacaoSelected: [],
      loaded: false,
      mensagemcarregada: [],
      op1: "1",
      op2: "1",
      op3: "1",
      opt1: false,
      opt2: false,
      motorista: null,
      tipo_msg: [],
      enviando2: null,
      respostas: [],
      tipoenvio: 5,
      gotRec: true,
      historicoDespacho: false,
      selection: '',
      value2: '',
      nivelOptions: [],
      nivelSelected: [],
      departamentoOptions: [],
      departamentoSelected: [],
      statusOptions: [],
      statusSelected: [],
    }

    this.getMensagens = this.getMensagens.bind(this);
    this.getPendentes = this.getPendentes.bind(this);
    this.sendQuestion = this.sendQuestion.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.salvaValor = this.salvaValor.bind(this);
    this.decodefunc = this.decodefunc.bind(this);
    this.handleCloseCancel = this.handleCloseCancel.bind(this);
    this.handleShowCancel = this.handleShowCancel.bind(this);
    this.handleChange = this.handleChange.bind(this)


    this.webservice = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  async componentDidMount() {
    clear = false;
    let res = await this.webservice.tipo_msg();
    let cancelamentos = await this.webservice.getCancel()
    this.setState({
      tipo_msg: res,
      tipo_cancel: cancelamentos,
    })
  }

  async getMensagens() {
    let result;
    //continaur aqui
    if (this.state.selected) {
      result = await this.webservice.fetch2('mensagens?cpf=' + motorista.cpf + '&dataHoraInicio=' + moment(this.state.selected.datahoracorrida).subtract(1, 'days').valueOf() + '&dataHoraFim=' + moment().add(1, 'days').valueOf(), {}, this.state.selected.dados_corrida.uuid.split(':')[0]);
    } else {
      result = await this.webservice.fetch2('mensagens?cpf=' + motorista.cpf + '&dataHoraInicio=' + moment().subtract(2, 'days').valueOf() + '&dataHoraFim=' + moment().add(1, 'days').valueOf(), {}, this.state.selected && this.state.selected.dados_corrida.uuid.split(':')[0]);
    }
    result = await result.json();
    this.setState({ mensagemcarregada: result });
  }

  salvaValor(e) {
    var value = e.target.value;
    this.setState({
      enviando: value
    })
  }

  async sendQuestion(texto, opcoes) {
    await this.webservice.fetch2('mensagens/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cpf: this.state.motorista.cpf,
        uuid: this.state.motorista.uuid333,
        at: this.state.motorista.porta,
        rt: this.state.motorista.rt,
        textolivre: texto,
        remetente: "monitor",
        alternativas: opcoes,
      })
    })
    this.timerId = setInterval(this.getMensagens, 16000);
    this.getMensagens();
  }

  async handleSend() {
    try {
      let altf = '';
      this.state.respostas.map((resp, i) => {
        if (i === 0) {
          altf += resp
        } else {
          altf += "," + resp
        }
        return null;
      })

      let tev = /<>/
      let nmsg = null
      if (this.state.tipoenvio === 3) {
        nmsg = this.state.enviando2.replace(tev, this.state.selected.endereco)
      }
      if (this.state.tipoenvio === 2) {
        nmsg = this.state.enviando2.replace(tev, this.state.enviando)
      }
      if (this.state.tipoenvio === 1) {
        nmsg = this.state.enviando2
      }
      console.log("motorista olha a pista", this.state.selected.dados_corrida.uuid.split(':')[0])
      await this.webservice.fetch2('mensagens/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cpf: this.state.motorista.cpf,
          uuid: this.state.motorista.uuid333,
          at: this.state.motorista.porta,
          rt: this.state.motorista.rt,
          textolivre: nmsg ? nmsg + "" : this.state.enviando,
          remetente: "monitor",
          alternativas: ((altf !== '') ? altf : " OK "),
        })
      }, this.state.selected.dados_corrida.uuid.split(':')[0])

      this.setState({ enviando: '', enviando2: null, tipoenvio: 5 });
      this.timerId = setInterval(this.getMensagens, 10000);
      this.getMensagens();
    } catch (err) {
      this.setState({ error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }

  decodefunc() {
    if (this.state.selected && this.state.selected.rota) {
      let testar = polyline.decode(this.state.selected.rota)
      let testando = testar.map(m => {
        return ({ lat: m[0], lng: m[1] })
      })
      console.log("testar", testando)
      this.setState({
        coords: testando
      })
    } else {
      this.setState({
        coords: null
      })
    }

  }

  async getPendentes() {
    await this.webservice.getOptions('cliente').then(result => cliente = result)
    await this.webservice.getOptions('corrida').then(result => corrida = result)
    await this.webservice.getOptions('pagto').then(result => pagto = result)
    await this.webservice.getOptions('carro').then(result => carro = result)
    await this.setState({ loaded: false, selected: null, motorista: null, reclamacao: '' });
    console.log("MEU TESTE AQUI", this.props.childProps)
    motorista = null;
    if (!this.props.motorista) {
      Dialog.resetOptions();

      await this.setState({ loaded: false, selected: null })

      let response = await this.webservice.getTipo('tipo_reclamacao', '', [], 1, 20, []);
      response = await response.json();

      let r = response.map((el) => {
        return {
          text: el.descricao,
          id: el.id,
        }
      });

      this.setState({
        reclamacaoOptions: r,
        nivelOptions: this.props.childProps.userAttrib.nivel,
        departamentoOptions: this.props.childProps.userAttrib.departamento,
        statusOptions: this.props.childProps.userAttrib.status,
        nivelSelected: [],
        departamentoSelected: [],
        statusSelected: [],
        reclamacaoSelected: [],
      });

      // let result = await this.webservice.fetch2('corrida?corridaPendente=10&qrl=10&qrv=10');
      // let cors = await result.json();
      // for (let i = 0; i < cors.length; i++) {
      //   if (cors[i].id === this.props.corrida.id && cors[i].pendente) {
      //     let corrida = await this.webservice.corridaByID(cors[i].id)
      //     await this.setState({ selected: corrida[0] })
      //     break;
      //   }
      // }
      if (!this.state.selected) { console.log('NAO TEM SELECIONADO'); await this.setState({ selected: this.props.corrida }, () => this.decodefunc())/* fazer aqui a chamada de função */ }
      central = null;
      if (this.state.selected && this.state.selected.central_id) {
        this.webservice.getCentral(this.state.selected.central_id).then(result => {
          central = result;
        })
      }
      solicitante = null;
      if (this.state.selected.voucher_func_solicitante) {
        this.webservice.getUser(this.state.selected.voucher_func_solicitante).then(result => {
          solicitante = result;
        })
      }
      empresa = null;
      if (this.state.selected.empresa) {
        this.webservice.getEmpresas(this.state.selected.empresa).then(result => {
          empresa = result;
        })
      }
      motorista = null;
      if (this.state.selected.motorista) {
        let result = await this.webservice.fetch2('motoristas/search?at=' + this.state.selected.motorista.porta, { headers: {} }, this.state.selected.central_id);
        motorista = await result.json();
        motorista.map((m, i) => {
          if (m.uuid === this.state.selected.uuid333) {
            motorista = m;
          }
          return null;
        })
        this.setState({ loaded: true })
      } else if (this.state.selected.t07_conceccao && !this.state.selected.t08_cpf_sec) {
        let result = await this.webservice.fetch2('motoristas/search?at=' + this.state.selected.t07_conceccao, { headers: {} }, this.state.selected.central_id);
        motorista = await result.json();
        motorista.map((m, i) => {
          if (m.uuid === this.state.selected.uuid333) {
            motorista = m;
          }
          return null;
        })
        this.setState({ loaded: true })
      } else if (this.state.selected.t08_cpf_sec && this.state.selected.t08_cpf_sec !== '') {
        let result = await this.webservice.fetch2('motoristas/search?cpf=' + this.state.selected.t08_cpf_sec + '&centrais=' + this.props.childProps.userAttrib.centralString.ids, { headers: {} }, this.state.selected.central_id);
        motorista = await result.json();
        let moto = false;
        for (let i = 0; i < motorista.length; i++) {
          if (motorista[i].uuid === this.state.selected.uuid333) {
            motorista = motorista[i];
            moto = true;
            break;
          }
        }
        if (!moto) { motorista = motorista[0]; }
        this.setState({ loaded: true })
      } else if (this.state.selected.t12_cpf_sec && this.state.selected.t12_cpf_sec !== '0') {
        let result = await this.webservice.fetch2('motoristas/search?cpf=' + this.state.selected.t12_cpf_sec + '&centrais=' + this.props.childProps.userAttrib.centralString.ids, { headers: {} }, this.state.selected.central_id);
        motorista = await result.json();
        let moto = false;
        for (let i = 0; i < motorista.length; i++) {
          if (motorista[i].online) {
            motorista = motorista[i];
            moto = true;
            break;
          }
        }
        if (!moto) { motorista = motorista[0]; }
        this.setState({ loaded: true })
      } else {
        this.setState({ loaded: true })
      }
      if (motorista) {
        //console.log('TEM MOTORSITA EH NOHS DASUHDUIAS', motorista)
        await this.setState({ motorista: motorista });
        this.getMensagens();
      }

      if (motorista && (motorista.latitude || motorista.longitude)) {
        //this.getCenter(this.state.selected.latitude, this.state.selected.longitude, motorista.latitude, motorista.longitude);
      }
      else {
        this.setState({ center: { lat: this.state.selected.latitude, lng: this.state.selected.longitude } })
      }
    } else {
      //console.log('TEM MOTORISTA NO PROPS')
      if (this.props.motorista.ultima_corrida) {
        let corridaMoto = await this.webservice.corridaByID(this.props.motorista.ultima_corrida);
        //console.log('ULTIMA CORRIDA', corridaMoto[0]);
        await this.setState({ loaded: false, selected: corridaMoto[0] });
      }
      let response = await this.webservice.getTipo('tipo_reclamacao', '', [], 1, 20, []);
      response = await response.json();

      let r = response.map((el) => {
        return {
          text: el.descricao,
          id: el.id,
        }
      });
      await this.setState({ reclamacaoOptions: r })

      motorista = this.props.motorista;
      await this.setState({ motorista: motorista });
      this.getMensagens();
      this.setState({
        reclamacaoOptions: r,
        nivelOptions: this.props.childProps.userAttrib.nivel,
        departamentoOptions: this.props.childProps.userAttrib.departamento,
        statusOptions: this.props.childProps.userAttrib.status,
        nivelSelected: [],
        departamentoSelected: [],
        statusSelected: [],
        reclamacaoSelected: [],
      });
      this.setState({ center: { lat: this.state.motorista.latitude, lng: this.state.motorista.longitude } })
      this.setState({ loaded: true })
    }
  }

  //Get the options from multiselect fields, put to an array and later save on DB(type of cars, payment, clients and runs)
  getSelectedOptionsValue(selectedOptions) {
    var values = [];

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(Number(selectedOptions[i].value));
    }

    return values;
  }

  sendReclamacao() {
    let dados = {
      nivel: this.state.nivelSelected[0],
      status: this.state.statusSelected[0],
      departamento: this.state.departamentoSelected[0],
    }

    if (this.props.motorista && !this.state.selected) {
      this.webservice.criarReclamacao(0, this.state.reclamacao, this.state.reclamacaoSelected[0], this.state.motorista.cpf, dados);
    } else if (this.state.motorista && this.state.selected) {
      this.webservice.criarReclamacao(this.state.selected.id || this.state.selected.t12_num_corrida, this.state.reclamacao, this.state.reclamacaoSelected[0], this.state.motorista.cpf, dados);
    } else if (this.state.selected && !this.state.motorista) {
      this.webservice.criarReclamacao(this.state.selected.id || this.state.selected.t12_num_corrida, this.state.reclamacao, this.state.reclamacaoSelected[0], 0, dados);
    }
    Dialog.resetOptions();
    this.dialog.show({
      body: 'Reclamação Gravada!',
      actions: [
        Dialog.OKAction(() => {
          this.props.onHide();
          this.setState({ gotRec: true });
        }),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  setDistance(directions) {
    this.setState({
      distance: directions.routes[0].legs[0].distance.text,
      time: directions.routes[0].legs[0].duration.text
    })
  }

  getReclamacoes() {
    this.webservice.getReclamacoes();
  }

  alterarReclamacao() {
    this.webservice.alterarReclamacao(this.state.selected.id || this.state.selected.t12_num_corrida, this.state.reclamacao, this.state.reclamacaoSelected[0], 24, 0);
  }

  deletarReclamacao() {
    this.webservice.deleteReclamacao(this.state.selected.id || this.state.selected.t12_num_corrida, this.state.reclamacao);
  }

  getReclamacaoCorrida() {
    this.webservice.getReclamacaoCorrida(this.state.selected.id || this.state.selected.t12_num_corrida, this.state.reclamacao);
  }

  openHistorico() {
    this.setState({ historicoDespacho: true });
  }

  hideHistorico() {
    this.setState({ historicoDespacho: false });
  }

  reenviar() {
    if (this.state.selected) {
      Dialog.setOptions({
        defaultOkLabel: 'Sim',
        defaultCancelLabel: 'Não',
        primaryClassName: 'btn-danger '
      })
      this.dialog.show({
        body: 'Deseja reenviar essa corrida?',
        actions: [
          Dialog.CancelAction(() => { }),
          Dialog.OKAction(() => {
            if (this.state.selected.id) {
              this.webservice.reenviarTaxi(this.state.selected.id, this.props.childProps.userAttrib.nome).then((retorno) => {
                if (retorno.ok === false) {
                  Dialog.resetOptions();
                  this.dialog.show({
                    body: 'Erro ao reenviar!',
                    actions: [Dialog.OKAction(() => { this.props.onHide() }),],
                    bsSize: 'small',
                    onHide: (dialog) => { dialog.hide() }
                  })
                } else {
                  Dialog.resetOptions();
                  this.dialog.show({
                    body: 'Corrida reenviada com sucesso!',
                    actions: [Dialog.OKAction(() => { this.props.onHide() }),],
                    bsSize: 'small',
                    onHide: (dialog) => { dialog.hide() }
                  })
                }
              });
            } else if (this.state.selected.t12_num_corrida) {
              this.webservice.reenviarTaxi(this.state.selected.t12_num_corrida, this.props.childProps.userAttrib.nome).then((retorno) => {
                if (retorno.ok === false) {
                  Dialog.resetOptions();
                  this.dialog && this.dialog.show({
                    body: 'Erro ao reenviar!',
                    actions: [Dialog.OKAction(() => { this.props.onHide() }),],
                    bsSize: 'small',
                    onHide: (dialog) => { dialog.hide() }
                  })
                } else {
                  Dialog.resetOptions();
                  this.dialog.show({
                    body: 'Corrida reenviada com sucesso!',
                    actions: [Dialog.OKAction(() => { this.props.onHide() }),],
                    bsSize: 'small',
                    onHide: (dialog) => { dialog.hide() }
                  })
                }
              });
            }
          }),
        ],
        bsSize: 'small',
        onHide: (dialog) => { dialog.hide() }
      })
    }
  }

  qrlMonitor() {
    if (this.state.selected) {
      Dialog.setOptions({
        defaultOkLabel: 'Sim',
        defaultCancelLabel: 'Não',
        primaryClassName: 'btn-danger '
      })
      this.dialog.show({
        body: 'Deseja dar QRL na corrida?',
        actions: [
          Dialog.CancelAction(() => { }),
          Dialog.OKAction(() => {
            if (this.state.selected.id) {
              this.webservice.qrlOp(this.state.selected.id, this.props.childProps.userAttrib.id).then(() => {
                Dialog.resetOptions();
                this.dialog.show({
                  body: 'QRL realizado com sucesso!',
                  actions: [Dialog.OKAction(() => { this.props.onHide() }),],
                  bsSize: 'small',
                  onHide: (dialog) => { dialog.hide() }
                })
              });
            } else if (this.state.selected.t12_num_corrida) {
              this.webservice.qrlOp(this.state.selected.t12_num_corrida, this.props.childProps.userAttrib.id).then(result => {
                Dialog.resetOptions();
                this.dialog.show({
                  body: 'QRL realizado com sucesso!',
                  actions: [Dialog.OKAction(() => { this.props.onHide() }),],
                  bsSize: 'small',
                  onHide: (dialog) => { dialog.hide() }
                })
              });
            }
          }),
        ],
        bsSize: 'small',
        onHide: (dialog) => { dialog.hide() }
      })
    }
  }

  qrvMonitor() {
    if (this.state.selected) {
      Dialog.setOptions({
        defaultOkLabel: 'Sim',
        defaultCancelLabel: 'Não',
        primaryClassName: 'btn-danger '
      })
      this.dialog.show({
        body: 'Deseja dar QRV na corrida?',
        actions: [
          Dialog.CancelAction(() => { }),
          Dialog.OKAction(() => {
            if (this.state.selected.id) {
              this.webservice.qrvOp(this.state.selected.id, this.props.childProps.userAttrib.nome).then(() => {
                Dialog.resetOptions();
                this.dialog.show({
                  body: 'QRL realizado com sucesso!',
                  actions: [Dialog.OKAction(() => { this.props.onHide() }),],
                  bsSize: 'small',
                  onHide: (dialog) => { dialog.hide() }
                })
              });
            } else if (this.state.selected.t12_num_corrida) {
              this.webservice.qrvOp(this.state.selected.t12_num_corrida, this.props.childProps.userAttrib.nome).then(() => {
                Dialog.resetOptions();
                this.dialog.show({
                  body: 'QRV realizado com sucesso!',
                  actions: [Dialog.OKAction(() => { this.props.onHide() }),],
                  bsSize: 'small',
                  onHide: (dialog) => { dialog.hide() }
                })
              });
            }
          }),
        ],
        bsSize: 'small',
        onHide: (dialog) => { dialog.hide() }
      })
    }
  }

  handleChange() {
    this.setState({ selection: this.inputEl.value });
  }

  handleShowCancel() {
    this.setState({
      showCancel: true,
    })
  }

  handleCloseCancel() {
    this.setState({
      showCancel: false,
      value2: '',
      selection: ''
    })
  }

  cancelaCorridaMonitor() {
    if (this.state.selected) {
      Dialog.setOptions({
        defaultOkLabel: 'Sim',
        defaultCancelLabel: 'Não',
        primaryClassName: 'btn-danger '
      })
      this.dialog.show({
        body: 'Você tem certeza?',
        actions: [
          Dialog.CancelAction(() => { }),
          Dialog.OKAction(() => {
            if (this.state.selected.id) {
              if (this.state.selection === '') {
                Dialog.resetOptions();
                this.dialog.showAlert('Por favor, escolha o motivo!')
                return;
              }
              if (this.state.value2 === '') {
                Dialog.resetOptions();
                this.dialog.showAlert('Por favor, preencha as observações!')
                return;
              }
              this.webservice.cancelTaxi(this.state.selected.id, this.props.childProps.userAttrib.nome, this.state.selected.central_id, this.state.value2, this.state.selection).then(() => {
                Dialog.resetOptions();
                this.dialog.show({
                  body: 'Corrida cancelada com sucesso!',
                  actions: [Dialog.OKAction(() => {
                    this.props.onHide();
                    this.handleCloseCancel();
                  }),],
                  bsSize: 'small',
                  onHide: (dialog) => {
                    dialog.hide();
                    this.handleCloseCancel();
                  }
                })
              });
            } else if (this.state.selected.t12_num_corrida) {
              if (this.state.selection === '') {
                Dialog.resetOptions();
                this.dialog.showAlert('Por favor, escolha o motivo!')
                return;
              }
              if (this.state.value2 === '') {
                Dialog.resetOptions();
                this.dialog.showAlert('Por favor, preencha as observações!')
                return;
              }
              this.webservice.cancelTaxi(this.state.selected.t12_num_corrida, this.props.childProps.userAttrib.nome, this.state.selected.central_id, this.state.value2, this.state.selection).then(() => {
                Dialog.resetOptions();
                this.dialog.show({
                  body: 'Corrida cancelada com sucesso!',
                  actions: [Dialog.OKAction(() => {
                    this.props.onHide();
                    this.handleCloseCancel();
                  }),],
                  bsSize: 'small',
                  onHide: (dialog) => {
                    this.handleCloseCancel();
                    dialog.hide();
                  }
                })
              });
            }
          }),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          this.handleCloseCancel();
          dialog.hide();
        }
      })
    }
  }

  render() {
    if (this.refs.reclamacao_type && !clear) { clear = true; this.refs.reclamacao_type.el.select2({ minimumResultsForSearch: -1 }); }

    let markers= []
    if (this.state.selected) {
      if (this.state.selected.latitude) {
        markers.push({ lat: this.state.selected.latitude, lng: this.state.selected.longitude, img_src: null, label: 'origem' })
      } else {
        if (this.state.selected.t12_lat) {
          markers.push({ lat: this.state.selected.t12_lat, lng: this.state.selected.t12_lon, img_src: null, label: 'origem' })
        }
      }
      if (this.state.selected.latitudedestino) {
        markers.push({ lat: this.state.selected.latitudedestino, lng: this.state.selected.longitudedestino, img_src: null, label: 'destinos' })
      } 
    }
                  
    return (
      <Modal
        keyboard
        show={this.props.show}
        onEnter={this.getPendentes}
        onExit={() => clearInterval(this.timerId)}
        onHide={() => { this.props.onHide(null) }}
        container={this}
        aria-labelledby="contained-modal-title"
        dialogClassName="dados-corrida-modal"
      >
        <Modal.Header closeButton>
          <Loader loaded={this.state.loaded}>
          </Loader>
          {this.state.selected &&
            <Modal.Title>Corrida {this.state.selected.id || this.state.selected.t12_num_corrida} da {central && central.nomefantasia}</Modal.Title>
          }
          {this.state.motorista && this.props.motorista && !this.state.selected &&
            <Modal.Title>{this.state.motorista.nome}</Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          <Dialog ref={(el) => { this.dialog = el }} />
          <Row ref={el => (this.componentRef = el)}>
            {motorista && this.props.motorista && !this.state.selected &&
              <Col xs={6} md={2} style={{ padding: 0, paddingLeft: 15 }}>
                <div className='data-box'>
                  <h4><strong>Motorista</strong></h4>
                  <p><strong>Status:</strong> {motorista && motorista.online ? 'Online' : 'Offline'}</p>
                  <p><strong>RT:</strong> {motorista && motorista.rt}</p>
                  <p><strong>Nome:</strong> {motorista && motorista.nome} </p>
                  <p><strong>Placa:</strong> {motorista && motorista.placa.replace(/(\w+[A-Z])(\d{4})/, "$1-$2")}</p>
                  <p><strong>Carro:</strong> {motorista && motorista.carro}</p>
                </div>
              </Col>
            }
            {this.state.selected &&
              <Col xs={6} md={2} style={{ padding: 0, paddingLeft: 15, height: "86vh", overflowY: 'scroll' }}>
                <div className='data-box'>
                  <h4><strong>Corrida</strong></h4>
                  {this.state.selected.t12_st_corrida &&
                    <div>
                      <p><strong>Status:</strong> {this.state.selected.t12_st_corrida === 'C' ? 'Cancelada' : this.state.selected.t12_st_corrida === 'M' ? 'Cancelada' : this.state.selected.datahoraagendamento || this.state.selected.t12_dthr_agendamento ? 'Corrida agendada' :
                        
                          this.state.selected.t12_st_corrida === 'N' ? 'Corrida não enviada' :
                            this.state.selected.t12_dthr_embarque === null ? 'Pendente QRL' :
                              this.state.selected.t12_dthr_dembarque === null ? 'Pendente QRV' :
                                'Corrida finalizada'}                        </p>
                    </div>
                  }
                  {this.state.selected.pendente &&
                    <div>
                      <p><strong>Status:</strong> Pendente</p>
                    </div>
                  }
                  {(this.state.selected.datahoraagendamento || this.state.selected.t12_dthr_agendamento) &&
                    <div><p><strong>Data Agendada:</strong> {moment(this.state.selected.datahoraagendamento || this.state.selected.t12_dthr_agendamento).format("DD/MM/YY HH:mm")}</p></div>
                  }
                  {this.state.selected.datahoracorrida &&
                    <div><p><strong>Data:</strong> {moment(this.state.selected.datahoracorrida).format("DD/MM/YY HH:mm")}</p></div>
                  }
                  {((this.state.selected.datahora || this.state.selected.t12_dthr_tele) && !this.state.selected.datahoraagendamento) &&
                    <div><p><strong>Data:</strong> {moment(this.state.selected.datahora || this.state.selected.t12_dthr_tele).format("DD/MM/YY HH:mm")}</p></div>
                  }

                  {!this.state.selected.t12_st_corrida &&
                    <div><p><strong>Endereço:</strong> {this.state.selected.endereco}</p></div>
                  }
                  {this.state.selected.t12_st_corrida &&
                    <div><p><strong>Endereço:</strong> {this.state.selected.endereco || this.state.selected.t12_endereco}, {this.state.selected.numero || this.state.selected.t12_numero}, {this.state.selected.t12_bairro}, {this.state.selected.cidade}, {this.state.selected.estado}</p></div>
                  }
                  {this.state.selected.t12_complemento && this.state.selected.t12_complemento !== '' &&
                    <div><p><strong>Complemento:</strong> {this.state.selected.t12_complemento}</p></div>
                  }

                  {this.state.selected.destinos && this.state.selected.destinos.length <= 0 && this.state.selected.taxi333$enderecodestino !== ", , " && this.state.selected.taxi333$enderecodestino !== ", " &&
                    <div><p><strong>Destino:</strong> {this.state.selected.taxi333$enderecodestino}, {this.state.selected.bairrodestino}, {this.state.selected.cidadedestino}, {this.state.selected.estadodestino}</p></div>
                  }

                  {this.state.selected.destinos && this.state.selected.destinos.map((d, i) => {
                    let index = i + 1;
                    return (
                      <div><p><strong>{'Destino ' + index + ' :'}</strong> {d.Address.shortAddress}</p></div>
                    );
                  })}

                  {this.state.selected.destinos && this.state.selected.destinos.length >= 0 && this.state.selected.taxi333$enderecodestino !== ", , " && this.state.selected.taxi333$enderecodestino !== ", " &&
                    <div><p><strong>Destino Final:</strong> {this.state.selected.taxi333$enderecodestino}, {this.state.selected.bairrodestino}, {this.state.selected.cidadedestino}, {this.state.selected.estadodestino}</p></div>
                  }

                  {this.state.selected.complementodestino && this.state.selected.complementodestino !== '' &&
                    <div><p><strong>Complemento:</strong> {this.state.selected.complementodestino}</p></div>
                  }

                  <div><p><strong>Cliente: </strong>{this.state.selected.cliente || this.state.selected.t12_nome}</p></div>

                  {this.state.selected.telefone
                    ?
                    <div><p><strong>Telefone: </strong>{this.state.selected.telefone.toString().length === 10 ? this.state.selected.telefone.toString().replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") : this.state.selected.telefone.toString().replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}</p></div>
                    :
                    this.state.selected.t12_fone &&
                    <div><p><strong>Telefone: </strong>{this.state.selected.t12_fone.toString().length === 10 ? this.state.selected.t12_fone.toString().replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") : this.state.selected.t12_fone.toString().replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}</p></div>
                  }

                  <div><p><strong>Atendente:</strong> {this.state.selected.atendente || this.state.selected.taxi333$atendente}</p></div>
                  {this.state.selected.dados_corrida &&
                    <div><p><strong>Valor Corrida: </strong> <NumberFormat value={this.state.selected.dados_corrida.valorcalculado} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} /></p></div>
                  }

                  {this.state.selected.dados_corrida && this.state.selected.dados_corrida.referencia_original_string &&
                    <div><p><strong>Referência:</strong> {this.state.selected.dados_corrida.referencia_original_string.split(' - ')[1]}</p></div>
                  }

                  {empresa &&
                    <div><p><strong>Empresa: </strong>{empresa.razaosocial}</p></div>
                  }

                  {solicitante &&
                    <div><p><strong>Solicitante: </strong>{solicitante.nome}</p></div>
                  }
                </div>
                {this.state.selected.t12_st_corrida === 'C' &&
                  <div className='data-box'>
                    <h4><strong>Cancelamento</strong></h4>
                    <p><strong>Status: </strong>{this.state.selected.t12_cd_st_cancelamento === 'C' ? 'Cancelada' :
                      this.state.selected.t12_cd_st_cancelamento === 'T' ? 'Reenviada' :
                        this.state.selected.t12_cd_st_cancelamento === 'H' ? 'Reenviada' :
                          this.state.selected.t12_cd_st_cancelamento === 'M' ? 'Cancelada' : ''}</p>
                    <p><strong>Por quem: </strong>{this.state.selected.atendente_cancelamento}</p>
                    <p><strong>Data: </strong>{this.state.selected.t12_dthr_deletous ? moment(this.state.selected.t12_dthr_deletous).format('DD/MM/YYYY') : 'Sem data!'}</p>
                    <p><strong>Hora: </strong>{this.state.selected.t12_dthr_deletous ? moment(this.state.selected.t12_dthr_deletous).format('HH:mm') : 'Sem hora!'}</p>
                    <p><strong>Motivo: </strong>{this.state.selected.tipocancelamento_string ? this.state.selected.tipocancelamento_string : 'Sem motivo!'}</p>
                    <p><strong>Obs: </strong>{this.state.selected.tipocancelamento_obs !== '' ? this.state.selected.tipocancelamento_obs : 'Sem observações!'}</p>
                  </div>
                }
                {this.state.selected.z_qtde_de_corridas !== null && this.state.selected.z_qtde_de_corridas > 1 &&
                  <div className='data-box'>
                    <h4><strong>Corrida Reenviada</strong></h4>
                    <div style={{ cursor: 'pointer', textDecorationLine: 'underline' }}
                      onClick={() => { this.props.onHide(this.state.selected.z_corrida_original) }}><p><strong>Ver Histórico</strong></p></div>
                  </div>
                }

                <div className='data-box'>
                  <h4><strong>Observações</strong></h4>
                  <p><strong>Motorista: </strong>{this.state.selected.observacoes || this.state.selected.t12_obs}</p>
                  <p><strong>Interna: </strong>{this.state.selected.coordenadas || this.state.selected.t12_coordenadas}</p>
                </div>

                <div className="data-box">
                  <h4><strong>Códigos</strong></h4>
                  <p><strong>Carro: </strong>
                    {carro.map((m, i) => {
                      let string = '';
                      for (let index = 0; index < this.state.selected.t12_cd_tp_carro_a.length; index++) {
                        if (m.id === this.state.selected.t12_cd_tp_carro_a[index]) {
                          if (index === 0) string += ' ' + m.text;
                          else string += ', ' + m.text;
                        }
                      }
                      return string;
                    })}
                  </p>
                  <p><strong>Cliente: </strong>
                    {cliente.map((m, i) => {
                      let string = '';
                      for (let index = 0; index < this.state.selected.t12_cd_tp_cliente_a.length; index++) {
                        if (m.id === this.state.selected.t12_cd_tp_cliente_a[index]) {
                          if (index === 0) string += ' ' + m.text;
                          else string += ', ' + m.text;
                        }
                      }
                      return string;
                    })}
                  </p>
                  <p><strong>Corrida: </strong>
                    {corrida.map((m, i) => {
                      let string = '';
                      for (let index = 0; index < this.state.selected.t12_cd_tp_corrida_a.length; index++) {
                        if (m.id === this.state.selected.t12_cd_tp_corrida_a[index]) {
                          if (index === 0) string += ' ' + m.text;
                          else string += ', ' + m.text;
                        }
                      }
                      return string;
                    })}
                  </p>
                  <p><strong>Pagamento: </strong>
                    {pagto.map((m, i) => {
                      let string = '';
                      for (let index = 0; index < this.state.selected.t12_cd_tp_pgto_a.length; index++) {
                        if (m.id === this.state.selected.t12_cd_tp_pgto_a[index]) {
                          if (index === 0) string += ' ' + m.text;
                          else string += ', ' + m.text;
                        }
                      }
                      return string;
                    })}
                  </p>
                </div>
                <div className='data-box'>
                  {console.log('tabelacomissao',this.state.selected.tabelacomissao )}
                  <h4><strong>Valor Calculado</strong></h4>
                    {this.state.selected.valorcalculado &&
                      <div><p><strong>Valor Corrida: </strong> R$ {this.state.selected.valorcalculado.toFixed(2).toString().replace('.',',')}</p></div>
                    }
                </div>
                <div className='data-box'>
                  <h4><strong>Motorista</strong></h4>
                  <p><strong>RT:</strong> {motorista && motorista.rt}</p>
                  <p><strong>Nome:</strong> {motorista && motorista.nome} </p>
                  {console.log('MOTORSITA', motorista)}
                  <p><strong>Placa:</strong> {motorista ? motorista.placa.replace(/(\w+[A-Z])(\d{4})/, "$1-$2") : ''}</p>
                  <p><strong>Carro:</strong> {motorista && motorista.carro}</p>
                </div>

                <div className='data-box'>
                  <h4><strong>Histórico</strong></h4>
                  <div><p><strong>Tempo:</strong> {this.state.selected.datahoraconfirmada && moment(this.state.selected.datahoraconfirmada).format("DD/MM/YY HH:mm")}  {this.state.selected.tempopercurso && this.state.selected.tempopercurso} minutos</p></div>
                  <div><p><strong>Em Frente:</strong> {this.state.selected.datahoraemfrente && moment(this.state.selected.datahoraemfrente).format("DD/MM/YY HH:mm")}</p></div>
                  <div><p><strong>QRL:</strong> {(this.state.selected.dataHoraQrl || this.state.selected.t12_dthr_embarque) && moment(this.state.selected.dataHoraQrl || this.state.selected.t12_dthr_embarque).format("DD/MM/YY HH:mm")}</p></div>
                  <div><p><strong>QRV:</strong> {(this.state.selected.dataHoraQrv || this.state.selected.t12_dthr_dembarque) && moment(this.state.selected.dataHoraQrv || this.state.selected.t12_dthr_dembarque).format("DD/MM/YY HH:mm")}</p></div>
                  {this.state.selected.atendente_qrv && <div><p><strong>QRV feito por: </strong> {this.state.selected.atendente_qrv}</p></div>}
                </div>
                
                {this.state.selected.star_avaliacao &&
                <div className='data-box'>
                  <h4><strong>Avaliação</strong></h4>
                  <div><p><strong>Geral:</strong> {this.state.selected.star_avaliacao}</p></div>
                  {this.state.selected.avadetalhes && <div><p><strong>Foto:</strong> {this.state.selected.avadetalhes.foto === 1 ? 'SIM' : 'NÃO'}</p></div>}
                  {this.state.selected.avadetalhes && <div><p><strong>Educação:</strong> {this.state.selected.avadetalhes.educacao}</p></div>}
                  {this.state.selected.avadetalhes && <div><p><strong>Limpeza:</strong> {this.state.selected.avadetalhes.limpeza}</p></div>}
                 <div><p><strong>Obs:</strong> {this.state.selected.star_coments}</p></div>
                </div>
                }
                <div className='data-box'>
                  <h4><strong>Motoristas que aceitaram</strong></h4>
                  {this.state.selected.dados_corrida &&
                    <div>
                      {this.state.selected.dados_corrida.corridaPendente &&
                        <div>
                          {this.state.selected.dados_corrida.corridaPendente.aceito &&
                            <div>
                              {this.state.selected.dados_corrida.corridaPendente.aceito.map(m => {
                                let dist = '?';
                                if (this.state.selected.dados_corrida.corridaPendente.todosMotoristasCompleto) {
                                  let mot = this.state.selected.dados_corrida.corridaPendente.todosMotoristasCompleto.find(x => x.key === m.uuid);
                                  if (mot) {
                                    dist = mot.distance ? mot.distance : 0;
                                  } else {
                                    mot = 0;
                                  }
                                }
                                let c1 = '';
                                let split = m.uuid.split(':');
                                this.props.childProps.userAttrib.centrais.map(c2 => {
                                  if (Number(split[0]) === c2.id) {
                                    c1 = c2;
                                  }
                                  return null;
                                })
                                return (<div>{m.motorista.at}-{m.motorista.nome} - {dist} m - {c1.nomefantasia}</div>)
                              })}</div>
                          }</div>
                      }</div>
                  }
                </div>
              </Col>
            }
            <Col xs={6} md={4} style={{ padding: 0, paddingLeft: 15 }}>
              {false && motorista &&
                <div className="panel">
                  <div className="panel-body" >
                    <ul className="chat">
                      {

                        this.state.mensagemcarregada.map(m => {
                          let classe = "right clearfix";
                          let classe2 = "pull-right text-muted";
                          let remetente = "Monitor";
                          let interna = true;
                          let url = "http://placehold.it/50/FA6F57/fff&text=OP";
                          if (m.remetente === 'Motorista') {
                            interna = false;
                            url = "http://placehold.it/50/55C1E7/fff&text=" + m.at;
                            classe = "left clearfix";
                            classe2 = "pull-left text-muted";
                            remetente = "Motorista AT " + m.at + " / RT " + m.rt;
                          }
                          return (
                            <li key={m.datahora} className={classe}>
                              {interna &&
                                <span className="chat-img pull-right">
                                  <img src={url} alt="User Avatar" className="img-circle" />
                                </span>}
                              {!interna &&
                                <span className="chat-img pull-left">
                                  <img src={url} alt="User Avatar" className="img-circle" />
                                </span>}

                              <div className="chat-body clearfix">
                                {interna &&
                                  <div className="header clearfix">
                                    <small className={classe2}>{moment(m.datahora).format("DD/MM/YY HH:mm")}</small>
                                    <strong className="pull-left primary-font">{remetente}</strong>
                                  </div>
                                }

                                {!interna &&

                                  <div className="header clearfix">
                                    <strong className="pull-right primary-font">{remetente}</strong>
                                    <small className={classe2}>{moment(m.datahora).format("DD/MM/YY HH:mm")}</small>
                                  </div>
                                }

                                <div className={classe}><p>{m.textolivre}</p></div>

                              </div>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                  <div className="panel-footer">
                    {this.state.enviando2 && <div>Mensagem selecionada {this.state.categoria}</div>}
                    <div className="input-group">

                      <input id="btn-input" style={{ height: 30 }} type="text" className="form-control input-sm" placeholder="Escreva sua mensagem..."
                        value={this.state.enviando} disabled={this.state.tipoenvio === 1 || this.state.tipoenvio === 3 ? true : false}
                        onChange={(v) => this.salvaValor(v)} onKeyDown={e => e.keyCode === 13 && this.state.enviando !== '' && this.handleSend(e) && e.preventDefault()} autoFocus
                      />
                      <span className="input-group-btn">
                        <button style={{ height: 30 }} disabled={this.state.enviando === '' ? true : false} className="btn btn-warning btn-sm" id="btn-chat" onClick={this.handleSend} >Enviar</button>
                      </span>

                    </div>
                    <br />

                    <p>Opções de resposta</p>
                    <DropdownButton id="msgs_prontas" title="mensagens prontas">
                      {this.state.tipo_msg.map((tipo, i) => {
                        return (<MenuItem key={i}
                          onClick={() => this.setState({
                            enviando: ' ',
                            enviando2: tipo.m04_msg,
                            respostas: tipo.m04_resposta,
                            tipoenvio: tipo.m04_cat_order,
                            categoria: tipo.m04_categoria
                          })
                          }>{tipo.m04_categoria}</MenuItem>)
                      })}
                    </DropdownButton>
                  </div>
                </div>
              }
              {/* <Button bsStyle="danger" onClick={() => this.sendQuestion('A caminho do atendimento ' + this.state.selected.endereco + '?', '3 min,7 min,10 min ')}>Tempo de Chegada?</Button> */}
              <br />
              <br />
              {//!this.props.childProps.userAttrib.admin &&
                motorista &&
                this.state.selected &&
                this.state.selected.t12_st_corrida !== "C" &&
                //this.state.selected.t12_dthr_embarque === null &&
                //this.state.selected.t12_dthr_dembarque === null &&
                <Button bsStyle="warning" onClick={this.reenviar.bind(this)}>REENVIAR</Button>}&nbsp;
              {/*this.props.childProps.userAttrib.admin &&
                this.state.selected &&
                this.state.selected.t12_st_corrida !== "C" &&
              <Button bsStyle="warning" onClick={this.reenviar.bind(this)}>REENVIAR ADMIN</Button>*/}&nbsp;
                 <ReactToPrint
                trigger={() => <Button bsStyle="warning">IMPRIMIR</Button>}
                content={() => this.componentRef}
              />
              {this.state.selected &&
                this.state.selected.dados_corrida &&
                this.state.selected.dados_corrida.corridaPendente &&
                <Button bsStyle="warning" onClick={this.openHistorico.bind(this)}>HIST DESPACHO</Button>}&nbsp;
              {motorista &&
                this.state.selected &&
                this.state.selected.t12_st_corrida !== "C" &&
                this.state.selected.t12_dthr_embarque === null &&
                <Button bsStyle="warning" onClick={this.qrlMonitor.bind(this)}>QRL</Button>}&nbsp;
              {motorista &&
                this.state.selected &&
                this.state.selected.t12_st_corrida !== "C" &&
                this.state.selected.t12_dthr_embarque !== null &&
                this.state.selected.t12_dthr_dembarque === null &&
                <Button bsStyle="warning" onClick={this.qrvMonitor.bind(this)}>QRV</Button>}&nbsp;
              {this.state.selected &&
                this.state.selected.t12_st_corrida !== "C" &&
                this.state.selected.t12_dthr_dembarque === null &&
                <Button bsStyle="warning" onClick={() => this.handleShowCancel()}>CANCELAR</Button>}&nbsp;
              {this.props.reclamacao &&
                <div style={{ width: '300px', marginTop: 20 }} >
                  <FormGroup>
                    <ControlLabel>Nível</ControlLabel>
                    <Select2
                      ref='nivel_type'
                      value={this.state.nivelSelected}
                      data={this.state.nivelOptions}
                      onSelect={e => {
                        this.setState({ nivelSelected: this.getSelectedOptionsValue(e.target.selectedOptions) },
                          () => {
                            let s = this.state;
                            if (s.reclamacaoSelected.length > 0 && s.statusSelected > 0 &&
                              s.nivelSelected.length > 0 && s.departamentoSelected.length > 0) {
                              this.setState({ gotRec: false });
                            }
                          })
                      }}
                      onUnselect={e => {
                        this.setState({ nivelSelected: [], gotRec: true })
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Status</ControlLabel>
                    <Select2
                      ref='status_type'
                      value={this.state.statusSelected}
                      data={this.state.statusOptions}
                      onSelect={e => {
                        this.setState({ statusSelected: this.getSelectedOptionsValue(e.target.selectedOptions) },
                          () => {
                            let s = this.state;
                            if (s.reclamacaoSelected.length > 0 && s.statusSelected > 0 &&
                              s.nivelSelected.length > 0 && s.departamentoSelected.length > 0) {
                              this.setState({ gotRec: false });
                            }
                          })
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Departamento</ControlLabel>
                    <Select2
                      ref='reclamacao_type'
                      value={this.state.departamentoSelected}
                      data={this.state.departamentoOptions}
                      onSelect={e => {
                        this.setState({ departamentoSelected: this.getSelectedOptionsValue(e.target.selectedOptions) },
                          () => {
                            let s = this.state;
                            if (s.reclamacaoSelected.length > 0 && s.statusSelected > 0 &&
                              s.nivelSelected.length > 0 && s.departamentoSelected.length > 0) {
                              this.setState({ gotRec: false });
                            }
                          })
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Tipo de Reclamação</ControlLabel>
                    <Select2
                      ref='reclamacao_type'
                      value={this.state.reclamacaoSelected}
                      data={this.state.reclamacaoOptions}
                      onSelect={e => {
                        this.setState({ reclamacaoSelected: this.getSelectedOptionsValue(e.target.selectedOptions) },
                          () => {
                            let s = this.state;
                            if (s.reclamacaoSelected.length > 0 && s.statusSelected > 0 &&
                              s.nivelSelected.length > 0 && s.departamentoSelected.length > 0) {
                              this.setState({ gotRec: false });
                            }
                          })
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Reclamação</ControlLabel>
                    <FormControl
                      autoFocus
                      ref="reclamacao"
                      componentClass="textarea"
                      placeholder=""
                      value={this.state.reclamacao}
                      onChange={e => { this.setState({ reclamacao: e.target.value }) }}
                    />
                  </FormGroup>
                  <Button disabled={this.state.gotRec} onClick={this.sendReclamacao.bind(this)} className="btn btn-warning btn-sm">Salvar Reclamação</Button>
                </div>
              }
            </Col>
            <Col xs={6} md={6} style={{ padding: 0, paddingLeft: 15 }}>
              <div style={{ width: '620px', height: '80vh' }} >
                {this.state.distance !== '' &&
                  <b><span style={{ fontSize: 16 }}>Distância aprox: {this.state.distance}&nbsp;&nbsp;</span></b>}
                {this.state.time !== '' &&
                  <b><span style={{ fontSize: 16 }}>Duração aprox: {this.state.time}&nbsp;</span></b>}
                  <GoMap
                    width={400}
                    height={450}
                    markO={markers}
                  ></GoMap>
                <input checked={this.state.centralizar} onChange={e => this.setState({ centralizar: e.target.checked })} type="checkbox" /> Centralizar mapa
              </div>
            </Col>
          </Row>
          <HistoricoDespacho
            modalHide={this.hideHistorico.bind(this)}
            show={this.state.historicoDespacho}
            corrida={this.state.selected}
          />
          <Modal
            show={this.state.showCancel}
            onHide={this.handleCloseCancel}
            aria-labelledby="contained-modal-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title">
                Cancelar corrida
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <FormGroup controlId="formControlsSelectMultiple">
                  <ControlLabel>Motivo cancelamento</ControlLabel>
                  <FormControl componentClass="select" onSelect={this.handleChange} onChange={this.handleChange} inputRef={el => this.inputEl = el} style={{ height: 40 }} >
                    <option value=""></option>
                    {this.state.tipo_cancel && this.state.tipo_cancel.map((c, i) => {
                      return (
                        <option key={i} ref={"selecionado"} value={c.id}>{c.descricao}</option>
                      )
                    })}

                  </FormControl>
                </FormGroup>
                <FormGroup
                  controlId="formBasicText"
                >
                  <ControlLabel>Observações: </ControlLabel>
                  <FormControl
                    type="text"
                    componentClass="textarea"
                    value={this.state.value2}
                    placeholder="Escreva o texto"
                    onChange={e => this.setState({ value2: e.target.value })}
                  />

                </FormGroup>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.cancelaCorridaMonitor.bind(this)}>Sim </Button>
              <Button onClick={this.handleCloseCancel}>cancelar</Button>
            </Modal.Footer>
          </Modal>
        </Modal.Body>
      </Modal>
    );
  }
}
export default DadosCorrida;
