import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from './CadastroHelper.js';

class CadastroTipoCorrida extends Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <CadastroHelper
        dados={{
          acessoID: '494da5ec-2aa4-4389-8536-76d5dd378e0a',
          parentProps: this.props,
          id: "t04_cd_tp_corrida",
          tipo_cadastro: "Tipo de corrida",
          tipo_plural: "corridas",
          href: "/tipo_corrida",
          titulo: "Cadastro de Tipos de Corridas",
          requests: {
            type: 'tipo_corrida',
            list: this.ws.getTipo.bind(this.ws),
            save: this.ws.criarTipo.bind(this.ws),
            edit: this.ws.alterarTipo.bind(this.ws),
            delete: this.ws.deleteTipo.bind(this.ws),
          },
          campos: [
            {
              ref: "t04_prioridade",
              label: "Prioridade",
              type: "number",
              placeHolder: "Escreva a prioridade do tipo de corrida",
            },
            {
              ref: "t04_sg_tp_corrida",
              label: "Sigla",
              type: "text",
              placeHolder: "Escreva a sigla do tipo de corrida",
            },
            {
              ref: "t04_desc",
              label: "Descrição",
              type: "text",
              placeHolder: "Escreva a descrição do tipo de corrida",
            },
            {
              ref: "t04_ativo",
              label: "Ativo",
              type: "checkbox",
            },
            {
              ref: "t04_op_virtual",
              label: "Op. Virtual",
              type: "checkbox",
            },
            {
              ref: "t04_lista",
              label: "Lista",
              type: "checkbox",
            },
            {
              ref: "t04_soop",
              label: "Tipo Interno?",
              type: "checkbox",
            },
          ],
          botoes: [
            {
              label: "Criar Tipo",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Prioridade",
                accessor: "t04_prioridade",
                width: 80,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                    }}
                  >
                  {row.original.t04_prioridade}
                  </div>
                ),
              },
              {
                Header: "Ativo",
                accessor: "t04_ativo",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.t04_ativo ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
              {
                Header: "Sigla",
                accessor: "t04_sg_tp_corrida",
                width: 100,
              },
              {
                Header: "Descrição",
                accessor: "t04_desc",
              },
              {
                Header: "Op. Virtual",
                accessor: "t04_op_virtual",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.t04_op_virtual ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
              {
                Header: "Lista",
                accessor: "t04_lista",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.t04_lista ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
              {
                Header: "Tipo Interno",
                accessor: "t04_soop",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.t04_soop ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
            ],
          }
        }}
      />
    );
  }
}

export default CadastroTipoCorrida;
