import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import './Bubbles.css';
import './Motoristas.css';
import WS from '../services/webservice';
import moment from 'moment';
import { Modal, Alert, Breadcrumb, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button, InputGroup, Tabs, Tab } from 'react-bootstrap';
import GoMap from './GoMap.js';

const MotoristaMarker = (props) => {
  let borderColor = '2px solid red';
  let fundo = 'red';
  if (props.livre) {
    borderColor = '2px solid green'
    fundo = 'green'
  }
  return (
    <div style={{ ...markerStyle, width: '15px', height: '15px', border: borderColor, borderRadius: '100%', backgroundColor: fundo }} >{/*props.text*/}</div>
  )
};

class Motoristas extends Component {

  static defaultProps = {
    center: { lat: -25.47, lng: -49.29 },
    zoom: 16
  };

  constructor(props) {
    super(props);
    this.state = {
      center: { lat: -25.47, lng: -49.29 },
      zoom: 11,
      motoristas: [],
      motoristas2: [],
      att: false,
      mensagens: [],
      motodado: [],
      mensagemcarregada: [],
      mensagematual: [],
      loaded: false,
      batata: false,
      error: null,
      filteredRt: null,
      show: true,
      showRtDetails: false,
      selectedRt: {
        dados: {
          rt: null,
          nome: null,
        },
      },
    }
    this.updateLocation = this.updateLocation.bind(this);
    this.updateData = this.updateData.bind(this);
    this.filtrarPorRt = this.filtrarPorRt.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.loadMSG = this.loadMSG.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.salvaValor = this.salvaValor.bind(this);
    this.webservice = new WS();
    this.isActive = this.isActive.bind(this);
    this.isBusy = this.isBusy.bind(this);
    this.forceAT = this.forceAT.bind(this);
    this.getTarget = this.getTarget.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.markerCLick = this.markerCLick.bind(this);
  }




  updateLocation(position) {
    //  this.setState({
    //    center: {
    //      lat: position.coords.latitude,
    //      lng: position.coords.longitude,
    //    },
    //    zoom: 16
    //  });
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  async componentDidMount() {
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push("/");
    }

    //if (navigator.geolocation) {
    //  navigator.geolocation.getCurrentPosition(this.updateLocation);
    //}


    let result = await this.webservice.fetch2('mensagens?cpf=' + this.state.selectedRt.dados.cpf)
    var msgc = await result.json(result.result);
    this.setState({ mensagemcarregada: msgc })

    this.timerId = setInterval(this.updateData, 12000);
    let result3 = await this.webservice.fetch2('motoristas/search')
    var moto2 = await result3.json();
    console.log('**moto2', moto2)
    this.setState({
      motoristas2: moto2
    })

    await this.updateData();

  }


  async updateData() {
    try {
      console.log("child",this.props.childProps.userAttrib)
      let result = await this.webservice.fetch2('motoristas/?centrais=' + this.props.childProps.userAttrib.centralString.ids);
      result = await result.json();
      console.log('updateData',result)
      let result2 = await this.webservice.fetch2('mensagens/?centrais=' + this.props.childProps.userAttrib.centralString.ids);
      var msg2 = await result2.json();
      this.handleClick(this.state.selectedRt.dados.cpf);
      this.setState({
        motoristas: result.map(r => { return { ...r, _uuid: r.uuid, color: r.livre ? 'green' : 'red' } }),
        mensagens: msg2.result2
      });

      const bounds = {
        ne: {
          lat: result[0].latitude + 0.0001, lng: result[0].longitude + 0.0001
        },
        sw: {
          lat: result[0].latitude - 0.0001, lng: result[0].longitude - 0.0001
        }
      };
      result.forEach(m => {
        if (m.latitude > bounds.ne.lat) {
          bounds.ne.lat = m.latitude;
        }
        if (m.longitude > bounds.ne.lng) {
          bounds.ne.lng = m.longitude;
        }
        if (m.latitude < bounds.sw.lat) {
          bounds.sw.lat = m.latitude;
        }
        if (m.longitude < bounds.sw.lng) {
          bounds.sw.lng = m.longitude;
        }
      });
      const size = {
        width: 440, // Map width in pixels
        height: 380, // Map height in pixels
      };
      console.log(result);
      if (this.state.att === true) {
        const { center, zoom } = fitBounds(bounds, size);
        await this.setState({
          center: center,
          zoom: zoom,
          motoristas: result
        });
      }
    } catch (err) {
      this.setState({ loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })

    }
  }
  async loadMSG(key) {
    try {
      let result = await this.webservice.fetch2('mensagens?cpf=' + key + '&centrais=' + this.props.childProps.userAttrib.centralString.ids);
      var msgc = await result.json(result.result);
      var msgAT = msgc[0];
      this.setState({
        mensagemcarregada: msgc,
        mensagematual: msgAT
      });
    } catch (err) {
      this.setState({ error: 'Ocorreu um erro ao carregar os dados do servidor...' })
    }
  }
  filtrarPorRt() {
    let teste = ReactDOM.findDOMNode(this.txtRt).value;
    console.log('teste',teste)
    this.setState({
      filteredRt: teste,
    })
    return true;
  }
  //aqui

  async handleClick(key) {
    try {
      let ncpf = key
      let ccpf = ncpf.replace("1:", "")
      let result = await this.webservice.fetch2('mensagens?cpf=' + ccpf);
      var msgc = await result.json(result.result);
      var msgAT = msgc[0];
      this.setState({
        mensagemcarregada: msgc,
        mensagematual: msgAT,
        showMsgDetails: true
      });
    } catch (err) {
      this.setState({ error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }

  async handleSend() {
    try {
      let re = /([1-9]+:)([1-9]+)/
      let ncpf = this.state.mensagematual.cpf
      let ref = ncpf.replace(re, '$2')
      await this.webservice.fetch2('mensagens/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cpf: ref,
          uuid: this.state.mensagematual.uuid,
          at: this.state.mensagematual.at,
          rt: this.state.mensagematual.rt,
          textolivre: this.state.enviando,
          remetente: "monitor",
          alternativas: "OK"
        })
      })

      this.setState({
        enviando: ''
      });


      this.handleClick(this.state.mensagematual.cpf);
    } catch (err) {
      this.setState({ error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }

  salvaValor(e) {
    var value = e.target.value;
    this.setState({
      enviando: value
    })
  }
  _onChildClick = (key, childProps) => {
    this.setState({
      selectedRt: childProps.data,
      showRtDetails: true,
    })

    // this.handleClick(this.state.selectedRt.cpf);
  }
  markerCLick(m) {
    console.log('markerCLick',m)
    this.setState({
      selectedRt: m,
      showRtDetails: true,
    })
  }
  handleClose() {
    this.setState({
      showRtDetails: false,
    })
  }

  isActive(motorista) {
    var act = 0;
    motorista.map(m => {
      if (m.livre) {
        act = act + 1;
      }
      return null;
    })
    return act;
  }

  isBusy(motorista) {
    var act = 0;
    motorista.map(m => {
      if (!m.livre) {
        act = act + 1;
      }
      return null
    })
    return act;
  }


  handleToggle() {
    this.setState({ show: !this.state.show });
  }

  getTarget() {
    return ReactDOM.findDOMNode(this.target);
  }

  forceAT(e) {
    var value = e.target.checked;
    this.setState({
      att: value,
    })
  }

  showMyRT(){
    let oe = this.webservice.getCookie('central-id');
    console.log("O CARAIA",oe)
    if(Number(oe) !== 5){
      console.log("entrei")
      return(
        <p>RT: {this.state.selectedRt.dados.rt}</p>
      )
    }

  }

  // uuid: "4274293904"
  // latitude: -25.413704
  // longitude: -49.33694
  // bearing: 66.400002
  // livre: true
  // corrida: null
  // tipo_carro: "CNL|GPS"
  // dados:
  // rt: "12001"
  // nome: "Rafael Ramos Doria"
  // at: "12003"
  // cpf: "4274293904"
  // placa: "ABL4533 "
  // central: "10"
  // cidadeatuacao: "Curitiba"
  // ultima_corrida: "18844511"
  // __proto__: Object
  // _uuid: "4274293904"
  // color: "green"

  render() {
    let marcadores = [];
    let dados = {};
    console.log('**moto',this.state.motoristas)
    for (let index = 0; index < this.state.motoristas.length; index++) {
      const element = this.state.motoristas[index];
      let cidade = element.dados ? element.dados.cidadeatuacao : '';
      if (!cidade) {cidade = '';}
      if (cidade.trim()==-'') {cidade = '-';}
      cidade = cidade.toUpperCase().trim();
      if (!dados[cidade]) {
        dados[cidade] = {cidade: cidade,livre:0, ocupado:0, total: 0}
      }
      let d = dados[cidade];
      d.total = d.total + 1;
      if (element.livre) {
        d.livre = d.livre + 1;
      } else {
        d.ocupado = d.ocupado + 1;
      }
      dados[cidade] = d;
    }
    let dadosArray = Object.keys(dados).map(i => dados[i]);
    dadosArray.sort((a,b) => {return +(a.cidade > b.cidade) || +(a.cidade === b.cidade) - 1;})

    if (this.state.filteredRt) {
      for (let index = 0; index < this.state.motoristas.length; index++) {
        const element = this.state.motoristas[index];
        if (element.dados){
        if (element.dados.placa !== 'QWH9946') {
        //if (element.dados.rt){if (element.dados.rt.toLowerCase() === this.state.filteredRt.toLowerCase()) {marcadores.push(element)}}
        //if (element.dados.at){if (element.dados.at.toLowerCase() === this.state.filteredRt.toLowerCase()) {marcadores.push(element)}}
        if (element.dados.cpf){if (element.dados.cpf.toLowerCase() === this.state.filteredRt.toLowerCase()) {marcadores.push(element)}}
        if (element.dados.cidadeatuacao){if (element.dados.cidadeatuacao.toLowerCase().indexOf(this.state.filteredRt.toLowerCase()) >= 0 ) {marcadores.push(element)}}
        if (element.dados.nome){if (element.dados.nome.toLowerCase().indexOf(this.state.filteredRt.toLowerCase()) >= 0 ) {marcadores.push(element)}}
        if (element.dados.placa){if (element.dados.placa.toLowerCase().indexOf(this.state.filteredRt.toLowerCase()) >= 0 ) {marcadores.push(element)}}
        }}
      }
    } else {
      marcadores = this.state.motoristas;
    }
    console.log('marcadores',marcadores)
    return (
      <div style={{ width: '100%%', height: '88vh', padding: '0px 10px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active href="/motoristas">Motoristas</Breadcrumb.Item>
        </Breadcrumb>
        {
          this.state.filteredRt &&
          (
            <Alert bsStyle="info">
              Mostrando somente o  filtrado ({this.state.filteredRt})
            </Alert>
          )
        }

        <Row>
          <Col sm={9}>
          <GoMap
              EscondeAtalhos={true}
              MarcadorClick={this.markerCLick}
              width={800}
              height={400}
              minZoom={7}
              maxZoom={18}
              markO={marcadores}
              zoommanual={true}
            ></GoMap>
          </Col>
          <Col sm={2}>
          <Form>
          <FormGroup controlId="formHorizontalEmail">
            <Row>
            <Col componentClass={ControlLabel}>
              Motorista
                </Col>
            <Col>
              <InputGroup>
                <FormControl style={{ height: 30 }} onKeyDown={e => e.keyCode === 13 && this.filtrarPorRt() && e.preventDefault()} ref={ref => this.txtRt = ref}  placeholder="Buscar" />
                <InputGroup.Button>
                  <Button onClick={this.filtrarPorRt} style={{ height: 30 }} >Buscar</Button>
                </InputGroup.Button>
              </InputGroup>
            </Col>
            </Row>
            <Row className="linhatabela">
                  <Col sm={7}> </Col>
                  <Col sm={1}>L</Col>
                  <Col sm={1}>O</Col>
                  <Col sm={1}>T</Col>
                </Row>
            {dadosArray.map((a) => {
              return (
                <Row className="linhatabela">
                  <Col sm={7}>{a.cidade.toUpperCase()}</Col>
                  <Col sm={1}>{a.livre}</Col>
                  <Col sm={1}>{a.ocupado}</Col>
                  <Col sm={1}>{a.total}</Col>
                </Row>
              )
            })}
          </FormGroup>
        </Form>
          </Col>
        </Row>

        {this.state.selectedRt &&
        <Modal show={this.state.showRtDetails} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>CPF {this.state.selectedRt.uuid}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab eventKey={1} title="Dados Gerais">
              {this.state.selectedRt.dados && <>
                <h4>Nome</h4>
                <p>{this.state.selectedRt.dados.nome}</p>
                <p>Central: {this.state.selectedRt.dados.razaosocial}</p>
                <p>Telefone: {this.state.selectedRt.dados.telefone}</p>
                <p>Carro: {this.state.selectedRt.dados.carro}</p>
                <p>Placa: {this.state.selectedRt.dados.placa}</p></>}
                <p>Tipo motorista: {this.state.selectedRt.tipo_carro}</p>
                <p>App: {this.state.selectedRt.versao} ({this.state.selectedRt.app}/{this.state.selectedRt.uniqueid})</p>
                <h4>Status Atual</h4>
                <p style={{ color: this.state.selectedRt.livre ? 'green' : 'red' }}>{this.state.selectedRt.livre ? "Livre" : "Ocupado"}</p>
              </Tab>

              {false  && <Tab eventKey={2} title="Mensagens" onClick={() => this.handleClick(this.state.selectedRt.dados.cpf)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel">
                      <div className="panel-body">
                        <ul className="chat">
                          {
                            this.state.mensagemcarregada.map(m => {
                              let classe = "right clearfix";
                              let classe2 = "pull-right text-muted";
                              let remetente = "Monitor";
                              let interna = true;
                              let url = "http://placehold.it/50/FA6F57/fff&text=OP";
                              if (m.remetente === 'Motorista') {
                                interna = false;
                                url = "http://placehold.it/50/55C1E7/fff&text=" + m.at;
                                classe = "left clearfix";
                                classe2 = "pull-left text-muted";
                                remetente = "Motorista AT " + m.at + " / RT " + m.rt;
                              }
                              return (
                                <li key={m.datahora} className={classe}>
                                  {interna &&
                                    <span className="chat-img pull-right">
                                      <img src={url} alt="User Avatar" className="img-circle" />
                                    </span>}
                                  {!interna &&
                                    <span className="chat-img pull-left">
                                      <img src={url} alt="User Avatar" className="img-circle" />
                                    </span>}

                                  <div className="chat-body clearfix">
                                    {interna &&
                                      <div className="header clearfix">
                                        <small className={classe2}>{moment(m.datahora).format("DD/MM/YY HH:mm")}</small>
                                        <strong className="pull-left primary-font">{remetente}</strong>
                                      </div>
                                    }

                                    {!interna &&

                                      <div className="header clearfix">
                                        <strong className="pull-right primary-font">{remetente}</strong>
                                        <small className={classe2}>{moment(m.datahora).format("DD/MM/YY HH:mm")}</small>
                                      </div>
                                    }

                                    <div className={classe}><p>{m.textolivre}</p></div>

                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      <div className="panel-footer">
                        <div className="input-group">
                          <input id="btn-input" style={{ height: 30 }} type="text" className="form-control input-sm" placeholder="Escreva sua mensagem..."
                            value={this.state.enviando}
                            onChange={(v) => this.salvaValor(v)} onKeyDown={e => e.keyCode === 13 && this.state.enviando !== '' && this.handleSend(e) && e.preventDefault()} autoFocus
                          />
                          <span className="input-group-btn">
                            <button className="btn btn-warning btn-sm" disabled={this.state.enviando === '' ? true : false} style={{ height: 30 }} id="btn-chat" onClick={this.handleSend}>Enviar</button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </Tab>}

            </Tabs>
          </Modal.Body>
        </Modal>}

      </div>
    );
  }
}

const K_SIZE = 30;
const markerStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  //onClick={()=>this.handleClick(this.state.selectedRt.dados.cpf)} faz funcionar aparecer na tela
  position: 'absolute',
  width: K_SIZE,
  height: K_SIZE * 0.8,
  left: -K_SIZE / 2,
  top: -K_SIZE / 2,

  border: '3px solid #f44336',
  backgroundColor: 'white',
  textAlign: 'center',
  color: '#3f51b5',
  fontSize: 12,
  fontWeight: 'bold',
  padding: 3,
  cursor: 'pointer'
};
export default Motoristas;
