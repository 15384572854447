import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from './CadastroHelper.js';

class CadastroMsgChat extends Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "msg_chat",
          tipo_cadastro: "Tipo de Mensagem Chat",
          tipo_plural: "mensagens",
          href: "/msg_chat",
          titulo: "Cadastro de Tipos de Mensagem",
          requests: {
            type: 'msg_chat',
            list: this.ws.getTipo.bind(this.ws),
            save: this.ws.criarTipo.bind(this.ws),
            edit: this.ws.alterarTipo.bind(this.ws),
            delete: this.ws.deleteTipo.bind(this.ws),
          },
          campos: [
            {
              ref: "msg",
              label: "Mensagem",
              type: "text",
              placeHolder: "Mensagem",
            },
            {
              ref: "ativo",
              label: "Ativo",
              type: "checkbox",
            },
            {
              ref: "motorista",
              label: "App Motorista",
              type: "checkbox",
            },
            {
              ref: "cliente",
              label: "App Cliente",
              type: "checkbox",
            },
          ],
          botoes: [
            {
              label: "Criar Tipo",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Ativo",
                accessor: "ativo",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.ativo ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
              {
                Header: "Mensagem",
                accessor: "msg",
              },
              
            ],
          }
        }}
      />
    );
  }
}

export default CadastroMsgChat;
