import React, { Component } from 'react';
import { Modal, Row, Col } from 'react-bootstrap'
import moment from 'moment';
import DadosCorrida from './DadosCorrida.js'
class ModalList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      clicked: '',
      mostra: false,
    };

    this.manageShortcut = this.manageShortcut.bind(this);
    this.modalHide = this.modalHide.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  modalHide() {
    this.setState({ mostra: false })
  }

  manageShortcut(e) {
    var code = (e.keyCode ? e.keyCode : e.which);

    if (code === 82) {
      this.props.history.push({ pathname: '/solicitacoes', state: { corrida: this.state.selected } })
    }
    if (code === 72) {
      e.preventDefault(); e.stopPropagation();
      this.setState({ mostra: true })
    }
  }

  render() {
    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={this.props.modalHide}
        onKeyDown={!this.props.onClickRow && this.manageShortcut}
        onEntered={() => { this.props.changeCanEsc && this.props.changeCanEsc(false) }}
        container={this}
        aria-labelledby="contained-modal-title"
        dialogClassName="historico-dialog-modal"
      >
        <Modal.Body>
          <DadosCorrida
            reclamacao={false}
            show={this.state.mostra}
            onHide={this.modalHide}
            corrida={this.state.selected}
            childProps={this.props.childProps}
          />
          <Row className="titulo-antigos">
            <Col className='minha-coluna-6percent' md={1}><div>CÓD.</div></Col>
            <Col className='minha-coluna-2percent' md={1}><div>T</div></Col>
            <Col className='minha-coluna-4percent' md={1}><div>UN</div></Col>
            <Col className='minha-coluna-2percent' md={1}><div></div></Col>
            <Col className='minha-coluna-7percent' md={1}><div></div></Col>
            <Col className='minha-coluna-9percent' md={1}><div>ABERTURA</div></Col>
            <Col className='minha-coluna-9percent' md={1}><div>SERVIÇO</div></Col>
            <Col className='minha-coluna-12percent' md={1}><div>CLIENTE</div></Col>
            <Col className='minha-coluna-12percent' md={1}><div>PASSAGEIRO</div></Col>
            <Col className='minha-coluna-18percent' md={2}><div>ORIGEM</div></Col>
            <Col className='minha-coluna-18percent' md={2}><div>DESTINO</div></Col>
          </Row>
          {this.props.dados.map((m, i) => {
            return (
              <Row key={i} style={i % 2 !== 0 ? (i === this.state.clicked ? { backgroundColor: '#FFFFD6' } : { backgroundColor: '#f6f6f6' }) : (i === this.state.clicked ? { backgroundColor: '#FFFFD6' } : {})} className="enderecos-antigos"
                onClick={() => {
                  this.props.onClickRow ? this.props.onClickRow(m) :
                    this.setState({ selected: m, clicked: i }, () => console.log("ESTOU SELECIONADO", this.state.selected))
                }}>
                <Col className='minha-coluna-6percent' md={1}><div>{m.id}</div></Col>
                <Col className='minha-coluna-2percent' md={1}><div></div></Col>
                <Col className='minha-coluna-4percent' md={1}><div>{m.t07_conceccao}</div></Col>
                <Col className='minha-coluna-2percent' md={1}><div style={m.t12_st_corrida === 'C' || m.t12_st_corrida === 'T' || m.t12_st_corrida === 'H' || m.t12_st_corrida === 'M' ? { backgroundColor: 'red' } : { backgroundColor: 'blue' }} className='box-status'></div></Col>
                <Col className='minha-coluna-7percent' md={1}><div>{m.t12_st_corrida === 'C' || m.t12_st_corrida === 'T' || m.t12_st_corrida === 'H' || m.t12_st_corrida === 'M'? 'Cancelada' : m.t12_dthr_dembarque === null ? 'Inicio' : 'Final'}</div></Col>
                <Col className='minha-coluna-9percent' md={1}><div>{m.datahoracorrida !== null ? moment(m.datahoracorrida).format("DD/MM HH:mm") : ''}</div></Col>
                <Col className='minha-coluna-9percent' md={1}><div>{m.t12_dthr_embarque !== null ? moment(m.t12_dthr_embarque).format("DD/MM HH:mm") : ''}</div></Col>
                <Col className='minha-coluna-12percent' md={1}><div></div></Col>
                <Col className='minha-coluna-12percent' md={1}><div>{m.cliente}</div></Col>
                <Col className='minha-coluna-18percent' md={1}><div>{m.endereco}</div></Col>
                <Col className='minha-coluna-18percent' md={1}><div>{m.destino !== ', , ' ? m.destino : ''}</div></Col>
              </Row>
            )
          })
          }
        </Modal.Body>
        <Modal.Footer>
          {!this.props.onClickRow && <center><p> H - historico &nbsp;&nbsp;&nbsp;&nbsp; R - reclamação</p></center>}
        </Modal.Footer>
      </Modal >

    );
  }
}
export default ModalList;