import React, { Component } from 'react';
import WS from '../services/webservice';
import parse from 'html-react-parser';
import { Column, Row } from "simple-flexbox";

class Politica extends Component {
  constructor(props) {
    super(props);
    this.state = { valor: [] }
    this.termos = this.termos.bind(this);
    this.renderData = this.renderData.bind(this);
    this.ws = new WS();
  }
  async termos(id) {
    let resultado = await this.ws.gettermo(id);
    if (!resultado.error) {
      let valor = this.renderData(resultado.msg)
      this.setState({
        valor: valor
      })
    }
  }
  componentDidMount() {
    console.log('aki');
    console.log('this.props.match.params', this.props.match.params.id);
    let data = this.props.match.params.id;

    console.log('intro', data);
    this.termos(data)
  }

  renderData(itens) {
    let saida = []
    let buffer = ""

    for (let index = 0; index < itens.length; index++) {
      const a = itens[index];
      if (a.tipo === 1) {
        saida.push(<p style={{textAlign: 'justify', paddingBottom: 13}}>{parse(a.txt)}</p>)
      } else if (a.tipo === 2) {
        saida.push(<h4 style={{textAlign: 'justify', paddingBottom: 13}}>{parse(a.txt)}</h4>)
      } else if (a.tipo === 3) {
        saida.push(<h3 style={{textAlign: 'justify', paddingBottom: 13}}>{parse(a.txt)}</h3>)
      } else if (a.tipo === 4) {
        saida.push(<h2 style={{textAlign: 'justify', paddingBottom: 13}}>{parse(a.txt)}</h2>)
      } else if (a.tipo === 5) {
        buffer = <Column flex={2} style={{padding:5, margin: 5, border: '1px solid black' }}><span style={{textAlign: 'justify'}}>{parse(a.txt)}</span></Column>
      } else if (a.tipo === 6) {
        saida.push(<Row vertical='start' horizontal='space-evenly'>{buffer}<Column flex={3} style={{padding:5, margin: 5, border: '1px solid black' }} ><span style={{textAlign: 'justify'}}>{parse(a.txt)}</span></Column></Row>)
        buffer = ""
      } else {
        saida.push (<p>{a.txt}</p>)
      }
    }


    return saida
  }

  render() {

    return (
      <div style={{marginLeft: '10%', marginRight: '10%'}}>
        {this.state.valor.map((a,b) => a)}
      </div>
    )

  }

}

export default Politica;
