import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from './CadastroHelper.js';

class CadastroTipoPermissoes extends Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Nível de Acesso",
          tipo_plural: "níveis de acesso",
          href: "/nivel_acesso",
          titulo: "Cadastro de Níveis de Acesso",
          requests: {
            type: 'nivel_acesso',
            list: this.ws.getTipo.bind(this.ws),
            save: this.ws.criarTipo.bind(this.ws),
            edit: this.ws.alterarTipo.bind(this.ws),
            delete: this.ws.deleteTipo.bind(this.ws),
            modulos: this.ws.getModulos.bind(this.ws),
          },
          campos: [
            {
              ref: "descricao",
              label: "Descrição",
              type: "text",
              placeHolder: "Escreva a descrição do nível de acesso",
            },
            {
              ref:"modulos",
              label: "Módulos",
              type: "modulos",
            },
          ],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Descrição",
                width: 200,
                accessor: "descricao",
              },
            ],
          }
        }}
      />
    );
  }
}

export default CadastroTipoPermissoes;
