import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from './CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class CadastroTipoCancelamento extends Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <CadastroHelper
        dados={{
          acessoID: '9038bd1a-b8d7-4c9a-b2ec-3cf625c751c7',
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Fatura",
          tipo_plural: "Faturas",
          href: "/faturaportal",
          titulo: "Controle de Faturas",
          novo: false,
          requests: {
            type: 'faturaportal',
            list: this.ws.getTipo.bind(this.ws),
            save: this.ws.criarTipo.bind(this.ws),
            edit: this.ws.alterarTipo.bind(this.ws),
          },
          campos: [
            {
              ref: "voucher_empresa_razaosocial",
              label: "voucher_empresa.razaosocial",
              type: "disabled",
            },
            
            {
              ref: "valor",
              label: "Valor",
              type: "currencydisabled",
            },
            {
              ref: "numeronf",
              label: "Nota Fiscal",
              type: "text"
            },
            {
              ref: "statusnf",
              label: "Situação",
              type: "select3",
              niveisList: [
                { text: 'Nova', id: 0 },
                { text: 'Pendente', id: 1 },
                { text: 'Paga', id: 3 },
                { text: 'Cancelada', id: 9 },
              ]

            }
          ],
          botoes: [

          ],
          table: {
            colunas: [
              {
                Header: "Empresa",
                accessor: "voucher_empresa_razaosocial",
              },
              { 
                Header: "No.Fatura",
                accessor: "numerofaturamento",
              },
              {
                Header: "Vencimento",
                accessor: "datavencimento",
                Cell: row => {
                  return (
                    <div>
                      {moment(row.original.datavencimento).format("DD-MM-YYYY")}
                    </div>
                  )
                }
              },                            
              {
                Header: "Valor",
                id: "valor",
                accessor: d => <NumberFormat value={Number(d.valor)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />,
              },
              {
                Header: "Nota Fiscal",
                accessor: "numeronf",
              },
              {
                Header: 'Status',
                id: "statusnf",
                accessor: d => d.statusnf === 0 ? 'Nova' : d.statusnf === 1 ? 'Pendente' : d.statusnf === 3 ? 'Paga' : 'Cancelada',
                filterable: true,
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  } else {
                    return row[filter.id];
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todas</option>
                    <option value={0}>Nova</option>
                    <option value={1}>Pendente</option>
                    <option value={3}>Paga</option>
                    <option value={9}>Cancelada</option>
                  </select>
              },
            ],
          }
        }}
      />
    );
  }
}

export default CadastroTipoCancelamento;
