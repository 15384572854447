import React, { Component } from 'react';
import './Corridas.css';
import './Bubbles.css';
import moment from 'moment';
import {  Modal,  Tabs} from 'react-bootstrap'
import WS from '../services/webservice';


class Mensagens extends Component {
  constructor(props){
    super(props);
    this.state = {
      mensagens: [],
      mensagemcarregada: [],
      mensagematual: [],
      historico: {},
      selecionado: [],
      motoristas: [],
      loaded: false,
      forceRt: false,
      loadedbusca: true,
      buscaRt: null,
      error: null
    }
    this.updateData = this.updateData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.salvaValor = this.salvaValor.bind(this);
    this.BuscarRT = this.BuscarRT.bind(this);
    this.handleBusca = this.handleBusca.bind(this);
    this.chanceRT = this.chanceRT.bind(this);
    this.forceAT = this.forceAT.bind(this);
    this.handleAtual = this.handleAtual.bind(this);
    this.isOnline = this.isOnline.bind(this);
    this.lastRun = this.lastRun.bind(this);
    this.todosMsg = this.todosMsg.bind(this);
    this.webservice = new WS();
  }
  async componentDidMount(){
    this.timerId  = setInterval(this.updateData, 15000);
    await this.updateData();

  }
  componentWillUnmount(){
    clearInterval(this.timerId);

  }

  handleKeyDown = function (e) {
    if (e.key === 13) {
      e.preventDefault();
      this.todosMsg();
    }
  };

  isOnline(motorista){
    if(motorista.online){
      return('Online')
    }else{
      return('Offline')
    }
  }

  showMensagens(){
    this.setState({
      showMsgDetails: true
    })
  }
  async updateData(){
    try{
     // let result = await this.webservice.fetch2('mensagens?centrais=' + this.props.childProps.userAttrib.centralString.ids);
      //var msg = await result.json();
      console.log("só pra verificar funcionamento")
      if(this.state.showMsgDetails === true && this.state.mensagematual){
        this.handleClick(this.state.mensagematual.cpf);
      }
      // this.setState({
      //   mensagens: msg.result,
      // });
      if(this.props.num === '0'){
        this.setState({
          showMsgDetails: true,
        })
      }
      this.setState({error: null, loaded: true});
    }catch(err){
      this.setState({loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente'})
    }
  }


  async handleAtual(item) {
    var msg = [];
      msg.cpf= item.cpf;
      msg.rt= item.rt;
      msg.at= item.at;
      msg.uuid= item.uuid;
    this.setState({mensagematual: msg});
    this.handleClick(item.cpf);
  }
  async handleClick(key) {
    try{
      let result = await this.webservice.fetch2('mensagens?cpf=' + key);
      var msgc = await result.json(result.result);
      this.setState({
        mensagemcarregada: msgc,
        showMsgDetails: true
        });

    }catch(err){
      this.setState({error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente'})
    }
  }
  async handleBusca() {
    if (!this.state.buscaRt && !this.state.buscaAT) {
      return;
    }
    try{
      var busca = "";
      if(!this.state.forceRt){
        busca = "?at=" + this.state.buscaRt;
      }else if (this.state.forceRt){
        busca ="?rt=" + this.state.buscaRt;
      }else if (this.state.buscaRt.length === 4) {
        busca = "?at=" + this.state.buscaRt;
      } else {
        busca = "?rt=" + this.state.buscaRt;
      }
      let result = await this.webservice.fetch2('motoristas/search' + busca);
      var msgc = await result.json(result);
      this.setState({
        motoristas: msgc
      })
      this.state.motoristas.map(m=>{
        if(m.online){
        this.handleAtual(m)
        }
        return null
      })
      this.setState({error: null, loadedbusca: true});
    }catch(err){
      this.setState({loadedbusca: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente'})
    }
  }
  handleClose(){
    this.setState({
      showMsgDetails: false,
      motoristas: [],
    })
    this.props.handleClose();
  }
  chanceRT(e) {
    var value = e.target.value;
    this.setState({
      buscaRt : value
    })
  }
  forceAT(e) {
    var value = e.target.checked;
    this.setState({
      forceRt : value,
    })
  }
  BuscarRT(e){
    //var value = e.target.value;
    this.handleBusca();

    return true
  }
  salvaValor(e) {
    var value = e.target.value;
    this.setState({
      enviando:value
    })
  }
  async todosMsg(){
    let result = await this.webservice.fetch2('motoristas?centrais=' + this.props.childProps.userAttrib.centralString.ids);
    result = await result.json();
    console.log("VENDO TODOS MOTORISTAS", result)
    result.map(m=>{
      this.handleSend(m, 1)
      return null
    })
    this.setState({
      enviando: ''
    });
    alert("Sua mensagem foi enviada para todos");
    this.handleClose();
  }
  async handleSend(m, todos){
    if (!todos) {todos = 0}
    try{
      if (m.dados) {
        await this.webservice.postSacPiloto(m.dados.cpf, this.state.enviando,m.dados.central, todos);
      }
     }catch(err){
       this.setState({error: 'Ocorreu um erro ao carregar os dados do servidor...'})
     }
  }

 lastRun(porta){
   this.webservice.runSearch(moment().subtract(1,'days').utc().format("YYYY-MM-DD HH:mm"),moment().utc().format("YYYY-MM-DD HH:mm"),'at',porta).then(resultado2=>{this.setState({historico: resultado2},function(){console.log(this.state.historico)})})
  //var bmh = await result2.json();



    //return bmh;
}

  render() {
    return (
      <div>

      <Modal show={this.state.showMsgDetails} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Enviando mensagem para todos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">

          <div className="row">
            <div className="col-md-12">
                <div className="panel">
                <div className="panel-footer">
                    <div className="input-group">
                        <input id="btn-input"  style={{height: 30}} type="text" className="form-control input-sm" placeholder="Escreva sua mensagem..."
                          value={this.state.enviando}
                          onChange={(v) => this.salvaValor(v)} onKeyDown={e => {if(e.key === 'Enter'){this.todosMsg()}}} autoFocus
                          />
                        <span className="input-group-btn">
                            <button  style={{height: 30}} disabled={this.state.enviando === '' ? true : false} className="btn btn-warning btn-sm"  id="btn-chat" onClick={this.todosMsg} >Enviar</button>
                        </span>
                    </div>
                </div>
                </div>
            </div>
          </div>


          {/*
            <Tab eventKey={2} title = "Corrida atual">
              <div>

              </div>
            </Tab>*/
          }
        </Tabs>
        </Modal.Body>
      </Modal>
      </div>
    );
  }
}
export default Mensagens;
