import WS from '../services/webservice';
import React, { Component } from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap'
import Autosuggest from 'react-autosuggest';
import Loader from 'react-loader';

class AutoComplete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: true,
      gotEnd: false,
    };

    this.webservice = new WS();
    this.salvaSugestion = this.salvaSugestion.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }
  salvaSugestion(suggestion) {
    this.webservice.place(suggestion.place_id).then(result => {
      console.log('salvaSugestion',result)
      //this.props.changeEndereco(result, true, this.props.i)
      let endereco = result.fullAddress;
      let cidade = result.cidade;
      let estado = result.estado;
      let numero = result.numero;
      let bairro = result.bairro;
      this.props.changeEndereco(endereco, cidade, estado, bairro, this.props.addressType, numero, null, {lat: result.latitude, lng: result.longitude},result,this.props.i)
      this.props.changeValidation(null, this.props.addressType, true,this.props.i);
      this.props.onSuggestionSelected(this.props.addressType, result,this.props.i)
      this.setState({loaded: true})
    })
  }
  // 
  //             this.setState({ moreinfo: result.moreinfo, autocompletelatlon: {lat: result.latitude, lng: result.longitude} }, () => {
// 

  componentDidMount() {
    if (this.props.changeAutoCompleteRefs) {
      this.props.changeAutoCompleteRefs(this.refs, this.props.addressType)
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({ gotEnd: false });
    this.props.changeEnderecoExtra(newValue,this.props.addressType,this.props.i)
    this.props.changeValidation("error", this.props.addressType, false,this.props.i)
  };

  onBlur = (event, { highlightedSuggestion }) => {
    // this.props.blur();
    // if (highlightedSuggestion) {
    //   this.salvaSugestion(highlightedSuggestion)
    // }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ gotEnd: false });

    this.webservice.autocomplete(value, this.props.center).then(result => {
      this.props.changeAddress(result, this.props.addressType);
    })
  };

  onSuggestionsClearRequested = () => {
    this.setState({ gotEnd: false });
    this.props.changeAddress([], this.props.addressType);
  };

  render() {
    return (
      <FormGroup className="autoComp" validationState={this.props.validation ? this.props.validation : null}>
        <Loader loaded={this.state.loaded}>
        </Loader>
        <ControlLabel>{this.props.title}</ControlLabel>
        <Autosuggest
          ref={"auto_complete_" + this.props.addressType}
          highlightFirstSuggestion={true}
          focusInputOnSuggestionClick={false}
          suggestions={this.props.address}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={(e, { suggestion, method }) => {
            this.setState({ loaded: false });
            this.salvaSugestion(suggestion)
        }}
          getSuggestionValue={item => item.endereco}
          renderSuggestion={item =>
            <div style={{ fontSize: 16 }}>
              {item.fullAddress}
            </div>
          }
          inputProps={{
            value: this.props.endereco ? this.props.endereco : '',
            onChange: this.onChange,
            onBlur: this.onBlur,
            disabled: this.props.disabled,
          }}
        />
      </FormGroup>
    );
  }
}
export default AutoComplete;
