import WS from '../services/webservice';
import React, { Component } from 'react';
import { Modal, Row, Col } from 'react-bootstrap'
import moment from 'moment';

class HistoricoDespacho extends Component {

  constructor(props) {
    super(props);

    this.webservice = new WS();
  }


  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {
  }

  render() {
    require('moment/locale/pt-br.js');
    moment.locale('pt-br');

    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={this.props.modalHide}
        container={this}
        aria-labelledby="contained-modal-title"
        dialogClassName="historico-despacho-modal"
      >
        <Modal.Body>
          {console.log('dados corrida', this.props.corrida)}

          {this.props.corrida &&
            this.props.corrida.dados_corrida &&
            this.props.corrida.dados_corrida.despacho_operador &&
            <div style={{ marginBottom: 10 }}>Corrida associada pelo operador!</div>
          }
          {this.props.corrida &&
            this.props.corrida.dados_corrida &&
            this.props.corrida.dados_corrida.lista &&
            <div style={{ marginBottom: 10 }}>Corrida não foi despachada, foi pega através da lista!</div>
          }
          {this.props.corrida &&
            this.props.corrida.dados_corrida &&
            this.props.corrida.dados_corrida.corridaPendente &&
            this.props.corrida.dados_corrida.corridaPendente.todosMotoristasCompleto &&
            <Row className="titulo-antigos">
              <Col xs={2} md={2}>
                <div>Posição</div>
              </Col>
              <Col xs={2} md={2}>
                <div>Nome</div>
              </Col>
              <Col xs={2} md={2}>
                <div>Distância</div>
              </Col>
              <Col xs={2} md={2}>
                <div>Aceite</div>
              </Col>
              <Col xs={2} md={2}>
                <div>Hora</div>
              </Col>
            </Row>
          }
          {this.props.corrida &&
            this.props.corrida.dados_corrida &&
            this.props.corrida.dados_corrida.corridaPendente &&
            this.props.corrida.dados_corrida.corridaPendente.todosMotoristasCompleto &&
            this.props.corrida.dados_corrida.corridaPendente.todosMotoristasCompleto.map((m, i) => {
              let motoRecusado;
              let vencedor = this.props.corrida.t12_cpf_sec ? this.props.corrida.t12_cpf_sec.toString() : '0';
              let atual = m.key ? m.key.toString().replace(m.central_id + ':', '') : '0';
              console.log('corridaPendente.recusado 1', m)
              if (this.props.corrida.dados_corrida.corridaPendente.recusado) {
                console.log('corridaPendente.recusado 2', this.props.corrida.dados_corrida.corridaPendente.recusado)
                motoRecusado = this.props.corrida.dados_corrida.corridaPendente.recusado.findIndex(obj => obj.motorista.at === m.porta);
              }
              let moto = this.props.corrida.dados_corrida.corridaPendente.aceito.findIndex(obj => obj.motorista.at === m.porta);
              let restmoto = this.props.corrida.dados_corrida.todosaceitos ? this.props.corrida.dados_corrida.todosaceitos.findIndex(t => t.motorista.at === m.porta) : {};
              let obj = this.props.corrida.dados_corrida.corridaPendente.aceito[moto];
              let obj2 = this.props.corrida.dados_corrida.todosaceitos ? this.props.corrida.dados_corrida.todosaceitos[restmoto] : {};
              console.log("UEEEEE", restmoto)
              console.log("WATAFOKE MAN", obj2)
              let objRecusado = this.props.corrida.dados_corrida.corridaPendente.recusado[motoRecusado];

              return (
                <Row style={{ backgroundColor: vencedor === atual ? 'rgb(159,245,249)' : 'white' }} key={i} className="enderecos-antigos">
                  <Col xs={2} md={2}>
                    <div>{i + 1}°</div>
                  </Col>
                  <Col xs={2} md={2}>
                    <div>{m.nome}</div>
                  </Col>
                  <Col xs={2} md={2}>
                    <div>{m.distance.toFixed(0) === '0' ? 'Por Referência' : m.distance.toFixed(0) + ' metros'}</div>
                  </Col>
                  <Col xs={2} md={2}>
                    <div style={{ color: moto > -1 || restmoto > -1 ? 'green' : 'red' }}>{moto > -1 || restmoto > -1 ? 'Aceitou' : (objRecusado && objRecusado.batido) || (!objRecusado && !obj) ? "Batido" : 'Recusou'}</div>
                  </Col>
                  <Col xs={2} md={2}>
                    <div>{moto > -1 || restmoto > -1 ? (moto > -1 ? moment(obj.dataHora).format('HH:mm:ss') : moment(obj2.dataHora).format('HH:mm:ss')) : '----------'}</div>


                  </Col>
                </Row>
              );
            })}

        </Modal.Body>
      </Modal>
    );
  }
}
export default HistoricoDespacho;
