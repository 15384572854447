import React, { Component } from 'react';
import './Corridas.css';
import './Bubbles.css';
import WS from '../services/webservice';
import { Col, Form, FormGroup,  FormControl, Button, InputGroup } from 'react-bootstrap'

class Buscamoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensagens: [],
      estado: false,
      mensagemcarregada: [],
      mensagematual: [],
      selecionado: [],
      id: '',
      motoristas: [],
      loaded: false,
      forceRt: false,
      loadedbusca: true,
      buscaRt: null,
      error: null
    }
    this.updateData = this.updateData.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.BuscarRT = this.BuscarRT.bind(this);
    this.handleBusca = this.handleBusca.bind(this);
    this.chanceRT = this.chanceRT.bind(this);
    this.forceAT = this.forceAT.bind(this);
    this.handleAtual = this.handleAtual.bind(this);
    this.teste = this.teste.bind(this);
    this.despachocorrida = this.despachocorrida.bind(this);
    this.webservice = new WS();


  }
  async componentDidMount() {
    this.timerId = setInterval(this.updateData, 15000);
    await this.updateData();
  }


  onchangeid(id) {
    this.setState({ id: id })
  }

  despachocorrida(id, uuid, central) {
    console.log("SOU ID", central)
    this.webservice.despacho(id, uuid, central);
    alert("Corrida enviada");
    this.props.handleClose();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  async handleAtual(item) {
    var msg = [];
    msg.cpf = item.cpf;
    msg.rt = item.rt;
    msg.at = item.at;
    msg.uuid = item.uuid;
    this.setState({ mensagematual: msg });

    this.handleClick(item.cpf);
  }

  async handleClick(key) {
    try {
      let result = await this.webservice.fetch2('mensagens?cpf=' + key);
      var msgc = await result.json(result.result);
      this.setState({
        mensagemcarregada: msgc,
        showMsgDetails: true
      });
    } catch (err) {
      this.setState({ error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }
  async updateData() {
    try {
      //let result = await this.webservice.fetch2('mensagens?centrais=' + this.props.childProps.userAttrib.centralString.ids);
      //var msg = await result.json();

      this.setState({
        mensagens: []
      });
      this.setState({ error: null, loaded: true });
    } catch (err) {
      this.setState({ loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }


  async handleBusca() {
    if (!this.state.buscaRt && !this.state.buscaAT) {
      return;
    }
    try {
      var busca = "";
      busca = "?busca=" + this.state.buscaRt;

      let result = await this.webservice.fetch2('motoristas/search' + busca + '&centrais=' + this.props.central.centralString.ids);
      var msgc = await result.json(result);
      this.setState({
        motoristas: msgc
      })
      this.setState({ error: null, loadedbusca: true });
      let cont = this.state.motoristas.length;
      this.state.motoristas.map(m=>{
        if(m.online ){
          if(m.livre === true){
            console.log("MOTORISTINHA", m)
            var r = window.confirm("Deseja atribuir a corrida para " + m.nome + " Placa: " + m.placa);
            if(r === true){
              console.log("oi menina", m.uuid333)
              this.despachocorrida(this.props.id, m.uuid333, m.central_id);
            }
          }else{
            alert("Motorista ocupado")
          }
          
        }else{
          cont = cont - 1;
          if(cont === 0){
          alert("Motorista offline");
        }
        }
        return null
      })

    } catch (err) {
      this.setState({ loadedbusca: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }
  handleClose() {
    this.setState({
      showMsgDetails: false,
    })
  }
  chanceRT(e) {
    var value = e.target.value;
    this.setState({
      buscaRt: value
    })
  }
  forceAT(e) {
    var value = e.target.checked;
    this.setState({
      forceRt: value
    })
  }
  BuscarRT(e) {
    //var value = e.target.value;
    this.handleBusca();
    return true
  }

  teste(id, cpf) {
    //console.log(id);
  }


  render() {
    return (
      <div>

      <Form horizontal>
        <FormGroup controlId="formHorizontalEmail">

          <Col sm={10}>
            <InputGroup>


              <FormControl ref={ref => this.txtRt = ref}
                 style={{height: 40}}placeholder="Busca" onChange={(v) => this.chanceRT(v)} onKeyDown = {e => e.keyCode === 13 && this.BuscarRT(e) && e.preventDefault()} autoFocus
                />
              <InputGroup.Button>
                <Button onClick={this.BuscarRT} style={{height: 40}}>Buscar</Button>
              </InputGroup.Button>
            </InputGroup>
          </Col>

          </FormGroup>
        </Form>



      </div>
    );
  }
}
export default Buscamoto;
