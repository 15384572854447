import WS from '../services/webservice';
import React, { Component } from 'react';
import Dialog from 'react-bootstrap-dialog-v2'
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import Loader from 'react-loader';
import { FormGroup, ControlLabel, FormControl, Button, Image } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  width: 200,
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  borderRadius: 10,

  // change background colour if dragging
  background: isDragging ? 'lightgrey' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  height: 'auto',
  padding: grid,
  overflow: 'auto',
});

class Transbordo extends Component {

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      codigos: [],
      codigosProibidos: '',
      codigosPermitidos: '',
      centrais: [
        {
          id: 'CN1',
          text: 'CN1'
        },
        {
          id: 'CN2',
          text: 'CN2'
        },
        {
          id: 'CN3',
          text: 'CN3'
        }
      ],
      centralSelected: '',
      loaded: true,
    };

    this.onDragEnd = this.onDragEnd.bind(this);

    this.webservice = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {
    if (this.props.childProps.centralData) {
      this.setState({ loaded: false })
      this.webservice.getCentral(this.props.childProps.centralData.id).then(result2 => {
        console.log(result2);
        if (result2.transbordo) {
          this.setState({ items: result2.transbordo.regras }, () => this.setState({ loaded: true }))
        } else {
          this.setState({ loaded: true })
        }
      })
    }

    if (this.webservice.getCookie('central-id')) {
      //Get options from DB to multiselect fields(type of payment, cars, runs and clients)
      this.webservice.getCentrais().then(result => {
        result = result.map((m, i) => {
          return {
            id: m.id,
            text: m.nomefantasia,
          }
        })
        this.setState({ centrais: result })
      })
      let codigos = [];
      this.webservice.getOptions('pagto', true).then(result => {
        codigos = [...codigos, { text: "Pagamentos", children: result }];
      })
      this.webservice.getOptions('carro', true).then(result => {
        codigos = [...codigos, { text: "Carros", children: result }];
      })
      this.webservice.getOptions('corrida', true).then(result => {
        codigos = [...codigos, { text: "Corridas", children: result }];
      })
      this.webservice.getOptions('cliente', true).then(result => {
        codigos = [...codigos, { text: "Clientes", children: result }];
        this.setState({ codigos: codigos })
      })
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  getSelectedOptionsValue(selectedOptions, toInt = false) {
    let values = [];

    if (toInt) {
      for (let j = 0; j < selectedOptions.length; j++) {
        values.push(parseInt(selectedOptions[j].value, 10));
      }
    } else {
      for (let i = 0; i < selectedOptions.length; i++) {
        values.push(selectedOptions[i].value);
      }
    }

    return values;
  }

  render() {
    return (
      <div>
        &nbsp;
        <Button
          bsStyle="primary"
          disabled={this.state.items.length < 6 ? false : true}
          onClick={() => {
            let rulesCopy = JSON.parse(JSON.stringify(this.state.items))
            rulesCopy.push({
              id: rulesCopy.length + 1,
              descricao: "",
              sequencia: [],
              tipotransbordo: "despacho",
              codigosproibidos: [],
              codigospermitidos: [],
            });
            this.setState({ items: rulesCopy });
          }} >Nova Regra</Button>&nbsp;
        <Button
          bsStyle="danger"
          onClick={() => {
            Dialog.setOptions({
              defaultOkLabel: 'Sim',
              defaultCancelLabel: 'Não',
              primaryClassName: 'btn-danger '
            })
            this.dialog.show({
              body: 'Deseja remover a última regra??',
              actions: [
                Dialog.CancelAction(() => { }),
                Dialog.OKAction(() => {
                  this.setState({ loaded: false })
                  let rulesCopy = JSON.parse(JSON.stringify(this.state.items))
                  rulesCopy.pop();
                  this.setState({ items: rulesCopy, loaded: true });
                }),
              ],
              bsSize: 'small',
              onHide: (dialog) => {
                dialog.hide()
              },
            })

          }} >Remover Regra</Button>&nbsp;
        <Button bsStyle="success"
          onClick={() => {
            Dialog.setOptions({
              defaultOkLabel: 'Sim',
              defaultCancelLabel: 'Não',
              primaryClassName: 'btn-danger '
            })
            this.dialog.show({
              body: 'Deseja salvar as regras?',
              actions: [
                Dialog.CancelAction(() => { }),
                Dialog.OKAction(() => {
                  this.setState({ loaded: false })
                  this.webservice.setCentralRules(this.state.items).then(result => {
                    console.log(result)
                    this.webservice.getCentral(this.props.childProps.centralData.id).then(result2 => {
                      console.log(result2);
                      this.setState({ items: result2.transbordo.regras, loaded: true })
                    })
                  })
                }),
              ],
              bsSize: 'small',
              onHide: (dialog) => {
                dialog.hide()
              },
            })
          }}
        >Salvar Regras</Button>
        <br />
        <br />
        <Loader loaded={this.state.loaded}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {this.state.items.map((item, index) => {
                    let keys = Object.keys(item);
                    return (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <p className="rule-title"><b>{(index + 1) + "ª REGRA"}</b></p>
                            {keys.map((key, i) => {
                              if (i === 0 || i === 3) return null;
                              if (i === 4 || i === 5) {
                                return (
                                  <FormGroup className="autoComp" style={{ padding: 5 }}>
                                    <ControlLabel>{i === 4 ? "Códigos Proibidos" : "Códigos Permitidos"}</ControlLabel>
                                    <Select2
                                      multiple
                                      value={this.state.items[index][key]}
                                      data={this.state.codigos}
                                      onSelect={e => {
                                        this.setState({ ["codigos_" + index + "_" + i]: this.getSelectedOptionsValue(e.target.selectedOptions) });
                                        let rulesCopy = JSON.parse(JSON.stringify(this.state.items))
                                        rulesCopy[index][key] = this.state["codigos_" + index + "_" + i];
                                        this.setState({ items: rulesCopy }, () => console.log(rulesCopy));
                                      }}
                                      onUnselect={e => {
                                        this.setState({ ["codigos_" + index + "_" + i]: this.getSelectedOptionsValue(e.target.selectedOptions) });
                                        let rulesCopy = JSON.parse(JSON.stringify(this.state.items))
                                        rulesCopy[index][key] = this.state["codigos_" + index + "_" + i];
                                        this.setState({ items: rulesCopy });
                                      }}
                                    />
                                  </FormGroup>
                                );
                              } else if (i === 2) {
                                return (
                                  <div style={{ border: 'solid 1px black' }}>
                                    <div style={{ textAlign: 'center' }}>
                                      <ControlLabel>Sequência</ControlLabel>&nbsp;
                                    <Image
                                        style={{ cursor: 'pointer' }}
                                        src={require('../images/plus.png')}
                                        onClick={() => {
                                          let rulesCopy = JSON.parse(JSON.stringify(this.state.items))
                                          rulesCopy[index][key] = [...rulesCopy[index][key], { centrais: [], tempominimo: 0, despachosminimos: 0 }]
                                          this.setState({ items: rulesCopy });
                                        }} />&nbsp;
                                    <Image
                                        style={{ cursor: 'pointer' }}
                                        src={require('../images/remove.png')}
                                        onClick={() => {
                                          let rulesCopy = JSON.parse(JSON.stringify(this.state.items))
                                          rulesCopy[index][key].pop();
                                          this.setState({ items: rulesCopy });
                                        }} />
                                    </div>
                                    {item[key].map((item2, i2) => {
                                      let keys2 = Object.keys(item2)
                                      return keys2.map((keys2, k2) => {
                                        if (k2 === 0) {
                                          return (
                                            <FormGroup className="autoComp" style={{ padding: 5 }}>
                                              <ControlLabel>{k2 === 0 ? "Centrais" : k2 === 1 ? "Tempo Mínimo" : "Despachos Mínimos"}</ControlLabel>
                                              <Select2
                                                multiple
                                                value={this.state.items[index][key][i2][keys2]}
                                                data={this.state.centrais}
                                                options={{
                                                  placeholder: '',
                                                }}
                                                onSelect={e => {
                                                  this.setState({ ["centrais_" + index + "_" + i2 + "_" + k2]: this.getSelectedOptionsValue(e.target.selectedOptions, true) });
                                                  let rulesCopy = JSON.parse(JSON.stringify(this.state.items))
                                                  rulesCopy[index][key][i2][keys2] = this.state["centrais_" + index + "_" + i2 + "_" + k2];
                                                  this.setState({ items: rulesCopy }, () => console.log(rulesCopy));
                                                }}
                                                onUnselect={e => {
                                                  this.setState({ ["centrais_" + index + "_" + i2 + "_" + k2]: this.getSelectedOptionsValue(e.target.selectedOptions, true) });
                                                  let rulesCopy = JSON.parse(JSON.stringify(this.state.items))
                                                  rulesCopy[index][key][i2][keys2] = this.state["centrais_" + index + "_" + i2 + "_" + k2];
                                                  this.setState({ items: rulesCopy });
                                                }}
                                              />
                                            </FormGroup>
                                          );
                                        } else {
                                          return (
                                            <FormGroup style={{ padding: 5 }}>
                                              <ControlLabel>{k2 === 1 ? "Tempo Mínimo" : "Despachos Mínimos"}</ControlLabel>
                                              <FormControl
                                                type="text"
                                                value={this.state.items[index][key][i2][keys2]}
                                                onChange={e => {
                                                  let res;
                                                  if (e.target.value.length <= 0) res = 0;
                                                  else res = e.target.value;
                                                  let rulesCopy = JSON.parse(JSON.stringify(this.state.items))
                                                  rulesCopy[index][key][i2][keys2] = parseInt(res, 10);
                                                  this.setState({ items: rulesCopy });
                                                }}
                                              />
                                            </FormGroup>
                                          );
                                        }
                                      })
                                    })}
                                  </div>
                                )
                              }
                              return (
                                <FormGroup>
                                  <ControlLabel>Descrição</ControlLabel>
                                  <FormControl
                                    type="text"
                                    placeholder="Descrição"
                                    value={item[key]}
                                    onChange={e => {
                                      let rulesCopy = JSON.parse(JSON.stringify(this.state.items))
                                      rulesCopy[index][key] = e.target.value;
                                      this.setState({ items: rulesCopy });
                                    }}
                                  />
                                </FormGroup>
                              );
                            })}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Loader>
        <Dialog ref={(el) => { this.dialog = el }} />
      </div>
    );
  }
}
export default Transbordo;