import React from 'react';
import Linkify from 'react-linkify';


const SystemMessage = (props) => {
  return <div className="sc-message--system">
    {props.data.text}
  </div>
}

export default SystemMessage
