import React, { Component } from 'react';
import WS from '../services/webservice.js';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export default class PrecadastroNew extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: [],
      suggestions: [],
      carroApi: {}
    }
    this.ws = new WS();
    //this.updateRef = this.updateRef.bind(this);
    this.subprecadastro_ref = this.updateRef.bind(this, 'subprecadastro');
    this.newPrecadastro = this.newPrecadastro.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderTonggle = this.renderTonggle.bind(this);
    this.renderPlaca = this.renderPlaca.bind(this);
    this.fetchCarro = this.fetchCarro.bind(this);
    this.renderCarro = this.renderCarro.bind(this);
  }
  async fetchData(nome) {
    // // show the loading overlay

    let result = await this.ws.getTipo('atuacaocidade', '', {id: 'nome'}, 1, 10, [{id: 'nome', value: nome}], null);
    let count = result.headers.get('X-Total-Count');
    result = await result.json();

    this.setState({
      suggestions: result
    })
  }
  renderTonggle() {
    return (
      <div
        style={{
          border: "1.5px solid #000",
          borderRadius: "10px",
          padding: "5px",
          fontWeight: "bold",
        }}
        onClick={() => this.setState({ showFotos: !this.state.showFotos })}
      >
        {this.state.showFotos ? "Mostrar Cadastro" : "Mostrar Fotos"}
</div>
    );
  }

  renderCarro() {
    console.log('renderCarro', this.state.carroApi)
    if(!this.state.carroApi){
      return '';
    }

    if(!!this.state.carroApi.error){
      return (
        <div style={{fontWeight: "bold", color: "red"}}>
          {this.state.carroApi.error}
        </div>
      );
    }

    if(!this.state.carroApi.MARCA){
      return '';
    }

    return (
      <div>
      <div>
        <b>Placa:</b> {this.state.carroApi.placa}
      </div>
        <div>
          <b>Marca:</b> {this.state.carroApi.MARCA}
        </div>
        <div>
          <b>Modelo:</b> {this.state.carroApi.MODELO}
        </div>
        <div>
          <b>Ano:</b> {this.state.carroApi.ano}/{this.state.carroApi.anoModelo}
        </div>
        <div>
          <b>Cor:</b> {this.state.carroApi.cor}
        </div>
      </div>
    );
  }

  async fetchCarro() {
    let result = await this.ws.getPlaca(this.state.record.carro_placa_do_detran);
    console.log('fetchCarro', result);
    this.setState({ carroApi: result });
  }

  renderPlaca() {
    return (
      <div
        style={{
          border: "1.5px solid #000",
          borderRadius: "10px",
          padding: "5px",
          fontWeight: "bold",
        }}
        onClick={this.fetchCarro}
      >
        Carregar Carro
</div>
    );
  }
  updateRef(name, ref) {
    this[name] = ref;
  }
  mirrorState(key) {
    console.log('mirrorState', key)
    this.setState(key);
  }
  async motoristas(p, after) {
    alert("Aguarde Processamento!")
    console.log('precadastro', p)
    await this.ws.efetivaprecadastrosNew(p.id);
    alert("Processamento concluido!")
    if (after) {
      after();
    }
    //console.log('motoristas 2', p.motorista)
  }
  async newPrecadastro(after) {
    this.subprecadastro.newClickedExterno(after);
  }
  render() {
    return (
      <><CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Pré-Cadastro",
          tipo_plural: "Pré-Cadastros",
          href: "precadastros",
          titulo: "Cadastro de Pré-Cadastro",
          mirrorState: ((key) => { this.setState(key); }).bind(this),
          requests: {
            type: 'precadastros_new',
            list: true,
            new: false,
            newOut: this.newPrecadastro.bind(this),
            save: true,
            edit: true,
            delete: true,
            listSub: true,
            outros: [
              { icone: 'FaUsers', tamanho: 30, cor: 'blue', texto: 'Efetivar Cadastro', funcao: this.motoristas.bind(this) }
            ],
          },
          autocomplete: {
            func: this.fetchData,
            suggestions: this.state.suggestions,
          },
          organizado: this.state.showFotos ? [[{ ref: "tonggleFotos", tipo: "fct", function: this.renderTonggle }],
          [{ref: 'ident', tipo: 'texto', texto:'FOTO'}, {ref: 'ident', tipo: 'texto', texto:'CNH'},],
          [{ref: 'fotobase64', tipo: 'campo', hidelabel: true}, {ref: 'fotobase64_1', tipo: 'campo', hidelabel: true}],
          [{ref: 'ident', tipo: 'texto', texto:'CRLV'}, {ref: 'ident', tipo: 'texto', texto:'COMPROVANTE'}],
          [{ref: 'fotobase64_2', tipo: 'campo', hidelabel: true}, {ref: 'fotobase64_3', tipo: 'campo', hidelabel: true}],

          [{ref: 'ident', tipo: 'texto', texto:'foto_carro_1'}, {ref: 'ident', tipo: 'texto', texto:'foto_carro_2'}],
          [{ref: 'foto_carro_1', tipo: 'campo', hidelabel: true}, {ref: 'foto_carro_2', tipo: 'campo', hidelabel: true}],
          [{ref: 'ident', tipo: 'texto', texto:'foto_carro_3'}, {ref: 'ident', tipo: 'texto', texto:'foto_carro_4'}],
          [{ref: 'foto_carro_3', tipo: 'campo', hidelabel: true}, {ref: 'foto_carro_4', tipo: 'campo', hidelabel: true}],
          [{ref: 'ident', tipo: 'texto', texto:'foto_carro_5'}, {ref: 'ident', tipo: 'texto', texto:'foto_carro_6'}],
          [{ref: 'foto_carro_5', tipo: 'campo', hidelabel: true}, {ref: 'foto_carro_6', tipo: 'campo', hidelabel: true}],
          [{ref: 'ident', tipo: 'texto', texto:'foto_carro_7'}],
          [{ref: 'foto_carro_7', tipo: 'campo', hidelabel: true}],
        
        ] : [
          [{ ref: "tonggleFotos", tipo: "fct", function: this.renderTonggle }],

            [
              {ref: 'cidadeatuacao', tipo: 'campo'},
              {ref: 'cpf', tipo: 'campo'},
              {ref: 'nome_completo', tipo: 'campo'}
            ],

            [
              {ref: 'email', tipo: 'campo'},
              {ref: 'senha', tipo: 'campo'},
            ],
            [
              {ref: 'data_de_nascimento', tipo: 'campo'},
              { ref: "chave_pix", tipo: 'campo'},
            ],
            [
              {ref: 'cep', tipo: 'campo'},
              {ref: 'rua', tipo: 'campo'}
            ],
            [
              {ref: 'numero', tipo: 'campo'},
              {ref: 'complemento', tipo: 'campo'},
            ],
            [
              {ref: 'bairro', tipo: 'campo'},
              {ref: 'cidade', tipo: 'campo'},
              {ref: 'uf', tipo: 'campo'},
            ],
            [
              {ref: 'rg', tipo: 'campo'},
              {ref: 'telefone', tipo: 'campo'},
            ],
            [
              {ref: 'cnh', tipo: 'campo'},
              {ref: 'cnh_data_de_vencimento', tipo: 'campo'},
            ],
            [
          ],
          [
            { ref: "carro_renavam", tipo: 'campo'},
            { ref: "carro_placa_do_detran", tipo: 'campo'},
            { ref: "renderPlaca", tipo: "fct", function: this.renderPlaca },
          ],
          [
            { ref: "renderCarro", tipo: "fct", function: this.renderCarro },
          ],
          ],
          subtipos: [
            { tipo: 'status', p: 'oarray', array: [{ txt: 'Ativo', id: true }, { txt: 'Inativo', id: false }] },
            { tipo: 'simnao', p: 'oarray', array: [{ txt: 'Sim', id: true }, { txt: 'Não', id: false }] },
            { tipo: 'marcaveiculo', id: 't17_cd_marca', txt: 't17_desc_carro', p: 'get' },
            { tipo: 'modeloveiculo', id: 't18_cd_modelo', txt: 't18_desc_modelo', p: 'get' },
            { tipo: 'atuacaocidade', id: 'nome', txt: 'nome', p: 'get' },
            { tipo: 'estados', p: 'oarray', array: [{txt: "Acre", id: "AC"},
            {txt: "Alagoas", id: "AL"},
            {txt: "Amapá", id: "AP"},
            {txt: "Amazonas", id: "AM"},
            {txt: "Bahia", id: "BA"},
            {txt: "Ceará", id: "CE"},
            {txt: "Distrito Federal", id: "DF"},
            {txt: "Espírito Santo", id: "ES"},
            {txt: "Goiás", id: "GO"},
            {txt: "Maranhão", id: "MA"},
            {txt: "Mato Grosso", id: "MT"},
            {txt: "Mato Grosso do Sul", id: "MS"},
            {txt: "Minas Gerais", id: "MG"},
            {txt: "Pará", id: "PA"},
            {txt: "Paraíba", id: "PB"},
            {txt: "Paraná", id: "PR"},
            {txt: "Pernambuco", id: "PE"},
            {txt: "Piauí", id: "PI"},
            {txt: "Rio de Janeiro", id: "RJ"},
            {txt: "Rio Grande do Norte", id: "RN"},
            {txt: "Rio Grande do Sul", id: "RS"},
            {txt: "Rondônia", id: "RO"},
            {txt: "Roraima", id: "RR"},
            {txt: "Santa Catarina", id: "SC"},
            {txt: "São Paulo", id: "SP"},
            {txt: "Sergipe", id: "SE"},
            {txt: "Tocantins", id: "TO"}]}
          ],
          campos: [
            { ref: "central", label: "Central", type: "justlabel", default: this.ws.getCookie('central-id')},
            { ref: "cidadeatuacao", label: "Cidade de Atuação", type: "AutoCompleteCity"},
            { ref: "cpf", label: "CPF", type: "text"},
            { ref: "nome_completo", label: "Nome", type: "text", },
            { ref: "data_de_nascimento", label: "Data de Nascimento", type: "date", },
            { ref: "cep", label: "CEP", type: "cep", cepdados: {
              uf:"uf",
              cidade: "cidade",
              bairro:"bairro",
              rua:"rua",
            }},
            { ref: "rua", label: "Endereço", type: "text", },
            { ref: "numero", label: "Número", type: "text", },
            { ref: "complemento", label: "Complemento", type: "text", },
            { ref: "bairro", label: "Bairro", type: "text", },
            { ref: "cidade", label: "Cidade", type: "text", },
            { ref: "uf", label: "UF", type: "text", },
            { ref: "telefone", label: "Telefone", type: "text", },
            { ref: "email", label: "E-mail", type: "text", },
            { ref: "rg", label: "RG", type: "text", },
            { ref: "cnh", label: "CNH", type: "text", },
            { ref: "cnh_data_de_vencimento", label: "Vencimento", type: "date", },
            { ref: "mot_prop", label: "É proprietário?", type: "select", listtype: 'simnao', },
            { ref: "chave_pix", label: "Chave Pix", type: "text", },
            { ref: "carro_renavam", label: "Renavam", type: "maskedtext", mask:"9999999999999"},
            { ref: "carro_placa_do_detran", label: "Placa", type: "text", },
            { ref: "senha", label: "Senha", type: "text", },
            { ref: "fotobase64", label: "fotobase64", type: "foto64", },
            { ref: "fotobase64_1", label: "fotobase64_1", type: "foto64", },
            { ref: "fotobase64_2", label: "fotobase64_2", type: "foto64", },
            { ref: "fotobase64_3", label: "fotobase64_3", type: "foto64", },
            { ref: "foto_carro_1", label: "foto_carro_1", type: "foto64", },
            { ref: "foto_carro_2", label: "foto_carro_2", type: "foto64", },
            { ref: "foto_carro_3", label: "foto_carro_3", type: "foto64", },
            { ref: "foto_carro_4", label: "foto_carro_4", type: "foto64", },
            { ref: "foto_carro_5", label: "foto_carro_5", type: "foto64", },
            { ref: "foto_carro_6", label: "foto_carro_6", type: "foto64", },
            { ref: "foto_carro_7", label: "foto_carro_7", type: "foto64", },
          ],
          table: {
            colunas: [
              { Header: "Cidade de Atuação", accessor: "cidadeatuacao", },
              { Header: "Nome", accessor: "nome_completo", },
              { Header: "CPF", accessor: "cpf", },
              { Header: "E-mail", accessor: "email", },
            ],
          }
        }} /><CadastroHelper
        ref = {this.subprecadastro_ref}
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Pré-Cadastro",
          tipo_plural: "Pré-Cadastros",
          href: "precadastros",
          titulo: "Cadastro de Pré-Cadastro",
          mirrorState: ((key) => {}).bind(this),
          onlymodal: true,
          requests: {
            type: 'precadastros_new',
            list: true,
            new: false,
            save: true,
            edit: true,
            delete: true,
            listSub: true,
          },
          autocomplete: {
            func: this.fetchData,
            suggestions: this.state.suggestions,
          },
          organizado: [
            [
              {ref: 'centralnome', tipo: 'campo'},
              {ref: 'cidadeatuacao', tipo: 'campo'},
            ],
            [
              {ref: 'cpf', tipo: 'campo'},
              {ref: 'nome_completo', tipo: 'campo'}
            ],

            [
              {ref: 'email', tipo: 'campo'},
            ],
            [
              {ref: 'senha', tipo: 'campo'},
            ],
            [
              {ref: 'data_de_nascimento', tipo: 'campo'},
              {ref: 'nome_da_mae', tipo: 'campo'},
            ],
            [
              {ref: 'cep', tipo: 'campo'}
            ],
            [
              {ref: 'rua', tipo: 'campo'}
            ],
            [
              {ref: 'numero', tipo: 'campo'},
              {ref: 'complemento', tipo: 'campo'},
            ],
            [
              {ref: 'bairro', tipo: 'campo'},
            ],
            [
              {ref: 'cidade', tipo: 'campo'},
              {ref: 'uf', tipo: 'campo'},
            ],
            [
              {ref: 'rg', tipo: 'campo'},
              {ref: 'telefone', tipo: 'campo'},
            ],
            [
              {ref: 'cnh', tipo: 'campo'},
              {ref: 'cnh_data_de_vencimento', tipo: 'campo'},
            ],
            [
            { ref: "banco_nome", tipo: 'campo'},
            { ref: "banco_n_da_agencia", tipo: 'campo'},
          ],
          [
            { ref: "banco_n_da_conta", tipo: 'campo'},
            { ref: "banco_tipo_da_conta", tipo: 'campo'},
          ],],
          subtipos: [
            { tipo: 'status', p: 'oarray', array: [{ txt: 'Ativo', id: true }, { txt: 'Inativo', id: false }] },
            { tipo: 'simnao', p: 'oarray', array: [{ txt: 'Sim', id: true }, { txt: 'Não', id: false }] },
            { tipo: 'marcaveiculo', id: 't17_cd_marca', txt: 't17_desc_carro', p: 'get' },
            { tipo: 'modeloveiculo', id: 't18_cd_modelo', txt: 't18_desc_modelo', p: 'get' },
            { tipo: 'marcamodeloveiculo', id: 't18_desc_modelo', txt: 'descricao', p: 'get' },
            { tipo: 'anocarro', p:'array', array: [...Array(15).keys()].map(x => Number(moment().year()) - x + 1)},
            { tipo: 'atuacaocidade', id: 'nome', txt: 'nome', p: 'get' },
            { tipo: 'corcarro', p: 'array', array: ['BRANCO','PRATA','PRETO','CINZA','VERMELHO','AZUL','BEGE','VERDE','MARROM','DOURADO','AMARELO','LARANJA']},
            { tipo: 'combustivel', p: 'array', array: [ 'GASOLINA', 'ETANOL', 'GNV', 'FLEX', 'TETRAFUEL']},
            { tipo: 'categoria', p: 'array', array: ['Carro Convencional','Carro Executivo','Moto','Guincho']},
            { tipo: 'estados', p: 'oarray', array: [{txt: "Acre", id: "AC"},
            {txt: "Alagoas", id: "AL"},
            {txt: "Amapá", id: "AP"},
            {txt: "Amazonas", id: "AM"},
            {txt: "Bahia", id: "BA"},
            {txt: "Ceará", id: "CE"},
            {txt: "Distrito Federal", id: "DF"},
            {txt: "Espírito Santo", id: "ES"},
            {txt: "Goiás", id: "GO"},
            {txt: "Maranhão", id: "MA"},
            {txt: "Mato Grosso", id: "MT"},
            {txt: "Mato Grosso do Sul", id: "MS"},
            {txt: "Minas Gerais", id: "MG"},
            {txt: "Pará", id: "PA"},
            {txt: "Paraíba", id: "PB"},
            {txt: "Paraná", id: "PR"},
            {txt: "Pernambuco", id: "PE"},
            {txt: "Piauí", id: "PI"},
            {txt: "Rio de Janeiro", id: "RJ"},
            {txt: "Rio Grande do Norte", id: "RN"},
            {txt: "Rio Grande do Sul", id: "RS"},
            {txt: "Rondônia", id: "RO"},
            {txt: "Roraima", id: "RR"},
            {txt: "Santa Catarina", id: "SC"},
            {txt: "São Paulo", id: "SP"},
            {txt: "Sergipe", id: "SE"},
            {txt: "Tocantins", id: "TO"}]},
          ],
          campos: [
            { ref: "central", label: "Cód Central", type: "disabled", default: this.ws.getCookie('central-id')},
            { ref: "cidadeatuacao", label: "Cidade de Atuação", type: "AutoCompleteCity"},
            { ref: "cpf", label: "CPF", type: "maskedtext", mask:"999.999.999-99", presave: (n) => {return Number(n.replace(/[^+\d]/g, ''))} },
            { ref: "nome_completo", label: "Nome", type: "text", },
            { ref: "data_de_nascimento", label: "Data de Nascimento", type: "date", },
            { ref: "nome_da_mae", label: "Nome da mãe", type: "text", },
            { ref: "cep", label: "CEP", type: "cep", cepdados: {
              uf:"uf",
              cidade: "cidade",
              bairro:"bairro",
              rua:"rua",
            }},
            { ref: "rua", label: "Endereço", type: "text", },
            { ref: "numero", label: "Número", type: "text", },
            { ref: "complemento", label: "Complemento", type: "text", },
            { ref: "bairro", label: "Bairro", type: "text", },
            { ref: "cidade", label: "Cidade", type: "text", },
            { ref: "uf", label: "UF", type: "select", listtype: 'estados' },
            { ref: "telefone", label: "Telefone", type: "telefone", maskCel:'(99) 99999-9999' ,maskFixo:'(99) 9999-9999'},
            { ref: "email", label: "E-mail", type: "text", },
            { ref: "rg", label: "RG", type: "text", },
            { ref: "cnh", label: "CNH", type: "text", },
            { ref: "cnh_data_de_vencimento", label: "Vencimento", type: "date", },
            { ref: "carro_categoria", label: "Categoria do Carro", type: "select", listtype: 'categoria' },
            { ref: "carro_renavam", label: "Renavam", type: "maskedtext", mask:"9999999999999"},
            { ref: "carro_placa_do_detran", label: "Placa", type: "maskedtext", mask:"aaa9**9"},
            { ref: "senha", label: "Senha", type: "text", },
          ],
          table: {
            colunas: [
              { Header: "Cidade de Atuação", accessor: "cidadeatuacao", },
              { Header: "Nome", accessor: "nome_completo", },
              { Header: "CPF", accessor: "cpf", },
              { Header: "E-mail", accessor: "email", },
            ],
          }
        }} /></>
    );
  }
};
