import React, { Component } from "react";

import FontAwesome from './FontAwesome.json'
import AntDesign from './AntDesign.json'
import Entypo from './Entypo.json'
import EvilIcons from './EvilIcons.json'
import Feather from './Feather.json'
import Fontisto from './Fontisto.json'
import Foundation from './Foundation.json'
import Ionicons from './Ionicons.json'
import MaterialCommunityIcons from './MaterialCommunityIcons.json'
import MaterialIcons from './MaterialIcons.json'
import Octicons from './Octicons.json'
import SimpleLineIcons from './SimpleLineIcons.json'
import Zocial from './Zocial.json'

const familyList = {
  'FontAwesome' : FontAwesome,
  'AntDesign' : AntDesign,
  'Entypo' : Entypo,
  'EvilIcons' : EvilIcons,
  'Feather' : Feather,
  'Fontisto' : Fontisto,
  'Foundation' : Foundation,
  'Ionicons' : Ionicons,
  'MaterialCommunityIcons' : MaterialCommunityIcons,
  'MaterialIcons' : MaterialIcons,
  'Octicons' : Octicons,
  'SimpleLineIcons' : SimpleLineIcons,
  'Zocial' : Zocial
}


const IconFont = (props) => {
  let iconText = props.icone ? props.icone : 'FontAwesome:car'
  let size = props.size ? props.size : 15
  let color  = props.color ? props.color : 'Black'
  let org = iconText.split(":")
  let family = org[0]
  let icone = org[1]

  let familyJson = FontAwesome
  if (!!familyList[family]) {
    familyJson = familyList[family]
  }

  let charCode = 60095

  if (!!familyJson[icone]) {
    charCode = familyJson[icone]
  }

  return (<div style={{fontFamily: family, fontSize: size, color: color}}>
          {String.fromCharCode(charCode)}
        </div>)
};

const ListIconFont = () => {
  let saida = []

  try {
    let Familias = Object.keys(familyList);

    for (let index = 0; index < Familias.length; index++) {
      const family = Familias[index];
      let familyJson = FontAwesome
      if (!!familyList[family]) {
        familyJson = familyList[family]
      }
      let Icones = Object.keys(familyJson);
      for (let i = 0; i < Icones.length; i++) {
        const Icone = Icones[i];
        saida.push(family + ":" + Icone)
      }
    }
  } catch (error) {
    console.log(error)
  }


  return saida
};

export default {
  IconFont,
  ListIconFont
};