import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class Empresa extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.ws = new WS();
  }

  mirrorState(key) {
    this.setState(key);
  }

  async resetPass(id) {
    console.log('resetpass', id)
    let result = await this.ws.resetpassportal(id)
    if (result) {
      alert("Senha resetada para o padrão da central!")
    } else {
      alert("Ocorreu um erro!")
    }
  }

  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Empresa",
          tipo_plural: "empresas",
          href: "voucher_empresa",
          titulo: "Cadastro de Empresas",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'voucher_empresa',
            new: false,
            list: true,
            save: true,
            edit: true,
            delete: false,
            listSub: false,
          },
          campos: [
            {
              ref: "razaosocial",
              label: "Razão Social",
              type: "justlabel",
            },
            {
              ref: "cnpj",
              label: "Cnpj",
              type: "justlabel",
            },
            {
              ref: "telefone",
              label: "Telefone",
              type: "justlabel",
            },
            {
              ref: "faturas",
              label: "Faturas",
              type: "currencydisabled",
            },
            {
              ref: "corridas",
              label: "Não Faturadas",
              type: "currencydisabled",
            },
            { ref: "appicon", label: "Icone", type: "foto64",},
            {
              ref: "usuarios",
              label: "Lista de Usuários",
              type: "jsonarray",
              acampos: [
                {
                  ref: "nome",
                  label: "Usuário",
                  type: "justlabel",
                  accessor: "nome",
                  Header: "Usuário",
                },
                {
                  ref: "email",
                  label: "Email",
                  type: "justlabel",
                  accessor: "email",
                  Header: "Email",
                },
                {
                  ref: "reset",
                  label: "Reset Pass",
                  type: "action",
                  func: ((key) => {this.resetPass(key)}).bind(this),
                  param: "id",
                },
                
              ]
            },
          ],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Razão Social",
                accessor: "razaosocial",
              },
              {
                Header: "Cnpj",
                accessor: "cnpj",
              },
              {
                Header: "Telefone",
                accessor: "telefone",
              },
              {
                Header: "Faturas",
                id: "faturas",
                accessor: d => <NumberFormat value={Number(d.faturas)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />,
              },
              {
                Header: "Não Faturadas",
                id: "corridas",
                accessor: d => <NumberFormat value={Number(d.corridas)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />,
              },
            ],
          }
        }}
      />
    );
  }
}
export default Empresa;
