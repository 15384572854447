import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class Empresa extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: []
    }

  }

  mirrorState(key) {
    console.log('mirrorState', key)
    this.setState(key);
  }

  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "t18_cd_modelo",
          tipo_cadastro: "Modelo",
          tipo_plural: "Modelos",
          href: "modeloveiculo",
          titulo: "Cadastro de Modelos de Veículos",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'modeloveiculo',
            list: true,
            new: true,
            save: true,
            edit: true,
            delete: false,
            listSub: true,
            defaultorder: [{ id: "t18_desc_modelo" }, { id: "t18_cd_marca" }, { id: "t18_cd_modelo" }],
          },
          subtipos: [
            {tipo: 'marcaveiculo', id: 't17_cd_marca', txt: 't17_desc_carro', p:'get'},
            {tipo: 'tipo_carro', id: 't03_sg_tp_carro', txt: 't03_sg_tp_carro', p:'get', filter:[{id: "t03_ativo", value: true}]},
            {tipo: 'status',p:'oarray', array:[{ txt: 'Ativo', id: true }, { txt: 'Inativo', id: false }]},
            {tipo: 'tipo',p:'oarray', array:[{ txt: 'Carro', id: 0 }, { txt: 'Moto', id: 1 }, { txt: 'Guincho', id: 2 }]},
          ],
          campos: [
            {
              ref: "t18_cd_marca",
              label: "Marca",
              type: "select",
              listtype:'marcaveiculo',
            },
            {
              ref: "t18_desc_modelo",
              label: "Modelo",
              type: "text",
            },
            {
              ref: "t18_ativo",
              label: "Ativo",
              type: "select",
              listtype:'status',
            },
            {
              ref: "tipo",
              label: "Categoria",
              type: "select",
              listtype:'tipo',
            },
            {
              ref: "tipoveiculo",
              label: "Tipo",
              type: "checkboxnextedstringvirgula", 
              placeHolder: "", 
              listtype:'tipo_carro'
            },
          ],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Modelo",
                accessor: "t18_desc_modelo",
              },
              {
                Header: "Marca",
                accessor: "t18_cd_marca", 
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return 0;
                  }
                  return filter.value === row[filter.id];
                },
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : 0}
                  >
                    <option value={0}>Todos</option>
                    {this.state.sublistas.marcaveiculo && this.state.sublistas.marcaveiculo.map( (a,b) => {
                      return (
                        <option value={a.id}>{a.text}</option>
                      )
                    })}
                  </select>),
                Cell: row => {
                  return (
                    <div>
                      {this.state.sublistas.marcaveiculo.find(element => element.id === row.original.t18_cd_marca ) &&
                      this.state.sublistas.marcaveiculo.find(element => element.id === row.original.t18_cd_marca ).text
                      }
                    </div>
                  )
                }
              },
              {
                Header: "Categoria",
                accessor: "tipo", 
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return 0;
                  }
                  return filter.value === row[filter.id];
                },
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : 0}
                  >
                    <option value={0}>Todos</option>
                    {this.state.sublistas.tipo && this.state.sublistas.tipo.map( (a,b) => {
                      return (
                        <option value={a.id}>{a.text}</option>
                      )
                    })}
                  </select>),
                Cell: row => {
                  return (
                    <div>
                      {this.state.sublistas.tipo[row.original.tipo] && 
                      this.state.sublistas.tipo[row.original.tipo].text}
                    </div>
                  )
                }
              },
              {
                Header: "Ativo",
                accessor: "t18_ativo",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.t18_ativo ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
            ],
          }
        }}
      />
    );
  }
}
export default Empresa;