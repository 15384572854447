import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from './CadastroHelper.js';

class CadastroTipoCliente extends Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <CadastroHelper
        dados={{
          acessoID: '977107da-c9a5-40ef-9ca3-ec6f9f196211',
          parentProps: this.props,
          id: "t14_cd_tp_cliente",
          tipo_cadastro: "Tipo de cliente",
          tipo_plural: "clientes",
          href: "/tipo_cliente",
          titulo: "Cadastro de Tipos de Clientes",
          requests: {
            type: 'tipo_cliente',
            list: this.ws.getTipo.bind(this.ws),
            save: this.ws.criarTipo.bind(this.ws),
            edit: this.ws.alterarTipo.bind(this.ws),
            delete: this.ws.deleteTipo.bind(this.ws),
          },
          campos: [
            {
              ref: "t14_prioridade",
              label: "Prioridade",
              type: "number",
              placeHolder: "Escreva a prioridade do tipo de cliente",
            },            
            {
              ref: "t14_sg_tp_cliente",
              label: "Sigla",
              type: "text",
              placeHolder: "Escreva a sigla do tipo de cliente",
            },
            {
              ref: "t14_descr_tp_cliente",
              label: "Descrição",
              type: "text",
              placeHolder: "Escreva a descrição do tipo de cliente",
            },
            {
              ref: "t14_ativo",
              label: "Ativo",
              type: "checkbox",
            },
          ],
          botoes: [
            {
              label: "Criar Tipo",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Prioridade",
                accessor: "t14_prioridade",
                width: 80,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                    }}
                  >
                  {row.original.t14_prioridade}
                  </div>
                ),
              },
              {
                Header: "Ativo",
                accessor: "t14_ativo",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.t14_ativo ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
              {
                Header: "Sigla",
                accessor: "t14_sg_tp_cliente",
                width: 100,
              },
              {
                Header: "Descrição",
                accessor: "t14_descr_tp_cliente",
              },
              
            ],
          }
        }}
      />
    );
  }
}

export default CadastroTipoCliente;
