import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group'
import Loader from 'react-loader';
//import Push from 'push.js';
import './Corridas.css';
import './Bubbles.css';
//import GoogleMapReact from 'google-map-react';
import { BrowserRouter as Router } from "react-router-dom";
import moment from 'moment';
//import NewMsg from './NewMsg.js';
import {
  Modal, Table, Col, Row, Form, FormGroup, /*ToggleButtonGroup, ToggleButton, ButtonToolbar,*/
  ControlLabel, FormControl, Button, InputGroup, DropdownButton, MenuItem
} from 'react-bootstrap'
import WS from '../services/webservice';
import { fitBounds } from 'google-map-react/utils';


var FotoMoto = require('../images/taxi-laranja.png')

class Mensagens2 extends Component {

  static defaultProps = {
    center: { lat: -25.47, lng: -49.29 },
    zoom: 16
  };

  constructor(props) {
    super(props);
    this.state = {
      tipoenvio: 5,
      mensagens: [],
      mensagensTotal: [],
      mensagensAtivas: [],
      mensagemcarregada: [],
      showMsgDetails: false,
      showMsgDetailsMotorista: false,
      mensagematual: [],
      MtA: [],
      his: [],
      coratt: '',
      historico: [],
      op1: "1",
      enviando2: null,
      op2: "1",
      op3: "1",
      tipo_msg: [],
      respostas: [],
      opt1: false,
      opt2: false,
      selecionado: [],
      limpa: '',
      motoristas: [],
      orig: {
        latitude: null,
        longitude: null,
      },
      dest: {
        latitude: null,
        longitude: null,
      },
      motorista: {
        latitude: null,
        longitude: null,
      },
      motoristas2: [],
      loaded: false,
      forceRt: false,
      loadedbusca: true,
      buscaRt: null,
      error: null,
      msgResOpt: ' OK '
    }
    this.updateData = this.updateData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.salvaValor = this.salvaValor.bind(this);
    this.BuscarRT = this.BuscarRT.bind(this);
    this.handleBusca = this.handleBusca.bind(this);
    this.chanceRT = this.chanceRT.bind(this);
    this.forceAT = this.forceAT.bind(this);
    this.handleAtual = this.handleAtual.bind(this);
    this.isOnline = this.isOnline.bind(this);
    this.lastRun = this.lastRun.bind(this);
    this.renderMarkers = this.renderMarkers.bind(this);
    this.clearinput = this.clearinput.bind(this);
    this.msgResOpt = this.msgResOpt.bind(this);
    this.cancelTaxi = this.cancelTaxi.bind(this);
    this.handleShowCancel = this.handleShowCancel.bind(this)
    this.handleCloseCancel = this.handleCloseCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.mostraCentral = this.mostraCentral.bind(this);
    this.addMensagem = this.addMensagem.bind(this);
    this.returnShow = this.returnShow.bind(this);
    this.salvaValorText = this.salvaValorText.bind(this);
    this.todosMsg = this.todosMsg.bind(this);
    this.webservice = new WS();
  }
  componentWillMount() {
    this.updateData();

  }

  handleShowCancel() {
    this.setState({
      showCancel: true,
    })
  }
  returnShow() {
    if (this.props.returnShow) {
      this.props.returnShow();
    }
  }
  handleCloseCancel() {
    this.setState({
      showCancel: false,
    })
  }

  handleChange() {
    this.setState({ selection: this.inputEl.value });
  }



  async componentDidMount() {
    this.timerId = setInterval(this.updateData, 15000);
    console.log("FUI CHAMADO FIAO")
    this.updateData();
    this.props.getRef(this.refs);
    let res = await this.webservice.tipo_msg()
    let cancelamentos = await this.webservice.getCancel()

    this.setState({
      tipo_msg: res,
      tipo_cancel: cancelamentos

    })
  }
  msgResOpt(val) {
    //console.log('msgResOpt', val)
    this.setState({
      msgResOpt: val
    })
  }
  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  handleKeyDown = function (e) {
    if (e.key === 13) {
      e.preventDefault();
      this.handleSend();
    }
  };

  addMensagem(m){
    let AV = this.state.mensagensAtivas
    console.log("OLHA O AV", AV)
    console.log("olha o m", m)
    var index = AV.indexOf(m);
    if (index > -1) {
      AV.splice(index, 1);
      this.setState({
        mensagensAtivas: AV
      })
    }else{
      this.setState({
        mensagensAtivas: [...this.state.mensagensAtivas, m]
      })
    }
   
  }

  isOnline(motorista) {
    if (motorista.online) {
      return ('Online')
    } else {
      return ('Offline')
    }
  }
  async updateData() {
    try {
      //console.log("PROZAOLINDO", this.props)
      if (this.props.central.centralString) {
        //let result = await this.webservice.fetch2('mensagens?centrais=' + this.props.central.centralString.ids);
        //var msg = await result.json();
        if (this.state.showMsgDetails === true && this.state.mensagematual) {
          this.handleClick(this.state.mensagematual.cpf);
        }
        var msg = []
        //console.log(msg.result);
        //let self = this;
        msg.result.map((m, i) => {
     //     let obj = this.props.central.centrais.find((obj) => { return obj.id === Number(m.central); });
        // let nomecentral = obj.nomefantasia;
          /*Push.create("Nova Mensagem", {
            body: "AT: " + m.at + " - Central: " + nomecentral,
            icon: require('../images/motorista.png'),
            timeout: 4000,
            onClick: function () {
              window.focus();
              this.close();
              self.handleClick(m.cpf, m.central);
            }
          });*/
          return null;
        })
        console.log("Mensagens total", this.state.mensagensTotal)
        this.setState({
          mensagens: msg.result,
          mensagensTotal: [...new Set([...msg.result, ...this.state.mensagensAtivas])]
        });

      }
      //console.log("SOU O CPF", this.state.mensagens)
      this.setState({ error: null, loaded: true });

    } catch (err) {
      //console.log("ERROR", err)
      this.setState({ loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }

  async handleAtual(item, mycentral) {
    var msg = [];
    msg.cpf = item.cpf;
    msg.rt = item.rt;
    msg.at = item.at ? item.at : item.porta;
    msg.uuid = item.uuid333 || item.uuid;
    // console.log('item', item);
    if (!this.state.corrat) {
      // console.log("mim mensagem", item)
      let usarcentral = item.central ? item.central : item.central_id
      let result = await this.webservice.newmsg(usarcentral, 'motoristas/search?at=' + (item.at ? item.at : item.porta) + '&centrais=' + this.props.central.centralString.ids, { headers: { 'x-central-id': usarcentral } });
      var msgc = await result.json(result);
      // console.log('item-7', msgc)
      await this.setState({
        motoristas: msgc
      })
      //console.log("MINHA CENTRAL CARAI", mycentral)
      msgc.map(m => {
        //console.log("item-8", m);
        if (m.uuid333 === msg.uuid && (m.central ? m.central : m.central_id) === mycentral) {
          //console.log("ENTREI AQUII CARAIAO", m);
          this.setState({
            coratt: m.ultima_corrida,
            MtA: m,
            motorista: {
              latitude: m && m.latitude,
              longitude: m && m.longitude,
            },

          })
        }
        return null
      })

      if (this.state.coratt) {
        let mresult = await this.webservice.fetch2('corrida/' + this.state.coratt);
        let resultin = await mresult.json(mresult);
        //console.log("SOU O RESULTIN", resultin)
        this.setState({
          his: resultin[0],
          orig: {
            latitude: resultin[0].latitude,
            longitude: resultin[0].longitude,
          },
          dest: {
            latitude: resultin[0].latitudedestino,
            longitude: resultin[0].longitudedestino,
          },

        })
      }
    }
    this.setState({ mensagematual: msg });
    this.lastRun(item.at ? item.at : item.porta);
    this.handleClick(item.cpf);
  }

  async handleClick(central, cpf, nome) {
    this.setState({
      showMsgDetailsMotorista: true,
      msg_central: central,
      msg_cpf: cpf,
      msg_nome: nome,
    })
    // try {
    //     this.webservice.forcesacpiloto(cpf,central);
    //     this.returnShow();
    // } catch (err) {
    //   this.setState({ error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    // }
  }
  todosMsg() {
    this.webservice.postSacPiloto(this.state.msg_cpf, this.state.textomsg, this.state.msg_central);
    this.setState({
      showMsgDetailsMotorista: false,
      msg_central: null,
      msg_cpf: null,
      msg_nome: null,
    })
    this.returnShow();
  }
  async handleBusca() {
   
    if (!this.state.buscaRt && !this.state.buscaAT) {
      return;
    }
    try {
      var busca = "";
      busca = "?busca=" + this.state.buscaRt;
      let result = await this.webservice.fetch2('motoristas/search' + busca + '&centrais=' + this.props.central.centralString.ids);
      var msgc = await result.json(result);
      this.setState({
        motoristas: msgc
      })


      this.setState({
        error: null,
        loadedbusca: true,
      });
    } catch (err) {
      this.setState({ loadedbusca: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }
  handleClose() {
    this.setState({
      showMsgDetails: false,
      showMsgDetailsMotorista: false,
      motoristas: [],
      his: [],
      coratt: '',
    })

    this.props.changeState(false);
  }
  chanceRT(e) {
    var value = e.target.value;
    this.setState({
      buscaRt: value,
      limpa: value
    })
  }
  forceAT(e) {
    var value = e.target.checked;
    this.setState({
      forceRt: value,
    })
  }
  BuscarRT(e) {
    //var value = e.target.value;
    this.handleBusca();

    return true
  }
  salvaValor(e) {
    var value = e.target.value;
    this.setState({
      enviando: value
    })
  }

  salvaValorText(e) {
    var value = e.target.value;
    this.setState({
      textomsg: value
    })
  }
  async handleSend() {
    try {
      //mexer aqui
      let altf = '';
      //console.log("STATE ", this.state.MtA)
      this.state.respostas.map((resp, i) => {
        if (i === 0) {
          altf += resp
        } else {
          altf += "," + resp
        }
        return null;
      })
      let re = /([1-9]+:)([1-9]+)/
      let ncpf = this.state.mensagematual.cpf
      let ref = ncpf.replace(re, '$2')

      // console.log("teste1", altf)
      let tev = /<>/
      let nmsg = null
      console.log("SOU O TIPO", this.state.tipoenvio)
      if (this.state.tipoenvio === 3) {
        nmsg = this.state.enviando2.replace(tev, this.state.his.endereco)
      }
      if (this.state.tipoenvio === 2) {
        nmsg = this.state.enviando2.replace(tev, this.state.enviando)
      }
      if (this.state.tipoenvio === 1) {
        nmsg = this.state.enviando2
      }
      //console.log("MENSAGEM POHA", nmsg)
      //console.log("TESTIN MENSAGEM", this.state.mensagematual)
      await this.webservice.newmsg(this.state.MtA.central_id, 'mensagens/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cpf: ref,
          uuid: this.state.MtA.uuid333,
          at: this.state.mensagematual.at,
          rt: this.state.mensagematual.rt,
          textolivre: nmsg ? nmsg + "" : this.state.enviando,
          remetente: "monitor",
          alternativas: ((altf !== '') ? altf : " OK "),
        })
      })
      //console.log(this.state.mensagematual);
      this.setState({
        enviando: '',
        enviando2: null,
        tipoenvio: 5,
      });
      //this.handleClick2(this.state.mensagematual.cpf);
    } catch (err) {
      this.setState({ error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }
  async renderMarkers(map, maps) {
    let result = await this.webservice.fetch2('motoristas/search?cpf=' + this.state.historico[0].t12_cpf_sec);
    result = await result.json();
    this.setState({
      motoristas2: result,
    })
    if (!this.state.historico[0].t12_dthr_embarque) {
      new maps.Marker({
        position: { lat: this.state.historico[0].latitude, lng: this.state.historico[0].longitude },
        map: map,
        icon: require('../images/cliente.png'),
        title: 'Cliente!'
      });

    } else {
      new maps.Marker({
        position: { lat: this.state.historico[0].latitudedestino, lng: this.state.historico[0].longitudedestino },
        map: map,
        icon: require('../images/cliente.png'),
        title: 'Cliente!'
      });
    }
    if (this.state.historico[0].t12_dthr_dembarque) {
      new maps.Marker({
        position: { lat: this.state.historico[0].latitude, lng: this.state.historico[0].longitude },
        map: map,
        icon: require('../images/cliente.png'),
        title: 'Inicio!'
      });
      new maps.Marker({
        position: { lat: this.state.historico[0].latitudedestino, lng: this.state.historico[0].longitudedestino },
        map: map,
        icon: require('../images/cliente.png'),
        title: 'Fim!'
      });
    }
    if (!this.state.historico[0].t12_dthr_dembarque) {
      this.state.motoristas2.map(moto => {
        if (moto.uuid === this.state.historico[0].uuid333) {
          new maps.Marker({
            position: { lat: moto.latitude, lng: moto.longitude },
            map: map,
            icon: require('../images/motorista.png'),
            title: 'Motorista!'
          });
          if (!this.state.historico[0].t12_dthr_embarque) {
            this.getCenter(moto.latitude, moto.longitude, this.state.historico[0].latitude, this.state.historico[0].longitude)
          } else {
            this.getCenter(moto.latitude, moto.longitude, this.state.historico[0].latitudedestino, this.state.historico[0].longitudedestino)
          }
        }
        return null
      })
    }
  }

  getCenter(latA, lngA, latB, lngB) {
    const bounds = {
      ne: {
        lat: latA,
        lng: lngA,
      },
      sw: {
        lat: latB,
        lng: lngB,
      }
    }

    if (latA > bounds.ne.lat) {
      bounds.ne.lat = latA;
    }
    if (lngA > bounds.ne.lng) {
      bounds.ne.lng = lngA;
    }
    if (latA < bounds.sw.lat) {
      bounds.sw.lat = latA;
    }
    if (lngA < bounds.sw.lng) {
      bounds.sw.lng = lngA;
    }

    if (latB > bounds.ne.lat) {
      bounds.ne.lat = latB;
    }
    if (lngB > bounds.ne.lng) {
      bounds.ne.lng = lngB;
    }
    if (latB < bounds.sw.lat) {
      bounds.sw.lat = latB;
    }
    if (lngB < bounds.sw.lng) {
      bounds.sw.lng = lngB;
    }

    const size = {
      width: 550, // Map width in pixels
      height: 253, // Map height in pixels
    };

    const { center, zoom } = fitBounds(bounds, size)
    this.setState({
      center: center,
      zoom: zoom,
    })

    //Set the distance from local position to destination
    this.webservice.directions(bounds.ne.lat, bounds.ne.lng, bounds.sw.lat, bounds.sw.lng).then(result => {
      this.setState({ distance: result.text })
      this.setState({ distanceValue: result.value })
    })
  }

  latcor() {
    if (!this.state.historico[0].t12_dthr_embarque) {

      return { lat: this.state.historico[0].latitude, lng: this.state.historico[0].longitude }

    } else {

      return { lat: this.state.historico[0].latitudedestino, lng: this.state.historico[0].longitudedestino }

    }

  }
  async cancelTaxi() {
    var r = window.confirm("Você tem certeza?");
    if (r === true) {
      //console.log("CONFIMADO CARAI", this.state.MtA)
      //console.log("props", this.props.central.nome)
      try {
        await this.webservice.cancelTaxi(Number(this.state.MtA.ultima_corrida), this.props.central.nome, Number(this.state.MtA.central_id));
        this.setState({
          showRtDetails: false,
          showCancel: false,
        })
      } catch (err) {
        console.log("erroo", err)
      }
      alert("Corrida Cancelada");
    }
  }

  lastRun(porta) {
    this.webservice.runSearch(moment().subtract(1, 'days').utc().format("YYYY-MM-DD HH:mm"), moment().utc().format("YYYY-MM-DD HH:mm"), 'at', porta).then(resultado2 => { this.setState({ historico: resultado2 }) })
    //var bmh = await result2.json();


    //return bmh;
  }
  clearinput() {
    this.setState({
      limpa: ''
    })
  }

  mostraCentral(motorista) {
    let motcentral = motorista.central ? motorista.central : motorista.central_id;
    let obj = this.props.central.centrais.find((obj) => { return obj.id === Number(motcentral); });
    let nomecentral = obj.nomefantasia;
    // let nomecentral = this.props.central.centrais.map(c=>{
    //   if(Number(motcentral) === c.id){
    //     return c.nomefantasia
    //   }
    // })
    //console.log("nome da central", nomecentral);
    return nomecentral;
  }

  msgaberto(result){
    console.log("result boys1", result)

    this.props.msgaberto(result)
  }
  render() {
    return (
      <div >
        <Form horizontal >
          <FormGroup controlId="formHorizontalEmail">
            <Col componentClass={ControlLabel} sm={2}>
              <div> Motorista:</div>
            </Col>
            <Col sm={9}>
              <InputGroup>
                <FormControl autoFocus onKeyDown={e => e.keyCode === 13 && this.BuscarRT(e) && e.preventDefault()} ref='focar'
                  type="text" value={this.state.limpa} onFocus={this.clearinput} style={{ height: 40, zIndex: 0}} placeholder="Motorista" onChange={(v) => this.chanceRT(v)} />

                <InputGroup.Button>
                  <Button onClick={this.BuscarRT} style={{ height: 40, zIndex: 0}}>Buscar</Button>
                </InputGroup.Button>
              </InputGroup>
            </Col>
          </FormGroup>
        </Form>
        <Loader loaded={this.state.loadedbusca}>
          <Router>
            <Table striped bordered condensed hover>
              <CSSTransitionGroup
                transitionName="motorista"
                component="tbody"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
                {

                  this.state.motoristas.map(m => {
                    return (
                      <tr onClick={() => this.handleClick(m.central ? m.central : m.central_id, m.cpf, m.nome)}>
                        <td>{m.nome} - {m.placa} - {this.isOnline(m)} - {this.mostraCentral(m)} </td>
                      </tr>
                    )
                  })

                }

              </CSSTransitionGroup>
            </Table>
          </Router>
        </Loader>
        {
          this.state.error && (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          )
        }
        {/*}
        <Loader loaded={this.state.loaded}>
          <Router>
            <Table>
              <CSSTransitionGroup
                transitionName="example"
                component="tbody"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
                {
                  this.state.mensagensTotal.map(m => {
                    console.log("NOVA MESSAGI", m)
                    return (
                      //<tr key={m.cpf} onClick={() => this.handleAtual(m, m.central ? m.central : m.central_id)}>
                        //<td>Nova Mensagem - {m.at} - {m.rt} - {this.mostraCentral(m)}</td>
                        <div style={{ display: 'inline-block', marginLeft: 65, position: 'fixed', bottom: '50px',width:'60x' }} >
                          <NewMsg sendmsg={this.addMensagem} msgaberto={this.msgaberto.bind(this)} eme={m} at={Number(m.at)} rt={Number(m.rt)} uuid={m.uuid} central={Number(m.central ? m.central : m.central_id)} cpf={Number(m.cpf)}  />
                        </div>
                      //</tr>
                    )
                  })
                }
              </CSSTransitionGroup>
            </Table>
          </Router>
        </Loader>
              */}

        <Modal bsSize="large" show={this.state.showMsgDetails} onHide={this.handleClose} dialogClassName="dados-corrida">
          <Modal.Header closeButton>
            {/* {console.log("SOU A MENSAGEM ATUAL", this.state.mensagematual)} */}
            <Modal.Title>Mensagens AT {this.state.mensagematual.at} /  RT {this.state.mensagematual.rt}</Modal.Title>
          </Modal.Header>
          <Modal.Body >

            <Row>
              <Col xs={6} md={4}>

                {this.state.his && this.state.coratt &&
                  <div>
                    {this.state.MtA.online &&
                      <p>
                        MOTORISTA:
                      <b style={{ color: 'green' }}>ONLINE</b> /
                      {this.state.MtA.livre && <b style={{ color: 'green' }}>LIVRE</b>}
                        {!this.state.MtA.livre && <b style={{ color: 'red' }}> OCUPADO </b>}
                      </p>
                    }
                    {!this.state.MtA.online &&
                      <p>MOTORISTA: <b style={{ color: 'red' }}>OFFLINE</b></p>
                    }
                    <p><b>Ultimas informações</b></p>
                    {!this.state.his.t12_dthr_dembarque &&
                      <p><b>Corrida em andamento</b></p>}
                    <p>Cliente: {this.state.his.cliente}</p>
                    <p>Data hora da chamada: {moment(this.state.his.datahora).format("DD/MM/YYYY HH:mm")}</p>
                    <p>Data hora recebido a corrida: {moment(this.state.his.t12_dthr_corrida_che_cel).format("DD/MM/YYYY HH:mm")}</p>
                    {this.state.his.t12_dthr_embarque &&
                      <p>Data hora Qrl: {moment(this.state.his.t12_dthr_embarque).format("DD/MM/YYYY HH:mm")}</p>}
                    {this.state.his.t12_dthr_dembarque &&
                      <p>Data hora Qrv {moment(this.state.his.t12_dthr_dembarque).format("DD/MM/YYYY HH:mm")}</p>}
                    <p>Endereço cliente: {this.state.his.endereco},{this.state.his.numero}</p>
                    <p>Endereço destino: {this.state.his.enderecodestino}</p>
                    <p>ID da corrida: {this.state.his.id}</p>
                    <p>Telefone cliente: {this.state.his.telefone && (this.state.his.telefone.toString().length === 10 ? this.state.his.telefone.toString().replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") : this.state.his.telefone.toString().replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"))} </p>
                    {(!this.state.his.t12_dthr_dembarque && !this.state.his.atendente_cancelamento) && <button className="btn btn-warning btn-sm espacamento" id="btn-cancel" onClick={() => this.handleShowCancel()}>Cancelar Corrida</button>}


                  </div>
                }
                {!this.state.coratt &&
                  <div>
                    <h1>Motorista offline</h1>
                  </div>

                }
              </Col>
              <Col xs={6} md={4}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel">
                      <div className="panel-body" >
                        <ul className="chat">
                          {

                            this.state.mensagemcarregada.map(m => {
                              let classe = "right clearfix";
                              let classe2 = "pull-right text-muted";
                              let remetente = "Monitor";
                              let interna = true;
                              let url = "http://placehold.it/50/FA6F57/fff&text=OP";
                              if (m.remetente === 'Motorista') {
                                interna = false;
                                url = "http://placehold.it/50/55C1E7/fff&text=" + m.at;
                                classe = "left clearfix";
                                classe2 = "pull-left text-muted";
                                remetente = "Motorista AT " + m.at + " / RT " + m.rt;
                              }
                              return (
                                <li key={m.datahora} className={classe}>
                                  {interna &&
                                    <span className="chat-img pull-right">
                                      <img src={url} alt="User Avatar" className="img-circle" />
                                    </span>}
                                  {!interna &&
                                    <span className="chat-img pull-left">
                                      <img src={url} alt="User Avatar" className="img-circle" />
                                    </span>}

                                  <div className="chat-body clearfix">
                                    {interna &&
                                      <div className="header clearfix">
                                        <small className={classe2}>{moment(m.datahora).format("DD/MM/YY HH:mm")}</small>
                                        <strong className="pull-left primary-font">{remetente}</strong>
                                      </div>
                                    }

                                    {!interna &&

                                      <div className="header clearfix">
                                        <strong className="pull-right primary-font">{remetente}</strong>
                                        <small className={classe2}>{moment(m.datahora).format("DD/MM/YY HH:mm")}</small>
                                      </div>
                                    }

                                    <div className={classe}><p>{m.textolivre}</p></div>

                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      <div className="panel-footer">
                        {this.state.enviando2 && <div>Mensagem selecionada {this.state.categoria}</div>}
                        <div className="input-group">

                          <input id="btn-input" style={{ height: 30 }} type="text" className="form-control input-sm" placeholder="Escreva sua mensagem..."
                            value={this.state.enviando} disabled={this.state.tipoenvio === 1 || this.state.tipoenvio === 3 ? true : false}
                            onChange={(v) => this.salvaValor(v)} onKeyDown={e => e.keyCode === 13 && this.state.enviando !== '' && this.handleSend(e) && e.preventDefault()} autoFocus
                          />
                          <span className="input-group-btn">
                            <button style={{ height: 30 }} disabled={this.state.enviando === '' ? true : false} className="btn btn-warning btn-sm" id="btn-chat" onClick={this.handleSend} >Enviar</button>
                          </span>

                        </div>
                        <br />

                        <p>Opções de resposta</p>
                        <DropdownButton id="msgs_prontas" title="mensagens prontas">
                          {/* {console.log("MENSAGEEEEM", this.state.tipo_msg)} */}
                          {this.state.tipo_msg.map((tipo, i) => {
                            return (<MenuItem key={i}
                              onClick={() => this.setState({
                                enviando: ' ',
                                enviando2: tipo.m04_msg,
                                respostas: tipo.m04_resposta,
                                tipoenvio: tipo.m04_cat_order,
                                categoria: tipo.m04_categoria
                              })
                              }>{tipo.m04_categoria}</MenuItem>)
                          })}
                        </DropdownButton>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              {/*
            <Tab eventKey={2} title = "Corrida atual">
              <div>

              </div>
            </Tab>*/
              }
              <Col xs={6} md={4} >

              </Col>
            </Row>

            <Modal
              show={this.state.showCancel}
              onHide={this.handleCloseCancel}
              aria-labelledby="contained-modal-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                  Cancelar corrida
            </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <FormGroup controlId="formControlsSelectMultiple">
                    <ControlLabel>Motivo cancelamento</ControlLabel>
                    <FormControl componentClass="select" onSelect={this.handleChange} onChange={this.handleChange} inputRef={el => this.inputEl = el} style={{ height: 40 }} >
                      <option value=""></option>
                      {this.state.tipo_cancel && this.state.tipo_cancel.map(c => {
                        return (
                          <option ref={"selecionado"} value={c.id}>{c.descricao}</option>
                        )
                      })}

                    </FormControl>
                  </FormGroup>
                  <FormGroup
                    controlId="formBasicText"
                  >
                    <ControlLabel>Observações: </ControlLabel>
                    <FormControl
                      type="text"
                      componentClass="textarea"
                      value={this.state.value2}
                      placeholder="Escreva o texto"
                      onChange={e => this.setState({ value2: e.target.value })}
                    />

                  </FormGroup>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => this.cancelTaxi()}>Sim </Button>
                <Button onClick={this.handleCloseCancel}>cancelar</Button>
              </Modal.Footer>
            </Modal>
          </Modal.Body>
        </Modal>

<Modal show={this.state.showMsgDetailsMotorista} onHide={this.handleClose}>
  <Modal.Header closeButton>
    <Modal.Title> Enviando mensagem para {this.state.msg_nome}</Modal.Title>
  </Modal.Header>
  <Modal.Body>

    <div className="row">
      <div className="col-md-12">
          <div className="panel">
          <div className="panel-footer">
              <div className="input-group">
                  <input id="btn-input"  style={{height: 30}} type="text" className="form-control input-sm" placeholder="Escreva sua mensagem..."
                    value={this.state.textomsg}
                    onChange={(v) => this.salvaValorText(v)} onKeyDown={e => {if(e.key === 'Enter'){this.todosMsg()}}} autoFocus
                    />
                  <span className="input-group-btn">
                      <button  style={{height: 30}} disabled={this.state.enviando === '' ? true : false} className="btn btn-warning btn-sm"  id="btn-chat" onClick={this.todosMsg} >Enviar</button>
                  </span>
              </div>
          </div>
          </div>
      </div>
    </div>


  </Modal.Body>
</Modal>
      </div>
    );
  }
}
export default Mensagens2;
