import WS from '../services/webservice';
import React, { Component } from 'react';
import ReactDom from 'react-dom'
import { Modal, Row, Col } from 'react-bootstrap'

class goDetalheValorPopup extends Component {

  constructor(props) {
    super(props);

    this.webservice = new WS();
  }


  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    let d = this.props.dados;
    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={this.props.modalHide}
        container={this}
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header closeButton>
        <Modal.Title>Detalhe do Valor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.dados && <div>
          <p>Nome da table: {d.nome} - {d.sigla}</p>
          <p>Origem dos Valores:{d.origemdatabela}</p>
          <p>Valor Calculado:{d.valor_formatado}</p>

          {d.tabelaprices &&
          <div>
            <p>Bandeirada:{d.tabelaprices.initialPrice}</p>
            <p>KM:{d.tabelaprices.pricePerKm}</p>
            <p>Acrescimo por Trecho:{d.tabelaprices.valor_acrescimo_trecho}</p>

            <p>Valor Minimo da Corrida:{d.tabelaprices.valorminimo}</p>
            <p>Valor Minimo do Trecho:{d.tabelaprices.valor_minimo_trecho}</p>
            <p>Taxa por parada (entrega):{d.tabelaprices.hora_parada_fixo}</p>
            
            <p>Retorno Metropolitano (Fixo):{d.tabelaprices.retorno_metropolitano_fixo}</p>
            <p>Retorno Metropolitano (Percentual):{d.tabelaprices.retorno_metropolitano_percent}</p>
            <p>Retorno Municipal (Fixo):{d.tabelaprices.retorno_municipal_fixo}</p>
            <p>Retorno Municipal (Percentual):{d.tabelaprices.retorno_municipal_percent}</p>            
            

            <p>Desconto (Percentual):{d.tabelaprices.discount}</p>
            <p>Custo em Credidos da Corrida (Percentual):{d.tabelaprices.creditopercentual}</p>
            <p>Limite de valor para o Custo Percentual:{d.tabelaprices.creditopercentuallimite}</p>
            <p>Custo em Creditos da Corrida (Fixo):{d.tabelaprices.creditosporcorrida}</p>
          </div>
          }
          {d.calculo &&
          <div>
            {
              d.calculo.map((c, i) => {
                return (
                  <div key={i}>
                    <p>Trecho {i+1}</p>
                    <p>Distancia:{c.distance} metros</p>
                    <p>Tempo:{c.duration / 60} minutos</p>
                    {c.tabela.length > 0 &&
                      <p>Nome da Tabela do Valor: {c.tabela[0].nome}</p>
                    }
                  </div>
                );
              })
            }
          </div>}
          </div>}
        </Modal.Body>
      </Modal>
    );
  }
}
export default goDetalheValorPopup;