import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';
export default class IuguWeb extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: []
    }
    this.ws = new WS()
  }

  mirrorState(key) {
    console.log('mirrorState', key)
    this.setState(key);
  }
  async loadList(p, after) {
    if (window.confirm('Are you sure you want to save this thing into the database?')) {
      let retorno = await this.ws.reloadFromIugu()
      if (retorno) {
        alert("Finalizado")
      } else {
        alert("ERRO")
      }
    } else {
      alert("OK")
    }
  }
  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "t08_cpf_sec",
          tipo_cadastro: "Saldo / Faturamento Motorista",
          tipo_plural: "Saldo / Faturamento Motorista",
          href: "iugu_loadmarketplace",
          titulo: "Saldo / Faturamento Motorista",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          extrabutton: (() => {this.loadList()}).bind(this),
          extrabuttontexto: 'Load From Iugu',
          requests: {
            type: 'iugu_loadmarketplace',
            list: true,
            save: false,
            edit: false,
            semdetalhes:true,
            delete: false,
            listSub: true,
            defaultorder: [{ id: "id" }],
          },
          campos: [
            {
              ref: "id",
              label: "ID",
              type: "text",
            },
            {
              ref: "name",
              label: "Nome",
              type: "text",
            },
            {
              ref: "verified",
              label: "Verificado",
              type: "checkbox",
            },
          ],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "ID",
                accessor: "id",
              },
              {
                Header: "Nome",
                accessor: "name",
              },
              {
                Header: "Verificado",
                accessor: "verified",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.verified ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
              },
            ],
          }
        }}
      />
    );
  }
}
