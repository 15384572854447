import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class Centralafaturar extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.ws = new WS();
    this.postFatura = this.postFatura.bind(this);
  }

  mirrorState(key) {
    this.setState(key);
  }

  async postFatura(parametros, after) {
    alert("Aguarde Processamento!")
    await this.ws.postfaturacentral(parametros)
    alert("Processamento concluido!")
    if (after) {
      after();
    }
  }

  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Corridas a faturar",
          tipo_plural: "Corridas a faturar",
          href: "afaturar_central",
          titulo: "Voucher a faturar",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'afaturar_central',
            list: true,
            save: false,
            edit: true,
            delete: false,
            listSub: false,
            outros: [
              {icone: 'FaUsers', tamanho: 30, cor: 'blue', texto: 'Gerar Fatura', funcao:this.postFatura.bind(this)}],
          },
          campos: [
            {
              ref: "central_nomefantasia",
              label: "Central",
              type: "justlabel",
              filter: 'central.nomefantasia',
            },
            {
              ref: "datacorrida",
              label: "Período",
              type: "datedisabled",
            },
            {
              ref: "valor",
              label: "Valor das Corridas",
              type: "currencydisabled",
            },
            { ref: "contagem", label: "Número de corridas", type: "justlabel", },
            { ref: "valor_franquia", label: "Valor da franquia", type: "currencydisabled", },
            { ref: "corridas_franquia", label: "corridas_franquia", type: "justlabel", },

            { ref: "extra_franquia", label: "Valor por corrida", type: "currencydisabled", },
            { ref: "percent_franquia", label: "% por corida", type: "justlabel", },
            { ref: "franquia", label: "Valor das corridas dentro da franquia", type: "currencydisabled", },
            { ref: "dif", label: "Diferença", type: "currencydisabled", },
            { ref: "valor_fatura", label: "Valor da Fatura", type: "currencydisabled", },
        ],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Central",
                accessor: "central_nomefantasia",
              },
              {
                Header: "Mês de Referência",
                accessor: "datacorrida",
              },
              {
                Header: "Valor",
                id: "valor_fatura",
                accessor: d => <NumberFormat value={Number(d.valor_fatura)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />,
              },
              {
                Header: "Número de corridas",
                accessor: "contagem",
              },
            ],
          }
        }}
      />
    );
  }
}
export default Centralafaturar;