import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Dialog from 'react-bootstrap-dialog-v2'
import { Alert } from 'reactstrap';

class SaldoMotorista2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: []
    }
    this.webservice = new WS();
  }

  mirrorState(key) {
    console.log('mirrorState', key)
    this.setState(key);
  }
  registrapagamento(p, after) {
    console.log(p);
    let dados = {
      central: p.central,
      cpf: p.t08_cpf_sec,
      valor: p.saldo,
    }
    console.log(dados)
    Dialog.setOptions({
      defaultOkLabel: 'Sim',
      defaultCancelLabel: 'Não',
    })
    this.dialog.show({
      body: 'Você deseja registrar o pagamento de R$ ' + Number(p.saldo).toFixed(2).toString().replace('.',',') + ' para ' + p.t08_nomemotorista + '?',
      actions: [
        Dialog.CancelAction(() => { 

        }),
        Dialog.OKAction(async () => {
          clearTimeout(this.salvar);
          await this.webservice.PagaMotoristas(p.central, dados);
          alert('Pagamento Realizado');
          if (after) {
            after();
          }
        }
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }
  render() {
    return (<>
    <Dialog ref={(el) => { this.dialog = el }} />
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "t08_cpf_sec",
          tipo_cadastro: "Saldo / Faturamento Motorista",
          tipo_plural: "Saldo / Faturamento Motorista",
          href: "motfinanceiro2",
          titulo: "Saldo / Faturamento Motorista",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'motfinanceiro2',
            list: true,
            save: false,
            edit: true,
            semdetalhes:false,
            delete: false,
            listSub: true,
            defaultorder: [{ id: "t08_cpf_sec" }],
            outros: [
              {icone: 'FaReceipt', tamanho: 22, cor: 'blue', texto:'Realizar Pagamento', funcao:this.registrapagamento.bind(this)}],
          },
          subtipos: [
            {tipo: 'central', id: 'id', txt: 'nomefantasia', p:'get'},
          ],
          campos: [
            {
              ref: "t08_cpf_sec",
              label: "Cpf",
              type: "justlabel",
            },
            {
              ref: "t08_nomemotorista",
              label: "Nome",
              type: "justlabel",
            },
            {
              ref: "central",
              label: "Central",
              type: "select",
              disabled: true,
              listtype:'central',
            },
            {
              ref: "saldo",
              label: "Saldo",
              type:'currencydisabled'
            },
          ],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "CPF",
                accessor: "t08_cpf_sec",
              },
              {
                Header: "Motorista",
                accessor: "t08_nomemotorista",
              },
              {
                Header: "Central",
                accessor: "central",
                filterable:false,
                Cell: row => {
                  return (
                    <div>
                      {this.state.sublistas && this.state.sublistas.central &&this.state.sublistas.central.find(element => element.id === row.original.central ) &&
                      this.state.sublistas.central.find(element => element.id === row.original.central ).text
                      }
                    </div>
                  )
                }
              },
              {
                Header: "Saldo",
                accessor: "saldo",
                filterable:false,
                Cell: row => {
                  return (
                    <div>
                      R$ {Number(row.original.saldo).toFixed(2).toString().replace('.',',')}
                    </div>
                  )
                }
              },
            ],
          }
        }}
      /></>
    );
  }
}
export default SaldoMotorista2;
