import React, { Component } from 'react'
//import { render } from 'react-dom'
import { Launcher } from './CompMsg/index'
import moment from 'moment';
import {
  DropdownButton, MenuItem, Button
} from 'react-bootstrap'
import WS from '../services/webservice';
import './Bubbles.css'

var messageList = {};

class NewSacPiloto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_msg: [],
      aberto: false,
      enviando2: null,
      respostas: [],
      tipoenvio: 5,
      categoria: '',
      aberto2: true,
    };

    this.loadMsg = this.loadMsg.bind(this)
    this.envio = this.envio.bind(this)
    this.msgpai = this.msgpai.bind(this)
    this.msgpai2 = this.msgpai2.bind(this)
    this.tipos = this.tipos.bind(this)
    this.finalizaChamado = this.finalizaChamado.bind(this);
    this.mostraCentral = this.mostraCentral.bind(this)

    this.webservice = new WS();
  }

  

  async loadMsg() {
    let msgl = []
    //console.log('loadMsg props', this.props.aberto)
    if (!this.props.aberto) {
      messageList[this.props.uuid + ':' + this.props.central]  = [];
    }
      let result = await this.webservice.GetSacPiloto(this.props.uuid,this.props.central)
      console.log('loadMsg piloto ' + this.props.uuid + ' ' + this.props.central, result)
      for (let index = 0; index < result.length; index++) {
        const e = result[index];
        let valores = new Date(e.datahora).toLocaleTimeString().split(' ')
        console.log('datahora',[valores, e.datahora])
        msgl.push(
          { author: e.remetente === 1 ? 'Cliente' : e.remetente === 2 ? 'Motorista' :  e.remetente === 5 ? 'system' : 'me', 
          id: e.id,
          key: e.key,
          type:  e.remetente === 5 ? 'system' : 'txt', 
          data: { text: e.msg, dthr: valores[0] } }
        )
      }
      //console.log('loadMsg props', msgl)
      messageList[this.props.uuid + ':' + this.props.central]  = msgl.reverse();  
      this.timeoutid = setTimeout(this.loadMsg,6500);
  }

  async tipos() {
    
  }
  componentDidMount() {
    messageList[this.props.uuid + ':' + this.props.central]  = [];
    this.tipos()
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  mostraCentral(motorista) {
    let motcentral = motorista;
    let obj = this.props.centrais.centrais.find((obj) => { return obj.id === Number(motcentral); });
    let nomecentral = obj.nomefantasia;
    return nomecentral;
  }

  _onMessageWasSent(message) {
    console.log("MIM msg", message)
    this.envio(message.data.text)
  }

  async envio(msg) {
    console.log('postSacPiloto envio sac/piloto', [this.props.uuid, msg,this.props.central])
    //console.log('envio', this.props)
    let post = await this.webservice.postSacPiloto(this.props.uuid, msg,this.props.central);
    console.log('postSacPiloto',post)
    this.loadMsg()
  }

  _sendMessage(text) {
    this.envio(text)
  }
  async finalizaChamado() {
    await this.webservice.FinalizaSacPiloto(this.props.uuid,this.props.central);
    this.props.modalHide();
    this.setState({ aberto: !this.state.aberto });
  }
  msgpai() {
    if (this.props.sendmsg) {
      console.log("OLHA O M AQUI CARAio", this.props.eme)
      this.props.sendmsg(this.props.eme);
    }
  }

  msgpai2() {
    console.log("result boys2", this.state.aberto)
    if (this.props.msgaberto) {
      this.props.msgaberto(!this.state.aberto)
    }
  }

  render() {
    return (<div style={{ marginLeft: 5,width: '60px', }}>
      <div style={{ position: 'fixed', bottom: '90px', width: '60px', backgroundColor: 'white' }}>
        <center>{this.props.nome} <br /> {this.props.placa} <br /> {this.mostraCentral(this.props.central)} </center>
      </div>
      {(this.props.aberto ? this.props.aberto : this.state.aberto) && <div style={{ position: 'fixed', zIndex: 1000, bottom: '155px', height: '40px', width: '358px'}}>
        <center>
        <Button onClick={this.finalizaChamado}>Finalizar Sac</Button>
        </center>
      </div>}
      <div className="chat-wrapper" onClick={(e) => { this.loadMsg(); console.log("loadMsg enter");  }}>
        <Launcher
          agentProfile={{
            teamName: 'Cliente ' + this.props.nome,
            imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
          }}
          mute={true}
          scheader={'sc-header'}
          onMessageWasSent={this._onMessageWasSent.bind(this)}
          newMessagesCount={1}
          newClass={'sc-launcher-blue'}
          isOpen={this.props.aberto ? this.props.aberto : this.state.aberto}
          handleClick={() => {
            if (this.props.aberto) {
              this.timeoutid = null;
              this.props.modalHide();
              this.msgpai();
            } else {
              this.setState({ aberto: !this.state.aberto });
              console.log("CLICADO NA PARADA", this.state.aberto);
              this.msgpai();
              this.msgpai2();
            }
          }}
          showEmoji={false}
          messageList={messageList[this.props.uuid + ':' + this.props.central] }
        />
      </div>

    </div>)
  }
}

export default NewSacPiloto;