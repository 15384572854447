import React, { Component } from "react";
import WS from '../services/webservice';
import { Button, FormGroup, FormControl, ControlLabel, Modal } from "react-bootstrap";
import Fontes from '../fonts/Fontes.js';


const { versao, url } = require('../go-versao.js')


//import {  CognitoUserPool,  AuthenticationDetails,  CognitoUser} from "amazon-cognito-identity-js";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      showNewPassword: false,
      showResetPassword: false,
      name: "",
      nickname: "",
      novoPass: "",
      error: null
    };

    this.ws = new WS();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmitReset = this.handleSubmitReset.bind(this);
    this.showResetPass = this.showResetPass.bind(this);
    this.saveEmail = this.saveEmail.bind(this)

  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }
  handleClose() {
    this.setState({
      showResetPassword: false
    });
  }

  saveEmail(event) {
    this.setState({
      email: event.target.value
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  componentDidMount() {
    this.timerId = setInterval(this.checkLoginCookie, 1000);
    console.log(this.props);
    let token = this.ws.getCookie('token')
    if (token) {
      this.props.loginUser(token);
    }
    console.log('fontes',Fontes.ListIconFont())

  }
  async handleSubmit(event) {
    event.preventDefault();

    let result = await this.ws.login(this.state.email, this.state.password);
    if (result.ok) {
      result = await result.json();
      console.log(result)
      this.props.loginUser(result);
      this.props.history.push("/Dashboard2");
    } else {
      this.setState({
        error: 'Usuário ou senha inválido!'
      });
    }

    
  }

  validateFormReset() {
    return this.state.email.length > 0;
  }

  async showResetPass() {
    this.setState({
      showResetPassword: true
    });
  }

  async handleSubmitReset(event) {
    event.preventDefault();

      window.open( url + "/go-secure/callcenter/request_password_reset/" + this.state.email, "_blank", "noopener noreferrer");
      this.setState({
        showResetPassword: false
      });
    
  }

  render() {
    return (
      <div className="Login">
        {
          this.state.error && (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          )
        }
        
        <div  style={{padding: 20}}>
        <form onSubmit={this.handleSubmit} className="loginForm">
          <h1>Sistema Administrativo</h1>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              //autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <Button
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
          >
            Login
          </Button>
          <Button
            block
            onClick={this.showResetPass}
          >
            Esqueci Minha Senha
          </Button>
        </form>
        </div>
        <Modal 
        show={this.state.showResetPassword} 
        onHide={this.handleClose}>
          <div  style={{padding: 20}}>
          <h1>Esqueci minha senha</h1>
          <form onSubmit={this.handleSubmitReset}>
            <FormGroup controlId="codigoconfirmacao" bsSize="large">
              <ControlLabel>Email</ControlLabel>
              <FormControl
                value={this.state.email}
                onChange={this.saveEmail}
                type="email"
              />
            </FormGroup>
            <Button
              block
              bsSize="large"
              disabled={!this.validateFormReset()}
              type="submit"
            >
              Solicitar Nova Senha
            </Button>
          </form>
          </div>
        </Modal>
      </div>
    );
  }
}
