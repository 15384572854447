import React, { Component } from 'react';
import './Bubbles.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fitBounds } from 'google-map-react/utils';
import moment from 'moment';
import { Row, Modal, Col, Form, FormGroup, ControlLabel, Button } from 'react-bootstrap';
import { GoogleMap, withGoogleMap, withScriptjs, Polyline } from 'react-google-maps';
import CustomMarker from './CustomMarker.js'
import WS from '../services/webservice';

import livreDot from '../images/livre-dot.png';
import ocupadoDot from '../images/ocupado-dot.png';

const GoogleMapsWrapper = withScriptjs(withGoogleMap(props => {
  const { onMapMounted, ...otherProps } = props;
  return <GoogleMap {...otherProps} ref={c => { onMapMounted && onMapMounted(c) }}>
    {props.children}
  </GoogleMap>
}));

class HistoricoRastreamento extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      center: { lat: -25.47, lng: -49.29 },
      zoom: 15,
      dataHoraFim: moment(),
      dataHoraInicio: moment().subtract(1, 'hours'),
      pathPolyline: [],
    }

    this.updateData = this.updateData.bind(this);

    this.webservice = new WS();
  }

  async updateData() {
    let uuid;
    if (!this.props.moto) { return; }
    else { uuid = this.props.moto.uuid333 }
    if (!this.state.dataHoraFim || !this.state.dataHoraInicio) {
      alert('Por favor escolha Data/Hora inicio e fim!')
      return;
    }
    try {
      let params = {
        'dataHoraInicio': moment(this.state.dataHoraInicio).valueOf(),
        'dataHoraFim': moment(this.state.dataHoraFim).valueOf(),
        'at': this.props.moto.porta,
      }
      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
      let result = await this.webservice.fetch2('gps/porMotorista?' + query);
      result = await result.json();
      let obj = result.find((obj) => { return obj.uuid === uuid });
      this.setState({ items: obj.items, pathPolyline: obj.items.map(o => { return { lat: o.latitude, lng: o.longitude } }), }, () => { console.log('Pegou', this.state.items); });
      const bounds = {
        ne: {
          lat: result[0].items[0].latitude + 0.0001, lng: result[0].items[0].longitude + 0.0001
        },
        sw: {
          lat: result[0].items[0].latitude - 0.0001, lng: result[0].items[0].longitude - 0.0001
        }
      };
      result[0].items.forEach(m => {
        if (m.latitude > bounds.ne.lat) {
          bounds.ne.lat = m.latitude;
        }
        if (m.longitude > bounds.ne.lng) {
          bounds.ne.lng = m.longitude;
        }
        if (m.latitude < bounds.sw.lat) {
          bounds.sw.lat = m.latitude;
        }
        if (m.longitude < bounds.sw.lng) {
          bounds.sw.lng = m.longitude;
        }
      });

      const size = {
        width: 440, // Map width in pixels
        height: 380, // Map height in pixels
      };

      const { center, zoom } = fitBounds(bounds, size);
      await this.setState({
        center: center,
        zoom: zoom
      });
    } catch (err) {
      this.setState({ loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
      console.log(err);
    }
  }

  render() {
    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={this.props.modalHide}
        onEntered={() => { this.updateData(); }}
        container={this}
        aria-labelledby="contained-modal-title"
        dialogClassName="rastreamento-dialog-modal"
      >
        <Modal.Body>
          <Form horizontal>
            <FormGroup controlId="formHorizontalEmail">
              <Col componentClass={ControlLabel} sm={2}>
                Per&iacute;odo
                </Col>
              <Col sm={3}>
                <DatePicker
                  selected={this.state.dataHoraInicio}
                  className='form-control'
                  onChange={(v) => this.setState({ dataHoraInicio: v })}
                  showTimeSelect
                  name="dataHoraInicio"
                  timeFormat="HH:mm"
                  timeIntervals={10}
                  dateFormat="DD/MM/YY HH:mm"
                  withPortal
                  timeCaption="hora" />
              </Col>
              <Col sm={3}>
                <DatePicker
                  selected={this.state.dataHoraFim}
                  className='form-control'
                  onChange={(v) => this.setState({ dataHoraFim: v })}
                  showTimeSelect
                  name="dataHoraFim"
                  withPortal
                  timeFormat="HH:mm"
                  timeIntervals={10}
                  dateFormat="DD/MM/YY HH:mm"
                  timeCaption="hora" />
              </Col>
              <Col componentClass={ControlLabel} sm={2}>
                <Button onClick={this.updateData}>Pesquisar</Button>
              </Col>
            </FormGroup>
          </Form>
          <Row>
            <div id="googleMapDiv" style={{ height: '80vh', width: '100%' }}>
              <GoogleMapsWrapper
                options={{ streetViewControl: false, mapTypeControl: false }}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCJvlPMRSy6vltr9575qooDS8bNKRtXxbs&language=pt&region=br&v=3&libraries=drawing"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                onMapMounted={this._handleMapMounted}
                center={this.state.center}
                zoom={this.state.zoom}
              >
                {this.state.items.map((c, i) => {
                  return (
                    <CustomMarker
                      iconUrl={c.livre ? livreDot : ocupadoDot}
                      iconSize={12}
                      lat={c.latitude}
                      lng={c.longitude}
                      onClick={() => { }}
                      info={'Hora: ' + moment(c.datahora).format('HH:mm')}
                    />
                  );
                })}

                <Polyline
                  path={this.state.pathPolyline}
                  options={{
                    strokeColor: '#FF0000',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  }}
                />
              </GoogleMapsWrapper>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}
export default HistoricoRastreamento;