import React, { Component } from 'react';
import ReactDom from 'react-dom';
import WS from '../services/webservice';
import Dialog from 'react-bootstrap-dialog-v2'
import AutoCompleteHelper from './AutoCompleteHelper.js';
import ReactTable from "react-table";
import "react-table/react-table.css";
import CurrencyInput from 'react-currency-input';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import { Row, OverlayTrigger, Popover, Image, Checkbox, Modal, Breadcrumb, Button, Form, FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap'
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Loader from 'react-loader';
import NumberFormat from 'react-number-format';


var user;
var admin = 'inherit';
var centrais;
var allCentrais;
var ref;

class CadastroHelper extends Component {

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.getList = this.getList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.saveStateValues = this.saveStateValues.bind(this);
    this.newRecordData = this.newRecordData.bind(this);
    this.newClicked = this.newClicked.bind(this);

    this.webservice = new WS();

    this.state = {
      center: { lat: -25.475015, lng: -49.2911143 },
      address: [],
      loaded: true,
      central: '',
      cookie: false,
      list: [],
      pages: null,
      loading: true,
      show: false,
      new: false,
      edit: false,
      id: 0,
      record: this.newRecordData(),
      modulos: [],
      modulosTable: [],
      modulosSelected: [],
      sendModulos: [],
      actionsCheck: [],
      showModulos: false,
    }
  }
  renderFields(field, pai) {
    if (this.props.dados.requests.type === "voucher_empresa" && !this.state.cookie) {
      let id = this.props.dados.requests.getCookie('central-id');
      let number = parseInt(id, 10);
      this.saveStateValues("central", number, pai)
      this.props.dados.requests.getCentral(number)
        .then(result => { this.setState({ central: result.nomefantasia }); })
      this.setState({ cookie: true });
    }
    if (field.campos) {
      let referencia = this.state.record;
      if (pai) {
        referencia = this.state.record[pai];
      }
      if (!referencia) {
        return;
      }
      return (
        <div>
          {field.campos.map((m, i) => {
            if (this.state.edit) {
              if (this.props.dados.requests.type === "voucher_empresa" &&
                m.ref === "senha" && referencia[m.ref] === undefined) {
                return null
              }
              if (m.ref !== "senha") {
                if (referencia[m.ref] === undefined) {
                  return null;
                }
              }
            }
            if (m.type === "select2") {
              return (
                <FormGroup style={{ display: admin }}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <Select2
                    multiple
                    ref={m.ref}
                    value={referencia[m.ref]}
                    data={this.state.niveisList}
                    onSelect={e => {
                      this.saveStateValues(m.ref, this.getSelectedOptionsValue(e.target.selectedOptions), pai)
                    }}
                    onUnselect={e => {
                      this.saveStateValues(m.ref, this.getSelectedOptionsValue(e.target.selectedOptions), pai)
                    }}
                  />
                </FormGroup>
              );
            } else if (m.type === "select2cidades") {              
              return (
                <FormGroup style={{ display: admin }}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <Select2
                    multiple
                    ref={m.ref}
                    value={referencia[m.ref]}
                    data={this.props.dados.parentProps.childProps.userAttrib.cidade}
                    onSelect={e => {
                      console.log('select2cidades',e)
                      this.saveStateValues(m.ref, this.getSelectedOptionsValue(e.target.selectedOptions), pai)
                    }}
                    onUnselect={e => {
                      this.saveStateValues(m.ref, this.getSelectedOptionsValue(e.target.selectedOptions), pai)
                    }}
                  />
                </FormGroup>
              );
            } else if (m.type === "select3") {              
              return (
                <FormGroup style={{ display: admin }}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <Select2
                    ref={m.ref}
                    value={referencia[m.ref]}
                    data={m.niveisList}
                    onSelect={e => {
                      console.log('select3',e.target.selectedOptions)
                      this.saveStateValues(m.ref, this.getSelectedOptionsValueInt(e.target.selectedOptions), pai)
                    }}
                    onUnselect={e => {
                      this.saveStateValues(m.ref, this.getSelectedOptionsValueInt(e.target.selectedOptions), pai)
                    }}
                  />
                </FormGroup>
              );
            } else if (m.type === "select2Padrao") {
              return (
                <FormGroup>
                  <ControlLabel>{m.label}</ControlLabel>
                  <Select2
                    ref={m.ref}
                    value={referencia[m.ref]}
                    data={this.props.dados.parentProps.childProps.userAttrib.centrais}
                    onSelect={e => {
                      this.saveStateValues(m.ref, e.target.selectedOptions[0].value, pai)
                    }}
                    onUnselect={e => {
                      this.saveStateValues(m.ref, this.getSelectedOptionsValue(e.target.selectedOptions), pai)
                    }}
                  />
                </FormGroup>
              );
            } else if (m.type === "selectDepartamento") {
              console.log("minhas centrais aqui", centrais)
              return (
                <FormGroup>
                  <ControlLabel>{m.label}</ControlLabel>
                  <Select2
                    ref={m.ref}
                    value={referencia[m.ref]}
                    data={this.props.dados.parentProps.childProps.userAttrib.departamento}
                    onSelect={e => {
                      let vetorzin = [];
                      vetorzin.push(Number(e.target.selectedOptions[0].value));
                      this.props.dados.requests.attCentral2(this.state.id, this.props.dados.parentProps.childProps.centralData.id, vetorzin);
                    }}
                    onUnselect={e => {
                      this.saveStateValues(m.ref, this.getSelectedOptionsValue(e.target.selectedOptions), pai)
                    }}
                  />
                </FormGroup>
              )
            }
            else if (m.type === "select2Centrais") {
              if (!this.state.new) {
                allCentrais = this.props.dados.parentProps.childProps.userAttrib.centrais;
                centrais = [];
                ref = referencia[m.ref];
                if (ref) {
                  ref.map((c, i) => {
                    centrais.push(c.central);
                    return null;
                  })
                }
                return (
                  <FormGroup style={{ display: admin }}>
                    <ControlLabel>{m.label}</ControlLabel>
                    <Select2
                      multiple
                      ref={m.ref}
                      value={centrais}
                      data={allCentrais}
                      onSelect={e => {
                        this.setState({ loaded: false });
                        centrais = this.getSelectedOptionsValue(e.target.selectedOptions);
                        this.props.dados.requests.addCentral(this.state.id, e.params.data.id, null, true).then(
                          () => {
                            this.getUser().then(() => {
                              ref = user[m.ref];
                              referencia[m.ref] = user[m.ref];
                              if (ref) {
                                centrais = [];
                                ref.map((c, i) => {
                                  centrais.push(c.central);
                                  return null;
                                })
                              }
                              this.setState({ loaded: true });
                            });
                          })
                      }}
                      onUnselect={e => {
                        this.setState({ loaded: false });
                        centrais = this.getSelectedOptionsValue(e.target.selectedOptions);
                        this.props.dados.requests.delCentral(this.state.id, e.params.data.id).then(
                          () => {
                            this.getUser().then(() => {
                              ReactDom.findDOMNode(this.refs.nome).focus();
                              ref = user[m.ref];
                              referencia[m.ref] = user[m.ref];
                              if (ref) {
                                centrais = [];
                                ref.map((c, i) => {
                                  centrais.push(c.central);
                                  return null;
                                })
                              }
                              this.setState({ loaded: true });
                            });
                          })
                      }}
                    />
                    <Loader loaded={this.state.loaded}>
                      {centrais.length > 0 &&
                        <div style={{ padding: 10, textAlign: 'center' }}>
                          <Row style={{ borderBottom: '1px solid' }}>
                            <Col xs={12} md={3}>
                              <FormGroup>
                                <ControlLabel>CENTRAL</ControlLabel>
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                              <FormGroup>
                                <ControlLabel>NÍVEIS DE ACESSO</ControlLabel>
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={3}>
                              <FormGroup>
                                <ControlLabel>DEPARTAMENTO</ControlLabel>
                              </FormGroup>
                            </Col>
                          </Row>
                          {ref.map((c, i) => {
                            let index;
                            let temcentral = false;
                            for (let i2 = 0; i2 < allCentrais.length; i2++) {
                              if (allCentrais[i2].id === c.central) {
                                index = i2;
                                temcentral = true;
                              }
                            }
                            return (<>{temcentral && 
                              <Row style={{ padding: '10px 0 5px 0', borderBottom: '1px solid' }}>
                                <Col xs={12} md={3} style={{ paddingTop: 8 }}>
                                  <span>{allCentrais[index].nome && allCentrais[index].nome}</span>
                                  <span>{allCentrais[index].nomefantasia && allCentrais[index].nomefantasia}</span>
                                </Col>
                                <Col xs={12} md={6}>
                                  <FormGroup style={{ display: admin }}>
                                    <Select2
                                      multiple
                                      ref={m.ref}
                                      value={c.niveisacesso}
                                      data={this.state.niveisList}
                                      onSelect={e => {
                                        c.niveisacesso = this.getSelectedOptionsValue(e.target.selectedOptions);
                                        this.props.dados.requests.attCentral(this.state.id, c.central, c.niveisacesso, true);
                                      }}
                                      onUnselect={e => {
                                        c.niveisacesso = this.getSelectedOptionsValue(e.target.selectedOptions);
                                        this.props.dados.requests.attCentral(this.state.id, c.central, c.niveisacesso, true);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={12} md={3}>
                                  <FormGroup style={{ display: admin }}>
                                    <Select2
                                      ref={m.ref}
                                      value={c.departamentos}
                                      data={this.props.dados.parentProps.childProps.userAttrib.departamento}
                                      onSelect={e => {
                                        let vetorzin = [];
                                        vetorzin.push(Number(e.target.selectedOptions[0].value));
                                        this.props.dados.requests.attCentral2(this.state.id, c.central, vetorzin);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>}</>
                            );
                          })}
                        </div>
                      }
                    </Loader>
                  </FormGroup>
                );
              } else { return null; }
            } else if (m.type === 'ramal') {
              return (
                <FormGroup key={m.ref}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <FormControl
                    value={referencia[m.ref] || 0}
                    ref={m.ref}
                    type="text"
                    placeholder={m.placeHolder}
                    onChange={e => {
                      let numbers = e.target.value.replace(/\D/g, '');
                      this.saveStateValues(m.ref, numbers, pai)
                    }}
                  />
                </FormGroup>
              )
            } else if (m.type === "autocomplete") {
              return (
                <FormGroup key={m.ref}>
                  <AutoCompleteHelper
                    address={this.state.address}
                    endereco={referencia["endereco"]}
                    changeAddress={function (address) { this.setState({ address }) }.bind(this)}
                    changeEndereco={
                      function (endereco, cidade, estado) {
                        this.saveStateValues("endereco", endereco, pai);
                      }.bind(this)}
                    endSelected={
                      function (endereco, cidade, estado) {
                        this.saveStateValues("endereco", endereco, pai);
                        this.webservice.geocodeByPlaceId(endereco, null, null).then(result => {
                          this.saveStateValues("latitude", result.latitude, pai);
                          this.saveStateValues("longitude", result.longitude, pai);
                        });
                      }.bind(this)}
                    title="Endereço"
                    center={this.state.center}
                  />
                </FormGroup>
              );
            } else if (m.type === 'voucher') {
              return (
                <FormGroup key={m.ref}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <Row>
                    <Col md={11} xs={11}>
                      <Select2
                        ref={m.ref}
                        value={referencia[m.ref]}
                        data={[{ text: "Voucher Eletrônico", id: 0 }, { text: "Voucher de Papel", id: 1 }]}
                        onSelect={e => {
                          this.saveStateValues(m.ref, parseInt(e.target.selectedOptions[0].value, 10), pai);
                        }}
                        onUnselect={e => {
                          this.saveStateValues(m.ref, this.getSelectedOptionsValue(e.target.selectedOptions), pai);
                        }}
                      />
                    </Col>
                    <Col md={1} xs={1}>
                      <Image style={{ cursor: 'pointer' }} src={require("../images/remove.png")} onClick={() => { this.saveStateValues("tipo_voucher", null, pai) }} />
                    </Col>
                  </Row>
                </FormGroup>
              )
            } else if (m.type === 'currencydisabled') {
              referencia[m.ref] = referencia[m.ref] === "" ? 0 : referencia[m.ref];
              return (
                <FormGroup key={m.ref}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <NumberFormat ref={m.ref} value={Number(referencia[m.ref])} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />
                </FormGroup>
              )
            } else if (m.type === 'currency') {
              referencia[m.ref] = referencia[m.ref] === "" ? "00.00" : referencia[m.ref];
              return (
                <FormGroup key={m.ref}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <CurrencyInput
                    ref={m.ref}
                    className="form-control"
                    value={(referencia[m.ref] && referencia[m.ref].taxaRetornoFixo) || referencia[m.ref]}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix="R$ "
                    onChangeEvent={(event, maskedvalue, floatvalue) => {
                      this.saveStateValues(m.ref, floatvalue, pai)
                    }}
                  />
                </FormGroup>
              )
            } else if (m.type === 'percentage') {
              referencia[m.ref] = referencia[m.ref] === "" ? "00.00" : referencia[m.ref];
              return (
                <FormGroup key={m.ref}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <CurrencyInput
                    ref={m.ref}
                    className="form-control"
                    value={referencia[m.ref]}
                    decimalSeparator=","
                    thousandSeparator="."
                    suffix="%"
                    onChangeEvent={(event, maskedvalue, floatvalue) => {
                      this.saveStateValues(m.ref, floatvalue, pai)
                    }}
                  />
                </FormGroup>
              )
            } else if (m.type === 'date') {
              if (!referencia[m.ref]) {
                this.saveStateValues(m.ref, moment(), pai);
              }
              return (
                <FormGroup key={m.ref}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <DatePicker
                    selected={referencia[m.ref]}
                    className='form-control'
                    dateFormat="DD/MM/YYYY"
                    onChange={e => { this.saveStateValues(m.ref, e, pai) }}
                    name={m.ref}
                  />
                </FormGroup>
              )
            } else if (m.type === 'maskedtext') {
              return (
                <FormGroup key={m.ref}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <InputMask
                    value={referencia[m.ref] || ''}
                    ref={m.ref}
                    className="form-control"
                    mask={m.mask}
                    maskChar="_"
                    placeholder={m.placeHolder}
                    onChange={e => { this.saveStateValues(m.ref, e.target.value, pai) }}
                  />
                </FormGroup>
              );
            } else if (m.type === 'telefone') {
              return (
                <FormGroup key={m.ref}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <InputMask
                    value={referencia[m.ref] || ''}
                    ref={m.ref}
                    className="form-control"
                    mask={/^.....9/.test(referencia[m.ref]) ? m.maskCel : m.maskFixo}
                    maskChar="_"
                    placeholder={m.placeHolder}
                    onChange={e => { this.saveStateValues(m.ref, e.target.value, pai) }}
                  />
                </FormGroup>
              );
            } else if (m.type === 'json') {
              return (
                <FormGroup key={m.ref}>
                  <ControlLabel>{m.label}</ControlLabel>
                  {this.renderFields(m, m.ref)}
                </FormGroup>
              )
            } else if (m.type === 'checkbox') {
              if (m.ref === "admin") {
                if (this.props.dados.parentProps.childProps.userAttrib.admin) {
                  return (
                    <FormGroup key={m.ref}>
                      <Checkbox
                        style={{ margin: '0' }}
                        checked={referencia[m.ref] ? true : false}
                        onChange={e => {
                          this.saveStateValues(m.ref, e.target.checked, pai)
                        }}>
                        <b>{m.label}</b>
                      </Checkbox>
                    </FormGroup>
                  )
                } else {
                  return null;
                }
              }
              if (m.ref === "admin_cidade") {
                console.log('admin_cidade: ', referencia[m.ref])
                console.log('admin_cidade 1', referencia[m.ref] === true ? true : false)
                  return (
                    <FormGroup key={m.ref}>
                      <Checkbox
                        style={{ margin: '0' }}
                        checked={referencia[m.ref] === true ? true : false}
                        onChange={e => {
                          this.saveStateValues(m.ref, e.target.checked, pai)
                        }}>
                        <b>{m.label}</b>
                      </Checkbox>
                    </FormGroup>
                  )
              }
              if (m.ref === "destino_obrigatorio") {
                return (
                  <FormGroup key={m.ref}>
                    <Checkbox
                      style={{ margin: '0' }}
                      checked={referencia[m.ref] ? true : false}
                      onChange={e => { this.saveStateValues(m.ref, e.target.checked, pai) }}>
                      <b>{m.label}</b>
                    </Checkbox>
                  </FormGroup>
                )
              }
              if (m.ref === "monitorcentral") {
                let adminCentralStyle = "none";
                if (referencia["centrais"] && referencia["centrais"].length > 0) {
                  adminCentralStyle = "inherit";
                } else {
                  adminCentralStyle = "none";
                }
                if (!this.state.new) {
                  return (
                    <FormGroup style={{ display: adminCentralStyle }} key={m.ref}>
                      <Checkbox
                        style={{ margin: '0' }}
                        checked={referencia[m.ref] ? true : false}
                        onChange={e => {
                          let id;
                          this.saveStateValues(m.ref, e.target.checked, pai)
                          this.state.niveisList.map((n, i) => {
                            if (n.descricao === "Admin Central") { id = n.id; }
                            return null;
                          })
                          ref.map((c, i) => {
                            if (e.target.checked) {
                              c.niveisacesso = [id];
                              this.props.dados.requests.attCentral(this.state.id, c.central, [id], true);
                              return null;
                            } else {
                              c.niveisacesso = [];
                              this.props.dados.requests.attCentral(this.state.id, c.central, [], true);
                              return null;
                            }
                          })
                        }}>
                        <b>{m.label}</b>
                      </Checkbox>
                    </FormGroup>
                  )
                } else {
                  return null;
                }
              }
              return (
                <FormGroup key={m.ref}>
                  <Checkbox
                    style={{ margin: '0' }}
                    checked={referencia[m.ref] ? true : false}
                    onChange={e => { this.saveStateValues(m.ref, e.target.checked, pai) }}>
                    <b>{m.label}</b>
                  </Checkbox>
                </FormGroup>
              );
            } else if (m.type === "disabled") {
              return (
                <FormGroup key={m.ref}>
                  <ControlLabel>{m.label}</ControlLabel>
                  <FormControl
                    value={referencia[m.ref] || ''}
                    ref={m.ref}
                    disabled={true}
                  />
                </FormGroup>
              );
            } else if (m.type === "textInfo") {
              return (
                <FormGroup key={m.ref}>
                  <ControlLabel>
                    {m.label}&nbsp;
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={
                        <Popover id="popover-trigger-hover-focus">
                          <strong>{`- \${valor} :`}</strong> Valor da corrida<br />
                          <strong>{`- \${porta} :`}</strong> AT do Motorista<br />
                          <strong>{`- \${modelo} :`}</strong> Carro do Motorista<br />
                          <strong>{`- \${contato} :`}</strong> Nome do Cliente<br />
                          <strong>{`- \${minutos} :`}</strong> Mins chegada do moto<br />
                        </Popover>
                      }
                    >
                      <Image src={require('../images/info.png')} />
                    </OverlayTrigger>
                  </ControlLabel>
                  <FormControl
                    value={referencia[m.ref] || ''}
                    ref={m.ref}
                    type="text"
                    placeholder={m.placeHolder}
                    onChange={e => { this.saveStateValues(m.ref, e.target.value, pai) }}
                  />
                </FormGroup>
              );
            } else if (m.type === "hidden") {
              return null;
            } else if (m.type === "modulos") {
              if (this.state.modulos) {
                return (
                  <div key={m.ref}>
                    <ReactTable
                      data={this.state.modulosTable}
                      resolveData={data => data.map(row => row)}
                      columns={[
                        {
                          Header: "Modulos",
                          accessor: "name"
                        },
                        {
                          Header: "Ações",
                          id: 'actions',
                          accessor: d => d.actions ? d.actions.map((m, i) => m).join(', ') : ''
                        },
                        {
                          Header: 'Remover',
                          width: 68,
                          filterable: false,
                          sortable: false,
                          style: {
                            cursor: 'pointer',
                          },
                          Cell: props =>
                            <div>
                              <Image src={require('../images/remove.png')} style={{ margin: 'auto', display: 'block' }}
                                onClick={function () {
                                  Dialog.setOptions({
                                    defaultOkLabel: 'Sim',
                                    defaultCancelLabel: 'Não',
                                    primaryClassName: 'btn-danger '
                                  })
                                  this.dialog.show({
                                    body: 'Você tem certeza que deseja remover??',
                                    actions: [
                                      Dialog.CancelAction(() => { }),
                                      Dialog.OKAction(() => {
                                        for (let i = 0; i < this.state.modulosTable.length; i++) {
                                          if (this.state.modulosTable[i].id === props.original.id) {
                                            this.state.modulosTable.splice(i, 1);
                                            this.setState({ modulosTable: this.state.modulosTable }, () => console.log(this.state.modulosTable))
                                            break;
                                          }
                                        }
                                      })
                                    ],
                                    bsSize: 'small',
                                    onHide: (dialog) => {
                                      dialog.hide()
                                    }
                                  })
                                }.bind(this)}></Image>

                            </div>
                        }

                      ]}
                      defaultPageSize={5}
                      className="-striped -highlight"
                    />
                    <Button onClick={() => {
                      this.setState({ showModulos: true, moduloSelected: [], actionsCheck: [], moduloSelectedData: [] })
                      let m = this.state.modulos.filter(array => !this.state.modulosTable.find(m => m.id === array.id));
                      this.setState({ modulos: m })
                    }}>Adicionar Modulos</Button>
                  </div>
                );
              }
              else return null;
            } else {
              if (i === 0) {
                return (
                  <FormGroup key={m.ref}>
                    <ControlLabel>{m.label}</ControlLabel>
                    <FormControl
                      autoFocus
                      value={referencia[m.ref] || ''}
                      ref={m.ref}
                      maxLength={m.label === "Sigla" ? "3" : ""}
                      type={m.type}
                      placeholder={m.placeHolder}
                      onChange={e => {
                        this.saveStateValues(m.ref, e.target.value, pai)
                      }}
                    />
                  </FormGroup>
                );
              } else {
                return (
                  <FormGroup key={m.ref}>
                    <ControlLabel>{m.label}</ControlLabel>
                    <FormControl
                      value={referencia[m.ref] || ''}
                      ref={m.ref}
                      type={m.type}
                      maxLength={m.maxLength ? m.maxLength : ""}
                      placeholder={m.placeHolder}
                      onChange={e => { this.saveStateValues(m.ref, e.target.value, pai) }}
                    />
                  </FormGroup>
                );
              }
            }


          })}
        </div>);
    }
  }
  saveStateValues(ref, valor, pai) {
    let r = this.state.record;
    if (pai) {
      r[pai][ref] = valor;
    } else {
      if (ref === "taxa_retorno") {
        r[ref] = { taxaRetornoFixo: valor };
      } else {
        r[ref] = valor;
      }
    }
    this.setState({ record: r });
  }
  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {
    if (this.props.dados.parentProps.new) { this.newClicked() }
    if (this.props.dados.requests.modulos) { this.getModulos() }

  }

  submit(e) {
    e.preventDefault();
    let saverecords = [];
    //tratajason
    this.props.dados.campos.map((m, i) => {
      if (!m.campos) {
        return (saverecords = { ...saverecords, [m.ref]: this.state.record[m.ref] })
      } else {
        return (saverecords = { ...saverecords, [m.ref]: JSON.stringify(this.state.record[m.ref]) })
      }
    })
    console.log('saverecords', saverecords)
    if (saverecords.departamentos && saverecords.departamentos.length > 0) {
      saverecords.centrais[0].departamentos = saverecords.departamentos
    }
    let allFields = false;
    for (let i = 0; i < this.props.dados.campos.length; i++) {
      if (this.props.dados.campos[i].type === "disabled") { continue; }
      if (this.props.dados.campos[i].ref === "uuid333") { allFields = true; continue; }
      if (this.props.dados.campos[i].type === "ramal") {
        if (saverecords["ramal"] === "") {
          saverecords["ramal"] = 0;
        }
        continue;
      }
      if (this.props.dados.requests.type === "nivel_acesso") { allFields = true; continue; }
      if (this.props.dados.requests.type === "voucher_empresa" &&
        (this.props.dados.campos[i].ref === "email" ||
          this.props.dados.campos[i].ref === "cpf" ||
          this.props.dados.campos[i].ref === "nome" ||
          this.props.dados.campos[i].ref === "senha")) { continue; }
      if (this.props.dados.requests.type === "usuario" &&
        this.props.dados.campos[i].ref === "senha") { continue; }
      if (this.props.dados.campos[i].ref === "tipo_voucher") { continue; }
      if (this.props.dados.campos[i].ref.indexOf("sendgrid") > -1) { continue; }
      if (this.props.dados.parentProps.childProps.userAttrib.admin) {
        if (this.props.dados.campos[i].ref === "centrais") { continue; }
      }
      if (!this.props.dados.parentProps.childProps.userAttrib.admin && this.state.new) {
        if (this.props.dados.campos[i].ref === "centrais") { continue; }
      }
      if (!this.props.dados.parentProps.childProps.userAttrib.admin && !this.state.new) {
        if (this.props.dados.campos[i].ref === "centrais") {
          if (!saverecords[this.props.dados.campos[i].ref] && saverecords["monitorcentral"] === false) {
            this.dialog.showAlert('Por favor preencha as Centrais ou como Administrador da Central!');
            allFields = false;
            break;
          }
          else if (saverecords[this.props.dados.campos[i].ref] &&
            saverecords[this.props.dados.campos[i].ref].length <= 0 && saverecords["monitorcentral"] === false) {
            this.dialog.showAlert('Por favor preencha as Centrais ou como Administrador da Central!!');
            allFields = false;
            break;
          } else {
            allFields = true;
            continue;
          }
        }
      }
      if (saverecords[this.props.dados.campos[i].ref] === "" || saverecords[this.props.dados.campos[i].ref] === null || saverecords[this.props.dados.campos[i].ref] === undefined) {
        this.dialog.showAlert('Por favor preencha ' + this.props.dados.campos[i].label + '!');
        allFields = false;
        break;
      }
      allFields = true;
    }
    if (!allFields) { return; }
    if (this.props.dados.requests.type === "voucher_empresa") {
      if (saverecords.endereco && saverecords.endereco.length > 0) {
        if (saverecords.endereco.includes(", ")) {
          let newEnd = saverecords.endereco.split(", ");

          if (newEnd[0] && newEnd[0].match("[A-z]+")) {

          } else {
            this.dialog.showAlert('Por favor preencha o endereço corretamente!(Rua, Número)');
            return;
          }

          if (newEnd[1] && newEnd[1].match("[0-9]+")) {

          } else {
            this.dialog.showAlert('Por favor preencha o endereço corretamente!(Rua, Número)');
            return;
          }
        } else {
          this.dialog.showAlert('Por favor preencha o endereço corretamente!(Rua, Número)');
          return;
        }
      } else {
        this.dialog.showAlert('Por favor preencha o endereço corretamente!(Rua, Número)');
        return;
      }
    }
    if (this.state.new) {
      if (this.props.dados.requests.type === 'nivel_acesso') {
        this.setState({ sendModulos: { ...this.state.sendModulos, ...saverecords, modulos: this.state.modulosTable, } },
          () => {
            Dialog.resetOptions();
            this.props.dados.requests.save(this.props.dados.requests.type, this.state.sendModulos, this.props.dados.campos).then(result => {
              if (result.ok) {
                this.dialog.showAlert(this.props.dados.tipo_cadastro + ' criado com sucesso!')
                this.setState({ show: false, new: false })
                this.getList();
              } else {
                this.dialog.showAlert('Por favor preencha os campos obrigatórios!')
              }
            })
          })
      } else {
        if (this.props.dados.requests.type === 'voucher_empresa') {
          let id = this.props.dados.requests.getCookie('central-id');
          let number = parseInt(id, 10);
          saverecords = { ...saverecords, central: number }
        }
        this.props.dados.requests.save(this.props.dados.requests.type, saverecords, this.props.dados.campos).then(result => {
          Dialog.resetOptions()
          if (result.ok) {
            this.dialog.showAlert(this.props.dados.tipo_cadastro + ' criado com sucesso!')
            this.setState({ show: false, new: false })
            this.getList();
          } else {
            this.dialog.showAlert('Por favor preencha os campos obrigatórios!')
          }
        })
      }
    } else if (this.state.edit) {
      if (this.props.dados.requests.type === 'nivel_acesso') {
        this.setState({ record: { ...this.state.record, modulos: this.state.modulosTable } }, () => {
          this.props.dados.requests.edit(this.props.dados.requests.type, this.state.id, this.state.record, this.props.dados.campos).then(result => {
            Dialog.resetOptions();
            if (result.ok) {
              this.dialog.showAlert(this.props.dados.tipo_cadastro + ' editado com sucesso!')
              this.setState({ show: false, edit: false })
              this.getList();
            } else {
              this.dialog.showAlert('Por favor preencha os campos obrigatórios!')
            }
          })
        })
      } else {
        if (this.props.dados.requests.type === "usuario") {
          delete saverecords.niveisacesso;
        }
        this.props.dados.requests.edit(this.props.dados.requests.type, this.state.id, saverecords, this.props.dados.campos).then(result => {
          Dialog.resetOptions()
          if (result.ok) {
            this.dialog.showAlert(this.props.dados.tipo_cadastro + ' editado com sucesso!')
            this.setState({ show: false, edit: false })
            this.getList();
          } else {
            this.dialog.showAlert('Por favor preencha os campos obrigatórios!')
          }
        })
      }
    }
  }

  async getModulos() {
    let response = await this.props.dados.requests.modulos();
    response = await response.json();

    let result = response.map((el) => {
      if (el.folder) return null;
      else {
        return {
          ...el, text: el.nome
        }
      }
    });

    result = result.filter(Boolean);

    this.setState({ modulos: result, allModulos: result })
  }

  async getUser() {
    let response = await this.props.dados.requests.list(this.props.dados.requests.type, this.state.id, [], 1, 5, [], this.props.dados.campos);
    response = await response.json();
    user = response;
  }

  async getNiveis(dados, instance) {
    let response = await this.props.dados.requests.niveis('nivel_acesso', '', [], 1, 20, [], this.props.dados.campos);
    response = await response.json();

    let result = response.map((el) => {
      if (el.modulos.length > 0) {
        return {
          ...el, text: el.descricao
        }
      } else {
        return null;
      }
    });

    result = result.filter(Boolean);

    this.setState({ niveisList: result })
  }

  async newClicked() {
    admin = 'inherit';
    this.props.dados.parentProps.history.push("/" + this.props.dados.requests.type + "/novo")
    await this.setState({ show: true, new: true, edit: false, id: 0, record: await this.newRecordData(), modulosTable: [] })
    this.props.dados.campos.map((m, i) => {
      if (m.type === 'checkbox') {
        if (!this.state.record[m.ref]) {
          this.setState({
            record: { ...this.state.record, [m.ref]: false }
          })
        }
      }
      return null
    })
  }
  async newRecordData() {
    let r = [];
    this.props.dados.campos.map((m, i) => {
      if (!m.campos) {
        return (
          r = { ...r, [m.ref]: m.defaultvalue ? m.defaultvalue : '' }
        );
      } else {
        let a = []
        m.campos.map((x, y) => {
          a = { ...a, [x.ref]: m.defaultvalue ? m.defaultvalue : '' }
          return null;
        })
        return (
          r = { ...r, [m.ref]: a }
        );
      }
    })

    return r;
  }
  async editClicked(props) {
    admin = 'inherit';
    let r = [];
    let table = [];
    this.setState({
      show: true,
      edit: true,
      new: false,
      record: this.newRecordData(),
      id: props.original[this.props.dados.id],
    }, () => {
      if (this.props.dados.requests.type === 'usuario') {
        this.getUser().then(() => {
          this.props.dados.campos.map((m, i) => {
            if (m.ref === 'niveisacesso' && user) {
              return (
                r = { ...r, niveisacesso: user.niveisacesso }
              );
            }
            return null;
          })
          this.setState({ record: r })
        });
      }
      this.props.dados.campos.map((m, i) => {
        if (!m.campos) {
          let v = ''
          if (props.original[m.ref] !== null) {
            v = props.original[m.ref];
          } else {
            v = m.defaultvalue ? m.defaultvalue : '';
          }
          if (m.type === "date") {
            v = moment(v);
          }
          if (m.ref === "tipo_voucher" && props.original[m.ref] === null) {
            v = null;
          }
          if (m.ref === "destino_obrigatorio" && props.original[m.ref] === false) {
            v = false;
          }
          if (m.ref === "destino_obrigatorio" && props.original[m.ref] === true) {
            v = true;
          }
          return (
            r = { ...r, [m.ref]: v }
          );
        } else {
          let a = []
          m.campos.map((x, y) => {
            let v = ''
            if (props.original[m.ref]) {
              v = props.original[m.ref][x.ref];
            }
            if (m.type === "date") {
              v = moment(v);
            }
            a = { ...a, [x.ref]: v }
            return null;
          })
          return (
            r = { ...r, [m.ref]: a }
          );
        }
      })
      if (r.modulos) {
        r.modulos.map((a, j) => {
          this.state.allModulos.map((n, k) => {
            if (a.id === n.id) {
              table.push({ id: n.id, name: n.nome, actions: a.actions })
            }
            return null;
          })
          return null;
        })
        this.setState({ modulosTable: table })
      }
      this.setState({ record: r })
    })
  }

  getList() {
    this.setState({ dados: { ...this.state.dados, page: this.state.dados.page - 1 } })
    this.fetchData(this.state.dados, this.state.instance);
  }

  modalHide() {
    this.props.dados.parentProps.history.push("/" + this.props.dados.requests.type)
    this.setState({ show: false, new: false, edit: false });
  }

  modulosHide() {
    this.props.dados.parentProps.history.push("/" + this.props.dados.requests.type)
    this.setState({ showModulos: false });
  }

  //Get the options from multiselect fields, put to an array and later save on DB(type of cars, payment, clients and runs)
  getSelectedOptionsValue(selectedOptions) {
    var values = [];

    if (selectedOptions.length <= 0) {
      values = null;
      return values;
    }

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value)
    }

    return values;
  }
  getSelectedOptionsValueInt(selectedOptions) {
    var values = [];

    if (selectedOptions.length <= 0) {
      values = null;
      return 0;
    }

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value)
    }

    return values[0];
  }

  async fetchData(dados, instance) {
    // show the loading overlay
    this.setState({ loading: true, dados: dados, instance: instance })
    if (this.props.dados.requests.niveis) { this.getNiveis(dados, instance) }
    // fetch your data
    let order = dados.sorted;
    if (order.length <= 0) {
      if (this.props.dados.requests.defaultorder !== undefined) {
        order = this.props.dados.requests.defaultorder;
      }
      if (this.props.dados.requests.type === "tipo_carro") { order = [{ id: "t03_prioridade", desc: false }] }
      if (this.props.dados.requests.type === "tipo_cliente") { order = [{ id: "t14_prioridade", desc: false }] }
      if (this.props.dados.requests.type === "tipo_corrida") { order = [{ id: "t04_prioridade", desc: false }] }
      if (this.props.dados.requests.type === "tipo_pagamento") { order = [{ id: "t05_prioridade", desc: false }] }
    }
    let filtros = dados.filtered;
    //console.log('dados.filtered 1',filtros)
    if (filtros.length <= 0) {
      if (this.props.dados.requests.defaultfilter !== undefined) {
        filtros = this.props.dados.requests.defaultfilter;
      }
    }
    console.log('dados.filtered 2',filtros)
    let result = await this.props.dados.requests.list(this.props.dados.requests.type, '', order, ++dados.page, dados.pageSize, filtros, this.props.dados.campos);
    let count = result.headers.get('X-Total-Count');
    result = await result.json();

    this.setState({
      list: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false
    })
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => { this.fetchData(dados, instance) }, 300)
    });
  }

  render() {


    console.log('user cadsastrohelper', this.props.dados.parentProps.childProps.userAttrib)
    let botaonovo = true;
    if (this.props.dados.novo === undefined) {
      // sentenças aqui são executadas
    } else {
      botaonovo = this.props.dados.novo;
    }
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active href={this.props.dados.href}>{this.props.dados.titulo}</Breadcrumb.Item>
        </Breadcrumb>

        <Col md={12}>

          {(this.props.dados.parentProps.childProps.userAttrib.admin ||
                      this.props.dados.parentProps.childProps.userAttrib.monitorcentral ||
            (this.props.dados.parentProps.childProps.userAttrib.acesso[this.props.dados.acessoID]
              && this.props.dados.parentProps.childProps.userAttrib.acesso[this.props.dados.acessoID].indexOf('create') > -1
              && this.props.dados.requests.type !== 'nivel_acesso')) && this.props.dados.requests.save && botaonovo &&
            <Button style={{ margin: '-10px 0 10px 0' }} onClick={this.newClicked.bind(this)}>Novo</Button>
          }
          <ReactTable
            manual
            data={this.state.list}
            pages={this.state.pages}
            loading={this.state.loading}
            onFetchData={this.fetchDataTimeOut}
            previousText='Anterior'
            nextText='Próxima'
            loadingText='Carregando...'
            noDataText='Nenhum dado encontado'
            pageText='Página'
            ofText='de'
            rowsText='linhas'
            getTdProps={(state, props, column) => {
              return {
                style: {
                  cursor: this.props.dados.parentProps.childProps.userAttrib.acesso[this.props.dados.acessoID] && this.props.dados.parentProps.childProps.userAttrib.acesso[this.props.dados.acessoID].indexOf('update') > -1 ? "pointer" : "default"
                },
                onClick: (e) => {
                  if (this.props.dados.requests.type !== 'nivel_acesso') {
                    if ((this.props.dados.parentProps.childProps.userAttrib.admin ||
                      this.props.dados.parentProps.childProps.userAttrib.monitorcentral ||
                      (this.props.dados.parentProps.childProps.userAttrib.acesso[this.props.dados.acessoID] &&
                        this.props.dados.parentProps.childProps.userAttrib.acesso[this.props.dados.acessoID].indexOf('update') > -1))) {
                      if (column.Header !== 'Ações' && props) {
                        this.editClicked(props);
                      }
                    }
                  }
                },
              }
            }}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
            columns={[
              {
                columns: [
                  ...this.props.dados.table.colunas,
                  {
                    Header: 'Ações',
                    width: 90,
                    filterable: false,
                    sortable: false,
                    style: {
                      cursor: 'pointer',
                    },
                    Cell: props =>
                      <div>
                        {(this.props.dados.parentProps.childProps.userAttrib.admin ||
                      this.props.dados.parentProps.childProps.userAttrib.monitorcentral ||
                          (this.props.dados.parentProps.childProps.userAttrib.acesso[this.props.dados.acessoID]
                            && this.props.dados.parentProps.childProps.userAttrib.acesso[this.props.dados.acessoID].indexOf('update') > -1
                            && this.props.dados.requests.type !== 'nivel_acesso')) &&
                          <Image src={require('../images/edit.png')} style={{ marginLeft: '10px' }} onClick={() => { this.editClicked(props) }}></Image>
                        }
                        {(this.props.dados.parentProps.childProps.userAttrib.admin ||
                      this.props.dados.parentProps.childProps.userAttrib.monitorcentral ||
                          (this.props.dados.parentProps.childProps.userAttrib.acesso[this.props.dados.acessoID]
                            && this.props.dados.parentProps.childProps.userAttrib.acesso[this.props.dados.acessoID].indexOf('delete') > -1
                            && this.props.dados.requests.type !== 'nivel_acesso')) && this.props.dados.requests.delete &&
                          <Image src={require('../images/remove.png')} style={{ marginLeft: '10px' }} onClick={function () {
                            Dialog.setOptions({
                              defaultOkLabel: 'Sim',
                              defaultCancelLabel: 'Não',
                              primaryClassName: 'btn-danger '
                            })
                            this.dialog.show({
                              body: 'Você tem certeza que deseja deletar??',
                              actions: [
                                Dialog.CancelAction(() => { }),
                                Dialog.OKAction(() => {
                                  this.props.dados.requests.delete(this.props.dados.requests.type, props.original[this.props.dados.id]).then(result => {
                                    Dialog.resetOptions();
                                    if (result.ok) {
                                      this.dialog.showAlert(this.props.dados.tipo_cadastro + ' deletado com sucesso!')
                                      this.getList();
                                    } else {
                                      this.dialog.showAlert('Não pode deletar! Existem ' + this.props.dados.tipo_plural + ' cadastradas com esse tipo!')
                                    }
                                  })
                                }
                                ),
                              ],
                              bsSize: 'small',
                              onHide: (dialog) => {
                                dialog.hide()
                              }
                            })
                          }.bind(this)}></Image>
                        }
                      </div>
                  }
                ]
              },
            ]}
            defaultPageSize={5}
            className="-striped -highlight"
          />
        </Col>
        <Dialog ref={(el) => { this.dialog = el }} />
        <Modal
          keyboard
          show={this.state.show}
          onHide={this.modalHide.bind(this)}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header>
            {this.state.new && <Modal.Title>Criar {this.props.dados.tipo_cadastro}</Modal.Title>}

            {this.state.edit && <Modal.Title>Editar {this.props.dados.tipo_cadastro}</Modal.Title>}

          </Modal.Header>
          <Modal.Body>
            <div>
              <Form
                onSubmit={e => this.submit(e)}
                onKeyPress={e => {
                  if (e.which === 13) {
                    e.preventDefault()
                    return false
                  }
                }}>
                {this.renderFields(this.props.dados)}
                {this.state.new &&
                  this.props.dados.botoes.map((m, i) => {
                    return (
                      <Button type="submit" key={i}>{m.label}</Button>
                    );
                  })
                }
                {this.state.edit &&
                  <div>
                    {this.props.dados.requests.edit !== undefined &&
                    <Button type="submit">Salvar</Button>}
                    <Button onClick={this.modalHide.bind(this)}>Cancelar</Button>
                  </div>
                }
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          keyboard
          show={this.state.showModulos}
          onHide={this.modulosHide.bind(this)}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header>
            <Modal.Title>Adicionar Módulos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Módulo</ControlLabel>
              <Select2
                ref='modulos_type'
                value={this.state.moduloSelected}
                data={this.state.modulos}
                onSelect={e => {
                  this.setState({ moduloSelected: this.getSelectedOptionsValue(e.target.selectedOptions) })
                  this.state.allModulos.map((m, i) => {
                    if (m.id === this.state.moduloSelected[0]) {
                      this.setState({ moduloSelectedData: m })
                      return null;
                    }
                    return null;
                  })
                  this.setState({ modulosSelected: { id: this.state.moduloSelectedData.id, name: this.state.moduloSelectedData.nome } })
                }}
              />
            </FormGroup>
            {this.state.moduloSelectedData && this.state.moduloSelectedData.actions &&
              <FormGroup>
                <ControlLabel>Ações</ControlLabel>
                {this.state.moduloSelectedData.actions.map((m, i) => {
                  return (
                    <Checkbox
                      key={i}
                      style={{ margin: '0' }}
                      checked={this.state.actionsCheck[m.nome]}
                      onChange={e => {
                        this.setState({ actionsCheck: { ...this.state.actionsCheck, [m.nome]: e.target.checked } })
                        if (e.target.checked) {
                          if (!this.state.modulosSelected.actions) {
                            this.setState({ modulosSelected: { ...this.state.modulosSelected, actions: [m.nome] } })
                          }
                          else {
                            this.setState({ modulosSelected: { ...this.state.modulosSelected, actions: [...this.state.modulosSelected.actions, m.nome] } })
                          }
                        } else {
                          var array = [...this.state.modulosSelected.actions];
                          var index = array.indexOf(e.target.value)
                          array.splice(index, 1);
                          this.setState({ modulosSelected: { ...this.state.modulosSelected, actions: array } });
                        }
                      }}>
                      <b>{m.label}</b>
                    </Checkbox>
                  );
                })
                }
              </FormGroup>
            }
            <Button onClick={() => {
              if (this.state.modulosSelected.id) {
                this.setState({ modulosTable: [...this.state.modulosTable, this.state.modulosSelected] })
                this.modulosHide();
              } else {
                Dialog.resetOptions();
                this.dialog.showAlert('Por favor selecione um módulo!')
              }
            }}>Adicionar Módulo</Button>
          </Modal.Body>
        </Modal>
      </div >
    );
  }
}

export default CadastroHelper;
