import React, { Component } from 'react';
import './Corridas.css';
import { CSSTransitionGroup } from 'react-transition-group'
import Push from 'push.js';
import NewSacPiloto from './NewSacPiloto.js';
import WS from '../services/webservice';

class SacNotigicationPiloto extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pil_mensagensTotal: [],
      pil_mensagensAtivas: [],
      pil_m:{},
      pil_at: '',
      pil_aberto: false,
      pil_central: '',
      pil_cpf: '',
      pil_rt: '',
      pil_uuid: '',
    };

    this.pil_mensagensAtivas = this.pil_mensagensAtivas.bind(this);
    this.pil_addMensagem = this.pil_addMensagem.bind(this);
    this.pil_msgaberto = this.pil_msgaberto.bind(this);

    this.webservice = new WS();
  }


  componentDidMount() {
    this.pil_timerId = setInterval(this.pil_mensagensAtivas, 15000);
    //console.log("FUI CHAMADO FIAO")
    this.pil_mensagensAtivas();
  }

  componentWillUnmount() {
    clearInterval(this.pil_timerId);
  }

  pil_addMensagem(pil_m) {


  }

  pil_msgaberto(result) {
    console.log("result boys1", result)

    this.props.msgaberto(result)
  }

  pil_modalHide() {
    this.pil_mensagensAtivas();
    this.setState({
      pil_aberto: false
    })
  }

  pil_handleClick(pil_m) {
    this.pil_addMensagem(pil_m)
    console.log("MEU M MOTORISTA", pil_m)
    this.setState({
      pil_eme: pil_m,
      pil_nome: pil_m.nome,
      pil_central: pil_m.central,
      pil_telefone: pil_m.telefone,
      pil_placa: pil_m.placa,
      pil_email: pil_m.email,
      pil_uuid: pil_m.cpf,
      pil_aberto: true,
    })
  }

  async pil_mensagensAtivas() {
    try {
      console.log("sac 1", this.props.central)
      if (this.props.central.centralString) {
        console.log("sac 2", this.props.central.centralString)
        let result = await this.webservice.checksacpiloto(this.props.central.centralString.ids);
        console.log('checksac_pil',result)
        let msg = result.sac;

        let self = this;
        msg.map((pil_m, i) => {
          let obj = this.props.central.centrais.find((obj) => { return obj.id === Number(pil_m.central); });
          let nomecentral = obj.nomefantasia;
          Push.create("Nova Mensagem", {
            body: "nome: " + pil_m.nome + " - Placa " + pil_m.placa + " - Central: " + nomecentral,
            icon: require('../images/motorista.png'),
            timeout: 4000,
            onClick: function () {
              window.focus();
              this.close();
              self.pil_handleClick(pil_m);
            }
          });
          return null;
        })
        console.log("Mensagens total sac", this.state.pil_mensagensTotal)
        console.log("mensagens ativas sac", this.state.pil_mensagensAtivas)
        this.setState({
          pil_mensagens: msg,
          pil_mensagensTotal: msg
        });

      }
      //console.log("SOU O CPF", this.state.pil_mensagens)
      this.setState({ error: null, loaded: true });

    } catch (err) {
      //console.log("ERROR", err)
      this.setState({ loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }


  render() {
    return (
      <div style={{ display: 'inline-block', padding: 5, backgroundColor:'red' }} >
        <CSSTransitionGroup
          transitionName="SacPiloto"
          component="tbody"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}>
          <div style={{ position: 'fixed', bottom: '50px' }}>
            {
              this.state.pil_mensagensTotal.map(pil_m => {
                //console.log("NOVA MESSAGI", pil_m)
                return (
                  //<tr key={pil_m.pil_cpf} onClick={() => this.handleAtual(pil_m, pil_m.pil_central ? pil_m.pil_central : pil_m.central_id)}>
                  //<td>Nova Mensagem - {pil_m.pil_at} - {pil_m.pil_rt} - {this.mostraCentral(pil_m)}</td>
                  
                  <div style={{ display: 'inline-block', padding: 5 }} onClick={()=>this.pil_handleClick(pil_m)} >
                    {!this.state.pil_aberto && pil_m && <NewSacPiloto
                      modalHide={this.pil_modalHide.bind(this)}
                      centrais={this.props.central}
                      sendmsg={this.pil_addMensagem}
                      msgaberto={this.pil_msgaberto.bind(this)}
                      eme={pil_m}
                      nome={pil_m.nome}
                      telefone={pil_m.telefone}
                      placa={pil_m.placa}
                      email={pil_m.email}
                      uuid={ pil_m.cpf}
                      central={ Number(pil_m.central ? pil_m.central : pil_m.central_id)}
                       />}
                  </div>

                  //</tr>
                )
              })
            }
            {this.state.pil_aberto && <div style={{ display: 'inline-block', padding: 5 }} >
              <NewSacPiloto
                modalHide={this.pil_modalHide.bind(this)}
                aberto={this.state.pil_aberto}
                centrais={this.props.central}
                sendmsg={this.pil_addMensagem.bind(this)}
                msgaberto={this.pil_msgaberto.bind(this)}
                eme={this.state.pil_eme}
                nome={this.state.pil_nome}
                placa={this.state.pil_placa}
                telefone={this.state.pil_telefone}
                email={this.state.pil_email}
                uuid={this.state.pil_uuid}
                central={this.state.pil_central}/>
            </div>}
          </div>

        </CSSTransitionGroup>
      </div>
    )
  }
}
export default SacNotigicationPiloto;
