import { CognitoUserPool } from 'amazon-cognito-identity-js'
const { versao, url } = require('../go-versao.js')

const serverUrlbase = url
//const serverUrlbase = "http://localhost:5555"
//const serverUrlbase = "http://paluchgo.duckdns.org:5000"

const serverUrl = serverUrlbase + '/api/'
export default class WS {
  status(uuid) {
    return this.callEndpointWithPayload('status-cliente', {
      uuidClient: uuid
    })
  }

  async getPonto(numero) {
    let valor
    valor = await this.fetch2('motoristasnoponto?referencia=' + numero)
    let valor2 = await valor.json()
    return valor2
  }

  async getCancel() {
    let valor
    valor = await this.fetch2('tipo_cancelamento')
    let valor2 = await valor.json()
    let retorno = []

    for (var c in valor2) {
      let a = valor2[c]
      if (a.ativo) {
        retorno.push({
          descricao: a.descricao,
          id: a.id
        })
      }
    }
    return retorno
  }
  async efetivaprecadastros(id) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2('efetivaprecadastros', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify({
        id: id
      })
    })

    return result
  }
  async efetivaprecadastrosNew(id) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2('efetiva_precadastros_new', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify({
        id: id
      })
    })

    return result
  }

  async ps_efetuaPagamento(id) {
    console.log('ps_efetuaPagamento', id)
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2('gateway/paypix', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify({
        token: id
      })
    })
    console.log(result)
    return result
  }

  async abl_efetuaPagamento(id) {
    console.log('abl_efetuaPagamento', id)
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2('paymentsAceita', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify({
        id: id
      })
    })
    console.log(result)
    return result
  }

  async restauraprecadastro(id) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2('pre_cadastro_backup_recupera', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify({
        id: id
      })
    })

    return result
  }
  async efetivaprecadastrosempresa(id) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2('efetivaprecadastroempresas', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify({
        id: id
      })
    })

    return result
  }

  cancelTaxi(id, atendente, central, motivo, id_cancel) {
    console.log('wtf men  ')
    return this.callEndpointWithPayload(
      'cancelar-taxi',
      {
        idCorrida: id,
        atendente: atendente,
        tipocancelamento: 'C',
        tipocancelamento_old: 'M',
        obscancelamento: motivo,
        idcancelamento: id_cancel
      },
      central
    )
  }

  qrlOp(id2, user) {
    let centralID = this.getCookie('central-id')
    let result = this.fetch2('corrida/qrl', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify({
        id: id2,
        operador: user
      })
    })

    return result
  }
  async FinalizaSac(uuid, centralid) {
    console.log('GetSac', [uuid, centralid])
    let result = await this.fetch2(
      'sac/client/finaliza/3/' + uuid,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralid
        }
      },
      centralid
    )
    return await result.json()
  }
  async FinalizaSacPiloto(uuid, centralid) {
    console.log('GetSac', [uuid, centralid])
    let result = await this.fetch2(
      'sac/pilot/finaliza/3/' + uuid,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralid
        }
      },
      centralid
    )
    return await result.json()
  }
  async getListaFaturas(id, tipo) {
    console.log('getListaFaturas', [id, tipo])
    let centralid = this.getCookie('central-id')
    let result = await this.fetch2(
      'voucher_fatura_' + tipo + '/?voucher_faturamento=' + id,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralid
        }
      },
      centralid
    )
    return await result.json()
  }
  async GetSac(uuid, centralid) {
    console.log('GetSac', [uuid, centralid])
    let result = await this.fetch2(
      'sac/cliente/3/' + uuid,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralid
        }
      },
      centralid
    )
    return await result.json()
  }
  async GetSacPiloto(uuid, centralid) {
    console.log('GetSac', [uuid, centralid])
    let result = await this.fetch2(
      'sac/piloto/3/' + uuid,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralid
        }
      },
      centralid
    )
    return await result.json()
  }
  async postbanco(itens) {
    ///sac/client/checkcentral/
    let centralID = this.getCookie('central-id')
    let dados = {
      cpf: itens.cpf,
      banco: itens.t08_motoristas_banco,
      tipoconta: itens.t08_motoristas_tipoconta,
      numeroagencia: itens.t08_motoristas_numeroagencia,
      numeroconta: itens.t08_motoristas_numeroconta
    }
    let result = await this.fetch2(
      'motoristacentralbanco',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralID
        },
        body: JSON.stringify(dados)
      },
      centralID
    )
    return await result.json()
  }

  async reloadFromIugu() {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2(
      'iugu_loadmarketplace',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralID
        },
        body: JSON.stringify({ id: 'id' })
      },
      centralID
    )
    if (result.ok) {
      return true
    } else {
      return false
    }
  }
 
  async sendSolicitarEmail(email) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2(
      'gateway/authsms/',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralID
        },
        body: JSON.stringify({ email: email, central: centralID })
      },
      centralID
    )
    if (result.ok) {
      return true
    } else {
      return false
    }
  }

  async sendConfirmarEmail(email, code) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2(
      'gateway/auth_token_sms/',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralID
        },
        body: JSON.stringify({ email: email, central: centralID, sms: code })
      },
      centralID
    )
    if (result.ok) {
      return true
    } else {
      return false
    }
  }

  async createIugu(id, nome) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2(
      'iugu/createacc',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralID
        },
        body: JSON.stringify({ central: id, nome: nome })
      },
      centralID
    )
    if (result.ok) {
      return true
    } else {
      return false
    }
  }

  async resetpassportal(id) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2(
      'voucher_empresa/password_reset/' + id,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralID
        },
        body: JSON.stringify({ id: id })
      },
      centralID
    )
    if (result.ok) {
      return true
    } else {
      return false
    }
  }

  async postExtras(metodo, id, itens) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2(
      metodo + '/' + id,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralID
        },
        body: JSON.stringify(itens)
      },
      centralID
    )
    return await result.json()
  }
  async postItens(itens) {
    ///sac/client/checkcentral/
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2(
      'motorista_central_tipos_itens',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralID
        },
        body: JSON.stringify({ itens: itens })
      },
      centralID
    )
    return await result.json()
  }

  async postfaturacentral(detalhes) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2(
      'afaturar_central/',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralID
        },
        body: JSON.stringify(detalhes)
      },
      centralID
    )
    return await result.json()
  }
  async postfatura(detalhes) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2(
      'voucher_afaturar/',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralID
        },
        body: JSON.stringify(detalhes)
      },
      centralID
    )
    return await result.json()
  }
  async postSac(uuid, msg, centralid) {
    ///sac/client/checkcentral/
    let result = await this.fetch2(
      'sac/cliente/3/' + uuid,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralid
        },
        body: JSON.stringify({ msg: msg, nome: '', telefone: '', email: '' })
      },
      centralid
    )
    return await result.json()
  }
  async postSacPiloto(uuid, msg, centralid, todos) {
    if (!todos) {
      todos = 0
    }
    console.log('postSacPiloto sac/piloto', [uuid, msg, centralid])
    ///sac/client/checkcentral/
    let result = await this.fetch2(
      'sac/piloto/3/' + uuid,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': centralid
        },
        body: JSON.stringify({ msg: msg, todos: todos })
      },
      centralid
    )
    return await result.json()
  }
  async PagaMotoristas(centralid, Dados) {
    console.log('checPagaMotoristasksac', centralid)
    let result = await this.fetch2('motfinanceiro2/efetiva/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralid
      },
      body: JSON.stringify(Dados)
    })
    return await result.json()
  }
  async checksac(centralid) {
    ///sac/client/checkcentral/
    console.log('checksac', centralid)
    let result = await this.fetch2('sac/client/checkcentral/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralid
      },
      body: JSON.stringify({
        centrais: centralid
      })
    })
    return await result.json()
  }
  async forcesacpiloto(uuid, centralid) {
    ///sac/client/checkcentral/
    console.log('checksac', centralid)
    let result = await this.fetch2('sac/pilot/forcecentral/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralid
      },
      body: JSON.stringify({
        centrais: centralid,
        uuid: uuid
      })
    })
    return await result.json()
  }
  async checksacpiloto(centralid) {
    ///sac/client/checkcentral/
    console.log('checksac', centralid)
    let result = await this.fetch2('sac/pilot/checkcentral/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralid
      },
      body: JSON.stringify({
        centrais: centralid
      })
    })
    return await result.json()
  }
  descadastro(uuid, ref, centralid) {
    let result = this.fetch2('descadastramotorista', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralid
      },
      body: JSON.stringify({
        referencia: ref,
        motorista: uuid
      })
    })
    return result
  }

  qrvOp(id2, user) {
    let centralID = this.getCookie('central-id')
    let result = this.fetch2('motoristas/qrv', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify({
        id: id2,
        atendente: user
      })
    })

    return result
  }
  async gettermo(tipo) {
    let result = await this.fetch2(
      'termos/' + tipo,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-central-id': 18
        }
      },
      18
    )
    return await result.json()
  }
  avaOP(id, avaliacao_cliente, avaliacao_cliente_comentarios) {
    let centralID = this.getCookie('central-id')
    let result = this.fetch2('avaliacaocliente', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify({
        id: id,
        avaliacao_cliente: avaliacao_cliente,
        avaliacao_cliente_comentarios: avaliacao_cliente_comentarios
      })
    })

    return result
  }

  async calcula(origin, destination, destinos, promo, empresa) {
    let centralID = this.getCookie('central-id')

    let waypoints = []

    for (var m in destinos) {
      waypoints.push({
        latitude: destinos[m].latlon.lat,
        longitude: destinos[m].latlon.lng
      })
    }
    let data = {
      origin_lat: origin.latlon.lat,
      origin_lon: origin.latlon.lng,
      destin_lat: destination.latlon.lat,
      destin_lon: destination.latlon.lng
    }

    if (waypoints.length > 0) {
      data.waypoints = waypoints
    }
    data.promocode = promo
    data.empresa = Number(empresa)
    data.central = Number(centralID)
    //let response = data;
    console.log('newCalculaPrice data', data)
    let result = await this.fetch2('calculavalor', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify(data)
    })
    let response = await result.json()
    return response
  }

  reenviarTaxi(id2, atendente) {
    let centralID = this.getCookie('central-id')
    let result = this.fetch2('corrida/enviarOutro', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify({
        id: id2,
        tipocancelamento: 11,
        tipocancelamento_old: 'T',
        atendente: atendente
      })
    })

    return result
  }

  setCookie(name, value, days) {
    var expires = ''
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  }

  async getRandom(link) {
    let result = await fetch(serverUrlbase + '/t/' + link)
    result = await result.json()
    return result
  }
  async getMotRandom(link) {
    let result = await fetch(serverUrlbase + '/t/' + link + '/m')
    console.log('resposta', result)
    if (result.status !== 404) {
      result = await result.json()
    }
    return result
  }
  async postMotAvalRandom(link, nota, descricao) {
    let result = await fetch(serverUrlbase + '/t/' + link + '/r', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        avaliacao: nota,
        comentarios: {
          descricao: descricao
        }
      })
    })

    return result
  }
  getCookie(name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }

    return null
  }

  // ---------------- Queries Dashboards ----------------------------

  async getStatusCorridas() {
    try {
      const response = await this.fetch2(`getStatusCorridas`)
      const data = await response.json()
      return data
    } catch (error) {
      console.log('error', error)
      return error
    }
  }

  async getStatusCorridasMonth(selectMes) {
    try {
      const response = await this.fetch2(`getStatusCorridasMonth/${selectMes}`)
      const data = await response.json()
      return data
    } catch (error) {
      console.log('error', error)
      return null
    }
  }

  async getDriversDrives(dataSelecionada) {
    try {
      const response = await this.fetch2(`getDriversDrives/${dataSelecionada}`)
      const data = await response.json()
      return data
    } catch (error) {
      console.log('**error**', error)
      return null
    }
  }

  async getDriversInfos() {
    try {
      const response = await this.fetch2('getDriversInfos')
      if (!response.ok) {
        throw new Error(`Erro ao buscar dados: ${response.statusText}`)
      }
      let data = await response.json()
      console.log('**********RETORNO BACK', response)
      if (!data) throw new Error(`Nenhum dado encontrado`)
      let motoristas_inativos = 0
      let motoristas_ativos = 0

      motoristas_inativos = data.filter(
        (item) => item.status_motorista === 'INATIVO'
      ).length
      motoristas_ativos = data.filter(
        (item) => item.status_motorista === 'ATIVO'
      ).length

      return { motoristas_inativos, motoristas_ativos }
    } catch (error) {
      console.error(error)
      throw new Error(`Ocorreu um erro: ${error.message}`)
    }
  }

  async getQtdeFormaPagamento() {
    try {
      let response = await this.fetch2('getQtdeFormaPagamento')
      response = await response.json()
      console.log('***PAGAMENTO', response)
      return response
    } catch (error) {
      console.log('***error', error)
      return error
    }
  }

  async getTotalizadores() {
    try {
      let response = await this.fetch2('getTotalizadores')
      response = await response.json()
      return response
    } catch (error) {
      console.log('***error', error)
      return error
    }
  }

  async getTotalSolicitacoes() {
    try {
      let response = await this.fetch2('getTotalSolicitacoes')
      response = await response.json()

      console.log('***objSolicitacoes', response)
      return response
    } catch (error) {
      console.log('***error', error)
      return error
    }
  }

  async getTiposCorridas() {
    try {
      let response = await this.fetch2('getTiposCorridas')
      response = await response.json()
      console.log('***TIPOS CORRIDAS***', response)
      return response
    } catch (error) {
      console.log('***error', error)
      return error
    }
  }

  async getTotalizadores() {
    try {
      let response = await this.fetch2('getTotalizadores')
      response = await response.json()
      return response
    } catch (error) {
      console.log('***error', error)
      return error
    }
  }

  async getTiposCorridas() {
    try {
      let response = await this.fetch2('getTiposCorridas')
      response = await response.json()
      console.log('***PAGAMENTO', response)
      return response
    } catch (error) {
      console.log('***error', error)
      return error
    }
  }

  async getTotalizadores() {
    try {
      let response = await this.fetch2('getTotalizadores')
      response = await response.json()
      return response
    } catch (error) {
      console.log('***error', error)
      return error
    }
  }

  async getTotalSolicitacoes() {
    try {
      let response = await this.fetch2('getTotalSolicitacoes')
      response = await response.json()

      console.log('***objSolicitacoes', response)
      return response
    } catch (error) {
      console.log('***error', error)
      return error
    }
  }

  async getTiposCorridas() {
    try {
      let response = await this.fetch2('getTiposCorridas')
      response = await response.json()
      console.log('***TIPOS CORRIDAS***', response)
      return response
    } catch (error) {
      console.log('***error', error)
      return error
    }
  }

  async getDashboardInfos(selectStatus) {
    try {
      const response = await this.fetch2(`getDashboardInfos/${selectStatus}`)
      const responseJson = await response.json()
      return responseJson
    } catch (error) {
      return error
    }
  }

  // ---------------------------------------------

  eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;'
  }

  async changeStatus(tipo, id, status) {
    let result = await this.fetch2(
      'tipo_central/' + tipo + '/' + id + '/' + status
    )
    return result
  }
  async tipo_msg() {
    let result3 = await this.fetch2(
      'tipo_mensagem?m04_central=true&m04_ativo=true'
    )
    let res = await result3.json()
    return res
  }

  async fetch(extension) {
    let token = this.getCookie('token')

    let object = {
      headers: {
        'x-access-token': token
      }
    }

    let result = await fetch(serverUrl + extension, object)
    return result
  }

  async criaDepartamento(id, descricao, administrativo, inicial) {
    let result = await this.fetch2('departamento_reclamacao/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id,
        descricao: descricao,
        administrativo: administrativo,
        inicial: inicial
      })
    })
    return result
  }

  async getDepartamento() {
    let result = await this.fetch2('departamento_reclamacao')
    let resultjson = await result.json()
    return resultjson
  }
  async GetCidade() {
    let result = await this.fetch2('cidadeatuacao')
    let resultjson = await result.json()
    return resultjson
  }
  async editDepartamento(id, descricao, administrativo, inicial) {
    let result = await this.fetch2('departamento_reclamacao/' + id, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id,
        descricao: descricao,
        administrativo: administrativo,
        inicial: inicial
      })
    })

    return result
  }

  async deleteDepartamento(id) {
    let result = await this.fetch2('departamento_reclamacao/' + id, {
      method: 'DELETE'
    })

    return result
  }

  async criaStatus(id, descricao, next) {
    let result = await this.fetch2('status_reclamacao', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id,
        descricao: descricao,
        next: next
      })
    })

    return result
  }

  async getStatus() {
    let result = await this.fetch2('status_reclamacao')
    let resultjson = await result.json()
    return resultjson
  }

  async getDespacho() {
    let result = await this.fetch2('despachoparametros')
    let resultjson = await result.json()
    return resultjson
  }

  async saveDespacho(
    MAXD,
    MAXDL,
    MMS1,
    MMS2,
    MINDL,
    TC,
    TECP,
    TECP2,
    TECP3,
    TTSB
  ) {
    let result = await this.fetch2('despachoparametros', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        parametros: {
          MAXIMO_DISTANCIA: Number(MAXD),
          MAXIMO_DISTANCIA_LISTA: Number(MAXDL),
          MAX_MOTORISTA_STEP1: Number(MMS1),
          MAX_MOTORISTA_STEP2: Number(MMS2),
          MINIMO_DISTANCIA_LISTA: Number(MINDL),
          TEMPO_CASTIGO: Number(TC),
          TEMPO_EXPIRA_CORRIDA_PENDENTE: Number(TECP),
          TEMPO_EXPIRA_CORRIDA_PENDENTE2: Number(TECP2),
          TEMPO_EXPIRA_CORRIDA_PENDENTE3: Number(TECP3),
          TIME_TO_SEND_BACK: Number(TTSB)
        }
      })
    })

    return result
  }

  async editStatus(id, descricao, next) {
    let result = await this.fetch2('status_reclamacao/' + id, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id,
        descricao: descricao,
        next: next
      })
    })

    return result
  }

  async deleteStatus(id) {
    let result = await this.fetch2('status_reclamacao/' + id, {
      method: 'DELETE'
    })

    return result
  }

  async criaNivel(id, descricao) {
    let result = await this.fetch2('nivel_reclamacao', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id,
        descricao: descricao
      })
    })

    return result
  }

  async getNivel() {
    let result = await this.fetch2('nivel_reclamacao')
    let resultjson = await result.json()
    return resultjson
  }

  async editNivel(id, descricao) {
    let result = await this.fetch2('nivel_reclamacao' + id, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id,
        descricao: descricao
      })
    })

    return result
  }

  async deleteNivel(id) {
    let result = await this.fetch2('nivel_reclamacao/' + id, {
      method: 'DELETE'
    })

    return result
  }

  async newmsg(central, extension, object = { headers: {} }) {
    console.log('item-1', extension)
    console.log('item-1', central)
    let token = this.getCookie('token')
    if (extension === 'central') {
      object.headers = {
        ...object.headers,
        'x-access-token': token,
        'x-central-id': 1
      }
    } else {
      let centralID = central
      if (centralID && centralID.indexOf(',') > -1) {
        centralID = centralID.split(',')[0]
        this.setCookie('central-id', centralID, 1)
      }
      console.log('item-2', centralID)
      object.headers = {
        ...object.headers,
        'x-access-token': token,
        'x-central-id': centralID
      }
    }
    console.log('item-3', object)
    let result = await fetch(serverUrl + extension, object)

    console.log('item-4', result)

    return result
  }

  async fetch2(extension, object = { headers: {} }, central = null) {
    let token = this.getCookie('token')
    let centralID = this.getCookie('central-id')
    if (centralID && centralID.indexOf(',') > -1) {
      centralID = centralID.split(',')[0]
      this.setCookie('central-id', centralID, 1)
    }
    if (central) {
      centralID = central !== null ? central : centralID
    }
    console.log(serverUrl + extension, ['object', object])
    object.headers = {
      ...object.headers,
      'x-access-token': token,
      'x-central-id': centralID
    }

    let result = await fetch(serverUrl + extension, object)

    console.log(object.method + ' ' + serverUrl + extension, [object, result])

    return result
  }

  async fetchPortal(extension, object = { headers: {} }, empresaID) {
    let token = this.getCookie('token')
    let centralID = this.getCookie('central-id')
    if (centralID && centralID.indexOf(',') > -1) {
      centralID = centralID.split(',')[0]
      this.setCookie('central-id', centralID, 1)
    }
    console.log('centralID', centralID)
    object.headers = {
      ...object.headers,
      'x-access-token': token,
      'x-central-id': centralID,
      'x-empresa-id': empresaID
    }

    let result = await fetch(serverUrl + extension, object)

    console.log(result)

    return result
  }

  async getEmpresas(id = '') {
    let result = await this.fetch2('voucher_empresa/' + id)
    result = result.json()

    return result
  }

  async getEmpresaByName(name) {
    let result = await this.fetch2('voucher_empresa?razaosocial=' + name)
    result = result.json()

    return result
  }

  async getFuncsByName(name, empresaID) {
    let result = await this.fetch2(
      'voucher_empresa/' +
        empresaID +
        '/funcionario?voucher_usuarios.nome={"eq":"' +
        name +
        '"}'
    )
    result = result.json()

    return result
  }

  async getFuncByUserIdAndEmpresaId(user, empresa) {
    let result = await this.fetch2(
      'voucher_empresa/' + empresa + '/funcionario?usuario=' + user
    )
    result = result.json()

    return result
  }

  async getUserByCPF(cpf) {
    let result = await this.fetch2('voucher_usuarios?cpf=' + cpf)
    result = result.json()

    return result
  }

  async getUser(id) {
    let result = await this.fetch2('voucher_usuarios/' + id)
    result = result.json()

    return result
  }

  async getVoucherByCPF(cpf) {
    let result = await this.fetch2('voucher_avulso?cpf=' + cpf)
    result = result.json()

    return result
  }

  async getVoucherByID(id) {
    let result = await this.fetch2('voucher_avulso?id=' + id)
    result = result.json()

    return result
  }

  async getFuncionario(idEmpresa, matricula = '') {
    let result
    if (matricula === '') {
      result = await this.fetch2(
        'voucher_empresa/' + idEmpresa + '/funcionario'
      )
    } else {
      result = await this.fetch2(
        'voucher_empresa/' +
          idEmpresa +
          '/funcionario?matricula={"eq": ' +
          matricula +
          '}'
      )
    }
    result = result.json()

    return result
  }

  async getCentrais() {
    let result = await this.fetch2('central/?per_page=200&order=nomefantasia&ativo=true')
    result = result.json()

    return result
  }

  async sendAutocomplete( rua, numero, bairro, cidade, estado) {
    let result = await this.fetch2(
       'newautocomplete',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "busca": rua + ", " + numero + ", " + cidade + " " + estado
        })
    })
    if (result) {
      result = await result.json()
      return result
    } else {
      return []
    }
  }

  async getCentral(id) {
    let result = await this.fetch2('central/' + id)
    result = result.json()

    return result
  }

  async addCentral(userID, centralID, niveisAcesso, ativo) {
    let result = await this.fetch2(
      'usuario/' + userID + '/add_central/' + centralID,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          niveisacesso: niveisAcesso,
          ativo: ativo
        })
      }
    )

    return result
  }

  async deleteCentral(userID, centralID) {
    let result = await this.fetch2(
      'usuario/' + userID + '/delete_central/' + centralID,
      { method: 'DELETE' }
    )

    return result
  }

  async atualizarCentral(userID, centralID, niveisAcesso, ativo) {
    let result = await this.fetch2(
      'usuario/' + userID + '/update_central/' + centralID,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          niveisacesso: niveisAcesso,
          ativo: ativo
        })
      }
    )

    return result
  }

  async atualizarCentral2(userID, centralID, departamentos) {
    let result = await this.fetch2(
      'usuario/' + userID + '/update_central_departamento/' + centralID,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          departamentos: departamentos,
          ativo: true
        })
      }
    )

    return result
  }

  async setCentralRules(rules) {
    let centralID = this.getCookie('central-id')
    let result = await this.fetch2('central/' + centralID, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        transbordo: {
          regras: rules
        }
      })
    })
    return result
  }

  async corridaByID(id) {
    let result = await this.fetch2('corrida/' + id)
    var corrida = await result.json()

    return corrida
  }

  async getMotoristas(busca) {
    let result = await this.fetch2('motoristas/search' + busca)
    var motorista = await result.json(result)

    return motorista
  }

  async getRamal(ramal) {
    let result = {}
    // result = await fetch('http://192.168.1.241/dgtelip/webservice/connectedline?oauth=e22462fefac11dcc6560bf0e759b5144&ramal=' + ramal)
    //  result = await fetch('http://187.32.149.178:8000/dgtelip/webservice/connectedline?oauth=e22462fefac11dcc6560bf0e759b5144&ramal=' + ramal)

    //result = result.json();

    return result
  }

  orderTaxi(orderData) {
    let centralID = this.getCookie('central-id')
    let contato = orderData.telefone_contato.toString()

    const number = contato.toString().replace(/\D/g, '')
    if (number.length <= 0 || !orderData.telefone_contato) {
      contato = orderData.telefone.toString()
    }

    return this.callEndpointWithPayload(
      'pedir-taxi',
      {
        destinos: orderData.destinos,
        moip: orderData.moip,
        voucher_avulso: orderData.voucherAvulso,
        empresa: orderData.codigo_empresa,
        voucher_funcionario: orderData.matricula,
        departamento_voucher: orderData.departamento,
        telefone: orderData.telefone.toString(),
        nome: orderData.nome,
        endereco: orderData.endereco,
        numero: orderData.numero,
        complemento: orderData.complemento,
        cidade: orderData.cidade,
        estado: orderData.estado,
        bairro: orderData.bairro,
        latitude: orderData.latitude,
        longitude: orderData.longitude,
        enderecoDestino:
          orderData.enderecoDestino + ', ' + orderData.numeroDestino,
        complementodestino: orderData.complementoDest,
        cidadedestino: orderData.cidadeDestino,
        estadodestino: orderData.estadoDestino,
        bairrodestino: orderData.bairroDestino,
        latitudeDestino: orderData.latitudeDestino,
        longitudeDestino: orderData.longitudeDestino,
        tipo_pagto: orderData.tipo_pagto,
        tipo_carro: orderData.tipo_carro,
        tipo_cliente: orderData.tipo_cliente,
        tipo_corrida: orderData.tipo_corrida,
        telefone_contato: contato,
        data_hora_agendamento: orderData.data_hora_agendamento,
        observacoes: orderData.observacoes,
        coordenadas: orderData.coordenadas,
        numero_carros: orderData.numero_carros,
        atendente: orderData.atendente,
        id: orderData.id,
        tabelavalores: orderData.tabelavalores,
        valorcalc: orderData.valorTabelada,
        antecipar: orderData.antecipar,
        desconto: orderData.desconto
      },
      centralID
    )
  }
  //cadastrar_cliente: orderData.cadastrarCliente,
  async getClient(phone) {
    let response = []
    //let response = await this.fetch2('cliente/' + phone);
    //response = await response.json();

    return response
  }

  async getOptions(filter, sigla = false) {
    let response = await this.fetch2('codigos?filter=' + filter)
    response = await response.json()

    console.log(filter, response)

    let result

    if (sigla) {
      result = response.map((option) => {
        return {
          id: option.sigla,
          text: option.sigla
        }
      })
    } else {
      result = response.map((option) => {
        return {
          id: option.codigo,
          text: option.descricao,
          tipo_voucher: option.tipo_voucher,
          iugu: option.iugu ? option.iugu : null,
          sigla: option.sigla
        }
      })
    }

    return result
  }

  async getAgendamentos(dataHoraFim) {
    let response = await this.fetch2(
      'corrida/agendamento?dataHoraFim=' + dataHoraFim
    )
    response = await response.json()

    return response
  }

  async runSearch(dataInicio, dataFim, type, value) {
    if (type === 'moto') {
      type = 'at'
    }
    if (type === 'motorista' && value.match('[0-9]+')) {
      type = 'at'
    }

    let response = await this.fetch2(
      'corrida/historico?dataHoraInicio=' +
        dataInicio +
        '&dataHoraFim=' +
        dataFim +
        '&' +
        type +
        '=' +
        value
    )
    response = await response.json()

    return response
  }

  async getCorridasAntigas(telefone) {
    let response = await this.fetch2('corrida/search?telefone=' + telefone)
    response = await response.json()

    return response
  }

  async atualizarCadastro(
    nome,
    endereco,
    numero,
    complemento,
    estado,
    bairro,
    cidade,
    latitude,
    longitude,
    telefone
  ) {
    let result = await this.fetch2('cliente', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cliente: nome,
        endereco: endereco,
        numero: numero,
        complemento: complemento,
        bairro: bairro,
        estado: estado,
        cidade: cidade,
        latitude: latitude,
        longitude: longitude,
        telefone: telefone
      })
    })
    return result
  }

  driversAround(latitude, longitude) {
    let centralID = this.getCookie('central-id')
    return this.callEndpointWithPayload(
      'motoristas-proximos',
      {
        latitude: latitude,
        longitude: longitude
      },
      centralID
    )
  }

  callEndpointWithPayload(method, parameters, central) {
    let payload = {
      method: method,
      parameters: parameters
    }

    let token = this.getCookie('token')
    let centralID = this.getCookie('central-id')
    if (centralID && centralID.indexOf(',') > -1) {
      centralID = centralID.split(',')[0]
      this.setCookie('central-id', centralID, 1)
    }

    var newURL = serverUrl
    newURL = newURL.replace('/api/', '')

    return fetch(newURL + '/client', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-central-id': central
      },
      body: JSON.stringify(payload)
    }).then((response) => response.json())
  }

  despacho(id, uuid, central) {
    let centralID = central
    console.log('CENTRAL AIDI', centralID)
    let result = this.newmsg(centralID, 'corrida/' + id + '/despacho', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': centralID
      },
      body: JSON.stringify({
        corrida: id,
        motorista: uuid
      })
    })

    return result
  }
  async pagamento(original) {
    let result = this.newmsg(original.central_id, 'pagamento', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-central-id': original.central_id
      },
      body: JSON.stringify({
        motorista: original.uuid333
      })
    })

    return result
  }

  //Get the routes from origin to destiny
  async directions(originLat, originLng, destLat, destLng) {
    let response = await this.fetch2(
      'directions?originLat=' +
        originLat +
        '&originLng=' +
        originLng +
        '&destLat=' +
        destLat +
        '&destLng=' +
        destLng
    )
    response = await response.json()

    return response
  }

  //Get the routes from origin to destiny with waypoints
  async directionsWaypoints(originLat, originLng, destLat, destLng, waypoints) {
    console.log(waypoints)
    let str = waypoints.map((m) => {
      return m.latitude.toString() + ',' + m.longitude.toString()
    })
    let points = str.join('|')

    let response = await this.fetch2(
      'directionswaypoint?originLat=' +
        originLat +
        '&originLng=' +
        originLng +
        '&destLat=' +
        destLat +
        '&destLng=' +
        destLng +
        '&waypoints=' +
        points
    )
    response = await response.json()

    return response
  }

  // Get the address coordinate
  async place(placeid) {
    let response = await this.fetch2('place?place=' + placeid)
    response = await response.json()
    return response
  }
  async autocomplete(address, coords) {
    let response = await this.fetch2(
      'autocomplete?latitude=' +
        coords.lat +
        '&longitude=' +
        coords.lng +
        '&address=' +
        address
    )
    response = await response.json()
    return response
  }

  async login(email, password) {
    let result = await this.fetch2('login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        senha: password
      })
    })

    return result
  }

  async getModulos() {
    let result = await this.fetch2('modulos')

    return result
  }

  async getUserAcessos() {
    let result = await this.fetch2('usuario/acessos').then((response) => {
      return response.json()
    })
    console.log('****RESULT', result)
  }

  async createUser(email, senha, cpf, nome, telefone, id) {
    let result = await this.fetchPortal(
      'voucher_usuarios',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          username: email,
          password: senha,
          telefone: telefone,
          cpf: cpf,
          nome: nome
        })
      },
      id
    )

    return result
  }

  async createFunc(telefone, userID, empresaID) {
    let result = await this.fetch2(
      'voucher_empresa/' + empresaID + '/funcionario',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          status: '1',
          calendar: null,
          departamento: '1',
          matricula: '99',
          role: '1',
          usuario: userID,
          telefone: telefone,
          valorlimit: null
        })
      }
    )

    return result
  }

  async criarTipo(tipo, tipo_object) {
    let result
    if (tipo === 'voucher_empresa') {
      if (
        tipo_object.email &&
        tipo_object.senha &&
        tipo_object.cpf &&
        tipo_object.nome
      ) {
        result = await this.fetch2(tipo + '/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...tipo_object
          })
        })

        let empresa = await result.json()
        console.log('EMPRESA', empresa)

        let createUser = await this.createUser(
          tipo_object.email,
          tipo_object.senha,
          tipo_object.cpf,
          tipo_object.nome,
          tipo_object.telefone,
          empresa[0].id
        )

        let user = await createUser.json()
        console.log('USUARIO', user)

        let createFuncionario = await this.createFunc(
          tipo_object.telefone,
          user[0].id,
          empresa[0].id
        )

        let funcionario = await createFuncionario.json()
        console.log('FUNCIONARIO', funcionario)
      } else {
        return { ok: false }
      }
    } else {
      result = await this.fetch2(tipo + '/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...tipo_object
        })
      })
    }

    return result
  }

  async getPlaca(placa) {

    let result = await this.fetch2('precadastros_newcarros/' + placa, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    result = await result.json()

    return result
  }

  async getSubTipo(tipo, filtered, order) {
    if (!filtered) {
      filtered = []
    }
    let filtros = filtered.map((m, i) => m.id + '=' + m.value).join('&')
    if (order) {
      order = '&order=' + order
    } else {
      order = ''
    }
    let pageSize = 200
    let response = await this.fetch2(
      tipo + '?per_page=' + pageSize + '&' + filtros + order
    )
    return response
  }
  async getTipo(tipo, id_tipo, sorted, page, pageSize, filtered, campos) {
    let response
    console.log('***filtered', sorted)
    let filtros = filtered
      .map((m, i) => {
        if (!campos) {
          return m.id + '=' + m.value
        } else {
          var obj = campos.find(function (obj) {
            return obj.ref === m.id
          })
          //console.log('obj',obj)
          if (obj.filter) {
            return obj.filter + '=' + m.value
          } else {
            return m.id + '=' + m.value
          }
        }
      })
      .join('&')
    if (id_tipo !== '') {
      response = await this.fetch2(tipo + '/' + id_tipo)
    } else if (sorted.length > 0) {
      let order = sorted.map((m, i) => (m.desc ? '-' : '') + m.id).join(',')
      response = await this.fetch2(
        tipo +
          '?per_page=' +
          pageSize +
          '&page=' +
          page +
          '&order=' +
          order +
          '&' +
          filtros
      )
    } else {
      response = await this.fetch2(
        tipo + '?per_page=' + pageSize + '&page=' + page + '&' + filtros
      )
    }

    return response
  }

  async getTipo2(tipo, id_tipo) {
    let response = await this.fetch2(tipo + '/' + id_tipo)
    if (response) {
      response = await response.json()
      return response
    } else {
      return {}
    }
  }

  async deleteTipo(tipo, id_tipo) {
    let result = await this.fetch2(tipo + '/' + id_tipo, { method: 'DELETE' })

    return result
  }
  async alterarTipoUUID(tipo, id_tipo, tipo_object, campos) {
    tipo_object.uuid = tipo_object.uuid333
    tipo_object.cpf = id_tipo
    let result = await this.fetch2('updateuuid/', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...tipo_object
      })
    })

    return result
  }
  async alterarTipo(tipo, id_tipo, tipo_object, campos) {
    console.log(tipo_object)
    let tipo_objectok = {}

    Object.keys(tipo_object).forEach(function (key) {
      // do something with obj[key]
      //
      var obj = campos.find(function (obj) {
        return obj.ref === key
      })
      if (!obj) {
        tipo_objectok[key] = tipo_object[key]
      } else if (obj.ignoresave) {
      } else if (obj.type === 'foto64') {
      } else {
        tipo_objectok[key] = tipo_object[key]
      }
    })
    let result = await this.sendalterarTipo(tipo, id_tipo, tipo_objectok)
    Object.keys(tipo_object).forEach(async (key) => {
      var obj = campos.find((obj) => {
        return obj.ref === key
      })
      if (obj.type === 'foto64') {
        let temp = {}
        temp[key] = tipo_object[key]
        await this.sendalterarTipo(tipo, id_tipo, temp)
      }
    })

    return result
  }

  async sendalterarTipo(tipo, id_tipo, tipo_objectok) {
    let result = await this.fetch2(
      tipo + '/' + id_tipo,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...tipo_objectok
        })
      },
      tipo_objectok.central ? tipo_objectok.central : null
    )
    return result
  }
  async addIP(ip) {
    let result = await this.fetch2('blacklist/' + ip, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    return result
  }

  async delIP(ip) {
    let result = await this.fetch2('blacklist/' + ip, { method: 'DELETE' })

    return result
  }

  async criarReclamacao(
    id_corrida,
    reclamacao,
    id_tipo_reclamacao,
    motorista_cpf,
    dados
  ) {
    let result = await this.fetch2('reclamacao/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        descricao: reclamacao,
        corrida_id: id_corrida,
        tiporeclamacao_id: id_tipo_reclamacao,
        motorista_cpf: motorista_cpf,
        nivel: dados.nivel,
        status: dados.status,
        departamento: dados.departamento
      })
    })

    return result
  }

  async getReclamacoes(dataInicio, dataFim, sorted, page, pageSize, filtered) {
    let response

    let filtros = filtered.map((m, i) => m.id + '=' + m.value).join('&')
    if (sorted.length > 0) {
      let order = sorted.map((m, i) => (m.desc ? '-' : '') + m.id).join(',')
      response = await this.fetch2(
        'reclamacao?datahora={"gte":"' +
          dataInicio +
          '","lte":"' +
          dataFim +
          '"}&per_page=' +
          pageSize +
          '&page=' +
          page +
          '&order=' +
          order +
          '&' +
          filtros
      )
    } else {
      response = await this.fetch2(
        'reclamacao?datahora={"gte":"' +
          dataInicio +
          '","lte":"' +
          dataFim +
          '"}&per_page=' +
          pageSize +
          '&page=' +
          page +
          '&' +
          filtros
      )
    }

    return response
  }

  async deleteReclamacao(id_reclamacao) {
    let result = await this.fetch2('reclamacao/' + id_reclamacao, {
      method: 'DELETE'
    })

    return result
  }

  async getReclamacaoByID(id_reclamacao) {
    let response = await this.fetch2('reclamacao/' + id_reclamacao)
    response = await response.json()

    return response
  }

  async alterarReclamacao(
    reclamacao,
    id_tipo_reclamacao,
    id_reclamacao,
    motorista_cpf,
    nivel,
    status,
    departamento,
    feedback
  ) {
    let result = await this.fetch2('reclamacao/' + id_reclamacao, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        descricao: reclamacao,
        tiporeclamacao_id: id_tipo_reclamacao,
        motorista_cpf: motorista_cpf,
        nivel: nivel,
        status: status,
        departamento: departamento,
        feedbackcliente: feedback
      })
    })

    return result
  }

  async getTodasCorridas() {
    let result = await this.fetch2('corridaz?per_page=10')
    result = result.json()

    return result
  }

  reverseGeocode(coords) {
    return fetch(
      this.buildUrl('geocode', 'json', {
        latlng: coords.latitude + ',' + coords.longitude
      })
    )
      .then((response) => {
        return response.json()
      })
      .then((response) => {
        let my_result = response.results.find((el) =>
          el.types.includes('street_address')
        )
        if (my_result) {
          let address_components = my_result.address_components
          let shortAddress =
            address_components.find((el) => el.types.includes('route'))
              .long_name +
            ', ' +
            address_components.find((el) => el.types.includes('street_number'))
              .long_name
          let fullAddress = shortAddress
          if (address_components.find((el) => el.types.includes('locality'))) {
            fullAddress =
              fullAddress +
              ', ' +
              address_components.find((el) => el.types.includes('locality'))
                .long_name
          }
          return { shortAddress: shortAddress, fullAddress: fullAddress }
        }
      })
  }

  async geocodeByPlaceId(street, number, city) {
    let response
    if (number && city) {
      response = await this.fetch2(
        'geocode?endereco=' + street + ', ' + number + ', ' + city
      )
      response = await response.json()
    } else if (city && !number) {
      response = await this.fetch2('geocode?endereco=' + street + ', ' + city)
      response = await response.json()
    } else {
      response = await this.fetch2('geocode?endereco=' + street)
      response = await response.json()
    }

    return response
  }

  async salvaRelatorio(sqltext, relatorio, tipo, linhas, colunas) {
    let result = await this.fetch2('relatorios', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        sqltext: sqltext,
        relatorio: relatorio,
        tipo: tipo,
        linhas: linhas,
        colunas: colunas
      })
    })

    return result
  }

  async listaRelatorio() {
    let response = await this.fetch2('relatorios')
    response = await response.json()
    response.sort(function (a, b) {
      return a.relatorio > b.relatorio ? 1 : b.relatorio > a.relatorio ? -1 : 0
    })

    return response
  }

  async updateRelatorio(sqltext, relatorio, tipo, codigo, linhas, colunas) {
    let result = await this.fetch2('relatorios', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        sqltext: sqltext,
        relatorio: relatorio,
        tipo: tipo,
        codigo: codigo,
        linhas: linhas,
        colunas: colunas
      })
    })

    return result
  }
  async executeRelatorio(codigo, parametros) {
    let esc = encodeURIComponent
    let query = Object.keys(parametros)
      .map((k) => esc(k) + '=' + esc(parametros[k]))
      .join('&')
    let response = await this.fetch2(
      'relatorios/execute?codigo=' + codigo + '&' + query
    )
    response = await response.json()

    return response
  }
  async runFaturamento(ano, mes, centrais) {
    let response = await this.fetch2(
      'relatorios/faturamento?ano=' +
        ano +
        '&mes=' +
        mes +
        '&centrais=' +
        centrais
    )
    response = await response.json()

    return response
  }
  async getParametro() {
    let response = await this.fetch2('parametros')
    response = await response.json()

    if (!response) {
      return {
        tempopendencia: 5 * 600,
        tempoqrl: 10 * 60,
        tempoqrv: 45 * 60,
        tempoemfrente: 7 * 60
      }
    }
    return response
  }

  async saveParametro(tempopendencia, tempoqrl, tempoqrv, tempoemfrente) {
    let result = await this.fetch2('parametros', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        tempopendencia: tempopendencia,
        tempoqrl: tempoqrl,
        tempoqrv: tempoqrv,
        tempoemfrente: tempoemfrente
      })
    })

    return result
  }

  async getRefsMapa() {
    let response = await this.fetch2('cercas/mapa')
    response = await response.json()

    return response
  }

  async getSaldo(cpf) {
    let response = await this.fetch2('voucher_saldo?cpf=' + cpf)
    response = await response.json()

    return response
  }

  async getFFTipos() {
    let response = await this.fetch2('tipoff')
    response = await response.json()

    return response
  }

  async colocarMotoFF(cpf, idFF, central, motivo, dataInicial, dataFinal) {
    let result = await this.fetch2('ffmotorista/colocar', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        t44_cpf_mot: cpf,
        t44_cd_tp_ff: idFF,
        central: central,
        t44_motivo_ff: motivo,
        t44_dthr_ini: dataInicial,
        t44_dthr_fim: dataFinal
      })
    })

    return result
  }

  async getHistPA() {
    let response = await this.fetch2('historicodepa')
    response = await response.json()

    return response
  }

  async enviarDadosIugu(dados, producao) {
    let result = await this.fetch2('criartoken', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        dados: dados,
        producao: producao
      })
    })

    result = result.json()

    return result
  }

  async motoByCPF(cpf) {
    let response = await this.fetch2('motoristacadastro?t08_cpf_sec=' + cpf)
    response = await response.json()

    return response
  }

  async getFFbyAT(at) {
    let response = await this.fetch2(
      'ffmotorista?t07_carro.t07_conceccao=' + at
    )
    response = await response.json()

    return response
  }

  async removerFFMoto(id, nome) {
    let result = await this.fetch2('ffmotorista/baixar', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        t44_num_ocorrencia: id,
        t44_nome_liberador: nome
      })
    })

    return result
  }

  async getAgenda() {
    try {
      let result = await this.fetch2('go-agendamentos')
      let resposta = await result.json()
      console.log('************* getAgenda **************: ', resposta)
      return resposta
    } catch (error) {
      //console.log('************* ERRO **************: ', error)
      return error
    }
  }

  async insertAgenda(){
    try {
      let result = await this.fetch2('go-agendamento', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      let resposta = await result.json()
      console.log('************* insertAgenda **************: ', resposta)
      return resposta
    } catch (error) {
      //console.log('************* ERRO **************: ', error)
      return error
    }
  }

  // async fetchIugu(url,dados) {
  //   return fetch(url, {
  //       method: 'POST',
  //       headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json',
  //         },
  //       body:JSON.stringify(dados)
  //   })
  //   .then(response => {
  //       let r = response.json();
  //       //console.log('OK ' + url, r);
  //       return r;
  //     })
  //   .catch((error) => {
  //       //console.log('ERRO ' + url, error);
  //   });
  // }

  // async criarTokenIugu(dados, producao) {
  //   //https://api.iugu.com/v1/payment_token
  //   let infos = {
  //     account_id: account_id,
  //     method: 'credit_card',
  //     test: !producao,
  //     data: dados
  //   }
  //   let saida = this.fetchIugu("https://api.iugu.com/v1/payment_token",infos);
  //   return saida;
  // }
}
