import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from './CadastroHelper';

class AttUUID extends Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <CadastroHelper
        dados={{
          acessoID: 'f47431df-d957-4fc4-b905-4c46c4fc2cf0',
          parentProps: this.props,
          id: "t08_cpf_sec",
          tipo_cadastro: "UUID",
          tipo_plural: "UUIDs",
          href: "/uuid",
          titulo: "Atualizar Celular",
          requests: {
            type: 'uuid',
            list: this.ws.getTipo.bind(this.ws),
            edit: this.ws.alterarTipoUUID.bind(this.ws),
          },
          campos: [
            {
              ref: "t08_nomemotorista",
              label: "Motorista",
              type: "disabled",
              ignoresave: true
            },
            {
              ref: "central_nomefantasia",
              filter: "central.nomefantasia",
              label: "Central",
              type: "disabled",
              ignoresave: true
            },
            {
              ref: "uuid333",
              label: "UUID",
              type: "text",
              placeHolder: "Escreva o UUID",
            },
            // {
            //   ref: "t10_st_motorista_t10_desc_st_motorista",
            //   filter: "t10_st_motorista.t10_desc_st_motorista",
            //   label: "Situacão",
            //   type: "disabled",
            // },
          ],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Motorista",
                accessor: "t08_nomemotorista",
              },
              {
                Header: "Central",
                accessor: "central_nomefantasia",
              },
              // {
              //   Header: "Situação",
              //   accessor: "t10_st_motorista_t10_desc_st_motorista",
              // },
            ],
          }
        }}
      />
    );
  }
}

export default AttUUID;
