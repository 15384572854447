import WS from '../services/webservice';
import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import { Button, Modal, Row, Col, Form, FormControl } from 'react-bootstrap'
import Loader from 'react-loader';
import moment from 'moment';
import Select2 from 'react-select2-wrapper';
import NumberFormat from 'react-number-format';

class DadosReclamacao extends Component {

  constructor(props) {
    super(props);
    console.log("lookprops", props)
    this.state = {
      reclamacao: null,
      loaded: false,
      corrida: null,
      motorista: null,
      departamento: [],
      nivel: [],
      status: [],
      feedback: '',
    }

    this.getData = this.getData.bind(this);

    this.webservice = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }


  getData() {
    this.setState({ loaded: false });

    console.log(this.props.reclamacao);

    // this.webservice.corridaByID(this.props.reclamacao.corrida_id)
    //   .then(result => {
    //     console.log(result);
    //     this.setState({ corrida: result[0] });
    //   });

    //   this.webservice.motoByCPF(this.props.reclamacao.motorista_cpf)
    //   .then(result => {
    //     console.log(result);
    //     this.setState({ motorista: result[0] });
    //   });

    this.setState({
      loaded: true,
      departamento: this.props.reclamacao.departamento,
      nivel: this.props.reclamacao.nivel,
      status: this.props.reclamacao.status,
      feedback: this.props.reclamacao.feedbackcliente !== null? this.props.reclamacao.feedbackcliente : null,
    });
  }

  render() {
    //Change the current language to portuguese BR
    require('moment/locale/pt-br.js');
    moment.locale('pt-br');

    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={() => {
          this.setState({ loaded: false, corrida: null, motorista: null });
          this.props.hide();
        }}
        onEntered={() => { this.getData(); }}
        container={this}
        aria-labelledby="contained-modal-title"
        dialogClassName='pesquisa-corridas-dialog-modal'
      >
        <Modal.Body>
          {!this.state.loaded && <div style={{ height: 100 }}></div>}
          <Loader loaded={this.state.loaded}>
            <Row ref={el => (this.reclamacaoRow = el)} style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Col md={3} >
                <Row style={{ padding: 10, paddingTop: 0, marginBottom: '1.2%', border: '1px solid black', borderRadius: 10 }}>

                  <h4><strong>Motorista</strong></h4>
                  {this.props.reclamacao &&
                    <div className="data-recla">
                      <p><strong>RT:</strong> {this.props.reclamacao.t08_motoristas_t08_rt_sec}</p>
                      <p><strong>Nome:</strong> {this.props.reclamacao.t08_motoristas_t08_nomemotorista}</p>
                      <p><strong>Placa:</strong> {this.props.reclamacao.t07_carro_t07_placa_detran}</p>
                      <p><strong>Modelo:</strong> {this.props.reclamacao.t18_modelo_t18_desc_modelo}</p>
                    </div>
                  }
                </Row>
                <Row style={{ padding: 10, paddingTop: 0, marginBottom: '1.2%', border: '1px solid black', borderRadius: 10 }}>
                  <Row style={{ padding: 10, paddingTop: 0, marginBottom: '1.2%' }}>
                    <h4><strong>Departamento</strong></h4>
                    <Select2
                      value={this.state.departamento}
                      data={this.props.depProp}
                      onSelect={e => {
                        //this.setState({ record: { ...referencia, [m.ref]: this.getSelectedOptionsValue(e.target.selectedOptions) } })
                        // let vetorzin = []
                        // vetorzin.push()
                        //console.log("OPA FIOOO", this.props.dados)
                        this.setState({ departamento: Number(e.target.selectedOptions[0].value) })
                      }}
                      onUnselect={e => {
                        //this.setState({ record: { ...referencia, [m.ref]: this.getSelectedOptionsValue(e.target.selectedOptions) } })
                        this.setState({ departamento: e })
                      }}
                    />
                  </Row>
                  <Row style={{ padding: 10, paddingTop: 0, marginBottom: '1.2%' }}>
                    <h4><strong>Status</strong></h4>
                    <Select2
                      value={this.state.status}
                      data={this.props.staProp}
                      onSelect={e => {
                        //this.setState({ record: { ...referencia, [m.ref]: this.getSelectedOptionsValue(e.target.selectedOptions) } })
                        // let vetorzin = []
                        // vetorzin.push(Number(e.target.selectedOptions[0].value))
                        // console.log("OPA FIOOO", this.props.dados)
                        this.setState({ status: Number(e.target.selectedOptions[0].value) })
                      }}
                      onUnselect={e => {
                        //this.setState({ record: { ...referencia, [m.ref]: this.getSelectedOptionsValue(e.target.selectedOptions) } })
                        this.setState({ status: e })
                      }}
                    />
                  </Row>
                  <Row style={{ padding: 10, paddingTop: 0, marginBottom: '1.2%' }}>
                    <h4><strong>Nivel</strong></h4>
                    <Select2
                      value={this.state.nivel}
                      data={this.props.nivProp}
                      onSelect={e => {
                        //this.setState({ record: { ...referencia, [m.ref]: this.getSelectedOptionsValue(e.target.selectedOptions) } })
                        //let vetorzin = []
                        // vetorzin.push(Number(e.target.selectedOptions[0].value))
                        // console.log("OPA FIOOO", this.props.dados)
                        this.setState({ nivel: Number(e.target.selectedOptions[0].value) })
                      }}
                      onUnselect={e => {
                        //this.setState({ record: { ...referencia, [m.ref]: this.getSelectedOptionsValue(e.target.selectedOptions) } })
                        this.setState({ nivel: e })
                      }}
                    />
                  </Row>
                </Row>
              </Col>
              <Col md={9} style={{ paddingLeft: '3%' }}>
                <Row style={{ padding: 10, paddingTop: 0, marginBottom: '1.2%', border: '1px solid black', borderRadius: 10 }}>
                  <h4><strong>Reclamação</strong></h4>
                  <div className="data-recla">
                    <p><strong>Data/Hora:</strong> {this.props.reclamacao ? moment(this.props.reclamacao.datahora).format('DD/MM/YYYY HH:mm') : ''}</p>
                    <p><strong>Tipo:</strong> {this.props.reclamacao ? this.props.reclamacao.tiporeclamacao_descricao : ''}</p>
                    <p><strong>Descrição:</strong> {this.props.reclamacao ? this.props.reclamacao.descricao : ''}</p>
                  </div>
                </Row>
                <Row style={{ padding: 10, paddingTop: 0, marginBottom: '1.2%', border: '1px solid black', borderRadius: 10 }}>
                  <h4><strong>Corrida</strong></h4>
                  {this.props.reclamacao && this.props.reclamacao.corrida &&
                    <div className="data-recla">
                      <div><p><strong>QRU:</strong> {this.props.reclamacao.corrida_id}</p></div>
                      {this.props.reclamacao.corrida[0] && this.props.reclamacao.corrida[0].t12_st_corrida &&
                        <div>
                          <p><strong>Status:</strong>
                            {this.props.reclamacao.corrida[0].datahoraagendamento || this.props.reclamacao.corrida[0].t12_dthr_agendamento ? 'Corrida agendada' :
                              this.props.reclamacao.corrida[0].t12_st_corrida === 'C' ? 'Cancelada' :
                                this.props.reclamacao.corrida[0].t12_st_corrida === 'N' ? 'Corrida não enviada' :
                                  this.props.reclamacao.corrida[0].t12_dthr_embarque === null ? 'Pendente QRL' :
                                    this.props.reclamacao.corrida[0].t12_dthr_dembarque === null ? 'Pendente QRV' :
                                      'Corrida finalizada'}</p>
                        </div>
                      }
                      {this.props.reclamacao.corrida[0] && <div><p><strong>Origem:</strong> {this.props.reclamacao.corrida[0] && (this.props.reclamacao.corrida[0].endereco || this.props.reclamacao.corrida[0].t12_endereco)}, {this.props.reclamacao.corrida[0].numero || this.props.reclamacao.corrida[0].t12_numero}, {this.props.reclamacao.corrida[0].t12_bairro}, {this.props.reclamacao.corrida[0].cidade}, {this.props.reclamacao.corrida[0].estado}</p></div>}

                      {this.props.reclamacao.corrida[0] && this.props.reclamacao.corrida[0].t12_complemento && this.props.reclamacao.corrida[0].t12_complemento !== '' &&
                        <div><p><strong>Complemento:</strong> {this.props.reclamacao.corrida[0].t12_complemento}</p></div>
                      }

                      {this.props.reclamacao.corrida[0] && !this.props.reclamacao.corrida[0].destinos && this.props.reclamacao.corrida[0].taxi333$enderecodestino !== ", , " && this.props.reclamacao.corrida[0].taxi333$enderecodestino !== ", " &&
                        <div><p><strong>Destino:</strong> {this.props.reclamacao.corrida[0].taxi333$enderecodestino}, {this.props.reclamacao.corrida[0].bairrodestino}, {this.props.reclamacao.corrida[0].cidadedestino}, {this.props.reclamacao.corrida[0].estadodestino}</p></div>
                      }

                      {this.props.reclamacao.corrida[0] && this.props.reclamacao.corrida[0].destinos && this.props.reclamacao.corrida[0].destinos.map((d, i) => {
                        let index = i + 1;
                        return (
                          <div><p><strong>{'Destino ' + index + ' :'}</strong> {d.Address.shortAddress}</p></div>
                        );
                      })}

                      { this.props.reclamacao.corrida[0] && this.props.reclamacao.corrida[0].destinos && this.props.reclamacao.corrida[0].destinos.length >= 0 && this.props.reclamacao.corrida[0].taxi333$enderecodestino !== ", , " && this.props.reclamacao.corrida[0].taxi333$enderecodestino !== ", " &&
                        <div><p><strong>Destino Final:</strong> {this.props.reclamacao.corrida[0].taxi333$enderecodestino}, {this.props.reclamacao.corrida[0].bairrodestino}, {this.props.reclamacao.corrida[0].cidadedestino}, {this.props.reclamacao.corrida[0].estadodestino}</p></div>
                      }
                      {this.props.reclamacao.corrida[0] && this.props.reclamacao.corrida[0].complementodestino && this.props.reclamacao.corrida[0].complementodestino !== '' &&
                        <div><p><strong>Complemento:</strong> {this.props.reclamacao.corrida[0].complementodestino}</p></div>
                      }

                      {this.props.reclamacao.corrida[0] && <div><p><strong>Cliente: </strong>{this.props.reclamacao.corrida[0].cliente || this.props.reclamacao.corrida[0].t12_nome}</p></div>}

                      {this.props.reclamacao.corrida[0] && this.props.reclamacao.corrida[0].telefone
                        ?
                        <div><p><strong>Telefone: </strong>{this.props.reclamacao.corrida[0] && this.props.reclamacao.corrida[0].telefone.toString().length === 10 ? this.props.reclamacao.corrida[0].telefone.toString().replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") : this.props.reclamacao.corrida[0].telefone.toString().replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}</p></div>
                        :
                        this.props.reclamacao.corrida[0] && this.props.reclamacao.corrida[0].t12_fone &&
                        <div><p><strong>Telefone: </strong>{this.props.reclamacao.corrida[0] && this.props.reclamacao.corrida[0].t12_fone.toString().length === 10 ? this.props.reclamacao.corrida[0].t12_fone.toString().replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") : this.props.reclamacao.corrida[0].t12_fone.toString().replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}</p></div>
                      }

                      {this.props.reclamacao.corrida[0] && <div><p><strong>Atendente:</strong> {this.props.reclamacao.corrida[0].atendente || this.props.reclamacao.corrida[0].taxi333$atendente}</p></div>}
                      {this.props.reclamacao.corrida[0] && this.props.reclamacao.corrida[0].dados_corrida &&
                        <div><p><strong>Valor Corrida: </strong> <NumberFormat value={this.props.reclamacao.corrida[0].dados_corrida.valorcalculado} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} /></p></div>
                      }

                      { this.props.reclamacao.corrida[0] &&this.props.reclamacao.corrida[0].dados_corrida && this.props.reclamacao.corrida[0].dados_corrida.referencia_original_string &&
                        <div><p><strong>Referência:</strong> {this.props.reclamacao.corrida[0].dados_corrida.referencia_original_string.split(' - ')[1]}</p></div>
                      }
                    </div>
                  }
                </Row>
                <Row style={{ padding: 10, paddingTop: 0, marginBottom: '1.2%', border: '1px solid black', borderRadius: 10 }}>
                  <h4><strong>Gravação</strong></h4>
                </Row>
                <Row style={{ padding: 10, paddingTop: 0, marginBottom: '1.2%', border: '1px solid black', borderRadius: 10 }}>
                  <h4><strong>Feedback</strong></h4>
                  {console.log("OLHA O FEEDBACK", this.state.feedback)}
                  {console.log("NOVO FEED", this.props.reclamacao && this.props.reclamacao.feedback)}
                  {(this.props.reclamacao && this.props.reclamacao.feedbackcliente === null) && <div>
                    <Form>
                      <FormControl
                        value={this.state.feedback}
                        componentClass="textarea"
                        style={{ height: 80 }}
                        onChange={(e) => this.setState({ feedback: e.target.value })}
                      />
                    </Form>
                  </div>}
                  {(this.props.reclamacao && this.props.reclamacao.feedbackcliente !== null) && <div>
                    {this.state.feedback}
                  </div>}
                </Row>
              </Col>
            </Row>
          </Loader>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => {
            this.webservice.alterarReclamacao(this.props.reclamacao.descricao, this.props.reclamacao.tiporeclamacao_id, this.props.reclamacao.id, this.props.reclamacao.motorista_cpf, this.state.nivel, this.state.status, this.state.departamento, this.state.feedback)
            this.setState({ loaded: false, corrida: null, motorista: null });
            this.props.hide();
            this.props.callInside();
          }}>
            Enviar
          </Button>
          <ReactToPrint
            trigger={() => <Button>Imprimir</Button>}
            content={() => this.reclamacaoRow}
          />
          <Button onClick={() => {
            this.setState({ loaded: false, corrida: null, motorista: null });
            this.props.hide();
          }}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
export default DadosReclamacao;
