import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import moment from 'moment';
import { Row, Modal, Col, FormGroup, ControlLabel, Button, FormControl } from 'react-bootstrap';
import WS from '../services/webservice';

class SuspenderFF extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataInicio: moment(),
      dataFinal: moment().add(1, 'hours'),
      showFinalPicker: false,
      ffSelected: [],
    }

    this.webservice = new WS();
  }

  getSelectedOptionsValue(selectedOptions) {
    var values = [];

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value)
    }

    return values;
  }

  render() {
    //Change the current language to portuguese BR
    require('moment/locale/pt-br.js')
    moment.locale('pt-br')

    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={this.props.modalHide}
        onEntered={() => { }}
        container={this}
        aria-labelledby="contained-modal-title"
      >
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Tipo de FF*</ControlLabel>
            <Select2
              value={this.state.ffSelected}
              data={this.props.ffTipos}
              onSelect={e => {
                let selected = this.getSelectedOptionsValue(e.target.selectedOptions);
                let obj = this.props.ffTipos.find((obj) => { return obj.id === Number(selected[0]) });
                if (!obj.t28_delimitado) {
                  this.setState({ showFinalPicker: true });
                } else {
                  this.setState({ showFinalPicker: false, dataFinal: moment(this.state.dataInicio).add(obj.t28_tempo, 'minutes') });
                }
                console.log(obj);
                this.setState({ ffSelected: selected });
              }}
              onUnselect={e => {
                this.setState({ ffSelected: this.getSelectedOptionsValue(e.target.selectedOptions) })
              }}
            />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Data Início</ControlLabel>
                <DatePicker
                  selected={this.state.dataInicio}
                  className='form-control'
                  onChange={(v) => this.setState({ dataInicio: v })}
                  showTimeSelect
                  name="dataHoraInicio"
                  timeFormat="HH:mm"
                  timeIntervals={10}
                  dateFormat="DD/MM/YY HH:mm"
                  timeCaption="hora" />
              </FormGroup>
            </Col>
            <Col md={6}>
              {this.state.showFinalPicker &&
                <FormGroup>
                  <ControlLabel>Data Final</ControlLabel>
                  <DatePicker
                    selected={this.state.dataFinal}
                    className='form-control'
                    onChange={(v) => this.setState({ dataFinal: v })}
                    showTimeSelect
                    name="dataHoraInicio"
                    timeFormat="HH:mm"
                    timeIntervals={10}
                    dateFormat="DD/MM/YY HH:mm"
                    timeCaption="hora" />
                </FormGroup>
              }
            </Col>
          </Row>
          <FormGroup>
            <ControlLabel>Motivo</ControlLabel>
            <FormControl
              type="text"
              value={this.state.motivo}
              onChange={e => { this.setState({ motivo: e.target.value }) }}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              if(this.state.ffSelected.length <= 0){
                this.props.dialog.showAlert('Por favor escolha o tipo do FF!');
                return;
              }
              let m = this.props.moto;
              let s = this.state;
              this.webservice.colocarMotoFF(m.cpf, s.ffSelected[0], m.central_id, s.motivo, s.dataInicio, s.dataFinal)
                .then((result) => {
                  console.log(result);
                  this.props.dialog.showAlert('Motorista colocado em FF com sucesso!!');
                  this.props.modalHide();
                })
            }}
          >Salvar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default SuspenderFF;