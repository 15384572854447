import React, { Component } from 'react';
import NewMsg from './NewMsg.js';
import { Modal } from 'react-bootstrap';

class HistoricoMensagens extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={this.props.modalHide}
        onEntered={() => { }}
        container={this}
        aria-labelledby="contained-modal-title"
        dialogClassName="mensagens-dialog-modal"
      >
        <Modal.Body>
          <div style={{ height: '80vh' }}>
            {this.props.moto &&
              <div style={{ display: 'inline-block', marginLeft: 65, position: 'fixed', bottom: '50px', width: '60px' }} >
                <NewMsg
                  at={Number(this.props.moto.porta)}
                  rt={Number(this.props.moto.rt)}
                  uuid={this.props.moto.uuid333}
                  central={Number(this.props.moto.central_id)}
                  cpf={Number(this.props.moto.cpf)}
                  aberto={true}
                  modalHide={this.props.modalHide}
                />
              </div>
            }
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
export default HistoricoMensagens;