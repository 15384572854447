import React, { Component } from 'react';
import Dialog from 'react-bootstrap-dialog-v2'
import ReactTable from "react-table";
import "react-table/react-table.css";
import AutoComplete from './AutoComplete.js';
import { GoogleMap, Marker, withGoogleMap, withScriptjs, Polygon } from 'react-google-maps';
import WS from '../services/webservice';
import './Solicitacoes.css';
import { Button, Row, Col, Breadcrumb, Image, Modal, Form, Checkbox, ControlLabel, FormControl, FormGroup } from 'react-bootstrap'
import GoMap from './GoMap.js';

const GoogleMapsWrapper = withScriptjs(withGoogleMap(props => {
  const { onMapMounted, ...otherProps } = props;
  return (
    <GoogleMap onTilesLoaded={props.onTilesLoaded} {...otherProps} ref={c => { onMapMounted && onMapMounted(c) }}  >
      {props.children}
    </GoogleMap>
  );
}));


class Referencias extends Component {

  static defaultProps = {
    center: { lat: -25.475015, lng: -49.2911143 },
    zoom: 17
  };

  constructor(props) {
    super(props);
    this.state = {
      address: [],
      local_text: '',
      local: {
        city: '',
        shortAddress: '', fullAddress:''
      },
      center: { lat: -25.475015, lng: -49.2911143 },
      zoom: 15,
      markerLocal: [{
        lat: '',
        lng: '',
      }],
      modal: false,
      novo: false,
      edit: false,
      list: [],
      pages: null,
      loading: true,
      t61_ref: 0,
      t61_nome: '',
      t61_lat: 0,
      t61_lon: 0,
      t61_ativado: true,
      polysPaths: [],
      proximos: [],
    }

    this.webservice = new WS();

    this.novo = this.novo.bind(this);
    this.edit = this.edit.bind(this);
    this.remove = this.remove.bind(this);
    this.submit = this.submit.bind(this);
    this.getList = this.getList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.changeEnderecoOrigemExtra = this.changeEnderecoOrigemExtra.bind(this);
  }
  modalHide() {
    this.props.history.push("/referencias");
    this.setState({ modal: false, novo: false, edit: false, id: null });
  }

  novo() {
    this.setState({
      modal: true,
      novo: true,
      address: [],
      local_text: '',
      local: {
        city: '',
        shortAddress: '',
      },
      center: { lat: -25.475015, lng: -49.2911143 },
      zoom: 15,
      markerLocal: [{
        lat: '',
        lng: '',
        img_src: '',
      }],
      t61_ref: 0,
      t61_nome: '',
      t61_lat: 0,
      t61_lon: 0,
      t61_ativado: true,
      polysPaths: [],
    });
  }

  edit(data) {
    let a = [{ path: [] }];
    if (data.original.t61_path) {
      for (let i = 0; i < data.original.t61_path.length; i++) {
        a[0].path.push(
          {
            lat: data.original.t61_path[i][0],
            lng: data.original.t61_path[i][1]
          },
        );
      }
    }

    data.original.p_e.map((p, i) => {
      let a = [{ path: [] }];
      if (p.t61_path) {
        for (let i = 0; i < p.t61_path.length; i++) {
          a[0].path.push(
            {
              lat: p.t61_path[i][0],
              lng: p.t61_path[i][1]
            },
          );
        }
        p.path = a;
        p.ativo = false;
      }
      let ref = data.original.proximos.find(r => r.id === p.id);
      if (!ref) { data.original.proximos.push(p); }
      return null;
    })
    data.original.p_n.map((p, i) => {
      let a = [{ path: [] }];
      if (p.t61_path) {
        for (let i = 0; i < p.t61_path.length; i++) {
          a[0].path.push(
            {
              lat: p.t61_path[i][0],
              lng: p.t61_path[i][1]
            },
          );
        }
        p.path = a;
        p.ativo = false;
      }
      let ref = data.original.proximos.find(r => r.id === p.id);
      if (!ref) { data.original.proximos.push(p); }
      return null;
    })
    data.original.p_ne.map((p, i) => {
      let a = [{ path: [] }];
      if (p.t61_path) {
        for (let i = 0; i < p.t61_path.length; i++) {
          a[0].path.push(
            {
              lat: p.t61_path[i][0],
              lng: p.t61_path[i][1]
            },
          );
        }
        p.path = a;
        p.ativo = false;
      }
      let ref = data.original.proximos.find(r => r.id === p.id);
      if (!ref) { data.original.proximos.push(p); }
      return null;
    })
    data.original.p_nw.map((p, i) => {
      let a = [{ path: [] }];
      if (p.t61_path) {
        for (let i = 0; i < p.t61_path.length; i++) {
          a[0].path.push(
            {
              lat: p.t61_path[i][0],
              lng: p.t61_path[i][1]
            },
          );
        }
        p.path = a;
        p.ativo = false;
      }
      let ref = data.original.proximos.find(r => r.id === p.id);
      if (!ref) { data.original.proximos.push(p); }
      return null;
    })
    data.original.p_s.map((p, i) => {
      let a = [{ path: [] }];
      if (p.t61_path) {
        for (let i = 0; i < p.t61_path.length; i++) {
          a[0].path.push(
            {
              lat: p.t61_path[i][0],
              lng: p.t61_path[i][1]
            },
          );
        }
        p.path = a;
        p.ativo = false;
      }
      let ref = data.original.proximos.find(r => r.id === p.id);
      if (!ref) { data.original.proximos.push(p); }
      return null;
    })
    data.original.p_se.map((p, i) => {
      let a = [{ path: [] }];
      if (p.t61_path) {
        for (let i = 0; i < p.t61_path.length; i++) {
          a[0].path.push(
            {
              lat: p.t61_path[i][0],
              lng: p.t61_path[i][1]
            },
          );
        }
        p.path = a;
        p.ativo = false;
      }
      let ref = data.original.proximos.find(r => r.id === p.id);
      if (!ref) { data.original.proximos.push(p); }
      return null;
    })
    data.original.p_sw.map((p, i) => {
      let a = [{ path: [] }];
      if (p.t61_path) {
        for (let i = 0; i < p.t61_path.length; i++) {
          a[0].path.push(
            {
              lat: p.t61_path[i][0],
              lng: p.t61_path[i][1]
            },
          );
        }
        p.path = a;
        p.ativo = false;
      }
      let ref = data.original.proximos.find(r => r.id === p.id);
      if (!ref) { data.original.proximos.push(p); }
      return null;
    })
    data.original.p_w.map((p, i) => {
      let a = [{ path: [] }];
      if (p.t61_path) {
        for (let i = 0; i < p.t61_path.length; i++) {
          a[0].path.push(
            {
              lat: p.t61_path[i][0],
              lng: p.t61_path[i][1]
            },
          );
        }
        p.path = a;
        p.ativo = false;
      }
      let ref = data.original.proximos.find(r => r.id === p.id);
      if (!ref) { data.original.proximos.push(p); }
      return null;
    })

    console.log('ORIGINAL', data.original);
    console.log('PROXIMOS', data.original.proximos);

    this.setState({
      polysPaths: a,
      modal: true,
      edit: true,
      proximos: data.original.proximos,
      id: data.original.id,
      t61_ref: data.original.t61_ref,
      t61_lat: data.original.t61_lat,
      t61_lon: data.original.t61_lon,
      t61_nome: data.original.t61_nome,
      t61_ativado: data.original.t61_ativado,
      markerLocal: [{
        lat: data.original.t61_lat,
        lng: data.original.t61_lon,
      }],
      center: {
        lat: data.original.t61_lat,
        lng: data.original.t61_lon,
      },
    });
  }

  remove(data) {
    Dialog.setOptions({
      defaultOkLabel: 'Sim',
      defaultCancelLabel: 'Não',
      primaryClassName: 'btn-danger '
    })
    this.dialog.show({
      body: 'Você tem certeza que deseja deletar??',
      actions: [
        Dialog.CancelAction(() => { }),
        Dialog.OKAction(() => {
          this.webservice.deleteTipo('cerca', data.original['id']).then(result => {
            Dialog.resetOptions();
            if (result.ok) {
              this.dialog.showAlert('Referência deletada com sucesso!')
              this.getList();
            } else {
              this.dialog.showAlert('Erro ao deletar a referência! Informe os desenvolvedores!')
            }
          })
        }
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  getList() {
    this.setState({
      dados: { ...this.state.dados, page: this.state.dados.page - 1 },
      modal: false,
      novo: false,
      edit: false
    });
    this.fetchData(this.state.dados, this.state.instance);
  }

  async fetchData(dados, instance) {
    // show the loading overlay
    this.setState({ loading: true, dados: dados, instance: instance })
    // fetch your data
    let order = dados.sorted;
    let result = await this.webservice.getTipo('cerca', '', order, ++dados.page, dados.pageSize, dados.filtered, null);
    let count = result.headers.get('X-Total-Count');
    result = await result.json();

    this.setState({
      list: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false
    })
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => { this.fetchData(dados, instance) }, 300)
    });
  }

  submit(e) {
    e.preventDefault();
    let s = this.state;
    let newPolyPaths = [];

    for (let i = 0; i < s.polysPaths.length; i++) {
      let tempPaths = [];
      this.refs['poly' + i].getPath().forEach((latlng, index) => {
        tempPaths.push([latlng.lat(), latlng.lng()]);
      })

      newPolyPaths = tempPaths;
    }

    if (s.t61_nome === '') {
      Dialog.resetOptions();
      this.dialog.showAlert("Por favor preencha o nome!");
      return;
    }
    if (s.t61_ref === 0 || s.t61_ref === '') {
      Dialog.resetOptions();
      this.dialog.showAlert("Por favor preencha a ref!");
      return;
    }
    if (s.t61_lat === 0) {
      Dialog.resetOptions();
      this.dialog.showAlert("Por favor preencha o endereço!");
      return;
    }
    if (s.t61_lon === 0) {
      Dialog.resetOptions();
      this.dialog.showAlert("Por favor preencha o endereço!");
      return;
    }

    let dados = {
      central: parseInt(this.webservice.getCookie('central-id'), 10),
      t61_ref: this.state.t61_ref,
      t61_nome: this.state.t61_nome,
      t61_lat: this.state.t61_lat,
      t61_lon: this.state.t61_lon,
      t61_dist: 0,
      t61_path: newPolyPaths,
      t61_ativado: this.state.t61_ativado,
    }


    if (this.state.novo) {
      this.webservice.criarTipo('cerca', dados)
        .then(result => {
          Dialog.resetOptions();
          if (result.ok) {
            this.getList();
            this.dialog.showAlert('Referência criada com sucesso!')
          } else {
            this.dialog.showAlert('Erro ao criar a referência! Verifique os dados digitados!')
          }
        })
    } else if (this.state.edit) {
      this.webservice.alterarTipo('cerca', this.state.id, dados, [])
        .then(result => {
          Dialog.resetOptions();
          if (result.ok) {
            this.getList();
            this.dialog.showAlert('Referência alterada com sucesso!')
          } else {
            this.dialog.showAlert('Erro ao alterar a referência! Verifique os dados alterados!')
          }
        })
    }
  }

  changeAddress(newAddress, addressType) {
    this.setState({ address: newAddress });
  }


  changeEndereco(newAddress, newCity, estado, bairro, addressType, number, obs, latlon, completo) {
    console.log('changeEnderecoOrigem', [newAddress, newCity, estado, bairro, addressType, number, obs, latlon, completo]);
    this.setState({ local: { shortAddress: newAddress, fullAddress:newAddress, city: newCity, latlon:latlon, completo: completo }, local_text: newAddress });
    
  }
  changeEnderecoOrigemExtra(newAddress) {
    this.setState({ local_text: newAddress });
  }
  changeAutoCompleteRefs(a, b, c) { }
  onSuggestionSelected(a) { }

  changeValidation(a, b, c) {

    if (c) {
      this.webservice.geocodeByPlaceId(this.state.local.shortAddress, null, null).then(result => {
        let a = this.state.polysPaths;
        a.push({
          path: [
            { lat: result.latitude - (result.latitude * 0.00007), lng: result.longitude + (result.longitude * 0.00005) },
            { lat: result.latitude - (result.latitude * 0.00007), lng: result.longitude - (result.longitude * 0.00005) },
            { lat: result.latitude + (result.latitude * 0.00007), lng: result.longitude - (result.longitude * 0.00005) },
            { lat: result.latitude + (result.latitude * 0.00007), lng: result.longitude + (result.longitude * 0.00005) },
          ]
        });
        this.setState({
          polysPaths: a,
          markerLocal: [{
            lat: result.latitude,
            lng: result.longitude,
          }]
          , center: { lat: result.latitude, lng: result.longitude },
          t61_lon: result.longitude,
          t61_lat: result.latitude,
        })
      })
    }
  }

  validateAutoCompleteLocal(a) { }

  _mapRef = null;

  _handleMapMounted = (c) => {
    if (!c || this._mapRef) return;
    this._mapRef = c;
  };

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active href='referencias'>Referências</Breadcrumb.Item>
        </Breadcrumb>
        <Col md={12}>
          {(this.props.childProps.userAttrib.admin ||
            this.props.childProps.userAttrib.monitorcentral ||
            this.state.userAttrib.acesso['9fb9c59f-7bca-4649-a910-62100976239c']) &&
            <Button style={{ margin: '-10px 0 10px 0' }} onClick={() => this.novo()}>Novo</Button>
          }
          <ReactTable
            manual
            data={this.state.list}
            pages={this.state.pages}
            loading={this.state.loading}
            onFetchData={this.fetchDataTimeOut}
            previousText='Anterior'
            nextText='Próxima'
            loadingText='Carregando...'
            noDataText='Nenhum dado encontado'
            pageText='Página'
            ofText='de'
            rowsText='linhas'
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
            columns={[
              {
                columns: [
                  {
                    Header: 'Ref',
                    accessor: 't61_ref',
                    width: 60,
                  },
                  {
                    Header: 'Nome',
                    accessor: 't61_nome',
                  },
                  {
                    Header: "Ativo",
                    accessor: "t61_ativado",
                    width: 100,
                    Cell: row => (
                      <div
                        style={{
                          width: '16%',
                          height: '76%',
                          margin: '0 auto',
                          backgroundColor: row.original.t61_ativado ? 'green' : 'red',
                          borderRadius: '2px'
                        }}
                      />
                    ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return row[filter.id];
                      }
                      return !row[filter.id];
                    },
                    Filter: ({ filter, onChange }) =>
                      <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : ""}
                      >
                        <option value="">Todos</option>
                        <option value="true">Ativado</option>
                        <option value="false">Desativado</option>
                      </select>
                  },
                  {
                    Header: 'Ações',
                    width: 90,
                    filterable: false,
                    sortable: false,
                    style: {
                      cursor: 'pointer',
                    },
                    Cell: props =>
                      <div>
                        {(this.props.childProps.userAttrib.admin ||
                          this.props.childProps.userAttrib.monitorcentral ||
                          this.state.userAttrib.acesso['9fb9c59f-7bca-4649-a910-62100976239c']) &&
                          <Image src={require('../images/edit.png')} style={{ marginLeft: '10px' }}
                            onClick={() => { this.edit(props) }}></Image>
                        }
                        {(this.props.childProps.userAttrib.admin ||
                          this.props.childProps.userAttrib.monitorcentral ||
                          this.state.userAttrib.acesso['9fb9c59f-7bca-4649-a910-62100976239c']) &&
                          <Image src={require('../images/remove.png')} style={{ marginLeft: '10px' }}
                            onClick={() => { this.remove(props) }}></Image>
                        }
                      </div>
                  }
                ]
              },
            ]}
            defaultPageSize={5}
            className="-striped -highlight"
          />
        </Col>
        <Dialog ref={(el) => { this.dialog = el }} />
        <Modal
          keyboard
          show={this.state.modal}
          onHide={this.modalHide.bind(this)}
          container={this}
          aria-labelledby="contained-modal-title"
          dialogClassName="referencias-dialog-modal"
        >
          <Modal.Header>
            {this.state.novo && <Modal.Title>Criar Referência</Modal.Title>}
            {this.state.edit && <Modal.Title>Editar Referência</Modal.Title>}
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form onSubmit={e => this.submit(e)}
                onKeyPress={e => {
                  if (e.which === 13) {
                    e.preventDefault()
                    return false
                  }
                }}>
                <Row>
                  <Col xs={4} md={4}>
                    <FormGroup>
                      <ControlLabel>Nome</ControlLabel>
                      <FormControl
                        type="text"
                        value={this.state.t61_nome}
                        onChange={e => { this.setState({ t61_nome: e.target.value }) }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Ref</ControlLabel>
                      <FormControl
                        type="text"
                        maxLength={'4'}
                        value={this.state.t61_ref}
                        onChange={e => {
                          if (e.target.value.length > 0) {
                            const number = e.target.value.replace(/\D/g, '');
                            this.setState({ t61_ref: parseFloat(number, 10) });
                          } else {
                            this.setState({ t61_ref: e.target.value });
                          }
                        }}
                      />
                    </FormGroup>
                    <AutoComplete
                      address={this.state.address} changeAddress={this.changeAddress.bind(this)}
                      endereco={this.state.local_text} changeEndereco={this.changeEndereco.bind(this)}
                      changeEnderecoExtra={this.changeEnderecoOrigemExtra.bind(this)}
                      validation={null} changeValidation={this.changeValidation.bind(this)}
                      changeAutoCompleteRefs={this.changeAutoCompleteRefs.bind(this)}
                      onSuggestionSelected={this.onSuggestionSelected.bind(this)}
                      title="Endereço*"
                      center={this.state.center}
                      addressType='local'
                      blur={this.validateAutoCompleteLocal.bind(this)}
                    />

                    <FormGroup>
                      <Checkbox
                        style={{ margin: '0' }}
                        checked={this.state.t61_ativado}
                        onChange={e => { this.setState({ t61_ativado: e.target.checked }) }}>
                        <b>Ativado?</b>
                      </Checkbox>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Referências Próximas</ControlLabel>
                      {this.state.proximos.map((p, i) => {
                        return (
                          < Checkbox
                            style={{ margin: '0', marginTop: 5 }}
                            checked={p.ativo}
                            onChange={e => {
                              let refs = this.state.proximos;
                              refs[i].ativo = e.target.checked;
                              this.setState({ proximos: refs })
                            }}>
                            {p.id + ' - ' + p.t61_nome}
                          </Checkbox>
                        )
                      })}
                    </FormGroup>
                  </Col>
                  <Col xs={8} md={8}>
                    <div id="googleMapDivRefs" style={{ height: '70vh', width: '100%' }}>
                      <GoogleMapsWrapper
                        options={{ streetViewControl: false, mapTypeControl: false }}
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCJvlPMRSy6vltr9575qooDS8bNKRtXxbs&language=pt&region=br&v=3&libraries=drawing"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        onMapMounted={this._handleMapMounted}
                        center={this.state.center}
                        zoom={this.state.zoom}
                        edit={this.state.edit}
                      >
                        <Button
                          onClick={() => {
                            if (this.state.polysPaths.length < 1) {
                              Dialog.resetOptions();
                              this.dialog.showAlert('Ainda não foi adicionado nenhum polígono!');
                            } else {
                              Dialog.setOptions({
                                defaultOkLabel: 'Sim',
                                defaultCancelLabel: 'Não',
                                primaryClassName: 'btn-danger '
                              })
                              this.dialog.show({
                                title: 'Deseja deletar o Polígono?',
                                body: 'Esta ação não pode ser desfeita! Todos os dados serão apagados!',
                                actions: [
                                  Dialog.CancelAction(() => { }),
                                  Dialog.OKAction(() => {
                                    this.setState({ polysPaths: [] }, () => {
                                      Dialog.resetOptions();
                                      this.dialog.showAlert('O polígono foi deletado com sucesso!');
                                    });
                                  }),
                                ],
                                bsSize: 'medium',
                                onHide: (dialog) => {
                                  dialog.hide()
                                }
                              })
                            }
                          }}
                        >Remover o Polígono</Button>
                        {this.state.polysPaths.map((p, i) => {
                          return (
                            <Polygon
                              ref={"poly" + i}
                              key={i}
                              editable
                              draggable
                              defaultPath={p.path}
                            />
                          );
                        })
                        }
                        <Marker
                          draggable
                          position={{ lat: this.state.t61_lat, lng: this.state.t61_lon }}
                          onDrag={(marker) => { this.setState({ t61_lat: marker.latLng.lat(), t61_lon: marker.latLng.lng() }) }}
                        />

                        {this.state.proximos.map((p, i) => {
                          if (p.ativo) {
                            return (
                              <Polygon
                                options={{
                                  strokeColor: '#6ca3fc',
                                  fillColor: '#4286f4',
                                }}
                                key={i}
                                defaultPath={p.path[0].path}
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                      </GoogleMapsWrapper>
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: 10 }}>
                  {this.state.novo &&
                    <div>
                      <Button type="submit" style={{ marginRight: 10 }}>Criar</Button>
                      <Button onClick={this.modalHide.bind(this)}>Cancelar</Button>
                    </div>
                  }
                  {this.state.edit &&
                    <div>
                      <Button type="submit" style={{ marginRight: 10 }}>Salvar</Button>
                      <Button onClick={this.modalHide.bind(this)}>Cancelar</Button>
                    </div>
                  }
                </Row>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div >
    );
  }
}

export default Referencias;
