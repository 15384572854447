import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';
const md5 = require('md5');

export default class ValoresCentral extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: [],
      suggestions: [],
      record:{}
    }
    this.ws = new WS();
    this.fetchData = this.fetchData.bind(this)

    this.calcValor_liquido = this.calcValor_liquido.bind(this)
    this.calcValor_imposto = this.calcValor_imposto.bind(this)
    this.calcValor_Bruto = this.calcValor_Bruto.bind(this)
    this.calcValor_motorista = this.calcValor_motorista.bind(this)
    this.calcValor_central = this.calcValor_central.bind(this)
    this.formataValor = this.formataValor.bind(this)
  }

  async fetchData(nome) {
    // // show the loading overlay

    let result = await this.ws.getTipo('atuacaocidade', '', {id: 'nome'}, 1, 10, [{id: 'nome', value: nome}], null);
    let count = result.headers.get('X-Total-Count');
    result = await result.json();

    this.setState({
      suggestions: result
    })
  }

  mirrorState(key) {
    this.setState(key);
  }
  novoId() {
    let valor = Math.random();
    let id =  md5(valor);
    return id.toString().substring(0,32);
  }

  calcValor_liquido(km, min, valores) {
    let servico = valores ? valores.servico : 0
    let retorno_municipal_percent = valores ? valores.retorno_municipal_percent / 100 : 0
    let hora_parada_fixo = valores ? valores.hora_parada_fixo : 0

    let base = valores ? valores.band_b1 : 0
    let time = valores ? valores.km_b2 : 0

    let kmlimit1 = valores ? valores.kmlimit1 : 0
    let kmlimit2 = valores ? valores.kmlimit2 : 0
    let kmlimit3 = valores ? valores.kmlimit3 : 0

    let kmvalor = valores ? valores.km_b1 : 0
    let kmvalor1 = valores ? valores.kmvalor1 : 0
    let kmvalor2 = valores ? valores.kmvalor2 : 0
    let kmvalor3 = valores ? valores.kmvalor3 : 0

    let valorminimo = valores ? valores.valorminimo : 0

    let dist = 0
    if (km < kmlimit1) { dist = kmvalor1}
    else if (km < kmlimit2) { dist = kmvalor2}
    else if (km < kmlimit3) { dist = kmvalor3}
    else { dist = kmvalor}

    let valor_calc = base + dist * km + time * min

    if (servico > 1) {
      valor_calc += (dist * km + time * min) * (retorno_municipal_percent)
    }

    if (servico > 2) {
      valor_calc += hora_parada_fixo
    }

    let valorUsar = valorminimo > valor_calc ? valorminimo : valor_calc

    return valorUsar
  }


  calcValor_motorista(km, min, valores) {
    let valor_liquido = this.calcValor_liquido(km, min, valores)
    let valor_central = this.calcValor_central(km, min, valores)


    return valor_liquido - valor_central
  }

  calcValor_central(km, min, valores) {
    let valor_liquido = this.calcValor_liquido(km, min, valores)
    let modalidade_cobranca = valores ? valores.modalidade_cobranca : 0



    let valor_inicial_mot = valores ? valores.valor_inicial_mot : 0
    let valor_km_mot = valores ? valores.valor_km_mot : 0
    let valor_mim_mot = valores ? valores.valor_mim_mot : 0

    let creditopercentuallimite1 = valores ? valores.creditopercentuallimite : 0
    let creditopercentual1 = valores ? valores.creditopercentual >= 0 ? valores.creditopercentual / 100 : valores.creditopercentual : 0
    let creditovalor1 = valores ? valores.creditovalor : 0
    let creditopercentuallimite2 = valores ? valores.creditopercentuallimite2 : 0
    let creditopercentual2 = valores ? valores.creditopercentual2 >= 0 ? valores.creditopercentual2 / 100 : valores.creditopercentual2 : 0
    let creditovalor2 = valores ? valores.creditovalor2 : 0
    let creditopercentuallimite3 = valores ? valores.creditopercentuallimite3 : 0
    let creditopercentual3 = valores ? valores.creditopercentual3 >= 0 ? valores.creditopercentual3 / 100 : valores.creditopercentual3 : 0
    let creditovalor3 = valores ? valores.creditovalor3 : 0
    let creditopercentual4 = valores ? valores.creditopercentual4 >= 0 ? valores.creditopercentual4 / 100 : valores.creditopercentual4 : 0
    let creditovalor4 = valores ? valores.creditovalor4 : 0
    let creditosporcorrida = valores ? valores.creditosporcorrida : 0

    let valor_central = 0

    if (modalidade_cobranca === 0 ) {
      if (valor_liquido < creditopercentuallimite1) {valor_central = valor_liquido * creditopercentual1 + creditovalor1}
      else if (valor_liquido < creditopercentuallimite2) {valor_central = valor_liquido * creditopercentual2 + creditovalor2}
      else if (valor_liquido < creditopercentuallimite3) {valor_central = valor_liquido * creditopercentual3 + creditovalor3}
      else {valor_central = valor_liquido * creditopercentual4 + creditovalor4}
    } else {
      let valor_mot = valor_inicial_mot + valor_km_mot * km
      if (valor_mot < valor_mim_mot) {valor_mot = valor_mim_mot}
      valor_central = valor_liquido - valor_mot
    }


    return valor_central
  }


  formataValor(valor) {
    if (!valor) {return "R$ 0,00"}

    return "R$ " + valor.toFixed(2).toString().replace(".",",")
  }

  calcValor_Bruto(km, min, valores) {
    let valor_liquido = this.calcValor_liquido(km, min, valores)
    let valor_imposto = this.calcValor_imposto(km, min, valores)

    return valor_liquido + valor_imposto
  }

  calcValor_imposto(km, min, valores) {
    let valor_liquido = this.calcValor_liquido(km, min, valores)

    let taxaextraplataforma = valores ? valores.taxaextraplataforma : 0
    let taxaextracentral = valores ? valores.taxaextracentral : 0
    let taxaextraplataformapercent = valores ? valores.taxaextraplataformapercent >= 0 ? valores.taxaextraplataformapercent / 100 : valores.taxaextraplataformapercent : 0
    let taxaextracentralpercent = valores ? valores.taxaextracentralpercent >= 0 ? valores.taxaextracentralpercent / 100 : valores.taxaextracentralpercent : 0

    let percentoimposto = valores ? valores.percentoimposto >= 0 ? valores.percentoimposto / 100 : valores.percentoimposto : 0
    let taxavalor = valores ? valores.taxavalor : 0
    let taxapercentual = valores ? valores.taxapercentual >= 0 ? valores.taxapercentual / 100 : valores.taxapercentual : 0


    let valorImposto = (valor_liquido + taxaextraplataforma + taxaextracentral + taxavalor) / (1 - taxaextraplataformapercent - taxaextracentralpercent - percentoimposto - taxapercentual)



    return valorImposto - valor_liquido


    
  }

  async motoristas(p, after) {
    alert("Aguarde Processamento!")
    console.log('precadastro', p)

    
    alert("Processamento concluido!")
    if (after) {
      after();
    }
    //console.log('motoristas 2', p.motorista)
  }
  render() {
    let percent_franquia = this.props.childProps.centralData.percent_franquia
    let extra_franquia = this.props.childProps.centralData.extra_franquia
    let administrador = this.props.childProps.userAttrib.admin
    return (
      <>
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Valor da Central",
          tipo_plural: "Valores da Central",
          href: "centralvalores",
          titulo: "Valores da Central",
          larguralabel: 100,
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'centralvalores',
            list: true,
            save: true,
            new: true,
            edit: true,
            delete: false,
            listSub: true,
            defaultorder: [{ id: "cidade" }, { id: "sigla" }],
          },
          autocomplete: {
            func: this.fetchData,
            suggestions: this.state.suggestions,
          },
          subtipos: [
            {tipo: 'siglas',p:'oarray', array:[{ txt: 'CNL', id: 'cnl' }, { txt: 'PRM', id: 'prm' },{ txt: 'C15', id: 'c15' }, { txt: 'MOT', id: 'mot' }, { txt: 'CDO', id: 'cdo' }, { txt: 'POP', id: 'pop' }, { txt: 'LDY', id: 'ldy' }, { txt: 'VET', id: 'vet' }]},
            {tipo: 'status',p:'oarray', array:[{ txt: 'Ativo', id: true }, { txt: 'Inativo', id: false }]},
            {tipo: 'simnao',p:'oarray', array:[{ txt: 'Sim', id: true }, { txt: 'Não', id: false }]},
            {tipo: 'atuacaocidade', id: 'nome', txt: 'nome', p:'get'},
            {tipo: 'legendatipo', id: 'tipo', txt: 'descricao', p:'get'},
            {tipo: 'tiposervico',p:'oarray', array:[{ txt: 'Entrega', id: 1 }, { txt: 'Transporte', id: 0 }]},
            {tipo: 'servico',p:'oarray', array:[{ txt: 'N/A', id: 0 }, { txt: 'Entrega', id: 1 }, {txt: 'Entrega e Retorno', id: 2}, {txt: 'Serviço Documental', id: 3}]},
            {tipo: 'modalidade_cobranca',p:'oarray', array:[{ txt: 'por parcela de valor', id: 0 }, { txt: 'por valor minimo', id: 1 }]},
          ],
          organizado: [
            [{ref: 'linha', tipo: 'hr'},],
            [{ref: 'tiposervico', tipo: 'campo'},{ref: 'servico', tipo: 'campo'}],
            [{ref: 'ativo', tipo: 'campo'},{ref: 'cidade', tipo: 'campo'},],
            [{ref: 'sigla', tipo: 'campo'},{ref: 'nome', tipo: 'campo'}],
            [{ref: 'linha', tipo: 'hr'},],
            [{ref: 'valores', tipo: 'texto', texto:'Valores'}],
            [
              {ref: 'band_b1', tipo: 'campo'},
              {ref: 'km_b2', tipo: 'campo'},
            ],
            [{ref: 'kms', tipo: 'texto', texto:'Valor por km'}],
            [
              {ref: 'ident', tipo: 'texto', texto:'Corridas até'},
              { ref: "kmlimit1", tipo: 'campo', hidelabel: true},            
              {ref: 'ident', tipo: 'texto', texto:'cobrar'},  
              { ref: "kmvalor1", tipo: 'campo', hidelabel: true,},
              {ref: 'ident', tipo: 'texto', texto:'por km'},
            ],
            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até'},
              { ref: "kmlimit2", tipo: 'campo', hidelabel: true},
              {ref: 'ident', tipo: 'texto', texto:'cobrar'},
              { ref: "kmvalor2", tipo: 'campo', hidelabel: true},
              {ref: 'ident', tipo: 'texto', texto:'por km'},
            ],
            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até'},
              { ref: "kmlimit3", tipo: 'campo', hidelabel: true},
              {ref: 'ident', tipo: 'texto', texto:'cobrar'},
              { ref: "kmvalor3", tipo: 'campo', hidelabel: true},
              {ref: 'ident', tipo: 'texto', texto:'por km'},
            ],
            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até'},
              { ref: "kmlimit5", tipo: 'campo', hidelabel: true},
              {ref: 'ident', tipo: 'texto', texto:'cobrar'},
              { ref: "kmvalor5", tipo: 'campo', hidelabel: true},
              {ref: 'ident', tipo: 'texto', texto:'por km'},
            ],
            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até'},
              { ref: "kmlimit6", tipo: 'campo', hidelabel: true},
              {ref: 'ident', tipo: 'texto', texto:'cobrar'},
              { ref: "kmvalor6", tipo: 'campo', hidelabel: true},
              {ref: 'ident', tipo: 'texto', texto:'por km'},
            ],
            [ 
              {ref: 'ident', tipo: 'texto', texto:'Acima disso cobrar'},
              { ref: "km_b1", tipo: 'campo', hidelabel: true},
              {ref: 'ident', tipo: 'texto', texto:'por km'},
            ],
            [{ref: 'linha', tipo: 'hr'},],
            [ 
              { ref: "retorno_municipal_percent", tipo: 'campo'},
            ],
            [ 
              { ref: "retorno_metropolitano_percent", tipo: 'campo'},
            ],
            [ 
              { ref: "hora_parada_fixo", tipo: 'campo'},
              { ref: "valor_acrescimo_trecho", tipo: 'campo'},
            ],
            [ 
              { ref: "valorminimo", tipo: 'campo'},
            ],
            [{ref: 'linha', tipo: 'hr'},],

            [{ref: 'imposto', tipo: 'texto', texto:'Impostos'}],
            [
              { ref: "percentoimposto", tipo: 'campo' },
              { ref: "taxaextraplataforma", tipo: 'campo' },
              { ref: "taxaextraplataformapercent", tipo: 'campo'},
            ],
            [{ref: 'linha', tipo: 'hr'},],

            [{ref: 'taxa', tipo: 'texto', texto:'Taxas'}],
            [{ref: 'linha', tipo: 'hr'},],

            [
              { ref: "taxapercentual", tipo: 'campo' },
              { ref: "taxavalor", tipo: 'campo'},
            ],
            [ 
              { ref: "taxapercentual_dinheiro", tipo: 'campo' },
              { ref: "taxavalor_dinheiro", tipo: 'campo' },
            ],
            [ 
              { ref: "taxapercentual_voucher", tipo: 'campo' },
              { ref: "taxavalor_voucher", tipo: 'campo' },
            ],
            [ 
              { ref: "taxapercentual_debito", tipo: 'campo' },
              { ref: "taxavalor_debito", tipo: 'campo' },
            ],
            [{ref: 'linha', tipo: 'hr'},],
            [{ref: 'descontos', tipo: 'texto', texto:'Descontos'}],
            [
              { ref: "desconto", tipo: 'campo'},
              { ref: "desconto_ativo", tipo: 'campo'},
            ],
            [{ref: 'linha', tipo: 'hr'},],
            [{ref: 'creditos', tipo: 'texto', texto:'Cobrar do Piloto/Motorista'}],
            [ 
              { ref: "modalidade_cobranca", tipo: 'campo', hidelabel: false},
            ],
            [
              {ref: 'ident', tipo: 'texto', texto:'Corridas até', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditopercentuallimite", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},            
              {ref: 'ident', tipo: 'texto', texto:'cobrar', hide: this.state.record.modalidade_cobranca === 1},  
              { ref: "creditopercentual", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
              {ref: 'ident', tipo: 'texto', texto:'do valor mais ', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditovalor", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
            ],
            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditopercentuallimite2", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
              {ref: 'ident', tipo: 'texto', texto:'cobrar', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditopercentual2", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
              {ref: 'ident', tipo: 'texto', texto:'do valor mais ', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditovalor2", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
            ],



            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditopercentuallimite3", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
              {ref: 'ident', tipo: 'texto', texto:'cobrar', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditopercentual3", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
              {ref: 'ident', tipo: 'texto', texto:'do valor mais ', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditovalor3", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
            ],



            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditopercentuallimite5", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
              {ref: 'ident', tipo: 'texto', texto:'cobrar', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditopercentual5", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
              {ref: 'ident', tipo: 'texto', texto:'do valor mais ', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditovalor5", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
            ],
            [ 
              {ref: 'ident', tipo: 'texto', texto:'Acima disso cobrar', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditopercentual4", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
              {ref: 'ident', tipo: 'texto', texto:'do valor mais ', hide: this.state.record.modalidade_cobranca === 1},
              { ref: "creditovalor4", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 1},
            ],

            [ 
              { ref: "valor_inicial_mot", tipo: 'campo', hidelabel: false, hide: this.state.record.modalidade_cobranca === 0},
              { ref: "valor_mim_mot", tipo: 'campo', hidelabel: false, hide: this.state.record.modalidade_cobranca === 0},
            ],


            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até', hide: this.state.record.modalidade_cobranca === 0},
              { ref: "mot_kmlimit1", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 0},
              {ref: 'ident', tipo: 'texto', texto:'pagar', hide: this.state.record.modalidade_cobranca === 0},
              { ref: "mot_kmvalor1", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 0},
              {ref: 'ident', tipo: 'texto', texto:'por km', hide: this.state.record.modalidade_cobranca === 0},
            ],


            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até', hide: this.state.record.modalidade_cobranca === 0},
              { ref: "mot_kmlimit2", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 0},
              {ref: 'ident', tipo: 'texto', texto:'pagar', hide: this.state.record.modalidade_cobranca === 0},
              { ref: "mot_kmvalor2", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 0},
              {ref: 'ident', tipo: 'texto', texto:'por km', hide: this.state.record.modalidade_cobranca === 0},
            ],


            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até', hide: this.state.record.modalidade_cobranca === 0},
              { ref: "mot_kmlimit3", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 0},
              {ref: 'ident', tipo: 'texto', texto:'pagar', hide: this.state.record.modalidade_cobranca === 0},
              { ref: "mot_kmvalor3", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 0},
              {ref: 'ident', tipo: 'texto', texto:'por km', hide: this.state.record.modalidade_cobranca === 0},
            ],


            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até', hide: this.state.record.modalidade_cobranca === 0},
              { ref: "mot_kmlimit4", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 0},
              {ref: 'ident', tipo: 'texto', texto:'pagar', hide: this.state.record.modalidade_cobranca === 0},
              { ref: "mot_kmvalor4", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 0},
              {ref: 'ident', tipo: 'texto', texto:'por km', hide: this.state.record.modalidade_cobranca === 0},
            ],


            [ 
              {ref: 'ident', tipo: 'texto', texto:'Corridas até', hide: this.state.record.modalidade_cobranca === 0},
              { ref: "mot_kmlimit5", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 0},
              {ref: 'ident', tipo: 'texto', texto:'pagar', hide: this.state.record.modalidade_cobranca === 0},
              { ref: "mot_kmvalor5", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 0},
              {ref: 'ident', tipo: 'texto', texto:'por km', hide: this.state.record.modalidade_cobranca === 0},
            ],

            [
              {ref: 'ident', tipo: 'texto', texto:'Acima disso pagar', hide: this.state.record.modalidade_cobranca === 0},
              { ref: "valor_km_mot", tipo: 'campo', hidelabel: true, hide: this.state.record.modalidade_cobranca === 0},
              {ref: 'ident', tipo: 'texto', texto:'por km', hide: this.state.record.modalidade_cobranca === 0},
            ],
            [{ref: 'linha', tipo: 'hr'},], 
            [
              {tipo: 'texto', texto:'Exemplo de Calculo'},
              {tipo: 'texto', texto:'Cobrar do Cliente'},
              {tipo: 'texto', texto:'Taxas da Plataforma'},
              {tipo: 'texto', texto:'Valor do Motorista'},
              {tipo: 'texto', texto:'Valor da Central'},
          ],
            [
              {tipo: 'texto', texto:'03km, 01 minutos'},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_Bruto(3,1,this.state.record))},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_imposto(3,1,this.state.record))},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_motorista(3,1,this.state.record))},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_central(3,1,this.state.record))}
            ],
            [
              {tipo: 'texto', texto:'10km, 03 minutos'},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_Bruto(10,3,this.state.record))},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_imposto(10,3,this.state.record))},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_motorista(10,3,this.state.record))},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_central(10,3,this.state.record))}
            ],
            [
              {tipo: 'texto', texto:'25km, 10 minutos'},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_Bruto(25,10,this.state.record))},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_imposto(25,10,this.state.record))},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_motorista(25,10,this.state.record))},
              {tipo: 'texto', texto: this.formataValor(this.calcValor_central(25,10,this.state.record))}
            ],
            [{ref: 'linha', tipo: 'hr'},], 

          ],
          campos: [
            { ref: "id", label: "id", type: "text", newvalue:() => {return this.novoId()}},
            { ref: "band_b1", label: "Valor Inicial", type: "currency", default: 1, },
            { ref: "band_b2", label: "band_b2", type: "currency", default: 0, },
            { ref: "km_b1", label: "Valor por Km", type: "currency", default: 2, },
            { ref: "km_b2", label: "Valor por Minuto", type: "currency", default: 0.1, },
            { ref: "desconto", label: "Desconto", type: "percentage", default: 0},
            { ref: "desconto_ativo", label: "Desconto Ativo?", type: "select", listtype:'simnao',default: false},
            { ref: "valorminimo", label: "Valor mínimo por corrida", type: "currency", default: 10, },
            { ref: "centraltaxi_id", label: "centraltaxi_id", type: "integer", default: 10, },
            { ref: "sigla", label: "Tipo App", type: "select", listtype:'legendatipo', default: 'cnl', },
            { ref: "ativo", label: "Ativo", type: "select", listtype:'simnao', default: true, },
            { ref: "tiposervico", label: "Tipo", type: "select", listtype:'tiposervico', default: 0, },
            { ref: "servico", label: "Serviço", type: "select", listtype:'servico', default: 0, },

            { ref: "nome", label: "Nome", type: "text", default: 'Novo', },
            { ref: "percentoimposto", label: "Imposto", type: "percentage", default: 0},
            
            //{ ref: "tipocorrida_id", label: "tipocorrida_id", type: "hidden", default: 0, },
            { ref: "hora_parada_fixo", label: "Taxa para serviços especiais", type: "currency", default:10, },
            { ref: "retorno_municipal_fixo", label: "Taxa para retorno ao remetente", type: "currency", default: 0, },
            { ref: "retorno_municipal_percent", label: "Taxa em % para retorno ao remetente", type: "percentage", default: 0, },
            { ref: "retorno_metropolitano_fixo", label: "Taxa para transporte inter-municipal", type: "currency", default: 0},
            { ref: "retorno_metropolitano_percent", label: "Taxa em % para transporte inter-municipal", type: "percentage", default: 0},
            { ref: "valor_minimo_trecho", label: "Valor mínimo por trecho", type: "currency", default: 0},
            { ref: "valor_acrescimo_trecho", label: "Valor de acrescimo por trecho extra", type: "currency", default: 0},
            { ref: "cidade", label: "Cidade", type: "AutoCompleteCity"},
            { ref: "creditosporcorrida", label: "creditosporcorrida", type: "currency", default: 0},
            { ref: "creditopercentual", label: "creditopercentual", type: "percentage", default: 0},
            { ref: "creditopercentuallimite", label: "creditopercentuallimite", type: "currency", default: 10000},
            { ref: "creditopercentual_faixa", label: "creditopercentual_faixa", type: "percentage", default: 0},
            { ref: "creditopercentuallimite_faixa", label: "creditopercentuallimite_faixa", type: "currency", default: 0},
            { ref: "creditovalor", label: "creditovalor", type: "currency", default: 0 },
            { ref: "creditopercentuallimite2", label: "creditopercentuallimite2", type: "currency", default: 0},
            { ref: "creditopercentual2", label: "creditopercentual2", type: "percentage", default: 0},
            { ref: "creditovalor2", label: "creditovalor2", type: "currency", default: 0},
            { ref: "creditopercentuallimite3", label: "creditopercentuallimite3", type: "currency", default: 0},
            { ref: "creditopercentual3", label: "creditopercentual3", type: "percentage", default: 0},
            { ref: "creditovalor3", label: "creditovalor3", type: "currency", default: 0},
            { ref: "creditopercentuallimite5", label: "creditopercentuallimite3", type: "currency", default: 0},
            { ref: "creditopercentual5", label: "creditopercentual3", type: "percentage", default: 0},
            { ref: "creditovalor5", label: "creditovalor3", type: "currency", default: 0},
            { ref: "creditopercentual4", label: "creditopercentual4", type: "percentage", default: 0},
            { ref: "creditovalor4", label: "creditovalor4", type: "currency", default: 0},
            { ref: "taxavalor", label: "Taxa em R$ para cobrança no APP", type: "currency", default: 0},
            { ref: "taxapercentual", label: "Taxa em % do cobrança no APP", type: "percentage", default: 0},
            { ref: "taxadestino", label: "taxadestino", type: "percentage", default: 0},
            { ref: "taxacomissao_mot", label: "% do valor para quem indicou o Motorista", type: "percentage", default: 0},
            { ref: "taxacomissao_convenio", label: "% do valor para quem indicou o convênio", type: "percentage", default: 0},
            { ref: "taxapercentual_dinheiro", label: "Taxa em % do valor para cobrança em Dinheiro", type: "percentage", default: 0},
            { ref: "taxavalor_dinheiro", label: "Taxa em R$ para cobrança em Dinheiro", type: "currency", default: 0},
            { ref: "taxapercentual_voucher", label: "Taxa em % do valor para cobrança de Convênios", type: "percentage", default: 0},
            { ref: "taxavalor_voucher", label: "Taxa em R$ para cobrança em Voucher", type: "currency", default: 0},
            { ref: "taxapercentual_debito", label: "Taxa em % do PIX", type: "percentage", default: 0},
            { ref: "taxavalor_debito", label: "Taxa em R$ do PIX", type: "currency", default: 0},
            { ref: "caridade", label: "Porcentagem para a caridade", type: "percentage", default: 0},
            { ref: "taxaextraplataforma", label: "Taxa da plataforma", type: "currencydisabled", default: extra_franquia, },
            { ref: "taxaextracentral", type: "currency", default: 0, },
            { ref: "taxaextraplataformapercent", label: "Taxa (%) da plataforma", type: "percentagedisabled", default: percent_franquia, },
            { ref: "taxaextracentralpercent", type: "percentage", default: 0, },
            { ref: "kmlimit1", type: "km", default: 0, },
            { ref: "kmvalor1", type: "currency", default: 0, required: true},
            { ref: "kmlimit2", type: "km", default: 0, },
            { ref: "kmvalor2", type: "currency", default: 0, },
            { ref: "kmlimit3", type: "km", default: 0, },
            { ref: "kmvalor3", type: "currency", default: 0, },
            { ref: "kmlimit5", type: "km", default: 0, },
            { ref: "kmvalor5", type: "currency", default: 0, },
            { ref: "kmlimit6", type: "km", default: 0, },
            { ref: "kmvalor6", type: "currency", default: 0, },
            { ref: "mot_kmlimit1", type: "km", default: 0, },
            { ref: "mot_kmvalor1", type: "currency", default: 0, },
            { ref: "mot_kmlimit2", type: "km", default: 0, },
            { ref: "mot_kmvalor2", type: "currency", default: 0, },
            { ref: "mot_kmlimit3", type: "km", default: 0, },
            { ref: "mot_kmvalor3", type: "currency", default: 0, },
            { ref: "mot_kmlimit4", type: "km", default: 0, },
            { ref: "mot_kmvalor4", type: "currency", default: 0, },
            { ref: "mot_kmlimit5", type: "km", default: 0, },
            { ref: "mot_kmvalor5", type: "currency", default: 0, },




            { ref: "valor_inicial_mot", label: "Valor Inicial do Motorista", type: "currency", default: 0, },
            { ref: "valor_km_mot", label: "Valor por Km do Motorista", type: "currency", default: 0, },
            { ref: "valor_mim_mot", label: "Valor Minimo do Motorista", type: "currency", default: 0, },
            { ref: "modalidade_cobranca", label: "Modalidade de cobrança da Corrida/Motorista", type: "select", listtype:'modalidade_cobranca', default: 0, },
          ],
          table: {
            colunas: [
              { Header: "Codade de Atuação", accessor: "cidade", },
              { Header: "Nome", accessor: "nome", },
              { Header: "Tipo App", accessor: "sigla", 
              filterMethod: (filter, row) => {
                if (filter.value === "") {
                  return true;
                }
                return filter.value === row[filter.id];
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                >
                  <option value="">Todos</option>
                  {this.state.sublistas.legendatipo && this.state.sublistas.legendatipo.map( (a,b) => {
                    return (
                      <option value={a.id}>{a.text}</option>
                    )
                  })}

                </select>),
              Cell: row => {
                return (
                  <div>
                    {this.state.sublistas.legendatipo && this.state.sublistas.legendatipo.find(element => element.id === row.original.sigla ) &&
                    this.state.sublistas.legendatipo.find(element => element.id === row.original.sigla ).text
                    }
                  </div>
                )
              }},
              { Header: 'Tipo', accessor: 'tiposervico', 
              filterMethod: (filter, row) => {
                if (filter.value === "") {
                  return true;
                }
                return filter.value === row[filter.id];
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                >
                  <option value="">Todos</option>
                  {this.state.sublistas.tiposervico && this.state.sublistas.tiposervico.map( (a,b) => {
                    return (
                      <option value={a.id}>{a.text}</option>
                    )
                  })}

                </select>),
              Cell: row => {
                return (
                  <div>
                    {this.state.sublistas.tiposervico && this.state.sublistas.tiposervico.find(element => element.id === row.original.tiposervico ) &&
                    this.state.sublistas.tiposervico.find(element => element.id === row.original.tiposervico ).text
                    }
                  </div>
                )
              }},
              { Header: 'Serviço', accessor: 'servico', 
              filterMethod: (filter, row) => {
                if (filter.value === "") {
                  return true;
                }
                return filter.value === row[filter.id];
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                >
                  <option value="">Todos</option>
                  {this.state.sublistas.servico && this.state.sublistas.servico.map( (a,b) => {
                    return (
                      <option value={a.id}>{a.text}</option>
                    )
                  })}

                </select>),
              Cell: row => {
                return (
                  <div>
                    {this.state.sublistas.servico && this.state.sublistas.servico.find(element => element.id === row.original.servico ) &&
                    this.state.sublistas.servico.find(element => element.id === row.original.servico ).text
                    }
                  </div>
                )
              }},
              { Header: "Ativo", accessor: "ativo", 
              filterMethod: (filter, row) => {
                if (filter.value === "") {
                  return true;
                }
                if (filter.value === "true") {
                  return row[filter.id];
                }
                return !row[filter.id];
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                >
                  <option value="">Todos</option>
                  <option value="true">Ativado</option>
                  <option value="false">Desativado</option>
                </select>),
              Cell: row => (
                <div
                  style={{
                    width: '16%',
                    height: '76%',
                    margin: '0 auto',
                    backgroundColor: row.original.ativo ? 'green' : 'red',
                    borderRadius: '2px'
                  }}
                />
              )
            },
            ],
          }
        }}
      /></>
    );
  }
}
