import React, { Component } from 'react';
import Dialog from 'react-bootstrap-dialog-v2'
import ReactTable from "react-table";
import "react-table/react-table.css";
import AutoComplete from './AutoComplete.js';
import CurrencyInput from 'react-currency-input';
import { GoogleMap, withGoogleMap, withScriptjs, Polygon } from 'react-google-maps';
import { fitBounds } from 'google-map-react/utils';

import WS from '../services/webservice';
import './Solicitacoes.css';
import { Button, Row, Col, Breadcrumb, Image, Modal, Form, Checkbox, ControlLabel, FormControl, FormGroup } from 'react-bootstrap'

const GoogleMapsWrapper = withScriptjs(withGoogleMap(props => {
  const { onMapMounted, ...otherProps } = props;
  return (
    <GoogleMap onTilesLoaded={props.onTilesLoaded} {...otherProps} ref={c => { onMapMounted && onMapMounted(c) }}  >
      {props.children}
    </GoogleMap>
  );
}));

class Tabelamento extends Component {

  static defaultProps = {
    center: { lat: -25.475015, lng: -49.2911143 },
    zoom: 17,
    centerD: { lat: -25.475015, lng: -49.2911143 },
    zoomD: 17
  };

  constructor(props) {
    super(props);
    this.state = {
      valor: 0,
      address: [],
      local: {
        city: '',
        shortAddress: '',
        fullAddress: ''
      },
      local_text: '',
      destiny_text: '',
      destiny: {
        city: '',
        shortAddress: '',
        fullAddress: ''
      },
      center: { lat: -25.475015, lng: -49.2911143 },
      zoom: 15,
      centerD: { lat: -25.475015, lng: -49.2911143 },
      zoomD: 15,
      modal: false,
      novo: false,
      edit: false,
      list: [],
      pages: null,
      loading: true,
      nome: '',
      ativo: true,
      origemPolysPaths: [],
      destinoPolysPaths: [],
    }

    this.webservice = new WS();

    this.novo = this.novo.bind(this);
    this.edit = this.edit.bind(this);
    this.remove = this.remove.bind(this);
    this.submit = this.submit.bind(this);
    this.getList = this.getList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
  }

  modalHide() {
    this.props.history.push("/tabelamento");
    this.setState({ modal: false, novo: false, edit: false, id: null });
  }

  novo() {
    this.setState({
      valor: 0,
      modal: true,
      novo: true,
      address: [],
      local: {
        city: '',
        shortAddress: '',
        fullAddress: ''
      },
      local_text: '',
      destiny_text: '',
      destiny: {
        city: '',
        shortAddress: '',
        fullAddress: ''
      },
      center: { lat: -25.475015, lng: -49.2911143 },
      zoom: 15,
      centerD: { lat: -25.475015, lng: -49.2911143 },
      zoomD: 15,
      nome: '',
      local_text: '',
      destiny_text: '',
      ativo: true,
      origemPolysPaths: [],
      destinoPolysPaths: [],
    });
  }
 setCenter(field) {
   if (field.length > 1) {
   const bounds = {
     ne: { lat: field[0][0], lng: field[0][1] },
     sw: { lat: field[0][0], lng: field[0][1] },
   }
      for (let i = 0; i < field.length; i++) {
        let latA = field[i][0];
        let lngA = field[i][1];
         if (latA > bounds.ne.lat) {
           bounds.ne.lat = latA;
         }
         if (lngA > bounds.ne.lng) {
           bounds.ne.lng = lngA;
         }
         if (latA < bounds.sw.lat) {
           bounds.sw.lat = latA;
         }
         if (lngA < bounds.sw.lng) {
           bounds.sw.lng = lngA;
         }
       }

       const size = {
         width: 500, // Map width in pixels
         height: 110, // Map height in pixels
       };

       const { center } = fitBounds(bounds, size)

       return center;
     } else {
       return { lat: -25.475015, lng: -49.2911143 };
     }
 }
  edit(data) {
    let origem = [{ path: [] }];
    let destino = [{ path: [] }];
    let center = { lat: -25.475015, lng: -49.2911143 };
    let centerD =  { lat: -25.475015, lng: -49.2911143 };
    if (data.original.origem) {
      center = this.setCenter(data.original.origem);
      for (let i = 0; i < data.original.origem.length; i++) {
        origem[0].path.push(
          {
            lat: data.original.origem[i][0],
            lng: data.original.origem[i][1]
          },
        );
      }
    }
    if (data.original.destino) {
      centerD = this.setCenter(data.original.destino);
      for (let i = 0; i < data.original.destino.length; i++) {
        destino[0].path.push(
          {
            lat: data.original.destino[i][0],
            lng: data.original.destino[i][1]
          },
        );
      }
    }

    console.log('ORIGINAL', data.original);

    this.setState({
      valor: data.original.valor,
      origemPolysPaths: origem,
      destinoPolysPaths: destino,
      modal: true,
      edit: true,
      id: data.original.id,
      nome: data.original.nome,
      ativo: data.original.ativo,
      local_text: data.original.origem_endereco,
      destiny_text: data.original.destino_endereco,
      center: center,
      centerD: centerD,
    });
  }

  remove(data) {
    Dialog.setOptions({
      defaultOkLabel: 'Sim',
      defaultCancelLabel: 'Não',
      primaryClassName: 'btn-danger '
    })
    this.dialog.show({
      body: 'Você tem certeza que deseja deletar??',
      actions: [
        Dialog.CancelAction(() => { }),
        Dialog.OKAction(() => {
          this.webservice.deleteTipo('tabelamento', data.original['id']).then(result => {
            Dialog.resetOptions();
            if (result.ok) {
              this.dialog.showAlert('Referência deletada com sucesso!')
              this.getList();
            } else {
              this.dialog.showAlert('Erro ao deletar a referência! Informe os desenvolvedores!')
            }
          })
        }
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  getList() {
    this.setState({
      dados: { ...this.state.dados, page: this.state.dados.page - 1 },
      modal: false,
      novo: false,
      edit: false
    });
    this.fetchData(this.state.dados, this.state.instance);
  }

  async fetchData(dados, instance) {
    // show the loading overlay
    this.setState({ loading: true, dados: dados, instance: instance })
    // fetch your data
    let order = dados.sorted;
    let result = await this.webservice.getTipo('tabelamento', '', order, ++dados.page, dados.pageSize, dados.filtered, null);
    let count = result.headers.get('X-Total-Count');
    result = await result.json();

    this.setState({
      list: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false
    })
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => { this.fetchData(dados, instance) }, 300)
    });
  }

  submit(e) {
    e.preventDefault();
    let s = this.state;
    let origemPolyPaths = [];
    let destinoPolyPaths = [];

    for (let i = 0; i < s.origemPolysPaths.length; i++) {
      let tempPaths = [];
      this.refs['origemPoly' + i].getPath().forEach((latlng, index) => {
        tempPaths.push([latlng.lat(), latlng.lng()]);
      })

      origemPolyPaths = tempPaths;
    }

    for (let i = 0; i < s.destinoPolysPaths.length; i++) {
      let tempPaths = [];
      this.refs['destinoPoly' + i].getPath().forEach((latlng, index) => {
        tempPaths.push([latlng.lat(), latlng.lng()]);
      })

      destinoPolyPaths = tempPaths;
    }

    if (s.nome === '') {
      Dialog.resetOptions();
      this.dialog.showAlert("Por favor preencha o nome!");
      return;
    }
    if (s.latitude === 0) {
      Dialog.resetOptions();
      this.dialog.showAlert("Por favor preencha o endereço!");
      return;
    }
    if (s.longitude === 0) {
      Dialog.resetOptions();
      this.dialog.showAlert("Por favor preencha o endereço!");
      return;
    }

    let dados = {
      central: parseInt(this.webservice.getCookie('central-id'), 10),
      nome: this.state.nome,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      origem: origemPolyPaths,
      destino: destinoPolyPaths,
      origem_endereco: this.state.local_text,
      destino_endereco: this.state.destiny_text,
      ativo: this.state.ativo,
      valor: this.state.valor,
    }
    

    if (this.state.novo) {
      this.webservice.criarTipo('tabelamento', dados)
        .then(result => {
          Dialog.resetOptions();
          if (result.ok) {
            this.getList();
            this.dialog.showAlert('Referência criada com sucesso!')
          } else {
            this.dialog.showAlert('Erro ao criar a referência! Verifique os dados digitados!')
          }
        })
    } else if (this.state.edit) {
      this.webservice.sendalterarTipo('tabelamento', this.state.id, dados, [])
        .then(result => {
          Dialog.resetOptions();
          if (result.ok) {
            this.getList();
            this.dialog.showAlert('Referência alterada com sucesso!')
          } else {
            this.dialog.showAlert('Erro ao alterar a referência! Verifique os dados alterados!')
          }
        })
    }
  }

  changeAddress(newAddress, addressType) {
    this.setState({ address: newAddress });
  }

  changeEnderecoOrigem(newAddress, newCity, estado, bairro, addressType, number, obs, latlon, completo) {
    console.log('changeEnderecoOrigem', [newAddress, newCity, estado, bairro, addressType, number, obs, latlon, completo]);
    this.setState({ local: { shortAddress: newAddress, fullAddress:newAddress, city: newCity, latlon:latlon, completo: completo }, local_text: newAddress });
    
  }

   changeEnderecoDestino(newAddress, newCity, estado, bairro, addressType, number, obs, latlon, completo) {
     this.setState({ destiny: { shortAddress: newAddress, fullAddress:newAddress, city: newCity, latlon:latlon, completo: completo}, destiny_text: newAddress });
  }
  changeEnderecoOrigemExtra(newAddress) {
    this.setState({ local_text: newAddress });
  }
  changeEnderecoDestinoExtra(newAddress) {
    this.setState({ destiny_text: newAddress });
  }
  changeAutoCompleteRefs(a, b, c) { }

  onSuggestionSelectedOrigem(addressType, result) {
      let a = this.state.origemPolysPaths;
      console.log('onSuggestionSelectedOrigem',result);
      console.log('onSuggestionSelectedOrigem',a);
      a.push({
        path: [
          { lat: result.latitude - (result.latitude * 0.00007), lng: result.longitude + (result.longitude * 0.00005) },
          { lat: result.latitude - (result.latitude * 0.00007), lng: result.longitude - (result.longitude * 0.00005) },
          { lat: result.latitude + (result.latitude * 0.00007), lng: result.longitude - (result.longitude * 0.00005) },
          { lat: result.latitude + (result.latitude * 0.00007), lng: result.longitude + (result.longitude * 0.00005) },
        ]
      });

      let z = {
        origemPolysPaths: a,
        center: { lat: result.latitude, lng: result.longitude },
        longitude: result.longitude,
        latitude: result.latitude,
      }
      console.log('onSuggestionSelectedOrigem', z);
      this.setState(z)
      console.log('onSuggestionSelectedOrigem');
  }
onSuggestionSelectedDestino(addressType, result) {
    let a = this.state.destinoPolysPaths;
    a.push({
      path: [
        { lat: result.latitude - (result.latitude * 0.00007), lng: result.longitude + (result.longitude * 0.00005) },
        { lat: result.latitude - (result.latitude * 0.00007), lng: result.longitude - (result.longitude * 0.00005) },
        { lat: result.latitude + (result.latitude * 0.00007), lng: result.longitude - (result.longitude * 0.00005) },
        { lat: result.latitude + (result.latitude * 0.00007), lng: result.longitude + (result.longitude * 0.00005) },
      ]
    });
    this.setState({
      destinoPolysPaths: a,
      centerD: { lat: result.latitude, lng: result.longitude },
      longitude: result.longitude,
      latitude: result.latitude,
    })
}
  changeValidationOrigem(a, b, c) {
    if (c) {

    }
  }

  changeValidationDestino(a, b, c) {
    console.log('changeValidationDestino destiny',this.state.destiny)
    if (c) {

    }
  }

  validateAutoCompleteLocal(a) { }

  _mapRef = null;
    _mapRefD = null;
  _handleMapMounted = (c) => {
    if (!c || this._mapRef) return;
    this._mapRef = c;
  };
  _handleMapMountedD = (c) => {
    if (!c || this._mapRef) return;
    this._mapRefD = c;
  };
  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active href='referencias'>Referências</Breadcrumb.Item>
        </Breadcrumb>
        <Col md={12}>
          {(this.props.childProps.userAttrib.admin ||
            this.props.childProps.userAttrib.monitorcentral ||
            this.state.userAttrib.acesso['9fb9c59f-7bca-4649-a910-62100976239c']) &&
            <Button style={{ margin: '-10px 0 10px 0' }} onClick={() => this.novo()}>Novo</Button>
          }
          <ReactTable
            manual
            data={this.state.list}
            pages={this.state.pages}
            loading={this.state.loading}
            onFetchData={this.fetchDataTimeOut}
            previousText='Anterior'
            nextText='Próxima'
            loadingText='Carregando...'
            noDataText='Nenhum dado encontado'
            pageText='Página'
            ofText='de'
            rowsText='linhas'
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
            columns={[
              {
                columns: [
                  {
                    Header: 'Nome',
                    accessor: 'nome',
                  },
                  {
                    Header: 'Origem',
                    accessor: 'origem_endereco',
                  },
                  {
                    Header: 'Destino',
                    accessor: 'destino_endereco',
                  },
                  {
                    Header: "Ativo",
                    accessor: "ativo",
                    width: 100,
                    Cell: row => (
                      <div
                        style={{
                          width: '16%',
                          height: '76%',
                          margin: '0 auto',
                          backgroundColor: row.original.ativo ? 'green' : 'red',
                          borderRadius: '2px'
                        }}
                      />
                    ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return row[filter.id];
                      }
                      return !row[filter.id];
                    },
                    Filter: ({ filter, onChange }) =>
                      <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : ""}
                      >
                        <option value="">Todos</option>
                        <option value="true">Ativado</option>
                        <option value="false">Desativado</option>
                      </select>
                  },
                  {
                    Header: 'Ações',
                    width: 90,
                    filterable: false,
                    sortable: false,
                    style: {
                      cursor: 'pointer',
                    },
                    Cell: props =>
                      <div>
                        {(this.props.childProps.userAttrib.admin ||
                          this.props.childProps.userAttrib.monitorcentral ||
                          this.state.userAttrib.acesso['9fb9c59f-7bca-4649-a910-62100976239c']) &&
                          <Image src={require('../images/edit.png')} style={{ marginLeft: '10px' }}
                            onClick={() => { this.edit(props) }}></Image>
                        }
                        {(this.props.childProps.userAttrib.admin ||
                          this.props.childProps.userAttrib.monitorcentral ||
                          this.state.userAttrib.acesso['9fb9c59f-7bca-4649-a910-62100976239c']) &&
                          <Image src={require('../images/remove.png')} style={{ marginLeft: '10px' }}
                            onClick={() => { this.remove(props) }}></Image>
                        }
                      </div>
                  }
                ]
              },
            ]}
            defaultPageSize={5}
            className="-striped -highlight"
          />
        </Col>
        <Dialog ref={(el) => { this.dialog = el }} />
        <Modal
          keyboard
          show={this.state.modal}
          onHide={this.modalHide.bind(this)}
          container={this}
          aria-labelledby="contained-modal-title"
          dialogClassName="referencias-dialog-modal"
        >
          <Modal.Header>
            {this.state.novo && <Modal.Title>Criar Referência</Modal.Title>}
            {this.state.edit && <Modal.Title>Editar Referência</Modal.Title>}
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form onSubmit={e => this.submit(e)}
                onKeyPress={e => {
                  if (e.which === 13) {
                    e.preventDefault()
                    return false
                  }
                }}>
                <Row>
                  <Col xs={4} md={4}>
                    <FormGroup>
                      <ControlLabel>Nome</ControlLabel>
                      <FormControl
                        type="text"
                        value={this.state.nome}
                        onChange={e => { this.setState({ nome: e.target.value }) }}
                      />
                    </FormGroup>
                    <AutoComplete
                      address={this.state.address} changeAddress={this.changeAddress.bind(this)}
                      endereco={this.state.local_text} changeEndereco={this.changeEnderecoOrigem.bind(this)}
                      changeEnderecoExtra={this.changeEnderecoOrigemExtra.bind(this)}
                      validation={null} changeValidation={this.changeValidationOrigem.bind(this)}
                      changeAutoCompleteRefs={this.changeAutoCompleteRefs.bind(this)}
                      onSuggestionSelected={this.onSuggestionSelectedOrigem.bind(this)}
                      title="Origem*"
                      center={this.state.center}
                      addressType='local'
                      blur={this.validateAutoCompleteLocal.bind(this)}
                    />
                    <AutoComplete
                      address={this.state.address} changeAddress={this.changeAddress.bind(this)}
                      endereco={this.state.destiny_text} changeEndereco={this.changeEnderecoDestino.bind(this)}
                      changeEnderecoExtra={this.changeEnderecoDestinoExtra.bind(this)}
                      validation={null} changeValidation={this.changeValidationDestino.bind(this)}
                      changeAutoCompleteRefs={this.changeAutoCompleteRefs.bind(this)}
                      onSuggestionSelected={this.onSuggestionSelectedDestino.bind(this)}
                      title="Destino*"
                      center={this.state.centerD}
                      addressType='destiny'
                      blur={this.validateAutoCompleteLocal.bind(this)}
                    />
                    <FormGroup>
                      <ControlLabel>Valor</ControlLabel>
                      <CurrencyInput
                        className="form-control"
                        value={this.state.valor}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix="R$ "
                        onChangeEvent={(event, maskedvalue, floatvalue) => {
                          this.setState({ valor: floatvalue });
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Checkbox
                        style={{ margin: '0' }}
                        checked={this.state.ativo}
                        onChange={e => { this.setState({ ativo: e.target.checked }) }}>
                        <b>Ativado?</b>
                      </Checkbox>
                    </FormGroup>
                  </Col>
                  <Col xs={8} md={8}>
                    <div id="googleMapDivRefs" style={{ height: '70vh', width: '100%' }}>
                      <GoogleMapsWrapper
                        options={{ streetViewControl: false, mapTypeControl: false }}
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCJvlPMRSy6vltr9575qooDS8bNKRtXxbs&language=pt&region=br&v=3&libraries=drawing"
                        loadingElement={<div style={{ height: `50%` }} />}
                        containerElement={<div style={{ height: `50%` }} />}
                        mapElement={<div style={{ height: `50%` }} />}
                        onMapMounted={this._handleMapMounted}
                        center={this.state.center}
                        zoom={this.state.zoom}
                        edit={this.state.edit}
                      >
                        <Button
                          onClick={() => {
                            if (this.state.origemPolysPaths.length < 1 < 1) {
                              Dialog.resetOptions();
                              this.dialog.showAlert('Ainda não foi adicionado nenhum polígono!');
                            } else {
                              Dialog.setOptions({
                                defaultOkLabel: 'Sim',
                                defaultCancelLabel: 'Não',
                                primaryClassName: 'btn-danger '
                              })
                              this.dialog.show({
                                title: 'Deseja deletar os Polígonos?',
                                body: 'Esta ação não pode ser desfeita! Todos os dados serão apagados!',
                                actions: [
                                  Dialog.CancelAction(() => { }),
                                  Dialog.OKAction(() => {
                                    this.setState({ origemPolysPaths: [] }, () => {
                                      Dialog.resetOptions();
                                      this.dialog.showAlert('Os polígonos foram deletados com sucesso!');
                                    });
                                  }),
                                ],
                                bsSize: 'medium',
                                onHide: (dialog) => {
                                  dialog.hide()
                                }
                              })
                            }
                          }}
                        >Remover os Polígonos</Button>
                        {this.state.origemPolysPaths.map((p, i) => {
                          return (
                            <Polygon
                              ref={"origemPoly" + i}
                              key={i}
                              editable
                              draggable
                              defaultPath={p.path}
                            />
                          );
                        })
                        }
                        {/* <Marker
                          draggable
                          position={{ lat: this.state.latitude, lng: this.state.longitude }}
                          onDrag={(marker) => { this.setState({ latitude: marker.latLng.lat(), longitude: marker.latLng.lng() }) }}
                        /> */}
                      </GoogleMapsWrapper>
                      <GoogleMapsWrapper
                        options={{ streetViewControl: false, mapTypeControl: false }}
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCJvlPMRSy6vltr9575qooDS8bNKRtXxbs&language=pt&region=br&v=3&libraries=drawing"
                        loadingElement={<div style={{ height: `50%` }} />}
                        containerElement={<div style={{ height: `50%` }} />}
                        mapElement={<div style={{ height: `50%` }} />}
                        onMapMounted={this._handleMapMountedD}
                        center={this.state.centerD}
                        zoom={this.state.zoomD}
                        edit={this.state.edit}
                      >
                        <Button
                          onClick={() => {
                            if ( this.state.destinoPolysPaths.length < 1) {
                              Dialog.resetOptions();
                              this.dialog.showAlert('Ainda não foi adicionado nenhum polígono!');
                            } else {
                              Dialog.setOptions({
                                defaultOkLabel: 'Sim',
                                defaultCancelLabel: 'Não',
                                primaryClassName: 'btn-danger '
                              })
                              this.dialog.show({
                                title: 'Deseja deletar os Polígonos?',
                                body: 'Esta ação não pode ser desfeita! Todos os dados serão apagados!',
                                actions: [
                                  Dialog.CancelAction(() => { }),
                                  Dialog.OKAction(() => {
                                    this.setState({  destinoPolysPaths: [] }, () => {
                                      Dialog.resetOptions();
                                      this.dialog.showAlert('Os polígonos foram deletados com sucesso!');
                                    });
                                  }),
                                ],
                                bsSize: 'medium',
                                onHide: (dialog) => {
                                  dialog.hide()
                                }
                              })
                            }
                          }}
                        >Remover os Polígonos</Button>
                        {this.state.destinoPolysPaths.map((p, i) => {
                          return (
                            <Polygon
                              ref={"destinoPoly" + i}
                              key={i}
                              editable
                              draggable
                              defaultPath={p.path}
                            />
                          );
                        })
                        }
                        {/* <Marker
                          draggable
                          position={{ lat: this.state.latitude, lng: this.state.longitude }}
                          onDrag={(marker) => { this.setState({ latitude: marker.latLng.lat(), longitude: marker.latLng.lng() }) }}
                        /> */}
                      </GoogleMapsWrapper>
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: 10 }}>
                  {this.state.novo &&
                    <div>
                      <Button type="submit" style={{ marginRight: 10 }}>Criar</Button>
                      <Button onClick={this.modalHide.bind(this)}>Cancelar</Button>
                    </div>
                  }
                  {this.state.edit &&
                    <div>
                      <Button type="submit" style={{ marginRight: 10 }}>Salvar</Button>
                      <Button onClick={this.modalHide.bind(this)}>Cancelar</Button>
                    </div>
                  }
                </Row>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div >
    );
  }
}

export default Tabelamento;
