import React, { Component } from 'react';
import './Corridas.css';
import { CSSTransitionGroup } from 'react-transition-group'
import Push from 'push.js';
import NewSac from './NewSac.js';
import WS from '../services/webservice';

class SacNotigication extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cli_pc: 0,
      cli_mensagensTotal: [],
      cli_mensagensAtivas: [],
      cli_mensagemcarregada: [],
      cli_mensagematual: [],
      cli_m:{},
      cli_at: '',
      cli_aberto: false,
      cli_central: '',
      cli_cpf: '',
      cli_rt: '',
      cli_uuid: '',
    };

    this.cli_updateData = this.cli_updateData.bind(this);
    this.cli_addMensagem = this.cli_addMensagem.bind(this);
    this.cli_msgaberto = this.cli_msgaberto.bind(this);

    this.webservice = new WS();
  }


  componentDidMount() {
    this.cli_timerId = setInterval(this.cli_updateData, 15000);
    //console.log("FUI CHAMADO FIAO")
    this.cli_updateData();
  }

  componentWillUnmount() {
    clearInterval(this.cli_timerId);
  }

  cli_addMensagem(cli_m) {


  }

  cli_msgaberto(result) {
    console.log("result boys1", result)

    this.props.msgaberto(result)
  }

  cli_modalHide() {
    this.cli_updateData();
    this.setState({
      cli_aberto: false
    })
  }

  cli_handleClick(cli_m) {
    this.cli_addMensagem(cli_m)
    console.log("MEU M MOTORISTA", cli_m)
    this.setState({
      cli_eme: cli_m,
      cli_nome: cli_m.nome,
      cli_central: cli_m.central,
      cli_telefone: cli_m.telefone,
      cli_email: cli_m.email,
      cli_uuid: cli_m.cliente,
      cliente: cli_m.cliente,
      cli_aberto: true,
    })
  }

  async cli_updateData() {
    try {
      console.log("sac 1", this.props.central)
      if (this.props.central.centralString) {
        console.log("sac 2", this.props.central.centralString)
        let result = await this.webservice.checksac(this.props.central.centralString.ids);
        console.log('checksac',result)

       let msg = result.sac;
        
        let self = this;
        msg.map((cli_m, i) => {
          let obj = this.props.central.centrais.find((obj) => { return obj.id === Number(cli_m.central); });
          let nomecentral = obj.nomefantasia;
          Push.create("Nova Mensagem", {
            body: "nome: " + cli_m.nome + " - Central: " + nomecentral,
            icon: require('../images/motorista.png'),
            timeout: 4000,
            onClick: function () {
              window.focus();
              this.close();
              self.cli_handleClick(cli_m);
            }
          });
          return null;
        })
        console.log("Mensagens total sac", this.state.cli_mensagensTotal)
        console.log("cli_mensagens ativas sac", this.state.cli_mensagensAtivas)
        this.setState({
          cli_mensagens: msg,
          cli_mensagensTotal: msg
        });

      }
      //console.log("SOU O CPF", this.state.cli_mensagens)
      this.setState({ error: null, loaded: true });

    } catch (err) {
      //console.log("ERROR", err)
      this.setState({ loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }


  render() {
    return (
      <div style={{ display: 'inline-block', padding: 5, backgroundColor:'blue' }} >
        <CSSTransitionGroup
          transitionName="SacCliente"
          component="tbody"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}>
          <div style={{ position: 'fixed', bottom: '50px' }}>
            {
              this.state.cli_mensagensTotal.map(cli_m => {
                //console.log("NOVA MESSAGI", cli_m)
                return (
                  //<tr key={cli_m.cli_cpf} onClick={() => this.handleAtual(cli_m, cli_m.cli_central ? cli_m.cli_central : cli_m.central_id)}>
                  //<td>Nova Mensagem - {cli_m.cli_at} - {cli_m.cli_rt} - {this.mostraCentral(cli_m)}</td>
                  
                  <div style={{ display: 'inline-block', padding: 5 }} onClick={()=>this.cli_handleClick(cli_m)} >
                    {!this.state.cli_aberto && cli_m && <NewSac
                      modalHide={this.cli_modalHide.bind(this)}
                      centrais={this.props.central}
                      sendmsg={this.cli_addMensagem}
                      msgaberto={this.cli_msgaberto.bind(this)}
                      eme={cli_m}
                      nome={cli_m.nome}
                      telefone={cli_m.telefone}
                      email={cli_m.email}
                      uuid={ cli_m.cliente}
                      central={ Number(cli_m.central ? cli_m.central : cli_m.central_id)}
                       />}
                  </div>

                  //</tr>
                )
              })
            }
            {this.state.cli_aberto && <div style={{ display: 'inline-block', padding: 5 }} >
              <NewSac
                modalHide={this.cli_modalHide.bind(this)}
                aberto={this.state.cli_aberto}
                centrais={this.props.central}
                sendmsg={this.cli_addMensagem.bind(this)}
                msgaberto={this.cli_msgaberto.bind(this)}
                eme={this.state.cli_eme}
                nome={this.state.cli_nome}
                telefone={this.state.cli_telefone}
                email={this.state.cli_email}
                uuid={this.state.cli_cliente}
                central={this.state.cli_central}/>
            </div>}
          </div>

        </CSSTransitionGroup>
      </div>
    )
  }
}
export default SacNotigication;
