import React, { Component } from 'react';
import WS from '../services/webservice';
import { Image, Button } from 'react-bootstrap'
import ReactTable from "react-table";

class ControlarTipo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      central: 'tipo_pagamento_detalhe',
      sigla: '',
      id_tipo: '',
      ativo: '',
      descricao: '',
      prioridade: '',
    }

    this.fetchData = this.fetchData.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.mudarStatus = this.mudarStatus.bind(this);
    this.webservice = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  mudarStatus(tipo, id, status){
    let tipo2 = tipo
    let ntipo = tipo2.replace(/tipo_/, "").replace(/_detalhe/, "")
    let result = this.webservice.changeStatus(ntipo, id, status)
    
    return result
  }
  async fetchData(dados, instance, tipo_central) {
    // show the loading overlay
    await this.setState({
      dados: {...dados, sorted: ""}, instance, central: tipo_central,
    })
    this.setState({ loading: true })
    // fetch your data
    let sigla
    let ativo
    let prioridade
    let descricao
    let id_tipo
    let pages = dados.page + 1

    let result = await this.webservice.getTipo(tipo_central, '', dados.sorted, pages, dados.pageSize, dados.filtered);
    let count = result.headers.get('X-Total-Count');
    result = await result.json();
    if(result.length > 0){

    let separar = Object.keys(result[0])
    separar.map(obj => {
      if (/_sg_tp_/.test(obj)) {
        return (sigla = obj)
      }
      if (/ativo_/.test(obj)) {
        return (ativo = obj)
      }
      if (/_desc/.test(obj) || /_msg/.test(obj)) {
        return (descricao = obj)
      }
      if (/_prioridade/.test(obj) || /_order/.test(obj)) {

        if (/_cat_/.test(obj)) {
          prioridade = obj.replace(/_cat_/, "_")
          return (prioridade)
        }
        return (prioridade = obj)
      }
      if(/_cd_tp_/.test(obj) || /codigo/.test(obj)){
        return (id_tipo = obj)
      }
      return null
    })
    
    this.setState({
      sigla: sigla,
      ativo: ativo,
      descricao: descricao,
      prioridade: prioridade,
      id_tipo: id_tipo,
      auth: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false
    })
  }else{
    this.setState({
      auth: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false
    })
  }
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => { this.fetchData(dados, instance, this.state.central) }, 300)
    });
  }

  render() {
    return (
      <div style={{margin: 100}}>
        <Button style={{ marginBottom: 5, marginLeft: 5 }} onClick={() => {this.fetchData(this.state.dados, this.state.instance, 'tipo_corrida_detalhe')}}> Corrida</Button>
        <Button style={{ marginBottom: 5, marginLeft: 5 }} onClick={() => {this.fetchData(this.state.dados, this.state.instance, 'tipo_carro_detalhe')}}> Carro</Button>
        <Button style={{ marginBottom: 5, marginLeft: 5 }} onClick={() => {this.fetchData(this.state.dados, this.state.instance, 'tipo_cliente_detalhe')}}> Cliente</Button>
        <Button style={{ marginBottom: 5, marginLeft: 5 }} onClick={() => {this.fetchData(this.state.dados, this.state.instance, 'tipo_pagamento_detalhe')}}> Pagamento</Button>
        <Button style={{ marginBottom: 5, marginLeft: 5 }} onClick={() => {this.fetchData(this.state.dados, this.state.instance, 'tipo_mensagem_detalhe')}}> Mensagem</Button>
        <ReactTable
          manual
          //data={this.state.corridas}
          pages={this.state.pages}
          loading={this.state.loading}
          onFetchData={this.fetchDataTimeOut}
          data={this.state.auth}
          previousText='Anterior'
          nextText='Próxima'
          loadingText='Carregando...'
          noDataText='Nenhum dado encontado'
          pageText='Página'
          ofText='de'
          rowsText='linhas'
          filterable
          columns={[
            {
              Header: "Prioridade",
              accessor: this.state.prioridade,
              width: 100,
            },
            {
              Header: "sigla",
              accessor: this.state.sigla,
              width: 100,
            },
            {
              Header: "Ativo",
              accessor: this.state.ativo,
              width: 100,

              Cell: row => (

                <div
                  style={{
                    width: '16%',
                    height: '76%',
                    margin: '0 auto',
                    backgroundColor: row.original[this.state.ativo] ? 'green' : 'red',
                    borderRadius: '2px'
                  }}

                > </div>
              ),
              filterMethod: (filter, row) => {
                if (filter.value === "") {
                  return true;
                }
                if (filter.value === "true") {
                  return row[filter.id];
                }
                return !row[filter.id];
              },
              Filter: ({ filter, onChange }) =>
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                >
                  <option value="">Todos</option>
                  <option value="true">Ativado</option>
                  <option value="false">Desativado</option>
                </select>
            },
            // {
            //   Header: "Sigla",
            //   accessor: this.state.sigla,
            //   width: 100,
            // },
            {
              Header: "Descrição",
              accessor: this.state.descricao,
            },           
            {
              Header: "Açoes",
              width: 100,
              Cell: row => (
                <div>
                  <div>
                    {!row.original[this.state.ativo] && <Image src={require('../images/check.png')} style={{ marginLeft: '10px', cursor: 'pointer'}} onClick={()=>{this.mudarStatus(this.state.central,row.original[this.state.id_tipo],'ativar').then(()=>this.fetchData(this.state.dados,this.state.instance, this.state.central))}} />}
                    {row.original[this.state.ativo] && <Image src={require('../images/remove.png')} style={{ marginLeft: '10px', width: 25, height: 25, cursor: 'pointer' }} onClick={()=>{this.mudarStatus(this.state.central,row.original[this.state.id_tipo],'inativar').then(()=>this.fetchData(this.state.dados,this.state.instance, this.state.central))}} />}
                  </div>
                </div>
              )
            }
          ]}
          defaultPageSize={5}

        />
      </div>

    );
  }
}

/*<CadastroHelper
      dados={{
        acessoID: '78aa66a5-c371-4b57-bf34-332d2ae8cd22',
        parentProps: this.props,
        id: "t03_cd_tp_carro",
        tipo_cadastro: "Tipo de carro",
        tipo_plural: "carros",
        href: "/tipo_carro",
        titulo: "Cadastro de Tipos de Carros",
        requests: {
          type: 'tipo_carro',
          list: this.ws.getTipo.bind(this.ws),
          save: this.ws.criarTipo.bind(this.ws),
          edit: this.ws.alterarTipo.bind(this.ws),
          delete: this.ws.deleteTipo.bind(this.ws),
        },
        campos: [

          {
            ref: "t03_ativo",
            label: "Ativo",
            type: "checkbox",
          },
        ],
        table: {
          colunas: [
            {
              Header: "Ativo",
              accessor: "t03_ativo",
              width: 100,
              Cell: row => (
                <div
                  style={{
                    width: '16%',
                    height: '76%',
                    margin: '0 auto',
                    backgroundColor: row.original.t03_ativo ? 'green' : 'red',
                    borderRadius: '2px'
                  }}
                />
              ),
              filterMethod: (filter, row) => {
                if (filter.value === "") {
                  return true;
                }
                if (filter.value === "true") {
                  return row[filter.id];
                }
                return !row[filter.id];
              },
              Filter: ({ filter, onChange }) =>
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                >
                  <option value="">Todos</option>
                  <option value="true">Ativado</option>
                  <option value="false">Desativado</option>
                </select>
            },
            {
              Header: "Sigla",
              accessor: "t03_sg_tp_carro",
              width: 100,
            },
            {
              Header: "Descrição",
              accessor: "t03_desc_tp_carro",
            },
            {
              Header: "Prioridade",
              accessor: "t03_prioridade",
              width: 100,
            },
          ],
        }
      }}
    />*/
export default ControlarTipo;
