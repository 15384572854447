import React, { Component } from 'react';

import './GoCss.css';


class GoTipoPagamento extends Component {
  constructor(props) {
    super(props);


  }
  

  escolhe() {
    this.props.escolhetipo(this.props.sigla,this.props.pgto);
}
titulo() {
    let sigla = this.props.sigla;
    let saida = 'Comum'
    if (sigla === 'cnl') {saida = 'Comum'}
    if (sigla === 'prm') {saida = 'Executivo'}
    if (sigla === 'c15') {saida = 'Desconto'}
    if (sigla === 'cdo') {saida = 'Adaptado'}
    if (sigla === 'mot') {saida = 'Motociclista'}
    if (sigla === 'vet') {saida = 'Convenio'}
    if (sigla === 'pop') {saida = 'Popular'}
    if (sigla === 'ldy') {saida = 'Lady'}

    return saida;
}
detalhes() {
  this.props.showDetalhe(this.props.sigla)
}
render() {
    let classe = this.props.tiposelecionado === this.props.sigla ? 'pagamentoOK' : 'pagamentoNOK';
    let prices = this.props.prices;
    let sigla = this.props.sigla;
    let price = {};
    let saida = ''
    if (prices) {
        if (prices[sigla]) {
            price = prices[sigla];
            saida = <div className={classe} style={{flex:1, flexDirection:'column', display: 'inline-flex', padding: 7, margin: 5, alignContent: 'center', alignItems:'center'}}>
                        <div style={{flex:1, alignSelf: 'center', alignContent: 'stretch', width:'100%'}}>
                        <label style={{flex:1, width:'100%', textAlign:'center'}} onClick={() => this.escolhe()}>
                        {price.nome.length > 1 ? price.nome : this.titulo()}
                        </label>
                        </div>
                        <div style={{flex:1, alignSelf: 'center', alignContent: 'stretch', width:'100%'}}>
                        <label style={{flex:1, width:'100%', textAlign:'center'}} onClick={() => this.escolhe()}>
                        {price.valor_formatado}
                        </label>
                        </div>
                        {this.props.mostrardetalhesuser &&<div style={{flex:1, alignSelf: 'center', alignContent: 'stretch', width:'100%'}}>
                        <label style={{flex:1, width:'100%'}} className="btn default" onClick={() => this.detalhes()}>Detalhes do Valor ({sigla})</label></div>}
            </div>
            }
        }

    return saida;
}

}
export default GoTipoPagamento;


