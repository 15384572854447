import React, { Component } from 'react';
//import { render } from 'react-dom'
//import ReactSVG from 'react-svg'
import windowSize from 'react-window-size';
import Map from 'pigeon-maps';
//import Marker from 'pigeon-marker';
import Overlay from 'pigeon-overlay';
import turfBbox from '@turf/bbox';
import { featureCollection as turfFeatureCollection, point as turfPoint } from '@turf/helpers';
import geoViewport from '@mapbox/geo-viewport';
//import { ReactComponent as Target } from '../assets/gomap/target.svg';
import { ReactComponent as Marker } from '../assets/gomap/marker.svg';
//import { ReactComponent as car } from '../assets/gomap/car.svg';
//import { ReactComponent as markerclear } from '../assets/gomap/markerclear.svg';


const providers = {
  osm: (x, y, z) => {
    const s = String.fromCharCode(97 + (x + y + z) % 3)
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`
  },
  wikimedia: (x, y, z, dpr) => {
    return `https://maps.wikimedia.org/osm-intl/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.png`
  },
  stamen: (x, y, z, dpr) => {
    return `https://stamen-tiles.a.ssl.fastly.net/terrain/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.jpg`
  },
  wmflabs: (x, y, z) => {
    return `https://tiles.wmflabs.org/bw-mapnik/${z}/${x}/${y}.png`
  }
  // streets: mapbox('streets-v10', MAPBOX_ACCESS_TOKEN),
  // satellite: mapbox('satellite-streets-v10', MAPBOX_ACCESS_TOKEN),
  // outdoors: mapbox('outdoors-v10', MAPBOX_ACCESS_TOKEN),
  // light: mapbox('light-v9', MAPBOX_ACCESS_TOKEN),
  // dark: mapbox('dark-v9', MAPBOX_ACCESS_TOKEN)
}


const Line = ({ mapState: { width, height }, latLngToPixel, coordsArray, style = { stroke: 'rgb(255,0,0)', strokeWidth: 2 } }) => {
  if (coordsArray.length < 2) {
    return null
  }

  let lines = []
  let pixel = latLngToPixel([coordsArray[0][1],coordsArray[0][0]])

  for (let i = 1; i < coordsArray.length; i++) {
    let pixel2 = latLngToPixel([coordsArray[i][1],coordsArray[i][0]])
    lines.push(<line key={i} x1={pixel[0]} y1={pixel[1]} x2={pixel2[0]} y2={pixel2[1]} style={style} />)
    pixel = pixel2
  }

  return (
    <svg width={width} height={height} style={{ top: 0, left: 0 }}>
      {lines}
    </svg>
  )
}

class GoMap extends Component {
  centerFake = {center: [-17.57628025, -42.53025855], zoom: 5};
  
  constructor(props) {
    super(props);
    this.state = {
      centro: {lat:-25, lng: -49},
      carrega: false,
      carregando: true,
      autozoom: true,
    }
    this.arrumaCoordenada = this.arrumaCoordenada.bind(this);
    this.arrumaArrayMarcadores = this.arrumaArrayMarcadores.bind(this);
    this.centerZoomFromLocations = this.centerZoomFromLocations.bind(this);
    this.escolhe = this.escolhe.bind(this);
    this.escolhe2 = this.escolhe2.bind(this);
    this.retorna = this.retorna.bind(this);
    this.retornainic = this.retornainic.bind(this);
    this.handleonClick = this.handleonClick.bind(this);
    this.setAutozoom = this.setAutozoom.bind(this);
  }
  arrumaCoordenada(latlng) {
    let saida = [];
    if (latlng.lat) {saida.push(latlng.lat);} 
    if (latlng.latitude) {saida.push(latlng.latitude);} 
    if (latlng.lng) {saida.push(latlng.lng);}
    if (latlng.lon) {saida.push(latlng.lon);}
    if (latlng.longitude) {saida.push(latlng.longitude);}
    return saida;
  }
  arrumaArrayMarcadores(marcadores) {
    let saida = [];
    if (marcadores) {
      for (let index = 0; index < marcadores.length; index++) {
        const element = marcadores[index];
        let item = {
          position: this.arrumaCoordenada(element),
          color: element.color ? element.color : null,
          text: element.label ? element.label : element.text ? element.text : null,
          objeto: element
        }
        
        if (item.position.length > 1) {
          saida.push(item)
        }
        
      }
    }
    return saida;
  }
  escolhe(ponto) {
    this.setState({
      centro: ponto,
      carrega: true
    })
    setTimeout(function () { this.retorna(); }.bind(this), 5000);
  }
  escolhe2(ponto) {
    this.setState({
      centro: ponto,
      carrega: true
    })
    setTimeout(function () { this.retorna(); }.bind(this), 1000);
  }
  retornainic() {
    this.setState({
      centro: {},
      carrega: false,
      carregando: true
    })
  }
  componentDidMount() {
    setTimeout(function () { this.escolhe2({lat:-25, lng: -49}); }.bind(this), 1000);
  }
  retorna() {
    this.setState({
      centro: {},
      carrega: false
    })
  }
  handleonClick(marcador) {
    if (this.props.MarcadorClick) {
      this.props.MarcadorClick(marcador.objeto)
    }
  }
  centerZoomFromLocations (locations, width, height) {
    console.log('centerZoomFromLocations', [locations, width, height])
    const points = locations.map(location => turfPoint(location.position))
    const features = turfFeatureCollection(points)
    const bounds = turfBbox(features)
    let zoomtemp = 17;
    console.log('centro',[bounds, [width, height]])
    const { center, zoom } = geoViewport.viewport(bounds, [width * 0.6, height * 0.6])
    console.log('centro',[center, zoom])
    if (!isNaN(zoom)) {zoomtemp = zoom}
    let saida = {
      center: center,
      zoom: Math.min(zoomtemp,this.props.maxZoom ? this.props.maxZoom : 19)
    }
    
    if (this.state.autozoom) {this.centerFake = saida}
    console.log('centerZoomFromLocations', this.centerFake)
    return this.centerFake;
  }
  setAutozoom() {
    this.setState({autozoom:!this.state.autozoom})
  }
  render() {
    let position = this.props.position ? this.arrumaCoordenada(this.props.position) : this.arrumaCoordenada({lat:-25, lng: -49});
    let minZoom = this.props.minZoom ? this.props.minZoom : 7;
    let maxZoom = this.props.maxZoom ? this.props.maxZoom : 19;
    let defaultZoom = this.props.defaultZoom ? this.props.defaultZoom : 16;
    let width = null;
    if (this.props.width) {
      if (isNaN(this.props.width)) {
        if (this.props.width.indexOf('%') >= 0) {
          width = parseFloat(this.props.width)/100 * window.innerWidth ;
        } else {
          width = window.innerWidth ? window.innerWidth : 400;
        }
      } else {
        width = this.props.width;
      }
    } else {
      width = window.innerWidth ? window.innerWidth : 400;
    }
    let height = null;
    if (this.props.height) {
      if (isNaN(this.props.height)) {
        if (this.props.height.indexOf('%') >= 0) {
          height = parseFloat(this.props.height)/100 * window.innerHeight;
        } else {
          height = window.innerHeight ? window.innerHeight : 400;
        }
      } else {
        height = this.props.height;
      }
    } else {
      height = window.innerHeight ? window.innerHeight : 400;
    }
    console.log(width,height)
    let center = position;
    let zoom = defaultZoom;
    let marcadores = [];
    let marcadoresZoom = [];
    marcadoresZoom = [...marcadoresZoom, ...this.arrumaArrayMarcadores(this.props.markO)]
    marcadoresZoom = [...marcadoresZoom, ...this.arrumaArrayMarcadores(this.props.mark1)]
    marcadores = [...marcadores, ...this.arrumaArrayMarcadores(this.props.markO)]
    marcadores = [...marcadores, ...this.arrumaArrayMarcadores(this.props.mark1)]
    //marcadores = [...marcadores, ...this.arrumaArrayMarcadores(this.props.mark2)]
    //marcadores = [...marcadores, ...this.arrumaArrayMarcadores(this.props.mark3)]
    let defaultcolor = this.props.defaultcolor;

    if (marcadoresZoom.length > 0) {
    let temp = this.centerZoomFromLocations(marcadoresZoom,width,height)
     center = temp.center;
     zoom = temp.zoom;
    }
    if (this.state.carrega) {
      center = this.state.centro;
      zoom = 16;
    }
    return (<div>
      {this.state.carregando && <div>
      <Map 
    center={center} 
    minZoom={minZoom}
    maxZoom={maxZoom}
    zoom={zoom} 
    provider={providers['osm']}
    defaultZoom={defaultZoom} 
    width={width} 
    height={height}>
    
    {marcadores.map((m, i) => {
      // return (
      //   <Marker anchor={m.position} payload={1} onClick={({ event, anchor, payload }) => {}} />
      // )
      return (
        <Overlay anchor={m.position} offset={[16, 32]} key={i}>
          <Marker onClick={() => {this.handleonClick(m)}} fill={m.color ? m.color : defaultcolor ? defaultcolor : 'red'} width={32} height={32} alt='' />
        </Overlay>
      )
    })}
    {this.props.c_points &&
    <Line coordsArray={this.props.c_points} />
    }
  </Map>
  <div>
  {this.props.zoommanual && (<>
    <div style={{flex:1, flexDirection:'column', display: 'inline-flex', padding: 7, margin: 5, alignContent: 'center', alignItems:'center', backgroundColor:'lightblue'}} onClick={() => this.setAutozoom()}>
  <label style={{flex:1, width:'100%', textAlign:'center'}} >{this.state.autozoom ? 'Desabilitar ' : 'Habilitar '}Zoom Automático</label>
          </div>
  </>)}
  </div>
  {!this.props.EscondeAtalhos &&
  <div>
  {marcadores.map((m, i) => {
      return (
        <div style={{flex:1, flexDirection:'column', display: 'inline-flex', padding: 7, margin: 5, alignContent: 'center', alignItems:'center', backgroundColor:'lightblue'}} onClick={() => this.escolhe(m.position)}>
          <label style={{flex:1, width:'100%', textAlign:'center'}} >
          {m.label ? m.label : m.text ? m.text : i+1}
          </label>
          </div>
      )
    })}</div>}
    </div>}
  </div>)
  }

}
export default GoMap;