import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import './Bubbles.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import WS from '../services/webservice';
import moment from 'moment';
import { Modal, Alert, Breadcrumb, Col, Form, FormGroup, ControlLabel, FormControl, Button, Tabs, Tab, InputGroup } from 'react-bootstrap'

const MotoristaMarker = (props) => {

  let borderColor = '2px solid red';
  let fundo = 'red';
  var ativado = false;
  if (props.livre) {
    borderColor = '2px solid green'
    fundo = 'green'
  }

  if (ativado === false) {
    ativado = true
  }
  if (props.text === props.selecionado.text) {
    return (
      //mexer aqui segunda para que quando for hover ele mudar para o quadrado e mostra o horario
      <div style={{ ...markerStyle, border: borderColor }}>{props.text}</div>

    )
  } else {
    return (
      <div style={{ ...markerStyle, width: '15px', height: '15px', border: borderColor, borderRadius: '100%', backgroundColor: fundo }} ></div>
    )
  }
};

class HistoricoGps extends Component {

  static defaultProps = {
    center: { lat: -25.47, lng: -49.29 },
    zoom: 11
  };

  constructor(props) {
    super(props);
    this.state = {
      center: { lat: -25.47, lng: -49.29 },
      zoom: 11,
      motoristas: [],
      dentro: false,
      points: [],
      entra: false,
      filteredRt: null,
      forceRt: false,
      crianca: [],
      showRtDetails: false,
      dataHoraInicio: moment(),
      dataHoraFim: moment(),
      selectedRt: {
        dados: {
          rt: null,
          nome: null,
        },
      },
    }
    this.updateLocation = this.updateLocation.bind(this);
    this.pesquisar = this.pesquisar.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGoogleMapApi = this.handleGoogleMapApi.bind(this);
    this.updatePath = this.updatePath.bind(this);
    this.webservice = new WS();
    this.muda = this.muda.bind(this)
    this.forceAT = this.forceAT.bind(this)
    this.muda2 = this.muda2.bind(this)
  }

  updateLocation(position) {
    //  this.setState({
    //    center: {
    //      lat: position.coords.latitude,
    //      lng: position.coords.longitude,
    //    }
    //  })
  }
  handleChange(event) {
    console.log(event.format());
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleGoogleMapApi(google) {
    this._google = google;
  }
  updatePath() {
    const google = this._google;
    if (this.state.caminho) {
      this.state.caminho.setMap(null);
    }
    //const self = this;
    var flightPath = new google.maps.Polyline({
      path: this.state.motoristas[0].items.map(mot => { return { lat: mot.latitude, lng: mot.longitude } }),
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    console.log(flightPath);
    this.setState({ caminho: flightPath });
    flightPath.setMap(google.map);
  }
  async updateData() {
    console.log('updating data');
    console.log(this.state.dataHoraInicio);
    console.log(moment(this.state.dataHoraInicio).valueOf())
    try {
      //MUDAR O BACKEND AQUI PARA ACEITAR O AT(FALAR CARLSON)
      let params = {
          'dataHoraInicio': moment(this.state.dataHoraInicio).valueOf(),
          'dataHoraFim': moment(this.state.dataHoraFim).valueOf(),
          'uuid': this.state.filteredRt,
        }

      var esc = encodeURIComponent;
      var query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
      let result = await this.webservice.fetch2('gps/porMotorista?' + query);
      result = await result.json();

      let filtrados = result[0].items.filter(function (e) {
        return e.longitude !== 0 && e.latitude !== 0;
      });

      this.setState({
        motoristas: result.map(r => { return { ...r, _uuid: r.uuid, items: filtrados } })
      })

      //console.log("resltadoo", result[0].items[0]);



      const bounds = {
        ne: {
          lat: filtrados[0].latitude + 0.0001, lng: filtrados[0].longitude + 0.0001
        },
        sw: {
          lat: filtrados[0].latitude - 0.0001, lng: filtrados[0].longitude - 0.0001
        }
      };
      filtrados.forEach(m => {
        if (m.latitude > bounds.ne.lat) {
          bounds.ne.lat = m.latitude;
        }
        if (m.longitude > bounds.ne.lng) {
          bounds.ne.lng = m.longitude;
        }
        if (m.latitude < bounds.sw.lat) {
          bounds.sw.lat = m.latitude;
        }
        if (m.longitude < bounds.sw.lng) {
          bounds.sw.lng = m.longitude;
        }
      });

      const size = {
        width: 440, // Map width in pixels
        height: 380, // Map height in pixels
      };

      const { center, zoom } = fitBounds(bounds, size);
      await this.setState({
        center: center,
        zoom: zoom
      });

      this.updatePath();
    } catch (err) {
      this.setState({ loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
      console.log(err);
    }
  }

  async componentDidMount() {
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push("/");
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.updateLocation);
    }
  }


  pesquisar() {
    this.setState({
      filteredRt: ReactDOM.findDOMNode(this.txtRt).value,
    }, () => this.updateData())
    return true;
  }

  handleClose() {
    this.setState({
      showRtDetails: false,
    })
  }

  muda = async (key, childProps) => {
    await this.setState({
      entra: true,
      crianca: childProps,
    })
    console.log("opopopopopop")
    console.log(this.state.crianca)

  }

  muda2() {
    this.setState({
      entra: false,
    })
  }

  forceAT(e) {
    var value = e.target.checked;
    this.setState({
      forceRt: value,
    })
  }

  render() {
    const bootstrapURLKeys = {
      key: 'AIzaSyCJvlPMRSy6vltr9575qooDS8bNKRtXxbs',
      libraries: ['drawing'].join(','),
    }
    let markers
    if (this.state.motoristas.length > 0) {
      console.log("motorista lel", this.state.motoristas[0])

      markers = this.state.motoristas[0].items.map(mot => {
        if (this.state.dentro === false) {
          this.setState({
            dentro: true,
          })
        }
        console.log("motin lel", mot.items)
        return (
          <MotoristaMarker
            key={mot.datahora}
            selecionado={this.state.crianca}
            hover={this.state.entra}
            lat={mot.latitude}
            lng={mot.longitude}
            text={moment(mot.datahora).format("HH:mm")}
            livre={mot.livre}
          />
        )
      })
    }
    console.log(this.state.entra)
    if (this.state.selectedRt) {
      this.selectedRt = this.state.motoristas.find(mot => mot.uuid === this.state.selectedRt.uuid);
    }
    console.log(this.state.selectedRt);
    if (!this.selectedRt) {
      this.selectedRt = {
        dados: {
          rt: null,
          nome: null,
        }
      }
    }
    return (
      <div style={{ width: '98%', height: '550px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active href="/historicogps">Hist&oacute;rico dos Motoristas</Breadcrumb.Item>
        </Breadcrumb>
        <Form horizontal>
          <FormGroup controlId="formHorizontalEmail">
            <Col componentClass={ControlLabel} sm={1}>
              Motorista
                </Col>
            <Col sm={2}>
              <InputGroup>
                <FormControl onKeyDown={e => e.keyCode === 13 && e.preventDefault()} ref={ref => this.txtRt = ref} type="number" placeholder='CPF' style={{height:30}}/>
              </InputGroup>
            </Col>
            <Col componentClass={ControlLabel} sm={1}>
              Per&iacute;odo
                </Col>
            <Col sm={2}>
              <DatePicker
                selected={this.state.dataHoraInicio}
                className='form-control'
                onChange={(v) => this.setState({ dataHoraInicio: v })}
                showTimeSelect
                name="dataHoraInicio"
                timeFormat="HH:mm"
                timeIntervals={10}
                dateFormat="DD/MM/YY HH:mm"
                withPortal
                timeCaption="hora" />
            </Col>
            <Col sm={2}>
              <DatePicker
                selected={this.state.dataHoraFim}
                className='form-control'
                onChange={(v) => this.setState({ dataHoraFim: v })}
                showTimeSelect
                name="dataHoraFim"
                withPortal
                timeFormat="HH:mm"
                timeIntervals={10}
                dateFormat="DD/MM/YY HH:mm"
                timeCaption="hora" />
            </Col>
            <Col componentClass={ControlLabel} sm={1}>
              <Button onClick={this.pesquisar}>Pesquisar</Button>
            </Col>
          </FormGroup>



        </Form>
        <GoogleMapReact
          ref={(map) => this._map = map}
          center={this.state.center}
          zoom={this.state.zoom}
          bootstrapURLKeys={bootstrapURLKeys}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.handleGoogleMapApi}
          onChildMouseEnter={this.muda}
          onChildMouseLeave={this.muda2}
          teste={this.state.entra}
        >
          {markers}

        </GoogleMapReact>
        <Modal show={this.state.showRtDetails} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>RT {this.selectedRt.dados.rt}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab eventKey={1} title="Dados Gerais">
                <h4>Nome</h4>
                <p>{this.selectedRt.dados.nome}</p>
                <h4>Status Atual</h4>
                <p style={{ color: this.selectedRt.livre ? 'green' : 'red' }}>{this.selectedRt.livre ? "Livre" : "Ocupado"}</p>
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}

const K_SIZE = 30;
const markerStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_SIZE + 20,
  height: K_SIZE * 0.8,
  left: -K_SIZE / 2,
  top: -K_SIZE / 2,

  border: '3px solid #f44336',
  backgroundColor: 'white',
  textAlign: 'center',
  color: '#3f51b5',
  fontSize: 12,
  fontWeight: 'bold',
  padding: 3,
  cursor: 'pointer'
};
export default HistoricoGps;
