import Raven from 'raven-js'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import store from './components/store'
import './index.css'
import registerServiceWorker from './registerServiceWorker'

Raven.config(
  'https://7b7af0489a314936ae8b337bde69ae22@sentry.io/1194615'
).install()

ReactDOM.render(
  <Provider store={store}>
    <div>
      <App />
    </div>
  </Provider>,
  document.getElementById('root')
)
registerServiceWorker()
