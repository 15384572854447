import React from 'react';
import WS from '../services/webservice';
import GOpivot from './gopivot';
import Loader from 'react-loader';
import _ from 'lodash'
import { Row, Col, Form, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import "react-table/react-table.css";

const mesNome = ['', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

function daysInMonth (month, year) { 
  return new Date(year, month, 0).getDate(); 
} 

class Rfaturamento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      var: [],
      loaded: false,
      mes: 1,
      ano: 2020
    };

    this.webservice = new WS();
    this.loadDados = this.loadDados.bind(this);
    this.formularios = this.formularios.bind(this);

  }



  async componentDidMount() {
    this.loadDados()
  }


  loadDados() {
    this.webservice.runFaturamento(this.state.ano, this.state.mes, '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20').then( resposta => {
      
      let pCentral = [];
        let itens = [];
        let titulo = resposta[0].central;
        let centralid = resposta[0].centralid;
        for (let i = 0; i < resposta.length; i++) {
          if (titulo !== resposta[i].central) {
            pCentral.push({central: titulo, centralid: centralid, itens: itens});
            titulo = resposta[i].central;
            centralid = resposta[i].centralid;
            itens = [];
          }
          itens.push(resposta[i]);
        }
        pCentral.push({central: titulo, centralid:centralid, itens: itens});
        let dias = daysInMonth(this.state.mes, this.state.ano)
        for (let i = 0; i < pCentral.length; i++) {
          const y = pCentral[i];
          y.org = []
          for (let index = 0; index < y.itens.length; index++) {
            const x = y.itens[index];
            let idx = y.org.findIndex(obj => obj.cpf === x.cpf);
            let v = {};
            if (idx < 0) {
              for (let k = 1; k <= dias; k++) {
                v[k] = {v:0, c:0}
              }
              v.tv = 0;
              v.tc = 0;
            } else {
              v = y.org[idx];
            }
            v.cpf = x.cpf;
            v.nome = x.nome;
            v.central = x.central;
            v.centralid = x.centralid;
            v[x.dia].v += x.valor;
            v[x.dia].c += x.contar;
            v.tv += x.valor;
            v.tc += x.contar;
            if (idx < 0) { 
              y.org.push(v);
            } else {
              y.org[idx] = v;
            }
          }
          pCentral[i] = y;
        }
      console.log('pCentral',pCentral)
      this.setState({
        var: pCentral,
        loaded: true
      })
    })
  }


  formularios() {
    return (
      <Row>
          <Col md={2}>
            <FormGroup>
              <ControlLabel>Ano</ControlLabel>
              <FormControl type="number" value={this.state.ano} style={{ height: 30 }} onChange={e => { this.setState({ ano: e.target.value }) }} />
            </FormGroup>
          </Col>


          <Col md={2}>
            <FormGroup>
              <ControlLabel>Mês</ControlLabel>
              <FormControl value={this.state.mes} style={{ height: 30 }} componentClass="select" inputRef={el => this.inputEl = el} onChange={e => { this.setState({ mes: this.inputEl.value }) }}>
                <option key='1' value="01">Janeiro</option>
                <option key='2' value="02">Fevereiro</option>
                <option key='3' value="03">Março</option>
                <option key='4' value="04">Abril</option>
                <option key='5' value="05">Maio</option>
                <option key='6' value="06">Junho</option>
                <option key='7' value="07">Julho</option>
                <option key='8' value="08">Agosto</option>
                <option key='9' value="09">Setembro</option>
                <option key='10' value="10">Outubro</option>
                <option key='11' value="11">Novembro</option>
                <option key='12' value="12">Dezembro</option>
              </FormControl>
            </FormGroup>
          </Col>
      </Row>
    )
  }

  render() {
    return (
      <div style={{ minHeight: '400px' }}>
          <div >
            <React.Fragment>
              {this.formularios()}
            </React.Fragment>
            </div>
          <Loader loaded={this.state.loaded}>
            <h1>Faturamento - {mesNome[Number(this.state.mes)]} / {this.state.ano}</h1>
            <GOpivot val={this.state.var} dias={daysInMonth(this.state.mes, this.state.ano)}/>
          </Loader>
      </div>
    );
  }
}

export default Rfaturamento;
