import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class Afaturar extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.ws = new WS();
    this.postFatura = this.postFatura.bind(this);
  }

  mirrorState(key) {
    this.setState(key);
  }

  async postFatura(parametros, after) {
    alert("Aguarde Processamento!")
    this.ws.postfatura(parametros)
    alert("Processamento concluido!")
    if (after) {
      after();
    }
  }

  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Voucher a faturar",
          tipo_plural: "Voucheres a faturar",
          href: "voucher_afaturar",
          titulo: "Voucher a faturar",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'voucher_afaturar',
            list: true,
            save: false,
            edit: false,
            delete: false,
            listSub: false,
            outros: [
              {icone: 'FaUsers', tamanho: 30, cor: 'blue', texto: 'Gerar Fatura', funcao:this.postFatura.bind(this)}],
          },
          campos: [
            {
              ref: "central_nomefantasia",
              label: "Central",
              type: "justlabel",
              filter: 'central.nomefantasia',
            },
            {
              ref: "voucher_empresa_razaosocial",
              label: "Empresa",
              type: "justlabel",
              filter: 'voucher_empresa.razaosocial',
            },
            {
              ref: "voucher_empresa_cnpj",
              label: "Cnpj",
              type: "justlabel",
              filter: 'voucher_empresa.cnpj',
            },
            {
              ref: "datainitial",
              label: "Período inicial",
              type: "datedisabled",
            },
            {
              ref: "fechamentoarrumado",
              label: "Período Final",
              type: "datedisabled",
            },
            {
              ref: "vencimentoreal",
              label: "Data Vencimento",
              type: "datedisabled",
            },
            {
              ref: "valor",
              label: "Valor",
              type: "currencydisabled",
            },
            {
              ref: "corridas",
              label: "Corridas",
              type: "justlabel",
            },

            {
              ref: 'listacorridas',
              label: 'listacorridas',
              type: 'hidden'
            },
            {
              ref: "central_id",
            label: "central_id",
            type: "hidden",
          },
          {
            ref: "central_razaosocial",
            label: "central_razaosocial",
            type: "hidden",
          },
          {
            ref: "fechamento",
            label: "fechamento",
            type: "hidden",
          },
          {
            ref: "numerofaturamento",
            label: "numerofaturamento",
            type: "hidden",
          },
          {
            ref: "quinzena",
            label: "quinzena",
            type: "hidden",
          },
          {
            ref: "vencimento",
            label: "vencimento",
            type: "hidden",
          },
          {
            ref: "voucher_empresa_id",
            label: "voucher_empresa_id",
            type: "hidden",
          },
          {
            ref: "voucher_empresa_tipofaturamento",
            label: "voucher_empresa_tipofaturamento",
            type: "hidden",
          },
          {
            ref: "voucher_empresa_vencimentomensal",
            label: "voucher_empresa_vencimentomensal",
            type: "hidden",
          },
          {
            ref: "voucher_empresa_vencimentoprimeiraquinzena",
            label: "voucher_empresa_vencimentoprimeiraquinzena",
            type: "hidden",
          },
          {
            ref: "voucher_empresa_vencimentosegundaquinzena",
            label: "voucher_empresa_vencimentosegundaquinzena",
            type: "hidden",
          },],
          botoes: [
            {
              label: "Criar",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Central",
                accessor: "central_nomefantasia",
              },
              {
                Header: "Empresa",
                accessor: "voucher_empresa_razaosocial",
              },
              {
                Header: "Mês de Referência",
                accessor: "data",
              },
              {
                Header: "Valor",
                id: "valor",
                accessor: d => <NumberFormat value={Number(d.valor)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />,
              },
              {
                Header: "Corridas",
                accessor: "corridas",
              },
            ],
          }
        }}
      />
    );
  }
}
export default Afaturar;