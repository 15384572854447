import WS from '../services/webservice';
import React, { Component } from 'react';
import ReactDom from 'react-dom'
import Search from './Search.js'
import { Grid, Button, Modal, Row, Col, FormGroup, ControlLabel } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Loader from 'react-loader';
import ModalList from './ModalList.js';

class SearchModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().subtract(6, "hours"),
      endDate: moment().add(1, "hours"),
      fone: '',
      endereco: '',
      numero: '',
      cliente: '',
      motorista: '',
      nomeMotorista: '',
      motoAT: '',
      motoCPF: '',
      motoNome: '',
      motoRT: '',
      atendente: '',
      refs: '',
      suggestions: [],
      typeSelected: '',
      loaded: true,
    }

    this.webservice = new WS();
    this.getCorridas = this.getCorridas.bind(this);
    this.getCurrentTime = this.getCurrentTime.bind(this);
    this.manageShortcut = this.manageShortcut.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
  }

  handleStartDateChange(date) {
    this.setState({
      startDate: date
    })
  }

  handleEndDateChange(date) {
    this.setState({
      endDate: date
    })
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {
    this.timerId = setInterval(this.getCurrentTime, 15000);
    this.getCurrentTime();
  }

  getCurrentTime() {
    if (!this.props.show)
      this.setState({ endDate: moment().add(1, "hours"), });
  }

  clearProps() {
    this.setState({
      fone: '',
      endereco: '',
      numero: '',
      cliente: '',
      motorista: '',
      nomeMotorista: '',
      atendente: '',
      motoAT: '',
      motoCPF: '',
      motoNome: '',
      motoRT: '',
    })
  }

  changeCorrida(cor) {
    this.setState({
      fone: cor.telefone,
      endereco: cor.endereco,
      numero: cor.numero,
      cliente: cor.cliente,
      motorista: cor.t08_nomemotorista,
      nomeMotorista: cor.t08_nomemotorista,
      // atendente: cor.atendente,
    })
    this.props.changeCorridaDados(cor)
  }

  changeMotorista(moto) {
    this.setState({
      motoAT: moto.porta,
      motoRT: moto.rt,
      motoNome: moto.nome,
      motoCPF: moto.cpf,
    })
    this.props.changeMotorista(moto)
  }

  changeValue(value, type) {
    if (type === 'endereco') this.setState({ endereco: value, typeSelected: type })
    if (type === 'fone') this.setState({ fone: value, typeSelected: type })
    if (type === 'cliente') this.setState({ cliente: value, typeSelected: type })
    if (type === 'moto') this.setState({ motorista: value, typeSelected: "motorista" })
    if (type === 'atendente') this.setState({ atendente: value, typeSelected: type })
    if (type === 'motorista') this.setState({ nomeMotorista: value, typeSelected: "nomeMotorista" })

    if (type === 'RT') this.setState({ motoRT: value, typeSelected: type })
    if (type === 'AT') this.setState({ motoAT: value, typeSelected: type })
    if (type === 'CPF') this.setState({ motoCPF: value, typeSelected: type })
    if (type === 'nome') this.setState({ motoNome: value, typeSelected: type })
  }

  getRefs(refs) {
    this.setState({ refs: refs })
  }

  modalHide() {
    this.setState({ showList: false })
    if(this.props.changeShowSearchModal){
      this.props.changeShowSearchModal(true);
    }
  }

  manageShortcut(e) {
    var code = (e.keyCode ? e.keyCode : e.which);

    if (code === 13) {
      if (this.state.typeSelected !== '' && this.props.historico) {
        this.getCorridas();
      }
    }
  }

  getCorridas() {
    console.log(this.state.typeSelected);
    let dateStart = moment(this.state.startDate).utc().format("YYYY-MM-DD HH:mm");
    let dateEnd = moment(this.state.endDate).utc().format("YYYY-MM-DD HH:mm");
    if (this.state.typeSelected === 'moto') {
      if (this.state[this.state.typeSelected].length >= 3 && this.state[this.state.typeSelected].length <= 4) {
        this.webservice.runSearch(dateStart, dateEnd, this.state.typeSelected, this.state[this.state.typeSelected])
          .then(result => {
            console.log(result);
            this.setState({ suggestions: result, showList: true })
            this.props.modalHide();
          })
      }
    } else if (this.state.typeSelected === 'fone') {
      if (this.state[this.state.typeSelected].length >= 10 && this.state[this.state.typeSelected].length <= 11) {
        this.webservice.runSearch(dateStart, dateEnd, this.state.typeSelected, this.state[this.state.typeSelected])
          .then(result => {
            console.log(result);
            this.setState({ suggestions: result, showList: true })
            this.props.modalHide();
          })
      }
    } else if (this.state.typeSelected === 'nomeMotorista') {
      this.webservice.runSearch(dateStart, dateEnd, "motorista", this.state[this.state.typeSelected])
        .then(result => {
          console.log(result);
          this.setState({ suggestions: result, showList: true })
          this.props.modalHide();
        })
    } else {
      this.webservice.runSearch(dateStart, dateEnd, this.state.typeSelected, this.state[this.state.typeSelected])
        .then(result => {
          console.log(result);
          this.setState({ suggestions: result, showList: true })
          this.props.modalHide();
        })
    }
  }

  async onClickRow(m) {
    this.setState({ loaded: false });
    await this.props.changeCorridaDados(m);
    this.setState({ showList: false });
    this.setState({ loaded: true });
    this.props.openCorridaDados();
    this.props.changeCanESC(true);
  }

  render() {
    let dateStart = moment(this.state.startDate).utc().format("YYYY-MM-DD HH:mm");
    let dateEnd = moment(this.state.endDate).utc().format("YYYY-MM-DD HH:mm");

    const vars = {
      startDate: dateStart,
      endDate: dateEnd,
    }

    const classes = {
      changeCorrida: this.changeCorrida.bind(this),
      clearProps: this.clearProps.bind(this),
      changeValue: this.changeValue.bind(this),
      changeMotorista: this.changeMotorista.bind(this),
      getRefs: this.getRefs.bind(this),
    }

    //Change the current language to portuguese BR
    require('moment/locale/pt-br.js')
    moment.locale('pt-br')

    return (
      <Grid>
        <Loader loaded={this.state.loaded}>
        </Loader>
        <Modal
          onKeyDown={this.manageShortcut} tabIndex="0"
          keyboard
          container={this}
          show={this.props.show}
          onHide={this.props.modalHide}
          aria-labelledby="contained-modal-title"
          onEntered={function () {
            if (!this.props.motorista) {
              this.props.historico ?
                ReactDom.findDOMNode(this.state.refs.auto_complete_fone).focus() :
                ReactDom.findDOMNode(this.state.refs.auto_complete_fone.input).focus();
            }
          }.bind(this)
          }
        >
          <Modal.Body>
            <Loader loaded={this.props.loaded}>
            </Loader>
            {!this.props.motorista &&
              <div>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <ControlLabel>Data hora início</ControlLabel>
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleStartDateChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        dateFormat="DD/MM/YYYY HH:mm"
                        timeCaption="time"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <ControlLabel>Data hora fim</ControlLabel>
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleEndDateChange}
                        showTimeSelect
                        minDate={moment(this.state.startDate)}
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        dateFormat="DD/MM/YYYY HH:mm"
                        timeCaption="time"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Search
                  pagtos={this.props.pagtos}
                  historico={this.props.historico}
                  defaultProps={vars}
                  defaultClasses={classes}
                  title="Telefone"
                  searchType='fone'
                  value={this.state.fone}
                />
                <Search
                pagtos={this.props.pagtos}
                  historico={this.props.historico}
                  defaultProps={vars}
                  defaultClasses={classes}
                  title="Endereço"
                  searchType='endereco'
                  value={this.state.endereco}
                />
                <Search
                pagtos={this.props.pagtos}
                  historico={this.props.historico}
                  defaultProps={vars}
                  defaultClasses={classes}
                  title="Cliente"
                  searchType='cliente'
                  value={this.state.cliente}
                />
                <Search
                pagtos={this.props.pagtos}
                  historico={this.props.historico}
                  defaultProps={vars}
                  defaultClasses={classes}
                  title="Motorista AT"
                  searchType='moto'
                  value={this.state.motorista}
                />
                <Search
                pagtos={this.props.pagtos}
                  historico={this.props.historico}
                  defaultProps={vars}
                  defaultClasses={classes}
                  title="Motorista"
                  searchType='motorista'
                  value={this.state.nomeMotorista}
                />
                <Search
                pagtos={this.props.pagtos}
                  historico={this.props.historico}
                  defaultProps={vars}
                  defaultClasses={classes}
                  title="Atendente"
                  searchType='atendente'
                  value={this.state.atendente}
                />
              </div>
            }
            {this.props.motorista &&
              <div>
                <Search
                pagtos={this.props.pagtos}
                  historico={this.props.historico}
                  motorista={this.props.motorista}
                  defaultProps={vars}
                  defaultClasses={classes}
                  title="AT"
                  searchType='AT'
                  value={this.state.motoAT}
                />
                <Search
                pagtos={this.props.pagtos}
                  historico={this.props.historico}
                  motorista={this.props.motorista}
                  defaultProps={vars}
                  defaultClasses={classes}
                  title="RT"
                  searchType='RT'
                  value={this.state.motoRT}
                />
                <Search
                pagtos={this.props.pagtos}
                  historico={this.props.historico}
                  motorista={this.props.motorista}
                  defaultProps={vars}
                  defaultClasses={classes}
                  title="Nome"
                  searchType='nome'
                  value={this.state.motoNome}
                />
                <Search
                pagtos={this.props.pagtos}
                  historico={this.props.historico}
                  motorista={this.props.motorista}
                  defaultProps={vars}
                  defaultClasses={classes}
                  title="CPF"
                  searchType='CPF'
                  value={this.state.motoCPF}
                />
              </div>
            }
            {this.props.historico &&
              <Button onClick={() => {
                console.log(this.state.typeSelected)
                if (this.state.typeSelected !== '') {
                  if (this.state[this.state.typeSelected] && this.state[this.state.typeSelected] !== '') {
                    console.log(this.state[this.state.typeSelected])
                    this.getCorridas();
                  }
                }
              }}>Pesquisar</Button>
            }
          </Modal.Body>
        </Modal>
        <ModalList
          show={this.state.showList}
          modalHide={this.modalHide.bind(this)}
          onClickRow={this.onClickRow.bind(this)}
          changeCanEsc={this.props.changeCanESC.bind(this)}
          dados={this.state.suggestions}
          route={this.props.route}
          childProps={this.props.childProps}
        />
      </Grid>
    );
  }
}
export default SearchModal;
