import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from './CadastroHelper.js';

class CadastroTipoMensagem extends Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "m04_codigo",
          tipo_cadastro: "Tipo de mensagem",
          tipo_plural: "mensagens",
          href: "/tipo_mensagem",
          titulo: "Cadastro de Tipos de Mensagem",
          requests: {
            type: 'tipo_mensagem',
            list: this.ws.getTipo.bind(this.ws),
            save: this.ws.criarTipo.bind(this.ws),
            edit: this.ws.alterarTipo.bind(this.ws),
            delete: this.ws.deleteTipo.bind(this.ws),
          },
          campos: [
            {
              ref: "m04_order",
              label: "Prioridade",
              type: "number",
              placeHolder: "Ordem",
            },            
            {
              ref: "m04_codigo",
              label: "Codigo",
              type: "text",
              placeHolder: "Codigo da mensagem",
            },
            {
              ref: "m04_msg",
              label: "Descrição",
              type: "text",
              placeHolder: "Descrição da mensagem",
            },
            {
              ref: "m04_ativo",
              label: "Ativo",
              type: "checkbox",
            },
          ],
          botoes: [
            {
              label: "Criar Tipo",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Ativo",
                accessor: "m04_ativo",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.m04_ativo ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
              {
                Header: "Codigo",
                accessor: "m04_codigo",
                width: 100,
              },
              {
                Header: "Descrição",
                accessor: "m04_msg",
              },
              
            ],
          }
        }}
      />
    );
  }
}

export default CadastroTipoMensagem;
