import React, { Component } from 'react';
import GoTipoPagamento from './GoTipoPagamento.js';
import GoDetalheValorPopup from './goDetalheValorPopup.js'
import { Image, Modal, Checkbox, Button, ButtonToolbar, FormGroup, ControlLabel, FormControl, Grid, Row, Col } from 'react-bootstrap'
import Select2 from 'react-select2-wrapper';


class GoPagamento extends Component {
  constructor(props) {
    super(props);

    this.showDetalhe = this.showDetalhe.bind(this);
    this.setTipoPagamento = this.setTipoPagamento.bind(this);
    this.updatePagamento = this.updatePagamento.bind(this);
    this.hideDetalhe = this.hideDetalhe.bind(this);
    this.state = {
      mostrardetalhesuser:'',
      tiposelecionado:'',
      pagtoSelected:'',
      priceEscolhidoDetalhe: {},
      showModal: false
    }
  }
  showDetalhe(sigla) {
    console.log(sigla,this.props.c_prices[sigla])
    this.setState({
      showModal: true,
      priceEscolhidoDetalhe: this.props.c_prices[sigla]
    })
  }
  hideDetalhe() {
    this.setState({
      showModal: false,
      priceEscolhidoDetalhe: {}
    })
  }
  updatePagamento(pgto) {
    this.props.updatePagamento(pgto)
  }
  setTipoPagamento(tipo, pgto) {
    let prices = {};
    if (this.props.c_prices) {
      if (this.props.c_prices[tipo]) {
        prices = this.props.c_prices[tipo];
      }
    }
    if (!pgto) {
      if (tipo==='vet') {
        pgto = '112'
      } else {
        pgto = '10'
      }
    }
    //console.log('setTipoPagamento',[tipo,prices,pgto])
    this.props.setTipoPagamento(tipo,prices,pgto)
  }
  render() {
    let prices = this.props.c_prices;
    let org = [];
    //console.log('this.props.c_prices',this.props.c_prices)

   // distancia_final
   let distancia_final = 0;
   let tempo_final = 0;
    if (this.props.c_prices) {
      if (prices.vet) if (prices.vet.origemdatabela === 'padrão') {delete prices.vet} else {org.push('vet'); distancia_final = prices.vet.distancia_final; tempo_final = prices.vet.tempo_final;}
      if (prices.cnl) if (prices.cnl.origemdatabela === 'padrão') {delete prices.cnl} else {org.push('cnl'); distancia_final = prices.cnl.distancia_final; tempo_final = prices.cnl.tempo_final;}
      if (prices.prm) if (prices.prm.origemdatabela === 'padrão') {delete prices.prm} else {org.push('prm'); distancia_final = prices.prm.distancia_final; tempo_final = prices.prm.tempo_final;}
      if (prices.ldy) if (prices.ldy.origemdatabela === 'padrão') {delete prices.ldy} else {org.push('ldy'); distancia_final = prices.ldy.distancia_final; tempo_final = prices.ldy.tempo_final;}
      if (prices.mot) if (prices.mot.origemdatabela === 'padrão') {delete prices.mot} else {org.push('mot'); distancia_final = prices.mot.distancia_final; tempo_final = prices.mot.tempo_final;}
      if (prices.cdo) if (prices.cdo.origemdatabela === 'padrão') {delete prices.cdo} else {org.push('cdo'); distancia_final = prices.cdo.distancia_final; tempo_final = prices.cdo.tempo_final;}
      if (prices.c15) if (prices.c15.origemdatabela === 'padrão') {delete prices.c15} else {org.push('c15'); distancia_final = prices.c15.distancia_final; tempo_final = prices.c15.tempo_final;}
      if (prices.pop) if (prices.pop.origemdatabela === 'padrão') {delete prices.pop} else {org.push('pop'); distancia_final = prices.pop.distancia_final; tempo_final = prices.pop.tempo_final;}
    }
    // if (org.length > 0) {
    //   this.setTipoPagamento(org[0])
    // }

    let distancia_final_fortamata = ''
    return (<div>
      <Row>
      <Col xs={12} md={4}>
        <Select2
          style={{ width: 250 }}
          id='pagamento-id'
          ref='pagto_type'
          value={this.props.c_pagamento}
          data={this.props.pagtoOptions}
          onSelect={e => {
            this.updatePagamento(e.target.selectedOptions[0].value)
          }}
          onUnselect={e => {
            this.updatePagamento(e.target.selectedOptions[0].value)
          }}
        />
      </Col>
      <Col xs={12} md={8}>
      {this.props.c_prices && <div>
      <GoTipoPagamento prices={prices} sigla='cnl' pgto='10'  showDetalhe={this.showDetalhe}  mostrardetalhesuser={this.props.mostrardetalhesuser} escolhetipo={this.setTipoPagamento} pagamento={this.props.c_pagamento} tiposelecionado={this.props.c_prices_escolhido} />
      <GoTipoPagamento prices={prices} sigla='prm' pgto='10'  showDetalhe={this.showDetalhe}  mostrardetalhesuser={this.props.mostrardetalhesuser} escolhetipo={this.setTipoPagamento} pagamento={this.props.c_pagamento} tiposelecionado={this.props.c_prices_escolhido} />
      <GoTipoPagamento prices={prices} sigla='vet' pgto='112' showDetalhe={this.showDetalhe}  mostrardetalhesuser={this.props.mostrardetalhesuser} escolhetipo={this.setTipoPagamento} pagamento={this.props.c_pagamento} tiposelecionado={this.props.c_prices_escolhido} />
      <GoTipoPagamento prices={prices} sigla='cdo' pgto='10'  showDetalhe={this.showDetalhe}  mostrardetalhesuser={this.props.mostrardetalhesuser} escolhetipo={this.setTipoPagamento} pagamento={this.props.c_pagamento} tiposelecionado={this.props.c_prices_escolhido} />
      <GoTipoPagamento prices={prices} sigla='ldy' pgto='10'  showDetalhe={this.showDetalhe}  mostrardetalhesuser={this.props.mostrardetalhesuser} escolhetipo={this.setTipoPagamento} pagamento={this.props.c_pagamento} tiposelecionado={this.props.c_prices_escolhido} />
      <GoTipoPagamento prices={prices} sigla='pop' pgto='10'  showDetalhe={this.showDetalhe}  mostrardetalhesuser={this.props.mostrardetalhesuser} escolhetipo={this.setTipoPagamento} pagamento={this.props.c_pagamento} tiposelecionado={this.props.c_prices_escolhido} />
      <GoTipoPagamento prices={prices} sigla='mot' pgto='10'  showDetalhe={this.showDetalhe}  mostrardetalhesuser={this.props.mostrardetalhesuser} escolhetipo={this.setTipoPagamento} pagamento={this.props.c_pagamento} tiposelecionado={this.props.c_prices_escolhido} />
      <GoTipoPagamento prices={prices} sigla='c15' pgto='10'  showDetalhe={this.showDetalhe}  mostrardetalhesuser={this.props.mostrardetalhesuser} escolhetipo={this.setTipoPagamento} pagamento={this.props.c_pagamento} tiposelecionado={this.props.c_prices_escolhido} />
      </div>}</Col>
      </Row>
      <GoDetalheValorPopup
        show={this.state.showModal}
        modalHide={this.hideDetalhe}
        dados={this.state.priceEscolhidoDetalhe}
      ></GoDetalheValorPopup>
    </div>)
  }

}
export default GoPagamento;