import WS from '../services/webservice';
import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl, Modal, Row, Col } from 'react-bootstrap'
import moment from 'moment';
import InputMask from 'react-input-mask';

var corridasFiltradas = [];

class CorridasAgendadas extends Component {

  constructor(props) {
    super(props);

    this.state = {
      corridasAgendadas: [],
      cliente: '',
      endereco: '',
      telefone: '',
      telefoneMask: '(99) 9999-9999',
    }

    this.webservice = new WS();

    this.filterAgendadas = this.filterAgendadas.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  getAgendadas() {
    corridasFiltradas = [];
    this.webservice.getAgendamentos(moment().add(30, "days").utc().format("YYYY-MM-DD HH:mm")).then(result => {
      
      for (let index = 0; index < result.length; index++) {
        corridasFiltradas.push(result[index]);
      }
      this.setState({ corridasAgendadas: result });
    })
  }

  filterAgendadas(value, type) {
    corridasFiltradas = [];
    if (value === '') {
      for (let index = 0; index < this.state.corridasAgendadas.length; index++) {
        corridasFiltradas.push(this.state.corridasAgendadas[index]);
      }
    }
    else {
      this.state.corridasAgendadas.map((m, i) => {
        if (type === 'cliente') {
          if (m.cliente.toLowerCase().includes(value.toLowerCase())) {
            corridasFiltradas.push(m);
          }
        } else if (type === 'endereco') {
          if (m.endereco.toLowerCase().includes(value.toLowerCase())) {
            corridasFiltradas.push(m);
          }
        }
        else if (type === 'telefone') {
          let number = value.replace(/\D/g, '');
          if (m.telefone.indexOf(number) === 0) {
            corridasFiltradas.push(m);
          }
        }
        return null;
      })
    }
  }

  render() {
    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={this.props.modalHide}
        onEnter={this.getAgendadas.bind(this)}
        container={this}
        aria-labelledby="contained-modal-title"
        dialogClassName="agend-dialog-modal"
      >
        <Modal.Body>
          <Row ref='campos'>
            <Col xs={12} md={4}>
              <FormGroup>
                <ControlLabel>Telefone</ControlLabel>
                <InputMask
                  ref="telefone"
                  value={this.state.telefone}
                  className="form-control"
                  mask={this.state.telefoneMask}
                  maskChar=""
                  onChange={e => {
                    this.setState({ telefone: e.target.value })
                    this.filterAgendadas(e.target.value, 'telefone');
                    if (/^.....9/.test(e.target.value)) {
                      this.setState({ telefoneMask: '(99) 99999-9999' })
                    }
                    else {
                      this.setState({ telefoneMask: '(99) 9999-9999' })
                    }
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={4}>
              <FormGroup>
                <ControlLabel>Cliente</ControlLabel>
                <FormControl
                  ref="cliente"
                  type="text"
                  value={this.state.cliente}
                  onChange={e => {
                    this.setState({ cliente: e.target.value });
                    this.filterAgendadas(e.target.value, 'cliente');
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={4}>
              <FormGroup>
                <ControlLabel>Endereco</ControlLabel>
                <FormControl
                  ref="endereco"
                  type="text"
                  value={this.state.endereco}
                  onChange={e => {
                    this.setState({ endereco: e.target.value });
                    this.filterAgendadas(e.target.value, 'endereco');
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <br/>
          <Row ref='agendadas' className="titulo-antigos">
            <Col xs={6} md={2}>
              <div>Telefone</div>
            </Col>
            <Col xs={6} md={2}>
              <div>Cliente</div>
            </Col>
            <Col xs={6} md={4}>
              <div>Endereço</div>
            </Col>
            <Col xs={6} md={1}>
              <div>Número</div>
            </Col>
            <Col xs={6} md={3}>
              <div>Data/Hora</div>
            </Col>
          </Row>
          {corridasFiltradas.map((m, i) => {
            return (
              <Row key={i} ref={'agendada' + i} tabIndex="0" className="enderecos-antigos" onClick={e => {
                this.props.changeAgendada(m);
              }}
              >
                <Col xs={6} md={2}>
                  <div>{m.telefone.toString().length === 10 ? m.telefone.toString().replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") : m.telefone.toString().replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}</div>
                </Col>
                <Col xs={6} md={2}>
                  <div>{m.cliente}</div>
                </Col>
                <Col xs={6} md={4}>
                  <div>{m.endereco}</div>
                </Col>
                <Col xs={6} md={1}>
                  <div>{m.numero}</div>
                </Col>
                <Col xs={6} md={3}>
                  <div>{moment(m.datahoraagendamento).format("DD/MM/YY HH:mm")}</div>
                </Col>
              </Row>
            )
          })
          }
        </Modal.Body>
      </Modal>
    );
  }
}
export default CorridasAgendadas;