import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from './CadastroHelper.js';

class CadastroTipoCancelamento extends Component {

  constructor(props) {
    super(props);
    this.state = {}

    this.ws = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {

  }

  render() {
    return (
      <CadastroHelper
        dados={{
          acessoID: 'afe44a24-0caa-4fe3-b555-077081b0eb32',
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Tipo de cancelamento",
          tipo_plural: "cancelamentos",
          href: "/tipo_cancelamento",
          titulo: "Cadastro de Tipos de Cancelamentos",
          requests: {
            type: 'tipo_cancelamento',
            list: this.ws.getTipo.bind(this.ws),
            save: this.ws.criarTipo.bind(this.ws),
            edit: this.ws.alterarTipo.bind(this.ws),
            delete: this.ws.deleteTipo.bind(this.ws),
          },
          campos: [
            {
              ref: "descricao",
              label: "Descrição",
              type: "text",
              placeHolder: "Escreva a descrição do tipo de cancelamento",
            },
            {
              ref: "ativo",
              label: "Está ativo?",
              type: "checkbox"
            },
            {
              ref: "cliente",
              label: "Mostra no app do cliente e no portal?",
              type: "checkbox"
            },
            {
              ref: "motorista",
              label: "Mostra no app do motorista?",
              type: "checkbox"
            },
            {
              ref: "reenvio",
              label: "Reenvia a Corrida?",
              type: "checkbox"
            },
            {
              ref: "ffcancelamento",
              label: "Tempo em MINUTOS de FF",
              type: "integer"
            },
          ],
          botoes: [
            {
              label: "Criar Tipo",
            },
          ],
          table: {
            colunas: [
              {
                Header: "Descrição",
                accessor: "descricao",
              },
              {
                Header: "Ativo",
                accessor: "ativo",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.ativo ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
              {
                Header: "Cliente",
                accessor: "cliente",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.cliente ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
              {
                Header: "Reenvio",
                accessor: "reenvio",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.reenvio ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              },
              {
                Header: "Motorista",
                accessor: "motorista",
                width: 100,
                Cell: row => (
                  <div
                    style={{
                      width: '16%',
                      height: '76%',
                      margin: '0 auto',
                      backgroundColor: row.original.motorista ? 'green' : 'red',
                      borderRadius: '2px'
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
              }
            ],
          }
        }}
      />
    );
  }
}

export default CadastroTipoCancelamento;
