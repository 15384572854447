import React, { Component } from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap'
import Autosuggest from 'react-autosuggest';
import Loader from 'react-loader';

class AutoCompleteCity extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: true,
    };

  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() { }

  onChange = (event, { newValue }) => {
    if (this.props.change) {
      this.props.change(newValue)
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
      if (this.props.autocomplete) {
        console.log(`newValue`,value)

        this.props.autocomplete(value)
      }

  };

  onSuggestionsClearRequested = () => {
    if (this.props.clear) {
      this.props.clear();
    }
  };

  render() {
    return (
      <FormGroup className="autoComp">
        <Loader loaded={this.state.loaded}>
        </Loader>
        {this.props.title && <ControlLabel>{this.props.title}</ControlLabel>}
        <Autosuggest
          ref={"auto_complete_helper"}
          highlightFirstSuggestion={true}
          focusInputOnSuggestionClick={false}
          suggestions={this.props.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={(e, { suggestion, method }) => {
            console.log('suggestion',suggestion)
            this.props.change(suggestion.nome);
            if (this.props.endSelected) {
              this.props.selected(suggestion.nome);
            }
          }}
          getSuggestionValue={item => item.nome}
          renderSuggestion={item =>
            <div>
              {item.nome}
            </div>
          }
          inputProps={{
            value: this.props.valor,
            onChange: this.onChange,
          }}
        />
      </FormGroup>
    );
  }
}
export default AutoCompleteCity;
