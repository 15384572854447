import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export default class Caridade extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: []
    }
    this.ws = new WS();
  }

  mirrorState(key) {
    console.log('mirrorState', key)
    this.setState(key);
  }
  render() {
    return (
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Caridade",
          tipo_plural: "Caridades",
          href: "caridade",
          titulo: "Cadastro de Caridades",
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          requests: {
            type: 'caridade',
            new: true,
            list: true,
            save: true,
            edit: true,
            delete: true,
            listSub: true,
          },
          subtipos: [
            {tipo: 'sequencia',p:'oarray', array:[{ txt: '1', id: 1 }, { txt: '2', id: 2 }, { txt: '3', id: 3 }, { txt: '4', id: 4 }, { txt: '5', id: 5 }, { txt: '6', id: 6 }, { txt: '7', id: 7 }]},
            {tipo: 'appicon',p:'oarray', array:[{ txt: '1 icone / p principe', id: 'caridadea' }, { txt: '2 icone / erasto', id: 'caridadeb' }, { txt: '3 icone / igreja', id: 'caridadec' }, { txt: '4 icone / apae', id: 'caridaded' }, { txt: '5 icone / ong', id: 'caridadee' }]},
          ],
          campos: [
            { ref: "nome", label: "Nome", type: "text",},
            { ref: "icone", label: "Icone", type: "foto64",},
            { ref: "sequencia", label: "Sequencia", type: "select", listtype:'sequencia',},
            { ref: "appicon", label: "Icone no App", type: "select", listtype:'appicon',},
          ],
          table: {
            colunas: [
              { Header: "Nome", accessor: "nome", },
            ],
          }
        }}
      />
    );
  }
};
