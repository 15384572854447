import React, { Component } from 'react'
import { Card, Col, ConfigProvider, DatePicker, Divider, Row } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { Dayjs } from 'dayjs'
import { render } from 'react-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

import WS from '../../services/webservice'

const cardStyleOff = {
  textAlign: 'center',
  color: '#FF0000',
  width: '100%',
  margin: '5px'
}

export default class SolicitacoesDashboard extends Component {
  constructor(props) {
    super(props)
    this.webservice = new WS()
    this.getStatusCorridasMonth = this.getStatusCorridasMonth.bind(this)

    this.state = {
      dataSelecionada: '',
      selectStatus30: '',
      statusCorridaMonth: []
    }
  }

  handleSelectChange30 = (value) => {
    this.setState({ selectStatus30: value })
    this.getStatusCorridasMonth(value)
  }

  async getStatusCorridasMonth(
    selectMes = JSON.stringify(new Date().toISOString().slice(0, 10))
  ) {
    try {
      const result = await this.webservice.getStatusCorridasMonth(selectMes)
      this.setState({ statusCorridaMonth: result })
    } catch (error) {
      return error
    }
  }
  render() {

    const { dataSelecionada, statusCorridaMonth } = this.state

    return (
      <Content>
        {!dataSelecionada && (
          <Divider
            orientationMargin='2'
            orientation='left'
            style={{ fontWeight: 'bold' }}
          >
            Selecione um mês
          </Divider>
        )}

        <ConfigProvider locale={'pt_BR'}>
          <DatePicker
            picker='month'
            placeholder={Dayjs().format('MMMM/YYYY')}
            format={'YYYY-MM-DD'}
            inputReadOnly
            onChange={(date, dateString) => {
              this.getStatusCorridasMonth(dateString)
              this.setState({
                dataSelecionada: dateString
              })
            }}
          />
        </ConfigProvider>

        <Divider
          orientationMargin='2'
          orientation='left'
          style={{ fontWeight: 'bold' }}
        >
          {dataSelecionada && dataSelecionada.length !== 0
            ? `Solicitações realizadas no mês de ${dayjs(
                dataSelecionada
              ).format('MMMM')}`
            : ''}
        </Divider>

        <Row justify='space-around' style={{ backgroundColor: '#fff' }}>
          {statusCorridaMonth !== null ? (
            statusCorridaMonth.map((item, index) => (
              <Col
                key={index}
                style={{
                  marginBottom: '10px',
                  textAlign: 'center',
                  width: '250px'
                }}
              >
                <Card
                  hoverable
                  headStyle={{ backgroundColor: '#a5f79c' }}
                  title={item.status.toUpperCase()}
                  bordered={false}
                  bodyStyle={{ backgroundColor: '#7daffa' }}
                >
                  {item.status === 'Canceladas' ? (
                    <div>{`Quantidade: ${item.quantidade}`}</div>
                  ) : (
                    <div>
                      <div>{`Quantidade: ${item.quantidade}`}</div>
                    </div>
                  )}
                </Card>
              </Col>
            ))
          ) : (
            <Card hoverable style={cardStyleOff} bordered>
              Sem informações para o mês selecionado
            </Card>
          )}
        </Row>
      </Content>
    )
  }
}
